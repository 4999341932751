import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import { createApprovalSheet, getStudentData, getApprovalSheetData, getSheetData } from '../../../ContextAPI/APIs/api';
import { FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import SignatureCanvas from 'react-signature-canvas'
import { useToast } from '../../../ContextAPI/Components/toast';
import { useAuth } from '../../../ContextAPI/Components/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Textarea from '../../../components/Form/Textarea';

function ApprovalForm() {

    const { user } = useAuth();
    const { alert } = useToast();
    const { id, doc } = useParams()
    const navigate = useNavigate()

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    // siblingDetails: { type: Array, required: false },

    const [data, setData] = useState({
        userId: id,
        documentIdRenewal: doc,
        name: "",
        institute: "",
        program: "",
        instituteName: "",
        programName: "",



        type: "renewal",
        currentSem: "",
        comments: "",
        currentYear: "",
        examStructure: "",
        Semfee: "",
        Yearfee: "",
        feeStudent: "",
        feeOutstanding: "",

        nextApproval: "",
        lastApprovedAmount: "",
        recomendationRepayment: "",
        recomendationPercent: "",
        recomendationRupees: "",
        recomendationRupeesNext: ""
    })


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };


    const save = async (e) => {
        e.preventDefault()
        try {

            const payload = {
                ...data,
            }

            console.log("Dlll", payload);
            const response = await createApprovalSheet(payload)
            alert(response?.message, response?.success)
            if (response?.success) {
                // alert(response?.message, response?.success)
                getSheet()
                // navigate(`/renewal-cases-history/${id}/${doc}`)

            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const [interviewers, setInterviewers] = useState([
        {
            Name: "",
            Signature: "",
        },
    ]);

    const interviewer_detail = {
        Name: "",
        Signature: "",
    }

    function Add_New_Interviewer(e) {
        e.preventDefault();
        setInterviewers([...interviewers, interviewer_detail])
    }

    function removeInterviewDetail(i) {

        const updated = [...interviewers]

        updated.splice(i, 1)
        setInterviewers(updated)

    }
    function handleInterviewChange(e, index, field) {
        const updated = [...interviewers];
        updated[index][field] = e.target.value;
        setInterviewers(updated);
    }



    const [siblingsDetail, setSiblingsDetail] = useState([
        {
            relation: "",
            maritalStatus: "",
            info: "",
        },
    ]);

    const siblings_detail = {
        relation: "",
        maritalStatus: "",
        info: "",
    }

    function Add_New_SiblingsDetail(e) {
        e.preventDefault();
        setSiblingsDetail([...siblingsDetail, siblings_detail])
    }

    function removeDetail(i) {

        const updated = [...siblingsDetail]

        updated.splice(i, 1)
        setSiblingsDetail(updated)

    }
    function handleSiblingChange(e, index, field) {
        const updated = [...siblingsDetail];
        updated[index][field] = e.target.value;
        setSiblingsDetail(updated);
    }



    const getstdData = async () => {
        try {
            const response = await getStudentData({ id: id })

            const response2 = await getApprovalSheetData({ id: id, type: "renewal", doc: doc })
            console.log("R1", response);
            console.log("R2", response2);


            if (response.success && (response2.success && response2.message !== null)) {
                setData({
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    examStructure: response?.message?.type,

                    documentIdRenewal: response2.message?.documentIdRenewal,
                    currentYear: response2.message?.currentYear,
                    Yearfee: response2.message?.Yearfee,
                    currentSem: response2.message?.currentSem,
                    comments: response2.message?.comments,
                    Semfee: response2.message?.Semfee,
                    feeOutstanding: response2.message?.feeOutstanding,
                    familyIncome: response2.message?.familyIncome,
                    recomendationRupees: response2.message?.recomendationRupees,
                    recomendationRupeesNext: response2.message?.recomendationRupeesNext,
                    recomendationPercent: response2.message?.recomendationPercent,
                    recomendationRepayment: response2.message?.recomendationRepayment,
                    nextApproval: response2.message?.nextApproval,
                    lastApprovedAmount: response2.message?.lastApprovedAmount,
                })
            }

            if (response2.message === null && response.success) {

                const response3 = await getSheetData({ id: doc, type: "renewal" })
                console.log("R3", response3);

                setData({
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    examStructure: response3?.message?.examStructure,

                    currentYear: response3.message?.currentYear,
                    Yearfee: response3.message?.Yearfee,
                    currentSem: response3.message?.currentSem,
                    Semfee: response3.message?.Semfee,
                    feeOutstanding: response3.message?.feeOutstanding,
                    familyIncome: response3.message?.familyIncome,
                    recomendationRupees: response3.message?.recomendationRupees,
                    recomendationRupeesNext: response3.message?.recomendationFeeNEXT,
                    recomendationPercent: response3.message?.recomendationPercent,
                    recomendationRepayment: response3.message?.recomendationRepayment,
                    nextApproval: response3.message?.nextApproval,
                    lastApprovedAmount: response3.message?.lastApprovedAmount,
                })
            }


        }
        catch (e) {
            console.log(e);
        }
    }

    const getSheet = async () => {
        try {
            const response = await getApprovalSheetData({ id: id, type: "renewal" })
            console.log("RSS", response);
            if (response.success && response.message !== null) {
                setData(response?.message)
            }
            else {
                getSheetDataInterview()
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const getSheetDataInterview = async () => {
        try {
            const response = await getSheetData({ id: id, type: "renewal" })
            console.log("RSS", response);
            if (response.success && response.message !== null) {
                setData(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getstdData()
        // getSheet()
    }, [])


    return (
        <>
            <Helmet>
                <title>Approval Form</title>
            </Helmet>


            <section className='interview_sheet_section'>

                <h4>
                    Ihsan Trust Renewal Case Approval Form
                </h4>

                <div className="">
                    {/* <div className="d-flex jc-end">
                                <div className="form-group">
                                    <Input inputType="date" label="Date" placeholder="Enter here" />
                                </div>
                            </div> */}

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <Input inputType="text" name="name" label="Applicant's Name" value={data.name} placeholder=" " onChange={handleChange} disabled={true} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <Input inputType="text" name="institute" label="Name of the University/Institution" value={data.instituteName} placeholder=" " onChange={handleChange} disabled={true} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <Input inputType="text" name="program" label="Program" value={data.programName} placeholder=" " onChange={handleChange} disabled={true} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            {console.log(data.examStructure)}
                            <div className="form-group">
                                <Input inputType="text" name="examStructure" label="Applicant's Examination Structure" span="*" value={data.examStructure} placeholder=" " onChange={handleChange} disabled={true} />
                                {/* <label className='form-label'>Applicant's Examination Structure <span className='required'>*</span></label> */}
                                {/* <select disabled name="examStructure" value={data.examStructure} className="form-select">
                                    <option disabled value="">Select</option>
                                    <option value="semester">Semester</option>
                                    <option value="year">Year</option>
                                </select> */}
                            </div>
                        </div>
                        {
                            (data.examStructure !== "" && data.examStructure === "year") && (
                                <>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <Input disabled inputType="number" name="currentYear" label="Current Year" span="*" value={data.currentYear} placeholder=" " onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <Input disabled inputType="number" name="Yearfee" label="Per Year Fee" span="*" value={data.Yearfee} placeholder=" " onChange={handleChange} />
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                            (data.examStructure !== "" && data.examStructure === "semester") && (
                                <>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <Input disabled inputType="number" name="currentSem" label="Current Semester" span="*" value={data.currentSem} placeholder=" " onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <Input disabled inputType="number" name="Semfee" label="Per Semester Fee" span="*" value={data.Semfee} placeholder=" " onChange={handleChange} />
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {/* <div className="col-md-6">
                            <div className="form-group">
                                <Input disabled inputType="text" name="nextApproval" label="Next Approval" span="*" value={data.nextApproval} placeholder="Enter here" onChange={handleChange} />
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <Input disabled inputType="number" name="lastApprovedAmount" label="Last Approved Amount" value={data.lastApprovedAmount} placeholder="Enter here" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <Input
                                    inputType="number"
                                    name="recomendationRupees"
                                    label="Tranch"
                                    value={data.recomendationRupees}
                                    placeholder="Enter here"
                                    onChange={handleChange} />
                            </div>
                        </div>
                        {
                            data.examStructure === "semester" && (
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <Input
                                            inputType="number"
                                            name="recomendationRupeesNext"
                                            label="Next Tranch"
                                            value={data.recomendationRupeesNext}
                                            placeholder="Enter here"
                                            onChange={handleChange} />
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <Input inputType="number" name="recomendationPercent" label="Recommended Percentage %" value={data.recomendationPercent} placeholder="Enter here" onChange={handleChange} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <Input inputType="number" name="recomendationRepayment" label="Repayment Amount (Rs.)" value={data.recomendationRepayment} placeholder="Enter here" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <Textarea
                                    label="Comments"
                                    name="comments"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    className='form-control'
                                    value={data.comments}
                                    placeholder="Enter here"
                                    onChange={handleChange}
                                ></Textarea>
                            </div>
                        </div>

                    </div>
                    {
                        (user?.role == "admin" || user?.role == "operations") && (

                            data?.documentIdRenewal?.RenewStatus !== "disburse" && (
                                <div className='d-flex jc-end'>
                                    <button className='btn save_btn' onClick={(e) => save(e)}>Save</button>
                                </div>
                            )

                        )
                    }
                </div>
            </section>

        </>
    )
}

export default ApprovalForm
