import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Input from '../../components/Form/Input';
import Textarea from '../../components/Form/Textarea';
import Select from '../../components/Form/Select';
import { createInterviewSheet, getStudentData, getSheetData } from '../../ContextAPI/APIs/api';
import { FaPlus } from 'react-icons/fa';
import { useNavigation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import SignatureCanvas from 'react-signature-canvas'
import { useToast } from '../../ContextAPI/Components/toast';
import { useAuth } from "../../ContextAPI/Components/auth"
import { useLocation, useNavigate } from 'react-router-dom';
import { semesters } from '../../helpers/data';

function InterviewSheet({ getData1, setShow }) {
    const { user } = useAuth();
    const { id, doc } = useParams()
    const navigate = useNavigate()
    console.log("--->id", id);
    console.log("--->doc", doc);

    const { alert } = useToast()

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    // siblingDetails: { type: Array, required: false },

    const [data, setData] = useState({
        userId: id,
        documentIdFresh: doc,
        name: "",
        institute: "",
        program: "",
        instituteName: "",
        programName: "",
        haveSiblings: "",
        totalSiblings: 0,
        belongsTo: "",
        houseDetail: "",
        houseSize: "",
        locality: "",
        repayThisYear: "",
        repayLastYear: "",
        careerGoals: "",
        otherDetails: "",

        like: false,
        mblAcc: false,
        tarjumah: false,
        Dua_e_Qunoot: false,

        type: "fresh",
        otherRequirements: "",
        Recomendations: "",

        selfIncomeSource: "",
        selfIncome: 0,
        fatherIncomeSource: "",
        fatherIncome: 0,
        motherIncomeSource: "",
        motherIncome: 0,
        familyIncome: 0,
        currentSem: "",
        currentYear: "",
        examStructure: "",
        Semfee: "",
        Yearfee: "",
        feeStudent: "",
        feeOutstanding: "",
        brothers: 0,
        sisters: 0,
        rent: "",

        recomendationRupees: "",
        recomendationPercent: "",
        recomendationFee: "",
        recomendationYear: "",
        recomendationRepayment: "",



        semDetail: "",
        recomendationFeeNEXT: "",
        semDetailNEXT: "",
        recomendationYearNEXT: "",

        session: "",
        semesterNext: "",
    })


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log("V", name, value);


        if (name == "brothers") {
            const tot = parseInt(data.sisters) + parseInt(value)
            setData({ ...data, brothers: value, totalSiblings: tot })
        }
        if (name == "sisters") {
            const tot = parseInt(data.brothers) + parseInt(value)
            setData({ ...data, sisters: value, totalSiblings: tot })
        }

        if (name != "brothers" && name != "sisters") {
            setData((prevData) => ({
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    };


    const save = async (e) => {
        e.preventDefault()
        console.log("hello 123");
        try {

            const payload = {
                ...data,
                interviewerDetails: interviewers,
                siblingsData: siblingsDetail,
                familyIncome: parseInt(data.selfIncome) + parseInt(data.fatherIncome) + parseInt(data.motherIncome)
            }

            console.log("familyIncome", payload.familyIncome);

            console.log("Dlll", payload);
            const response = await createInterviewSheet(payload)
            alert(response?.message, response?.success)
            if (response?.success) {
                getstdData()
                getData1()
                navigate(`/fresh-cases-history/${id}/${doc}#interview-sheet-preview`)
                // alert(response?.message, response?.success)
                // getSheet()
                // http://localhost:3000/fresh-cases-history/65c7355db0aa4444eb7fb525
                // setShow("#cases-detail")
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const [interviewers, setInterviewers] = useState([
        {
            Name: "",
            Signature: "",
        },
    ]);

    const interviewer_detail = {
        Name: "",
        Signature: "",
    }

    function Add_New_Interviewer(e) {
        e.preventDefault();
        setInterviewers([...interviewers, interviewer_detail])
    }

    function removeInterviewDetail(i) {

        const updated = [...interviewers]

        updated.splice(i, 1)
        setInterviewers(updated)

    }
    function handleInterviewChange(e, index, field) {
        const updated = [...interviewers];
        updated[index][field] = e.target.value;
        setInterviewers(updated);
    }



    const [siblingsDetail, setSiblingsDetail] = useState([
        {
            relation: "",
            maritalStatus: "",
            info: "",
            comments: ""
        },
    ]);

    const siblings_detail = {
        relation: "",
        maritalStatus: "",
        info: "",
        comments: ""
    }

    function Add_New_SiblingsDetail(e) {
        e.preventDefault();
        setSiblingsDetail([...siblingsDetail, siblings_detail])
    }

    function removeDetail(i) {

        const updated = [...siblingsDetail]

        updated.splice(i, 1)
        setSiblingsDetail(updated)

    }
    function handleSiblingChange(e, index, field) {
        const updated = [...siblingsDetail];
        updated[index][field] = e.target.value;
        setSiblingsDetail(updated);
    }


    const getstdData = async () => {
        try {
            const response = await getStudentData({ id: id, doc: doc })
            // const response = await getStudentData({ id: id })
            console.log("response33333", response.message);


            const response2 = await getSheetData({ id: doc, type: "fresh" })

            console.log("response22222", response2.message);

            let upd = {}
            // if (response.success && response2.success && response2 !== null) {
            //     console.log("HHH@@@", response.message);
            //     setData({
            //         ...data,
            //         name: response?.message?.studentId?.fullName,
            //         institute: response?.message?.university?._id,
            //         program: response?.message?.program?._id,
            //         instituteName: response?.message?.university?.fullName,
            //         programName: response?.message?.program?.name,
            //         ...response2.message
            //     })
            //     setInterviewers(response2?.message?.interviewerDetails)
            //     setSiblingsDetail(response2?.message?.siblingsData)
            // }



            if ((response?.success && response?.message !== null) && (response2.message === null)) {
                upd = {
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    careerGoals: response?.message?.careerGoals,
                    brothers: response?.message?.brothers,
                    sisters: response?.message?.sisters,
                    totalSiblings: response?.message?.totalSiblings,
                    examStructure: response?.message?.examStructure,
                    currentSem: response?.message?.currentSem,
                    semtype: response?.message?.semtype,
                    currentYear: response?.message?.currentSem,
                    houseDetail: response?.message?.houseDetail,
                    houseSize: response?.message?.houseSize,
                }
            }
            else {
                upd = {
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    ...response2.message
                }

                setInterviewers(response2?.message?.interviewerDetails)
                setSiblingsDetail(response2?.message?.siblingsData)

            }
            setData(upd)
        }
        catch (e) {
            console.log(e);
        }
    }

    const getSheet = async () => {
        try {
            const response = await getSheetData({ id: id, type: "fresh" })
            console.log("RSS", response);
            if (response.success && response.message !== null) {
                setData(response?.message)
                setInterviewers(response?.message?.interviewerDetails)
                setSiblingsDetail(response?.message?.siblingsData)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getstdData()
        // getSheet()
    }, [])


    return (
        <>
            <Helmet>
                <title>Interview Sheet</title>
            </Helmet>


            <section className='interview_sheet_section'>

                <h4>Ihsan Trust Fresh Case Interview sheet</h4>

                <div className="">
                    <form onSubmit={(e) => save(e)}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="name" label="Applicant's Name" value={data.name} placeholder="Enter here" onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="institute" label="Name of the Institution" value={data.instituteName} placeholder="" onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="program" label="Program" value={data.programName} placeholder="" onChange={handleChange} disabled={true} />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="examStructure" label="Examination Structure" span="*" value={data.examStructure} placeholder="" onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            {
                                (data.examStructure !== "" && data.examStructure === "year") && (
                                    <>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input disabled inputType="number" name="currentYear" label="Current Year" span="*" value={data.currentYear} placeholder="Enter here" onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input inputType="number" name="Yearfee" label="Per Year Fee" span="*" value={data.Yearfee} placeholder="Enter here" onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {
                                (data.examStructure !== "" && data.examStructure === "semester") && (
                                    <>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input disabled inputType="number" name="currentSem" label="Current Semester" span="*" value={data.currentSem} placeholder="Enter here" onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input disabled inputType="text" name="semtype" label="Academic Session" span="*" value={data.semtype} placeholder="Enter here" onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input inputType="number" name="Semfee" label="Per Semester Fee" span="*" value={data.Semfee} placeholder="Enter here" onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="number" name="feeStudent" label="Fee Paid by Student" span="*" value={data.feeStudent} placeholder="Enter here" onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="number" name="feeOutstanding" label="Outstanding Fee" value={data.feeOutstanding} placeholder="Enter here" onChange={handleChange} />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="belongsTo" label="Belongs to" span="*" value={data.belongsTo} placeholder="Enter here" onChange={handleChange} required />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form_sub_heading mb-3">
                                    <h5 className='my-2'>House Information</h5>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className='form-group'>
                                    <div className="house_size_input">
                                        <label className='form-label me-4'>Do you own or rent a house? <span className='required'>*</span></label>
                                        <div className="form-check me-5">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="houseDetail"
                                                id="ownedRadio"
                                                checked={data.houseDetail === 'owned'}
                                                onChange={() => handleChange({ target: { name: 'houseDetail', value: 'owned', type: 'radio' } })}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="ownedRadio"> Owned </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="houseDetail"
                                                id="rentalRadio"
                                                checked={data.houseDetail === 'rental'}
                                                onChange={() => handleChange({ target: { name: 'houseDetail', value: 'rental', type: 'radio' } })}
                                            />
                                            <label className="form-check-label" htmlFor="rentalRadio"> Rental </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='form-group'>
                                    <Input inputType="text" name="houseSize" value={data.houseSize} label="Size of the House(yards)" span="*" placeholder="Enter here" onChange={handleChange} required />
                                </div>
                            </div>
                            {
                                data.houseDetail === "rental" && (
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className='form-group'>
                                            <Input inputType="text" name="rent" value={data.rent} label="Monthly Rent (Rs.)" span="*" placeholder="Enter here" onChange={handleChange} required />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='form-group'>
                                    <Input inputType="text" name="locality" value={data.locality} label="Locality" span="*" placeholder="Enter here" onChange={handleChange} required />
                                </div>
                            </div>


                            <div className="accordion" id="accordionExample">
                                {/* Siblings Information */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Siblings Information
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="row">
                                                {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label className='form-label'>Do you have siblings? <span className='required'>*</span></label>
                                                        <select name="haveSiblings" value={data.haveSiblings} onChange={handleChange} className="form-select" required>
                                                            <option disabled value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="brothers" label="Number of Brothers" value={data.brothers} onChange={handleChange} placeholder="Enter here" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="sisters" label="Number of Sisters" value={data.sisters} onChange={handleChange} placeholder="Enter here" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <div className="form-group">
                                                        <Input inputType="number" value={data.totalSiblings} label="Total" disabled={true} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form_sub_heading">
                                                        <h5 className='mb-0'>Siblings Detail</h5>
                                                        {
                                                            siblingsDetail.length < 2 && (
                                                                <div className='d-flex jc-end'>
                                                                    <button className='btn add_btn' onClick={(e) => Add_New_SiblingsDetail(e)}><FaPlus /></button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    siblingsDetail.map((data, i) => {
                                                        return <>
                                                            {
                                                                i > 0 &&
                                                                <div className='col-12'>
                                                                    <div className="section_break"></div>
                                                                </div>
                                                            }

                                                            <div className="col-md-12">
                                                                <div className="form_sub_heading mb-3">
                                                                    <h5 className='mb-0'>Sibling {i + 1}</h5>
                                                                    {
                                                                        i !== 0 && i == siblingsDetail.length - 1 && (
                                                                            <div className='d-flex jc-end'>
                                                                                <button className='btn add_btn' onClick={(e) => Add_New_SiblingsDetail(e)}><FaPlus /></button>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <div className="form-group">
                                                                    <label className='form-label'>Relationship</label>
                                                                    <select value={siblingsDetail[i].relation} className='form-select' onChange={(e) => handleSiblingChange(e, i, 'relation')}>
                                                                        <option selected disabled value="">Select</option>
                                                                        <option value="sister">Sister</option>
                                                                        <option value="brother">Brother</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                <div className="form-group">
                                                                    <label className='form-label'>Marital Status</label>
                                                                    <select value={siblingsDetail[i].maritalStatus} className='form-select' onChange={(e) => handleSiblingChange(e, i, 'maritalStatus')}>
                                                                        <option selected disabled value="">Select</option>
                                                                        <option value="single">Single</option>
                                                                        <option value="married">Married</option>
                                                                        <option value="widow">Widow</option>
                                                                        <option value="widower">Widower</option>
                                                                        <option value="divorced">Divorced</option>
                                                                    </select>

                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className='form-label'>Sibling {i + 1} Information</label>
                                                                    <textarea value={siblingsDetail[i].info} className='form-control' cols="30" rows="3" placeholder='Type here...' onChange={(e) => handleSiblingChange(e, i, 'info')}></textarea>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label className='form-label'>Sibling {i + 1} Comments</label>
                                                                            <textarea value={siblingsDetail[i].comments} className='form-control' cols="30" rows="3" placeholder='Type here...' onChange={(e) => handleSiblingChange(e, i, 'comments')}></textarea>
                                                                        </div>
                                                                    </div> */}

                                                            {
                                                                i > 0 &&
                                                                <div className='d-flex jc-end'>
                                                                    <button type='button' onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                                </div>
                                                            }
                                                        </>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Income Details */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Income Details
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label style={{ visibility: "hidden" }} className='form-label'>Income detail of <span className='required'>*</span></label>
                                                        <h5>Self Income Details</h5>
                                                        {/* <textarea name="selfIncomeSource" className='form-control' value={data.selfIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange} required></textarea> */}
                                                    </div>
                                                </div>

                                                <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="selfIncome" label="Total Income" value={data.selfIncome >= 0 ? data.selfIncome : ""} span="*" placeholder="Enter here" onChange={handleChange} required />
                                                    </div>
                                                </div>


                                                <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Source of Income <span className='required'>*</span></label>
                                                        <textarea name="selfIncomeSource" className='form-control' value={data.selfIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange} required></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label style={{ visibility: "hidden" }} className='form-label'>Income detail of <span className='required'>*</span></label>
                                                        <h5>Father Income Details</h5>
                                                        {/* <textarea name="selfIncomeSource" className='form-control' value={data.selfIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange} required></textarea> */}
                                                    </div>
                                                </div>

                                                <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="fatherIncome" label="Total Income" value={data.fatherIncome >= 0 ? data.fatherIncome : ""} span="*" placeholder="Enter here" onChange={handleChange} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Source of Income <span className='required'>*</span></label>
                                                        <textarea name="fatherIncomeSource" className='form-control' value={data.fatherIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange} required></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label style={{ visibility: "hidden" }} className='form-label'>Income detail of <span className='required'>*</span></label>
                                                        <h5>Mother Income Details</h5>
                                                        {/* <textarea name="selfIncomeSource" className='form-control' value={data.selfIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange} required></textarea> */}
                                                    </div>
                                                </div>

                                                <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="motherIncome" label="Total Income" value={data.motherIncome >= 0 ? data.motherIncome : ""} span="*" placeholder="Enter here" onChange={handleChange} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Source of Income <span className='required'>*</span></label>
                                                        <textarea name="motherIncomeSource" className='form-control' value={data.motherIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange} required></textarea>
                                                    </div>
                                                </div>

                                                {/* <div className="col-12">
                                                            <div className="form-group">
                                                                <label className='form-label'>Comments</label>
                                                                <textarea name="motherIncomeSource" className='form-control' value={data.motherIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange}></textarea>
                                                            </div>
                                                        </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input inputType="number" name="repayThisYear" label="Monthly repayment" span="*" value={data.repayThisYear} placeholder="Enter here" onChange={handleChange} required />
                                </div>
                            </div>

                            {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <Input inputType="number" name="repayLastYear" label="Last year Repayment" span="*" value={data.repayLastYear} placeholder="Enter here" onChange={handleChange} required />
                                        </div>
                                    </div> */}


                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="like" checked={data.like} onChange={handleChange} id="likeCheckbox" required />
                                                <label className="form-check-label form-label" htmlFor="likeCheckbox"> FB & Insta Like <span className='required'>*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="mblAcc" checked={data.mblAcc} onChange={handleChange} id="mblAccCheckbox" required />
                                                <label className="form-check-label form-label" htmlFor="mblAccCheckbox"> MBL Account <span className='required'>*</span> </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="tarjumah" checked={data.tarjumah} onChange={handleChange} id="tarjumahCheckbox" />
                                                <label className="form-check-label form-label" htmlFor="tarjumahCheckbox"> Tarjuma & Tashreeh </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="Dua_e_Qunoot" checked={data.Dua_e_Qunoot} onChange={handleChange} id="duaQunootCheckbox" />
                                                <label className="form-check-label form-label" htmlFor="duaQunootCheckbox"> Dua e Qanoot </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='form-label'>Career aspirations of the candidate</label>
                                    <textarea className='form-control' name="careerGoals" value={data.careerGoals} cols="30" rows="3" placeholder='Type here...' onChange={handleChange}></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='form-label'>Comments</label>
                                    <textarea className='form-control' name="otherDetails" value={data.otherDetails} cols="30" rows="3" placeholder='Type here...' onChange={handleChange}></textarea>
                                </div>
                            </div>

                            {/* <div className="col-12">
                                        <div className="form-group">
                                            <label className='form-label'>Other Requirements</label>
                                            <textarea className='form-control' name="otherRequirements" value={data.otherRequirements} cols="30" rows="3" placeholder='Type here...' onChange={handleChange}></textarea>
                                        </div>
                                    </div> */}

                            <div className="col-12">
                                <div className="form_sub_heading mb-3">
                                    <h5 className='my-2'>RECOMMENDATIONS</h5>
                                </div>
                                <div className='recommendation'>
                                    <p>
                                        As per our recommendation, the student seems to be needy as the applicant’s family income
                                        is insufficient to ear educational expenses in full, and the applicant has requested for
                                        financial assistance
                                    </p>

                                    <div className='d-flex flex-wrap ai-center'>
                                        <p>@ Rs.</p>
                                        <input type="number" disabled value={data.recomendationRupees} name="recomendationRupees" onChange={handleChange} placeholder='Amount' className='form-control recomend_input' />
                                        <p>/- i.e.</p>
                                        <input type="number" disabled value={data.recomendationPercent} name="recomendationPercent" onChange={handleChange} placeholder='Percentage' className='form-control recomend_input' />
                                        <p>% of fee for</p>
                                        &nbsp;
                                        {/* <input type="number" value={data.recomendationFee} name="recomendationFee" onChange={handleChange} placeholder='Semester' className='form-control recomend_input' /> */}
                                        <input type="number" disabled value={data.semDetail} name="semDetail" onChange={handleChange} placeholder='Semester / year' className='form-control recomend_input' />
                                        {/* <select name="semDetail" disabled value={data.semDetail} onChange={handleChange} className='form-select' style={{ width: "20%" }} >
                                            <option selected disabled value="">Select Semester</option>
                                            {
                                                semesters.map((semester, i) => {
                                                    return <>
                                                        <option value={semester}>{semester}</option>
                                                    </>
                                                })
                                            }
                                        </select> */}

                                        &nbsp;

                                        {/* year */}
                                        {
                                            data.examStructure === "semester" ?
                                                <>
                                                    <p>semester</p> &nbsp;
                                                </>
                                                :
                                                data.examStructure === "year" ?
                                                    <>
                                                        <p>year</p> &nbsp;
                                                    </>
                                                    :
                                                    "---"
                                        }


                                        {
                                            data.examStructure === "semester" && (
                                                <>
                                                    {/* <label className='form-label'>Fall/Spring</label> */}
                                                    <input type="text" disabled value={data.session} name="session" onChange={handleChange} placeholder='Session' className='form-control recomend_input' />

                                                    {/* <select disabled name="session" value={data.session} onChange={handleChange} className='form-select' >
                                                        <option selected disabled value="">Select Session</option>
                                                        <option value="spring">Spring</option>
                                                        <option value="fall">Fall</option>
                                                    </select> */}
                                                </>
                                            )
                                        }

                                        {
                                            data.examStructure === "semester" && (
                                                <>
                                                    {/* <div className='d-flex ai-center' style={{ textWrap: "nowrap" }}> */}
                                                    <input disabled type="number" value={data.recomendationYear} name="recomendationYear" placeholder="year" onChange={handleChange} className='form-control recomend_input' />
                                                    {/* values set krni hein */}
                                                    <p>and furthermore</p>
                                                    <input disabled type="number" value={data.recomendationFeeNEXT} name="recomendationFeeNEXT" placeholder="% of fee" onChange={handleChange} className='form-control recomend_input' />
                                                    <p>% of fee for</p> &nbsp;
                                                    <input disabled type="number" value={data.semesterNext} name="semesterNext" placeholder="Semester" onChange={handleChange} className='form-control recomend_input' />
                                                    {/* <select disabled name="semesterNext" value={data.semesterNext} onChange={handleChange} className='form-select' style={{ width: "20%" }} >
                                                            <option selected disabled value="">Select Semester</option>
                                                            {
                                                                semesters.map((semester, i) => {
                                                                    return <>
                                                                        <option value={semester}>{semester}</option>
                                                                    </>
                                                                })
                                                            }
                                                        </select> */}
                                                    <p>semester</p> &nbsp;
                                                    {/* <label className='form-label'>Fall/Spring</label> */}
                                                    <input disabled type="text" value={data.semDetailNEXT} name="semDetailNEXT" placeholder="Session" onChange={handleChange} className='form-control recomend_input' />
                                                    {/* <select disabled name="semDetailNEXT" value={data.semDetailNEXT} onChange={handleChange} className='form-select' >
                                                            <option selected disabled value="">Select Session</option>
                                                            <option value="spring">Spring</option>
                                                            <option value="fall">Fall</option>
                                                        </select> */}
                                                    <input disabled type="number" value={data.recomendationYearNEXT} name="recomendationYearNEXT" placeholder="year" onChange={handleChange} className='form-control recomend_input' />
                                                    {/* </div> */}
                                                </>
                                            )
                                        }


                                        {/* <div className='d-flex ai-center' style={{ textWrap: "nowrap" }}> */}
                                        <div>
                                            <p>could be given as Interest Free Loan with monthly repayments</p>
                                        </div>
                                        &nbsp;
                                        <p>@ Rs.</p>
                                        <input disabled type="number" value={data.recomendationRepayment} name="recomendationRepayment" onChange={handleChange} className='form-control recomend_input' />
                                        <p>/- per month.</p>
                                        {/* </div> */}


                                    </div>

                                </div>
                            </div>

                            <div className="">

                                <div className="form_sub_heading mb-3">
                                    <h5 className='mb-0'>Interviewers</h5>
                                    <div className='d-flex jc-end'>
                                        <button className='btn add_btn' type='button' onClick={(e) => Add_New_Interviewer(e)}><FaPlus /></button>
                                    </div>
                                </div>

                                {
                                    interviewers.map((data, i) => (
                                        <>

                                            {
                                                i > 0 &&
                                                <div className="section_break"></div>
                                            }

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Name <span className='required'>*</span></label>
                                                        <input value={interviewers[i].Name} onChange={(e) => handleInterviewChange(e, i, "Name")} type="text" className='form-control' placeholder='Enter here' required />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className='form-label'>Signature</label>
                                                            </div>
                                                        </div> */}
                                            </div>

                                            {
                                                i > 0 &&
                                                <div className='d-flex jc-end'>
                                                    <button type='button' onClick={() => removeInterviewDetail(i)} className='btn delete_btn mb-3'>Remove Detail</button>
                                                </div>
                                            }

                                        </>
                                    ))
                                }

                            </div>
                        </div>
                        {
                            (user?.role == "admin" || user?.role == "operations") && (
                                data.documentIdFresh.status !== "disburse" && (
                                    < div className='d-flex jc-end'>
                                        <button className='btn save_btn' type='submit'>Save</button>
                                    </div>
                                )
                            )
                        }
                    </form>
                </div>
            </section>
        </>
    )
}

export default InterviewSheet
