import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../ContextAPI/Components/auth'

import { PiArrowBendDownLeftBold } from "react-icons/pi";

function AccessDenied() {

    const { user } = useAuth();

    return (
        <>
            <div className='update_msg'>
                <div>
                    {/* <h1>Access Denied!</h1> */}
                    <h1>Complete your profile first</h1>
                    <h2>then you could proceed</h2>
                    {/* <p>You need to complete your Profile and Qualification</p> */}
                    <div>
                        {
                            user.pInfo == false ?
                                <Link to="/std-profile">
                                    <button className='btn btn-complete'>Complete Profile</button>
                                </Link>
                                :
                                <Link to="/std-profile#education-details">
                                    <button className='btn btn-complete'>Complete Education Information</button>
                                </Link>
                        }
                        <PiArrowBendDownLeftBold className='animated-arrow'/>
                        {/* <span className='animated-arrow'><PiArrowBendDownLeftBold /></span> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccessDenied
