import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import SignatureCanvas from 'react-signature-canvas'
import UserModal from '../../components/Modals/Modal';
import Input from '../../components/Form/Input';
import SelectNew from '../../components/Form/Select_New';

// import SelectNew from "../Form/Select_New";
import { useEffect } from 'react';
import { DisbursementAnalysis, GetUnisList, repaymentDisbursementAnalysis } from '../../ContextAPI/APIs/api';
import { useToast } from '../../ContextAPI/Components/toast';
import { CSVLink } from "react-csv";
import Pagination from '../../components/Pagination';
import { useAuth } from '../../ContextAPI/Components/auth';

import formatter from "../../helpers/number_formatter"
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';


const headers = [
    { label: "S.No", key: "SNO", },
    { label: "Name", key: "name", },
    { label: "University", key: "institute", },
    { label: "Program", key: "program", },
    { label: "Family Income Per month (Rs.)", key: "familyIncome", },
    { label: "Per Semester/Year Fee (Rs.)", key: "Semfee / Yearfee", },
    { label: "Outstanding fee (Rs.)", key: "feeOutstanding", },
    { label: "Amount of Fee Recommended", key: "recomendationRupees", },
    { label: "% of Fee Recommended", key: "recomendationPercent", },
    { label: "Repayment Amount", key: "recomendationRepayment", },
    // { label: "Available_Limit",  key: "Available Limit", },
];


function DisbursementRepayAnalysis() {

    const [uni, setUni] = useState([])

    const { user } = useAuth();

    const { alert } = useToast();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();




    const [totalCount, setTotalCount] = useState(4)


    function FormatDataIntoCSV() {
        const formattedData = data.map((item, i) => ({
            SNO: i + 1,
            ...item,
            institute: item.institute ? item.institute.fullName : null,
            program: item.program ? item.program.name : null,
            Available_Limit: null
        }));

        return formattedData
    }




    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(skip)
    }

    const [Qualification, setQualification] = useState({
        university: "",
    })

    const SELECTUNI = (e) => {
        console.log(e)
        try {
            setQualification({ ...Qualification, university: e })
        }
        catch (e) {
            console.log(e);
        }
    }

    const getAllUni = async () => {
        try {
            const response = await GetUnisList()

            if (response?.success) {
                console.log("UNISS", response.message);
                setUni(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const currentYear = new Date().getFullYear();

    const yearOptions = [];
    for (let year = currentYear; year >= 2010; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }

    const [year, setYear] = useState("")
    // Handler function to handle option change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        // Do something with the selected year
        setYear(event.target.value)

        console.log("Selected year:", selectedYear);
    };



    const [data, setData] = useState([])

    const search = async (skip) => {
        try {
            console.log("UNIII", Qualification.university);

            const response = await repaymentDisbursementAnalysis({ year: year, uni: Qualification.university._id }, skip)
            console.log("hello 123 responseeee", response);

            // return

            if (response.success && response.message.length > 0) {
                setData(response.message)
                setTotalCount(response?.totalCount?.length)
                setShow(false)

                let temp = [];

                for (let i = 0; i < response?.totalCount?.length; i++) {
                    let a = {};
                    // a['Student Name'] = response?.totalCount[i]?.studentId?.fullName;
                    // a.University = response?.totalCount[i]?.uniId?.fullName;
                    // a.CNIC = response?.totalCount[i]?.studentProfile?.cnic;
                    // a.Contact = response?.totalCount[i]?.studentId?.phone;
                    // a.Email = response?.totalCount[i]?.studentId?.email;
                    // a['Case Receipt Date'] = timestampToDate(response?.totalCount[i]?.documentFresh.timestamp);
                    // // a['Interview Time'] = timestampToTime(response?.totalCount[i]?.interviewTime);
                    // a['Interview Date'] = timestampToDate(response?.totalCount[i]?.interviewDate);
                    // a['Approval Date'] = timestampToDate(response?.totalCount[i]?.approvalDate);
                    // a['Disbursement Date'] = timestampToDate(response?.totalCount[i]?.disbursementDate);
                    // a['Interview Remarks'] = response?.totalCount[i]?.remarks;
                    a['S.No'] = i+1
                    a['Name'] = response?.totalCount[i]?.userId?.fullName
                    a['University'] = response?.totalCount[i]?.uniId?.fullName
                    a['Program'] = response?.totalCount[i]?.program?.name
                    a['Total Disbursements'] = response?.totalCount[i]?.disbursements
                    a['Total Repayments'] = response?.totalCount[i]?.repayments
                    a['Total Outstandings'] =parseFloat(response?.totalCount[i]?.disbursements)-  parseFloat(response?.totalCount[i]?.repayments || 0)
                    

                    temp.push(a);
                }

                setXlsxData(temp);
            }
            if (!response.success) {
                alert(response.message, response.success)
                setData([])
            }
            if (response.success && response.message.length < 1) {
                setData([])
                alert("No data found", false)
            }

        } catch (e) {
            console.log(e);
        }
    };



    useEffect(() => {
        getAllUni()
    }, [])

    const [xlsxData, setXlsxData] = useState([]);

    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const fetchTemplateAndExport = async (apiData, fileName) => {
        try {
            const response = await fetch('/disbursementAndRepaymentAnalysis.xlsx');
    
            // Added debugging logs
            console.log('Fetch Response:', response);
    
            if (!response.ok) {
                throw new Error('Failed to fetch template file');
            }
    
            const arrayBuffer = await response.arrayBuffer();
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(arrayBuffer);
    
            const worksheet = workbook.getWorksheet('Sheet1');
            const startRow = 3;
    
            apiData.forEach((row, rowIndex) => {
                const sheetRow = worksheet.getRow(startRow + rowIndex);
                Object.keys(row).forEach((key, colIndex) => {
                    const cell = sheetRow.getCell(colIndex + 1);
                    cell.value = row[key];
    
                    // Apply header styles only if they are necessary and don't affect the font weight
                    const headerCell = worksheet.getRow(2).getCell(colIndex + 1);
                    if (headerCell) {
                        // cell.font = { ...headerCell.font, bold: false };  // Ensure font is not bold
                        cell.font = {  bold: false };  // Ensure font is not bold
                        cell.alignment = headerCell.alignment;
                        cell.border = headerCell.border;
                        cell.fill = headerCell.fill;
                    }
                });
                sheetRow.commit();
            });
    
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: fileType });
    
            FileSaver.saveAs(blob, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            alert('Error exporting to Excel. Please try again later.');
        }
    };



    return (
        <>
            <Helmet>
                <title>Disbursements and Repayments Analysis </title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section'>

                        <div className='d-flex jc-between ai-center mb-4 flex_heading_button'>

                            <div className='d-flex ai-center'>
                                <Link to="/reporting">
                                    <IoArrowBack className='back_arrow_icon me-3' />
                                </Link>
                                <h2 className='mb-0'>Disbursements and Repayments Analysis</h2>
                            </div>

                            <div className='d-flex jc-end gap-1 buttons'>

                            {(data.length > 0 && xlsxData.length > 0) && (user?.role === 'admin' || user?.role === 'operations') && (
                                    <button
                                        onClick={() => fetchTemplateAndExport(xlsxData, 'Disbursements and Repayments Analysis')}
                                        className="btn save_btn"
                                    >
                                        Download
                                    </button>
                                )}

                                <UserModal btnText={"Filter"} heading="Filter" id={"filter"} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <SelectNew
                                                    label="Name of the University/Institution"
                                                    options={[{ _id: '', fullName: 'Select your University/Institution' }, ...uni]}
                                                    isOptionDisabled={(option) => (option._id == '' || option._id == null)}
                                                    value={Qualification?.university}
                                                    getOptionLabel={option =>
                                                        `${option.fullName}`
                                                    }
                                                    getOptionValue={option => `${option._id}`}
                                                    onChange={(value) => SELECTUNI(value)}
                                                    loadingMessage={"Loading..."}
                                                    style={{ textTransform: "capitalize" }}
                                                // required
                                                // span="*"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Year </label>
                                                <select className='form-select' id="yearSelect" onChange={handleYearChange}>
                                                    <option selected disabled value="">Select Year</option>
                                                    {yearOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='d-flex jc-end'>
                                            <button onClick={(e) => search(pageSkip)} className='btn save_btn'>Save</button>
                                        </div>
                                    </div>
                                </UserModal>
                            </div>

                        </div>

                        <div className="card">
                            {
                                data.length > 0 ?
                                    <>
                                        <Table
                                            class="table-striped"
                                            head={["S.No", "Name", "University", "Program", "Total Disbursements", "Total Repayments", "Total Outstanding"]}
                                        >
                                            {
                                                data?.map((item, i) => {
                                                    return <tr key={i}>
                                                        <td><Text text={(pageSkip * 10)+ i + 1} /></td>
                                                        <td><Text text={item?.userId?.fullName} /></td>
                                                        <td><Text text={item?.uniId?.fullName} /></td>
                                                        <td><Text text={item?.program?.name} /></td>
                                                        <td><Text text={formatter(item?.disbursements)} /></td>
                                                        <td><Text text={formatter(item?.repayments ? item?.repayments : 0)} /></td>
                                                        <td><Text text={formatter(item?.disbursements - (item.repayments ? item.repayments : 0))} /></td>
                                                    </tr>
                                                })
                                            }
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />

                                        {/* || item?.disbursements */}
                                        {/* <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} /> */}
                                    </>

                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }
                        </div>

                    </section>

                </div>
            </div>

        </>
    )
}

export default DisbursementRepayAnalysis
