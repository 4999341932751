import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import Badges from '../../components/Badges'
import Badge from "../../components/Badges"
import { RxCross2 } from 'react-icons/rx';
import Input from '../../components/Form/Input';
import { BiArrowBack } from 'react-icons/bi';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { getRepaymentYear, getStudentData, updateRepaymentInvoice, getStudentsApplications, getStdTranch } from "../../ContextAPI/APIs/api"
import Tabs from '../../components/tabs';
import TooltipComponent from '../../components/tooltip';
import { TbReportAnalytics } from 'react-icons/tb';
import IconText from '../../components/Table/IconText';
import { FaEdit } from 'react-icons/fa';

import UserModal from '../../components/Modals/Modal';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useToast } from '../../ContextAPI/Components/toast';
import { IoArrowBack } from 'react-icons/io5'
import formatter from '../../helpers/number_formatter';


function StudentReport() {
    const { user } = useAuth();
    const { id } = useParams();
    const { alert } = useToast();

    const [showDetail, setShowDetail] = useState("ShowFlow")
    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setUpdData({ ...updData, paid: "", due: "" })
    }

    const handleShow = (modalItemValue) => {
        console.log("DSDSDS",modalItemValue);
        setUpdData(modalItemValue)
        setShow(true);
        setModalItem(modalItemValue)
    };



    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const repayment_flow = [
        {
            std_name: "Ahmed",
            contact: "0333-1234567",
            email: "abc@gmail.com",
            uni_name: "SSUET",
            last_repayment: 50000,
            total_repayment: 50000,
            outstanding_amt: 50000,
        },
        {
            std_name: "Ahmed",
            contact: "0333-1234567",
            email: "abc@gmail.com",
            uni_name: "SSUET",
            last_repayment: 50000,
            total_repayment: 50000,
            outstanding_amt: 50000,
        },
    ]

    const { hash } = useLocation();
    const navigate = useNavigate();

    // const [show, setShow] = useState("flow");


    // useEffect(() => {
    //     console.log((hash.split("#"))[1] || "flow");
    //     setShow((hash.split("#"))[1] || "flow")
    // }, [hash])


    const tabs = [
        {
            tabName: "Student Detail",
            pathName: "#detail"
        },
        {
            tabName: "Applications / Renewals",
            pathName: "#applications_renewals"
        },
        {
            tabName: "Repayment History",
            pathName: "#repayment_history"
        },
        {
            tabName: "Disbursement History",
            pathName: "#disbursement_history"
        },
    ]

    const [showTab, setShowTab] = useState("");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "");
        setShowTab((hash.split("#"))[1] || "")
    }, [hash])



    const [data, setData] = useState({
        name: "",
        email: "",
        email2: "",
        email3: "",
        phone: "",
        phone2: "",
        phone3: "",
        university: "",
        semester: "",
        rollNo: "",
        outstandingAmount: "",
        totalLoan: "",
        repayment: "",
        type: ""
    })

    const getSTDdata = async () => {
        try {
            const response = await getStudentData({ id: id })
            console.log("DD00NNNEEE", response);
            if (response.success) {
                if (response.message != null) {
                    console.log("gggg",response.message);
                    setData(response?.message)
                    setData({
                        ...data,
                        name: response.message?.studentId?.fullName,
                        email: response.message?.studentId?.email,
                        email2: response.message?.studentId?.email2,
                        email3: response.message?.studentId?.email3,
                        phone: response.message?.studentId.phone,
                        phone2: response.message?.studentId?.phone2,
                        phone3: response.message?.studentId?.phone3,
                        cnic: response.message?.cnic,
                        city: response.message?.city,
                        fatherName: response.message?.fatherName,
                        university: response.message?.university?.fullName,
                        campus: response.message?.campus?.name,
                        program: response.message?.program?.name,
                        semester: response.message?.semester,
                        rollNo: response.message?.rollNo,
                        EnrollNo: response.message?.EnrollNo,
                        outstandingAmount: response.message?.outstandingAmount,
                        totalLoan: response.message?.totalLoan,
                        repayment: response.message?.repayment,
                        renewalDate: response.message?.renewalDate,
                        year: response.message?.year,
                        type: response.message?.type
                    })
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [repay, setRepay] = useState([])
    const getRepayYear = async () => {
        try {
            const response = await getRepaymentYear({ id: id })
            console.log("RRRR", response);
            if (response.success) {
                setRepay(response.message)
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    const [applications, setApplications] = useState([])

    const getApps = async () => {
        try {
            const response = await getStudentsApplications({ userId: id })
            if (response.success) {
                console.log("LLLLL", response);
                setApplications(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp?.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate
        }
        else {
            return "---"
        }
    }

    const [disbursements, setDicbursements] = useState([])
    const getDisbursements = async () => {
        try {
            const response = await getStdTranch({ studentId: id })
            if (response.success) {
                setDicbursements(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getApps()
        getRepayYear()
        getSTDdata()
        getDisbursements()
    }, [])

    function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);

        // Get date components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based
        const year = date.getFullYear();

        // Get time components
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12; // Handle midnight

        // Formatted date and time string
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }



    const [updData, setUpdData] = useState({
        amount: "",
        remainingAmount: "",
        repaymentDate: "",
        modeOfPayment: "",
        chequeNo: "",
        slipNo: "",
        stanNo: "",
        accountNo: "",
        otherDetails: ""
    })

    const handleUpdChange = (e, feild) => {
        setUpdData({ ...updData, [feild]: e.target.value })
    }

    const updateREPAY = async (id) => {
        try {
            const payload = {
                repayId: id,
                paid:updData.amount,
                due:updData.remainingAmount,
                ...updData
            }

            const response = await updateRepaymentInvoice(payload)
            alert(response.message, response.success)

            if (response.success) {
                getRepayYear()
                getSTDdata()
            }
            console.log("PAYLOAD", payload);
            handleClose()
        }
        catch (e) {
            console.log(e);
        }
    }


    return (
        <>
            <Helmet>
                <title>Student Report</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='repayment_flow_section'>

                        {
                            showTab == "flow" &&
                            <div className="card mb-3">
                                <div className='d-flex jc-between sort'>
                                    <h2 className='mb-3'>Repayment Flow</h2>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Filter</label>
                                        </div>
                                        <select class="custom-select form-select" id="inputGroupSelect01">
                                            <option selected value={""}>Choose...</option>
                                            <option value="this month">This Month</option>
                                            <option value="last three months">Last Three Months</option>
                                            <option value="last six months">Last Six Months</option>
                                            <option value="this year">This Year</option>
                                        </select>
                                    </div>

                                </div>

                                <Table head={["Student Name", "Contact", "Email", "University Name", "Last Repayment", "Total Repayment", "Outstanding Amount", "Detail"]}>

                                    {
                                        repayment_flow.map((data, i) => {
                                            return <>
                                                <tr key={i}>
                                                    <td><Text text={data.std_name} /></td>
                                                    <td><Text text={data.contact} /></td>
                                                    <td><Text text={data.email} /></td>
                                                    <td><Text text={data.uni_name} /></td>
                                                    <td><Text text={data.last_repayment} /></td>
                                                    <td><Text text={data.total_repayment} /></td>
                                                    <td><Text text={data.outstanding_amt} /></td>
                                                    <td>
                                                        <button className='btn detail_btn' onClick={() => navigate(`#${"detail"}`)}>View Detail</button>
                                                    </td>
                                                </tr>
                                            </>
                                        })
                                    }

                                </Table>

                                <Pagination itemsPerPage={4} />

                            </div>
                        }


                        <div className='d-flex jc-between ai-center mb-3'>
                            {/* <BiArrowBack className='back_arrow_icon' onClick={() => navigate(`#${"flow"}`)} /> */}
                        </div>

                        <div className='d-flex ai-center mb-4'>
                            {/* <Link to="/all-users/students-list">
                                <IoArrowBack className='back_arrow_icon me-3' />
                            </Link> */}
                            <h2 className='mb-0'>Student Report</h2>
                        </div>


                        <div className="card mb-4">

                            <Tabs tabLinks={tabs} show={showTab} setShow={setShowTab} />

                            {
                                (showTab == "" || showTab == "detail") &&

                                <div className="">

                                    <h4>Student Detail</h4>
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Student Name" value={data?.name} inputType="text" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Email" value={data?.email} inputType="text" class="text-transform-none" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Father Name" value={data.fatherName} inputType="text" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Contact" value={data?.phone} inputType="text" />
                                                </div>
                                            </div>

                                            {
                                                (data.phone2 && data.phone2 !== "" && data.phone2 !== "+92" && data.phone2 !== null) && (
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className='form-group'>
                                                            <Input disabled label="Contact (Optional)" value={data?.phone2} inputType="text" />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            {
                                                (data.phone3 && data.phone3 !== "" && data.phone3 !== "+92" && data.phone3 !== null) && (
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className='form-group'>
                                                            <Input disabled label="Contact (Optional)" value={data?.phone3} inputType="text" />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                (data.email2 && data.email2 !== "" && data.email2 !== null) && (
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className='form-group'>
                                                            <Input disabled label="Email" value={data?.email2} inputType="text" class="text-transform-none" />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                (data.email3 && data.email3 !== "" && data.email3 !== null) && (
                                                    <div className="col-lg-4 col-md-6">
                                                        <div className='form-group'>
                                                            <Input disabled label="Email" value={data?.email3} inputType="text" class="text-transform-none" />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="CNIC" value={data.cnic} inputType="text" />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="City" value={data.city} inputType="text" />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="University" value={data?.university} inputType="text" />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Campus" value={data.campus} inputType="text" />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Program" value={data.program} inputType="text" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Examination Structure" value={data?.type} inputType="text" />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-3">
                                                <div className='form-group'>
                                                    <Input disabled label="Year" value={data?.year} inputType="text" />
                                                </div>
                                            </div> */}

                                            <div className="col-lg-6 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Enrollment No." value={data?.EnrollNo} inputType="text" />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-3">
                                                <div className='form-group'>
                                                    <Input disabled label="Reg No. / Enrollment No." value={data?.rollNo} inputType="text" />
                                                </div>
                                            </div> */}

                                            {/* <div className="col-md-4">
                                                <div className='form-group'>
                                                    <Input disabled label="Last Repayment" value={data?.repayment} inputType="text" />
                                                </div>
                                            </div> */}

                                            <div className="col-lg-6 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Total Loan" value={formatter(data?.totalLoan)} inputType="text" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className='form-group'>
                                                    <Input disabled label="Outstanding Amount" value={formatter(data?.outstandingAmount)} inputType="text" />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-4">
                                                <div className='form-group'>
                                                    <Input disabled label="Renewal Date" value={data?.renewalDate} inputType="text" />
                                                </div>
                                            </div> */}

                                        </div>
                                    </form>

                                </div>
                            }


                            {
                                showTab == "applications_renewals" &&

                                <div className="">

                                    <h4>Applications / Renewals</h4>
                                    {
                                        applications.length > 0 ?
                                            <Table head={["Student Name", "University Name", "Program", "Status", "Submission Date", "App. Sem/Year", "Type"]}>
                                                {
                                                    applications?.map((data, i) => {
                                                        return <tr key={i}>
                                                            <td><Text text={data?.userId?.fullName || "---"} /></td>
                                                            <td><Text text={data?.university?.fullName || data?.Institute?.fullName} /></td>
                                                            <td><Text text={data?.program?.name || data?.Program?.name} /></td>
                                                            {
                                                                data.Institute ?
                                                                    <td><Badge text={data?.RenewStatus === "pending" ? "Processing" : data?.RenewStatus || "---"} classes={`${data?.RenewStatus} md`} /></td>
                                                                    :
                                                                    <td><Badge text={data?.status === "pending" ? "Processing" : data?.status || "---"} classes={`${data?.status} md`} /></td>
                                                            }
                                                            <td><Text text={timestampToDate(data?.timestamp) || "---"} /></td>
                                                            <td><Text text={data?.form2?.currentSem || data?.sem_year} /></td>
                                                            {
                                                                data.Institute ?
                                                                    <td><Text text="Renewal Form" /></td>
                                                                    :
                                                                    <td><Text text="Application Form" /></td>
                                                            }
                                                            {
                                                                data.Institute ?
                                                                    <td>
                                                                        <Link to={`/renewal-cases-history/${data?.userId._id}/${data?._id}#cases-detail`} className='link'>
                                                                            <button className='btn save_btn'>Case Detail</button>
                                                                        </Link>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <Link to={`/fresh-cases-history/${data?.userId?._id}/${data._id}#cases-detail`} className='link'>
                                                                            <button className='btn save_btn'>Case Detail</button>
                                                                        </Link>
                                                                    </td>
                                                            }


                                                        </tr>
                                                    })
                                                }
                                            </Table>
                                            :
                                            <h5 className='not_found_msg'>No Records Found</h5>
                                    }
                                </div>

                            }


                            {
                                showTab == "repayment_history" &&
                                <>
                                    <h4>Repayment History</h4>
                                    {
                                        repay.length > 0 ?
                                            <div className="row">
                                                {
                                                    repay.length > 0 && repay.map((item, itemIndex) => (
                                                        <div className="col-md-12" key={itemIndex}>
                                                            <div className="card mb-4">
                                                                <h4>{item?.year}</h4>
                                                                <Table head={["Month", "Amount", "Paid Amount", "Due Amount", "Status", "Repayment Date", "Mode of Payment", "Payment Details", "Date"]}>
                                                                    {
                                                                        item?.data.map((item2, i) => (
                                                                            <tr key={i} className={`${item2?.remainingAmount == 0 ? "complete" : "incomplete"}`}>
                                                                                <td><Text text={item2?.month} /></td>
                                                                                <td><Text text={parseFloat(item2?.amount) + parseFloat(item2?.remainingAmount)} /></td>
                                                                                <td><Text text={item2?.amount} /></td>
                                                                                <td><Text text={item2?.remainingAmount} /></td>
                                                                                <td><Badges text={item2?.remainingAmount == 0 ? "complete" : "incomplete" || "---"} classes={`${item2?.remainingAmount == 0 ? "complete" : "incomplete"} md`} /></td>
                                                                                <td><Text text={item2?.repaymentDate} /></td>
                                                                                <td><Text text={item2?.modeOfPayment} /></td>
                                                                                <td><Text text={item2?.chequeNo || item2.slipNo || item2?.stanNo || item2?.accountNo || item2?.otherDetails} /></td>
                                                                                <td><Text text={formatDateTime(item2?.datetime)} /></td>
                                                                                <td>
                                                                                    {
                                                                                        (user?.role == "admin" || user?.role == "operations" || user?.role == "repayment") && (
                                                                                            <td>
                                                                                                <UserModal btnText={<FaEdit />} heading="Edit Repayment" id={item2} customClasses="edit_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12">
                                                                                                            <div className="form-group">
                                                                                                                <Input
                                                                                                                    inputType="number"
                                                                                                                    label="Paid Amount"
                                                                                                                    placeholder="Enter here"
                                                                                                                    value={updData.amount }
                                                                                                                    onChange={(e) => handleUpdChange(e, 'amount')}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-12">
                                                                                                            <div className="form-group">
                                                                                                                <Input
                                                                                                                    inputType="number"
                                                                                                                    label="Due Amount"
                                                                                                                    placeholder="Enter here"
                                                                                                                    value={updData.remainingAmount }
                                                                                                                    onChange={(e) => handleUpdChange(e, 'remainingAmount')}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-12">
                                                                                                            <div className="form-group">
                                                                                                                <Input
                                                                                                                    inputType="date"
                                                                                                                    label="Repayment Date"
                                                                                                                    placeholder="Enter here"
                                                                                                                    value={updData.repaymentDate}
                                                                                                                    onChange={(e) => handleUpdChange(e, 'repaymentDate')}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-12">
                                                                                                            <div className="form-group">
                                                                                                                <label className='form-label'>Mode of Payment</label>
                                                                                                                <select
                                                                                                                    value={updData?.modeOfPayment}
                                                                                                                    onChange={(event) => handleUpdChange(event, 'modeOfPayment')}
                                                                                                                    className="form-select" required={true}>
                                                                                                                    <option selected disabled >Select Mode of Payment</option>
                                                                                                                    <option value="Cheque">Cheque</option>
                                                                                                                    <option value="Deposit">Deposit</option>
                                                                                                                    <option value="IBFT">IBFT</option>
                                                                                                                    <option value="Internal transfer from Meezan Bank">Internal transfer from Meezan Bank</option>
                                                                                                                    <option value="Easypaisa/Jazzcash">Easypaisa/Jazzcash</option>
                                                                                                                    <option value="other">other</option>
                                                                                                                </select>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            (updData.modeOfPayment !== "" ) && (
                                                                                                                <div className="col-12">
                                                                                                                    <div className="form-group">
                                                                                                                        {
                                                                                                                            (updData?.modeOfPayment == "Cheque" && (
                                                                                                                                <Input
                                                                                                                                    span="*"
                                                                                                                                    inputType="text"
                                                                                                                                    label="Cheque No"
                                                                                                                                    placeholder="Enter here"
                                                                                                                                    value={updData.chequeNo}
                                                                                                                                    onChange={(event) => handleUpdChange(event, 'chequeNo')}
                                                                                                                                    required={true}
                                                                                                                                />
                                                                                                                            )
                                                                                                                            )
                                                                                                                        }
                                                                                                                        {
                                                                                                                            ((updData?.modeOfPayment == "Deposit")) && (
                                                                                                                                <Input
                                                                                                                                    span="*"
                                                                                                                                    inputType="text"
                                                                                                                                    label="Slip No"
                                                                                                                                    placeholder="Enter here"
                                                                                                                                    value={updData.slipNo}
                                                                                                                                    onChange={(event) => handleUpdChange(event, 'slipNo')}
                                                                                                                                    required={true}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                        {
                                                                                                                            ((updData.modeOfPayment == "Internal transfer from Meezan Bank" && updData?.modeOfPayment == "Internal transfer from Meezan Bank") ) && (
                                                                                                                                <Input
                                                                                                                                    span="*"
                                                                                                                                    inputType="text"
                                                                                                                                    label="Account No"
                                                                                                                                    placeholder="Enter here"
                                                                                                                                    value={updData.accountNo }
                                                                                                                                    onChange={(event) => handleUpdChange(event, 'accountNo')}
                                                                                                                                    required={true}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                        {
                                                                                                                            (((updData?.modeOfPayment == "IBFT" || updData?.modeOfPayment == "Easypaisa/Jazzcash")) ) && (
                                                                                                                                <Input
                                                                                                                                    span="*"
                                                                                                                                    inputType="text"
                                                                                                                                    label="Stan No"
                                                                                                                                    placeholder="Enter here"
                                                                                                                                    value={updData.stanNo }
                                                                                                                                    onChange={(event) => handleUpdChange(event, 'stanNo')}
                                                                                                                                    required={true}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                        {
                                                                                                                            ( updData.modeOfPayment == "other") && (
                                                                                                                                <Input
                                                                                                                                    inputType="text"
                                                                                                                                    label="Details"
                                                                                                                                    placeholder="Enter here"
                                                                                                                                    value={updData.otherDetails}
                                                                                                                                    onChange={(event) => handleUpdChange(event, 'otherDetails')}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                        <div className='d-flex jc-end'>
                                                                                                            <button onClick={() => updateREPAY(item2?._id)} className='btn save_btn'>Save</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </UserModal>
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <h5 className='not_found_msg'>No Records Found</h5>
                                    }


                                </>
                            }

                            {
                                showTab == "disbursement_history" &&
                                <>
                                    <div className="">
                                        <h4>Disbursement History</h4>
                                        {
                                            disbursements?.length > 0 ?
                                                <Table head={["Student Name", "University Name", "Program", "Tranch", "Amount", "Status", "Case Type", "Date",]}>
                                                    {
                                                        disbursements?.map((data, i) => {
                                                            return <tr key={i}>
                                                                <td><Text text={data?.userId?.fullName || "---"} /></td>
                                                                <td><Text text={data?.institute?.fullName} /></td>
                                                                <td><Text text={data?.program?.name} /></td>
                                                                <td><Text text={data?.tranchNo} /></td>
                                                                <td><Text text={formatter(data?.amount)} /></td>
                                                                <td><Badges text={data?.status} classes={`${data?.status} md`} /></td>
                                                                <td><Text text={data?.type} /></td>
                                                                <td><Text text={timestampToDate(data?.timestamp) || "---"} /></td>
                                                            </tr>
                                                        })
                                                    }
                                                </Table>
                                                :
                                                <h5 className='not_found_msg'>No Records Found</h5>

                                        }

                                        {/* <Pagination itemsPerPage={4} /> */}

                                    </div>

                                </>
                            }
                        </div>

                    </section>
                </div>
            </div >
        </>
    )
}

export default StudentReport
