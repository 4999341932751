import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import UserModal from '../../components/Modals/Modal';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import { AGING } from "../../ContextAPI/APIs/api"
import { useEffect } from 'react';
import formatter from '../../helpers/number_formatter';
import { useToast } from '../../ContextAPI/Components/toast';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';


function AgingAnalysis() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const { alert } = useToast();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };


    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        fetch(skip)
        // sear ch(skip)
    }

    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = currentYear; year >= 2010; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }


    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    // Handler function to handle option change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        // Do something with the selected year
        setYear(event.target.value)

        console.log("Selected year:", selectedYear);
    };

    const handleMonthChange = (event) => {
        // Do something with the selected year
        setMonth(event.target.value)

    };

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState({})
    const fetAging = async (e) => {
        try {
            e.preventDefault()

            const response = await AGING({ month: month, year: year })

            console.log("FFDFDSDDD", response);
            if (response.success && response.message.length > 0) {
                setData(response?.message)
                setTotalData(response?.lastRow)


            }

            if (!response.success) {
                alert(response.message, response.success)
                setData([])
                setTotalData({})

            }
            setShow(false)
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        // fetAging()


        let allTRs = Array.from(document.querySelectorAll("tr"))
        allTRs.shift()
        allTRs.pop()
        allTRs.pop()

        allTRs.forEach((tr, i) => {
            console.log(tr)
            if (i % 2 != 0) {
                tr.children[0].style.background = ("white")
                tr.children[0].style.opacity = (1)

                tr.children[1].style.background = ("white")
                tr.children[1].style.opacity = (1)
            }
        })

    }, [data])


    return (
        <>
            <Helmet>
                <title>Aging Analysis</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section aging_analysis'>

                        <div className='d-flex jc-between ai-center mb-4 flex_heading_button'>

                            <div className='d-flex ai-center'>
                                <Link to="/reporting">
                                    <IoArrowBack className='back_arrow_icon me-3' />
                                </Link>
                                <h2 className='mb-0'>Aging Analysis</h2>
                            </div>

                            <div className='d-flex jc-end buttons'>
                                <UserModal btnText={"Filter"} heading="Filter" id={"filter"} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Month <span className='required'>*</span></label>
                                                <select className='form-select' value={month} id="yearSelect" onChange={handleMonthChange}>
                                                    <option selected disabled value="">Select Month </option>
                                                    <option value="January">January</option>
                                                    <option value="February">February</option>
                                                    <option value="March">March</option>
                                                    <option value="April">April</option>
                                                    <option value="May">May</option>
                                                    <option value="June">June</option>
                                                    <option value="July">July</option>
                                                    <option value="August">August</option>
                                                    <option value="September">September</option>
                                                    <option value="October">October</option>
                                                    <option value="November">November</option>
                                                    <option value="December">December</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Year <span className='required'>*</span></label>
                                                <select value={year} className='form-select' id="yearSelect" onChange={handleYearChange}>
                                                    <option selected disabled value="">Select Year</option>
                                                    {yearOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='d-flex jc-end'>
                                            <button onClick={(e) => fetAging(e)} className='btn save_btn'>Save</button>
                                        </div>
                                    </div>
                                </UserModal>
                            </div>
                        </div>

                        <div className="card">
                            {
                                data.length > 0 ?
                                    <Table
                                        classFixed="table-fixed-first"
                                        class="table-striped"
                                        head={[
                                            "S.No",
                                            "Universities",
                                            "Prompt",
                                            "1 Month",
                                            "2 Months",
                                            "3 Months",
                                            "4 Months",
                                            "5 Months",
                                            "6 Months",
                                            "7-9 Months",
                                            "1 Year (10-12 Months)",
                                            "1.5 Year (13-18 Months)",
                                            "2 Years (19-24 Months)",
                                            "3 Years (25-36 Months)",
                                            "4 years",
                                            "5 years",
                                            "6 years",
                                            "6 years + ",
                                            "Total no. of N.P Students",
                                            "Overall  Students",
                                            "Total Outstanding Amount"
                                        ]}>
                                        {
                                            data.map((item, i) => (
                                                <>
                                                    <tr>
                                                        <td><Text text={i + 1} /></td>
                                                        <td><Text text={item?.uniName} /></td>
                                                        <td><Text text={item?.promptStd} /></td>
                                                        <td><Text text={item?.m1} /></td>
                                                        <td><Text text={item?.m2} /></td>
                                                        <td><Text text={item?.m3} /></td>
                                                        <td><Text text={item?.m4} /></td>
                                                        <td><Text text={item?.m5} /></td>
                                                        <td><Text text={item?.m6} /></td>
                                                        <td><Text text={item?.m7t9} /></td>
                                                        <td><Text text={item?.m10t12} /></td>
                                                        <td><Text text={item?.m13t18} /></td>
                                                        <td><Text text={item?.m19t24} /></td>
                                                        <td><Text text={item?.m25t36} /></td>
                                                        <td><Text text={item?.m48} /></td>
                                                        <td><Text text={item?.m60} /></td>
                                                        <td><Text text={item?.m72} /></td>
                                                        <td><Text text={item?.y6p} /></td>
                                                        <td><Text text={item?.nonPaying} /></td>
                                                        <td><Text text={item?.overAll} /></td>
                                                        <td><Text text={"---"} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><Text text={""} /></td>
                                                        <td><Text text={item?.uniName} /></td>
                                                        <td><Text text={"---"} /></td>
                                                        <td><Text text={formatter(item?.p1)} /></td>
                                                        <td><Text text={formatter(item?.p2)} /></td>
                                                        <td><Text text={formatter(item?.p3)} /></td>
                                                        <td><Text text={formatter(item?.p4)} /></td>
                                                        <td><Text text={formatter(item?.p5)} /></td>
                                                        <td><Text text={formatter(item?.p6)} /></td>
                                                        <td><Text text={formatter(item?.p7t9)} /></td>
                                                        <td><Text text={formatter(item?.p10t12)} /></td>
                                                        <td><Text text={formatter(item?.p13t18)} /></td>
                                                        <td><Text text={formatter(item?.p19t24)} /></td>
                                                        <td><Text text={formatter(item?.p25t36)} /></td>
                                                        <td><Text text={formatter(item?.p48)} /></td>
                                                        <td><Text text={formatter(item?.p60)} /></td>
                                                        <td><Text text={formatter(item?.p72)} /></td>
                                                        <td><Text text={formatter(item?.y6pp)} /></td>
                                                        <td><Text text={"---"} /></td>
                                                        <td><Text text={"---"} /></td>
                                                        <td><Text text={formatter(item?.totalOutstanding)} /></td>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                        {
                                            totalData && (
                                                <>

                                                    <tr style={{ backgroundColor: "rgba(94, 207, 255, 0.1)" }}>
                                                        <td><Text text={"Total Students"} /></td>
                                                        <td><Text text={""} /></td>
                                                        <td><Text text={totalData.totalpromptStd} /></td>
                                                        <td><Text text={totalData.totalm1} /></td>
                                                        <td><Text text={totalData.totalm2} /></td>
                                                        <td><Text text={totalData.totalm3} /></td>
                                                        <td><Text text={totalData.totalm4} /></td>
                                                        <td><Text text={totalData.totalm5} /></td>
                                                        <td><Text text={totalData.totalm6} /></td>
                                                        <td><Text text={totalData.totalm7t9} /></td>
                                                        <td><Text text={totalData.totalm10t12} /></td>
                                                        <td><Text text={totalData.totalm13t18} /></td>
                                                        <td><Text text={totalData.totalm19t24} /></td>
                                                        <td><Text text={totalData.totalm25t36} /></td>
                                                        <td><Text text={totalData.totalm48} /></td>
                                                        <td><Text text={totalData.totalm60} /></td>
                                                        <td><Text text={totalData.totalm72} /></td>
                                                        <td><Text text={totalData.totaly6p} /></td>
                                                        <td><Text text={totalData.totalnonPaying} /></td>
                                                        <td><Text text={totalData.totaloverAll} /></td>
                                                        <td></td>

                                                    </tr>
                                                    <tr style={{ backgroundColor: "rgba(94, 207, 255, 0.1)" }}>
                                                        <td><Text text={"Total Amount"} /></td>
                                                        <td><Text text={""} /></td>
                                                        <td><Text text={""} /></td>
                                                        <td><Text text={formatter(totalData.totalp1)} /></td>
                                                        <td><Text text={formatter(totalData.totalp2)} /></td>
                                                        <td><Text text={formatter(totalData.totalp3)} /></td>
                                                        <td><Text text={formatter(totalData.totalp4)} /></td>
                                                        <td><Text text={formatter(totalData.totalp5)} /></td>
                                                        <td><Text text={formatter(totalData.totalp6)} /></td>
                                                        <td><Text text={formatter(totalData.totalp7t9)} /></td>
                                                        <td><Text text={formatter(totalData.totalp10t12)} /></td>
                                                        <td><Text text={formatter(totalData.totalp13t18)} /></td>
                                                        <td><Text text={formatter(totalData.totalp19t24)} /></td>
                                                        <td><Text text={formatter(totalData.totalp25t36)} /></td>
                                                        <td><Text text={formatter(totalData.totalp48)} /></td>
                                                        <td><Text text={formatter(totalData.totalp60)} /></td>
                                                        <td><Text text={formatter(totalData.totalp72)} /></td>
                                                        <td><Text text={formatter(totalData.totaly6pp)} /></td>
                                                        <td><Text text={""} /></td>
                                                        <td><Text text={""} /></td>
                                                        <td><Text text={formatter(totalData.totaltotalOutstanding)} /></td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                    </Table>
                                    :
                                    <h5 className='not_found_msg'>No record found</h5>
                            }
                        </div>


                    </section>
                </div>
            </div>
        </>
    )
}

export default AgingAnalysis
