import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useProSidebar } from 'react-pro-sidebar';
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import CountUp from 'react-countup';
import TooltipComponent from '../../components/tooltip';
import { IoCalendarOutline } from 'react-icons/io5';
import { TfiBarChart } from 'react-icons/tfi';
import { PiStudent, PiStudentFill } from 'react-icons/pi';
import { CgProfile } from 'react-icons/cg';
import Table from '../../components/Table/Table';
import IconText from '../../components/Table/IconText';
import Text from '../../components/Table/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { getProgram, getStdCount, universityFilter, getUniMOU } from '../../ContextAPI/APIs/api'
import { Show_Profile_Pic } from '../../components/Profile/upload_profilePic';


function UniversityDashboard() {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const [selectedProgram, setSelectedProgram] = useState([])
    const student_history = [
        {
            name: "Ahmed",
            roll_no: "2244",
            campus: "One",
            program: "SE",
            outstanding_amt: 2500,
        },
        {
            name: "Asad",
            roll_no: "2244",
            campus: "One",
            program: "SE",
            outstanding_amt: 2500,
        },
    ]


    const [stdList, setStdList] = useState([])
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [show, setShow] = useState("std_history");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "std_history");
        setShow((hash.split("#"))[1] || "std_history")
    }, [hash])

    const [count, setCount] = useState(0)

    const stdCount = async () => {
        try {
            setSelectedProgram([])

            const response = await getStdCount()
            console.log("AAA", response);
            if (response.success) {
                console.log("EEEE", response.message);
                setStdList(response.message)
                setCount(response.message.length)
            }


        }
        catch (e) {
            console.log(e);
        }
    }


    const [prog, setProg] = useState([])
    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {
            const abc = selectedProgram.filter((uniId) => uniId !== id)

            // setSelectedProgram((prevSelectedUni) => prevSelectedUni.filter((uniId) => uniId !== id));
            setSelectedProgram(abc,)
            search(abc)
        } else {
            const upd = [...selectedProgram, id]

            // setSelectedProgram((prevSelectedUni) => [...prevSelectedUni, id]);
            setSelectedProgram(upd);
            search(upd)

        }
    };

    const search = async (prog) => {
        try {

            const payload = {
                program: prog,
            }
            const response = await universityFilter(payload)
            if (response.success) {
                console.log("FILTERR", response.message);
                // setAllUsers(response.message)
                setStdList(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const [mou, setMOU] = useState({
        MOU_Date: "",
        expiry: "",
        total: "",
        freshLimit: "",
        renewalLimit: "",
        freshConsumed: "",
        RenewalConsumed: "",
    })

    const getMOUDATA = async () => {
        try {
            const response = await getUniMOU()
            console.log("MOMO", response);

            if (response.success) {
                setMOU(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function formatDate(inputDate) {
        if (!inputDate || inputDate === "") {
            return "MOU not signed"
        }
        else {
            var parts = inputDate.split('-');
            return parts[2] + '-' + parts[1] + '-' + parts[0];
        }
    }


    useEffect(() => {
        getMOUDATA()
        stdCount()
        getAllProg()
    }, [])

    return (
        <>
            <Helmet>
                <title>Ihsan Trust | University Dashboard</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='university_dashboard_section'>

                        <h2>Dashboard</h2>

                        <div className="grid_row_3 mb-4">
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div>
                                            <IoCalendarOutline className='grid_icon' style={{ color: "#28a745" }} />
                                            <p>MOU Date</p>
                                        </div>
                                        <div>
                                            <h4><span>{formatDate(mou.MOU_Date) || "NA"}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div>
                                            <IoCalendarOutline className='grid_icon' style={{ color: "#17a2b8" }} />
                                            <p>Renewal Date</p>
                                        </div>
                                        <div>
                                            <h4><span>{mou.expiry || "NA"}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div>
                                            <IoCalendarOutline className='grid_icon' style={{ color: "#6c757d" }} />
                                            <p>Expiry Date</p>
                                        </div>
                                        <div>
                                            <h4><span>{formatDate(mou.expiry) || "NA"}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div>
                                            <TfiBarChart className='grid_icon' style={{ color: "#28a745" }} />
                                            <p>Total Limit </p>
                                        </div>
                                        <div>
                                            <h4>
                                                <span><CountUp prefix="Rs. " start={0} end={parseInt(mou.total)} decimal="." decimals={0} duration={1.75} /></span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div>
                                            <TfiBarChart className='grid_icon' style={{ color: "#FF9325" }} />
                                            <p>Consumed Limit</p>
                                        </div>
                                        <div>
                                            <h4>
                                                <span><CountUp prefix="Rs. " start={0} end={parseInt(mou.RenewalConsumed + mou.freshConsumed)} decimal="." decimals={0} duration={1.75} /></span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div>
                                            <PiStudentFill className='grid_icon' style={{ color: "#924AEF" }} />
                                            <p>No. Of Students</p>
                                        </div>
                                        <div>
                                            <h4>
                                                <span><CountUp start={0} end={count} decimal="." decimals={0} duration={1.75} /></span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            show == "std_history" &&
                            <>
                                <div className="card">

                                    <h4>Students</h4>

                                    <div className="filter d-flex ai-center jc-between mb-3">
                                        <div className='d-flex ai-center'>
                                            <p>Filter By</p>

                                            <div className="dropdown">
                                                <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Programs
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <div className='uni_filter_header d-flex jc-between'>
                                                        <p>Name</p>
                                                        <p className='me-0'>Filter</p>
                                                    </div>
                                                    <div className='uni_filter_body'>

                                                        {
                                                            prog.map((item, i) => (
                                                                <li key={i}>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.name}</label>
                                                                        {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                            id={`uniCheckbox_${item._id}`}
                                                                            onChange={() => handleProgramChange(item._id)}
                                                                            checked={selectedProgram.includes(item._id)}
                                                                        />

                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            selectedProgram.length > 0 && (
                                                <div className='d-flex'>
                                                    <button onClick={() => stdCount()} className='btn clear_btn'>Clear</button>
                                                </div>
                                            )
                                        }
                                    </div>

                                    {
                                        stdList.length > 0 ?
                                            <>
                                                <Table head={["Name", "Father Name", "Enrollment No.", "Campus", "Program"]}>
                                                    {
                                                        stdList.map((student, i) => {
                                                            return <tr key={i}>
                                                                <td>
                                                                    {/* <div className='d-flex ai-center'>
                                                                        <TooltipComponent className="" placement="top" tooltipText="View Profile">
                                                                            <div onClick={() => navigate("/student-report#detail")} >
                                                                                <Show_Profile_Pic src={student?.profilepic || null} classes="profile-image sm" />
                                                                            </div>
                                                                        </TooltipComponent>
                                                                        <IconText text={student.studentId.fullName} />
                                                                    </div> */}
                                                                    <Text text={student.studentId.fullName} />
                                                                </td>
                                                                <td><Text text={student.fatherName} /></td>
                                                                <td><Text text={student.EnrollNo} /></td>
                                                                <td><Text text={student.campus.name} /></td>
                                                                <td className='text-capital'><Text text={student.program.name} /></td>
                                                            </tr>
                                                        })
                                                    }
                                                </Table>

                                                <Pagination itemsPerPage={4} />
                                            </>
                                            :
                                            <h5 className='not_found_msg'>No Records Found</h5>
                                    }

                                </div>
                            </>
                        }
                    </section>
                </div >
            </div >
        </>
    )
}

export default UniversityDashboard
