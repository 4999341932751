import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import UserModal from '../../components/Modals/Modal';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import EmailText from '../../components/Table/EmailText';
import { AGING, Mastersheet, getAllUniversities } from "../../ContextAPI/APIs/api"
import { useEffect } from 'react';
import formatter from '../../helpers/number_formatter';
import { useToast } from '../../ContextAPI/Components/toast';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import Pagination from '../../components/Pagination';
import Badge from '../../components/Badges';
// import formatter from '../../helpers/number_formatter';



function MasterSheet() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const { alert } = useToast();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };




    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = currentYear; year >= 2010; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }


    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    // Handler function to handle option change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        // Do something with the selected year
        setYear(event.target.value)

        console.log("Selected year:", selectedYear);
    };

    const handleMonthChange = (event) => {
        // Do something with the selected year
        setMonth(event.target.value)

    };

    const [data, setData] = useState([])
    const [TableHead, setTableHead] = useState([])
    const [ths, setThs] = useState([])

    const fetchMastersheet = async (unis, skip) => {
        try {

            const response = await Mastersheet({
                uni: unis
            }, skip)

            console.log("FFDFDSDDD--->", response);

            if (response.success && response.temp.length > 0) {
                setData(response.temp)
                setTotalCount(response.totalCount)

                const th1 = ["S.No", "Student Name", "Institute", "Batch", "Cnic", "Contact", "Email",]
                const th2 = ["Total Repayemnt", "Total Outstanding", "Payment Settled"]

                const ab = [...th1, ...response.tableHead, ...th2]
                console.log("jkjdnsjd", ab);
                setTableHead(ab)

                setThs(response.tableHead)
            }

            if (!response.success) {
                alert(response.message, response.success)
                setData([])
                setTableHead([])
            }
            setShow(false)
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        fetchMastersheet(selectedUni, pageSkip)
        getAllunis()
    }, [])




    function getTimestamps(monthYear) {
        const date = new Date(monthYear);
        const year = date.getFullYear();
        const month = date.getMonth();

        // Get the first day of the month
        const firstDay = new Date(year, month, 1);
        const firstDayTimestamp = firstDay.getTime();

        // Get the last day of the month
        const lastDay = new Date(year, month + 1, 0); // Setting day to 0 goes back to the last day of the previous month
        const lastDayTimestamp = lastDay.getTime();

        return {
            firstDayTimestamp,
            lastDayTimestamp
        };
    }



    const generateTableRows = (data, data1) => {
        const rows = [];
        let yearlyDisbursements = 0;
        let yearlyRepayments = 0;
        let totalDisbursements = 0;
        let totalRepayments = 0;

        // disabled={fieldName.name.startsWith("total") ? true : false}
        for (let i = 0; i < ths.length / 2; i++) {

            if (data.length > 0) {

                if (ths[i].startsWith("Total")) {
                    rows.push(
                        <td>< Text text={formatter(yearlyDisbursements) } /></td>
                    );
                    yearlyDisbursements = 0
                }
                else {
                    let time = getTimestamps(ths[i])

                    let j = data.find((item) => item?.payDate >= time.firstDayTimestamp && item?.payDate <= time.lastDayTimestamp)

                    if (j == undefined) {
                        rows.push(
                            <td>< Text text={"---"} /></td>
                        );
                    }
                    else {
                        rows.push(
                            <td>< Text text={formatter(j.amount) } /></td>
                        );
                        yearlyDisbursements += j.amount
                        totalDisbursements += j.amount
                    }
                }
            }
            else {
                if (ths[i].startsWith("Total")) {
                    rows.push(
                        <td>< Text text={0} /></td>
                    );
                }
                else {
                    rows.push(
                        <td>< Text text={"---"} /></td>
                    );
                }
            }
        }




        for (let i = 0; i < ths.length / 2; i++) {

            if (data1.length > 0) {
                if (ths[(ths.length / 2) + i].startsWith("Total")) {
                    rows.push(
                        <td>< Text text={formatter(yearlyRepayments) } /></td>
                    );
                    yearlyRepayments = 0
                }
                else {
                    let f = data1.find((item) => item.month == ths[(ths.length / 2) + i])
                    console.log("FFF", f);
                    if (f == undefined) {
                        rows.push(
                            <td>< Text text={"---"} /></td>
                        );
                    }
                    else {
                        rows.push(
                            <td>< Text text={formatter(f.amount) } /></td>
                        );
                        yearlyRepayments += f.amount
                        totalRepayments += f.amount
                    }
                }
            }
            else {

                if (ths[(ths.length / 2) + i].startsWith("Total")) {
                    rows.push(
                        <td>< Text text={0} /></td>
                    );
                }
                else {
                    rows.push(
                        <td>< Text text={"---"} /></td>
                    );
                }
            }
        }

        rows.push(
            <td>< Text text={formatter(totalRepayments) } /></td>
        );
        rows.push(
            <td>< Text text={formatter(totalDisbursements - totalRepayments) } /></td>
        );
        rows.push(
            <td>< Badge text={totalDisbursements - totalRepayments <= 0 ? "TRUE" : "FALSE"} classes={`${totalDisbursements - totalRepayments <= 0 ? "TRUE" : "FALSE"} md`} /></td>
        );

        return rows;
    };



    const [selectedUni, setSelectedUni] = useState([])
    const [totalCount, setTotalCount] = useState(4)

    const [uni, setUni] = useState([])
    const getAllunis = async () => {
        try {
            const response = await getAllUniversities()
            if (response.success) {
                console.log("AAAAA", response);
                setUni(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const handleUniChange = (id) => {
        const isSelected = selectedUni.includes(id);
        if (isSelected) {
            const abc = selectedUni.filter((uniId) => uniId !== id)

            setSelectedUni(abc);

            // search(abc, selectedProgram, selectedStatus, 0)
            fetchMastersheet(abc, 0)

        } else {
            const upd = [...selectedUni, id]

            setSelectedUni(upd);
            // search(upd, selectedProgram, selectedStatus, 0)
            fetchMastersheet(upd, 0)
        }
    };

    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        // search(selectedUni, selectedProgram, selectedStatus, skip)
        fetchMastersheet(selectedUni, skip)
    }


    const clear = async () => {
        try {

            console.log("selectedUni", selectedUni);
            setSelectedUni([])
            fetchMastersheet([], 0)

        }
        catch (e) {
            console.log(e);
        }

    }



    return (
        <>
            <Helmet>
                <title>Mastersheet</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section aging_analysis'>

                        <div className='d-flex jc-between ai-center mb-4'>

                            <div className='d-flex ai-center'>
                                <Link to="/reporting">
                                    <IoArrowBack className='back_arrow_icon me-3' />
                                </Link>
                                <h2 className='mb-0'>Mastersheet</h2>
                            </div>


                        </div>

                        <div className="card">

                            <div className="filter d-flex ai-center jc-between mb-3">
                                <div className='d-flex ai-center'>
                                    <p>Filter By</p>

                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Universities
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>
                                                {
                                                    uni.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.fullName}</label>
                                                                {/* <input className="form-check-input" onChange={() => handleUniChange()} type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleUniChange(item._id)}
                                                                    checked={selectedUni.includes(item._id)}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>


                                </div>
                                <div className='d-flex'>
                                    {
                                        (selectedUni.length > 0) && (
                                            <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                        )
                                    }
                                    {/* <button onClick={() => search()} className='btn clear_btn ml-2'>Search</button> */}
                                </div>
                            </div>

                            {
                                data.length > 0 && TableHead.length > 0 ?
                                    <>
                                        <Table
                                            classFixed="table"
                                            class="table-striped"
                                            head={TableHead}>
                                            {
                                                data.map((item, i) => (
                                                    <>
                                                        <tr>
                                                            <td><Text text={(pageSkip * 10) + i + 1} /></td>
                                                            <td><Text text={item?.user?.studentId?.fullName} /></td>
                                                            <td><Text text={item?.user?.university?.fullName} /></td>
                                                            <td><Text text={"--"} /></td>
                                                            <td><Text text={item?.user?.cnic} /></td>
                                                            <td><Text text={item?.user?.studentId?.phone} /></td>
                                                            <td><EmailText text={item?.user?.studentId?.email} /></td>
                                                            {generateTableRows(item?.disbursements, item?.repayments)}
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No record found</h5>
                            }
                        </div>


                    </section>
                </div>
            </div>
        </>
    )
}

export default MasterSheet
