import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../ContextAPI/Components/auth"
import { RenewalUrl } from '../../helpers/data';
import { getrenewal } from '../../ContextAPI/APIs/api';
import { FileViewerRenewal } from '../Form/fileViewerRenewal';
import { ValidatePhoneNumber } from '../../helpers/validatePhoneNumber';

import PERSONAL_INFO from "../../assets/form-icons/personal-information.png"
import ACADEMIC_INFO from "../../assets/form-icons/academic.png"
import INCOME_DETAIL from "../../assets/form-icons/income-detail.png"
import CASE_HISTORY from "../../assets/form-icons/case-history.png"
import formatter from "../../helpers/number_formatter"

function Preview() {
    const { renewalTabData } = useAuth();
    const navigate = useNavigate();

    return (
        <>
            <Demographics_Information />
            <Academic_Background />
            <Renewal_Details />
            <Additional_Information />

            <div className='d-flex jc-between'>
                <button className='btn prev_btn' onClick={() => navigate(`#${"additional_information"}`)}>Previous</button>
                <button className='btn next_btn' onClick={() => navigate(`#${"final"}`)}>Save and Next</button>
            </div>

        </>
    )
}

export function Demographics_Information() {

    const { user } = useAuth()

    const [form1, setForm1] = useState({
        Name: user.fullName || "",
        email: user.email || "",
        contactNo: user.phone || "",
        Institute: user.university._id || "",
        InstituteName: user.university.fullName || "",
        Program: user.program._id || "",
        ProgramName: user.program.name || "",
        status: "",
        meezanBanckAccount: "",
    })

    const getDData = async () => {
        try {
            const response = await getrenewal()
            console.log("RRR", response);
            if (response.success) {
                setForm1({
                    ...form1,
                    Name: response.message.Name,
                    email: response.message.email,
                    contactNo: response.message.contactNo,
                    Institute: response.message.Institute,
                    Program: response.message.Program,
                    status: response.message.status,
                    meezanBanckAccount: response.message.meezanBanckAccount,
                })

                // setForm2({
                //     ...form2,
                //     renewalStatus: response.message.renewalStatus,
                //     sem_year: response.message.sem_year,
                //     cGPA: response.message.cGPA,
                // })

                // setForm3({
                //     ...form3,
                //     Amt_Disb_ToDate: response.message.Amt_Disb_ToDate,
                //     Monthly_rePay: response.message.Monthly_rePay,
                //     rePayDate: response.message.rePayDate,
                //     amountOutstanding: response.message.amountOutstanding,
                //     promptRepay: response.message.promptRepay,
                //     question1: response.message.question1,
                //     question2: response.message.question2,
                //     amtRequested: response.message.amtRequested,
                //     revMonthlyRepay: response.message.revMonthlyRepay,
                //     srcMonthlyRepay: response.message.srcMonthlyRepay,
                // })

                // setForm4({
                //     ...form4,
                //     prevTotalIncome: response.message.prevTotalIncome,
                //     currTotalIncome: response.message.currTotalIncome,
                //     prevTotalExpense: response.message.prevTotalExpense,
                //     currTotalExpense: response.message.currTotalExpense,
                //     prevTotalAssets: response.message.prevTotalAssets,
                //     currTotalAssets: response.message.currTotalAssets,
                //     newMajorExpense: response.message.newMajorExpense,
                //     reasonForAssetsChange: response.message.reasonForAssetsChange,
                //     srcMonthlyRepay: response.message.srcMonthlyRepay,
                // })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])

    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Demographics Information</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <img src={PERSONAL_INFO} alt="icon" className='img-fluid form_icons' />
                                Personal Info
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="Name"
                                                value={form1.Name}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Email Address</label>
                                            <input
                                                type="email"
                                                className='form-control'
                                                name="email"
                                                value={form1.email}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Contact Number</label>
                                            <input
                                                inputType="tel"
                                                className='form-control'
                                                name="contactNo"
                                                value={form1.contactNo}
                                                disabled
                                                required
                                            />
                                            <small className="text-danger">{(form1.contactNo != null && form1.contactNo != "" && ValidatePhoneNumber(form1.contactNo) == false) && "Phone numbers should start with +92 and be 13 characters long."}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Name of the University/Institution</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="Name"
                                                value={form1?.InstituteName}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Program</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="Name"
                                                value={form1?.ProgramName}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Job Status <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="Name"
                                                value={form1?.status}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Meezan Bank Current Account No <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="meezanBanckAccount"
                                                value={form1.meezanBanckAccount}
                                                // onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Academic_Background() {

    const { user } = useAuth();

    const [form2, setForm2] = useState({
        renewalStatus: "",
        reviewNo: "",
        sem_year: "",
        cGPA: "",
    })
    const getDData = async () => {
        try {
            const response = await getrenewal()
            console.log("RRR222", response);
            if (response.success) {

                setForm2({
                    ...form2,
                    reviewNo: response.message.reviewNo,
                    sem_year: response.message.sem_year,
                    semtype: response.message.semtype,
                    cGPA: response.message.cGPA,
                })

            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])

    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Academic Background</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                Semester / Year
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Status <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="sem_year"
                                                value={form2.reviewNo}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Semester/Year <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="sem_year"
                                                value={form2.sem_year}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {
                                        user.type == "semester" &&
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                            <div className='form-group'>
                                                <label className="form-label">Academic Session <span className='required'>*</span></label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="semtype"
                                                    value={form2.semtype}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div className="col-12 vertically_center">
                                        <div className="form-group">
                                            <FileViewerRenewal
                                                text="Latest (paid (if any) and unpaid) Fee Voucher / Fee Ledger"
                                                span="*"
                                                url={RenewalUrl}
                                                keyName="paidFeeVoucher"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Current (latest) CGPA / % <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="cGPA"
                                                value={form2.cGPA}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className="form-group">
                                            <FileViewerRenewal
                                                text="Latest Transcript / Result Sheet"
                                                span="*"
                                                url={RenewalUrl}
                                                keyName="latestTranscript"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Renewal_Details() {

    const [form3, setForm3] = useState({
        Amt_Disb_ToDate: "",
        Monthly_rePay: "",
        rePayDate: "",
        amountOutstanding: "",
        promptRepay: "",
        question1: "",
        question2: "",
        amtRequested: "",
        revMonthlyRepay: "",
        srcMonthlyRepay: "",
    })
    const getDData = async () => {
        try {
            const response = await getrenewal()
            console.log("RRRFORM####333", response.message.srcMonthlyRepay);
            if (response.success) {


                setForm3({
                    ...form3,
                    Amt_Disb_ToDate: response.message.Amt_Disb_ToDate,
                    Monthly_rePay: response.message.Monthly_rePay,
                    rePayDate: response.message.rePayDate,
                    amountOutstanding: response.message.amountOutstanding,
                    promptRepay: response.message.promptRepay,
                    question1: response.message.question1,
                    question2: response.message.question2,
                    amtRequested: response.message.amtRequested,
                    revMonthlyRepay: response.message.revMonthlyRepay,
                    srcMonthlyRepay: response.message.srcMonthlyRepay,
                })


            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])


    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Renewal Details</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    {/* Case History */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <img src={CASE_HISTORY} alt="icon" className='img-fluid form_icons' />
                                Case History
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row case_history">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Amount Disbursed to Date <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="Amt_Disb_ToDate"
                                                value={formatter(form3.Amt_Disb_ToDate)}
                                                // value={form3.Amt_Disb_ToDate ? new Date(form3.Amt_Disb_ToDate).toISOString().split('T')[0] : ''}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">What token monthly repayment amount you can easily pay ? <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="Monthly_rePay"
                                                value={formatter(form3.Monthly_rePay)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Repayments Made to Date <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="date"
                                                className='form-control'
                                                name="rePayDate"
                                                value={form3.rePayDate ? new Date(form3.rePayDate).toISOString().split('T')[0] : ''}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Amount Outstanding <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="amountOutstanding"
                                                value={formatter(form3.amountOutstanding)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Prompt Repayment <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="amountOutstanding"
                                                value={(form3.promptRepay)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {
                                        form3.promptRepay == "no" && (
                                            <div className="col-12 vertically_center">
                                                <div className='form-group'>
                                                    <label className="form-label">If No, explain why <span className='required'>*</span></label>
                                                    <textarea
                                                        disabled
                                                        cols="30"
                                                        rows="5"
                                                        className='form-control'
                                                        name="question1"
                                                        value={form3.question1}
                                                    >
                                                    </textarea>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Any other financial assistance received during the review period? If yes, then what are the amount and the type of the financial assistance?</label>
                                            <textarea
                                                disabled
                                                cols="30"
                                                rows="5"
                                                className='form-control'
                                                name="question2"
                                                value={form3.question2}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Case Extension */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <img src={CASE_HISTORY} alt="icon" className='img-fluid form_icons' />
                                Case Extension
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row case_history">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Amount Requested <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="amtRequested"
                                                value={formatter(form3.amtRequested)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Reviewed Monthly Repayment Amount <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="revMonthlyRepay"
                                                value={formatter(form3.revMonthlyRepay)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Source of Monthly Repayment <span className='required'>*</span></label>
                                            <textarea
                                                disabled
                                                cols="30"
                                                rows="3"
                                                className='form-control'
                                                name="srcMonthlyRepay"
                                                value={form3.srcMonthlyRepay}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export function Additional_Information() {

    const [form4, setForm4] = useState({
        prevTotalIncome: "",
        currTotalIncome: "",
        prevTotalExpense: "",
        currTotalExpense: "",
        prevTotalAssets: "",
        currTotalAssets: "",
        newMajorExpense: "",
        reasonForAssetsChange: "",
        srcMonthlyRepay: "",
    })

    const getDData = async () => {
        try {
            const response = await getrenewal()
            console.log("RRR", response);
            if (response.success) {

                setForm4({
                    ...form4,
                    prevTotalIncome: response.message.prevTotalIncome,
                    currTotalIncome: response.message.currTotalIncome,
                    prevTotalExpense: response.message.prevTotalExpense,
                    currTotalExpense: response.message.currTotalExpense,
                    prevTotalAssets: response.message.prevTotalAssets,
                    currTotalAssets: response.message.currTotalAssets,
                    newMajorExpense: response.message.newMajorExpense,
                    reasonForAssetsChange: response.message.reasonForAssetsChange,
                    srcMonthlyRepay: response.message.srcMonthlyRepay,
                    reasonForIncomeChange2: response.message.reasonForIncomeChange2
                })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])


    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Additional Information</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <img src={INCOME_DETAIL} alt="icon" className='img-fluid form_icons' />
                                Income and Expense Details
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className='row'>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Previous Total Income (Rs.) <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="prevTotalIncome"
                                                value={formatter(form4.prevTotalIncome)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Current Total Income (Rs.) <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="currTotalIncome"
                                                value={formatter(form4.currTotalIncome)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">If the current total income is different from previous total income, then what’s the reason for that change?</label>
                                            <textarea
                                                disabled
                                                cols="30"
                                                rows="4"
                                                className='form-control textarea'
                                                name="reasonForIncomeChange"
                                                value={form4.reasonForIncomeChange2}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className="form-group">
                                            <FileViewerRenewal
                                                text="Latest Salary Slip (all family members who are earning)"
                                                url={RenewalUrl}
                                                keyName="salarySlip"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className="form-group">
                                            <FileViewerRenewal
                                                text="Last 3 Months Bank Statements"
                                                url={RenewalUrl}
                                                keyName="bankStmt"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Previous Total Expenses(Rs.) <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="prevTotalExpense"
                                                value={formatter(form4.prevTotalExpense)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Current Total Expenses (Rs.) <span className='required'>*</span></label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="currTotalExpense"
                                                value={formatter(form4.currTotalExpense)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Any new major expense added? If yes, then what are the source and the amount being incurred?</label>
                                            <textarea
                                                disabled
                                                cols="30"
                                                rows="4"
                                                className='form-control textarea'
                                                name="newMajorExpense"
                                                value={form4.newMajorExpense}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Previous value of total assets of the family (Rs.)</label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="prevTotalAssets"
                                                value={formatter(form4.prevTotalAssets)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">Current value of total assets of the family (Rs.)</label>
                                            <input
                                                disabled
                                                type="text"
                                                className='form-control'
                                                name="currTotalAssets"
                                                value={formatter(form4.currTotalAssets)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 vertically_center">
                                        <div className='form-group'>
                                            <label className="form-label">If the current value of total assets is different from the previous value, then please state the reason for that change.</label>
                                            <textarea
                                                disabled
                                                cols="30"
                                                rows="4"
                                                className='form-control textarea'
                                                name="reasonForAssetsChange"
                                                value={form4.reasonForAssetsChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Preview
