import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import { createApprovalSheet, getStudentData, getApprovalSheetData, getSheetData } from '../../ContextAPI/APIs/api';
import { FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import SignatureCanvas from 'react-signature-canvas'
import { useToast } from '../../ContextAPI/Components/toast';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Textarea from '../../components/Form/Textarea';


function ApprovalForm({ setShow }) {

    const { user } = useAuth();

    const { id, doc } = useParams()
    const { alert } = useToast()

    const navigate = useNavigate()
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    // siblingDetails: { type: Array, required: false },

    const [data, setData] = useState({
        userId: id,
        documentIdFresh: doc,
        name: "",
        institute: "",
        program: "",
        instituteName: "",
        programName: "",
        repayThisYear: "",
        repayLastYear: "",
        type: "fresh",
        Recomendations: "",

        selfIncomeSource: "",
        currentSem: "",
        currentYear: "",
        examStructure: "",
        Semfee: "",
        Yearfee: "",
        feeStudent: "",
        feeOutstanding: "",
        comments: "",

        recomendationRupees: "",
        recomendationRupeesNext: "",
        recomendationPercent: "",
        recomendationFee: "",
        recomendationYear: "",
        recomendationLoanRupees: "",
        recomendationRepayment: ""
    })


    const handleChange = (e) => {

        const { name, value, type, checked } = e.target;

        console.log("name", name);
        console.log("value", value);

        setData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };


    const save = async (e) => {
        e.preventDefault()
        try {

            const payload = {
                type: "fresh",
                userId: id,
                institute: data.institute,
                documentIdFresh: doc,
                program: data.program,
                name: data.name,
                instituteName: data.instituteName,
                comments: data.comments,
                programName: data.programName,
                examStructure: data.examStructure,
                currentYear: data.currentYear,
                Yearfee: data.Yearfee,
                currentSem: data.currentSem,
                Semfee: data.Semfee,
                feeOutstanding: data.feeOutstanding,
                familyIncome: data.familyIncome,
                recomendationRupees: data.recomendationRupees,
                recomendationRupeesNext: data.recomendationRupeesNext,
                recomendationPercent: data.recomendationPercent,
                recomendationRepayment: data.recomendationRepayment,
            }

            console.log("Dlll", payload);
            const response = await createApprovalSheet(payload)
            alert(response?.message, response?.success)
            if (response?.success) {
                // alert(response?.message, response?.success)
                // getSheet()
                // navigate(`/fresh-cases-history/${id}/${doc}`)
                // setShow("#cases-detail")

                getstdData()
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const [interviewers, setInterviewers] = useState([
        {
            Name: "",
            Signature: "",
        },
    ]);

    const interviewer_detail = {
        Name: "",
        Signature: "",
    }

    function Add_New_Interviewer(e) {
        e.preventDefault();
        setInterviewers([...interviewers, interviewer_detail])
    }

    function removeInterviewDetail(i) {

        const updated = [...interviewers]

        updated.splice(i, 1)
        setInterviewers(updated)

    }
    function handleInterviewChange(e, index, field) {
        const updated = [...interviewers];
        updated[index][field] = e.target.value;
        setInterviewers(updated);
    }



    const [siblingsDetail, setSiblingsDetail] = useState([
        {
            relation: "",
            maritalStatus: "",
            info: "",
        },
    ]);

    const siblings_detail = {
        relation: "",
        maritalStatus: "",
        info: "",
    }

    function Add_New_SiblingsDetail(e) {
        e.preventDefault();
        setSiblingsDetail([...siblingsDetail, siblings_detail])
    }

    function removeDetail(i) {

        const updated = [...siblingsDetail]

        updated.splice(i, 1)
        setSiblingsDetail(updated)

    }
    function handleSiblingChange(e, index, field) {
        const updated = [...siblingsDetail];
        updated[index][field] = e.target.value;
        setSiblingsDetail(updated);
    }



    const getstdData = async () => {
        try {
            const response = await getStudentData({ id: id })

            const response2 = await getApprovalSheetData({ id: id, doc: doc, type: "fresh" })

            if (response.success && (response2.success && response2.message !== null)) {
                console.log("HHH@@@", response.message);
                setData({
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    examStructure: response?.message?.type,
                    documentIdFresh: response2.message?.documentIdFresh,
                    comments: response2.message?.comments,
                    currentYear: response2.message?.currentYear,
                    Yearfee: response2.message?.Yearfee,
                    currentSem: response2.message?.currentSem,
                    Semfee: response2.message?.Semfee,
                    feeOutstanding: response2.message?.feeOutstanding,
                    familyIncome: response2.message?.familyIncome,
                    recomendationRupees: response2.message?.recomendationRupees,
                    recomendationRupeesNext: response2.message?.recomendationRupeesNext,
                    recomendationPercent: response2.message?.recomendationPercent,
                    recomendationRepayment: response2.message?.recomendationRepayment,
                })
            }

            if (response2.message === null && response.success) {

                const response3 = await getSheetData({ id: doc, type: "fresh" })
                setData({
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    examStructure: response?.message?.type,
                    currentYear: response3.message?.currentYear,
                    Yearfee: response3.message?.Yearfee,
                    currentSem: response3.message?.currentSem,
                    Semfee: response3.message?.Semfee,
                    feeOutstanding: response3.message?.feeOutstanding,
                    familyIncome: response3.message?.familyIncome,
                    recomendationRupees: response3.message?.recomendationRupees,
                    recomendationRupeesNext: response3.message?.recomendationFeeNEXT,
                    recomendationPercent: response3.message?.recomendationPercent,
                    recomendationRepayment: response3.message?.recomendationRepayment,
                })
            }

            // setData({
            //     ...data,
            //     currentYear: response2.message?.currentYear,
            //     Yearfee: response2.message?.Yearfee,
            //     currentSem: response2.message?.currentSem,
            //     Semfee: response2.message?.Semfee,
            //     feeOutstanding: response2.message?.feeOutstanding,
            //     familyIncome: response2.message?.familyIncome,
            //     recomendationRupees: response2.message?.recomendationRupees,
            //     recomendationPercent: response2.message?.recomendationPercent,
            //     recomendationRepayment: response2.message?.recomendationRepayment,
            // })


        }
        catch (e) {
            console.log(e);
        }
    }



    const getSheet = async () => {
        try {
            const response = await getApprovalSheetData({ id: id, type: "fresh" })
            console.log("RSS1", response);
            if (response.success && response.message !== null) {
                setData(response?.message)
                setData({
                    ...data,
                    currentYear: response.message?.currentYear,
                    Yearfee: response.message?.Yearfee,
                    currentSem: response.message?.currentSem,
                    Semfee: response.message?.Semfee,
                    feeOutstanding: response.message?.feeOutstanding,
                    familyIncome: response.message?.familyIncome,
                    recomendationRupees: response.message?.recomendationRupees,
                    recomendationPercent: response.message?.recomendationPercent,
                    recomendationRepayment: response.message?.recomendationRepayment,
                })
            }
            else {
                getSheetDataInterview()
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const getSheetDataInterview = async () => {
        try {
            const response = await getSheetData({ id: id, type: "fresh" })
            console.log("RSS2", response);
            if (response.success && response.message !== null) {
                setData(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getstdData()
        // getSheet()
    }, [])


    return (
        <>
            <Helmet>
                <title>Approval Form</title>
            </Helmet>


            <section className='interview_sheet_section'>

                <h4>Ihsan Trust Fresh Case Approval Form</h4>

                <div className="">
                    <form onSubmit={(e) => save(e)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input inputType="text" name="name" label="Applicant's Name" value={data.name} placeholder=" " onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="institute" label="Name of the University/Institution" value={data.instituteName} placeholder=" " onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="program" label="Program" value={data.programName} placeholder=" " onChange={handleChange} disabled={true} />
                                </div>
                            </div>

                            {/* <div className="col-md-4">
                                <div className="form-group">
                                    <label className='form-label'>Applicant's Examination Structure <span className='required'>*</span></label>
                                    <select name="examStructure" value={data.examStructure} className="form-select">
                                        <option disabled value="">Select</option>
                                        <option value="semester">Semester</option>
                                        <option value="year">Year</option>
                                    </select>
                                </div>
                            </div> */}

                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" label="Applicant's Examination Structure" placeholder=" " value={data.examStructure} disabled />
                                </div>
                            </div>
                            {
                                (data.examStructure !== "" && data.examStructure === "year") && (
                                    <>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input inputType="number" label="Current Year" placeholder=" " value={data.currentYear} disabled />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input inputType="number" label="Per Year Fee" placeholder=" " value={data.Yearfee} disabled />
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {
                                (data.examStructure !== "" && data.examStructure === "semester") && (
                                    <>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input inputType="number" label="Current Semester" placeholder=" " value={data.currentSem} disabled />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <Input inputType="number" label="Per Semester Fee" placeholder=" " value={data.Semfee} disabled />
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="OutStanding Fee (Rs.)" label="OutStanding Fee (Rs.)" value={data.feeOutstanding} placeholder=" " onChange={handleChange} disabled={true} />
                                </div>
                            </div>

                            {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <Input inputType="text" name="Family Income Per Month (Rs.)" label="Family Income Per Month (Rs.)" value={data.familyIncome} placeholder=" " onChange={handleChange} disabled={true} />
                                        </div>
                                    </div> */}

                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="recomendationRupees" label="Tranch" value={data.recomendationRupees} placeholder="Enter here" onChange={handleChange} />
                                </div>
                            </div>
                            {
                                data.examStructure === "semester" && (
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <Input inputType="text" name="recomendationRupeesNext" label="Next Tranch %" value={data.recomendationRupeesNext} placeholder="Enter here" onChange={handleChange} />
                                        </div>
                                    </div>
                                )
                            }

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="recomendationPercent" label="Recommended Percentage %" value={data.recomendationPercent} placeholder="Enter here" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <Input inputType="text" name="recomendationRepayment" label="Repayment Amount (Rs.)" value={data.recomendationRepayment} placeholder="Enter here" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Textarea
                                        label="Comments"
                                        name="comments"
                                        id=""
                                        cols="30"
                                        rows="10"
                                        className='form-control'
                                        value={data.comments}
                                        placeholder="Enter here"
                                        onChange={handleChange}
                                    ></Textarea>
                                </div>
                            </div>
                        </div>
                        {
                            (user?.role == "admin" || user?.role == "operations") && (
                                data?.documentIdFresh?.status !== "disburse" && (
                                    <div className='d-flex jc-end'>
                                        <button className='btn save_btn' type='submit'>Save</button>
                                    </div>
                                )
                            )
                        }
                    </form>
                </div>
            </section>
        </>
    )
}

export default ApprovalForm
