import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useProSidebar } from 'react-pro-sidebar';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useToast } from '../../ContextAPI/Components/toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// ----------------------------------------------------------------

import { Update_Password } from './update_password';
import { Show_Profile_Pic } from './upload_profilePic';

import Input from "../Form/Input"
import Tabs from '../tabs';
import NavSidebar from '../Sidebar';
import Navbar from '../Navbar';
import { getUniMOU, getUNIProfile } from '../../ContextAPI/APIs/api';


export function Profile_UniversityProfileView() {

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const tabs = [
        {
            tabName: "University Information",
            pathName: ""
        },
        {
            tabName: "MOU",
            pathName: "#university_mou"
        },
        {
            tabName: "Update Password",
            pathName: "#password"
        },
    ]

    const Mob_tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "MOU",
            pathName: "#university_mou"
        },
        {
            tabName: "Password",
            pathName: "#password"
        },
    ]


    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    const [show, setShow] = useState("");
    const { hash } = useLocation();

    useEffect(() => {
        console.log((hash.split("#"))[1] || "university_info");
        setShow((hash.split("#"))[1] || "university_info")
    }, [hash])


    const { alert } = useToast()
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    const [sUNI, setsUNI] = useState({
        profilepic: user.profilepic,
        fullName: user.fullName,
        email: user.email,
        shortName: user.shortName,
        types: user.types,
    })
    const [campuses, setCampuses] = useState(user.campuses)
    const [programs, setPrograms] = useState(user.programs)


    const getAllData = async () => {
        try {
            const response = await getUNIProfile({ uniId: user._id })
            console.log("REPO", response);

            if (response.success == true && response.message != null) {
                setsUNI(response?.message)
                setCampuses(response?.message?.campuses || [])
                setPrograms(response?.message?.programs || [])
            } else {
                alert("invalid university", "error")
                navigate("/universities-list")
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const [mou, setMOU] = useState({
        MOU_Date: "",
        expiry: "",
        total: "",
        freshLimit: "",
        renewalLimit: "",
        freshConsumed: "",
        RenewalConsumed: "",
        isNotApproved: "",
        notApprovedReason: "",
    })

    const getMOUDATA = async () => {
        try {
            const response = await getUniMOU()
            if (response.success) {
                setMOU(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        getMOUDATA()
        getAllData()

    }, [])



    return <>

        <div className="d-flex">
            <div className="sidebar_div">
                <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
            </div>
            <div className="page_div">
                <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                <section className='uni_profile_section'>

                    <div className="card uni_profile">

                        <Tabs tabLinks={isMobile ? Mob_tabs : tabs} show={show} setShow={setShow} />

                        {
                            show == "university_info" &&
                            <>
                                <div className='d-flex jc-between ai-center mb-4'>
                                    <div className='d-flex ai-center'>
                                        <Show_Profile_Pic src={sUNI.profilepic} /> &nbsp;
                                        <h2 className='mb-0 ml-2'>{sUNI.fullName}</h2>
                                    </div>
                                </div>
                                <div className="university_info">

                                    <div className='d-flex ai-center jc-between mb-3'>
                                        <h4 className='mb-0'>University Information</h4>
                                        {/* <button className='btn save_btn' onClick={() => navigate(`/update-university-profile/${id}`)}>Update Profile <FaEdit className='edit_icon ml-2' /></button> */}
                                    </div>

                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <Input label="Email" inputType="text" value={sUNI.email} disabled={true} />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <Input style={{ textTransform: "upperCase" }} label="Short Name" value={sUNI.shortName} inputType="text" disabled={true} />
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <Input label="Institute Types" value={sUNI.types} disabled={true} />
                                                </div>
                                            </div> */}

                                            <div className='d-flex ai-center jc-between my-3'>
                                                <h4 className='mb-0'>Campuses</h4>
                                            </div>
                                            <div className="col-md-12">
                                                {
                                                    (typeof campuses != "undefined" && (campuses?.length) > 0) && campuses?.map((c, i) => {
                                                        if (typeof c.name != "undefined" && c.name != "" && c.name != null && typeof c.address != "undefined" && c.address != "" && c.address != null) {
                                                            return <div className='row'>
                                                                <div className="col-md-6 col-sm-6 col-12 mt-1">
                                                                    <div className="form-group">
                                                                        <Input label="Name" inputType="text" key={i} value={c.name} disabled style={{ textTransform: "capitalize" }} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6 col-12 mt-1">
                                                                    <div className="form-group">
                                                                        <Input label="Address" inputType="text" key={i} value={c.address} disabled style={{ textTransform: "capitalize" }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    })
                                                }
                                                {
                                                    campuses.length < 1 && (
                                                        <h5 className='not_found_msg'>No Records Found</h5>
                                                    )
                                                }
                                            </div>


                                            <div className='d-flex ai-center jc-between my-3'>
                                                <h4 className='mb-0'>Programs</h4>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className='row'>
                                                        {
                                                            (typeof programs != "undefined" && (programs?.length) > 0) && programs.map((p, i) => {
                                                                if (typeof p.name != "undefined" && p.name != "" && p.name != null) {
                                                                    return <div className="col-md-6 col-sm-6 col-12 mt-1">
                                                                        <div className='form-group'>
                                                                            <Input key={i} value={p.name} label={false} disabled style={{ textTransform: "capitalize" }} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            })
                                                        }
                                                        {
                                                            programs.length < 1 && (
                                                                <h5 className='not_found_msg'>No Records Found</h5>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                        }

                        {
                            show == "university_mou" &&
                            <div className="university_mou">
                                <h4 className='mb-3'>University MOU</h4>
                                <form>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <Input label="Initial MOU Date" inputType="date" placeholder="---" value={mou.MOU_Date} disabled={true} />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className='form-group'>
                                                <Input label="Renewal Date" inputType="date" placeholder="---" value={mou.MOU_Date} disabled={true} />
                                            </div>
                                        </div> */}
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <Input label="Expiry Date" inputType="date" placeholder="---" value={mou.expiry} disabled={true} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Total Limit" inputType="text" placeholder="---" value={mou.total} disabled={true} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Not Approved Reason" placeholder="---" value={mou.notApprovedReason} disabled={true} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Fresh Cases Limit" placeholder="---" value={mou.freshLimit} inputType="text" disabled={true} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Fresh Cases Consumed" placeholder="---" value={mou.freshConsumed} inputType="text" disabled={true} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Renewal Cases Limit" placeholder="---" value={mou.renewalLimit} inputType="text" disabled={true} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Renewal Cases Consumed" placeholder="---" value={mou.RenewalConsumed} inputType="text" disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }

                        {
                            show == "password" &&
                            <Update_Password />
                        }
                    </div>

                </section>

            </div>
        </div>
    </>
}