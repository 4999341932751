import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';

import Navbar from '../../../components/Navbar';
import NavSidebar from '../../../components/Sidebar';
import { useAuth } from '../../../ContextAPI/Components/auth';
import '../Form/Form.css'

import FormTabs from './FormTabs';

import Instructions from '../../../components/ApplicationForm/Instructions';
import Demographics_Information from '../../../components/ApplicationForm/Demographics_Information';
import Academic_Background from '../../../components/ApplicationForm/Academic_Background';
import Financial_Information from '../../../components/ApplicationForm/Financial_Information';
import Additional_Information from '../../../components/ApplicationForm/Additional_Information';
import SOP from '../../../components/ApplicationForm/SOP';
import Preview from '../../../components/ApplicationForm/Preview';
import Final from '../../../components/ApplicationForm/Final';
import AccessDenied from './AccessDenied';

import Step_1 from '../../../components/ApplicationForm/Instructions';
import Step_2 from '../../../components/ApplicationForm/Demographics_Information';
import Step_3 from '../../../components/ApplicationForm/Academic_Background';
import Step_4 from '../../../components/ApplicationForm/Additional_Information';
import Step_5 from '../../../components/ApplicationForm/Step_5';
import Step_6 from '../../../components/ApplicationForm/Step_6';
import Step_7 from '../../../components/ApplicationForm/Step_7';
import Step_8 from '../../../components/ApplicationForm/Step_8';
import Step_9 from '../../../components/ApplicationForm/Step_9';
import Step_10 from '../../../components/ApplicationForm/Final';

import { getForm8, GetLatestSubmittedApplication, getReason, validateApplication } from '../../../ContextAPI/APIs/api';
import { useToast } from "../../../ContextAPI/Components/toast"
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { passimageUrl } from '../../../helpers/data';


function ApplicationForm() {
    const { alert } = useToast()
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const { user, GettabsData, getDocuments, fileData } = useAuth();
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("");

    const haveSubmitted = async () => {
        const res = await GetLatestSubmittedApplication(user._id);
        if (res.success) {
            console.log("haveSubmitted", res.message);
            if (res.message.appStatus == "done") {
                alert("You have already submitted")
                navigate("/application-tracking")
            }
            else if (res.message.renewalStatus == "done") {
                alert("You have already submitted")
                navigate("/application-tracking")
            }
        }
    }

    const [status, setStatus] = useState({
    })

    const checkSubmitted = async () => {
        try {
            const res = await validateApplication()
            console.log("haveSubmitted", res);


            if (res?.application) {
                if (res?.application.status === "disburse" || res?.application.status === "reject") {
                    if (parseInt(res?.application.nextReview) > Date.now()) {
                        navigate("/application-tracking")
                    }
                }
                else {
                    if (res.application.appStatus === "done" && res?.application.status !== "resubmit") {
                        navigate("/application-tracking")
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setShowForm((hash.split("#"))[1] || "instructions");
    }, [hash]);

    const [reason, setReason] = useState(null)
    const getR = async () => {
        try {
            const response = await getReason()
            console.log("REASON", response);
            if (response.success) {
                // setReason(response.message)
                // alert("you have to resubmit your application", "info", false)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {

        if (user.totalLoan > 0) {
            navigate("/dashboard")
        }


        // getR()
        checkSubmitted()
        GettabsData()
        getDocuments()
        console.log("<<<<fileData>>>>", fileData);
    }, [])



    return (
        <>

            <Helmet>
                <title>Application Form</title>
            </Helmet>

            {
                (user.role == "student" && user.pInfo && user.qInfo
                    // && (status.status !== "resubmit" && status.appStatus !== "done")
                )
                    ?
                    <>
                        {/* Instructions */}
                        {
                            showForm == "instructions" &&
                            <Instructions />
                        }

                        {
                            (showForm == "demographics_information" || showForm == "academic_background" || showForm == "financial_information" || showForm == "additional_information" || showForm == "sop" || showForm == "preview" || showForm == "final") &&

                            <div className="page_div">

                                <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                                <section className='application_form_section section_main'>
                                    <div className="card">

                                        <div className='header mb-4'>
                                            <div className='heading'>
                                                <h2>Interest Free Loan Application Form For Higher Education</h2>
                                                <p>
                                                    It is responsibility of the candidate to provide all the documents and if at any stage the documents are found to be fake/forged the Ihsan Trust will have the right to take necessary actions which may lead to cancellation of application without intimation.
                                                </p>
                                                <ul>
                                                    <li>
                                                        All fields marked with (<span className='required'>*</span>) are mandatory and ensure all compulsory documents are uploaded in required format.
                                                    </li>
                                                    <li>
                                                        Please ensure that your mobile numbers, CNIC, Email and Permenat address are correct and updated.
                                                    </li>
                                                    <li>
                                                        Check all the provided details before moving to the Next section.
                                                    </li>
                                                </ul>
                                            </div>
                                            <button className='btn save_btn'>Online Application Video Guide</button>
                                        </div>

                                        <FormTabs showForm={showForm} />

                                        <div className="row">

                                            <div className="col-xl-3 col-lg-12 col-md-12 mb-4">
                                                {/* REQUIRED DOCUMENTS */}
                                                <div className="card required_doc_card">
                                                    <h4>Required Documents</h4>
                                                    <div className='row mb-3'>
                                                        {/* <Uploaded_Doc_Checked lable="Two (02) Recent passport size photographs" keyData="passimage" /> */}
                                                        <Uploaded_Doc_Checked lable="Self CNIC / B-Form" keyData="selfCnic" />
                                                        {/* <Uploaded_Doc_Checked lable="Father's CNIC" keyData="fatherCnic" /> */}
                                                        {/* <Uploaded_Doc_Checked lable="Mother's CNIC" keyData="motherCnic" /> */}
                                                        {/* <Uploaded_Doc_Checked lable="Admission Letter" keyData="AdmissionLetter" /> */}

                                                        <Uploaded_Doc_Checked lable="Self resume / CV" keyData="Resume" />
                                                        <Uploaded_Doc_Checked lable="Latest Unpaid fee vouchers" keyData="UnpaidFeeVoucher" />
                                                        <Uploaded_Doc_Checked lable="Matric Certificate" keyData="matricResult" />
                                                        <Uploaded_Doc_Checked lable="Intermediate Certificate" keyData="interResult" />

                                                        {/* {
                                                            !fileData?.undergrad && (
                                                                <Uploaded_Doc_Checked lable="Bachelor's Certificate" keyData="bachelorsResult" />
                                                            )
                                                        } */}
                                                        {/* {
                                                            !fileData?.grad && (
                                                                <Uploaded_Doc_Checked lable="Master's Certificate" keyData="mastersResult" />
                                                            )
                                                        } */}
                                                        {/* {
                                                            !fileData?.empDis && (
                                                                <Uploaded_Doc_Checked lable="Income Certificate / Pay-slip / Pension Book Slip (if Applicable)" keyData="employemnetIncomeCertificate" />
                                                            )
                                                        } */}


                                                        <Uploaded_Doc_Checked lable="Electricity Bill" keyData="ElectricBill" />
                                                        <Uploaded_Doc_Checked lable="Gas Bill" keyData="GasBill" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-9 col-lg-12 col-md-12">
                                                <div className="card">

                                                    {/* <FormTabs showForm={showForm} /> */}

                                                    {/* 01 */}
                                                    {
                                                        showForm == "demographics_information" &&
                                                        <Demographics_Information />
                                                    }

                                                    {/* 02 */}
                                                    {
                                                        showForm == "academic_background" &&
                                                        <Academic_Background />
                                                    }

                                                    {/* 03 */}
                                                    {
                                                        showForm == "financial_information" &&
                                                        <>
                                                            <Financial_Information />
                                                            {/* <Step_6 /> */}
                                                        </>
                                                    }

                                                    {/* 04 */}
                                                    {
                                                        showForm == "additional_information" &&
                                                        <Additional_Information />
                                                    }

                                                    {/* 05 */}
                                                    {
                                                        showForm == "sop" &&
                                                        <SOP />
                                                    }

                                                    {/* 06 */}
                                                    {
                                                        showForm == "preview" &&
                                                        <Preview />
                                                    }

                                                    {/* 07 */}
                                                    {
                                                        showForm == "final" &&
                                                        <Final />
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </section>
                            </div>
                        }
                    </>
                    :
                    <div className="d-flex">
                        <div className="sidebar_div">
                            <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                        </div>

                        <div className="page_div">

                            <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                            <section className='application_form_section section_main'>
                                <AccessDenied />
                            </section>
                        </div>
                    </div>
            }
        </>
    )
}


export function Uploaded_Doc_Checked(props) {

    const { fileData } = useAuth()
    // const [isDocUploaded, setIsDocUploaded] = useState([]);

    // const [file, setFile] = useState({})

    console.log("HHHHH", [props.keyData]);
    // console.log("IIIII", fileData[props.keyData]);

    useEffect(() => {
        // getData()
        // getDocuments()
    }, [])

    return (
        <>
            <div className='d-flex jc-between ai-center mb-1 Uploaded_Doc_Checked'>
                <label className='mb-0 me-2'>{props?.lable}</label>

                <div>
                    {
                        (typeof fileData[props.keyData] != "undefined" && fileData[props.keyData] != null) ?
                            <MdCheckCircle className='tick_icon' style={{ color: "var(--successColor)" }} />
                            :
                            <MdCancel className='tick_icon' style={{ color: "var(--dltBtnColor)" }} />
                    }
                </div>

            </div>
        </>
    )
}


export default ApplicationForm