import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import UserModal from '../../components/Modals/Modal';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import TooltipComponent from '../../components/tooltip';
import { CgProfile } from 'react-icons/cg';
import IconText from '../../components/Table/IconText';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';

function ApprovalFlow() {

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const navigate = useNavigate();

    const approval_data = [
        {
            std_name: "Ali",
            uni_name: "SSUET",
            program: "BS",
            semester_year: "Semester",
            appr_date: "10/09/23",
            interview_date: "20/09/23",
            appr_semester_year: 5,
            appr_percentage: 60,
            repayment_amount: 10000
        },
        {
            std_name: "Asad",
            uni_name: "Agha Khan",
            program: "SE",
            appr_date: "10/09/23",
            interview_date: "20/09/23",
            semester_year: "Year",
            appr_semester_year: 7,
            appr_percentage: 70,
            repayment_amount: 15000
        },
    ]

    function handleSubmit(e) {
        e.preventDefault();
        alert("Please Fill All Fields")
    }

    return (
        <>
            <Helmet>
                <title>Application</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='approval_flow_section'>
                        <div className="card">
                            <h2 className='mb-3'>Applications</h2>

                            <Table head={["Student Name", "University Name", "Program", "Approved Date", "Interview Date", "Semester/Year", "Approved Semester/Year", "Approved Percentage", "Repayment Amount"]}>

                                {
                                    approval_data.map((data, i) => {
                                        return <tr key={i}>
                                            <td>
                                                <div className='d-flex ai-center'>
                                                    <TooltipComponent className="" placement="top" tooltipText="View Status">
                                                        <div><CgProfile className='icon' onClick={() => navigate("/application-form")} /></div>
                                                    </TooltipComponent>
                                                    <IconText text={data.std_name} />
                                                </div>
                                            </td>
                                            <td><Text text={data.uni_name} /></td>
                                            <td><Text text={data.program} /></td>
                                            <td><Text text={data.appr_date} /></td>
                                            <td><Text text={data.interview_date} /></td>
                                            <td><Text text={data.semester_year} /></td>
                                            <td><Text text={data.appr_semester_year} /></td>
                                            <td><Text text={data.appr_percentage} /></td>
                                            <td><Text text={data.repayment_amount} /></td>
                                            <td>
                                                <UserModal btnText={"Disbust Now"} heading="Disbustment Form" id={`ViewProgram${i}`} customClasses="detail_btn" modalSize="lg" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Application ID" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Student ID" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="University ID" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Campus ID" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Program ID" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Amount" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Repayment" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Timestamp" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Select label="Semester/Year" selectText="Select" optOne="Semester" optTwo="Year" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Semester/Year" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Fresh Renewal Case" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Renewal Date" inputType="date" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Approved Percentage" inputType="text" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <Input label="Repayment Amount" inputType="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex jc-end mt-3'>
                                                            <button className='btn save_btn' onClick={(e) => handleSubmit(e)}>Save</button>
                                                        </div>

                                                    </form>
                                                </UserModal>
                                            </td>
                                        </tr>
                                    })
                                }


                            </Table>
                            
                            <Pagination itemsPerPage={4} />

                        </div>
                    </section>


                </div>
            </div>

        </>
    )
}

export default ApprovalFlow
