import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';


import { getForm3, SubmissionForm3 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"
import { useToast } from "../../ContextAPI/Components/toast"
import ImageUploadInput from '../Modals/ImageUploadInput';

import {
    academicCertficatesUrl, admissionLetterUrl, BillUrl, childrenBformUrl, CNICUrl, DeathCertificateUrl, DomicileUrl,
    FamilyUrl, IncomeCertificateUrl, newURL, passimageUrl, RenewalUrl, RentAgreementUrl, resumeUrl, semesters, vouchersUrl, years
} from '../../helpers/data';

import Form from 'react-bootstrap/Form';

import FINANCIAL_DETAIL from "../../assets/form-icons/financial-details.png"
import FINANCIAL_APPR from "../../assets/form-icons/financial-appraisal.png"
import INCOME_DETAIL from "../../assets/form-icons/income-detail.png"
import FormErrors from './FormErrors';

// import { NumericFormat } from 'react-number-format';
import formatter from '../../helpers/number_formatter';
import { MdNewspaper } from 'react-icons/md';

// import NumberFormat from "react-num"


function Financial_Information() {

    const { alert, } = useToast()
    const { GetLoginUser, GettabsData, user } = useAuth();

    // Financial Assistance Detail
    const [financeAssis, setFinanceAssis] = useState([
        {
            Name_of_Organization: "",
            Type_of_Assistance: "",
            Amount_Applied_For: "",
            Amount_Received: "",
        },
    ]);

    const financial_detail = {
        Name_of_Organization: "",
        Type_of_Assistance: "",
        Amount_Applied_For: "",
        Amount_Received: "",
    }

    function Add_New_Finance_Detail(e) {
        e.preventDefault();
        setFinanceAssis([...financeAssis, financial_detail])
    }

    const handleInputChangeFA = (e, index) => {
        const { name, value } = e.target;
        const updatedBankDetail = [...financeAssis];
        updatedBankDetail[index][name] = value;
        setFinanceAssis(updatedBankDetail);
    };

    const [bankDetail, setBankDetail] = useState([
        {
            Nameof: "",
            relation: "",
            NameBank: "",
            ClosingBalance: "",
            ClosingBalanceNum: "",
        },
    ]);

    const bank_detail = {
        Nameof: "",
        relation: "",
        NameBank: "",
        ClosingBalance: "",
        ClosingBalanceNum: "",
    }

    function Add_New_Bank_Detail(e) {
        e.preventDefault();
        setBankDetail([...bankDetail, bank_detail])
    }


    // const handleInputChangeBANK = (e, index) => {
    //     const { name, value } = e.target;
    //     const updatedBankDetail = [...bankDetail];
    //     updatedBankDetail[index][name] = value;
    //     setBankDetail(updatedBankDetail);
    // };


    const handleInputChangeBANK = (e, index) => {
        const { name, value } = e.target;
        const updatedBankDetail = [...bankDetail];

        if (name == "ClosingBalance") {
            updatedBankDetail[index][name] = value;
            updatedBankDetail[index]["ClosingBalanceNum"] = value;
        }
        else {
            updatedBankDetail[index][name] = value;
        }


        setBankDetail(updatedBankDetail);

        const totalClosingBalance = updatedBankDetail.reduce((sum, item) => {
            return sum + parseFloat(item.ClosingBalance || 0);
        }, 0);

        setData((prevData) => ({
            ...prevData,
            AssetTotal: totalClosingBalance,
        }));

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Loan Detail
    const [loanDetail, setLoanDetail] = useState([
        {
            S_No: "",
            Semester_Year: "",
            Total_Fee_Cost: "",
            Own_Contribution: "",
            Loan_Required: "",
        },
    ]);

    const loan_detail = {
        S_No: "",
        Semester_Year: "",
        Total_Fee_Cost: "",
        Own_Contribution: "",
        Loan_Required: "",
    }

    function Add_New_Loan_Detail(e) {
        e.preventDefault();
        setLoanDetail([...loanDetail, loan_detail])
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);


    const [feePayment, setFeePayment] = useState({
        source: 'Father',
        otherSource: '',
    });

    const [data, setData] = useState({
        supportingEducation: "",
        relation: "",
        q21: "",
        q22: "",
        q23: "",
        feeContributor: "",
        perSemFee: "",
        OutStandingFee: "",
        Comments: "",
        AssetTotal: 0,
        otherTotalIncome: 0,
        FamilyMemberEarning: "",


        fatherSalary: 0,
        motherSalary: 0,
        selfSalary: 0,
        siblingSalary: 0,
        spouseSalary: 0,
        totalSalary: 0,

        fatherPension: 0,
        motherPension: 0,
        selfPension: 0,
        siblingPension: 0,
        spousePension: 0,
        totalPension: 0,

        fatherAgriIncome: 0,
        motherAgriIncome: 0,
        selfAgriIncome: 0,
        siblingAgriIncome: 0,
        spouseAgriIncome: 0,
        totalAgriIncome: 0,

        fatherBusinessIncome: 0,
        motherBusinessIncome: 0,
        selfBusinessIncome: 0,
        siblingBusinessIncome: 0,
        spouseBusinessIncome: 0,
        totalBusinessIncome: 0,

        fatherProfitBank: 0,
        motherProfitBank: 0,
        selfProfitBank: 0,
        siblingProfitBank: 0,
        spouseProfitBank: 0,
        totalProfitBank: 0,

        fatherHouseRent: 0,
        motherHouseRent: 0,
        selfHouseRent: 0,
        siblingHouseRent: 0,
        spouseHouseRent: 0,
        totalHouseRent: 0,

        fatherBusinessRent: 0,
        motherBusinessRent: 0,
        selfBusinessRent: 0,
        siblingBusinessRent: 0,
        spouseBusinessRent: 0,
        totalBusinessRent: 0,

        fatherShares: 0,
        motherShares: 0,
        selfShares: 0,
        siblingShares: 0,
        spouseShares: 0,
        totalShares: 0,

        finalTotal: 0,

        totalfather: 0,
        totalMother: 0,
        totalSelf: 0,
        totalSiblings: 0,
        totalSpouse: 0,

        fatherEarning: "",
        motherEarning: "",
        selfEarning: "",
        siblingEarning: "",
        spouseEarning: "",

    })

    const handleFeePayer = (e) => {
        setData({ ...data, feePayer: e.target.value })
    }

    const handlequestion31 = (e) => {
        setData({ ...data, q21: e.target.value })
    }


    const handleRelation = (e) => {
        setData({ ...data, relation: e.target.value })
    }

    const handleamountByGuardian = (e) => {
        setData({ ...data, feeContributor: e.target.value })
    }

    const handleperSemFee = (e) => {
        setData({ ...data, perSemFee: e.target.value })
    }

    const handleOutStandingFee = (e) => {
        setData({ ...data, OutStandingFee: e.target.value })
    }

    const handlequestion32 = (e) => {
        setData({ ...data, q22: e.target.value })
    }

    const handlequestion33 = (e) => {
        setData({ ...data, q23: e.target.value })
    }

    const handleLoanDetailChange = (e, rowIndex, fieldName) => {
        const updatedLoanDetail = [...loanDetail];
        updatedLoanDetail[rowIndex][fieldName] = e.target.value;
        setLoanDetail(updatedLoanDetail);
    };

    // const handleInputChange = (fieldName, value) => {
    //     setData((prevData) => ({
    //         ...prevData,
    //         [fieldName]: value,
    //     }));
    // };


    const handleInputChange = (fieldName, value) => {
        setData(prevData => {
            const newData = { ...prevData, [fieldName]: value };

            // Helper function to calculate total for a specific category
            const calculateTotal = (category) => {
                let total = 0;
                for (const key in newData) {
                    if (key.startsWith(category) && !isNaN(parseInt(newData[key]))) {
                        total += parseInt(newData[key]);
                    }
                }
                return total;
            };

            newData.totalfather = calculateTotal('fatherSalary') + calculateTotal('fatherPension') + calculateTotal('fatherAgriIncome') + calculateTotal('fatherBusinessIncome') + calculateTotal('fatherProfitBank') + calculateTotal('fatherHouseRent') + calculateTotal('fatherBusinessRent') + calculateTotal('fatherShares')
            newData.totalMother = calculateTotal('motherSalary') + calculateTotal('motherPension') + calculateTotal('motherAgriIncome') + calculateTotal('motherBusinessIncome') + calculateTotal('motherProfitBank') + calculateTotal('motherHouseRent') + calculateTotal('motherBusinessRent') + calculateTotal('motherShares')
            newData.totalSelf = calculateTotal('selfSalary') + calculateTotal('selfPension') + calculateTotal('selfAgriIncome') + calculateTotal('selfBusinessIncome') + calculateTotal('selfProfitBank') + calculateTotal('selfHouseRent') + calculateTotal('selfBusinessRent') + calculateTotal('selfShares')
            newData.totalSiblings = calculateTotal('siblingSalary') + calculateTotal('siblingPension') + calculateTotal('siblingAgriIncome') + calculateTotal('siblingBusinessIncome') + calculateTotal('siblingProfitBank') + calculateTotal('siblingHouseRent') + calculateTotal('siblingBusinessRent') + calculateTotal('siblingShares')
            newData.totalSpouse = calculateTotal('spouseSalary') + calculateTotal('spousePension') + calculateTotal('spouseAgriIncome') + calculateTotal('spouseBusinessIncome') + calculateTotal('spouseProfitBank') + calculateTotal('spouseHouseRent') + calculateTotal('spouseBusinessRent') + calculateTotal('spouseShares')
            newData.finalTotal = newData.totalfather + newData.totalMother + newData.totalSelf + newData.totalSiblings + newData.totalSpouse
            return newData;
        });
    };


    const [isFinanc, setIsFinanc] = useState(false)
    const [findis, setFinDis] = useState(false)


    const getFormData = async () => {
        try {
            const response = await getForm3()
            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
                setLoanDetail(response.message.FinancialBreakDown)
                setFinanceAssis(response.message.FinancialAssistance)
                setBankDetail(response.message.bankAccountDetails)
                setFinDis(response.message.bankAccountApplicable)
                setIsFinanc(response.message.bankAccountApplicable)
                setGrossSalary(response.message.IncomeDetails[0])
                setNetSalary(response.message.IncomeDetails[1])
                setPension(response.message.IncomeDetails[2])
                setBusinessIncome(response.message.IncomeDetails[3])
                setAgriIncome(response.message.IncomeDetails[4])
                setHouseProp(response.message.IncomeDetails[5])
                setBusinessProp(response.message.IncomeDetails[6])
                setShares(response.message.IncomeDetails[7])
                // setFeePayment({
                //     otherSource: (response.message.feePayer).toLowerCase() == "other" ? response.message.otherSpecified : "",
                //     source: response.message.feePayer,
                // });
                // setLoanDetail(response.message.semesterDetails)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()

    }, [])

    function removeDetail(e, i) {
        e.preventDefault();

        const updated = [...bankDetail]
        updated.splice(i, 1)
        setBankDetail(updated)
        const calc = parseFloat(data.AssetTotal) - parseFloat(bankDetail[i].ClosingBalance)
        setData({ ...data, AssetTotal: calc })
    }

    function removeLoanDetail(i) {
        const updated = [...loanDetail]
        updated.splice(i, 1)
        setLoanDetail(updated)
    }

    function removeFinanceDetail(i) {
        const updated = [...financeAssis]
        updated.splice(i, 1)
        setFinanceAssis(updated)
    }


    const handleCheckBoxFinanc = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setFinDis(true)
        }
        else {
            setFinDis(false)
        }
        setIsFinanc(e.target.checked)
    }

    const [validated, setValidated] = useState(false);



    const [supportingEdu, setSupportingEdu] = useState();


    const [grossSalary, setGrossSalary] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [netSalary, setNetSalary] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [pension, setPension] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [businessIncome, setBusinessIncome] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [AgriIncome, setAgriIncome] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [houseProp, setHouseProp] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [businessProp, setBusinessProp] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [Shares, setShares] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })


    const handleGrossChange = (e, key) => {
        const value = e.target.value;

        setGrossSalary((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const handleNetChange = (e, key) => {
        const value = e.target.value;

        setNetSalary((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const handlePensionChange = (e, key) => {
        const value = e.target.value;

        setPension((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const handleBIChange = (e, key) => {
        const value = e.target.value;

        setBusinessIncome((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const calculateTotalIncome = (incomeObject, state) => {
        const { father, mother, self: selfIncome, siblings, spouse } = incomeObject;

        // Convert string values to numbers (assuming they represent numbers)
        const fatherIncome = parseFloat(father) || 0;
        const motherIncome = parseFloat(mother) || 0;
        const selfIncomeValue = parseFloat(selfIncome) || 0;
        const siblingsIncome = parseFloat(siblings) || 0;
        const spouseIncome = parseFloat(spouse) || 0;

        // Calculate the total income
        const totalIncome = fatherIncome + motherIncome + selfIncomeValue + siblingsIncome + spouseIncome;
        console.log("TOTAL", totalIncome);
        console.log("TOTAL Other", data.otherTotalIncome);
        let calc = 0;

        const totalHP = parseFloat(houseProp.total) || 0;
        const totalBP = parseFloat(businessProp.total) || 0;
        const totalShares = parseFloat(Shares.total) || 0;


        if (state == "HP") {
            calc = totalIncome + totalBP + totalShares
        }
        // || state == "BP" || state == "shares"
        if (state == "BP") {
            calc = totalIncome + totalHP + totalShares
        }
        if (state == "shares") {
            calc = totalIncome + totalHP + totalBP
        }

        setData({ ...data, otherTotalIncome: calc })

        return totalIncome;
    };


    const handleAIChange = (e, key) => {
        const value = e.target.value;

        setAgriIncome((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const handleHPChange = (e, key) => {
        const value = e.target.value;

        setHouseProp((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome, "HP");

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });


    };

    const handleBPChange = (e, key) => {
        const value = e.target.value;

        setBusinessProp((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome, "BP");

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const handleShareChange = (e, key) => {
        const value = e.target.value;

        setShares((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome, "shares");

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log("DATA", data);
        // return

        // bankDetail
        const abc = [
            grossSalary,
            netSalary,
            pension,
            businessIncome,
            AgriIncome,
            houseProp,
            businessProp,
            Shares
        ]


        console.log("HANDLESUBMIT", bankDetail);
        const payload = {
            ...data,
            bankAccountDetails: bankDetail,
            IncomeDetails: abc,
            FinancialAssistance: financeAssis,
            FinancialBreakDown: loanDetail,
            relation: data.relation,
            bankAccountApplicable: findis,
            // q21: data.question31,
            // q22: data.question32,
            // q23: data.question33,
            // feeContributor: data.amountByGuardian,
            // perSemFee: data.perSemFee,
            // OutStandingFee: data.OutStandingFee,
            q21: data.q21,
            q22: data.q22,
            q23: data.q23,
            feeContributor: data.feeContributor,
            perSemFee: data.perSemFee,
            OutStandingFee: data.OutStandingFee,
            supportingEducation: data.supportingEducation,
            AssetTotal: data.AssetTotal,
            Comments: data.Comments,
            otherTotalIncome: data.otherTotalIncome
        }

        console.log("PPPAAAA", payload);

        const check = validation()

        if (check === "proceed") {
            console.log("API HIT");
            const response = await SubmissionForm3(payload)
            if (response.success) {
                GettabsData()
                alert(response.message, "success")
                navigate(`#${"additional_information"}`)
            }
            else {
                alert(response.message, "error")
            }
        }
        else {
            // alert("Error", "error")
            alert("Form Validation Error", "error")
        }

    };

    const handleEducationSupport = (e) => {
        try {
            setSupportingEdu(e.target.value)
            setData({ ...data, supportingEducation: e.target.value })
        }
        catch (e) {
            console.log(e);
        }
    }


    const [formErrors, setFormErrors] = useState([])
    let errors = {}

    const validation = () => {
        try {

            if (data.fatherEarning == "") {
                errors.fatherEarning = ("Is your Father earning is required")
            }
            if (data.motherEarning == "") {
                errors.motherEarning = ("Is your Mother earning is required")
            }
            if (data.selfEarning == "") {
                errors.selfEarning = ("Are you earning is required")
            }
            if (data.siblingEarning == "") {
                errors.siblingEarning = ("Are your Siblings earning is required")
            }
            if (data.spouseEarning == "") {
                errors.spouseEarning = ("Is your Spouse earning is required")
            }

            if (loanDetail.length > 0) {
                for (let i = 0; i < loanDetail.length; i++) {
                    if (loanDetail[i].Semester_Year == "") {
                        errors[`Semester_Year${i}`] = ("Semester / Year is required in Requirement of Loan ")
                    }
                    if (loanDetail[i].Total_Fee_Cost == "") {
                        errors[`Total_Fee_Cost${i}`] = ("Total Fee Cost is required in Requirement of Loan ")
                    }
                    if (loanDetail[i].Own_Contribution == "") {
                        errors[`Own_Contribution${i}`] = ("Own Contribution is required in Requirement of Loan ")
                    }
                    if (loanDetail[i].Loan_Required == "") {
                        errors[`Loan_Required${i}`] = ("Loan is required in Requirement of Loan ")
                    }
                }
            }

            if (data.q21 == "") {
                errors.q21 = ("Your general financial conditions is required ")
            }
            if (data.supportingEducation == "") {
                errors.supportingEducation = ("Supporting your educational expense is required")
            }
            if (data.supportingEducation == "yes") {
                // alert("test #3")
                if (data.feeContributor == "") {
                    errors.feeContributor = ("Fee Contributor is required")
                }
                if (data.relation == "") {
                    errors.relation = ("Relation with contributer is required")
                }
                if (data.perSemFee == "") {
                    errors.perSemFee = ("Per Semester Fee is required")
                }
                if (data.OutStandingFee == "") {
                    errors.OutStandingFee = ("OutStanding Fee is required")
                }
            }

            if (data.q22 == "") {
                errors.q22 = ("How you would be able to return the amount to IT is required")
            }
            if (data.q23 == "") {
                errors.q23 = ("Alternate Arrangements is required")
            }

            console.log("data.Comments", data.Comments);
            if (data.Comments == "" || !data.Comments) {
                errors.Comments = ("Detail for applying is required")
            }
            if ((Object.entries(errors)).length == 0) {
                setFormErrors([])
                return "proceed"
            }
            else {
                setFormErrors(errors)
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    function IsError(id) {
        return !(Object.keys(formErrors)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formErrors)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }



    const generateInputField = (fieldName) => (
        <div className="col-lg-4 col-md-6" key={fieldName}>
            <div className="form-group">
                <label className='form-label' >{fieldName.label}</label>
                <input
                    type="number"
                    className="form-control"
                    placeholder="Enter here"
                    value={data[fieldName.name] > 0 ? data[fieldName.name] : ""}
                    disabled={fieldName.name.startsWith("total") ? true : false}
                    onChange={(e) => handleInputChange(fieldName.name, e.target.value)}
                />
            </div>
        </div>
    );



    return (
        <>
            <div className="">

                {
                    (Object.values(formErrors)).length > 0 && (
                        <FormErrors errorList={formErrors} />
                    )
                }

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="accordion" id="accordionExample">

                        {/* Financial Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <img src={FINANCIAL_DETAIL} alt="icon" className='img-fluid form_icons' />
                                    Financial Details
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {/* <div className="form-check text-center mb-4">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" checked={isFinanc} onChange={(e) => handleCheckBoxFinanc(e)} />
                                        <label className="form-check-label" for="flexCheckDefault1" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                            Not Applicable For Me
                                        </label>
                                    </div> */}
                                    <div className="table_form mb-4">
                                        <div className='form_sub_heading mb-4'>
                                            <h5 className='mb-0'>Self and Family Members Bank Accounts Details</h5>
                                            <div className='d-flex jc-end'>
                                                <button className='btn add_btn' disabled={findis} onClick={(e) => Add_New_Bank_Detail(e)}>Add More Bank Account</button>
                                            </div>
                                        </div>

                                        {
                                            bankDetail.map((item, i) => {
                                                return <>
                                                    {
                                                        i > 0 &&
                                                        <div className="section_break"></div>
                                                    }

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId={`relation-${i}`} id='NameofAccount' className='form-group'>
                                                                <Form.Label>Relation</Form.Label>
                                                                <Form.Select name="relation" value={bankDetail[i].relation} onChange={(e) => handleInputChangeBANK(e, i)} aria-label="Default select example" >
                                                                    <option selected disabled value="">Select</option>
                                                                    <option value="self">Self</option>
                                                                    <option value="father">Father</option>
                                                                    <option value="mother">Mother</option>
                                                                    <option value="guardian">Guardian</option>
                                                                    <option value="Spouse">Spouse</option>
                                                                    <option value="other">Other</option>
                                                                </Form.Select>
                                                                {/* <Form.Control
                                                                    type="text"
                                                                    name="relation"
                                                                    value={bankDetail[i].relation}
                                                                    onChange={(e) => handleInputChangeBANK(e, i)}
                                                                    placeholder="Enter here"
                                                                    disabled={findis}
                                                                /> */}
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId={`accountTitle-${i}`} id='NameofAccount' className='form-group'>
                                                                <Form.Label>Account Title</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Nameof"
                                                                    value={bankDetail[i].Nameof}
                                                                    onChange={(e) => handleInputChangeBANK(e, i)}
                                                                    placeholder="Enter here"
                                                                    disabled={findis}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId={`bankName-${i}`} id='bankName' className='form-group'>
                                                                <Form.Label>Name of the Bank</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={bankDetail[i].NameBank}
                                                                    name="NameBank"
                                                                    onChange={(e) => handleInputChangeBANK(e, i)}
                                                                    placeholder="Enter here"
                                                                    disabled={findis}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId={`closingBalance-${i}`} id='closingBalance' className='form-group'>
                                                                <Form.Label>Closing Balance in Bank Account</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="ClosingBalance"
                                                                    value={bankDetail[i].ClosingBalance}
                                                                    onChange={(e) => handleInputChangeBANK(e, i)}
                                                                    placeholder="Enter here"
                                                                    disabled={findis}
                                                                />

                                                                {/* <NumericFormat
                                                                    thousandSeparator={true}
                                                                    prefix=""
                                                                    type="text"
                                                                    name="ClosingBalanceNum"
                                                                    value={bankDetail[i].ClosingBalanceNum}
                                                                    onChange={(e) => handleInputChangeBANK(e, i)}
                                                                    // type="number"
                                                                    placeholder="Enter here"
                                                                    disabled={findis}
                                                                    className="form-control"
                                                                /> */}

                                                            </Form.Group>
                                                        </div>
                                                        {
                                                            i > 0 && (
                                                                <div className='d-flex jc-end'>
                                                                    <button onClick={(e) => removeDetail(e, i)} className='btn delete_btn'>Remove Detail</button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            })
                                        }

                                        <div className="row">
                                            <div className="col-12 vertically_center">
                                                <Form.Group controlId="total" id='total' className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="total"
                                                        value={data.AssetTotal}
                                                        // onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                            <ImageUploadInput
                                                multiple={false}
                                                name="IncomeTaxFamily"
                                                text="Income Tax Return of All Family Members"
                                                url={FamilyUrl}
                                                itemkey="IncomeTaxFamily"
                                                accept=".pdf"
                                                classes="label-margin"
                                                dis={findis}
                                            />
                                        </div> */}
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                            <ImageUploadInput
                                                multiple={false}
                                                name="BankStatementFamily"
                                                text="Bank Statement of Last Three (03) Months (Latest) of All Family Members having an Account"
                                                url={FamilyUrl}
                                                itemkey="BankStatementFamily"
                                                accept=".pdf"
                                                dis={findis}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/*  Income Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <img src={INCOME_DETAIL} alt="icon" className='img-fluid form_icons' />
                                    Income Details
                                </button>
                            </h2>
                            <div id="collapseTwo" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <div className="table_form mb-4">
                                        {/* <h4>Details of Income</h4> */}
                                        {/* <h5>Main sources of Income:</h5> */}

                                        {/* FATHER DATA */}
                                        <Form.Group controlId="validationCustom08" id='fatherEarning' className='form-group'>
                                            <Form.Label>Is your Father earning? <span className='required'>*</span></Form.Label>
                                            <Form.Select
                                                value={data.fatherEarning}
                                                onChange={handleChange}
                                                aria-label="Default select example"
                                                name="fatherEarning"
                                                className={`${(formErrors.length == 0 ? "" : IsError("fatherEarning") ? "success" : "error")}`}
                                            >
                                                <option selected disabled value="">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </Form.Select>
                                            {
                                                (ShowError("fatherEarning"))[0] &&
                                                <p className='error_label'>{(ShowError("fatherEarning"))[1]}</p>
                                            }
                                        </Form.Group>

                                        {
                                            data.fatherEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Father Income Details</h5>
                                                    </div>

                                                    <div className="row">

                                                        {[
                                                            { name: 'fatherSalary', label: 'Salary' },
                                                            { name: 'fatherPension', label: 'Pension' },
                                                            { name: 'fatherAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'fatherBusinessIncome', label: 'Business Income' },
                                                            { name: 'fatherProfitBank', label: 'Profit in Bank' },
                                                            { name: 'fatherHouseRent', label: 'House Rent Income' },
                                                            { name: 'fatherBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'fatherShares', label: 'Shares and Securites' },
                                                            { name: 'totalfather', label: 'Total' }
                                                        ]
                                                            .map(fieldName =>
                                                                generateInputField(fieldName)
                                                            )}

                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherPaySlip"
                                                                text="Pay-slip"
                                                                url={newURL}
                                                                itemkey="fatherPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherPension"
                                                                text="Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="fatherPension"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherAgriIncome"
                                                                text="Income Certificate"
                                                                url={newURL}
                                                                itemkey="fatherAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherIncomeCertificate"
                                                                text="Rental Agreement"
                                                                url={newURL}
                                                                itemkey="fatherIncomeCertificate"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* MOTHER DATA */}
                                        <Form.Group controlId="validationCustom08" id='motherEarning' className='form-group'>
                                            <Form.Label>Is your Mother earning? <span className='required'>*</span></Form.Label>
                                            <Form.Select
                                                value={data.motherEarning}
                                                onChange={handleChange}
                                                aria-label="Default select example"
                                                name="motherEarning"
                                                className={`${(formErrors.length == 0 ? "" : IsError("motherEarning") ? "success" : "error")}`}
                                            >
                                                <option selected disabled value="">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </Form.Select>
                                            {
                                                (ShowError("motherEarning"))[0] &&
                                                <p className='error_label'>{(ShowError("motherEarning"))[1]}</p>
                                            }
                                        </Form.Group>

                                        {
                                            data.motherEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Mother Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'motherSalary', label: 'Salary' },
                                                            { name: 'motherPension', label: 'Pension' },
                                                            { name: 'motherAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'motherBusinessIncome', label: 'Business Income' },
                                                            { name: 'motherProfitBank', label: 'Profit in Bank' },
                                                            { name: 'motherHouseRent', label: 'House Rent Income' },
                                                            { name: 'motherBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'motherShares', label: 'Shares and Securites' },
                                                            { name: 'totalMother', label: 'Total' }
                                                        ].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}

                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherPaySlip"
                                                                text="Pay-slip"
                                                                url={newURL}
                                                                itemkey="motherPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherPension"
                                                                text="Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="motherPension"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherAgriIncome"
                                                                text="Income Certificate"
                                                                url={newURL}
                                                                itemkey="motherAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherIncomeCertificate"
                                                                text="Rental Agreement"
                                                                url={newURL}
                                                                itemkey="motherIncomeCertificate"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* SELF DATA */}
                                        <Form.Group controlId="validationCustom08" id='selfEarning' className='form-group'>
                                            <Form.Label>Are you earning? <span className='required'>*</span></Form.Label>
                                            <Form.Select
                                                value={data.selfEarning}
                                                onChange={handleChange}
                                                aria-label="Default select example"
                                                name="selfEarning"
                                                className={`${(formErrors.length == 0 ? "" : IsError("selfEarning") ? "success" : "error")}`}
                                            >
                                                <option selected disabled value="">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </Form.Select>
                                            {
                                                (ShowError("selfEarning"))[0] &&
                                                <p className='error_label'>{(ShowError("selfEarning"))[1]}</p>
                                            }
                                        </Form.Group>

                                        {
                                            data.selfEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Self Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'selfSalary', label: 'Salary' },
                                                            { name: 'selfPension', label: 'Pension' },
                                                            { name: 'selfAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'selfBusinessIncome', label: 'Business Income' },
                                                            { name: 'selfProfitBank', label: 'Profit in Bank' },
                                                            { name: 'selfHouseRent', label: 'House Rent Income' },
                                                            { name: 'selfBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'selfShares', label: 'Shares and Securites' },
                                                            { name: 'totalSelf', label: 'Total' }
                                                        ].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}

                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="selfPaySlip"
                                                                text="Pay-slip"
                                                                url={newURL}
                                                                itemkey="selfPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="selfPension"
                                                                text="Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="selfPension"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="selfAgriIncome"
                                                                text="Income Certificate"
                                                                url={newURL}
                                                                itemkey="selfAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="selfIncome"
                                                                text="Rental Agreement"
                                                                url={newURL}
                                                                itemkey="selfIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* SIBLINGS DATA */}
                                        <Form.Group controlId="validationCustom08" id='siblingEarning' className='form-group'>
                                            <Form.Label>Are your Siblings earning? <span className='required'>*</span></Form.Label>
                                            <Form.Select
                                                value={data.siblingEarning}
                                                onChange={handleChange}
                                                aria-label="Default select example"
                                                name="siblingEarning"
                                                className={`${(formErrors.length == 0 ? "" : IsError("siblingEarning") ? "success" : "error")}`}
                                            >
                                                <option selected disabled value="">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </Form.Select>
                                            {
                                                (ShowError("siblingEarning"))[0] &&
                                                <p className='error_label'>{(ShowError("siblingEarning"))[1]}</p>
                                            }
                                        </Form.Group>

                                        {
                                            data.siblingEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Siblings Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'siblingSalary', label: 'Salary' },
                                                            { name: 'siblingPension', label: 'Pension' },
                                                            { name: 'siblingAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'siblingBusinessIncome', label: 'Business Income' },
                                                            { name: 'siblingProfitBank', label: 'Profit in Bank' },
                                                            { name: 'siblingHouseRent', label: 'House Rent Income' },
                                                            { name: 'siblingBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'siblingShares', label: 'Shares and Securites' },
                                                            { name: 'totalSiblings', label: 'Total' }
                                                        ]
                                                            .map(fieldName =>
                                                                generateInputField(fieldName)
                                                            )}

                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="siblingPaySlip"
                                                                text="Pay-slip"
                                                                url={newURL}
                                                                itemkey="siblingPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="siblingPension"
                                                                text="Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="siblingPension"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="siblingAgriIncome"
                                                                text="Income Certificate"
                                                                url={newURL}
                                                                itemkey="siblingAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="siblingsIncome"
                                                                text="Rental Agreement"
                                                                url={newURL}
                                                                itemkey="siblingsIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* SPOUSE DATA */}
                                        <Form.Group controlId="validationCustom08" id='spouseEarning' className='form-group'>
                                            <Form.Label>Is your Spouse earning? <span className='required'>*</span></Form.Label>
                                            <Form.Select
                                                value={data.spouseEarning}
                                                onChange={handleChange}
                                                aria-label="Default select example"
                                                name="spouseEarning"
                                                className={`${(formErrors.length == 0 ? "" : IsError("spouseEarning") ? "success" : "error")}`}
                                            >
                                                <option selected disabled value="">Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </Form.Select>
                                            {
                                                (ShowError("spouseEarning"))[0] &&
                                                <p className='error_label'>{(ShowError("spouseEarning"))[1]}</p>
                                            }
                                        </Form.Group>

                                        {
                                            data.spouseEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Spouse Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'spouseSalary', label: 'Salary' },
                                                            { name: 'spousePension', label: 'Pension' },
                                                            { name: 'spouseAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'spouseBusinessIncome', label: 'Business Income' },
                                                            { name: 'spouseProfitBank', label: 'Profit in Bank' },
                                                            { name: 'spouseHouseRent', label: 'House Rent Income' },
                                                            { name: 'spouseBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'spouseShares', label: 'Shares and Securites' },
                                                            { name: 'totalSpouse', label: 'Total' }
                                                        ].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}

                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spousePaySlip"
                                                                text="Pay-slip"
                                                                url={newURL}
                                                                itemkey="spousePaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spousePension"
                                                                text="Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="spousePension"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spouseAgriIncome"
                                                                text="Income Certificate"
                                                                url={newURL}
                                                                itemkey="spouseAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spouseIncome"
                                                                text="Rental Agreement"
                                                                url={newURL}
                                                                itemkey="spouseIncome"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }


                                        <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>Total</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder=''
                                                name="finalTotal"
                                                value={data.finalTotal}
                                                disabled
                                            />
                                        </Form.Group>



                                        {/* <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>How many family members are earning? <span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder='Enter here'
                                                name="FamilyMemberEarning"
                                                value={data.FamilyMemberEarning}
                                                onChange={handleChange}
                                            />
                                        </Form.Group> */}

                                        {/* <div className="table-responsive mb-4">
                                            <table className="table table-bordered mb-0" style={{ width: "170%" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Father</th>
                                                        <th scope="col">Mother</th>
                                                        <th scope="col">Self</th>
                                                        <th scope="col">Siblings</th>
                                                        <th scope="col">Spouse</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th rowSpan={1}>Salary</th>
                                                        {['fatherSalary', 'motherSalary', 'selfSalary', 'siblingSalary', 'spouseSalary', 'totalSalary'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherPaySlip"
                                                                text="Father's Pay-slip"
                                                                url={newURL}
                                                                itemkey="fatherPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherPaySlip"
                                                                text="Mother's Pay-slip"
                                                                url={newURL}
                                                                itemkey="motherPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="selfPaySlip"
                                                                text="Self Pay-slip"
                                                                url={newURL}
                                                                itemkey="selfPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={true}
                                                                name="siblingPaySlip"
                                                                text="Siblings Pay-slip"
                                                                url={newURL}
                                                                itemkey="siblingPaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spousePaySlip"
                                                                text="Spouse Pay-slip"
                                                                url={newURL}
                                                                itemkey="spousePaySlip"
                                                                accept=".pdf"
                                                            />
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <th>Pension (if applicable)</th>
                                                        {['fatherPension', 'motherPension', 'selfPension', 'siblingPension', 'spousePension', 'totalPension'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherPension"
                                                                text="Father's Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="fatherPension"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherPension"
                                                                text="Mother's Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="motherPension"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="selfPension"
                                                                text="Self Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="selfPension"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={true}
                                                                name="siblingPension"
                                                                text="Siblings Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="siblingPension"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spousePension"
                                                                text="Spouse Pension Book Slip"
                                                                url={newURL}
                                                                itemkey="spousePension"
                                                                accept=".pdf"
                                                            />
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <th>Agriculture Income</th>
                                                        {['fatherAgriIncome', 'motherAgriIncome', 'selfAgriIncome', 'siblingAgriIncome', 'spouseAgriIncome', 'totalAgriIncome'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherAgriIncome"
                                                                text="Father's Income Certificate"
                                                                url={newURL}
                                                                itemkey="fatherAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherAgriIncome"
                                                                text="Mother's Income Certificate"
                                                                url={newURL}
                                                                itemkey="motherAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spouseAgriIncome"
                                                                text="Self Income Certificate"
                                                                url={newURL}
                                                                itemkey="spouseAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={true}
                                                                name="siblingAgriIncome"
                                                                text="Siblings Income Certificate"
                                                                url={newURL}
                                                                itemkey="siblingAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="selfAgriIncome"
                                                                text="Spouse Income Certificate"
                                                                url={newURL}
                                                                itemkey="selfAgriIncome"
                                                                accept=".pdf"
                                                            />
                                                        </td>

                                                    </tr>


                                                    <tr>
                                                        <th>Business Income</th>
                                                        {['fatherBusinessIncome', 'motherBusinessIncome', 'selfBusinessIncome', 'siblingBusinessIncome', 'spouseBusinessIncome', 'totalBusinessIncome'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="fatherIncomeCertificate"
                                                                text="Father's Income Certificate"
                                                                url={newURL}
                                                                itemkey="fatherIncomeCertificate"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="motherIncomeCertificate"
                                                                text="Mother's Income Certificate"
                                                                url={newURL}
                                                                itemkey="motherIncomeCertificate"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spouseIncome"
                                                                text="Self Income Certificate"
                                                                url={IncomeCertificateUrl}
                                                                itemkey="spouseIncomeCertificate"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={true}
                                                                name="siblingsIncome"
                                                                text="Siblings Income Certificate"
                                                                url={IncomeCertificateUrl}
                                                                itemkey="siblingIncomeCertificate"
                                                                accept=".pdf"
                                                            />
                                                        </td>
                                                        <td>
                                                            <ImageUploadInput
                                                                multiple={false}
                                                                name="spouseIncome"
                                                                text="Spouse Income Certificate"
                                                                url={IncomeCertificateUrl}
                                                                itemkey="spouseIncomeCertificate"
                                                                accept=".pdf"
                                                            />
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <th>Profit On Bank Deposit</th>
                                                        {['fatherProfitBank', 'motherProfitBank', 'selfProfitBank', 'siblingProfitBank', 'spouseProfitBank', 'totalProfitBank'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>

                                                    <tr>
                                                        <th>Houe Rental Income</th>
                                                        {['fatherHouseRent', 'motherHouseRent', 'selfHouseRent', 'siblingHouseRent', 'spouseHouseRent', 'totalHouseRent'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>

                                                    <tr>
                                                        <th>Business Rental Income</th>
                                                        {['fatherBusinessRent', 'motherBusinessRent', 'selfBusinessRent', 'siblingBusinessRent', 'spouseBusinessRent', 'totalBusinessRent'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>

                                                    <tr>
                                                        <th>Shares/Securities Income</th>
                                                        {['fatherShares', 'motherShares', 'selfShares', 'siblingShares', 'spouseShares', 'totalShares'].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div> */}

                                    </div>

                                    {/* <div className="table_form mb-4">

                                        <div className='form_sub_heading mb-4'>
                                            <h5 className='my-2'>Salary Income</h5>
                                        </div>

                                        <div className="row">
                                            <h5>Gross Salary</h5>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherSalary" className="form-group">
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={grossSalary.father}
                                                        onChange={(e) => handleGrossChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherSalary" className="form-group">
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={grossSalary.mother}
                                                        onChange={(e) => handleGrossChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="selfSalary" className="form-group">
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={grossSalary.self}
                                                        onChange={(e) => handleGrossChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsSalary" className="form-group">
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={grossSalary.siblings}
                                                        onChange={(e) => handleGrossChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spouseSalary" className="form-group">
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={grossSalary.spouse}
                                                        onChange={(e) => handleGrossChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="totalSalary" className="form-group">
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        value={grossSalary.total}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='section_break'></div>

                                        <div className="row">
                                            <h5>Net Salary</h5>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherSalary" className='form-group'>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={netSalary.father}
                                                        onChange={(e) => handleNetChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherSalary" className='form-group'>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={netSalary.mother}
                                                        onChange={(e) => handleNetChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="selfSalary" className='form-group'>
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={netSalary.self}
                                                        onChange={(e) => handleNetChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="fatherPaySlip"
                                                    text="Father's Pay-slip"
                                                    url={newURL}
                                                    itemkey="fatherPaySlip"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="motherPaySlip"
                                                    text="Mother's Pay-slip"
                                                    url={newURL}
                                                    itemkey="motherPaySlip"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="selfPaySlip"
                                                    text="Self Pay-slip"
                                                    url={newURL}
                                                    itemkey="selfPaySlip"
                                                    accept=".pdf"
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsSalary" className='form-group'>
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={netSalary.siblings}
                                                        onChange={(e) => handleNetChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spouseSalary" className='form-group'>
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={netSalary.spouse}
                                                        onChange={(e) => handleNetChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="totalSalary" className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        disabled
                                                        value={netSalary.total}
                                                        onChange={(e) => handleNetChange(e, 'total')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="siblingPaySlip"
                                                    text="Siblings Pay-slip"
                                                    url={newURL}
                                                    itemkey="siblingPaySlip"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="spousePaySlip"
                                                    text="Spouse Pay-slip"
                                                    url={newURL}
                                                    itemkey="spousePaySlip"
                                                    accept=".pdf"
                                                />
                                            </div>
                                        </div>

                                        <div className='form_sub_heading mb-4'>
                                            <h5 className='my-2'>Pension</h5>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherPension" className='form-group'>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={pension.father}
                                                        onChange={(e) => handlePensionChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherPension" className='form-group'>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={pension.mother}
                                                        onChange={(e) => handlePensionChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="selfPension" className='form-group'>
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={pension.slef}
                                                        onChange={(e) => handlePensionChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="fatherPension"
                                                    text="Father's Pension Book Slip"
                                                    url={newURL}
                                                    itemkey="fatherPension"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="motherPension"
                                                    text="Mother's Pension Book Slip"
                                                    url={newURL}
                                                    itemkey="motherPension"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="selfPension"
                                                    text="Self Pension Book Slip"
                                                    url={newURL}
                                                    itemkey="selfPension"
                                                    accept=".pdf"
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsPension" className='form-group'>
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={pension.siblings}
                                                        onChange={(e) => handlePensionChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spousePension" className='form-group'>
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={pension.spouse}
                                                        onChange={(e) => handlePensionChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="totalPension" className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        type="number"
                                                        className="form-control"
                                                        value={pension.total}
                                                        onChange={(e) => handlePensionChange(e, 'total')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="siblingPension"
                                                    text="Siblings Pension Book Slip"
                                                    url={newURL}
                                                    itemkey="siblingPension"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="spousePension"
                                                    text="Spouse Pension Book Slip"
                                                    url={newURL}
                                                    itemkey="spousePension"
                                                    accept=".pdf"
                                                />
                                            </div>
                                        </div>

                                        <div className='form_sub_heading mb-4'>
                                            <h5 className='my-2'>Business Income</h5>
                                        </div>

                                        <div className="row">
                                            <h5>Monthly Income</h5>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherIncome" className='form-group'>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessIncome.father}
                                                        onChange={(e) => handleBIChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherIncome" className='form-group'>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessIncome.mother}
                                                        onChange={(e) => handleBIChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="selfIncome" className='form-group'>
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessIncome.self}
                                                        onChange={(e) => handleBIChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="fatherIncomeCertificate"
                                                    text="Father's Income Certificate"
                                                    url={newURL}
                                                    itemkey="fatherIncomeCertificate"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="motherIncomeCertificate"
                                                    text="Mother's Income Certificate"
                                                    url={newURL}
                                                    itemkey="motherIncomeCertificate"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="spouseIncome"
                                                    text="Self Income Certificate"
                                                    url={IncomeCertificateUrl}
                                                    itemkey="spouseIncomeCertificate"
                                                    accept=".pdf"
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsIncome" className='form-group'>
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessIncome.siblings}
                                                        onChange={(e) => handleBIChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spouseIncome" className='form-group'>
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessIncome.spouse}
                                                        onChange={(e) => handleBIChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="totalIncome" className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        value={businessIncome.total}
                                                        onChange={(e) => handleBIChange(e, 'total')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="siblingsIncome"
                                                    text="Siblings Income Certificate"
                                                    url={IncomeCertificateUrl}
                                                    itemkey="siblingIncomeCertificate"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="spouseIncome"
                                                    text="Spouse Income Certificate"
                                                    url={IncomeCertificateUrl}
                                                    itemkey="spouseIncomeCertificate"
                                                    accept=".pdf"
                                                />
                                            </div>
                                        </div>

                                        <div className='form_sub_heading mb-4'>
                                            <h5 className='my-2'>Agriculture Income</h5>
                                        </div>

                                        <div className="row">
                                            <h5>Monthly Income</h5>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherIncome" className='form-group'>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={AgriIncome.father}
                                                        onChange={(e) => handleAIChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherIncome" className='form-group'>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={AgriIncome.mother}
                                                        onChange={(e) => handleAIChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="selfIncome" className='form-group'>
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={AgriIncome.self}
                                                        onChange={(e) => handleAIChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="fatherAgriIncome"
                                                    text="Father's Income Certificate"
                                                    url={newURL}
                                                    itemkey="fatherAgriIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="motherAgriIncome"
                                                    text="Mother's Income Certificate"
                                                    url={newURL}
                                                    itemkey="motherAgriIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="spouseAgriIncome"
                                                    text="Self Income Certificate"
                                                    url={newURL}
                                                    itemkey="spouseAgriIncome"
                                                    accept=".pdf"
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsIncome" className='form-group'>
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={AgriIncome.siblings}
                                                        onChange={(e) => handleAIChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spouseIncome" className='form-group'>
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={AgriIncome.spouse}
                                                        onChange={(e) => handleAIChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id="totalIncome" className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        value={AgriIncome.total}
                                                        onChange={(e) => handleAIChange(e, 'total')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="siblingAgriIncome"
                                                    text="Siblings Income Certificate"
                                                    url={newURL}
                                                    itemkey="siblingAgriIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="selfAgriIncome"
                                                    text="Spouse Income Certificate"
                                                    url={newURL}
                                                    itemkey="selfAgriIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                        </div>

                                        <div className='form_sub_heading mb-4'>
                                            <h5 className='my-2'>Other Income</h5>
                                        </div>

                                        <div className="row">
                                            <h5>From House Property</h5>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherHouseProperty" className='form-group'>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={houseProp.father}
                                                        onChange={(e) => handleHPChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherHouseProperty" className='form-group'>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={houseProp.mother}
                                                        onChange={(e) => handleHPChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="selfHouseProperty" className='form-group'>
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={houseProp.self}
                                                        onChange={(e) => handleHPChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsHouseProperty" className='form-group'>
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={houseProp.siblings}
                                                        onChange={(e) => handleHPChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spouseHouseProperty" className='form-group'>
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={houseProp.spouse}
                                                        onChange={(e) => handleHPChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="totalHouseProperty" className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        value={houseProp.total}
                                                        onChange={(e) => handleHPChange(e, 'total')}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='section_break'></div>

                                        <div className="row">
                                            <h5>From Business Property</h5>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherBusinessProperty" className='form-group'>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessProp.father}
                                                        onChange={(e) => handleBPChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherBusinessProperty" className='form-group'>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessProp.mother}
                                                        onChange={(e) => handleBPChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="selfBusinessProperty" className='form-group'>
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessProp.self}
                                                        onChange={(e) => handleBPChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsBusinessProperty" className='form-group'>
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessProp.siblings}
                                                        onChange={(e) => handleBPChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spouseBusinessProperty" className='form-group'>
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={businessProp.spouse}
                                                        onChange={(e) => handleBPChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="totalBusinessProperty" className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        value={businessProp.total}
                                                        onChange={(e) => handleBPChange(e, 'total')}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='section_break'></div>
                                        <div className="row">
                                            <h5>Shares/Securities</h5>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="fatherSharesSecurities" className='form-group'>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={Shares.father}
                                                        onChange={(e) => handleShareChange(e, 'father')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="motherSharesSecurities" className='form-group'>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={Shares.mother}
                                                        onChange={(e) => handleShareChange(e, 'mother')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="selfSharesSecurities" className='form-group'>
                                                    <Form.Label>Self</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={Shares.self}
                                                        onChange={(e) => handleShareChange(e, 'self')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="siblingsSharesSecurities" className='form-group'>
                                                    <Form.Label>Siblings</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={Shares.siblings}
                                                        onChange={(e) => handleShareChange(e, 'siblings')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="spouseSharesSecurities" className='form-group'>
                                                    <Form.Label>Spouse</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={Shares.spouse}
                                                        onChange={(e) => handleShareChange(e, 'spouse')}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id="totalSharesSecurities" className='form-group'>
                                                    <Form.Label>Total</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        value={Shares.total}
                                                        onChange={(e) => handleShareChange(e, 'total')}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 vertically_center">
                                                <Form.Group id="totalOtherIncome" className='form-group'>
                                                    <Form.Label>Total Other Income</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        disabled
                                                        value={data.otherTotalIncome}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="fatherOtherIncome"
                                                    text="Father's Income Certificate"
                                                    url={newURL}
                                                    itemkey="fatherOtherIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="motherOtherIncome"
                                                    text="Mother's Income Certificate"
                                                    url={newURL}
                                                    itemkey="motherOtherIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="selfOtherIncome"
                                                    text="Self Income Certificate"
                                                    url={newURL}
                                                    itemkey="selfOtherIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="siblingOtherIncome"
                                                    text="Siblings Income Certificate"
                                                    url={newURL}
                                                    itemkey="siblingOtherIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="spouseOtherIncome"
                                                    text="Spouse Income Certificate"
                                                    url={newURL}
                                                    itemkey="spouseOtherIncome"
                                                    accept=".pdf"
                                                />
                                            </div>
                                        </div>

                                    </div> */}
                                </div>
                            </div>
                        </div>

                        {/* Financial Appraisal */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <img src={FINANCIAL_APPR} alt="icon" className='img-fluid form_icons' />
                                    Requirement of Loan
                                </button>
                            </h2>
                            <div id="collapseThree" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='financial_appraisal'>

                                        {/* Financial Breakdown */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='mb-0'>For how many Semesters/Years do you need Financial Assistance?</h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' onClick={(e) => Add_New_Loan_Detail(e)}><FaPlus /></button>
                                                </div>
                                            </div>

                                            {loanDetail.map((data, i) => {
                                                return <>
                                                    {
                                                        i > 0 &&
                                                        <div className="section_break"></div>
                                                    }

                                                    <div className="row">

                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Semester_Year${i}`} className="form-group">
                                                                <Form.Label>{user.type === "semester" ? "Semester" : "Year"} <span className='required'>*</span></Form.Label>
                                                                <Form.Select
                                                                    value={data.Semester_Year}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Semester_Year')}
                                                                    name="Semester_Year"
                                                                    required
                                                                    // className={`${(formErrors.length == 0 ? "" : IsError("totalSem") ? "success" : "error")}`}>
                                                                    className={`${(formErrors.length == 0 ? "" : IsError(`Semester_Year${i}`) ? "success" : "error")}`}>
                                                                    <option selected disabled value="">Select</option>
                                                                    {
                                                                        user.type == "semester" &&
                                                                        semesters.map((semester, i) => {
                                                                            return <>
                                                                                <option value={semester}>{semester}</option>
                                                                            </>
                                                                        })
                                                                    }
                                                                    {
                                                                        user.type == "year" &&
                                                                        years.map((year, i) => {
                                                                            return <>
                                                                                <option value={year}>{year}</option>
                                                                            </>
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                                {
                                                                    (ShowError(`Semester_Year${i}`)) &&
                                                                    <p className='error_label'>{(ShowError(`Semester_Year${i}`))}</p>
                                                                }
                                                            </Form.Group>
                                                        </div>

                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Total_Fee_Cost${i}`} className='form-group'>
                                                                <Form.Label>Total Fee Cost <span className='required'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter here"
                                                                    value={data.Total_Fee_Cost}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Total_Fee_Cost')}
                                                                    required
                                                                    className={`${(formErrors.length == 0 ? "" : IsError(`Total_Fee_Cost${i}`) ? "success" : "error")}`}
                                                                />
                                                                {
                                                                    (ShowError(`Total_Fee_Cost${i}`)) &&
                                                                    <p className='error_label'>{(ShowError(`Total_Fee_Cost${i}`))}</p>
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Own_Contribution${i}`} className='form-group'>
                                                                <Form.Label>Own Contribution <span className='required'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter here"
                                                                    value={data.Own_Contribution}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Own_Contribution')}
                                                                    required
                                                                    className={`${(formErrors.length == 0 ? "" : IsError(`Own_Contribution${i}`) ? "success" : "error")}`}
                                                                />
                                                                {
                                                                    (ShowError(`Own_Contribution${i}`)) &&
                                                                    <p className='error_label'>{(ShowError(`Own_Contribution${i}`))}</p>
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Loan_Required${i}`} className='form-group'>
                                                                <Form.Label>Loan Required <span className='required'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter here"
                                                                    value={data.Loan_Required}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Loan_Required')}
                                                                    required
                                                                    className={`${(formErrors.length == 0 ? "" : IsError(`Loan_Required${i}`) ? "success" : "error")}`}
                                                                />
                                                                {
                                                                    (ShowError(`Loan_Required${i}`)) &&
                                                                    <p className='error_label'>{(ShowError(`Loan_Required${i}`))}</p>
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                        {
                                                            i > 0 && (
                                                                <div className='d-flex jc-end'>
                                                                    <button onClick={() => removeLoanDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            })
                                            }

                                        </div>

                                        {/* Financial Assistance */}
                                        <div className="table_form mb-2">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='mb-0'>Financial Assistance Previously Received (if any)</h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' onClick={(e) => Add_New_Finance_Detail(e)}><FaPlus /></button>
                                                </div>
                                            </div>

                                            {financeAssis.map((data, i) => {
                                                return <>
                                                    {
                                                        i > 0 &&
                                                        <div className="section_break"></div>
                                                    }

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Name_of_Organization_${i}`} className='form-group'>
                                                                <Form.Label>Name of Organization</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter here"
                                                                    name="Name_of_Organization"
                                                                    value={financeAssis[i].Name_of_Organization}
                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Type_of_Assistance_${i}`} className='form-group'>
                                                                <Form.Label>Type of Assistance</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter here"
                                                                    name="Type_of_Assistance"
                                                                    value={financeAssis[i].Type_of_Assistance}
                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Amount_Applied_For_${i}`} className='form-group'>
                                                                <Form.Label>Amount Applied For</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter here"
                                                                    name="Amount_Applied_For"
                                                                    value={financeAssis[i].Amount_Applied_For}
                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id={`Amount_Received_${i}`} className='form-group'>
                                                                <Form.Label>Amount Received</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter here"
                                                                    name="Amount_Received"
                                                                    value={financeAssis[i].Amount_Received}
                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        {
                                                            i > 0 &&
                                                            <div className='d-flex jc-end'>
                                                                <button onClick={() => removeFinanceDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            })
                                            }

                                        </div>

                                        <div className="row">
                                            <div className="col-12 vertically_center">
                                                <Form.Group id="q21" className='form-group'>
                                                    <Form.Label>
                                                        Have your general financial conditions changed in the recent years? Or are there any reasons to believe that they may change in the days to come? <span className='required'>*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data.q21}
                                                        onChange={(e) => handlequestion31(e)}
                                                        rows={5}
                                                        placeholder="Type here..."
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("q21") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("q21"))[0] &&
                                                        <p className='error_label'>{(ShowError("q21"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-12 vertically_center">
                                                <Form.Group id="supportingEducation" className='form-group'>
                                                    <Form.Label>
                                                        Are any of the family members beside father/mother/guardian supporting your educational expense at this institute? <span className='required'>*</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        className={`${(formErrors.length == 0 ? "" : IsError("supportingEducation") ? "success" : "error")}`}
                                                        value={data.supportingEducation} onChange={(e) => handleEducationSupport(e)} aria-label="Default select example">
                                                        <option selected disabled value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </Form.Select>
                                                    {
                                                        (ShowError("supportingEducation"))[0] &&
                                                        <p className='error_label'>{(ShowError("supportingEducation"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            {
                                                data.supportingEducation == "yes" &&
                                                <>
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group id="feeContributor" className='form-group'>
                                                            <Form.Label>Amount Contributed Rs. <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={data.feeContributor}
                                                                onChange={(e) => handleamountByGuardian(e)}
                                                                placeholder="Enter here"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("feeContributor") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("feeContributor"))[0] &&
                                                                <p className='error_label'>{(ShowError("feeContributor"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group id="relation" className='form-group'>
                                                            <Form.Label>Relation <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={data.relation}
                                                                onChange={(e) => handleRelation(e)}
                                                                placeholder="Enter here"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("relation") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("relation"))[0] &&
                                                                <p className='error_label'>{(ShowError("relation"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group id='perSemFee' className='form-group'>
                                                            <Form.Label>Per Semester Fee <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={data.perSemFee}
                                                                onChange={(e) => handleperSemFee(e)}
                                                                placeholder="Enter here"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("perSemFee") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("perSemFee"))[0] &&
                                                                <p className='error_label'>{(ShowError("perSemFee"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group id='OutStandingFee' className='form-group'>
                                                            <Form.Label>Outstanding Fee <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={data.OutStandingFee}
                                                                onChange={(e) => handleOutStandingFee(e)}
                                                                placeholder="Enter here"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("OutStandingFee") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("OutStandingFee"))[0] &&
                                                                <p className='error_label'>{(ShowError("OutStandingFee"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                </>
                                            }

                                            <div className="col-12 vertically_center">
                                                <Form.Group id="q22" className='form-group'>
                                                    <Form.Label>How you would be able to return the amount to IT enabling it to provide similar facilities to other applicants? <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data.q22}
                                                        onChange={(e) => handlequestion32(e)}
                                                        rows={5}
                                                        placeholder="Type here..."
                                                        required
                                                        className={`${formErrors.length == 0 ? "" : IsError("q22") ? "success" : "error"}`}
                                                    />
                                                    {
                                                        (ShowError("q22"))[0] &&
                                                        <p className="error_label">{(ShowError("q22"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-12 vertically_center">
                                                <Form.Group id="q23" className='form-group'>
                                                    <Form.Label>What alternate arrangements have you made or can you make in case an Interest-Free Loan by Ihsan Trust is not approved?<span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data.q23}
                                                        onChange={(e) => handlequestion33(e)}
                                                        rows={5}
                                                        placeholder="Type here..."
                                                        required
                                                        className={`${formErrors.length == 0 ? "" : IsError("q23") ? "success" : "error"}`}
                                                    />
                                                    {
                                                        (ShowError("q23"))[0] &&
                                                        <p className="error_label">{(ShowError("q23"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-12 vertically_center">
                                                <Form.Group id='Comments' className='form-group'>
                                                    <Form.Label>Explain in detail, why are you applying for the Interest-free Loan from Ihsan trust? <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data.Comments}
                                                        onChange={handleChange}
                                                        rows={5}
                                                        placeholder="Type here..."
                                                        name="Comments"
                                                        required
                                                        className={`${formErrors.length == 0 ? "" : IsError("Comments") ? "success" : "error"}`}
                                                    />
                                                    {
                                                        (ShowError("Comments"))[0] &&
                                                        <p className="error_label">{(ShowError("Comments"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='d-flex jc-between gap-2 mt-4'>
                        <button className='btn prev_btn' onClick={() => navigate(`#${"academic_background"}`)}>Previous</button>
                        <button className='btn next_btn' type='submit'>Save and Next</button>
                    </div>

                </Form >
            </div >
        </>
    )
}

export default Financial_Information