import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { useProSidebar } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Table from '../../components/Table/Table';
import IconText from '../../components/Table/IconText';
import Text from '../../components/Table/Text';
import TooltipComponent from '../../components/tooltip';
import Pagination from '../../components/Pagination';
import Badge from "../../components/Badges"
import { TbReportAnalytics } from 'react-icons/tb';
import { useToast } from '../../ContextAPI/Components/toast';
import { useConfirm } from '../../ContextAPI/Components/confirm';
import { freshCasesFilterAdmin, getAllTranch, payTranch, getAllApplications, getAllUniversities, getProgram, updateApplicationStatus, undoTranch } from '../../ContextAPI/APIs/api';
import { status } from '../../helpers/data';
import { useParams } from 'react-router-dom';
import UserModal from '../../components/Modals/Modal';
import Input from '../../components/Form/Input';
import { useAuth } from '../../ContextAPI/Components/auth';
import formatter from '../../helpers/number_formatter';


function Tranch() {

    const { user } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Get the value of the 'filter' parameter
    const filter = queryParams.get('filter');

    // const { filter } = useParams()s;
    console.log("fff222", filter);

    const { alert } = useToast()
    const { Ask } = useConfirm()

    // const [modalItem, setModalItem] = useState("");
    // const [show, setShow] = useState(false);


    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(false);



    const [reason, setReason] = useState("")
    const [date123, setDate123] = useState("")

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const navigate = useNavigate();
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [applications, setApp] = useState([])
    const getAppData = async () => {
        try {
            const response = await getAllApplications()
            console.log("Response", response);
            if (response.success) {
                setApp(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp?.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate
        }
        else {
            return "---"
        }
    }


    const [selectedUni, setSelectedUni] = useState([])

    const [selectedProgram, setSelectedProgram] = useState([])

    const [selectedStatus, setSelectedStatus] = useState([])



    const getAllFresh = async () => {
        try {
            const response = await freshCasesFilterAdmin()
            console.log("DDD", response.message);
            if (response.success) {

                setApp(response.message)
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const [prog, setProg] = useState([])
    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [uni, setUni] = useState([])
    const getAllunis = async () => {
        try {
            const response = await getAllUniversities()
            if (response.success) {
                console.log("AAAAA", response);
                setUni(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const search = async (uni, prog, stat, skip) => {
        try {

            const payload = {
                uni: uni,
                program: prog,
                status: stat,
            }

            const response = await freshCasesFilterAdmin(payload)
            if (response.success) {
                console.log("FILTERR", response.message);
                setApp(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const clear = async () => {
        try {

            console.log("selectedUni", selectedUni);
            console.log("selectedProgram", selectedProgram);
            // console.log("selectedCity", selectedCity);
            setSelectedProgram([])
            setSelectedStatus([])
            setSelectedUni([])
            const response = await freshCasesFilterAdmin()
            // setApp(response.message)
            if (response.success) {
                console.log("DDD", response.message);
                setApp(response.message)
            }

        }
        catch (e) {
            console.log(e);
        }

    }


    const handleStatusChange = (id) => {

        const isSelected = selectedStatus.includes(id);
        if (isSelected) {
            const abc = selectedStatus.filter((uniId) => uniId !== id)

            setSelectedStatus(abc);
            getTranches(selectedUni, selectedProgram, abc, 0)
        } else {

            const upd = [...selectedStatus, id]
            setSelectedStatus(upd);

            // search()
            getTranches(selectedUni, selectedProgram, upd, 0)

            // search(selectedUni, selectedProgram, upd)
        }
    };


    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {
            const abc = selectedProgram.filter((uniId) => uniId !== id)

            setSelectedProgram(abc);

            // search(selectedUni, abc, selectedStatus)
            getTranches(selectedUni, abc, selectedStatus, 0)

        } else {
            const upd = [...selectedProgram, id]
            setSelectedProgram(upd);

            // search()

            // search(selectedUni, upd, selectedStatus)
            getTranches(selectedUni, upd, selectedStatus, 0)

        }
    };


    const handleUniChange = (id) => {
        const isSelected = selectedUni.includes(id);
        if (isSelected) {
            const abc = selectedUni.filter((uniId) => uniId !== id)

            setSelectedUni(abc);

            // search(abc, selectedProgram, selectedStatus)

            getTranches(abc, selectedProgram, selectedStatus, 0)

        } else {
            const upd = [...selectedUni, id]

            setSelectedUni(upd);
            // search(upd, selectedProgram, selectedStatus)
            getTranches(upd, selectedProgram, selectedStatus, 0)
        }
    };


    const [tranch, setTranch] = useState([])
    const [totalCount, setTotalCount] = useState(4)

    const getTranches = async (uni, prog, status, skip) => {
        try {
            const response = await getAllTranch({
                uni: uni,
                program: prog,
                status: status
            }, skip)
            if (response.success && response.message.length > 0) {
                setTranch(response.message)
                setTotalCount(response.totalCount)
            } else {
                setTranch([])
                setTotalCount(0)

            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const [data, setData] = useState({ name: "", amount: "" })
    const handleChange = (e) => {
        if (e.target.name == "amount") {
            setData({ ...data, amount: e.target.value })
        }
        if (e.target.name == "name") {
            setData({ ...data, name: e.target.value })
        }
    }

    const pay = async (id, name) => {
        try {
            if (data.name !== name) {
                setData({ ...data, name: "", amount: "" })
                handleClose()

                return alert("Invalid name", false)
            }
            const response = await payTranch({ id: id, amount: data.amount })
            alert(response.message, response.success)
            if (response.success) {
                getTranches(selectedUni, selectedProgram, selectedStatus, pageSkip)
                setData({ ...data, name: "", amount: "" })
                handleClose()

            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const undo = async (id, name) => {
        try {
            if (data.name !== name) {
                setData({ ...data, name: "" })
                handleClose()
                return alert("Invalid name", false)
            }
            console.log("chlagyaaa");

            const response = await undoTranch({ id: id })
            alert(response.message, response.success)
            if (response.success) {
                getTranches(selectedUni, selectedProgram, selectedStatus, pageSkip)
                setData({ ...data, name: "" })
                handleClose()
            }
        }
        catch (e) {
            console.log(e);
        }

    }


    useEffect(() => {
        getTranches(selectedUni, selectedProgram, selectedStatus, pageSkip)

        getAllProg()
        getAllunis()


        // console.log("fff", filter);
        // if (filter === "interview") {
        //     // const isSelected = selectedStatus.includes("interview");
        //     const upd = [...selectedStatus, "interview"]
        //     setSelectedStatus(upd);
        //     search(selectedUni, selectedProgram, upd)
        // } else {
        //     getAllFresh()
        // }
    }, [])


    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        // search(selectedUni, selectedProgram, selectedStatus, skip)
        getTranches(selectedUni, selectedProgram, selectedStatus, skip)
    }


    return (
        <>
            <Helmet>
                <title>Disbursements</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='fresh_cases_section'>
                        <h2>Disbursements</h2>

                        <div className="card">

                            <div className="filter d-flex ai-center jc-between mb-3">
                                <div className='d-flex ai-center'>
                                    <p>Filter By</p>

                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Universities
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>
                                                {
                                                    uni.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.fullName}</label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleUniChange(item._id)}
                                                                    checked={selectedUni.includes(item._id)}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Programs
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>
                                                {
                                                    prog.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.name}</label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleProgramChange(item._id)}
                                                                    checked={selectedProgram.includes(item._id)}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Status
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>

                                            <div className='uni_filter_body'>
                                                {
                                                    ["unpaid", "paid"].map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item}`}>{item == "pending" ? "processing" : item}</label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item}`}
                                                                    onChange={() => handleStatusChange(item)}
                                                                    checked={selectedStatus.includes(item)}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    {
                                        (selectedProgram.length > 0 || selectedUni.length > 0 || selectedStatus.length > 0) && (
                                            <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                        )
                                    }
                                </div>
                            </div>

                            {
                                tranch?.length > 0 ?
                                    <>
                                        <Table head={["Student Name", "University Name", "Amount", "%", "Status", "Time"]}>
                                            {
                                                tranch?.map((data, i) => {
                                                    return <tr key={i}>
                                                        <td><Text text={data?.userId?.fullName || "---"} /></td>
                                                        <td><Text text={data?.institute?.fullName || "---"} /></td>
                                                        <td><Text text={data?.amount ? formatter(data?.amount) : "---"} /></td>
                                                        <td><Text text={`${data?.percentageOfFee}%` || "---"} /></td>
                                                        <td><Badge text={data?.status || "---"} classes={`${data?.status} md`} /></td>
                                                        <td><Text text={timestampToDate(data?.timestamp) || "---"} /></td>
                                                        {
                                                            (user.role == "admin" || user.role == "operations" || user.role == "finance") && (
                                                                <>
                                                                    {
                                                                        data?.status === "unpaid" && (
                                                                            <td>
                                                                                {/* <button onClick={() => pay(data._id)} className='btn save_btn'>Pay Tranch</button> */}
                                                                                <UserModal btnText={"Pay Tranch"} heading="Pay Tranch" id={`filter${i}`} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                                                    <div className="row">

                                                                                        <div className="col-12">
                                                                                            <div className="form-group">
                                                                                                <Input
                                                                                                    inputType="number"
                                                                                                    label="Enter Amount"
                                                                                                    placeholder="Enter here"
                                                                                                    name="amount"
                                                                                                    // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                    onChange={(e) => handleChange(e)}
                                                                                                    tooltip={true}
                                                                                                    tooltipText="Mentioned percentage of amount will be disbursed. Diburse accordingly!"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="form-group">
                                                                                                <label className='form-label mb-0'>To Pay the Tranch enter <span className='tranch_pill'>{data?.userId?.fullName}</span></label>
                                                                                                <Input
                                                                                                    inputType="text"
                                                                                                    placeholder="Enter here"
                                                                                                    name="name"
                                                                                                    // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                    onChange={(event) => handleChange(event)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d-flex jc-end'>
                                                                                            <button onClick={() => pay(data._id, data?.userId?.fullName)} className='btn save_btn'>Pay</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </UserModal>
                                                                            </td>
                                                                        )
                                                                    }
                                                                    {
                                                                        (data?.status === "paid" && data.tranchNo == 2) && (
                                                                            <td>
                                                                                {/* <button onClick={() => undo(data._id)} className='btn save_btn'>Undo</button> */}
                                                                                <UserModal btnText={"Revert Tranch"} heading="Revert this Disbursement" id={`filter${i}`} customClasses="delete_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <div className="form-group">
                                                                                                <label className='form-label mb-0'>To revert this Tranch enter <span className='tranch_pill'>{data?.userId?.fullName}</span></label>
                                                                                                <Input
                                                                                                    inputType="text"
                                                                                                    placeholder="Enter here"
                                                                                                    name="name"
                                                                                                    // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                    onChange={(e) => handleChange(e)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='d-flex jc-end'>
                                                                                            <button onClick={() => undo(data._id, data?.userId?.fullName)} className='btn save_btn'>Revert Now</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </UserModal>
                                                                            </td>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={4} />
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }

                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Tranch