import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useProSidebar } from 'react-pro-sidebar';
import TooltipComponent from '../../components/tooltip';

import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Table from '../../components/Table/Table';
import IconText from '../../components/Table/IconText';
import Text from '../../components/Table/Text';
import Input from "../../components/Form/Input"

import profile from "../../assets/profile.svg"
import Pagination from '../../components/Pagination';

import { getAllUniversities, getUNI, uniStatusToggle, getUNIProfile, getProgram, uniFilter, getUNIsData, getTop5UNIS, getTop5PROG } from '../../ContextAPI/APIs/api';
import { UserCard } from '../../components/UserCard';
import { Show_Profile_Pic } from '../../components/Profile/upload_profilePic';

import Badge from "../../components/Badges"

import PieChartUni from '../../components/Charts/Chart.Universities/PieChartUni';
import UniBarChart from '../../components/Charts/Chart.Universities/UniBarChart';
import FreshCasesChart from '../../components/Charts/Chart.Universities/FreshCasesChart';
import RenewalCasesChart from '../../components/Charts/Chart.Universities/RenewalCasesChart';


import FreshRadialBarChart from '../../components/Charts/Charts.Students/FreshRadialBarChart';
import RenewalRadialBarChart from '../../components/Charts/Charts.Students/RenewalRadialBarChart';

import CountUp from 'react-countup';
import { LiaUniversitySolid } from 'react-icons/lia';
// import cities from "../../helpers/data"

import { cities, defaultFields } from '../../helpers/data';
// import { useToast } from '../../ContextAPI/Components/toast';


import { useToast } from "../../ContextAPI/Components/toast"
const universities = [
    {
        uni_name: "SSUET",
        uni_type: "Engineering",
        budget: 80000,
        outstanding_amount: 90000
    },
    {
        uni_name: "Agha Khan",
        uni_type: "Medical",
        budget: 60000,
        outstanding_amount: 90000
    },
]


function Universities() {
    const { alert } = useToast();
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [allUniversities, setAllUniversities] = useState([])

    async function GetAllUniversities() {
        const res = await getAllUniversities()
        if (res.success) setAllUniversities(res.message)
    }

    useEffect(() => {
        // GetAllUniversities()
    }, [])

    const [showProfile, setShowProfile] = useState("ShowUniversities")

    const [campuses, setCampuses] = useState([])
    const [programs, setPrograms] = useState([])

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [show, setShow] = useState("university");

    const [uni, setUni] = useState([])

    const getAllUni = async () => {
        try {
            const response = await getUNI()
            if (response?.success) {
                setUni(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        console.log((hash.split("#"))[1] || "university");
        setShow((hash.split("#"))[1] || "university")
    }, [hash])

    const [uniId, setUniId] = useState()
    const openUNIPAGE = (id) => {
        try {
            navigate(`#${"university_name"}`)
            getAllData(id)
            setUniId(id)
        }
        catch (e) {
            console.log(e);
        }
    }

    const openBackPage = () => {
        try {
            setUniId()
            navigate(`#${"university"}`)
        }
        catch (e) {
            console.log(e);
        }
    }

    const [sUNI, setsUNI] = useState({})

    const getAllData = async (id) => {
        try {
            const response = await getUNIProfile({ uniId: id })
            console.log("REPO", response);

            if (response.success) {
                setsUNI(response?.message)
                setCampuses(response?.message?.campuses || [])
                setPrograms(response?.message?.programs || [])
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [selectedCity, setSelectedCity] = useState([])

    const [selectedProgram, setSelectedProgram] = useState([])

    const [prog, setProg] = useState([])

    const [totalCount, setTotalCount] = useState(4)

    const getAllFresh = async (skip) => {
        try {
            const response = await uniFilter({}, pageSkip)
            console.log("DDD", response.message);
            if (response.success) {
                // setApp(response.message)
                setAllUniversities(response.message)
                setTotalCount(response.totalCount)
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }




    const handleCityChange = (id) => {
        const isSelected = selectedCity.includes(id);
        if (isSelected) {
            const abc = selectedCity.filter((uniId) => uniId !== id)
            setSelectedCity(selectedCity);
            // search(selectedProgram, abc)

        } else {
            const update = [...selectedCity, id]
            setSelectedCity(update);
            // search(selectedProgram, update)

        }
    };


    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {
            const abc = selectedProgram.filter((uniId) => uniId !== id)

            // setSelectedProgram((prevSelectedUni) => prevSelectedUni.filter((uniId) => uniId !== id));
            setSelectedProgram(abc);
            search(abc, selectedCity, 0)

        } else {
            const update = [...selectedProgram, id]
            setSelectedProgram(update)
            search(update, selectedCity, 0)
        }
    };


    const clear = async () => {
        try {
            // console.log("selectedUni", selectedUni);
            console.log("selectedProgram", selectedProgram);
            // console.log("selectedCity", selectedCity);

            setSelectedProgram([])
            setSelectedCity([])
            // setSelectedUni([])
            const response = await uniFilter({}, 0)
            if (response.success) {
                console.log("FILTERR", response.message);

                setAllUniversities(response.message)
                search([], [], 0)
                // setAllUsers(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }

    }

    const search = async (prog, city, skip) => {
        try {
            // program: selectedProgram,

            const payload = {
                program: prog,
                // city: selectedCity
            }
            console.log("---------------------------------------------");
            const response = await uniFilter(payload, skip)
            if (response.success) {
                console.log("FILTERR", response.message);

                setAllUniversities(response.message)
                // setAllUsers(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [uniC, setUniC] = useState(0)
    const [appC, setAppC] = useState(0)
    const [renC, steRenC] = useState(0)

    const cardData = async () => {
        try {
            const response = await getUNIsData()
            console.log("RREE", response);
            if (response.success) {
                setUniC(response?.uniCount)
                setAppC(response?.AppCount)
                steRenC(response?.RenewalCount)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [barData, setBarData] = useState({
        count: [],
        names: []
    })

    const [GraphCount, setGraphCount] = useState(0)

    const [uniCo, setUniCo] = useState(0)

    const getPie = async () => {
        try {
            const response = await getTop5UNIS()
            if (response.success) {
                console.log("PIE", response);
                setBarData({ ...barData, count: response.count, names: response.names })

                // setUniCount(response?.count)
                if (response?.count.length > 0) {
                    let a = 0;
                    const arraytoSum = response?.count

                    for (let i = 0; i < arraytoSum.length; i++) {
                        if (arraytoSum[i] > 0) {
                            a++
                        }
                    }

                    console.log("arraytoSum", arraytoSum);
                    const sum = arraytoSum.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    console.log("sum", sum);
                    setGraphCount(sum)
                    setUniCo(a)
                }

            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const [pieData, setPieData] = useState([])
    const [tooltip, setTooltip] = useState([])
    const getPieChart = async () => {
        try {
            const response = await getTop5PROG()
            if (response.success && response?.count.length > 0) {
                console.log("PIEChart111", response);
                const a = (response?.count.map(item => parseInt(item)) || []).reduce((a, b) => a + b, 0);


                if (a > 0) {
                    setPieData(response?.count)
                    setTooltip(response?.names)
                }

                console.log("AAA", a);



                // setBarData({ ...barData, count: response.count, names: response.names })
                // setUniCount(response?.count)

            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const toggle = async (id, status) => {
        try {
            console.log("00", id, status);
            const response = await uniStatusToggle({ id: id, status: status })
            alert(response.message, response.success)
            if (response.success) {
                getAllFresh()
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    useEffect(() => {
        getPieChart()
        getPie()
        getAllProg()
        getAllUni()
        getAllFresh()
        cardData()
    }, [])



    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(selectedProgram, selectedCity, skip)
    }



    return (

        <>

            <Helmet>
                <title>Universities</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='uni_profile_manag_section all_users_section'>

                        <h2>Universities</h2>

                        <div className={`${GraphCount == 0 ? "grid_row_3" : "grid_row_4"} mb-4`}>
                            {/* <div className="grid_row_4 mb-4"> */}
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div className='text-center grid_card_header'>
                                            <LiaUniversitySolid className='grid_icon' style={{ color: "#924AEF" }} />
                                        </div>
                                        <div>
                                            <h4>
                                                <CountUp prefix="" start={0} end={uniC} decimal="." decimals={0} duration={1.75} />
                                            </h4>
                                            <p>No. of Universities</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div className='grid_card_header'>
                                            {/* <FreshCasesChart appC={appC} /> */}
                                            <RenewalRadialBarChart noStd={parseInt(appC + renC) || 1} noMale={appC} noFemale={renC} />

                                            {/* <TbClock24 className='grid_icon' style={{ color: "#17a2b8" }} /> */}
                                        </div>
                                        <div>
                                            <h4>
                                                <span><CountUp prefix="" start={0} end={appC} decimal="." decimals={0} duration={1.75} /></span>
                                            </h4>
                                            <p>Fresh Cases</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div className='grid_card_header'>
                                            {/* <RenewalCasesChart renC={renC} /> */}
                                            <FreshRadialBarChart noStd={parseInt(appC + renC) || 1} noFemale={renC} noMale={appC} />

                                            {/* <IoCalendarOutline className='grid_icon' style={{ color: "#ffc107" }} /> */}
                                        </div>
                                        <div>
                                            <h4>
                                                <span><CountUp prefix="" start={0} end={renC} decimal="." decimals={0} duration={1.75} /></span>

                                            </h4>
                                            <p>Renewal Cases</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                GraphCount > 0 && (
                                    <div className='mb-2'>
                                        <div className="card grid_card h-100">
                                            <div className="content">
                                                <div className='grid_card_header'>
                                                    <UniBarChart barData={barData} />
                                                    {/* <TfiBarChart className='grid_icon' style={{ color: "#6c757d" }} /> */}
                                                </div>
                                                <div>
                                                    <h4>
                                                        <span><CountUp prefix="" start={0} end={GraphCount} decimal="." decimals={0} duration={1.75} /></span>
                                                    </h4>
                                                    <p>Top {uniCo} Universities</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>

                        <div className='unversity_card'>

                            <div className="row row_reverse">
                                <div className={`col-lg-${(pieData.length == 0 && tooltip.length == 0) ? 12 : 9} mb-3`}>
                                    <div className="card">

                                        <h4 className='mb-3'>Registered Universities</h4>

                                        <div className="filter d-flex ai-center jc-between mb-3">
                                            <div className='d-flex ai-center'>
                                                <p>Filter By</p>

                                                <div className="dropdown">
                                                    <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Programs
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <div className='uni_filter_header d-flex jc-between'>
                                                            <p>Name</p>
                                                            <p className='me-0'>Filter</p>
                                                        </div>
                                                        <div className='uni_filter_body'>
                                                            {
                                                                prog.map((item, i) => (
                                                                    <li key={i}>
                                                                        <div className="form-check">
                                                                            <div>
                                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                            {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                            <div>
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id={`uniCheckbox_${item._id}`}
                                                                                    onChange={() => handleProgramChange(item._id)}
                                                                                    checked={selectedProgram.includes(item._id)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }

                                                        </div>
                                                    </ul>
                                                </div>
                                                {/* <div className="dropdown">
                                                    <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> Cities </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <div className='uni_filter_header d-flex jc-between'>
                                                            <p>Name</p>
                                                            <p className='me-0'>Filter</p>
                                                        </div>
                                                        <div className='uni_filter_body'>
                                                            {
                                                                cities.map((item, i) => (
                                                                    <li key={i}>
                                                                        <div className="form-check">
                                                                            <label className="form-check-label" for="flexCheckDefault">{item}</label>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                value=""
                                                                                id={`uniCheckbox_${item}`}
                                                                                onChange={() => handleCityChange(item)}
                                                                                checked={selectedCity.includes(item)}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </div>
                                                    </ul>
                                                </div> */}
                                            </div>

                                            {
                                                selectedProgram.length > 0 && (
                                                    <div>
                                                        <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                                    </div>

                                                )
                                            }

                                        </div>

                                        {
                                            allUniversities.length > 0 ?
                                                <>

                                                    <Table head={["Name", "Status", " ", " "]}>
                                                        {
                                                            allUniversities?.map((data, i) => {
                                                                return <tr key={i}>
                                                                    <td className="text-left">
                                                                        <div className='d-flex ai-center'>
                                                                            <div>
                                                                                <TooltipComponent className="tooltip" placement="top" tooltipText="View Profile">
                                                                                    <div>
                                                                                        <Link to={`/university-profile/${data?.uniId?._id}`} >
                                                                                            <Show_Profile_Pic src={data?.uniId?.profilepic} role={data?.uniId?.role} classes="profile-image sm mb-0" />
                                                                                        </Link>
                                                                                    </div>
                                                                                </TooltipComponent>
                                                                            </div>
                                                                            <div>
                                                                                <IconText text={data?.uniId?.fullName} smallText={data?.uniId?.email || "NA"} />
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td><Badge text={data?.uniId?.status === "active" ? "Active" : data?.uniId?.status || "---"} classes={`${data?.uniId?.status} md`} /></td>

                                                                    {/* <td><Text text={data?.uniId?.status || "NA"} /></td> */}
                                                                    {/* <td><Text text={data?.phone || "NA"} /></td> */}
                                                                    {/* <td className="text-capital"><Text text={data?.profile?.city || "NA"} /></td> */}
                                                                    {/* <td><Badges text={data?.status || "NA"} classes={`${data?.status || "pending"} md`} /></td> */}

                                                                    <td>
                                                                        <div className="form-check form-switch">
                                                                            <input className={`form-check-input ${data?.uniId?.status}`} onChange={() => toggle(data?.uniId?._id, data?.uniId?.status === "active" ? "inactive" : "active")} type="checkbox" id="flexSwitchCheckChecked" checked={data?.uniId?.status === "active" ? true : false} />
                                                                        </div>
                                                                    </td>


                                                                    <td>
                                                                        <Link to={`/university-profile/${data?.uniId?._id}`} className='link'>
                                                                            <button className='btn save_btn'>View</button>
                                                                        </Link>

                                                                        {/* <div className="dropdown">
                                                                            <button className="btn dropdown-toggle action_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i className="fa fa-ellipsis-v"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                <Link to={`/university-profile/${data?.uniId?._id}`} className="dropdown-item">View</Link>
                                                                            </div>
                                                                        </div> */}
                                                                    </td>

                                                                    {/* <td>
                                                                        <div className="dropdown">
                                                                            <button className="btn dropdown-toggle action_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <i className="fa fa-ellipsis-v"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                {
                                                                                    data?.uniId?.status === "active" ?
                                                                                        <button className='btn save-btn' onClick={() => toggle(data?.uniId?._id, "inactive")}>Inactive</button>
                                                                                        :
                                                                                        <button className='btn save-btn' onClick={() => toggle(data?.uniId?._id, "active")}>active</button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td> */}
                                                                </tr>
                                                            })
                                                        }
                                                    </Table>

                                                    <Pagination totalCount={totalCount} itemsPerPage={10} handlePagination={handlePagination} />
                                                </>
                                                :
                                                <h5 className='not_found_msg'>No Records Found</h5>
                                        }

                                    </div>
                                </div>
                                {
                                    (pieData.length > 1 && tooltip.length > 1) && (
                                        <>
                                            {/* <h2 className='mb-3 h2_md'>Registered Universities</h2> */}
                                            <div className="col-lg-3 col-12 mb-4 pl-lg-0">
                                                <div className="card pieChart_card">
                                                    <p>Programs ratio university wise</p>
                                                    <PieChartUni uniCount={pieData} tooltip={tooltip} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>


                            {/* <div className="allUser">
                                <div className="user_row">
                                    {
                                        allUniversities.map((data, i) => {
                                            return <>
                                                <div key={i} className="user_card mb-3">
                                                    <UserCard data={data} />
                                                </div>
                                            </>
                                        })
                                    }
                                </div>
                            </div> */}

                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}


export default Universities