import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import Form from "react-bootstrap/Form"
import { getForm5, SubmissionForm5 } from '../../ContextAPI/APIs/api';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useToast } from '../../ContextAPI/Components/toast';
import FormErrors from './FormErrors';

function SOP() {

    const { GettabsData, tabData } = useAuth();
    const { alert } = useToast();
    const navigate = useNavigate();

    const [sop, setSop] = useState({ SOP: "" })

    const handleSop = (e) => {
        setSop({ ...sop, SOP: e.target.value })
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            SOPs: sop.SOP
        }

        const check = validation()
        if (check === "proceed") {
            console.log("PPAAYYLLOOAADD", payload);
            const response = await SubmissionForm5(payload)
            if (response.success) {
                GettabsData()
                if (tabData.form1 && tabData.form2 && tabData.form3 && tabData.form4) {
                    navigate(`#${"preview"}`)
                    // alert("hello")
                }
            }
            alert(response.message, response.success)
        }
        else {
            alert("Form Validation Error", "error")
        }




    };

    const getFormData = async () => {
        try {
            const response = await getForm5()
            console.log("REP", response);
            if (response.success) {
                setSop({ ...sop, SOP: response.message?.SOPs })
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    let errors = {}
    const [formErrors, setFormErrors] = useState([])
    const validation = () => {
        try {
            if (sop.SOP == "") {
                errors.SOP = ("SOP is required")
            }

            if ((Object.entries(errors)).length == 0) {
                setFormErrors([])
                return "proceed"
            }
            else {
                setFormErrors(errors)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    function IsError(id) {
        return !(Object.keys(formErrors)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formErrors)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }


    useEffect(() => {
        getFormData()
    }, [])


    return (
        <>
            {
                (Object.values(formErrors)).length > 0 && (
                    <FormErrors errorList={formErrors} />
                )
            }

            <div className="table_form">
                <div className='mb-4 form_sub_heading'>
                    <h5 className='my-2'>Statement of Purpose</h5>
                </div>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <div className="col-md-12">
                    <div className='sop_section'>
                        <h5>Statement of Purpose means:</h5>
                        <ul>
                            <li>What the applicant wants to do in his/her life</li>
                            <li>What ambitions you have, what special you have done in the past</li>
                            <li>What peculiar and special qualities and personal attributes you posses which makes you
                                eligible for Ihsan Trust Interest Free Loan scheme
                            </li>
                            <li>What plans you have for your country Pakistan to make it a better place to live</li>
                        </ul>
                        <div className='mb-4'>
                            <Form.Group id="SOP">
                                <Form.Label className='form-label'>Statement of Purpose (SOP) in Life <span className='required'>*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={sop.SOP}
                                    onChange={(e) => handleSop(e)}
                                    className={`${formErrors.length == 0 ? "" : IsError("SOP") ? "success" : "error"}`}
                                    cols={30}
                                    rows={7}
                                    placeholder='Type here...'
                                    required
                                />
                                {
                                    (ShowError("SOP"))[0] &&
                                    <p className="error_label">{(ShowError("SOP"))[1]}</p>
                                }
                            </Form.Group>
                        </div>

                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"additional_information"}`)}>Previous</button>
                            <button className='btn next_btn' type='submit'>Save and Next</button>
                        </div>
                    </div>
                </div>

            </Form>
        </>
    )
}

export default SOP
