import '../../pages/Students/Form/Form.css'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';
import { useAuth } from '../../ContextAPI/Components/auth';
import { getrenewalByAdmin, validateRenewal } from '../../ContextAPI/APIs/api';
import { RenewalUrl } from '../../helpers/data';
import { FileViewer } from '../../components/Form/fileViewer';
import { FaFileCircleXmark } from "react-icons/fa6";
import { FaFacebook, FaInstagram } from "react-icons/fa";

import Navbar from '../../components/Navbar';
import NavSidebar from '../../components/Sidebar';
import Preview from '../../components/RenewalFormAdmin/Preview';
import { IoArrowBack } from 'react-icons/io5';



function RenewalFormForAdmin() {

    const { id, doc } = useParams();
    const { hash } = useLocation();
    const { user } = useAuth();
    const navigate = useNavigate();

    const Next1 = async () => navigate(`#${"step_2"}`)
    const Next2 = async () => navigate(`#${"step_3"}`)

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [showForm, setShowForm] = useState("preview");


    const [data, setData] = useState({
        date: "",
        Name: "",
        Institute: "",
        Program: "",
        contactNo: "",
        email: "",
        status: "",
        sem_year: "",
        cGPA: "",
        meezanBanckAccount: "",
        jobStatus: "",
        Amt_Disb_ToDate: "",
        Monthly_rePay: "",
        rePayDate: "",
        amountOutstanding: "",
        promptRepay: "",
        question1: "",
        question2: "",
    })

    const [data3, setData3] = useState({
        prevTotalIncome: "",
        currTotalIncome: "",
        prevTotalExpense: "",
        currTotalExpense: "",
        prevTotalAssets: "",
        currTotalAssets: "",
        amtRequested: "",
        revMonthlyRepay: "",
        srcMonthlyRepay: "",
    })

    const getData = async () => {
        try {
            const response = await getrenewalByAdmin(id)
            if (response.success) {
                console.log("RENEW", response);
                setData(response.message)
                setData3(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function timestampToDate(timestamp) {

        if (timestamp) {
            const milliseconds = timestamp.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate

        }
        else {
            return "---"
        }
    }

    useEffect(() => {
        // getData()

    }, [])

    useEffect(() => {
        console.log((hash.split("#"))[1] || "preview");
        setShowForm((hash.split("#"))[1] || "preview")

    }, [hash])




    return (
        <>
            <Helmet>
                <title>Renewal Form</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>

                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='renewal_form_section section_main'>

                        <div className='header mb-4'>
                            <div className='heading'>
                                <div className='d-flex ai-center jc-center'>
                                    {/* <Link to={`/renewal-cases-history/${id}/${doc}#cases-detail`}>
                                        <IoArrowBack className='back_arrow_icon me-3' />
                                    </Link> */}
                                    <h2 className='mb-0'>Renewal Form</h2>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            {/* Preview */}
                            {
                                showForm == "preview" &&
                                <Preview />
                            }

                        </div>
                    </section>


                    {/* 1 */}
                    {
                        showForm == "step_1" &&
                        <section className='section_one py-5'>
                            {
                                (user?.role == "admin" || user?.role == "operations") ?
                                    <div className="container">
                                        <div className="card">
                                            <p>Documents to be attached (Mandatory) “Without these required documents
                                                APPLICATION WILL BE CANCELLED without Intimation”:
                                            </p>
                                            <div className='mt-3'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Last 3 Months Bank Statements</label>
                                                    {data?.bankStmt ?
                                                        <FileViewer file="pdf" urls={[`${RenewalUrl}/${data?.bankStmt}`]} />
                                                        :
                                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                                            <FaFileCircleXmark className='gray_icon file_icon' />
                                                            <p className='mb-0 ml-2'>File Not Found</p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='form-group'>
                                                    <label className='form-label'>Latest Salary Slip (all family members who are earning)</label>
                                                    {data?.salarySlip ?
                                                        <FileViewer file="pdf" urls={[`${RenewalUrl}/${data?.salarySlip}`]} />
                                                        :
                                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                                            <FaFileCircleXmark className='gray_icon file_icon' />
                                                            <p className='mb-0 ml-2'>File Not Found</p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='form-group'>
                                                    <label className='form-label'>Latest (paid (if any) and unpaid) Fee Voucher / Fee Ledger</label>
                                                    {data?.paidFeeVoucher ?
                                                        <FileViewer file="pdf" urls={[`${RenewalUrl}/${data?.paidFeeVoucher}`]} />
                                                        :
                                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                                            <FaFileCircleXmark className='gray_icon file_icon' />
                                                            <p className='mb-0 ml-2'>File Not Found</p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='form-group'>
                                                    <label className='form-label'>Latest Transcript / Result Sheet</label>
                                                    {data?.latestTranscript ?
                                                        <FileViewer file="pdf" urls={[`${RenewalUrl}/${data?.latestTranscript}`]} />
                                                        :
                                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                                            <FaFileCircleXmark className='gray_icon file_icon' />
                                                            <p className='mb-0 ml-2'>File Not Found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className='mt-3'>
                                                <p>Do’s:</p>
                                                <ul>
                                                    <li>The applicant must visit Ihsan Trust website before interview and must
                                                        LIKE (Follow See First) Ihsan Trust’s social media channels i.e. Ihsan
                                                        Trust Facebook page & Instagram page. <br />
                                                        <a href="http://www.facebook.com/ihsantrust" target='_blank' className='social_icons'>
                                                            <FaFacebook className='me-2' />
                                                            <p>http://www.facebook.com/ihsantrust</p>
                                                        </a>
                                                        <a href="http://www.instagram.com/ihsantrust__" target='_blank' className='social_icons'>
                                                            <FaInstagram className='me-2' />
                                                            <p>http://www.instagram.com/ihsantrust__</p>
                                                        </a>
                                                    </li>
                                                    <li>The applicant must have thoroughly read the whole <span> Tarjuma e Quran (Translation of Quran) </span> before Renewal Interview otherwise case will be rejected.</li>
                                                    <li>The applicant must have (opened) his/her own <span> bank account </span> in <span>Meezan Bank Ltd. (MBL)</span>.</li>
                                                    <li><span>All monthly repayments must be cleared before Renewal Interview.</span></li>
                                                    <li>Incomplete Forms or Forms without supporting documents shall not be considered</li>
                                                    <li>Submit your application form to your university’s Focal Person/Financial Aid Officer only.</li>
                                                </ul>
                                            </div>

                                            <div className='d-flex jc-end mt-3'>
                                                <button className='btn next_btn' onClick={() => Next1()}>Next</button>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <h1>Please Update Your Profile</h1>
                                    </div>

                            }
                        </section>
                    }

                    {/* 2 */}
                    {
                        showForm == "step_2" &&
                        <section className='section_two py-5'>
                            <div className="container">
                                <div className="card">
                                    <h1>Ihsan Trust</h1>
                                    <p>Interest Free Loan Student Summary (Renewal Case)</p>

                                    <div className="row">
                                        <div className='d-flex jc-end'>
                                            <div className='form-group d-flex ai-center date'>
                                                <label className="form-label mb-0 me-2">Date:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="date"
                                                    value={timestampToDate(data.date)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Name of the applicant:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="Name"
                                                    value={data?.Name}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Name of the Institution:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="Institute"
                                                    value={data?.Institute?.fullName}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Program:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="Program"
                                                    value={data?.Program?.name}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Contact Number:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="contactNo"
                                                    value={data?.contactNo}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">E-Mail ID:</label>
                                                <input disabled
                                                    type="email"
                                                    className='form-control'
                                                    name="email"
                                                    value={data.email}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Status:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    value={data.status}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Semester/Year:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="sem_year"
                                                    value={data.sem_year}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Current (latest) CGPA:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="cGPA"
                                                    value={data.cGPA}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Meezan Bank Current Account No:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="meezanBanckAccount"
                                                    value={data.meezanBanckAccount}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className="form-label">Job Status:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="meezanBanckAccount"
                                                    value={data?.jobStatus}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <h3>Case history</h3>

                                    <div className="row case_history">
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Amount Disbursed to Date:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control w-50'
                                                    name="Amt_Disb_ToDate"
                                                    value={timestampToDate(data.Amt_Disb_ToDate)}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Monthly Repayment Amount:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control w-50'
                                                    name="Monthly_rePay"
                                                    value={data.Monthly_rePay}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Repayments Made to Date:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control w-50'
                                                    name="rePayDate"
                                                    value={timestampToDate(data.rePayDate)}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Amount Outstanding:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control w-50'
                                                    name="amountOutstanding"
                                                    value={data.amountOutstanding}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Prompt repayment:</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control w-50'
                                                    name="amountOutstanding"
                                                    value={data.promptRepay}
                                                    placeholder='Enter here'
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">*If No, explain why:</label>
                                                <textarea
                                                    disabled
                                                    cols="30"
                                                    rows="5"
                                                    className='form-control'
                                                    name="question1"
                                                    value={data.question1}
                                                    placeholder='Type here...'
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Any other financial assistance received during the review period? If yes, then what are the amount and the type of the financial assistance?</label>
                                                <textarea
                                                    disabled
                                                    cols="30"
                                                    rows="5"
                                                    className='form-control'
                                                    name="question2"
                                                    value={data.question2}
                                                    placeholder='Type here...'
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex jc-between'>
                                        <button className='btn prev_btn' onClick={() => navigate(`#${"step_1"}`)}>Previous</button>
                                        <button className='btn next_btn' onClick={() => Next2()}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                    {/* 3 */}
                    {
                        showForm == "step_3" &&
                        <section className='section_three py-5'>
                            <div className="container">
                                <div className="card">

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Previous Total Income (Rs.)</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    name="prevTotalIncome"
                                                    value={data3.prevTotalIncome}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Current Total Income (Rs.)</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    name="currTotalIncome"
                                                    value={data3.currTotalIncome}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className='form-group mb-4'>
                                                <label className="form-label">If the current total income is different from previous total income, then what’s the reason for that change?</label>
                                                <textarea
                                                    disabled
                                                    cols="30"
                                                    rows="1"
                                                    className='form-control textarea'
                                                    placeholder='Type here...'
                                                    name="reasonForIncomeChange"
                                                    value={data3?.reasonForIncomeChange}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Previous Total Expenses(Rs.)</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    name="prevTotalExpense"
                                                    value={data3?.prevTotalExpense}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Current Total Expenses (Rs.)</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    name="currTotalExpense"
                                                    value={data3?.currTotalExpense}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className='form-group mb-4'>
                                                <label className="form-label">Any new major expense added? If yes, then what are the source and the amount being incurred?</label>
                                                <textarea
                                                    disabled
                                                    cols="30"
                                                    rows="1"
                                                    className='form-control textarea'
                                                    placeholder='Type here...'
                                                    name="newMajorExpense"
                                                    value={data3?.newMajorExpense}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Previous value of total assets of the family (Rs.)</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    name="prevTotalAssets"
                                                    value={data3.prevTotalAssets}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Current value of total assets of the family (Rs.)</label>
                                                <input disabled
                                                    type="text"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    name="currTotalAssets"
                                                    value={data3.currTotalAssets}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">If the current value of total assets is different from the previous value, then please state the reason for that change.</label>
                                                <textarea
                                                    disabled
                                                    cols="30"
                                                    rows="1"
                                                    className='form-control textarea'
                                                    placeholder='Type here...'
                                                    name="reasonForAssetsChange"
                                                    value={data3.reasonForAssetsChange}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <h3>Case extension</h3>

                                        <div className="row case_history">
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className="form-label">Amount Requested:</label>
                                                    <input disabled
                                                        type="text"
                                                        className='form-control w-50'
                                                        placeholder='Enter here'
                                                        name="amtRequested"
                                                        value={data3.amtRequested}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className="form-label">Reviewed Monthly Repayment Amount:</label>
                                                    <input disabled
                                                        type="text"
                                                        className='form-control w-50'
                                                        placeholder='Enter here'
                                                        name="revMonthlyRepay"
                                                        value={data3.revMonthlyRepay}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className="form-label">Source of Monthly Repayment:</label>
                                                    <textarea
                                                        disabled
                                                        cols="30"
                                                        rows="3"
                                                        className='form-control'
                                                        placeholder='Type here...'
                                                        name="srcMonthlyRepay"
                                                        value={data3.srcMonthlyRepay}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex jc-between'>
                                        <button className='btn prev_btn' onClick={() => navigate(`#${"step_2"}`)}>Previous</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                </div>
            </div>

        </>
    )
}

export default RenewalFormForAdmin
