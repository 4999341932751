import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';
import CountUp from 'react-countup';
import { UserCard } from '../../components/UserCard';
import { FiltersSTD, getAllStudents, getAllUniversities, getProgram, getRepaymentYear, getStudentsData, getTop5UNIS } from '../../ContextAPI/APIs/api';
import Navbar from '../../components/Navbar'
import NavSidebar from '../../components/Sidebar'
import Badges from '../../components/Badges';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import IconText from '../../components/Table/IconText';
import Pagination from '../../components/Pagination';
import TooltipComponent from '../../components/tooltip';
import { Show_Profile_Pic } from '../../components/Profile/upload_profilePic';
import { PiStudentFill } from 'react-icons/pi';
import { cityList, defaultFields } from '../../helpers/data';

import MaleRadialBarChart from '../../components/Charts/Charts.Students/MaleRadialBarChart';
import FemaleRadialBarChart from '../../components/Charts/Charts.Students/FemaleRadialBarChart';
import BarChart from '../../components/Charts/Charts.Students/BarChart';
import PieChart from '../../components/Charts/Charts.Students/PieChart';



const all_users = [
    {
        name: "Ahmed",
        email: "xyz@gmail.com",
        status: "Active",
        role: "Student",
        created_at: "10-10-2023",
        actions: "Active"
    },
    {
        name: "Asad",
        email: "xyz@gmail.com",
        status: "Active",
        role: "Admin",
        created_at: "10-10-2023",
        actions: "Inactive"
    },
]

function AllUsers() {

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const [allUsers, setAllUsers] = useState([])

    const [totalCount, setTotalCount] = useState(4)

    async function GetAllUsers() {
        const res = await FiltersSTD({}, pageSkip)

        console.log("HELLO", res.message);
        if (res.success) {
            setAllUsers(res.message)
            setTotalCount(res.totalCount)
        }
    }

    const [noStd, setCountSTD] = useState(0)
    const [noMale, setCountMale] = useState(0)
    const [noFemale, setCountFemale] = useState(0)
    const [uniCount, setUniCount] = useState([])
    const [city, setCity] = useState([])
    const [count, setCount] = useState([])

    const [barData, setBarData] = useState({
        count: [],
        names: []
    })

    const [GraphCount, setGraphCount] = useState(0)
    const [cityCount, setCityCount] = useState(0)
    const getCards = async () => {
        try {
            const abc = cityList
            // console.log("CCIITTYYY", abc);
            const encodedCities = encodeURIComponent(JSON.stringify(abc));
            console.log("CCIITTYYY", encodedCities);

            const response = await getStudentsData(encodedCities)
            if (response.success) {
                console.log("STD", response);
                setCountSTD(response.students)
                setCountMale(response.male)
                setCountFemale(response.female)
                setGraphCount(response.graphTotal)
                setCityCount(response.cityCount)
                // setCity(response.cities)
                // setCount(response.count || [])
                // const cityArray = JSON.parse(response.cities);
                const cityArray = response.cities;

                setBarData({ ...barData, count: response.count, names: cityArray })
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const [pieTooltip, setPieToolTip] = useState([])
    const getPie = async () => {
        try {
            const response = await getTop5UNIS()
            console.log("PIE", response);
            if (response.success && response?.count.length > 0) {

                const a = (response?.count.map(item => parseInt(item)) || []).reduce((a, b) => a + b, 0);
                if (a > 0) {
                    setUniCount(response?.count)
                    setPieToolTip(response?.names)
                }

                console.log("AAA", a);

            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [prog, setProg] = useState([])
    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [uni, setUni] = useState([])
    const getAllunis = async () => {
        try {
            const response = await getAllUniversities()
            if (response.success) {
                console.log("AAAAA", response);
                setUni(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const [selectedUni, setSelectedUni] = useState([])
    const [selectedCity, setSelectedCity] = useState([])
    const [selectedProgram, setSelectedProgram] = useState([])

    const handleUniChange = (id) => {
        const isSelected = selectedUni.includes(id);
        if (isSelected) {
            const abc = selectedUni.filter((uniId) => uniId !== id)
            setSelectedUni(abc);
            search(abc, selectedProgram, selectedCity, 0)

        } else {
            const update = [...selectedUni, id]
            setSelectedUni(update);
            search(update, selectedProgram, selectedCity, 0)
        }
    };
    const handleCityChange = (id) => {
        const isSelected = selectedCity.includes(id);
        if (isSelected) {

            const abc = selectedCity.filter((uniId) => uniId !== id)

            // setSelectedCity((prevSelectedUni) => prevSelectedUni.filter((uniId) => uniId !== id));
            setSelectedCity(abc);
            search(selectedUni, selectedProgram, abc, 0)
        } else {

            const update = [...selectedCity, id]
            setSelectedCity(update);
            search(selectedUni, selectedProgram, update, 0)

        }
    };


    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {

            const abc = selectedProgram.filter((uniId) => uniId !== id)
            // setSelectedProgram((prevSelectedUni) => prevSelectedUni.filter((uniId) => uniId !== id));
            setSelectedProgram(abc);
            search(selectedUni, abc, selectedCity, 0)

        } else {
            const update = [...selectedProgram, id]
            setSelectedProgram(update);
            search(selectedUni, update, selectedCity, 0)
            // search(selectedUni, prevSelectedUni, selectedCity)
        }
    };

    const clear = () => {
        try {
            console.log("selectedUni", selectedUni);
            console.log("selectedProgram", selectedProgram);
            console.log("selectedCity", selectedCity);

            setSelectedProgram([])
            setSelectedCity([])
            setSelectedUni([])

            search([], [], [], pageSkip)
        }
        catch (e) {
            console.log(e);
        }

    }

    const search = async (uni, prog, city, skip) => {
        try {

            const payload = {
                uni: uni,
                program: prog,
                city: city
            }

            const response = await FiltersSTD(payload, skip)
            if (response.success) {
                console.log("FILTERR", response.message);
                setAllUsers(response.message)
                setTotalCount(response.totalCount)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getCards()
        getPie()
        getAllProg()
        getAllunis()
        GetAllUsers()
    }, [])




    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(selectedUni, selectedProgram, selectedCity, skip)
    }



    return (
        <>
            <Helmet>
                <title>Students List</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='all_users_section'>

                        <h2>Students</h2>

                        <div className={`${GraphCount == 0 ? "grid_row_3" : "grid_row_4"} mb-4`}>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div className='text-center grid_card_header'>
                                            <PiStudentFill className='grid_icon' style={{ color: "#924AEF" }} />
                                        </div>
                                        <div>
                                            <h4>
                                                <CountUp prefix="" start={0} end={noStd} decimal="." decimals={0} duration={1.75} />
                                            </h4>
                                            <p>No. of Students</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div className='grid_card_header'>
                                            <MaleRadialBarChart noStd={noStd || 1} noMale={noMale} />
                                            {/* <TbClock24 className='grid_icon' style={{ color: "#17a2b8" }} /> */}
                                        </div>
                                        <div>
                                            <h4>
                                                <span><CountUp prefix="" start={0} end={noMale} decimal="." decimals={0} duration={1.75} /></span>
                                            </h4>
                                            <p>Male Students</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="card grid_card h-100">
                                    <div className="content">
                                        <div className='grid_card_header'>
                                            <FemaleRadialBarChart noStd={noStd || 1} noFemale={noFemale} />
                                            {/* <IoCalendarOutline className='grid_icon' style={{ color: "#ffc107" }} /> */}
                                        </div>
                                        <div>
                                            <h4>
                                                <span><CountUp prefix="" start={0} end={noFemale} decimal="." decimals={0} duration={1.75} /></span>
                                            </h4>
                                            <p>Female Students</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                GraphCount > 0 && (
                                    <div className='mb-2'>
                                        <div className="card grid_card h-100">
                                            <div className="content">
                                                <div className='grid_card_header'>

                                                    <BarChart barData={barData} />

                                                    {/* <TfiBarChart className='grid_icon' style={{ color: "#6c757d" }} /> */}
                                                </div>
                                                <div>
                                                    <h4>
                                                        <span><CountUp prefix="" start={0} end={GraphCount} decimal="." decimals={0} duration={1.75} /></span>
                                                    </h4>
                                                    <p>Top {cityCount} Cities</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>


                        <div className="row row_reverse">
                            <div className={`col-lg-${(uniCount.length == 0 && pieTooltip.length == 0) ? 12 : 9} mb-3`}>
                                <div className="card">

                                    <h4 className='mb-3'>Registered Students</h4>
                                    {/* <h4 className='mb-3 h2_lg'>Registered Students</h4> */}

                                    <div className="filter d-flex ai-center jc-between mb-3">
                                        <div className='d-flex ai-center'>
                                            <p>Filter By</p>
                                            <div className="dropdown">
                                                <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Universities
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <div className='uni_filter_header d-flex jc-between'>
                                                        <p>Name</p>
                                                        <p className='me-0'>Filter</p>
                                                    </div>
                                                    <div className='uni_filter_body'>
                                                        {
                                                            uni.map((item, i) => (
                                                                <li key={i}>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.fullName}</label>
                                                                        {/* <input className="form-check-input" onChange={() => handleUniChange()} type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                            id={`uniCheckbox_${item._id}`}
                                                                            onChange={() => handleUniChange(item._id)}
                                                                            checked={selectedUni.includes(item._id)}
                                                                        />
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </div>
                                                    {/* <div className='uni_filter_footer'>
                                                        <button className='btn clear_btn'>Reset</button>
                                                    </div> */}
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Programs
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <div className='uni_filter_header d-flex jc-between'>
                                                        <p>Name</p>
                                                        <p className='me-0'>Filter</p>
                                                    </div>
                                                    <div className='uni_filter_body'>

                                                        {
                                                            prog.map((item, i) => (
                                                                <li key={i}>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.name}</label>
                                                                        {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                            id={`uniCheckbox_${item._id}`}
                                                                            onChange={() => handleProgramChange(item._id)}
                                                                            checked={selectedProgram.includes(item._id)}
                                                                        />

                                                                    </div>
                                                                </li>
                                                            ))
                                                        }



                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            (selectedUni.length > 0 ||
                                                selectedProgram.length > 0 ||
                                                selectedCity.length > 0) && (
                                                <div>
                                                    <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                                </div>
                                            )
                                        }
                                    </div>

                                    {
                                        allUsers.length > 0 ?
                                            <>
                                                <Table head={["Name", "Contact", "Gender", "City", "University",]}>
                                                    {
                                                        allUsers?.map((data, i) => {
                                                            return <tr key={i}>
                                                                <td className="text-left">
                                                                    <div className='d-flex ai-center'>
                                                                        <div>
                                                                            <TooltipComponent className="tooltip" placement="top" tooltipText="View Profile">
                                                                                <div>
                                                                                    <Link to={`/student-report/${data?.studentId?._id}#detail`} >
                                                                                        <Show_Profile_Pic src={data?.studentId?.profilepic} role={data?.role} classes="profile-image sm mb-0" />
                                                                                    </Link>
                                                                                </div>
                                                                            </TooltipComponent>
                                                                        </div>
                                                                        <div>
                                                                            <IconText text={data?.studentId?.fullName} smallText={data?.studentId?.email || "NA"} />
                                                                            {/* <Text text={data?.email || "NA"} /> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td><Text text={data?.email || "NA"} /></td> */}
                                                                <td><Text text={data?.studentId?.phone || "NA"} /></td>
                                                                <td><Text text={data?.studentId?.gender || "NA"} /></td>
                                                                <td><Text text={data?.city || "NA"} /></td>
                                                                <td><Text text={data?.university?.fullName || "NA"} /></td>
                                                                {/* <td><Badges text={data?.studentId?.status || "NA"} classes={`${data?.studentId?.status || "pending"} md`} /></td> */}
                                                                <td>
                                                                    <div className="dropdown">
                                                                        <button className="btn dropdown-toggle action_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <i className="fa fa-ellipsis-v"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                            <Link to={`/student-report/${data?.studentId?._id}#detail`} className="dropdown-item">View</Link>
                                                                            {/* <a className="dropdown-item" href="#">Edit</a>
                                                                <a className="dropdown-item" href="#">Block</a> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </Table>

                                                <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                            </>
                                            :
                                            <h5 className='not_found_msg'>No Records Found</h5>
                                    }

                                </div>
                            </div>

                            {/* <h2 className='mb-3 mt-4 h2_md'>Registered Students</h2> */}
                            {
                                (uniCount.length > 1 && pieTooltip.length > 1) && (
                                    <>
                                        {/* <h2 className='mb-3 mt-4 h2_md'>Registered Students</h2> */}
                                        <div className="col-lg-3 col-md-12 mb-4 pl-lg-0">
                                            <div className="card pieChart_card">
                                                <p>Students ratio university wise</p>
                                                <PieChart uniCount={uniCount} pieTooltip={pieTooltip} />
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                        {/* <div className="allUser">
                            <div className="user_row">
                                {
                                    allUsers.map((data, i) => {
                                        return <>
                                            <div key={i} className="user_card mb-3">
                                                <UserCard data={data} />
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                        </div> */}

                    </section>
                </div>
            </div>
        </>
    )
}

export default AllUsers
