import React from 'react'
import { useState, useEffect } from 'react';
import { Next } from 'react-bootstrap/esm/PageItem';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../../ContextAPI/Components/auth"

import { SubmissionForm4, getForm4, getFormsByAdmin } from '../../ContextAPI/APIs/api'


function Step_5(props) {

    const { GetLoginUser } = useAuth();
    // Financial Assistance Detail
    const [financeAssis, setFinanceAssis] = useState([
        {
            Name_of_Organization: "",
            Type_of_Assistance: "",
            Amount_Applied_For: "",
            Amount_Received: "",
        },
    ]);

    const financial_detail = {
        Name_of_Organization: "",
        Type_of_Assistance: "",
        Amount_Applied_For: "",
        Amount_Received: "",
    }

    function Add_New_Finance_Detail(e) {
        e.preventDefault();
        setFinanceAssis([...financeAssis, financial_detail])
    }

    const [bankDetail, setBankDetail] = useState([
        {
            Nameof: "",
            NameBank: "",
            ClosingBalance: ""
        },
    ]);

    const bank_detail = {
        Nameof: "",
        NameBank: "",
        ClosingBalance: ""
    }

    function Add_New_Bank_Detail(e) {
        e.preventDefault();
        setBankDetail([...bankDetail, bank_detail])
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);


    const [data, setData] = useState({
        EmployeeOrg: "",
        EmployeeDesig: "",
        EmployeeExp: "",
        EmployeeSalary: "",
        OtherBenefits: "",
        EmployeeStatus: "",
        OrgOutcomeAmount: "",
        ResignDate: "",
        CommutationDate: "",
        question41: "",
        question42: "",
        question43: "",
        question44: "",
        cashInHand: "",
        total: "",
    })





    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleRadioChange = (e) => {
        setData(prevData => ({
            ...prevData,
            EmployeeStatus: e.target.value
        }));
    };

    const handleInputChangeFA = (e, index) => {
        const { name, value } = e.target;
        const updatedBankDetail = [...financeAssis];
        updatedBankDetail[index][name] = value;
        setFinanceAssis(updatedBankDetail);
    };



    const handleInputChangeBANK = (e, index) => {
        const { name, value } = e.target;
        const updatedBankDetail = [...bankDetail];
        updatedBankDetail[index][name] = value;
        setBankDetail(updatedBankDetail);
    };



    const Next = async () => {
        try {
            // const payload = {
            //     ...data,
            //     bankSTMT: bankDetail,
            //     FinancialAssistance: financeAssis
            // }
            // console.log("PAYLOAD", payload);
            // const response = await SubmissionForm4(payload)
            // if (response.success) {
            navigate(`#${"step_6"}`)
            // }
        }
        catch (e) {
            console.log(e);
        }
    }






    const getFormData = async () => {
        try {
            // const response = await getForm3()

            const response = await getFormsByAdmin(4, props.id)

            if (response.success) {
                console.log("fomr1", response);
                if (response.success) {
                    setData(response?.message)
                    setBankDetail(response.message?.bankSTMT)
                    setFinanceAssis(response.message?.FinancialAssistance)
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getFormData()
    }, [])
    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        {/* <form> */}
                        <div className="table_form mb-4">
                            <div className='d-flex ai-center jc-between mb-4'>
                                <h5 className='mb-0'>Financial Assistance Previously Received (if any)</h5>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name of Organization</th>
                                            <th scope="col">Type of Assistance</th>
                                            <th scope="col">Amount Applied For</th>
                                            <th scope="col">Amount Received</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {financeAssis.map((data, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <input disabled
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        name="Name_of_Organization"
                                                        value={financeAssis[i].Name_of_Organization}
                                                    />
                                                </td>
                                                <td>
                                                    <input disabled
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        name="Type_of_Assistance"
                                                        value={financeAssis[i].Type_of_Assistance}
                                                    />
                                                </td>
                                                <td>
                                                    <input disabled
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        name="Amount_Applied_For"
                                                        value={financeAssis[i].Amount_Applied_For}
                                                    />
                                                </td>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        name="Amount_Received"
                                                        value={financeAssis[i].Amount_Received}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> </div>
                        </div>

                        <div className="table_form mb-4">
                            <h5>Details of Employment Prior to Admission: (if applicable)</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className='form-group'>
                                        <label className="form-label">Name of the Organization:</label>
                                        <input disabled
                                            type="text"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="EmployeeOrg"
                                            value={data.EmployeeOrg}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <label className="form-label">Designation:</label>
                                        <input disabled
                                            type="text"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="EmployeeDesig"
                                            value={data.EmployeeDesig}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <label className="form-label">Experience in years:</label>
                                        <input disabled
                                            type="number"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="EmployeeExp"
                                            value={data.EmployeeExp}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <label className="form-label">Salary per month during the program (Rs.):</label>
                                        <input disabled
                                            type="number"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="EmployeeSalary"
                                            value={data.EmployeeSalary}
                                            required
                                        />
                                        <small><label><i>(Please attach a salary slip of the last drawn salary)</i></label></small>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <label className="form-label">Other benefits:</label>
                                        <input disabled
                                            type="text"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="OtherBenefits"
                                            value={data.OtherBenefits}
                                            required
                                        />
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className='form-group'>
                                        <label className="form-label">Status of the job during the Program:</label>
                                        <div className='d-flex'>
                                            <div className="form-check me-5">
                                                <input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="EmployeeStatus"
                                                    id="flexRadioDefault11"
                                                    value="Working"
                                                    checked={data.EmployeeStatus === "Working"}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault11">Working</label>
                                            </div>
                                            <div className="form-check me-5">
                                                <input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="EmployeeStatus"
                                                    id="flexRadioDefault12"
                                                    value="On leave with pay"
                                                    checked={data.EmployeeStatus === "On leave with pay"}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault12">On leave with pay</label>
                                            </div>
                                            <div className="form-check me-5">
                                                <input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="EmployeeStatus"
                                                    id="flexRadioDefault13"
                                                    value="On leave without pay"
                                                    checked={data.EmployeeStatus === "On leave without pay"}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault13">On leave without pay</label>
                                            </div>
                                            <div className="form-check">
                                                <input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="EmployeeStatus"
                                                    id="flexRadioDefault14"
                                                    value="Resigned"
                                                    checked={data.EmployeeStatus === "Resigned"}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault14">Resigned</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-10">
                                    <div className='form-group'>
                                        <label className="form-label mb-0">Did you ask the organization to sponsor your educational expense for this program? If yes,</label>
                                        <label className="form-label">outcome amount Rs.</label>
                                        <input disabled
                                            type="number"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="OrgOutcomeAmount"
                                            value={data.OrgOutcomeAmount}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <label className="form-label">If resigned, Date of resignation</label>
                                        <input disabled
                                            type="date"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="ResignDate"
                                            value={data.ResignDate ? new Date(data.ResignDate).toISOString().split('T')[0] : ''}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <label className="form-label">Commutation received:</label>
                                        <input disabled
                                            type="date"
                                            className='form-control'
                                            placeholder='Enter here'
                                            name="CommutationDate"
                                            value={data.CommutationDate ? new Date(data.CommutationDate).toISOString().split('T')[0] : ''}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <div className='d-flex ai-center jc-between mb-3 mt-4'>
                                    <h5 className='mb-0'>Bank Accounts (for all family members)</h5>
                                </div>
                                <table className="table table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">In the Name of</th>
                                            <th scope="col">Name of the Bank</th>
                                            <th scope="col">Closing Balance in Bank Account</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bankDetail.map((item, i) => {
                                                return <tr key={i}>
                                                    <td><input disabled
                                                        name="Nameof"
                                                        value={bankDetail[i].Nameof}
                                                        type="text" className='form-control' placeholder='Enter here' /></td>
                                                    <td><input disabled
                                                        value={bankDetail[i].BankName}
                                                        name="NameBank"
                                                        type="text" className='form-control' placeholder='Enter here' /></td>
                                                    <td><input disabled
                                                        name="ClosingBalance"
                                                        value={bankDetail[i].ClosingBalance}
                                                        type="number" className='form-control' placeholder='Enter here' /></td>
                                                </tr>
                                            })
                                        }
                                        <tr>
                                            <th scope='row' colSpan={2} style={{ textAlign: "left" }}>Total Cash in Hand</th>
                                            <td><input disabled type="number"
                                                name="cashInHand"
                                                value={data.cashInHand}
                                                className='form-control' placeholder='Enter here' /></td>
                                        </tr>
                                        <tr>
                                            <th scope='row' colSpan={2} style={{ textAlign: "left" }}>Total</th>
                                            <td className='bg-gray'><input disabled type="number"
                                                name="total"
                                                value={data.total}
                                                className='form-control' placeholder='Enter here' /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <small><label><i>(Please note that the bank statement should be for full year and should be latest)</i></label></small>
                            </div>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">Please mention the expenditure of transportation for the family which will include Public transport and private transport (please specify the expenditure)</label>
                            <textarea
                                disabled
                                name="question41"
                                value={data.question41}
                                className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">Have you or any of your family members been oversees in the last two years? If yes, then please specify the amount spent your relationship with that person and sponsoring agency (if any).</label>
                            <textarea
                                disabled
                                name="question42"
                                value={data.question42}
                                className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">Monthly household Ration expenditure (please specify):</label>
                            <textarea
                                disabled
                                name="question43"
                                value={data.question43}
                                className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">Medical expense (please specifies and attaches medical certificates):</label>
                            <textarea
                                disabled
                                name="question44"
                                value={data.question44}
                                className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>

                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"step_4"}`)}>Previous</button>
                            <button className='btn next_btn' onClick={() => Next()}>Next</button>
                        </div>
                        {/* </form > */}
                    </div>
                </div >
            </section >
        </>
    )
}

export default Step_5
