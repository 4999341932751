import { FaEdit, FaPlus } from "react-icons/fa"
import UserModal from "../Modals/Modal"
import { useEffect, useMemo, useState } from "react";
import { cities, states, CitiesPk } from "../../helpers/data";
import { DeleteCampus, UpdateCampus, createCampus, getCampus } from "../../ContextAPI/APIs/api";
import { useParams } from "react-router-dom";
import Input from "../Form/Input";
import Select from "../Form/Select";

import { useToast } from '../../ContextAPI/Components/toast'
import { useConfirm } from '../../ContextAPI/Components/confirm'
import { useMediaQuery } from "react-responsive";



export function University_Campuses() {
    const params = useParams();
    const { alert } = useToast();
    const { Ask } = useConfirm();

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const [AllCamps, setAllCamps] = useState([])
    const [camp, setCamp] = useState({
        name: "",
        state: "",
        city: "",
        address: "",
    })
    const [campuUpdate, setCampusUpdate] = useState({
        name: "",
        state: "",
        city: "",
        address: "",
    })


    const addCampus = async (e) => {
        e.preventDefault();

        try {
            const payload = {
                uniId: params.id,
                ...camp
            }
            console.log("CAMPUS", payload);
            const response = await createCampus(payload)
            if (response?.success) {
                getAllCampuses()
                handleClose()
            }

            alert(response?.message, response?.success)
        }
        catch (e) {
            console.log(e);
        }
    }

    const getAllCampuses = async () => {
        try {
            const response = await getCampus({ uniId: params.id })

            if (response.success) {
                setAllCamps(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const updateCampus = async (e, id) => {
        e.preventDefault();

        try {
            const payload = {
                cid: id,
                ...campuUpdate
            }
            console.log("CAMPUS", payload);

            if (await Ask() == false) return;

            const response = await UpdateCampus(payload)
            if (response?.success) {
                getAllCampuses()
                handleClose()
            }

            alert(response?.message, response?.success)
        }
        catch (e) {
            console.log(e);
        }
    }

    const deleteCampus = async (id) => {
        if (await Ask() == false) return;

        try {
            const response = await DeleteCampus(id)
            if (response?.success) {
                getAllCampuses()
                handleClose()
            }

            alert(response?.message, response?.success)
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getAllCampuses()

    }, [])


    useMemo(() => {

    })

    const isTablet = useMediaQuery({ query: '(max-width: 992px)' });




    return <>
        <div className='d-flex jc-between ai-center mb-3'>
            <h4 className='mb-0'>University Campuses</h4>

            <div className='d-flex jc-end'>
                <UserModal btnText={<FaPlus />} heading="Add Campus" id={`ViewCampus`} customClasses="round_btn" modalSize="lg" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>

                    <form onSubmit={(e) => addCampus(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Input onChange={(e) => setCamp({ ...camp, name: e.target.value })} label="Campus Name" inputType="text" required style={{ textTransform: "capitalize" }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select onChange={(e) => setCamp({ ...camp, state: e.target.value, city: "" })} options={states} label="State" selectText="Select your state" required style={{ textTransform: "capitalize" }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">City</label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={camp.city}
                                        onChange={(e) => setCamp({ ...camp, city: e.target.value })}
                                        style={{ textTransform: "capitalize" }}
                                        required
                                    >
                                        <option selected disabled value={""}>Select your City</option>
                                        {
                                            CitiesPk.map((item) => (
                                                item.state === camp.state && (
                                                    <option value={item?.city}>{item?.city}</option>
                                                )
                                            ))
                                        }
                                    </select>
                                    {/* <Select onChange={(e) => setCamp({ ...camp, city: e.target.value })} label="State" options={states} selectText="Select your state" required style={{ textTransform: "capitalize" }} /> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Input onChange={(e) => setCamp({ ...camp, address: e.target.value })} label="Address" inputType="text" required />
                                </div>
                            </div>

                            <div className='d-flex jc-end mt-3'>
                                <button className='btn save_btn'>Add New Campus</button>
                            </div>

                        </div>
                    </form>

                </UserModal>
            </div>
        </div>

        <form>
            {
                AllCamps.map((item, i) => {
                    return <>
                        <div className={`${isTablet ? "d-block" : "d-flex ai-center"} my-2`}>
                            <div className="row w-100">
                                <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <Input label="Campus Name" disabled value={item?.name} inputType="text" style={{ textTransform: "capitalize" }} />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <Input label="City" disabled value={item?.city} inputType="text" style={{ textTransform: "capitalize" }} />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <Input label="State" disabled value={item?.state} inputType="text" style={{ textTransform: "capitalize" }} />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <Input label="Address" disabled value={item?.address} inputType="text" />
                                    </div>
                                </div>
                            </div>


                            <div className={`${isTablet ? "d-none" : "d-flex jc-end ai-center"}`}>
                                <UserModal btnText={<FaEdit />} heading="Update Campus" id={`View${i}`} customClasses="round_btn ml-2 mt-4" modalSize="lg" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow} setUpdate={setCampusUpdate} dataToBeSet={item}>
                                    <form onSubmit={(e) => updateCampus(e, item?._id)}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <Input value={campuUpdate.name} onChange={(e) => setCampusUpdate({ ...campuUpdate, name: e.target.value })} label="Campus Name" inputType="text" required style={{ textTransform: "capitalize" }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <Select
                                                        value={campuUpdate.state}
                                                        onChange={(e) => setCampusUpdate({ ...campuUpdate, state: e.target.value, city: "" })}
                                                        options={states}
                                                        label="State"
                                                        selectText="Select your State"
                                                        required
                                                        style={{ textTransform: "capitalize" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    {/* <Select
                                                        value={campuUpdate.city}
                                                        onChange={(e) => setCampusUpdate({ ...campuUpdate, city: e.target.value })}
                                                        label="State"
                                                        options={states}
                                                        selectText="Select your state"
                                                        required
                                                    /> */}
                                                    <label className="form-label">City</label>
                                                    <select
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        value={campuUpdate.city}
                                                        onChange={(e) => setCampusUpdate({ ...campuUpdate, city: e.target.value })}
                                                        style={{ textTransform: "capitalize" }}
                                                        required
                                                    >
                                                        <option selected disabled value={""}>Select your City</option>
                                                        {
                                                            CitiesPk.map((item) => (
                                                                item.state === campuUpdate.state && (
                                                                    < option value={item?.city} >{item?.city}</option>
                                                                )
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <Input value={campuUpdate.address} onChange={(e) => setCampusUpdate({ ...campuUpdate, address: e.target.value })} label="Address" inputType="text" required />
                                                </div>
                                            </div>
                                            <div className='d-flex jc-end mt-3'>
                                                <button className='btn save_btn me-2'>Update Campus</button>
                                                <button type="button" className='btn delete_btn mb-0' onClick={() => deleteCampus(item?._id)}>Delete Campus</button>
                                            </div>
                                        </div>
                                    </form>
                                </UserModal>
                            </div>
                        </div>

                        <div className={`${isTablet ? "d-flex jc-end" : "d-none"}`}>
                            <UserModal btnText={"Edit"} heading="Update Campus" id={`View${i}`} customClasses="save_btn" modalSize="lg" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow} setUpdate={setCampusUpdate} dataToBeSet={item}>
                                <form onSubmit={(e) => updateCampus(e, item?._id)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Input value={campuUpdate.name} onChange={(e) => setCampusUpdate({ ...campuUpdate, name: e.target.value })} label="Campus Name" inputType="text" required style={{ textTransform: "capitalize" }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Select
                                                    value={campuUpdate.state}
                                                    onChange={(e) => setCampusUpdate({ ...campuUpdate, state: e.target.value, city: "" })}
                                                    options={states}
                                                    label="State"
                                                    selectText="Select your State"
                                                    required
                                                    style={{ textTransform: "capitalize" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                {/* <Select
                                                        value={campuUpdate.city}
                                                        onChange={(e) => setCampusUpdate({ ...campuUpdate, city: e.target.value })}
                                                        label="State"
                                                        options={states}
                                                        selectText="Select your state"
                                                        required
                                                    /> */}
                                                <label className="form-label">City</label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    value={campuUpdate.city}
                                                    onChange={(e) => setCampusUpdate({ ...campuUpdate, city: e.target.value })}
                                                    style={{ textTransform: "capitalize" }}
                                                    required
                                                >
                                                    <option selected disabled value={""}>Select your City</option>
                                                    {
                                                        CitiesPk.map((item) => (
                                                            item.state === campuUpdate.state && (
                                                                < option value={item?.city} >{item?.city}</option>
                                                            )
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Input value={campuUpdate.address} onChange={(e) => setCampusUpdate({ ...campuUpdate, address: e.target.value })} label="Address" inputType="text" required />
                                            </div>
                                        </div>
                                        <div className='d-flex jc-end mt-3'>
                                            <button className='btn save_btn me-2'>Update Campus</button>
                                            <button type="button" className='btn delete_btn mb-0' onClick={() => deleteCampus(item?._id)}>Delete Campus</button>
                                        </div>
                                    </div>
                                </form>
                            </UserModal>
                        </div>
                    </>
                })
            }
        </form>

    </>
}