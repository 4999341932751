import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useProSidebar } from 'react-pro-sidebar';
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import UserModal from '../../components/Modals/Modal';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import Select from '../../components/Form/Select';
import Input from '../../components/Form/Input';

import { setProgram, getProgram, DeleteProgrambyId, UpdProgram, DeleteCampus, RemoveProgramID } from '../../ContextAPI/APIs/api';
import { useEffect } from 'react';
import { UniFields } from '../../helpers/data';
import { useConfirm } from '../../ContextAPI/Components/confirm';
import { useToast } from "../../ContextAPI/Components/toast"
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../ContextAPI/Components/auth';


function Programs() {

    const { user } = useAuth();
    const { Ask } = useConfirm()
    const { alert } = useToast()
    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    async function DeleteThisProgram(item) {

        if (await Ask() == false) return
        const res = await DeleteProgrambyId(item._id)
        if (res.success) {
            alert(res.message, res.success)
            getAllPrograms()

        } else {
            alert(res.message, res.success)

        }
    }

    const [programData, setProgramData] = useState({
        type: "",
        name: ""
    })

    const [updProgramData, setUPDProgramData] = useState({
        feild: null,
        name: null
    })

    const handleNameUPD = (e) => {
        setUPDProgramData({ ...updProgramData, name: e.target.value })
    }
    const handleTypeUPD = (e) => {
        setUPDProgramData({ ...updProgramData, feild: e.target.value })
    }

    const handleName = (e) => {
        setProgramData({ ...programData, name: e.target.value })
    }

    const handleType = (e) => {
        setProgramData({ ...programData, type: e.target.value })
    }

    const [prog, setProg] = useState([])

    const getAllPrograms = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const AddProgram = async () => {
        if (await Ask() == false) return

        try {
            const payload = {
                name: programData?.name,
                type: programData?.type
            }
            const response = await setProgram(payload)
            console.log("payload", payload);

            if (response.success) {
                alert(response.message, response.success)
                setProgramData({ type: "", name: "" })
            }
            else {
                alert(response.message, response.success)

            }
            handleClose()
            getAllPrograms()
        }
        catch (e) {
            console.log(e);
        }
    }

    const UpdateProg = async (id) => {
        if (await Ask() == false) return

        try {
            const payload = {
                programId: id,
                ...updProgramData
            }
            const response = await UpdProgram(payload)
            if (response.success) {
                alert(response.message, response.success)
                getAllPrograms()
            }
            alert(response.message, response.success)
            handleClose()
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getAllPrograms()
    }, [])




    return (
        <>
            <Helmet>
                <title>All Programs</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='programs_section'>

                        <div className='d-flex jc-between ai-center mb-3'>
                            <h2 className='mb-0'>Programs</h2>
                            {
                                (user?.role == "admin" || user?.role == "operations") && (
                                    <div className='d-flex jc-end'>
                                        <UserModal btnText={<FaPlus />} heading="Add New Program" id={`ViewProgram`} customClasses="round_btn" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                            {/* <form> */}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Select options={UniFields} value={programData.type} onChange={(e) => handleType(e)} label="Field" selectText="Select Field of a Program" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Input onChange={(e) => handleName(e)} value={programData.name} label="Program Name" inputType="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex jc-end mt-3'>
                                                <button className='btn save_btn' onClick={() => AddProgram()}>Add</button>
                                            </div>
                                            {/* </form> */}
                                        </UserModal>
                                    </div>
                                )
                            }
                        </div>

                        <div className="card mb-3">
                            <h4 className='mb-0'>Engineering</h4>
                            <div className="program_row">
                                {
                                    prog.map((item, i) => {
                                        return <>
                                            {
                                                item?.feild == "engineering" && (
                                                    <div className="program_card mt-2">
                                                        <div className='d-flex ai-center jc-between'>
                                                            <p className='prog_name'>{item?.name}</p>
                                                            {
                                                                (user?.role == "admin" || user?.role == "operations") && (
                                                                    <div className='d-flex'>
                                                                        <ThisModal btnText={<FaEdit />} heading="Update Program" id={`ViewProgram${i}`} customClasses="edit_program_btn" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow} setUPDProgramData={setUPDProgramData} item={item}>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <Select options={UniFields} value={updProgramData?.feild} onChange={(e) => handleTypeUPD(e)} label="Type" selectText="Select Field of a Program" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <Input value={updProgramData?.name} onChange={(e) => handleNameUPD(e)} label="Program Name" inputType="text" class="text-capital" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex jc-end mt-3'>
                                                                                <button className='btn save_btn' onClick={() => UpdateProg(item?._id)}>Update</button>
                                                                            </div>
                                                                        </ThisModal>

                                                                        <button className='btn dlt_program_btn ml-2'>
                                                                            <FaTrash onClick={() => DeleteThisProgram(item)} />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    })
                                }
                            </div>
                        </div>

                        <div className="card mb-3">
                            <h4 className='mb-0'>Medical</h4>
                            <div className="program_row">
                                {
                                    prog.map((item, i) => {
                                        return <>
                                            {
                                                item?.feild == "medical" && (
                                                    <div className="program_card my-2">
                                                        <div className='d-flex ai-center jc-between'>
                                                            <p className='prog_name'>{item?.name}</p>
                                                            {
                                                                (user?.role == "admin" || user?.role == "operations") && (
                                                                    <div className='d-flex'>
                                                                        <ThisModal btnText={<FaEdit />} heading="Update Program" id={`ViewProgram${i}`} customClasses="edit_program_btn" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow} setUPDProgramData={setUPDProgramData} item={item}>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <Select options={UniFields} value={updProgramData?.feild} onChange={(e) => handleTypeUPD(e)} label="Type" selectText="Select Field of a Program" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <Input value={updProgramData?.name} onChange={(e) => handleNameUPD(e)} label="Program Name" inputType="text" class="text-capital" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex jc-end mt-3'>
                                                                                <button className='btn save_btn' onClick={() => UpdateProg(item?._id)}>Update</button>
                                                                            </div>
                                                                        </ThisModal>

                                                                        <button className='btn dlt_program_btn ml-2'>
                                                                            <FaTrash onClick={() => DeleteThisProgram(item)} />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    })
                                }
                            </div>
                        </div>

                        <div className="card mb-3">
                            <h4 className='mb-0'>Commerce</h4>
                            <div className="program_row">
                                {
                                    prog.map((item, i) => {
                                        return <>
                                            {
                                                item?.feild == "commerce" && (
                                                    <div className="program_card my-2">
                                                        <div className='d-flex ai-center jc-between'>
                                                            <p className='prog_name'>{item?.name}</p>
                                                            {
                                                                (user?.role == "admin" || user?.role == "operations") && (
                                                                    <div className='d-flex'>
                                                                        <ThisModal btnText={<FaEdit />} heading="Update Program" id={`ViewProgram${i}`} customClasses="edit_program_btn" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow} setUPDProgramData={setUPDProgramData} item={item}>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <Select options={UniFields} value={updProgramData?.feild} onChange={(e) => handleTypeUPD(e)} label="Type" selectText="Select Field of a Program" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <Input value={updProgramData?.name} onChange={(e) => handleNameUPD(e)} label="Program Name" inputType="text" class="text-capital" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex jc-end mt-3'>
                                                                                <button className='btn save_btn' onClick={() => UpdateProg(item?._id)}>Update</button>
                                                                            </div>
                                                                        </ThisModal>

                                                                        <button className='btn dlt_program_btn ml-2'>
                                                                            <FaTrash onClick={() => DeleteThisProgram(item)} />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    })
                                }
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}





function ThisModal(props) {

    return (
        <>
            <button type='button' className={`btn ${props.customClasses}`} onClick={() => { props.handleShow(props.id); props.setUPDProgramData(props.item) }}>
                {typeof props.icon !== "undefined" ? props.icon : props.btnText}
            </button>

            {
                props.modalItem == props.id &&
                <Modal
                    className='modal'
                    {...props}
                    size={props.modalSize}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={props.show}
                    onHide={props.handleClose}
                >
                    <Modal.Header closeButton>
                        <h4 className='mb-0'>{props.heading}</h4>
                    </Modal.Header>

                    <Modal.Body className='modal_body'>
                        {props.children}
                    </Modal.Body>

                    {/* <Modal.Footer>
                    </Modal.Footer> */}

                </Modal>
            }
        </>
    );
}


export default Programs