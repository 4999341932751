import React from 'react'
import { Helmet } from 'react-helmet'
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table/Table';
import Text from '../../../components/Table/Text';

function TAT_Renewal({ data, percent, month, year }) {


    return (
        <>

            <Helmet>
                <title>TAT Renewal</title>
            </Helmet>

            <section className=''>
                <h4>TAT Renewal</h4>

                <div className="TAT_card mb-4">

                    <Table head={["Particulars", `Fresh Cases in ${month}`, "Remaining Cases till Last Month", `Total Cases in ${year}`]}>
                        {
                            data.map((item, i) => (
                                <tr>
                                    <td><Text text={item?.remarks || "---"} /></td>
                                    <td><Text text={item?.countFetch1} /></td>
                                    <td><Text text={item?.countFetch2} /></td>
                                    <td><Text text={item?.countFetch3} /></td>
                                </tr>
                            ))
                        }

                    </Table>

                    {/* <h5 className='not_found_msg'>No Records Found</h5> */}

                </div>

                <div className='TAT_card mb-4'>
                    <Table head={["Cancelled", `In ${month}`, "Total"]}>
                        {
                            data.map((item, i) => (
                                (item?.status == "reject" && item?.remarks !== "Cancelled before Interview" && item?.remarks !== "Cancelled after Interview") && (
                                    < tr >
                                        <td><Text text={item?.remarks || "---"} /></td>
                                        <td><Text text={item?.countFetch1} /></td>
                                        {/* <td><Text text={item?.countFetch2} /></td> */}
                                        <td><Text text={item?.countFetch3} /></td>
                                    </tr>
                                )
                            ))
                        }
                    </Table>

                    {/* <h5 className='not_found_msg'>No Records Found</h5> */}

                </div>

                <div className='TAT_card'>
                    <Table head={["TAT", `For ${month}`, `For ${year}`]}>
                        <tr>
                            <td><Text text={"TAT for Interview" || "---"} /></td>
                            <td><Text text={`${percent?.percentageTATinterview?.toFixed(2)} %` || "---"} /></td>
                            <td><Text text={`${percent?.percentageTATapproval?.toFixed(2)} %` || "---"} /></td>
                        </tr>
                        <tr>
                            <td><Text text={"TAT for Approval" || "---"} /></td>
                            <td><Text text={`${percent?.percentageTATinterviewYearly?.toFixed(2)} %` || "---"} /></td>
                            <td><Text text={`${percent?.percentageTATapprovalYearly?.toFixed(2)} %` || "---"} /></td>
                        </tr>
                    </Table>

                    {/* <h5 className='not_found_msg'>No Records Found</h5> */}

                </div>

            </section >

        </>
    )
}

export default TAT_Renewal
