import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import UNDERTAKING from "../../assets/undertaking.png";

function Step_10() {

    function handleSubmit(e) {
        e.preventDefault();
        alert("Please Fill All Fields")
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);
    

    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        <form>
                            <div className="instructions_section">
                                <h5>Instructions</h5>
                                <h6>Kindly read the points below and reproduce (rewrite word to word) all these points in your
                                    own hand writing on the next page attached (Extra sheet can be attached if needed). Without
                                    this Undertaking (Mandatory), <i>APPLICATION WILL BE CANCELLED WITHOUT INTIMATION.</i>
                                </h6>
                            </div>

                            <div className="undertaking_section">
                                <h5>UNDERTAKING</h5>
                                <img src={UNDERTAKING} alt="image" className='img-fluid mb-4' />
                                {/* <ul>
                                                <li>We understand that the approval of the above facility will be evaluated by the IT on “Need‐cum‐
                                                    Merit” basis, and on the availability of the resources. Decision of the IT will be final.
                                                </li>
                                                <li>We undertake that the applicant will try his level best, never to work for any organization which is
                                                    involved in spreading/promoting Interest/Usury and will always strive to promote Islamic Banking
                                                    and Finance.
                                                </li>
                                                <li>We believes in the unity and oneness of Almighty Allah, in the absolute and unqualified finality of
                                                    the Prophet hood of Muhammad (peace be upon him), the last of the prophets, and do not believe
                                                    in, or recognize as a prophet or religious reformer, any person who claimed or claims to be a
                                                    prophet, in any sense of the word or of any description whatsoever, after Muhammad (Peace be
                                                    upon him).
                                                </li>
                                                <li>The information given in this application is complete and true to the best of our knowledge and we
                                                    understand that any incorrect information will result in the cancellation of this application. Further,
                                                    if any information given in this application is found incorrect or false after grant of financial
                                                    assistance, IT will stop further aid.
                                                </li>
                                                <li>If the student is awarded any other financial aid (scholarship) during the tenure of Interest free
                                                    Loan, we will immediately inform IT about it.
                                                </li>
                                                <li>We will always choose Islamic Banking and Finance over conventional banking and will not support
                                                    any sort of interest-based transactions.
                                                </li>
                                                <li>We will never knowingly and willingly get involved in any sort of exploitation, cheating, gambling,
                                                    fraud or any other unethical behavior whatsoever, classified assuch by societal orIslamic standards,
                                                    whether in our personal life or professional life.
                                                </li>
                                                <li>We will strive to work towards the betterment of the society, our beloved country Pakistan and of
                                                    the Muslim Ummah as a whole to the best of our ability.
                                                </li>
                                                <li>We will uphold moral and ethical values and follow the code of conduct in the university, workplace,
                                                    and at home, according to societal and Islamic standards.
                                                </li>
                                                <li>We will be fair, honest, and kind in dealings with peersincluding, but not limited to, family, relatives,
                                                    friends, neighbors, colleagues, and people working us.
                                                </li>
                                                <li>We will be supportive of fellow Muslims who need our help, and do our best to help them out with
                                                    their needs in whatever way we can.
                                                </li>
                                                <li>We will always strive to learn, understand and implement more religion in our lives in order to lead
                                                    a balanced and blessed lifestyle.
                                                </li>
                                                <li>We will not support any organization, cause, movement, or idea that is against the principles of
                                                    Shari’ah and Sunnah.
                                                </li>
                                                <li>We will alwayssupport and help our beloved country Pakistan and will never everspeak, act or work
                                                    against it.
                                                </li>
                                                <li>We will always remain goodwill ambassadors of Ihsan Trust and will always support it in achieving
                                                    its (Ihsan Trust’s) goals.
                                                </li>
                                            </ul> */}

                                <textarea cols="30" rows="30" className='form-control textarea' placeholder='Type here...'></textarea>
                            </div>

                            <div className="signature_section">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className=''>
                                            <div className='signature_line'></div>
                                            <label className='form-label'>Applicant’s Signature</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=''>
                                            <div className='signature_line'></div>
                                            <label className='form-label'>Father’s / Guardian / Guardian’s Signature</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=''>
                                            <div className='signature_line'></div>
                                            <label className='form-label'>Mother’s Signature</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=''>
                                            <div className='signature_line'></div>
                                            <label className='form-label'>Spouse (if any)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex jc-between mt-5'>
                                <button className='btn prev_btn' type='button' onClick={() => navigate(`#${"step_9"}`)}>Previous</button>
                                <button className='btn submit_btn' onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step_10
