import { createContext, useContext, useState, useEffect } from "react"
import { useLocation } from 'react-router-dom';


// Step 1
const PathnameContext = createContext()

// Step 2
export const usePathname = () => {
    return useContext(PathnameContext);
}

// Step 3
export const PathnameProvider = ({ children }) => {
    const location = useLocation()

    const [style, setStyle] = useState(sessionStorage.getItem("a") || "home")

    useEffect(() => {
        console.log("location.pathname", location.pathname);
        handlePathname()

    }, [location.pathname]);

    const handlePathname = () => {
        // GLOBAL
        if (window.location.pathname == "/application-tracking") {
            sessionStorage.setItem("a", "application_tracking")
            setStyle("application_tracking")
        }
        if (window.location.pathname == "/profile") {
            sessionStorage.setItem("a", "profile")
            setStyle("profile")
        }
        // ADMIN
        if (window.location.pathname == "/admin-dashboard") {
            sessionStorage.setItem("a", "admin_dashboard")
            setStyle("admin_dashboard")
        }
        if (window.location.pathname == "/create-user") {
            sessionStorage.setItem("a", "create_user")
            setStyle("create_user")
        }
        if (window.location.pathname == "/all-users/students-list") {
            sessionStorage.setItem("a", "all_users")
            setStyle("all_users")
        }
        if (window.location.pathname == "/all-users/universities-list") {
            sessionStorage.setItem("a", "universities")
            setStyle("universities")
        }
        if (window.location.pathname == "/all-users/others-list") {
            sessionStorage.setItem("a", "admin")
            setStyle("admin")
        }
        if (window.location.pathname == "/approval-flow") {
            sessionStorage.setItem("a", "approval_flow")
            setStyle("approval_flow")
        }
        if (window.location.pathname == "/disbustment-process") {
            sessionStorage.setItem("a", "disbustment_process")
            setStyle("disbustment_process")
        }
        if (window.location.pathname == "/repayment-flow") {
            sessionStorage.setItem("a", "repayment_flow")
            setStyle("repayment_flow")
        }
        if (window.location.pathname == "/programs") {
            sessionStorage.setItem("a", "programs")
            setStyle("programs")
        }
        if (window.location.pathname == "/programs") {
            sessionStorage.setItem("a", "programs")
            setStyle("programs")
        }
        if (window.location.pathname == "/cases/fresh-cases") {
            sessionStorage.setItem("a", "freshCases")
            setStyle("freshCases")
        }
        if (window.location.pathname == "/cases/renewal-cases") {
            sessionStorage.setItem("a", "renewalCases")
            setStyle("renewalCases")
        }
        if (window.location.pathname == "/approval-sheets/fresh") {
            sessionStorage.setItem("a", "approvalSheet")
            setStyle("approvalSheet")
        }

        if (window.location.pathname == "/approval-sheets/renewal") {
            sessionStorage.setItem("a", "approvalSheetRenewal")
            setStyle("approvalSheetRenewal")
        }

        if (window.location.pathname == "/university-profile") {
            sessionStorage.setItem("a", "university_profile")
            setStyle("university_profile")
        }
        // STUDENT
        if (window.location.pathname == "/application-form") {
            sessionStorage.setItem("a", "application_form")
            setStyle("application_form")
        }
        if (window.location.pathname == "/renewal-form") {
            sessionStorage.setItem("a", "renewal_form")
            setStyle("renewal_form")
        }
        if (window.location.pathname == "/dashboard") {
            sessionStorage.setItem("a", "repayment_history")
            setStyle("repayment_history")
        }
        // UNIVERSITIES
        if (window.location.pathname == "/university-dashboard") {
            sessionStorage.setItem("a", "university_dashboard")
            setStyle("university_dashboard")
        }
        if (window.location.pathname == "/fresh-cases-report") {
            sessionStorage.setItem("a", "fresh_cases_report")
            setStyle("fresh_cases_report")
        }
        if (window.location.pathname == "/renewal-cases-report") {
            sessionStorage.setItem("a", "renewal_cases_report")
            setStyle("renewal_cases_report")
        }
        if (window.location.pathname == "/disbustment") {
            sessionStorage.setItem("a", "disbustment")
            setStyle("disbustment")
        }

        if (window.location.pathname == "/create-repayments") {
            sessionStorage.setItem("a", "create-repayments")
            setStyle("create-repayments")
        }


        if (window.location.pathname == "/std-profile") {
            sessionStorage.setItem("a", "std-profile")
            setStyle("std-profile")
        }
        if (window.location.pathname == "/tranch") {
            sessionStorage.setItem("a", "tranch")
            setStyle("tranch")
        }


        if (window.location.pathname == "/reporting/disbursementAnalysis-Student") {
            sessionStorage.setItem("a", "disb_analysis_std")
            setStyle("disb_analysis_std")
        }
        if (window.location.pathname == "/reporting/disbursementAnalysis-University") {
            sessionStorage.setItem("a", "disb_analysis_uni")
            setStyle("disb_analysis_uni")
        }

        if (window.location.pathname == "/reporting/disbursement-repayments-Analysis") {
            sessionStorage.setItem("a", "DBvsREPAY")
            setStyle("DBvsREPAY")
        }
        if (window.location.pathname == "/reporting/repayments-Analysis") {
            sessionStorage.setItem("a", "repay_analysis")
            setStyle("repay_analysis")
        }
        if (window.location.pathname == "/reporting/monthly-analysis") {
            sessionStorage.setItem("a", "monthly_analysis")
            setStyle("monthly_analysis")
        }
        if (window.location.pathname == "/reporting/interview-analysis") {
            sessionStorage.setItem("a", "interview_analysis")
            setStyle("interview_analysis")
        }
        if (window.location.pathname == "/reporting/aging-analysis") {
            sessionStorage.setItem("a", "aging_analysis")
            setStyle("aging_analysis")
        }

        if (window.location.pathname == "/reporting" || window.location.pathname == "/reporting/disbursement-repayments-Analysis" || window.location.pathname == "/reporting/repayments-Analysis" || window.location.pathname == "/reporting/monthly-analysis" || window.location.pathname == "/reporting/interview-analysis" || window.location.pathname == "/reporting/aging-analysis" || window.location.pathname == "/reporting/disbursementAnalysis-Student" || window.location.pathname == "/reporting/disbursementAnalysis-University") {
            sessionStorage.setItem("a", "reporting2")
            setStyle("reporting2")
        }
    };


    return (
        <PathnameContext.Provider value={{ handlePathname, style }}>
            {children}
        </PathnameContext.Provider>
    )
}