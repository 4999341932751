import { login_user, getLogin_user, getTab,getForm9, getForm8, getrenewal, getForm2, getForm4, getRepayments } from "../APIs/api";

import { createContext, useContext, useState, useEffect } from "react"
import { useCookies } from 'react-cookie';


// Step 1
const AuthContext = createContext()

// Step 2
export const useAuth = () => {
    return useContext(AuthContext);
}

// Step 3
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const [tabData, setTabData] = useState({});

    const [loader, setLoader] = useState(false);


    const [renewalTabData, setRenewalTabData] = useState({});

    const [fileData, setfileData] = useState({
        undergrad: false,
        grad: false,
        other: false
    });

    const [fileData2, setfileData2] = useState({});
    
    const getFormData = async () => {
        try {
            const response = await getForm9()
            console.log("RESPONSE hello response ", response);
            if (response.success) {
                setfileData2(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [cookies, setCookie, removeCookie] = useCookies();
    // const [cookies, setCookie, removeCookie] = useCookies(["pk2"]);


    async function Login(body) {
        const res = await login_user(body)
        console.log("RREESS", res);

        if (res?.success) {
            console.log("token", res.hash);
            setUser(res?.user)
            setCookie('pk2', res.hash, {
                path: '/',
                maxAge: 6000000,
            });
        }

        // getFormData()
        return res
    }

    
    async function GettabsData(body) {
        const res = await getTab()
        console.log("TABRES", res);
        if (res?.success) {
            // setUser(res?.user)
            setTabData(res.message)
        }
        return res
    }

    async function getDocuments(body) {
        const res = await getForm8();
        const response = await getForm2();
        const response2 = await getForm4();

        console.log("DOC1", res);
        console.log("DOC2", response);
        console.log("DOC3", response2);
        console.log("TABRES file data", res);

        let upd = {};

        if (res.success && res.message !== null) {
            upd = {
                ...res.message,
            };
        }

        if (response.success && response.message !== null) {
            upd.undergrad = response.message?.underGradApplicable;
            upd.grad = response.message?.postGradApplicable;
        }

        if (response2.success && response2.message !== null) {
            upd.empDis = response2.message?.EmploymentApplicable;
        }

        console.log("UPDATEDFILE", upd);
        setfileData(upd);
        return res;
    }


    async function getRenewalTabs(body) {
        const res = await getrenewal()
        console.log("TABRES", res);
        if (res?.success) {
            // setUser(res?.user)
            setRenewalTabData(res.message)
        }
        return res
    }


    async function GetLoginUser() {
        const response = await getLogin_user()
        console.log("login-user", response);
        if (response.success) {
            setUser(response?.message)

        }


    }

    function Logout() {
        removeCookie("pk2")
        setUser(null);
        setTabData({})
        setRenewalTabData({})
        setfileData({})
        setfileData2({})
    }

    const [repayments, setRepayments] = useState([])

    const getAllRepayments = async () => {
        try {
            const response = await getRepayments()
            console.log("Response", response);
            if (response.success) {
                setRepayments(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }











    return (
        <AuthContext.Provider value={{
            getAllRepayments,
            repayments,
            setRepayments,
            user,
            setUser,
            Login,
            GetLoginUser,
            Logout,
            GettabsData,
            tabData,
            getDocuments,
            fileData,
            setfileData,
            getRenewalTabs,
            renewalTabData,
            loader,
            setLoader,
            fileData2, 
            setfileData2,
            getFormData
        }}>
            {children}
        </AuthContext.Provider>
    )
}


