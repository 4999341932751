import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import SignatureCanvas from 'react-signature-canvas'
import UserModal from '../../components/Modals/Modal';
import Input from '../../components/Form/Input';
import SelectNew from '../../components/Form/Select_New';
// import SelectNew from "../Form/Select_New";
import { useEffect } from 'react';
import { DisbursementAnalysis } from '../../ContextAPI/APIs/api';
import { useToast } from '../../ContextAPI/Components/toast';
import { CSVLink } from "react-csv";
import Pagination from '../../components/Pagination';
import { useAuth } from '../../ContextAPI/Components/auth';

import formatter from "../../helpers/number_formatter"
import { IoArrowBack } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import ExcelJS from 'exceljs';
import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";


const headers = [
    { label: "S.No", key: "SNO", },
    { label: "Name", key: "name", },
    { label: "University", key: "institute", },
    { label: "Program", key: "program", },
    { label: "Family Income Per month (Rs.)", key: "familyIncome", },
    { label: "Per Semester/Year Fee (Rs.)", key: "Semfee / Yearfee", },
    { label: "Outstanding fee (Rs.)", key: "feeOutstanding", },
    { label: "Amount of Fee Recommended", key: "recomendationRupees", },
    { label: "% of Fee Recommended", key: "recomendationPercent", },
    { label: "Repayment Amount", key: "recomendationRepayment", },
    // { label: "Available_Limit",  key: "Available Limit", },
];


function DisbursementAnalysisStudentWise() {

    const { user } = useAuth();

    const { alert } = useToast();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();




    const [totalCount, setTotalCount] = useState(4)


    function FormatDataIntoCSV() {
        const formattedData = data.map((item, i) => ({
            SNO: i + 1,
            ...item,
            institute: item.institute ? item.institute.fullName : null,
            program: item.program ? item.program.name : null,
            Available_Limit: null
        }));

        return formattedData
    }


    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";


    const fetchTemplateAndExport = async (apiData, fileName) => {
        try {
          // Fetch the template file
        //   const response = await fetch('/template.xlsx');
        const response = await fetch('/disbursementAnalysisSTD.xlsx');
          
        if (!response.ok) {
            throw new Error('Failed to fetch template file');
          }

          const arrayBuffer = await response.arrayBuffer();
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(arrayBuffer);
    
          // Assuming the template has a sheet named 'Sheet1'
          const worksheet = workbook.getWorksheet('Sheet1');
    
          // Determine the starting row to insert data (e.g., row 3 to avoid headers)
          const startRow = 3;
    
          // Add new data starting from the specified row
          apiData.forEach((row, rowIndex) => {
            const sheetRow = worksheet.getRow(startRow + rowIndex);
            Object.keys(row).forEach((key, colIndex) => {
              const cell = sheetRow.getCell(colIndex + 1); // ExcelJS is 1-based for columns
              cell.value = row[key];
              // Copy the style from the second row (headers) for new rows
              const headerCell = worksheet.getRow(2).getCell(colIndex + 1);
              if (headerCell) {
                cell.style = { ...headerCell.style };
              }
            });
            sheetRow.commit();
          });
    
          // Write the updated workbook to a buffer
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: fileType });
    
          // Save the updated file
          FileSaver.saveAs(blob, fileName + fileExtension);
        } catch (error) {
          console.error('Error exporting to Excel:', error);
        }
      };
    



    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(skip)
        // search(selectedUni, selectedProgram, selectedStatus, skip)
    }



    const currentYear = new Date().getFullYear();

    const yearOptions = [];
    for (let year = currentYear; year >= 2010; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }

    const [year, setYear] = useState("")
    // Handler function to handle option change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        // Do something with the selected year
        setYear(event.target.value)

        console.log("Selected year:", selectedYear);
    };



    const [data, setData] = useState([])
    // const [totalCount, setTotalCount] = useState(0)
    const [xlsxData, setXlsxData] = useState([])

    const search = async (skip) => {
        try {
            // e.preventDefault();

            const response = await DisbursementAnalysis({ year: year, type: "student" }, skip)

            console.log("hello responseeee", response);
            if (response.success && response.message.length > 0) {
                setData(response.message)
                // setTotalCount(response.totalCount)
                setTotalCount(response.totalCount.length)
                setShow(false)

                let temp = []
                for (let i = 0; i < response.totalCount?.length; i++) {
                    let a = {}

                    a.Sno = (pageSkip * 10) + i + 1
                    a.Name = response?.totalCount[i]?.studentId?.fullName
                    a.University = response?.totalCount[i]?.institute?.fullName
                    a.Program = response?.totalCount[i]?.program?.name
                    a.Total =formatter(response?.totalCount[i]?.totalAmount)

                    for (let j = 0; j < response?.totalCount[i]?.Alltranches.length; j++) {
                        a[`tranch${j + 1}`] = formatter(response?.totalCount[i]?.Alltranches[j]);
                    }
                    temp.push(a)
                }

                console.log("TEMP",temp);

                setXlsxData(temp)

            }
            if (!response.success) {
                alert(response.message, response.success)
                setData([])
                setXlsxData([])

            }
            if (response.success && response.message.length < 1) {
                setData([])
                setXlsxData([])

                alert("No data found", false)
            }

        } catch (e) {
            console.log(e);
        }
    };




    return (
        <>
            <Helmet>
                <title>Disbursement Analysis - Student Wise </title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section'>

                        <div className='d-flex jc-between ai-center mb-4 flex_heading_button'>

                            <div className='d-flex ai-center'>
                                <Link to="/reporting">
                                    <IoArrowBack className='back_arrow_icon me-3' />
                                </Link>
                                <h2 className='mb-0'>Disbursement Analysis - Student Wise</h2>
                            </div>

                            <div className='d-flex jc-end gap-1 buttons'>

                                {/* {
                                    (data.length > 0 && (user?.role == "admin" || user?.role == "operations")) &&
                                    (
                                        <CSVLink filename={"Fresh.csv"} data={FormatDataIntoCSV()} headers={headers} className='link'>
                                            <button className='btn save_btn'> Download </button>
                                        </CSVLink>
                                    )
                                } */}
                                {
                                    ((data.length > 0 && xlsxData.length > 0) && (user?.role == "admin" || user?.role == "operations")) &&
                                    (
                                        <button 
                                        // onClick={(e) => exportToCSV(xlsxData, "Disbursement Analysis")} 
                                        onClick={(e) => fetchTemplateAndExport(xlsxData, "Disbursement Analysis")} 
                                        className='btn save_btn'> Download </button>
                                    )
                                }

                                <UserModal btnText={"Filter"} heading="Filter" id={"filter"} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Year <span className='required'>*</span></label>
                                                <select value={year} className='form-select' id="yearSelect" onChange={handleYearChange}>
                                                    <option selected disabled value="">Select Year</option>
                                                    {yearOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='d-flex jc-end'>
                                            <button onClick={() => search(pageSkip)} className='btn save_btn'>Save</button>
                                        </div>
                                    </div>
                                </UserModal>
                            </div>

                        </div>

                        <div className="card">
                            {
                                data.length > 0 ?
                                    <>
                                        <Table
                                            class="table-striped"
                                            head={["S.No", "Name", "University", "Program", "Total Amount", "Tranches"]}
                                        >
                                            {
                                                data?.map((item, i) => {
                                                    return <tr key={i}>
                                                        <td><Text text={(pageSkip * 10) + i + 1} /></td>
                                                        <td><Text text={item?.studentId?.fullName} /></td>
                                                        <td><Text text={item?.institute?.fullName} /></td>
                                                        <td><Text text={item?.program?.name} /></td>
                                                        <td><Text text={formatter(item?.totalAmount)} /></td>
                                                        {
                                                            item?.Alltranches.map((item) => (
                                                                <td><Text text={formatter(item)} /></td>
                                                            ))
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                    </>

                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }
                        </div>

                    </section>

                </div>
            </div>

        </>
    )
}

export default DisbursementAnalysisStudentWise
