import { Helmet } from "react-helmet";

export function PageNotFound() {


    return <>
         <Helmet>
                <title>404 | Ihsan Trust</title>
            </Helmet>

        <div className='update_msg'>
            <div>
                <h1>404</h1>
                <h2>Page not found!</h2>
            </div>
        </div>
    </>
}