import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import SignatureCanvas from 'react-signature-canvas'
import UserModal from '../../components/Modals/Modal';
import Input from '../../components/Form/Input';
import SelectNew from '../../components/Form/Select_New';

// import SelectNew from "../Form/Select_New";
import { useEffect } from 'react';
import { ApprovalSheetFilter, GetUnisList } from '../../ContextAPI/APIs/api';
import { useToast } from '../../ContextAPI/Components/toast';
import { CSVLink } from "react-csv";
import Pagination from '../../components/Pagination';
import { useAuth } from '../../ContextAPI/Components/auth';
import formatter from '../../helpers/number_formatter';


import ExcelJS from 'exceljs';
import * as FileSaver from "file-saver";


const headers = [
    { label: "S.No", key: "SNO", },
    { label: "Name", key: "name", },
    { label: "University", key: "institute", },
    { label: "Program", key: "program", },
    { label: "Family Income Per month (Rs.)", key: "familyIncome", },
    { label: "Per Semester/Year Fee (Rs.)", key: "Semfee / Yearfee", },
    { label: "Outstanding fee (Rs.)", key: "feeOutstanding", },
    { label: "Amount of Fee Recommended", key: "recomendationRupees", },
    { label: "% of Fee Recommended", key: "recomendationPercent", },
    { label: "Repayment Amount", key: "recomendationRepayment", },
    // { label: "Available_Limit",  key: "Available Limit", },
];


function ApprovalSheet() {

    const { user } = useAuth();

    const { alert } = useToast();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [uni, setUni] = useState([])

    const [Qualification, setQualification] = useState({
        university: "",
    })

    const SELECTUNI = (e) => {
        console.log(e)
        try {
            setQualification({ ...Qualification, university: e })
        }
        catch (e) {
            console.log(e);
        }
    }

    const getAllUni = async () => {
        try {
            const response = await GetUnisList()

            if (response?.success) {
                console.log("UNISS", response.message);
                setUni(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleChange = (event, dateType) => {
        const value = event.target.value;

        // Convert the date to timestamp
        const timestamp = Date.parse(value);

        // Update the state based on the date type
        if (dateType === 'start') {
            setStartDate(value);
        } else if (dateType === 'end') {
            setEndDate(value);
        }
    };


    const [data, setData] = useState([])
    const [xlsxData, setXlsxData] = useState([])


    const search = async (e) => {
        try {
            e.preventDefault();

            if (!Qualification.university && !startDate && !endDate) {
                alert("Please select any filter", false);
                return;
            } else {
                if (startDate && !endDate) {
                    alert('End Date is required', false);
                    return;
                } else if (endDate && !startDate) {
                    alert('Start Date is required', false);
                    return;
                }
            }


            const payload = {
                uni: Qualification.university._id,
                start: startDate,
                end: endDate,
                type: "fresh"
            }

            console.log("PAYLOAD", payload);
            const response = await ApprovalSheetFilter(payload, 0)
            console.log("FILTER---->", response);
            if (response.success && response.message.length > 0) {
                setData(response.message)
                setTotalCount(response.totalCount?.length)
                let temp = []
                for (let i = 0; i < response?.totalCount?.length; i++) {
                    let a = {}
                    a["S.No"] = i + 1
                    a.Name = response?.totalCount[i]?.name
                    a.university = response?.totalCount[i]?.institute?.fullName
                    a["Family Income Per month (Rs.)"] = response?.totalCount[i]?.familyIncome
                    a.Program = response?.totalCount[i]?.program?.name
                    a["Semester/Year"] = response?.totalCount[i]?.currentSem || response?.totalCount[i]?.currentYear
                    a["Semester/Yearly Fee"] = response?.totalCount[i]?.Semfee !== "" ? formatter(response?.totalCount[i]?.Semfee) : formatter(response?.totalCount[i]?.Yearfee)
                    a["Outstanding Fee"] = formatter(response?.totalCount[i]?.feeOutstanding)
                    a["Amount of Fee Recomended"] = formatter(response?.totalCount[i]?.recomendationRupees)
                    a["% of Fee Recomended"] = `${response?.totalCount[i]?.recomendationPercent} %`
                    a["Repayment Amount"] = formatter(response?.totalCount[i]?.recomendationRepayment)
                temp.push(a)
                }
                setXlsxData(temp)
                setShow(false)
            }
            else {
                alert("No data found", false)
            }

        } catch (e) {
            console.log(e);
        }
    };

    const [totalCount, setTotalCount] = useState(4)

    const fetchData = async (skip) => {
        try {
            const response = await ApprovalSheetFilter({ type: "fresh" }, skip)
            console.log("FILTER---->", response);
            if (response.success && response.message.length > 0) {
                setData(response.message)
                setTotalCount(response?.totalCount?.length)
                // setShow(false)
                // <td><Text text={(pageSkip*10)+ i + 1} /></td>
                // <td><Text text={item?.name} /></td>
                // <td><Text text={item?.institute?.fullName} /></td>
                // <td className='text-center'><Text text={formatter(item?.familyIncome)} /></td>
                // <td className='text-capital'><Text text={item?.program?.name} /></td>
                // <td className='text-center'><Text text={item?.currentSem || item?.currentYear} /></td>
                // <td className='text-center'><Text text={item?.Semfee !== "" ? formatter(item?.Semfee) : formatter(item?.Yearfee)} /></td>
                // <td className='text-center'><Text text={formatter(item?.feeOutstanding)} /></td>
                // <td className='text-center'><Text text={formatter(item?.recomendationRupees)} /></td>
                // <td className='text-center'><Text text={`${item?.recomendationPercent} %`} /></td>
                // <td className='text-center'><Text text={formatter(item?.recomendationRepayment)} /></td>

                // head={["S.No", "Name", "University", "Family Income Per month (Rs.)", "Program", "Semester/Year", 
                // "Semester/Yearly Fee", "Outstanding Fee", "Amount of Fee Recommended", "% of Fee Recommended", "Repayment Amount",
                //     // "Available Limit in Rs."
                // ]} >
                let temp = []
                for (let i = 0; i < response?.totalCount?.length; i++) {
                    let a = {}
                    a["S.No"] = i + 1
                    a.Name = response?.totalCount[i]?.name
                    a.university = response?.totalCount[i]?.institute?.fullName
                    a["Family Income Per month (Rs.)"] = response?.totalCount[i]?.familyIncome
                    a.Program = response?.totalCount[i]?.program?.name
                    a["Semester/Year"] = response?.totalCount[i]?.currentSem || response?.totalCount[i]?.currentYear
                    a["Semester/Yearly Fee"] = response?.totalCount[i]?.Semfee !== "" ? formatter(response?.totalCount[i]?.Semfee) : formatter(response?.totalCount[i]?.Yearfee)
                    a["Outstanding Fee"] = formatter(response?.totalCount[i]?.feeOutstanding)
                    a["Amount of Fee Recomended"] = formatter(response?.totalCount[i]?.recomendationRupees)
                    a["% of Fee Recomended"] = response?.totalCount[i]?.recomendationPercent
                    a["Repayment Amount"] = formatter(response?.totalCount[i]?.recomendationRepayment)
                temp.push(a)
                }
                setXlsxData(temp)

            }
            else {
                alert("No Data Found", false)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function FormatDataIntoCSV() {
        const formattedData = data.map((item, i) => ({
            SNO: i + 1,
            ...item,
            institute: item.institute ? item.institute.fullName : null,
            program: item.program ? item.program.name : null,
            Available_Limit: null
        }));

        return formattedData
    }



    useEffect(() => {
        fetchData(pageSkip)
        getAllUni()
    }, [])




    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        fetchData(skip)
        // search(selectedUni, selectedProgram, selectedStatus, skip)
    }



    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";


    const fetchTemplateAndExport = async (apiData, fileName) => {
        try {
            // Fetch the template file
            //   const response = await fetch('/template.xlsx');
            const response = await fetch('/approvalSheetFresh.xlsx');

            if (!response.ok) {
                throw new Error('Failed to fetch template file');
            }

            const arrayBuffer = await response.arrayBuffer();
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(arrayBuffer);

            // Assuming the template has a sheet named 'Sheet1'
            const worksheet = workbook.getWorksheet('Sheet1');

            // Determine the starting row to insert data (e.g., row 3 to avoid headers)
            const startRow = 3;

            // Add new data starting from the specified row
            apiData.forEach((row, rowIndex) => {
                const sheetRow = worksheet.getRow(startRow + rowIndex);
                Object.keys(row).forEach((key, colIndex) => {
                    const cell = sheetRow.getCell(colIndex + 1); // ExcelJS is 1-based for columns
                    cell.value = row[key];
                    // Copy the style from the second row (headers) for new rows
                    const headerCell = worksheet.getRow(2).getCell(colIndex + 1);
                    if (headerCell) {
                        cell.style = { ...headerCell.style };
                    }
                });
                sheetRow.commit();
            });

            // Write the updated workbook to a buffer
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: fileType });

            // Save the updated file
            FileSaver.saveAs(blob, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };



    return (
        <>
            <Helmet>
                <title>Approval Sheet - Fresh Cases</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='approval_sheet_section'>
                        <div className='d-flex jc-between ai-center mb-4 flex_heading_button'>
                            <h2 className='mb-0'>Approval Sheet - Fresh Cases</h2>

                            <div className='d-flex jc-end gap-1 buttons'>

                            {
                                    ((data.length > 0 && xlsxData.length > 0) && (user?.role == "admin" || user?.role == "operations")) &&
                                    (
                                        <button 
                                        // onClick={(e) => exportToCSV(xlsxData, "Disbursement Analysis")} 
                                        onClick={(e) => fetchTemplateAndExport(xlsxData, "Approval Sheet Fresh")} 
                                        className='btn save_btn'> Download </button>
                                    )
                                }

                                <UserModal btnText={"Filter"} heading="Filter" id={"filter"} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <SelectNew
                                                    label="Name of the University/Institution:"
                                                    options={[{ _id: '', fullName: 'Select your University/Institution' }, ...uni]}
                                                    isOptionDisabled={(option) => (option._id == '' || option._id == null)}
                                                    value={Qualification?.university}
                                                    getOptionLabel={option =>
                                                        `${option.fullName}`
                                                    }
                                                    getOptionValue={option => `${option._id}`}
                                                    onChange={(value) => SELECTUNI(value)}
                                                    loadingMessage={"Loading..."}
                                                    style={{ textTransform: "capitalize" }}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <Input
                                                    inputType="date"
                                                    label="Start Date"
                                                    placeholder="Enter here"
                                                    value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                    onChange={(event) => handleChange(event, 'start')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <Input
                                                    inputType="date"
                                                    label="End Date"
                                                    placeholder="Enter here"
                                                    value={endDate ? new Date(endDate).toISOString().split('T')[0] : ''}
                                                    onChange={(event) => handleChange(event, 'end')}
                                                />
                                            </div>
                                        </div>

                                        <div className='d-flex jc-end'>
                                            <button onClick={(e) => search(e)} className='btn save_btn'>Save</button>
                                        </div>
                                    </div>
                                </UserModal>
                            </div>

                        </div>

                        <div className="card">
                            {
                                data.length > 0 ?
                                    <>
                                        <Table
                                            head={["S.No", "Name", "University", "Family Income Per month (Rs.)", "Program", "Semester/Year", "Semester/Yearly Fee", "Outstanding Fee", "Amount of Fee Recommended", "% of Fee Recommended", "Repayment Amount",
                                                // "Available Limit in Rs."
                                            ]} >
                                            {
                                                data?.map((item, i) => {
                                                    return <tr key={i}>
                                                        <td><Text text={(pageSkip * 10) + i + 1} /></td>
                                                        <td><Text text={item?.name} /></td>
                                                        <td><Text text={item?.institute?.fullName} /></td>
                                                        <td className='text-center'><Text text={formatter(item?.familyIncome)} /></td>
                                                        <td className='text-capital'><Text text={item?.program?.name} /></td>
                                                        <td className='text-center'><Text text={item?.currentSem || item?.currentYear} /></td>
                                                        {/* <td className='text-center'><Text text={formatter(item?.Semfee) || formatter(item?.Yearfee)} /></td> */}
                                                        <td className='text-center'><Text text={item?.Semfee !== "" ? formatter(item?.Semfee) : formatter(item?.Yearfee)} /></td>
                                                        {/* <td className='text-center'><Text text={item?.currentSem !== "" ? item?.currentSem : item?.currentYear} /></td> */}
                                                        {/* <td className='text-center'><Text text={item?.Semfee !== "" ? item?.Semfee : item?.Yearfee} /></td> */}
                                                        <td className='text-center'><Text text={formatter(item?.feeOutstanding)} /></td>
                                                        <td className='text-center'><Text text={formatter(item?.recomendationRupees)} /></td>
                                                        <td className='text-center'><Text text={`${item?.recomendationPercent} %`} /></td>
                                                        <td className='text-center'><Text text={formatter(item?.recomendationRepayment)} /></td>
                                                        {/* <td><Text text={"Available Limit in Rs"} /></td> */}
                                                    </tr>
                                                })
                                            }
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }
                        </div>

                    </section>

                </div>
            </div>

        </>
    )
}

export default ApprovalSheet
