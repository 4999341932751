export function ValidateNIC(NICNumber) {
  // Convert the number to a string

  console.log("NIC", NICNumber.length);
  console.log("NICNUM", NICNumber);

  // const numberStr = String(NICNumber);

  const hasUnderscores = /_/.test(NICNumber);
  if (hasUnderscores) {
    console.log('NICNUM should not contain underscores.');
    return false
  }

  // Check if the length is 13
  if (NICNumber.length === 15) {
    return true; // Valid

  } else {
    return false; // Not valid

  }
}  