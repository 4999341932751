import { useState } from "react";
import { UpdateProfilePic, getPic } from "../../ContextAPI/APIs/api";
import { useAuth } from "../../ContextAPI/Components/auth";
import { useToast } from "../../ContextAPI/Components/toast";
import { profilePicUrl } from "../../helpers/data";
import profile from "../../assets/profile.svg"
import { LiaUniversitySolid } from "react-icons/lia";
import { useMemo } from "react";
import TooltipComponent from "../tooltip";
import { MdInfoOutline } from "react-icons/md";


export function Upload_Profile_Pic() {
    const { alert } = useToast()
    const { user, GetLoginUser } = useAuth()

    const [logo, setLogo] = useState(null);
    const [showLogo, setShowLogo] = useState([]);

    const onSelectLogo = (event) => {
        const selectedFiles = event.target.files;
        setLogo(selectedFiles[0])
        setShowLogo(URL.createObjectURL(selectedFiles[0]))

        // FOR BUG IN CHROME
        event.target.value = "";
    };

    function deleteLogo(image, e) {
        e.preventDefault();
        setLogo(null);
        URL.revokeObjectURL(image);
        setShowLogo([])
    }

    async function handleImage(e) {
        e.preventDefault();

        const myForm = new FormData();
        myForm.append("picture", logo)
        const res = await UpdateProfilePic(myForm)
        if (res.success) {
            GetLoginUser()
            setLogo(null)
            return alert(res.message, res.success)
        }
        else return alert(res.message, res.success)
    }


    return <>

        <div className="d-flex">
            <h4>Profile Picture <span className="required">*</span></h4>

            <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Profile picture is required. Click on the tick after uploading your profile picture to save it.">
                <div><MdInfoOutline className='hint-icon mb-2 ml-1' /></div>
            </TooltipComponent>
        </div>

        <form>
            <div className='profile_picture mb-4'>
                <div className={`profile-image image ${(user.profilepic && user.profilepic != "") ? "p-0" : "pt-2"}`}>
                    {
                        (showLogo && showLogo != "") ?
                            <img src={showLogo} alt="logo 1" className="img-fluid" />
                            :
                            (user.profilepic && user.profilepic != "") ?
                                <img src={`${profilePicUrl}/${user.profilepic}`} alt="logo 3" className="img-fluid" />
                                :
                                <img src={profile} alt="logo 4" className="img-fluid" />
                    }

                </div>

                <label className="mb-0">
                    <input accept=".jpg,.png,.jpeg,.gif" type="file" name="" className='upload_image_input' id="" style={{ display: "none" }} onChange={onSelectLogo} />
                    {/* <div className="overlay"> */}

                    <div className="d-flex jc-center ai-center text-center">
                        {
                            (!user.profilepic || user.profilepic == "") ?
                                (typeof logo == "undefined" || logo === null) && (
                                    <div className="upload_image_btn ml-2">Upload</div>
                                )
                                :
                                (typeof logo == "undefined" || logo === null) && (
                                    <div className="icon">
                                        <i className="fas fa-pen"></i>
                                    </div>
                                )
                        }

                        {
                            typeof logo !== "undefined" && logo !== null &&
                            <>
                                <div className="icon d-flex jc-center">
                                    <div onClick={(e) => handleImage(e)}>
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div onClick={(e) => deleteLogo(logo.image, e)}>
                                        <i className="fas fa-trash"></i>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </label>
            </div>
        </form>
    </>
}


























export function Upload_User_Profile_Pic(props) {
    const { alert } = useToast()

    const [PP, setPP] = useState("");
    const [logo, setLogo] = useState(null);
    const [showLogo, setShowLogo] = useState([]);

    const onSelectLogo = (event) => {
        const selectedFiles = event.target.files;
        setLogo(selectedFiles[0])
        setShowLogo(URL.createObjectURL(selectedFiles[0]))

        // FOR BUG IN CHROME
        event.target.value = "";
    };

    function deleteLogo(image, e) {
        e.preventDefault();
        setLogo(null);
        URL.revokeObjectURL(image);
        setShowLogo([])
    }

    async function handleImage(e) {
        e.preventDefault();
        // alert(props.id)
        const myForm = new FormData();
        myForm.append("picture", logo)
        myForm.append("id", props.id)
        const res = await UpdateProfilePic(myForm)
        if (res.success) {
            setLogo(null)

            if (props.callDataFunction != null && props.callDataFunction != "" && typeof props.callDataFunction != "undefined") props.callDataFunction()

        }
        alert(res.message, res.success)
    }


    useMemo(() => {
        if (props.profilepic != "" && props.profilepic != null) {
            setPP(props.profilepic)
        }

    }, [props.profilepic])


    return <>
        <h4>Profile Picture</h4>
        <form>
            <div className='profile_picture mb-4'>
                <div className={`profile-image image ${(PP && PP != "") ? "p-0" : "pt-2"}`}>
                    {
                        (showLogo && showLogo != "") ?
                            <img src={showLogo} alt="logo 1" className="img-fluid" />
                            :
                            (PP && PP != "") ?
                                <img src={`${profilePicUrl}/${PP}`} alt="logo 3" className="img-fluid" />
                                :
                                <img src={profile} alt="logo 4" className="img-fluid" />
                    }
                </div>

                <label className="mb-0">
                    <input type="file" accept=".jpg,.png,.jpeg,.gif" className='upload_image_input' style={{ display: "none" }} onChange={onSelectLogo} />

                    <div className="overlay">
                        <span className="icon d-flex justify-content-around align-items-center w-40 text-center">
                            <div>
                                <i className="fas fa-pen"></i>
                            </div>
                            {
                                typeof logo !== "undefined" && logo !== null &&
                                <>
                                    <div onClick={(e) => deleteLogo(logo.image, e)}>
                                        <i className="fas fa-trash"></i>
                                    </div>
                                    <div onClick={(e) => handleImage(e)}>
                                        <i className="fas fa-check"></i>
                                    </div>
                                </>
                            }
                        </span>
                    </div>

                </label>
            </div>
        </form>
    </>
}



export function User_Profile_Pic(props) {
    const { user } = useAuth()

    return <>
        <div className='profile_picture'>
            <div className={`profile-image image ${user.profilepic && user.profilepic != "" ? "p-0" : "pt-2"} ${props.classes && props.classes}`}>
                <label className="mb-0">
                    {
                        (user.profilepic && user.profilepic != "") ?
                            <img src={`${profilePicUrl}/${user.profilepic}`} alt="logo 3" className="img-fluid" />
                            :
                            <img src={profile} alt="logo 4" className="img-fluid" />
                    }
                </label>
            </div>
        </div>
    </>
}



export function Show_Profile_Pic(props) {
    return <>
        <div className='profile_picture'>
            <div className={`profile-image image ${(props.src && props.src != "" && typeof props.src != "undefined" && props.src != null) ? "p-0" : "pt-2"} ${props.classes && props.classes}`}>
                <label className="mb-0">
                    {
                        (props.src && props.src != "" && typeof props.src != "undefined" && props.src != null) ?
                            <img src={`${profilePicUrl}/${props.src}`} alt="logo 3" className="img-fluid" />
                            :
                            <>
                                {
                                    props?.role == "university" ?
                                        <LiaUniversitySolid className="uni_icon" />
                                        :
                                        <img src={profile} alt="logo 4" className="img-fluid" />
                                }
                            </>
                    }
                </label>
            </div>
        </div>
    </>
}


export function Student_Profile_Pic_Dashboard(props) {
    return <>
        <div className='profile_picture'>
            <div className={`student_profile_image image ${(props.src && props.src != "" && typeof props.src != "undefined" && props.src != null) ? "p-0" : "pt-2"} ${props.classes && props.classes}`}>
                <label className="mb-0">
                    {/* {
                        (props.src && props.src != "" && typeof props.src != "undefined" && props.src != null) ? */}
                    <img src={`${profilePicUrl}/${props.src}`} alt="logo 3" className="img-fluid" />
                    {/* :
                            <>
                                {
                                    props?.role == "university" ?
                                        <LiaUniversitySolid className="uni_icon" />
                                        :
                                        <img src={profile} alt="logo 4" className="img-fluid" />
                                }
                            </>
                    } */}
                </label>
            </div>
        </div>
    </>
}