import React, { useState, useEffect } from 'react'

import { useParams } from "react-router-dom"
import { RenewalUrl } from '../../helpers/data';
import { useAuth } from "../../ContextAPI/Components/auth"
import { getAllRenewalAdmin } from '../../ContextAPI/APIs/api';
import { FileViewerAdminRenewal } from '../Form/fileViewerAdminRenewal';
import formatter from '../../helpers/number_formatter';

import { ValidatePhoneNumber } from '../../helpers/validatePhoneNumber';

import PERSONAL_INFO from "../../assets/form-icons/personal-information.png"
import ACADEMIC_INFO from "../../assets/form-icons/academic.png"
import INCOME_DETAIL from "../../assets/form-icons/income-detail.png"
import CASE_HISTORY from "../../assets/form-icons/case-history.png"


function Preview() {

    const { id, doc } = useParams();

    return (
        <>
            <Demographics_Information id={id} doc={doc} />
            <Academic_Background id={id} doc={doc} />
            <Renewal_Details id={id} doc={doc} />
            <Additional_Information id={id} doc={doc} />
        </>
    )
}


export function Demographics_Information(props) {

    const { user } = useAuth()

    const [form1, setForm1] = useState({
        Name: "",
        email: "",
        contactNo: "",
        Institute: "",
        InstituteName: "",
        Program: "",
        ProgramName: "",
        status: "",
        meezanBanckAccount: "",
    })

    const getDData = async () => {
        try {
            const response = await getAllRenewalAdmin({ id: props.doc })
            console.log("RRR123", response);
            if (response.success) {
                setForm1({
                    ...form1,
                    Name: response.message.Name,
                    email: response.message.email,
                    contactNo: response.message.contactNo,
                    Institute: response.message?.Institute?.fullName,
                    Program: response.message.Program?.name,
                    status: response.message.status,
                    meezanBanckAccount: response.message.meezanBanckAccount,
                })

                // setForm2({
                //     ...form2,
                //     renewalStatus: response.message.renewalStatus,
                //     sem_year: response.message.sem_year,
                //     cGPA: response.message.cGPA,
                // })

                // setForm3({
                //     ...form3,
                //     Amt_Disb_ToDate: response.message.Amt_Disb_ToDate,
                //     Monthly_rePay: response.message.Monthly_rePay,
                //     rePayDate: response.message.rePayDate,
                //     amountOutstanding: response.message.amountOutstanding,
                //     promptRepay: response.message.promptRepay,
                //     question1: response.message.question1,
                //     question2: response.message.question2,
                //     amtRequested: response.message.amtRequested,
                //     revMonthlyRepay: response.message.revMonthlyRepay,
                //     srcMonthlyRepay: response.message.srcMonthlyRepay,
                // })

                // setForm4({
                //     ...form4,
                //     prevTotalIncome: response.message.prevTotalIncome,
                //     currTotalIncome: response.message.currTotalIncome,
                //     prevTotalExpense: response.message.prevTotalExpense,
                //     currTotalExpense: response.message.currTotalExpense,
                //     prevTotalAssets: response.message.prevTotalAssets,
                //     currTotalAssets: response.message.currTotalAssets,
                //     newMajorExpense: response.message.newMajorExpense,
                //     reasonForAssetsChange: response.message.reasonForAssetsChange,
                //     srcMonthlyRepay: response.message.srcMonthlyRepay,
                // })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])


    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Demographics Information</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <img src={PERSONAL_INFO} alt="icon" className='img-fluid form_icons' />
                                Personal Info
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className='form-group'>
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="Name"
                                                // value={form1.Name}
                                                value={form1.Name}
                                                placeholder=''
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className='form-group'>
                                            <label className="form-label">Email Address</label>
                                            <input
                                                type="email"
                                                className='form-control'
                                                name="email"
                                                value={form1.email}
                                                placeholder=''
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className='form-group'>
                                            <label className="form-label">Contact Number</label>
                                            <input
                                                inputType="tel"
                                                className='form-control'
                                                name="contactNo"
                                                value={form1.contactNo}
                                                placeholder=''
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className='form-group'>
                                            <label className="form-label">Name of the University/Institution</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="Name"
                                                value={form1?.Institute}
                                                placeholder=''
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className='form-group'>
                                            <label className="form-label">Program</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="Name"
                                                value={form1.Program}
                                                placeholder=''
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className='form-group' id='jobStatus'>
                                            <label className='form-label'>Job Status <span className='required'>*</span></label>
                                            <input type="text" className='form-control' value={form1.status} disabled />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className='form-group' id='meezanBanckAccount'>
                                            <label className="form-label">Meezan Bank Current Account No <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                name="meezanBanckAccount"
                                                value={form1.meezanBanckAccount}
                                                // onChange={handleChange}
                                                className='form-control'
                                                placeholder=''
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Academic_Background(props) {

    const { user } = useAuth();

    const { id, doc } = useParams();

    const [form2, setForm2] = useState({
        renewalStatus: "",
        sem_year: "",
        cGPA: "",
        semtype: ""
    })

    const getDData = async () => {
        try {
            const response = await getAllRenewalAdmin({ id: props.doc })
            console.log("RRR", response);
            if (response.success) {


                setForm2({
                    ...form2,
                    renewalStatus: response.message.renewalStatus,
                    sem_year: response.message.sem_year,
                    cGPA: response.message.cGPA,
                    semtype: response.message.semtype,
                })

            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])


    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Academic Background</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                Semester / Year
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className='form-group' id='status'>
                                            <label className="form-label">Status <span className='required'>*</span></label>
                                            <input type="text" className='form-control' value={form2.renewalStatus} disabled />
                                        </div>
                                    </div>
                                    {
                                        form2.sem_year !== "" && (
                                            <div className="col-lg-6 col-md-6">
                                                <div className='form-group' id='sem_year'>
                                                    <label className="form-label">Semester/Year <span className='required'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="sem_year"
                                                        value={form2.sem_year}
                                                        // onChange={handleChangeForm2}
                                                        className='form-control'
                                                        placeholder=''
                                                        disabled
                                                        required
                                                    />
                                                </div>
                                            </div>

                                        )
                                    }


                                    {
                                        user.type == "semester" &&
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                            <div className='form-group'>
                                                <label className="form-label">Academic Session</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className='form-control'
                                                    name="semtype"
                                                    value={form2.semtype}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div className="col-md-12">
                                        <FileViewerAdminRenewal
                                            id={doc}
                                            url={RenewalUrl}
                                            keyName={"paidFeeVoucher"}
                                            span="*"
                                            text="Latest (paid (if any) and unpaid) Fee Voucher / Fee Ledger"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group' id='cGPA'>
                                            <label className="form-label">Current (latest) CGPA / Percentage(%) <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                name="cGPA"
                                                value={form2.cGPA}
                                                // onChange={handleChangeForm2}
                                                className='form-control'
                                                placeholder=''
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <FileViewerAdminRenewal
                                            id={doc}
                                            url={RenewalUrl}
                                            keyName={"latestTranscript"}
                                            span="*"
                                            text="Latest Transcript / Result Sheet"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Renewal_Details(props) {

    const [form3, setForm3] = useState({
        Amt_Disb_ToDate: "",
        Monthly_rePay: "",
        rePayDate: "",
        amountOutstanding: "",
        promptRepay: "",
        question1: "",
        question2: "",
        amtRequested: "",
        revMonthlyRepay: "",
        srcMonthlyRepay: "",
    })

    const getDData = async () => {
        try {
            const response = await getAllRenewalAdmin({ id: props.doc })
            console.log("RRR", response);
            if (response.success) {
                setForm3({
                    ...form3,
                    Amt_Disb_ToDate: response.message.Amt_Disb_ToDate,
                    Monthly_rePay: response.message.Monthly_rePay,
                    rePayDate: response.message.rePayDate,
                    amountOutstanding: response.message.amountOutstanding,
                    promptRepay: response.message.promptRepay,
                    question1: response.message.question1,
                    question2: response.message.question2,
                    amtRequested: response.message.amtRequested,
                    revMonthlyRepay: response.message.revMonthlyRepay,
                    srcMonthlyRepay: response.message.srcMonthlyRepay,
                })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])


    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Renewal Details</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    {/* Case History */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <img src={CASE_HISTORY} alt="icon" className='img-fluid form_icons' />
                                Case History
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row case_history">
                                    <div className="col-md-6">
                                        <div className='form-group' id='Amt_Disb_ToDate'>
                                            <label className="form-label">Amount Disbursed to Date <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                name="Amt_Disb_ToDate"
                                                value={formatter(form3.Amt_Disb_ToDate)}
                                                // value={form3.Amt_Disb_ToDate ? new Date(form3.Amt_Disb_ToDate).toISOString().split('T')[0] : ''}
                                                // onChange={handleChangeForm3}
                                                className='form-control'
                                                placeholder=''
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-group' id='Monthly_rePay'>
                                            <label className="form-label">What token monthly repayment amount you can easily pay? <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                name="Monthly_rePay"
                                                value={formatter(form3.Monthly_rePay)}
                                                // onChange={handleChangeForm3}
                                                className='form-control'
                                                placeholder=''
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-group' id='rePayDate'>
                                            <label className="form-label">Repayments Made to Date <span className='required'>*</span></label>
                                            <input
                                                type="date"
                                                name="rePayDate"
                                                value={form3.rePayDate ? new Date(form3.rePayDate).toISOString().split('T')[0] : ''}
                                                // onChange={handleChangeForm3}
                                                className='form-control'
                                                placeholder=''
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-group' id='amountOutstanding'>
                                            <label className="form-label">Amount Outstanding <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                name="amountOutstanding"
                                                value={formatter(form3.amountOutstanding)}
                                                // onChange={handleChangeForm3}
                                                className='form-control'
                                                placeholder=''
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='form-group' id='promptRepay'>
                                            <label className='form-label'> Prompt repayment <span className='required'>*</span></label>
                                            <input type="text" className='form-control' value={form3.promptRepay} disabled />
                                        </div>
                                    </div>
                                    {
                                        form3.promptRepay == "no" &&
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">If No, explain why: <span className='required'>*</span></label>
                                                <textarea
                                                    cols="30"
                                                    rows="5"
                                                    className='form-control'
                                                    name="question1"
                                                    value={form3.question1}
                                                    // onChange={handleChangeForm3}
                                                    placeholder=''
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>
                                    }
                                    {
                                        form3.question2 &&
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">Any other financial assistance received during the review period? If yes, then what are the amount and the type of the financial assistance?</label>
                                                <textarea
                                                    cols="30"
                                                    rows="5"
                                                    className='form-control'
                                                    name="question2"
                                                    value={form3.question2}
                                                    // onChange={handleChangeForm3}
                                                    placeholder=''
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Case Extension */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <img src={CASE_HISTORY} alt="icon" className='img-fluid form_icons' />
                                Case Extension
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row case_history">
                                    <div className="col-md-6">
                                        <div className='form-group' id='amtRequested'>
                                            <label className="form-label">Amount Requested <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                placeholder=''
                                                name="amtRequested"
                                                value={formatter(form3.amtRequested)}
                                                // onChange={handleChangeForm3}
                                                className='form-control'
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-group' id='revMonthlyRepay'>
                                            <label className="form-label">Reviewed Monthly Repayment Amount <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                placeholder=''
                                                name="revMonthlyRepay"
                                                value={formatter(form3.revMonthlyRepay)}
                                                // onChange={handleChangeForm3}
                                                className='form-control'
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='form-group' id='srcMonthlyRepay'>
                                            <label className="form-label">Source of Monthly Repayment <span className='required'>*</span></label>
                                            <textarea
                                                cols="30"
                                                rows="5"
                                                placeholder=''
                                                name="srcMonthlyRepay"
                                                value={form3.srcMonthlyRepay}
                                                // onChange={handleChangeForm3}
                                                className='form-control'
                                                disabled
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export function Additional_Information(props) {

    const { id, doc } = useParams();

    const [form4, setForm4] = useState({
        prevTotalIncome: "",
        currTotalIncome: "",
        prevTotalExpense: "",
        currTotalExpense: "",
        prevTotalAssets: "",
        currTotalAssets: "",
        newMajorExpense: "",
        reasonForAssetsChange: "",
        srcMonthlyRepay: "",
    })

    const getDData = async () => {
        try {
            const response = await getAllRenewalAdmin({ id: props.doc })
            console.log("RRR", response);
            if (response.success) {

                setForm4({
                    ...form4,
                    prevTotalIncome: response.message.prevTotalIncome,
                    currTotalIncome: response.message.currTotalIncome,
                    prevTotalExpense: response.message.prevTotalExpense,
                    currTotalExpense: response.message.currTotalExpense,
                    prevTotalAssets: response.message.prevTotalAssets,
                    currTotalAssets: response.message.currTotalAssets,
                    newMajorExpense: response.message.newMajorExpense,
                    reasonForAssetsChange: response.message.reasonForAssetsChange,
                    srcMonthlyRepay: response.message.srcMonthlyRepay,
                })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
    }, [])


    return (
        <>
            <div className="">

                <div className="preview_heading">
                    <h5>Additional Information</h5>
                </div>

                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <img src={INCOME_DETAIL} alt="icon" className='img-fluid form_icons' />
                                Income and Expense Details
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className='row'>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className='form-group border_input' id='prevTotalIncome'>
                                            <label className="form-label">Previous Total Income (Rs.) <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder=''
                                                name="prevTotalIncome"
                                                value={formatter(form4.prevTotalIncome)}
                                                // onChange={handleChangeForm4}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className='form-group border_input' id='currTotalIncome'>
                                            <label className="form-label">Current Total Income (Rs.) <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder=''
                                                name="currTotalIncome"
                                                value={formatter(form4.currTotalIncome)}
                                                // onChange={handleChangeForm4}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>

                                    {
                                        form4.reasonForIncomeChange &&
                                        <div className="col-md-12">
                                            <div className='form-group mb-4'>
                                                <label className="form-label">If the current total income is different from previous total income, then what’s the reason for that change?</label>
                                                <textarea
                                                    cols="30"
                                                    rows="5"
                                                    className='form-control textarea'
                                                    placeholder=''
                                                    name="reasonForIncomeChange"
                                                    value={form4.reasonForIncomeChange}
                                                    // onChange={handleChangeForm4}
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>
                                    }

                                    <div className="col-md-6">
                                        <FileViewerAdminRenewal
                                            id={doc}
                                            url={RenewalUrl}
                                            keyName={"salarySlip"}
                                            text="Latest Salary Slip (all family members who are earning)"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FileViewerAdminRenewal
                                            id={doc}
                                            url={RenewalUrl}
                                            keyName={"bankStmt"}
                                            text="Last 3 Months Bank Statements"
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className='form-group border_input' id='prevTotalExpense'>
                                            <label className="form-label">Previous Total Expenses(Rs.) <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder=''
                                                name="prevTotalExpense"
                                                value={formatter(form4.prevTotalExpense)}
                                                // onChange={handleChangeForm4}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className='form-group border_input' id='currTotalExpense'>
                                            <label className="form-label">Current Total Expenses (Rs.) <span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder=''
                                                name="currTotalExpense"
                                                value={formatter(form4.currTotalExpense)}
                                                // onChange={handleChangeForm4}
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>

                                    {
                                        form4.newMajorExpense &&
                                        <div className="col-md-12">
                                            <div className='form-group mb-4'>
                                                <label className="form-label">Any new major expense added? If yes, then what are the source and the amount being incurred?</label>
                                                <textarea
                                                    cols="30"
                                                    rows="5"
                                                    className='form-control textarea'
                                                    placeholder=''
                                                    name="newMajorExpense"
                                                    value={form4.newMajorExpense}
                                                    // onChange={handleChangeForm4}
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>
                                    }

                                    {
                                        form4.prevTotalAssets &&
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Previous value of total assets of the family (Rs.)</label>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    placeholder=''
                                                    name="prevTotalAssets"
                                                    value={formatter(form4.prevTotalAssets)}
                                                    // onChange={handleChangeForm4}
                                                    disabled
                                                    required
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        form4.currTotalAssets &&
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className='form-group border_input'>
                                                <label className="form-label">Current value of total assets of the family (Rs.)</label>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    placeholder=''
                                                    name="currTotalAssets"
                                                    value={formatter(form4.currTotalAssets)}
                                                    // onChange={handleChangeForm4}
                                                    disabled
                                                    required
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        form4.reasonForAssetsChange &&
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className="form-label">If the current value of total assets is different from the previous value, then please state the reason for that change.</label>
                                                <textarea
                                                    cols="30"
                                                    rows="5"
                                                    className='form-control textarea'
                                                    placeholder=''
                                                    name="reasonForAssetsChange"
                                                    value={form4.reasonForAssetsChange}
                                                    // onChange={handleChangeForm4}
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default Preview
