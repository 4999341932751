import { Helmet } from 'react-helmet'
import NavSidebar from '../Sidebar'
import Navbar from '../Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import { UniversityProfileView } from './university_profile_view';



function UniversityProfile() {


    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    return (
        <>
            <Helmet>
                <title>University Profile</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <UniversityProfileView />

                </div>
            </div>
        </>
    )
}


export default UniversityProfile