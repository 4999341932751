import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import SignatureCanvas from 'react-signature-canvas'
import UserModal from '../../components/Modals/Modal';
import Input from '../../components/Form/Input';
import SelectNew from '../../components/Form/Select_New';

// import SelectNew from "../Form/Select_New";
import { useEffect } from 'react';
import { DisbursementAnalysis, GetUnisList, repaymentAnalysis } from '../../ContextAPI/APIs/api';
import { useToast } from '../../ContextAPI/Components/toast';
import { CSVLink } from "react-csv";
import Pagination from '../../components/Pagination';
import { useAuth } from '../../ContextAPI/Components/auth';

import formatter from "../../helpers/number_formatter"
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
const headers = [
    { label: "S.No", key: "SNO", },
    { label: "Name", key: "name", },
    { label: "University", key: "institute", },
    { label: "Program", key: "program", },
    { label: "Family Income Per month (Rs.)", key: "familyIncome", },
    { label: "Per Semester/Year Fee (Rs.)", key: "Semfee / Yearfee", },
    { label: "Outstanding fee (Rs.)", key: "feeOutstanding", },
    { label: "Amount of Fee Recommended", key: "recomendationRupees", },
    { label: "% of Fee Recommended", key: "recomendationPercent", },
    { label: "Repayment Amount", key: "recomendationRepayment", },
    // { label: "Available_Limit",  key: "Available Limit", },
];


function RepayAnalysis() {

    const [uni, setUni] = useState([])

    const { user } = useAuth();

    const { alert } = useToast();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();




    const [totalCount, setTotalCount] = useState(4)


    function FormatDataIntoCSV() {
        const formattedData = data.map((item, i) => ({
            SNO: i + 1,
            ...item,
            institute: item.institute ? item.institute.fullName : null,
            program: item.program ? item.program.name : null,
            Available_Limit: null
        }));

        return formattedData
    }




    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(skip)
    }

    const [Qualification, setQualification] = useState({
        university: "",
    })

    const SELECTUNI = (e) => {
        console.log(e)
        try {
            setQualification({ ...Qualification, university: e })
        }
        catch (e) {
            console.log(e);
        }
    }

    const getAllUni = async () => {
        try {
            const response = await GetUnisList()

            if (response?.success) {
                console.log("UNISS", response.message);
                setUni(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const currentYear = new Date().getFullYear();

    const yearOptions = [];
    for (let year = currentYear; year >= 2010; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }


    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    // Handler function to handle option change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        // Do something with the selected year
        setYear(event.target.value)

        console.log("Selected year:", selectedYear);
    };

    const handleMonthChange = (event) => {
        // Do something with the selected year
        setMonth(event.target.value)

    };



    const [xyz, steXyz] = useState(0)

    const [data, setData] = useState({})



    // Example usage:


    const search = async (skip) => {
        try {





            if (!month || month == "" || month == null || typeof month == undefined) {
                return alert("Please Select a Valid Month", false)
            }
            if (!year || year == "" || year == null || typeof year == undefined) {
                return alert("Please Select a Valid Year", false)
            }


            const val = `${month} ${year}`


            console.log("UNIII", val);


            const response = await repaymentAnalysis({ year: year, month: month })
            console.log("response ", response);

            console.log("hello 123 responseeee", response);

            // return

            if (response.success && response.message) {
                setData(response.message)

                // if (response.message.projected == 0) {
                //     setData({ ...data, projected: 1 })
                // }
                // else {
                //     setData({ ...data, projected: response.message.projected })
                // }


                // if (response.message.projectedStd == 0) {
                //     setData({ ...data, projectedStd: 1 })
                // }
                // else {
                //     setData({ ...data, projectedStd: response.message.projectedStd })
                // }

                // setData({ ...data, actual: response.message.actual })
                // setData({ ...data, QH: response.message.QH })
                // setData({ ...data, actualStd: response.message.actualStd })

                steXyz(1)
                setShow(false)
            }
            if (!response.success) {
                alert(response.message, response.success)
                steXyz(0)
                setData({})
            }

        } catch (e) {
            console.log(e);
        }
    };



    useEffect(() => {
        getAllUni()
    }, [])


    return (
        <>
            <Helmet>
                <title>Repayments Analysis </title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section'>

                        <div className='d-flex jc-between ai-center mb-4 flex_heading_button'>

                            <div className='d-flex ai-center'>
                                <Link to="/reporting">
                                    <IoArrowBack className='back_arrow_icon me-3' />
                                </Link>
                                <h2 className='mb-0'>Repayments Analysis</h2>
                            </div>

                            <div className='d-flex jc-end buttons'>

                                {
                                    (data.length > 0 && (user?.role == "admin" || user?.role == "operations")) &&
                                    (
                                        <CSVLink filename={"Fresh.csv"} data={FormatDataIntoCSV()} headers={headers} className='link'>
                                            <button className='btn save_btn'> Download </button>
                                        </CSVLink>
                                    )
                                }

                                &nbsp;

                                <UserModal btnText={"Filter"} heading="Filter" id={"filter"} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Month <span className='required'>*</span></label>
                                                <select className='form-select' value={month} id="yearSelect" onChange={handleMonthChange}>
                                                    <option selected disabled value="">Select Month</option>
                                                    <option value="January">January</option>
                                                    <option value="February">February</option>
                                                    <option value="March">March</option>
                                                    <option value="April">April</option>
                                                    <option value="May">May</option>
                                                    <option value="June">June</option>
                                                    <option value="July">July</option>
                                                    <option value="August">August</option>
                                                    <option value="September">September</option>
                                                    <option value="October">October</option>
                                                    <option value="November">November</option>
                                                    <option value="December">December</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Year <span className='required'>*</span></label>
                                                <select value={year} className='form-select' id="yearSelect" onChange={handleYearChange}>
                                                    <option selected disabled value="">Select Year</option>
                                                    {yearOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='d-flex jc-end'>
                                            <button onClick={(e) => search(pageSkip)} className='btn save_btn'>Save</button>
                                        </div>
                                    </div>
                                </UserModal>
                            </div>

                        </div>

                        <div className="card">
                            {
                                xyz > 0 ?
                                    <>
                                        <Table head={["Particulars", "Projected", "Actual", "Variance/Difference", "%"]}>
                                            <tr>
                                                <td><Text text="Monthly Repayments" /></td>
                                                <td><Text text={formatter(data.projected)} /></td>
                                                <td><Text text={formatter(data.actual)} /></td>
                                                <td><Text text={formatter(data.vd1)} /></td>
                                                <td><Text text={`${data.p1} %`} /></td>
                                            </tr>
                                            <tr>
                                                <td><Text text="monthly Repayments Received as per Projection" /></td>
                                                <td><Text text={""} /></td>
                                                <td><Text text={formatter(data.QH)} /></td>
                                                <td><Text text={formatter(data.vd2)} /></td>
                                                <td><Text text={`${data.p2} %`} /></td>
                                            </tr>
                                            <tr>
                                                <td><Text text={`Total no. of Students to make Repayments in ${month} ${year}`} /></td>
                                                <td><Text text={data.projectedStd} /></td>
                                                <td><Text text={data.actualStd} /></td>
                                                <td><Text text={data.vd3} /></td>
                                                <td><Text text={`${data.p3} %`} /></td>
                                            </tr>
                                        </Table>
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }
                        </div>

                    </section>

                </div>
            </div>

        </>
    )
}

export default RepayAnalysis
