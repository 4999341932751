import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import TooltipComponent from '../../components/tooltip';
import Table from '../../components/Table/Table';
import IconText from '../../components/Table/IconText';
import Text from '../../components/Table/Text';
import { CgProfile } from 'react-icons/cg';
import Input from '../../components/Form/Input';

import { BiArrowBack } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';

function DisbustmentProcess() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const disbustent_detail = [
        {
            std_name: "Ahmed",
            uni_name: "SSUET",
            first_disbust_date: "10/09/23",
            last_disbust_date: "10/08/23",
            last_disbust_amt: 50000,
            semester_year: 4,
            roll_no: 4545,
            program: "BS",
        },
        {
            std_name: "Asad",
            uni_name: "KU",
            first_disbust_date: "10/09/23",
            last_disbust_date: "10/08/23",
            last_disbust_amt: 50000,
            semester_year: 4,
            roll_no: 4545,
            program: "SE",
        },
    ]

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [show, setShow] = useState("disbustment");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "disbustment");
        setShow((hash.split("#"))[1] || "disbustment")
    }, [hash])

    return (
        <>
            <Helmet>
                <title>Disbustment Process</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='student_profile_manag_section'>

                        {
                            show == "disbustment" &&
                            <div className="card mb-4 disbustment_process_card">
                                <h2 className='mb-3'>Disbustment Process</h2>
                                <Table head={["Student Name", "University Name", "First Disbustment Date", "Last Disbustment Date", "Last Disbustment Amount", "Semester/Year", "Reg No. / Enrollment No.", "Program"]}>

                                    {
                                        disbustent_detail.map((data, i) => {
                                            return <tr key={i}>
                                                <td>
                                                    <div className='d-flex ai-center'>
                                                        <TooltipComponent className="" placement="top" tooltipText="View Profile">
                                                            <div><CgProfile className='icon' onClick={() => navigate(`#${"std_profile"}`)} /></div>
                                                        </TooltipComponent>
                                                        <IconText text={data.std_name} />
                                                    </div>
                                                </td>
                                                <td><Text text={data.uni_name} /></td>
                                                <td><Text text={data.first_disbust_date} /></td>
                                                <td><Text text={data.last_disbust_date} /></td>
                                                <td><Text text={data.last_disbust_amt} /></td>
                                                <td><Text text={data.semester_year} /></td>
                                                <td><Text text={data.roll_no} /></td>
                                                <td><Text text={data.program} /></td>
                                            </tr>
                                        })
                                    }

                                </Table>

                                <Pagination itemsPerPage={4} />

                            </div>
                        }

                        {
                            show == "std_profile" &&
                            <div className="card std_profile">
                                <div className='d-flex jc-between ai-center mb-4'>
                                    <h2 className='mb-0'>Student Profile Management</h2>
                                    <BiArrowBack className='back_arrow_icon' onClick={() => navigate(`#${"disbustment"}`)} />
                                </div>

                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Student Name" inputType="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Email ID" inputType="email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="University Name" inputType="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Campus" inputType="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Reg No. / Enrollment No." inputType="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="CNIC" inputType="number" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Phone" inputType="tel" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <Input label="Family Contact" inputType="tel" />
                                            </div>
                                        </div>

                                    </div>
                                </form>

                            </div>
                        }
                    </section>

                </div>
            </div>
        </>
    )
}

export default DisbustmentProcess
