import React from 'react'
import { useState, useEffect } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

function Step_1() {

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);


    return (
        <>
            <section className='section_four'>
                <div className="container">
                    <div className="card">
                        <h2>Instructions</h2>

                        <div className='mb-2'>
                            <ul>
                                <li>The applicant must visit Ihsan Trust website before interview and must
                                    LIKE (Follow See First) Ihsan Trust’s social media channels i.e. Ihsan
                                    Trust Facebook page & Instagram page. <br />
                                    <a href="http://www.facebook.com/ihsantrust" target='_blank' className='social_icons'>
                                        <FaFacebook className='me-2' />
                                        <p>http://www.facebook.com/ihsantrust</p>
                                    </a>
                                    <a href="http://www.instagram.com/ihsantrust__" target='_blank' className='social_icons'>
                                        <FaInstagram className='me-2' />
                                        <p>http://www.instagram.com/ihsantrust__</p>
                                    </a>
                                </li>
                                <li>Incomplete Forms or Forms without supporting documents shall not be considered.</li>
                                <li>Attach all documents.</li>
                                <li>Do consult with parent(s) / guardian(s) for financial data accuracy & reliability.</li>
                                <li>For the information not present/relevant in the application form write in capital letter <span>N/A.</span></li>
                                <li>Do not Provide False / unclear / incomplete information.</li>
                                <li>Do not Provide expired CNIC of Self, parents / guardian and siblings.</li>
                            </ul>
                        </div>

                        <div className='d-flex jc-end'>
                            <button className='btn next_btn' onClick={() => navigate(`#${"step_2"}`)}>Next</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step_1
