import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide, SwiperSlideProps } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import Slide1 from "../../assets/slide1.JPG"
import Slide2 from "../../assets/slide2.JPG"
import Slide3 from "../../assets/slide3.JPG"
import Slide4 from "../../assets/slide4.JPG"
import { Autoplay, Navigation } from 'swiper/modules';

import VIDEO from "../../assets/Application Form Procedure.mp4"
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

import ReactPlayer from 'react-player'

function DashboardSlider(props) {
    const [initialSlide, setInitialSlide] = useState(0);


    const swiperRef = useRef(null);

    // Set the initial slide based on props.currentSlide
    // React.useEffect(() => {
    // });
    useEffect(() => {
        if (swiperRef.current && props.currentSlide !== undefined) {
            console.log(swiperRef.current);

            // swiperRef.current.slideTo(props.currentSlide); // Activate the desired slide
        }
    }, [props.currentSlide]);


    // useEffect(() => {
    //     setInitialSlide(props.currentSlide);
    //     console.log("props.currentSlide", props.currentSlide);
    // }, [props.currentSlide])

    return (
        <>
            {/* <Swiper
                ref={swiperRef}
                navigation={false}
                autoHeight={true}
                autoplay={{
                    delay: 100000,
                    disableOnInteraction: false
                }}
                loop={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide className="" style={{ height: "470px", borderRadius: "0.875rem" }}>
                    <div className="d-flex jc-center">
                        <img src={Slide1} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide className="" style={{ height: "470px", borderRadius: "0.875rem" }}>
                    <div className="d-flex jc-center">
                        <img src={Slide2} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide className="" style={{ height: "470px", borderRadius: "0.875rem" }}>
                    <div className="d-flex jc-center">
                        <img src={Slide3} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide className="" style={{ height: "470px", borderRadius: "0.875rem" }}>
                    <div className="d-flex jc-center">
                        <img src={Slide4} alt="" />
                    </div>
                </SwiperSlide>
            </Swiper> */}


            {/* <div style={{
                position: "relative", width: "100%", height: "0", paddingTop: "76.9231%",
                paddingBottom: "0", boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)", marginTop: "0", marginBottom: "0.9em", overflow: "hidden",
                borderRadius: "8px", willChange: "transform"
            }}>
                <iframe loading="lazy" style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: "none", padding: 0, margin: 0, }}
                    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF_80L9Be0&#x2F;cIUEOiVAhFzvG7lKtsCGvg&#x2F;watch?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
                </iframe>
            </div > */}


            <Player autoPlay={true} loop={true}>
                <source src={VIDEO} type='video/mp4' className="profile_video" />
            </Player>
        </>


    );
}

export default DashboardSlider;
