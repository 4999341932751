import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import SocialWidget from '../components/SocialWidget'

import LOGO from "../assets/logo.png"

function EmailVerification() {

    const [data, setData] = useState({
        otp: "",
    })

    const handleOTP = (e) => {
        setData({ ...data, otp: e.target.value })
    }


    return (
        <>
            <Helmet>
                <title>Ihsan Trust | Email Verification</title>
            </Helmet>

            <section className='authentication_section email_verification_page d-flex ai-center'>
                <div className="container">

                    <SocialWidget />

                    <div className="card">
                        <img src={LOGO} alt="logo" className='img-fluid' />
                        <div className="sign_form">
                            <form >
                                <h3>Verify Your Email</h3>
                                <p className='desc'>An OTP has been sent to your email please check and enter your OTP.</p>
                                <div className="form-group">
                                    <input onChange={(e) => handleOTP(e)} type="email" className="form-control" placeholder="Enter your OTP" />
                                </div>
                                <div className="">
                                    <button type='submit' className="btn sign_btn mb-4">Verify</button>
                                </div>
                                <div className="">
                                    <button type='submit' className="btn signup_btn">Resend OTP</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default EmailVerification
