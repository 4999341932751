import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

function UserModal(props) {

    return (
        <>
            {
                typeof props.setUpdate != "undefined" ?
                    <button type='button' className={`btn ${props.customClasses}`} onClick={() => { props.handleShow(props.id); props.setUpdate(props.dataToBeSet) }}>
                        {typeof props.icon !== "undefined" ? props.icon : props.btnText}
                    </button>
                    :
                    <button type='button' className={`btn ${props.customClasses}`} onClick={() => props.handleShow(props.id)}>
                        {typeof props.icon !== "undefined" ? props.icon : props.btnText}
                    </button>
            }

            {
                props.modalItem == props.id &&
                <Modal
                    className='modal'
                    {...props}
                    size={props.modalSize}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={props.show}
                    onHide={props.handleClose}
                >
                    <Modal.Header closeButton>
                        <h4 className='mb-0'>{props.heading}</h4>
                    </Modal.Header>

                    <Modal.Body className={`modal_body ${props.actionModalClass}`}>
                        {props.children}
                    </Modal.Body>

                    {/* <Modal.Footer>
                    </Modal.Footer> */}

                </Modal>
            }
        </>
    );
}

export default UserModal