import React, { useState, useEffect } from 'react'
import Select from '../Form/Select_New';
import { FaPlus } from 'react-icons/fa';
import Step_7 from '../ApplicationForm/Step_7';
import Step_8 from '../ApplicationForm/Step_8';
import ImageUploadInput from '../Modals/ImageUploadInput';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../ContextAPI/Components/toast';
import { User_Profile_Pic } from '../Profile/upload_profilePic';

import { academicCertficatesUrl, admissionLetterUrl, CNICUrl, DeathCertificateUrl, DomicileUrl, FamilyUrl, IncomeCertificateUrl, passimageUrl, resumeUrl, vouchersUrl } from '../../helpers/data';

import { getForm1, getForm2, getForm3, getUNI, GetUnisProfileForStudent, SubmissionForm1, SubmissionForm2, SubmissionForm3 } from '../../ContextAPI/APIs/api';

import PERSONAL_INFO from "../../assets/form-icons/personal-information-preview.png"
import FAMILY_INFO from "../../assets/form-icons/financial-appraisal-preview.png"
import ACADEMIC_INFO from "../../assets/form-icons/academic-preview.png"
import FINANCIAL_DETAIL from "../../assets/form-icons/financial-details-preview.png"
import FINANCIAL_APPR from "../../assets/form-icons/financial-appraisal-preview.png"
import INCOME_DETAIL from "../../assets/form-icons/income-detail-preview.png"
import EMP_DETAIL from "../../assets/form-icons/employment-detail-preview.png"
import EXPENDIRURE from "../../assets/form-icons/expenditure-preview.png"
import PROPERTY from "../../assets/form-icons/property-preview.png"


import Demographics_InformationViewOnly from './Demographics_InformationViewOnly';
import Academic_BackgroundViewOnly from './Academic_BackgroundViewOnly';
import Financial_InformationViewOnly from './Financial_InformationViewOnly';
import Additional_InformationViewOnly from './Additional_InformationViewOnly';
import SOPViewOnly from './SOPViewOnly';

function Preview() {

    const navigate = useNavigate();
    const { tabData } = useAuth()
    if (tabData.form1 && tabData.form2 && tabData.form3 && tabData.form4 && tabData.form5) {

    }
    return (
        <>
            {
                (tabData.form1 && tabData.form2 && tabData.form3 && tabData.form4 && tabData.form5) ?
                    <>
                        <Demographics_InformationViewOnly />
                        <Academic_BackgroundViewOnly />
                        <Financial_InformationViewOnly />
                        <Additional_InformationViewOnly />
                        <SOPViewOnly />

                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"sop"}`)}>Previous</button>
                            <button className='btn next_btn' onClick={() => navigate(`#${"final"}`)}>Save and Next</button>
                        </div>
                    </>
                    :
                    <>
                        <div><h1></h1></div>
                    </>
            }

        </>
    )
}




export default Preview
