import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from "../ContextAPI/Components/auth"

import LOGO from "../assets/logo.png"

import profile from "../assets/profile.svg"

import MobSidebar from './MobSidebar'
import { PiGraduationCap, PiSignOutBold } from 'react-icons/pi'
import { useCookies } from 'react-cookie'


import { HiOutlineUserCircle } from 'react-icons/hi2';
import { MdOutlineLock } from 'react-icons/md';
import { profilePicUrl } from '../helpers/data';
import { User_Profile_Pic } from './Profile/upload_profilePic'
import { useMediaQuery } from 'react-responsive'

import { IoArrowBackCircleOutline } from "react-icons/io5";


function Navbar() {

    const { user, Logout } = useAuth()

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("");

    useEffect(() => {
        setShowForm((hash.split("#"))[1] || "instructions");
    }, [hash]);

    const isMobile = useMediaQuery({ query: '(max-width: 1250px)' });

    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-light">

                {/* <div>
                    <IoArrowBackCircleOutline className='back_arrow_icon' />
                </div> */}

                <div className={`${isMobile ? "d-none" : "d-block"}`}>
                    {
                        (showForm == "demographics_information") || (showForm == "academic_background") || (showForm == "financial_information") || (showForm == "additional_information") || (showForm == "sop") || (showForm == "preview") || (showForm == "final") || (showForm == "renewal_details") ?
                            <Link to="/dashboard" className='link'>
                                <img src={LOGO} alt="logo" className='img-fluid' />
                            </Link>
                            :
                            ""
                    }
                </div>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <div class="nav-item navbar-brand nav_sidebar_toggle" >
                        <MobSidebar />
                    </div>

                    <div class="nav-item d-flex form-inline">

                        {/* <DarkMode /> */}

                        <div className={`${isMobile ? "d-none" : "d-block"}`}>
                            {
                                (showForm == "demographics_information") || (showForm == "academic_background") || (showForm == "financial_information") || (showForm == "additional_information") || (showForm == "sop") || (showForm == "preview") || (showForm == "final") || (showForm == "renewal_details") ?
                                    <div className='me-2'>
                                        <Link to="/dashboard" className='link'>
                                            <button className='btn goto_dahboard_btn'>Dashboard</button>
                                        </Link>
                                    </div>
                                    :
                                    ""
                            }
                        </div>

                        <div className="nav-item __account">

                            <div className="account d-flex ai-center jc-end">
                                <div className='img'>
                                    <User_Profile_Pic classes={"sm mx-auto mb-0"} />
                                </div>
                                {/* <div className='ml-3'>
                                    <h6 className='mb-0 limit-text xs'>{user?.fullName || "Username"}</h6>
                                </div> */}
                            </div>


                            <div className='profile_box'>

                                <div className="user_info text-center">
                                    <User_Profile_Pic classes={"md mx-auto mb-2"} />
                                    <h6 className='mb-0 limit-text sm'>{user?.fullName || "Username"}</h6>
                                </div>

                                {
                                    user?.role == "university" &&
                                    <div className='user_profile_info'>
                                        <div className='mb-2'>
                                            <Link to="/university-profile" className="link">
                                                <HiOutlineUserCircle className="logOut_icon" />
                                                Profile
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/university-profile#password" className="link">
                                                <MdOutlineLock className="logOut_icon" />
                                                Password
                                            </Link>
                                        </div>
                                    </div>
                                }


                                {
                                    user?.role != "university" &&
                                    <div className='user_profile_info'>
                                        <div className='mb-2'>
                                            {
                                                user.role === "student" ?

                                                    <Link to="/std-profile" className="link">
                                                        <HiOutlineUserCircle className="logOut_icon" />
                                                        Profile
                                                    </Link>
                                                    :
                                                    <Link to="/profile" className="link">
                                                        <HiOutlineUserCircle className="logOut_icon" />
                                                        Profile
                                                    </Link>
                                            }
                                        </div>
                                        {
                                            user?.role == "student" &&
                                            <div className='mb-2'>
                                                <Link to="/std-profile#education-details" className="link">
                                                    <PiGraduationCap className="logOut_icon" />
                                                    Education
                                                </Link>
                                            </div>
                                        }
                                        <div>
                                            {
                                                user.role === "student" ?
                                                    <Link to="/std-profile#password" className="link">
                                                        <MdOutlineLock className="logOut_icon" />
                                                        Password
                                                    </Link>
                                                    :
                                                    <Link to="/profile#password" className="link">
                                                        <MdOutlineLock className="logOut_icon" />
                                                        Password
                                                    </Link>
                                            }
                                        </div>
                                    </div>
                                }



                                <div className='logout_box'>
                                    <Link to="/sign-in" className="link" onClick={() => Logout()}>
                                        <PiSignOutBold className="logOut_icon" />
                                        Log Out
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </nav>

        </>
    )
}


export default Navbar