import React, { useEffect, useState } from 'react'
import CasesHistory from './CasesHistory'
import InterviewSheet from './InterviewSheet'
import RecomendationForm from './RecommendationForm'
import ApprovalForm from './ApprovalForm'
import Tabs from '../../components/tabs'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar'
import { getApprovalSheetForSTD, getFreshCaseDetail, getInterviewSheetForSTD, isMOUExpired } from '../../ContextAPI/APIs/api'
import { useAuth } from '../../ContextAPI/Components/auth'
import InterviewSheetPreview from './InterviewSheetPreview'
import { IoArrowBack } from 'react-icons/io5'

function FreshCaseDetails() {

    const { id, doc } = useParams();
    const { user } = useAuth();
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const [tabsD, setTabs] = useState([
        {
            tabName: "Case Detail",
            pathName: "#cases-detail"
        },
    ])


    const Mob_tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "Education",
            pathName: "#education-details"
        },
        {
            tabName: "Password",
            pathName: "#password"
        }
    ]

    const navigate = useNavigate();
    const { hash } = useLocation();
    const [show, setShow] = useState("cases-detail");


    useEffect(() => {
        setShow(hash)
    }, [hash])



    const [data, setData] = useState({
    })
    const [app, setApp] = useState({})

    const getData1 = async () => {
        try {
            const response = await getFreshCaseDetail({ _id: doc })
            console.log("REEESS", response.message);
            if (response.success) {
                setData({
                    ...data,
                    _id: response?.message?._id,
                    name: response?.message?.userId?.fullName,
                    email: response?.message?.userId?.email,
                    cnic: response?.message?.form1?.cnic,
                    cell: response?.message?.userId?.phone,
                    fCell: response?.message?.form1?.familyDetails[0]?.cellNo,
                    mCell: response?.message?.form1?.familyDetails[1]?.cellNo,
                    uni: response?.message?.university?.fullName,
                    program: response?.message?.program?.name,
                    semester: response?.message?.form2?.totalSem,
                    appSem: response?.message?.form2?.currentSem,
                    type: response?.message?.form2?.ExamStructure
                })
                setApp(response.message)
                checkMouExpiry(response?.message?.university?._id)


                if (response?.message?.status == "interview conducted" || response?.message?.status == "interview" || response?.message?.status == "approved" || response?.message?.status == "disburse") {
                    
                    // setTabs([...tabsD, {
                    //     tabName: "Interview Sheet",
                    //     pathName: "#interview-sheet"
                    // }])

                    const check = tabsD.some(obj => obj.tabName === "Interview Sheet");
                    // const check = false;
                    if (!check) {
                        const p = {
                            tabName: "Interview Sheet",
                            pathName: "#interview-sheet"
                        }

                        getInterviewSheet(p)
                    }
                    else {
                        getInterviewSheet({})
                    }
                }
            }



            console.log("DATA", response.message);
        }
        catch (e) {
            console.log(e);
        }
    }


    const [isMou, setMou] = useState(false)
    const [mouTXT, setMouTXT] = useState("")

    const checkMouExpiry = async (uni) => {
        try {
            const payload = { id: uni }
            const response = await isMOUExpired(payload)
            console.log("RESPONSEMOU", response.message);
            if (response.success) {
                setMou(true)
                setMouTXT(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)

    
    const getInterviewSheet = async (p) => {
        try {
            console.log("PAYLOAD0007", p);
            let a = []

            if (p && p.tabName) {
                a.push(p)
                a.push({
                    tabName: "Interview Sheet Preview",
                    pathName: "#interview-sheet-preview"
                })

                a.push({
                    tabName: "Recommendation Form",
                    pathName: "#recomendation-form"
                })

            }

            const response = await getInterviewSheetForSTD({
                id: id,
                type: "fresh",
                documentIdFresh: doc
            })

            const response2 = await getApprovalSheetForSTD({
                id: id,
                type: "fresh",
                documentIdFresh: doc
            })
            const getApp = await getFreshCaseDetail({ _id: doc })

            console.log("response||||||||", response2);

            // if (response.success && response.message !== null && getApp?.message?.status ==="interview conducted" && response?.message?.recomendationRupees !== "" && response?.message?.recomendationRepayment !== "" && response?.message?.recomendationPercent !=="") {
            if (response.success && response.message !== null && (getApp?.message?.status ==="interview conducted" || getApp?.message?.status ==="approved" || getApp?.message?.status ==="disburse") && response?.message?.recomendationRupees !== "" && response?.message?.recomendationRepayment !== "" && response?.message?.recomendationPercent !=="") {
                console.log("heffffff");

                // const check2 = tabsD.some(obj => obj.tabName === "Recommendation Form");
                // if (!check2) {
                //     a.push({
                //         tabName: "Recommendation Form",
                //         pathName: "#recomendation-form"
                //     })
                // }
                const check3 = tabsD.some(obj => obj.tabName === "Approval Form");
                if (!check3) {
                    a.push({
                        tabName: "Approval Form",
                        pathName: "#approval-form"
                    })
                }
            }

            setTabs([...tabsD, ...a])
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getData1()

        // getInterviewSheet()
    }, [])


    // {
    //     user.role === "university" ?
    //         <Link to={`/uni-interview-sheet/${id}/${data._id}`} className='link'>
    //             <button className="btn save_btn">Interview Sheet</button>
    //         </Link>
    //         :
    //         <Link to={`/interview-sheet/${id}/${data._id}`} className='link'>
    //             <button className="btn save_btn">Interview Sheet</button>
    //         </Link>
    // }







    return (
        <>

            <Helmet>
                <title>Cases History</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='cases_details_section'>

                        <div className='d-flex ai-center mb-4'>
                            {/* <Link to="/cases/fresh-cases">
                                <IoArrowBack className='back_arrow_icon me-3' />
                            </Link> */}
                            <h2 className='mb-0'>Fresh Case Detail</h2>
                        </div>

                        <div className='card mb-4'>

                            <Tabs tabLinks={tabsD} show={show} setShow={setShow} />

                            {
                                show == "#cases-detail" &&
                                <CasesHistory getData1={getData1} />
                            }
                            {
                                show == "#interview-sheet" &&
                                <InterviewSheet getData1={getData1} setShow={setShow} />
                            }
                            {
                                show == "#interview-sheet-preview" &&
                                <InterviewSheetPreview setShow={setShow} />
                            }
                            {
                                show == "#recomendation-form" &&
                                <RecomendationForm setShow={setShow} />
                            }
                            {
                                show == "#approval-form" &&
                                <ApprovalForm setShow={setShow} />
                            }

                        </div>


                    </section>

                </div>
            </div>





        </>
    )
}

export default FreshCaseDetails
