import Navbar from '../components/Navbar'
import NavSidebar from '../components/Sidebar'
import { Helmet } from 'react-helmet';
import { useProSidebar } from 'react-pro-sidebar';
import { Admin_Index } from '../components/Profile/admin_index';
import { Student_Index } from '../components/Profile/student_index';
import UniversityProfile from '../components/Profile/university_index';
import { useAuth } from '../ContextAPI/Components/auth';
import { PageNotFound } from '../pages/PageNotFound';




function Profile() {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const { user } = useAuth()
    console.log("UUUUUUUUUU", user);


    return (

        <>
            {
                user !== null ?
                    <>
                        <Helmet>
                            <title>Profile</title>
                        </Helmet>
                        <div className="d-flex">
                            <div className="sidebar_div">
                                <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                            </div>

                            <div className="page_div">
                                <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                                <section className='profile_section'>
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                (user.role == "admin" || user?.role == "viewer" || user.role == "operations" || user.role == "finance" || user.role == "repayment") ?
                                                    <Admin_Index />
                                                    :
                                                    user.role === "student" ?
                                                        <Student_Index />
                                                        :
                                                        <UniversityProfile />
                                            }
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <PageNotFound />
                    </>
            }
        </>

    )
}

export default Profile
