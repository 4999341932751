// import 'bootstrap/dist/css/bootstrap.css';

import './bootstrap-css/bootstrap.css';
import './App.css';
import './Global.css'
import './tracking-timeline.scss'
import { ConfirmBox } from './ContextAPI/Components/confirm';
import { ToastBox } from './ContextAPI/Components/toast';
import MyRoutes from './routing/routes';
import { useAuth } from './ContextAPI/Components/auth';
import Loader from './components/Loader';
function App() {

  const { loader } = useAuth()
  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  const storedTheme = localStorage.getItem("theme");
  const prefersDark = (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches);
  const defaultDark = (storedTheme === "dark" || (storedTheme === null && prefersDark));

  if (defaultDark) setDark();




  return (
    <>
      {
        loader && <Loader />
      }
      <ToastBox />

      <ConfirmBox />

      <MyRoutes />

    </>
  );
}


export default App;