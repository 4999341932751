import React from 'react'
import ReactApexChart from "react-apexcharts"
import { useMediaQuery } from 'react-responsive';

function PieChart(props) {

    const data = {
        series: props.uniCount,
        options: {
            chart: {
                type: 'pie',
            },
            tooltip: {
                enabled: true,
                style: {
                    background: '#ffffff', // Add your desired background color here
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    console.log("series => ", series);
                    console.log("seriesIndex => ", seriesIndex);
                    console.log("dataPointIndex => ", dataPointIndex);
                    console.log("w => ", w);

                    return '<div class="arrow_box">' +
                        '<h2>' + props.pieTooltip[seriesIndex] + '</h2>' +
                        '</div>';
                }
            }
            ,
            colors: ["#1475e1", "#1475e1"], //Add this line
            legend: {
                show: false,
            },
            markers: {
                show: false,
            },
            stroke: {
                show: false,
                curve: 'smooth',
                lineCap: 'round',
                colors: undefined,
                width: 2,
                dashArray: 0,
            },
            fill: {
                type: "solid",
                // colors: ["#e742b9", "#ffd6f4"],
                colors: ['#28a745', '#5ECFFF', '#FF9325', '#FF4A55', '#924AEF'],
                hover: {
                    colors: ["#20FF20", "#f91c48"],
                }
            },
        },

    }

    const isLargeScreen = useMediaQuery({ query: '(max-width: 1460px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

    return (
        <>
            <ReactApexChart
                height={isMobile ? "250px" : isTablet ? "200px": isLargeScreen ? "220px" : "240px"}
                options={data.options}
                series={data.series}
                type="pie"
                className="PieChart"
            />
        </>
    )
}

export default PieChart