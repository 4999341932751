import React from 'react'
import { useState, useEffect } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';
import NavSidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import { SlInfo } from 'react-icons/sl';

function RenewalInstructions() {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("instructions");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "instructions");
        setShowForm((hash.split("#"))[1] || "instructions");
    }, [hash]);


    return (
        <>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>

                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='section_four terms_conditions_section mt-4'>
                        <div className="card">
                            <div className="terms_icon">
                                <SlInfo className='icon' />
                            </div>
                            <div className="terms_conditions">
                                <h4>Terms And Conditions</h4>
                            </div>

                            <div className='conditions my-4'>
                                <ul>
                                    <li>The applicant must visit Ihsan Trust website before interview and must
                                        LIKE (Follow See First) Ihsan Trust’s social media channels i.e. Ihsan
                                        Trust Facebook page & Instagram page. <br />
                                        <a href="http://www.facebook.com/ihsantrust" target='_blank' className='social_icons'>
                                            <FaFacebook className='me-2 fb_icon' />
                                            <p>http://www.facebook.com/ihsantrust</p>
                                        </a>
                                        <a href="http://www.instagram.com/ihsantrust__" target='_blank' className='social_icons'>
                                            <FaInstagram className='me-2 insta_icon' />
                                            <p>http://www.instagram.com/ihsantrust</p>
                                        </a>
                                    </li>
                                    <li>The applicant must have thoroughly read the whole Tarjuma e Quran (Translation of Quran) before Renewal Interview.</li>
                                    <li>The applicant must have (opened) his/her own bank account in Meezan Bank Ltd. (MBL).</li>
                                    <li>All monthly repayments must be cleared before Renewal Interview.</li>
                                    <li>Incomplete Forms or Forms without supporting documents shall not be considered.</li>
                                </ul>
                            </div>

                            <div className='d-flex flex-wrap jc-center gap-2'>
                                <button className='btn dont_accept_btn' onClick={() => navigate("/dashboard")}>Do Not Accept</button>
                                <button className='btn accept_btn' onClick={() => navigate(`#${"demographics_information"}`)}>Accept and Continue</button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default RenewalInstructions