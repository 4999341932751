import { Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ToolTip from 'react-bootstrap/Tooltip';

function TooltipComponent(props) {
  // ['top', 'right', 'bottom', 'left']


  // const renderTooltip = (props) => {
  //   return (
  //     <Tooltip id="button-tooltip" className={`abc ${props.className}`} {...props}>
  //       {props.tooltipText}
  //     </Tooltip>
  //   )
  // };

  return (
    <>
      <OverlayTrigger
        placement={props.placement}
        overlay={<ToolTip className={`abc ${props.className}`} id={`tooltip-${props.placement}`}>
          {props.tooltipHeading &&
            <h6> {props.tooltipHeading} </h6>
          }
          {props.tooltipText}
        </ToolTip>}
      >
        {props.children}
      </OverlayTrigger>


      {/* <OverlayTrigger
        placement={props.placement}
        // delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        {props.children}
      </OverlayTrigger> */}

    </>
  );
}

export default TooltipComponent;