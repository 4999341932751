import { useState, useEffect } from "react";
import jsPDF from "jspdf";
import cloud_upload from "../../assets/cloud-upload.svg"
import { FaRegFileLines } from "react-icons/fa6";
import {
    form8, uploadAcademicCertficates, getForm9, uploadAdmissionLetter,
    uploadBankStatementFamily, uploadChildrenBform, uploadCnicParent,
    uploadCnicSelf, uploadCnicSibling, uploadCnicSpouse, uploadDeathCertificate,
    uploadDomicile, uploadElectricBill, uploadFeeLedger, uploadGasBill,
    uploadGuardianIncomeCertificate,
    uploadIncomeTaxFamily, uploadPaidFeeVoucher, uploadPassportImage,
    uploadRentAgreement, uploadResume, uploadSelfIncomeCertificate,
    uploadSiblingIncomeCertificate, uploadSpouseIncomeCertificate,
    uploadTelephoneBill, uploadUnpaidFeeVoucher, uploadwaterBill,
    matricResult, interResult, bachelorsResult, mastersResult,
    othersResult, undertaking, cnicFather, cnicMother,
    employemnetIncomeCertificate, fatherPaySlip, motherPaySlip,
    selfPaySlip, siblingPaySlip, spousePaySlip, fatherPension,
    motherPension, selfPension, siblingPension, spousePension,
    fatherIncomeCertificate, motherIncomeCertificate, fatherAgriIncome,
    motherAgriIncome, spouseAgriIncome, siblingAgriIncome,
    selfAgriIncome, fatherOtherIncome, motherOtherIncome,
    selfOtherIncome, siblingOtherIncome, spouseOtherIncome
    , uploadDeathCertificateFather, uploadDeathCertificateMother, uploadGuardianCnic
} from "../../ContextAPI/APIs/api";




import { useToast } from "../../ContextAPI/Components/toast"
import { useAuth } from "../../ContextAPI/Components/auth";
import TooltipComponent from "../tooltip";
import { MdInfoOutline } from "react-icons/md";

function ImageUploadInput(props) {

    const { alert } = useToast();
    const { getDocuments,getFormData, fileData2,  } = useAuth()


    const [imageUrls, setImageUrls] = useState([]);
    const [param, setParam] = useState("")
    const [fileData, setFileData] = useState({})
    const [Accetp, setAccept] = useState([])

    const [pdfFile, setPdfFile] = useState([])

    const addImage = (event) => {

        // setImageUrls([])
        console.log("KEY", props);
        // console.log("KEY22", fileData[props.key]);
        console.log("nn", event.target.name);

        setParam(event.target.name)

        const files = event.target.files;

        console.log("files", files);

        setPdfFile(files)
        if (!validateForm(files)) return;

        const urls = [];
        for (let i = 0; i < files.length; i++) {
            const url = URL.createObjectURL(files[i]);
            urls.push(url);
        }
        console.log("URL", urls);

        if (imageUrls.length > 0) {
            setImageUrls(urls);
        }
        else {
            setImageUrls((prevImageUrls) => [...prevImageUrls, ...urls]);
        }
    };

    const generatePdf = async (e) => {
        e.preventDefault();
        if (!validateForm(pdfFile)) return;
        if (props.accept !== ".pdf") {
            const doc = new jsPDF();
            for (const url of imageUrls) {
                const img = new Image();
                img.src = url;
                await new Promise((resolve) => (img.onload = resolve));

                const imgWidth = 210;
                const imgHeight = (img.height * imgWidth) / img.width;
                doc.addImage(img, "JPEG", 0, 0, imgWidth, imgHeight);
                if (url !== imageUrls[imageUrls.length - 1]) {
                    doc.addPage();
                }
            }
            // setPdfDoc(doc);
            console.log("dOV", doc);
            savePdf(doc)
        }
        else {
            console.log("--------------------");
            console.log(imageUrls[0]);
            console.log("--------------------");
            savePdf(imageUrls[0])
        }
    };

    const savePdf = async (pdfDoc) => {
        if (pdfDoc) {
            console.log("PDFFFOC", pdfDoc);
            console.log("PATH", param);

            // pdfDoc.save("images.pdf");
            let pdfBlob
            if (props.accept === ".pdf") {
                console.log("------", pdfFile[0]);
            }
            else {
                pdfBlob = pdfDoc.output("blob");
            }


            // console.log("BLLL", pdfBlob);
            // return


            if (param == "fatherIncomeCertificate") {
                const formData = new FormData();
                formData.append("fatherIncomeCertificate", pdfFile[0], "fatherIncomeCertificate.pdf");
                console.log("FD", formData);
                const response = await fatherIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }



            if (param == "motherIncomeCertificate") {
                const formData = new FormData();
                formData.append("motherIncomeCertificate", pdfFile[0], "motherIncomeCertificate.pdf");
                console.log("FD", formData);
                const response = await motherIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "spouseIncome") {
                const formData = new FormData();
                formData.append("spouseIncome", pdfFile[0], "spouseIncome.pdf");
                console.log("FD", formData);
                const response = await uploadSpouseIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }














            if (param == "fatherAgriIncome") {
                const formData = new FormData();
                formData.append("fatherAgriIncome", pdfFile[0], "fatherAgriIncome.pdf");
                console.log("FD", formData);
                const response = await fatherAgriIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "motherAgriIncome") {
                const formData = new FormData();
                formData.append("motherAgriIncome", pdfFile[0], "motherAgriIncome.pdf");
                console.log("FD", formData);
                const response = await motherAgriIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "spouseAgriIncome") {
                const formData = new FormData();
                formData.append("spouseAgriIncome", pdfFile[0], "spouseAgriIncome.pdf");
                console.log("FD", formData);
                const response = await spouseAgriIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "siblingAgriIncome") {
                const formData = new FormData();
                formData.append("siblingAgriIncome", pdfFile[0], "siblingAgriIncome.pdf");
                console.log("FD", formData);
                const response = await siblingAgriIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "selfAgriIncome") {
                const formData = new FormData();
                formData.append("selfAgriIncome", pdfFile[0], "selfAgriIncome.pdf");
                console.log("FD", formData);
                const response = await selfAgriIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }















            if (param == "fatherOtherIncome") {
                const formData = new FormData();
                formData.append("fatherOtherIncome", pdfFile[0], "fatherOtherIncome.pdf");
                console.log("FD", formData);
                const response = await fatherOtherIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "motherOtherIncome") {
                const formData = new FormData();
                formData.append("motherOtherIncome", pdfFile[0], "motherOtherIncome.pdf");
                console.log("FD", formData);
                const response = await motherOtherIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "selfOtherIncome") {
                const formData = new FormData();
                formData.append("selfOtherIncome", pdfFile[0], "selfOtherIncome.pdf");
                console.log("FD", formData);
                const response = await selfOtherIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "siblingOtherIncome") {
                const formData = new FormData();
                formData.append("siblingOtherIncome", pdfFile[0], "siblingOtherIncome.pdf");
                console.log("FD", formData);
                const response = await siblingOtherIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "spouseOtherIncome") {
                const formData = new FormData();
                formData.append("spouseOtherIncome", pdfFile[0], "spouseOtherIncome.pdf");
                console.log("FD", formData);
                const response = await spouseOtherIncome(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }














            if (param == "fatherPension") {
                const formData = new FormData();
                formData.append("fatherPension", pdfFile[0], "fatherPension.pdf");
                console.log("FD", formData);
                const response = await fatherPension(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "motherPension") {
                const formData = new FormData();
                formData.append("motherPension", pdfFile[0], "motherPension.pdf");
                console.log("FD", formData);
                const response = await motherPension(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "selfPension") {
                const formData = new FormData();
                formData.append("selfPension", pdfFile[0], "selfPension.pdf");
                console.log("FD", formData);
                const response = await selfPension(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "siblingPension") {
                const formData = new FormData();
                formData.append("siblingPension", pdfFile[0], "siblingPension.pdf");
                console.log("FD", formData);
                const response = await siblingPension(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "spousePension") {
                const formData = new FormData();
                formData.append("spousePension", pdfFile[0], "spousePension.pdf");
                console.log("FD", formData);
                const response = await spousePension(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }



            if (param == "fatherPaySlip") {
                const formData = new FormData();
                formData.append("fatherPaySlip", pdfFile[0], "fatherPaySlip.pdf");
                console.log("FD", formData);
                const response = await fatherPaySlip(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "motherPaySlip") {
                const formData = new FormData();
                formData.append("motherPaySlip", pdfFile[0], "motherPaySlip.pdf");
                console.log("FD", formData);
                const response = await motherPaySlip(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "selfPaySlip") {
                const formData = new FormData();
                formData.append("selfPaySlip", pdfFile[0], "selfPaySlip.pdf");
                console.log("FD", formData);
                const response = await selfPaySlip(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "siblingPaySlip") {
                const formData = new FormData();
                formData.append("siblingPaySlip", pdfFile[0], "siblingPaySlip.pdf");
                console.log("FD", formData);
                const response = await siblingPaySlip(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "spousePaySlip") {
                const formData = new FormData();
                formData.append("spousePaySlip", pdfFile[0], "spousePaySlip.pdf");
                console.log("FD", formData);
                const response = await spousePaySlip(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }



            if (param == "passportImage") {
                const formData = new FormData();
                formData.append("passimage", pdfBlob, "passimage.pdf");
                console.log("FD", formData);
                const response = await uploadPassportImage(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }



            if (param == "resume") {
                const formData = new FormData();
                formData.append("resume", pdfFile[0], "resume.pdf");
                console.log("FD", formData);
                const response = await uploadResume(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "selfCnic") {
                const formData = new FormData();
                console.log("pdfBlob", pdfBlob);
                formData.append("cnicSelf", pdfBlob, "cnicSelf.pdf");
                console.log("FD", formData);
                const response = await uploadCnicSelf(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "guardianCnic") {
                const formData = new FormData();
                console.log("pdfBlob", pdfBlob);
                formData.append("cnicGuardian", pdfBlob, "cnicGuardian.pdf");
                console.log("FD", formData);
                const response = await uploadGuardianCnic(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "cnicFather") {
                const formData = new FormData();
                formData.append("cnicFather", pdfBlob, "cnicFather.pdf");
                console.log("FD", formData);
                const response = await cnicFather(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "cnicMother") {
                const formData = new FormData();
                formData.append("cnicMother", pdfBlob, "cnicMother.pdf");
                console.log("FD", formData);
                const response = await cnicMother(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "cnicSpouse") {
                const formData = new FormData();
                formData.append("cnicSpouse", pdfBlob, "cnicSpouse.pdf");
                console.log("FD", formData);
                const response = await uploadCnicSpouse(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "siblingsCnic") {
                const formData = new FormData();
                formData.append("cnicSibling", pdfBlob, "cnicSibling.pdf");
                console.log("FD", formData);
                const response = await uploadCnicSibling(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "domicile") {
                const formData = new FormData();
                formData.append("domicile", pdfBlob, "domicile.pdf");
                console.log("FD", formData);
                const response = await uploadDomicile(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "childrenBform") {
                const formData = new FormData();
                formData.append("childrenBform", pdfBlob, "childrenBform.pdf");
                console.log("FD", formData);
                const response = await uploadChildrenBform(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "DeathCertificateFather") {
                const formData = new FormData();
                formData.append("DeathCertificateFather", pdfBlob, "DeathCertificateFather.pdf");
                console.log("FD", formData);
                const response = await uploadDeathCertificateFather(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "DeathCertificateMother") {
                const formData = new FormData();
                formData.append("DeathCertificateMother", pdfBlob, "DeathCertificateMother.pdf");
                console.log("FD", formData);
                const response = await uploadDeathCertificateMother(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "AdmissionLetter") {
                const formData = new FormData();
                formData.append("admissionLetter", pdfBlob, "admissionLetter.pdf");
                console.log("FD", formData);
                const response = await uploadAdmissionLetter(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "AcademicCertificates") {
                const formData = new FormData();
                formData.append("academicCertficates", pdfBlob, "academicCertficates.pdf");
                console.log("FD", formData);
                const response = await uploadAcademicCertficates(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "UnpaidFee") {
                const formData = new FormData();
                formData.append("unpaidFeeVoucher", pdfBlob, "unpaidFeeVoucher.pdf");
                console.log("FD", formData);
                const response = await uploadUnpaidFeeVoucher(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "paidFee") {
                const formData = new FormData();
                formData.append("paidFeeVoucher", pdfBlob, "paidFeeVoucher.pdf");
                console.log("FD", formData);
                const response = await uploadPaidFeeVoucher(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "feeLedger") {
                const formData = new FormData();
                formData.append("feeLedger", pdfBlob, "feeLedger.pdf");
                console.log("FD", formData);
                const response = await uploadFeeLedger(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }





            if (param == "selfIncome") {
                const formData = new FormData();
                formData.append("selfIncomeCertificate", pdfFile[0], "selfIncome.pdf");
                console.log("FD", formData);
                const response = await uploadSelfIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "guardianIncome") {
                const formData = new FormData();
                formData.append("guardianIncomeCertificate", pdfFile[0], "guardianIncomeCertificate.pdf");
                console.log("FD", formData);
                const response = await uploadGuardianIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "spouseIncome") {
                const formData = new FormData();
                formData.append("spouseIncomeCertificate", pdfFile[0], "spouseIncomeCertificate.pdf");
                console.log("FD", formData);
                const response = await uploadSpouseIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "siblingsIncome") {
                const formData = new FormData();
                formData.append("siblingIncomeCertificate", pdfFile[0], "siblingIncomeCertificate.pdf");
                console.log("FD", formData);
                const response = await uploadSiblingIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "IncomeTaxFamily") {
                const formData = new FormData();
                formData.append("IncomeTaxFamily", pdfFile[0], "IncomeTaxFamily.pdf");
                console.log("FD", formData);
                const response = await uploadIncomeTaxFamily(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "BankStatementFamily") {
                const formData = new FormData();
                formData.append("BankStatementFamily", pdfFile[0], "BankStatementFamily.pdf");
                console.log("FD", formData);
                const response = await uploadBankStatementFamily(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "ElectricBill") {
                const formData = new FormData();
                formData.append("ElectricBill", pdfBlob, "ElectricBill.pdf");
                console.log("FD", formData);
                const response = await uploadElectricBill(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "GasBill") {
                const formData = new FormData();
                formData.append("GasBill", pdfBlob, "GasBill.pdf");
                console.log("FD", formData);
                const response = await uploadGasBill(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "TelephoneBill") {
                const formData = new FormData();
                formData.append("TelephoneBill", pdfBlob, "TelephoneBill.pdf");
                console.log("FD", formData);
                const response = await uploadTelephoneBill(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "waterBill") {
                const formData = new FormData();
                formData.append("waterBill", pdfBlob, "waterBill.pdf");
                console.log("FD", formData);
                const response = await uploadwaterBill(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "Rent") {
                const formData = new FormData();
                formData.append("RentAgreement", pdfFile[0], "RentAgreement.pdf");
                console.log("FD", formData);
                const response = await uploadRentAgreement(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "matricResult") {
                const formData = new FormData();
                formData.append("matricResult", pdfBlob, "matricResult.pdf");
                console.log("FD", formData);
                console.log("pdfFile[0]", pdfBlob);
                const response = await matricResult(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "interResult") {
                const formData = new FormData();
                formData.append("interResult", pdfBlob, "interResult.pdf");
                console.log("FD", formData);
                const response = await interResult(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            if (param == "bachelorsResult") {
                const formData = new FormData();
                formData.append("bachelorsResult", pdfBlob, "bachelorsResult.pdf");
                console.log("FD", formData);
                const response = await bachelorsResult(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "mastersResult") {
                const formData = new FormData();
                formData.append("mastersResult", pdfBlob, "mastersResult.pdf");
                console.log("FD", formData);
                const response = await mastersResult(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "othersResult") {
                const formData = new FormData();
                formData.append("othersResult", pdfBlob, "othersResult.pdf");
                console.log("FD", formData);
                const response = await othersResult(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "undertaking") {
                const formData = new FormData();
                formData.append("undertaking", pdfFile[0], "undertaking.pdf");
                console.log("FD", formData);
                const response = await undertaking(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }

            if (param == "employemnetIncomeCertificate") {
                const formData = new FormData();
                formData.append("employemnetIncomeCertificate", pdfFile[0], "employemnetIncomeCertificate.pdf");
                console.log("FD", formData);
                const response = await employemnetIncomeCertificate(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }


            getDocuments()
            setImageUrls([])
            getFormData()
        }
    };

    


    function validateForm(files) {
        // // Get the input element
        // var fileInput = document.getElementById('fileUpload');

        // // Get the selected files
        // var files = fileInput.files;


        // Check if any file is selected
        if (files.length === 0) {
            alert("Please select at least one file.", "error");
            return false;
        }
        console.log("PP======>", param);
        console.log("PP======>", files.length);
        // console.log("files======>");

        if ((param === "selfCnic" || param === "cnicFather" || param === "cnicMother" || param === "cnicSpouse" || param === "siblingsCnic" || param === "guardianCnic") && files.length != 2) {


            setImageUrls([])
            setParam("")
            setPdfFile([])
            const a = (document.getElementById('imageInput')).value = ""

            alert("Please upload front and back both images of CNIC", "error");


            return false;
        }




        // Accepted file types
        var acceptedTypes = props.accept || ['.jpg', '.jpeg', '.png', '.pdf', '.doc', '.docx'];

        // Check each selected file
        for (var i = 0; i < files.length; i++) {
            var file = files[i];

            // Get the file extension
            var fileExtension = '.' + file.name.split('.').pop().toLowerCase();

            // Check if the file type is accepted
            if (acceptedTypes.indexOf(fileExtension) === -1) {


                alert(`Invalid file type. Please upload files of type: ${formatAcceptData(props.accept)}`, "error");
                return false;
            }
        }

        // If everything is valid, allow the form submission
        return true;
    }

    function formatAcceptData(acceptData) {
        const extensionMapping = {
            '.jpg': 'JPG',
            '.jpeg': 'JPEG',
            '.png': 'PNG',
            '.pdf': 'PDF',
            '.doc': 'DOC',
            '.docx': 'DOC',
            '.ppt': 'PPT',
            '.pptx': 'PPT'
        };

        // Split the accept data into individual extensions
        const extensions = acceptData.split(',').map(type => type.trim());

        // Map each extension to its corresponding format
        const formattedTypes = extensions.map(extension => extensionMapping[extension]);

        // Filter out undefined values (extensions not in the mapping)
        const validTypes = formattedTypes.filter(type => type);

        // Join the types into a comma-separated string
        const formattedString = validTypes.join(', ');

        return formattedString;
    }


    useEffect(() => {
        // getFormData()
        setAccept(props.accept)

    }, [])



    const cancel = (e) => {
        e.preventDefault();

        setImageUrls([])
        setParam("")
        setPdfFile([])
        const a = (document.getElementById('imageInput')).value = ""
    }

    return (
        <>
            <div className={`form-group ${props.classes}`}>
                <div className='d-flex ai-center jc-between'>
                    <div className="d-flex">
                        <label className='form-label'>{props.text} <span className="required">{props.span}</span></label>
                        {
                            props.tooltip == true &&
                            <TooltipComponent className="" placement="top" tooltipText={props.tooltipText}>
                                <div><MdInfoOutline className='hint-icon mb-2 ml-1' /></div>
                            </TooltipComponent>
                        }
                    </div>
                </div>
                <div className='upload_file dropzone image mb-0'>
                    <label className="mb-0">
                        <div className="overlay"></div>
                        <input id="imageInput" disabled={props?.dis} required={props.required} type="file" name={props.name} multiple={props?.multiple} className='upload_image_input' accept={props.accept} style={{ display: "none" }} onChange={addImage} />
                    </label>

                    <div className="d-flex align-items-center gap-3 w-auto">
                        <div className="upload_icon rounded-2 px-2 py-1 w-auto">
                            <img src={cloud_upload} alt="img" />
                        </div>

                        <div className="dz-message text-start">
                            {
                                imageUrls?.length > 0 ?
                                    <div className="d-flex flex-wrap ai-center">
                                        {
                                            ['.pdf', '.doc', '.docx']?.some(item => item.includes(Accetp)) ?
                                                imageUrls?.map((item, i) => {
                                                    console.log("imageUrls => ", item);
                                                    return <>
                                                        <FaRegFileLines className="purple_icons" style={{ fontSize: "34px" }} />
                                                    </>
                                                })
                                                :
                                                imageUrls?.map((item, i) => {
                                                    console.log("imageUrls => ", item);
                                                    return <>
                                                        <div className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                                                            <div className="dz-image">
                                                                {/* <img data-dz-thumbnail="" alt="images.jfif" src="data:image/png" /> */}
                                                                <img key={i} src={item} alt="logo" className="img-fluid border image_upload" />
                                                            </div>
                                                        </div>
                                                    </>
                                                })
                                        }
                                    </div>
                                    :
                                    fileData2[props.itemkey] ?
                                        <div className="uploaded_doc_icon">
                                            <a href={`${props.url}/${fileData2[props.itemkey]}`} className="" target="_blank">
                                                <FaRegFileLines className="purple_icons file_icon" />
                                            </a>
                                        </div>
                                        :
                                        <div>
                                            <p className="mb-0">Upload File</p>
                                            <small className="mb-0 fw-semibold text-gray">{formatAcceptData(props.accept)}</small>
                                        </div>
                            }
                        </div>
                    </div>

                </div>

                {imageUrls?.length > 0 &&
                    <div style={{ display: "flex", flexDirection: "row-reverse" }} className="mt-2">
                        <button className='btn upload_btn me-2' onClick={(e) => cancel(e)} >Cancel</button>
                        <button className='btn upload_btn me-2' onClick={(e) => generatePdf(e)}>Save</button>
                    </div>
                }

            </div>
        </>
    );
}


export default ImageUploadInput;