import React from 'react'
import Select from 'react-select'




export default function SelectNew(props) {

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            console.log({ data, isDisabled, isFocused, isSelected });
            return {
                ...styles,
                backgroundColor: (isSelected && "#924AEF") || (isFocused && "#924aef26"),
                color: (isSelected && "white") || (isFocused && "black"),
            };
        }
    };

    return <>
        <label className='form-label' style={{ textTransform: "capitalize" }}>{props?.label}  <span className='required'>{props.span}</span></label>

        <Select
            styles={colourStyles}
            classNames="select_inner"
            {...props}
        />
    </>

}



// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
//     { value: '', label: 'Mango' }
// ]

// export default function SelectNew(props) {

//     return <>
//         <Select
//             options={options}
//             isOptionDisabled={(option) => option.value == ''}
//             isSearchable={false}
//             loadingMessage={"Loading..."}
//             required
//             value={{ value: 'strawberry', label: 'Strawberry' }}
//         />
//     </>

// }