import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';


import { getForm3, getFormsByAdmin, SubmissionForm3 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"


function Step_4(props) {

    const { GetLoginUser } = useAuth();
    // Loan Detail
    const [loanDetail, setLoanDetail] = useState([
        {
            S_No: "",
            Semester_Year: "",
            Total_Fee_Cost: "",
            Own_Contribution: "",
            Loan_Required: "",
        },
    ]);

    const loan_detail = {
        S_No: "",
        Semester_Year: "",
        Total_Fee_Cost: "",
        Own_Contribution: "",
        Loan_Required: "",
    }

    function Add_New_Loan_Detail(e) {
        e.preventDefault();
        setLoanDetail([...loanDetail, loan_detail])
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);


    const [feePayment, setFeePayment] = useState({
        source: 'Father',
        otherSource: '',
    });


    const [dis, setDis] = useState(true)


    const handleRadioChange = (e) => {
        if (e.target.value == "Other") {
            setDis(false)
        }
        else {
            setDis(true)
        }
        setFeePayment({
            ...feePayment,
            source: e.target.value,
        });
    };

    const handleOtherSourceChange = (e) => {
        setFeePayment({
            ...feePayment,
            otherSource: e.target.value,
        });
    };

    const Next = async () => {
        try {

            // console.log("fee", feePayment);

            // console.log("data", data);

            // console.log("data", loanDetail);


            // let payload = {
            //     ...data,
            //     semesterDetails: loanDetail
            // }


            // if (feePayment.source == "Other") {
            //     payload.feePayer = feePayment.source
            //     payload.otherSpecified = feePayment.otherSource
            // }
            // else {
            //     payload.feePayer = feePayment.source
            // }

            // console.log("PAYLOAD", payload);



            // const response = await SubmissionForm3(payload)
            // if (response.success) {
            //     alert(response.message)
            navigate(`#${"step_5"}`)
            //     GetLoginUser()
            // }
        }
        catch (e) {
            console.log(e);
        }
    }




    const [data, setData] = useState({
        // feePayer: "",
        question31: "",
        amountByGuardian: "",
        perSemFee: "",
        OutStandingFee: "",
        question32: "",
        question33: "",
    })

    const handleFeePayer = (e) => {
        setData({ ...data, feePayer: e.target.value })
    }


    const handlequestion31 = (e) => {
        setData({ ...data, question31: e.target.value })
    }

    const handleamountByGuardian = (e) => {
        setData({ ...data, amountByGuardian: e.target.value })
    }

    const handleperSemFee = (e) => {
        setData({ ...data, perSemFee: e.target.value })
    }

    const handleOutStandingFee = (e) => {
        setData({ ...data, OutStandingFee: e.target.value })
    }

    const handlequestion32 = (e) => {
        setData({ ...data, question32: e.target.value })
    }

    const handlequestion33 = (e) => {
        setData({ ...data, question33: e.target.value })
    }



    const handleLoanDetailChange = (e, rowIndex, fieldName) => {
        const updatedLoanDetail = [...loanDetail];
        updatedLoanDetail[rowIndex][fieldName] = e.target.value;
        setLoanDetail(updatedLoanDetail);
    };


    const getFormData = async () => {
        try {
            // const response = await getForm3()

            const response = await getFormsByAdmin(3, props.id)

            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
                setLoanDetail(response.message.semesterDetails)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getFormData()
    }, [])



    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        {/* <form> */}
                        <div className='financial_appraisal'>
                            <h5>Financial Appraisal</h5>

                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label me-4">Who has paid your school or college fee up till now? How much has that been (per annum)?</label>
                                        <div className="d-flex jc-between">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault7"
                                                    value="Father"
                                                    checked={(feePayment.source).toLowerCase() === 'father'}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault7">
                                                    Father
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault8"
                                                    value="Mother"
                                                    checked={(feePayment.source).toLowerCase() === 'mother'}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault8">
                                                    Mother
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault9"
                                                    value="Guardian"
                                                    checked={(feePayment.source).toLowerCase() === 'guardian'}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault9">
                                                    Guardian
                                                </label>
                                            </div>
                                            <div className="form-check d-flex">
                                                <input
                                                    className="form-check-input me-2"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault10"
                                                    value="Other"
                                                    checked={(feePayment.source).toLowerCase() === 'other'}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault10">
                                                    Other (specify)
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    value={feePayment.otherSource}
                                                />
                                            </div>
                                        </div>
                                    </div> </div>

                                <div className="col-12">
                                    <div className='form-group'>
                                        <label className="form-label">Have your general financial conditions changed in the recent years? Or are there any reasons to believe that they may change in the days to come?</label>
                                        <textarea disabled value={data.question31} className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className='form-group'>
                                        <label className="form-label">Are any of the family members beside father/mother/guardian supporting your educational expense at thisinstitute? If yes, then please state the amount contributed and your relationship with that person.</label>
                                        <div className='d-flex ai-center'>
                                            <label className="form-label mb-0 me-3">Rs.</label>
                                            <input disabled value={data.amountByGuardian} type='number' className='form-control w-50' placeholder='Enter here'></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row mb-4">

                                        <div className='d-flex ai-center'>
                                            <label className="form-label mb-0 me-2">Per Semester Fee:</label>
                                            <div className="col-md-2 me-3">
                                                <input disabled value={data.perSemFee} type="number" className='form-control' placeholder='Enter here' required />
                                            </div>

                                            <div className='d-flex ai-center'>
                                                <label className="form-label mb-0 me-2">Outstanding Fee (if any):</label>
                                                <div className="col-md-3 me-2">
                                                    <input disabled value={data.OutStandingFee} type="number" className='form-control' placeholder='Enter here' required />
                                                </div>
                                                <label className="form-label mb-0">(provide fee summary)</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className='form-group'>
                                        <label className="form-label">How you would be able to return the amount to IT enabling it to provide similar facilities to other applicants?</label>
                                        <textarea disabled value={data.question32} className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className='form-group'>
                                        <label className="form-label">What alternate arrangements have you made or are able to make in case Financial Assistance is not approved?</label>
                                        <textarea disabled value={data.question33} className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="table_form mb-4">
                                <div className='d-flex ai-center jc-between h6_flex'>
                                    <h6>Please state (Mandatory) the tentative requirement of Loan desired from IT (keeping in view Fee required for
                                        the complete program). Without this information (requirement), <i>APPLICATION WILL BE CANCELLED WITHOUT
                                            INTIMATION.</i> Ihsan Trust will not support 100% of the fee; the student has to (must) contribute a certain portion of the fee.
                                    </h6>
                                    {/* <div className='button'>
                                        <button className='btn add_btn' onClick={(e) => Add_New_Loan_Detail(e)}><FaPlus /></button>
                                    </div> */}
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">S. No</th>
                                                <th scope="col">Semester / Year</th>
                                                <th scope="col">Total Fee Cost</th>
                                                <th scope="col">Own Contribution</th>
                                                <th scope="col">Loan Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loanDetail.map((data, i) => (
                                                <tr key={i}>
                                                    <th scope="row" className='s_no'>{(i + 1)}</th>
                                                    <td>
                                                        <input disabled
                                                            type="number"
                                                            className='form-control'
                                                            placeholder="Enter here"
                                                            value={data.Semester_Year}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input disabled
                                                            type="number"
                                                            className='form-control'
                                                            placeholder="Enter here"
                                                            value={data.Total_Fee_Cost}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input disabled
                                                            type="number"
                                                            className='form-control'
                                                            placeholder="Enter here"
                                                            value={data.Own_Contribution}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input disabled
                                                            type="number"
                                                            className='form-control'
                                                            placeholder="Enter here"
                                                            value={data.Loan_Required}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"step_3"}`)}>Previous</button>
                            <button className='btn next_btn' onClick={() => Next()}>Next</button>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step_4
