import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAppDocuments, getForm3, getFreshCaseApp, SubmissionForm3 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"
import { useToast } from "../../ContextAPI/Components/toast"
import { FileViewerAdmin } from '../Form/fileViewerAdmin';

import { FamilyUrl, IncomeCertificateUrl, newURL } from '../../helpers/data';

import Form from 'react-bootstrap/Form';

import FINANCIAL_DETAIL from "../../assets/form-icons/financial-details.png"
import FINANCIAL_APPR from "../../assets/form-icons/financial-appraisal.png"
import INCOME_DETAIL from "../../assets/form-icons/income-detail.png"
import formatter from '../../helpers/number_formatter';

function Financial_InformationViewOnly({ docs }) {
    const { id, doc } = useParams();
    const { alert } = useToast()
    const { GetLoginUser, user } = useAuth();

    // Financial Assistance Detail
    const [financeAssis, setFinanceAssis] = useState([
        // {
        //     Name_of_Organization: "",
        //     Type_of_Assistance: "",
        //     Amount_Applied_For: "",
        //     Amount_Received: "",
        // },
    ]);

    const financial_detail = {
        Name_of_Organization: "",
        Type_of_Assistance: "",
        Amount_Applied_For: "",
        Amount_Received: "",
    }

    function Add_New_Finance_Detail(e) {
        e.preventDefault();
        setFinanceAssis([...financeAssis, financial_detail])
    }

    const handleInputChangeFA = (e, index) => {
        const { name, value } = e.target;
        const updatedBankDetail = [...financeAssis];
        updatedBankDetail[index][name] = value;
        setFinanceAssis(updatedBankDetail);
    };

    const [bankDetail, setBankDetail] = useState([
        {
            Nameof: "",
            NameBank: "",
            ClosingBalance: ""
        },
    ]);

    const bank_detail = {
        Nameof: "",
        NameBank: "",
        ClosingBalance: ""
    }

    function Add_New_Bank_Detail(e) {
        e.preventDefault();
        setBankDetail([...bankDetail, bank_detail])
    }


    // const handleInputChangeBANK = (e, index) => {
    //     const { name, value } = e.target;
    //     const updatedBankDetail = [...bankDetail];
    //     updatedBankDetail[index][name] = value;
    //     setBankDetail(updatedBankDetail);
    // };


    const handleInputChangeBANK = (e, index) => {
        const { name, value } = e.target;
        const updatedBankDetail = [...bankDetail];
        updatedBankDetail[index][name] = value;
        setBankDetail(updatedBankDetail);

        // Calculate the sum of all closing balances
        const totalClosingBalance = updatedBankDetail.reduce((sum, item) => {
            return sum + parseFloat(item.ClosingBalance || 0);
        }, 0);

        // Set the total closing balance in the data state
        setData((prevData) => ({
            ...prevData,
            AssetTotal: totalClosingBalance,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Loan Detail
    const [loanDetail, setLoanDetail] = useState([
        {
            S_No: "",
            Semester_Year: "",
            Total_Fee_Cost: "",
            Own_Contribution: "",
            Loan_Required: "",
        },
    ]);

    const loan_detail = {
        S_No: "",
        Semester_Year: "",
        Total_Fee_Cost: "",
        Own_Contribution: "",
        Loan_Required: "",
    }

    function Add_New_Loan_Detail(e) {
        e.preventDefault();
        setLoanDetail([...loanDetail, loan_detail])
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);


    const [feePayment, setFeePayment] = useState({
        source: 'Father',
        otherSource: '',
    });

    const [data, setData] = useState({
        // feePayer: "",
        // question31: "",
        // amountByGuardian: "",
        // perSemFee: "",
        // OutStandingFee: "",
        // question32: "",
        // question33: "",

        supportingEducation: "",
        relation: "",
        q21: "",
        q22: "",
        q23: "",
        feeContributor: "",
        perSemFee: "",
        OutStandingFee: "",
        AssetTotal: 0
    })

    const handleFeePayer = (e) => {
        setData({ ...data, feePayer: e.target.value })
    }

    const handlequestion31 = (e) => {
        setData({ ...data, question31: e.target.value })
    }


    const handleRelation = (e) => {
        setData({ ...data, relation: e.target.value })
    }

    const handleamountByGuardian = (e) => {
        setData({ ...data, amountByGuardian: e.target.value })
    }

    const handleperSemFee = (e) => {
        setData({ ...data, perSemFee: e.target.value })
    }

    const handleOutStandingFee = (e) => {
        setData({ ...data, OutStandingFee: e.target.value })
    }

    const handlequestion32 = (e) => {
        setData({ ...data, question32: e.target.value })
    }

    const handlequestion33 = (e) => {
        setData({ ...data, question33: e.target.value })
    }

    const handleLoanDetailChange = (e, rowIndex, fieldName) => {
        const updatedLoanDetail = [...loanDetail];
        updatedLoanDetail[rowIndex][fieldName] = e.target.value;
        setLoanDetail(updatedLoanDetail);
    };

    const handleInputChange = (fieldName, value) => {
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const [isFinanc, setIsFinanc] = useState(false)
    const [findis, setFinDis] = useState(false)


    const getFormData = async () => {
        try {
            // const response = await getForm3()

            const response = await getFreshCaseApp(
                {
                    id: doc,
                    form: "form3"
                }
            )


            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
                setLoanDetail(response.message.FinancialBreakDown)
                setFinanceAssis(response.message.FinancialAssistance)
                setBankDetail(response.message.bankAccountDetails)
                setFinDis(response.message.bankAccountApplicable)
                setIsFinanc(response.message.bankAccountApplicable)
                setGrossSalary(response.message.IncomeDetails[0])
                setNetSalary(response.message.IncomeDetails[1])
                setPension(response.message.IncomeDetails[2])
                setBusinessIncome(response.message.IncomeDetails[3])
                setAgriIncome(response.message.IncomeDetails[4])
                setHouseProp(response.message.IncomeDetails[5])
                setBusinessProp(response.message.IncomeDetails[6])
                setShares(response.message.IncomeDetails[7])
                // setFeePayment({
                //     otherSource: (response.message.feePayer).toLowerCase() == "other" ? response.message.otherSpecified : "",
                //     source: response.message.feePayer,
                // });
                // setLoanDetail(response.message.semesterDetails)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    function removeDetail(i) {
        const updated = [...bankDetail]
        updated.splice(i, 1)
        setBankDetail(updated)
    }

    function removeLoanDetail(i) {
        const updated = [...loanDetail]
        updated.splice(i, 1)
        setLoanDetail(updated)
    }

    function removeFinanceDetail(i) {
        const updated = [...financeAssis]
        updated.splice(i, 1)
        setFinanceAssis(updated)
    }


    const handleCheckBoxFinanc = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setFinDis(true)
        }
        else {
            setFinDis(false)
        }
        setIsFinanc(e.target.checked)
    }

    const [validated, setValidated] = useState(false);

    const [supportingEdu, setSupportingEdu] = useState();

    const [grossSalary, setGrossSalary] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })

    const [netSalary, setNetSalary] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })

    const [pension, setPension] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })

    const [businessIncome, setBusinessIncome] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })

    const [AgriIncome, setAgriIncome] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [houseProp, setHouseProp] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })
    const [businessProp, setBusinessProp] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })

    const [Shares, setShares] = useState({
        father: "",
        mother: "",
        self: "",
        siblings: "",
        spouse: "",
        total: ""
    })


    const handleGrossChange = (e, key) => {
        const value = e.target.value;

        setGrossSalary((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };


    const handleNetChange = (e, key) => {
        const value = e.target.value;

        setNetSalary((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };


    const handlePensionChange = (e, key) => {
        const value = e.target.value;

        setPension((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };


    const handleBIChange = (e, key) => {
        const value = e.target.value;

        setBusinessIncome((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const calculateTotalIncome = (incomeObject) => {
        const { father, mother, self: selfIncome, siblings, spouse } = incomeObject;

        // Convert string values to numbers (assuming they represent numbers)
        const fatherIncome = parseFloat(father) || 0;
        const motherIncome = parseFloat(mother) || 0;
        const selfIncomeValue = parseFloat(selfIncome) || 0;
        const siblingsIncome = parseFloat(siblings) || 0;
        const spouseIncome = parseFloat(spouse) || 0;

        // Calculate the total income
        const totalIncome = fatherIncome + motherIncome + selfIncomeValue + siblingsIncome + spouseIncome;

        return totalIncome;
    };


    const handleAIChange = (e, key) => {
        const value = e.target.value;

        setAgriIncome((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };

    const handleHPChange = (e, key) => {
        const value = e.target.value;

        setHouseProp((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };


    const handleBPChange = (e, key) => {
        const value = e.target.value;

        setBusinessProp((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };


    const handleShareChange = (e, key) => {
        const value = e.target.value;

        setShares((prevBusinessIncome) => {
            const updatedIncome = {
                ...prevBusinessIncome,
                [key]: value
            };

            const totalIncome = calculateTotalIncome(updatedIncome);

            return {
                ...updatedIncome,
                total: totalIncome.toString() // Convert total to string if necessary
            };
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();


        // bankDetail
        const abc = [
            grossSalary,
            netSalary,
            pension,
            businessIncome,
            AgriIncome,
            houseProp,
            businessProp,
            Shares
        ]


        console.log("HANDLESUBMIT", bankDetail);
        const payload = {
            bankAccountDetails: bankDetail,
            IncomeDetails: abc,
            FinancialAssistance: financeAssis,
            FinancialBreakDown: loanDetail,
            relation: data.relation,
            bankAccountApplicable: findis,
            // q21: data.question31,
            // q22: data.question32,
            // q23: data.question33,
            // feeContributor: data.amountByGuardian,
            // perSemFee: data.perSemFee,
            // OutStandingFee: data.OutStandingFee,
            q21: data.q21,
            q22: data.q22,
            q23: data.q23,
            feeContributor: data.feeContributor,
            perSemFee: data.perSemFee,
            OutStandingFee: data.OutStandingFee,
            supportingEducation: data?.supportingEducation,
            AssetTotal: data.AssetTotal
        }

        console.log("PPPAAAA", payload);

        const response = await SubmissionForm3(payload)
        alert(response.message)



        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.stopPropagation();
        // }

        // setValidated(true);
        // navigate(`#${"academic_background"}`)


    };

    const handleEducationSupport = (e) => {
        try {
            setSupportingEdu(e.target.value)
            setData({ ...data, supportingEducation: e.target.value })
        }
        catch (e) {
            console.log(e);
        }
    }


    const generateInputField = (fieldName) => {
        if (formatter(data[fieldName.name])) {
            return (
                <div className="col-md-4" key={fieldName}>
                    <div className="form-group">
                        <label className='form-label' >{fieldName.label}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={formatter(data[fieldName.name])}
                            disabled
                            onChange={(e) => handleInputChange(fieldName.name, e.target.value)}
                        />
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }


    const [form8, setForm8] = useState({})

    const getDocument = async () => {
        try {
            const response = await getAppDocuments({ id: id })
            console.log("RESPONSE23", response);
            if (response.success) {
                setForm8(response?.message)
                console.log("AAA", response?.message);
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getFormData()
        getDocument()
    }, [])


    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Financial Information</h5>
                </div>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="accordion accordion-flush" id="accordionFlushExample">

                        {/* Financial Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFinancial">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFinancial" aria-expanded="false" aria-controls="collapseFinancial">
                                    <img src={FINANCIAL_DETAIL} alt="icon" className='img-fluid form_icons' />
                                    Financial Details
                                </button>
                            </h2>
                            <div id="collapseFinancial" className="accordion-collapse collapse show" aria-labelledby="headingFinancial" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    {/* <div class="form-check text-center mb-4">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1" checked={isFinanc} onChange={(e) => handleCheckBoxFinanc(e)} />
                                        <label class="form-check-label" for="flexCheckDefault1" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                            Not Applicable For Me
                                        </label>
                                    </div> */}

                                    {
                                        bankDetail.length > 1 ?

                                            <>
                                                {/* Bank Details */}
                                                < div className="table_form mb-4">
                                                    {
                                                        bankDetail.length > 1 &&
                                                        <>
                                                            <div className='form_sub_heading mb-4'>
                                                                <h5 className='mb-0'>Bank Accounts Details</h5>
                                                                <div className='d-flex jc-end'>
                                                                    <button className='btn add_btn' disabled onClick={(e) => Add_New_Bank_Detail(e)}>Add More Bank Account</button>
                                                                </div>
                                                            </div>

                                                            {
                                                                bankDetail.map((item, i) => {
                                                                    console.log("bankDetail.length", bankDetail.length);
                                                                    return <>
                                                                        {
                                                                            i > 1 &&
                                                                            <div className="section_break"></div>
                                                                        }

                                                                        <div className="row">
                                                                            {
                                                                                bankDetail[i].relation &&
                                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                    <Form.Group controlId={`relation-${i}`} id='NameofAccount' className='form-group'>
                                                                                        <Form.Label>Relation</Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            name="Nameof"
                                                                                            value={bankDetail[i].relation}
                                                                                            // onChange={(e) => handleInputChangeBANK(e, i)}
                                                                                            placeholder=""
                                                                                            disabled
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                bankDetail[i].Nameof &&
                                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                    <Form.Group controlId={`accountTitle-${i}`} className='form-group'>
                                                                                        <Form.Label>Account Title</Form.Label>
                                                                                        <Form.Control
                                                                                            name="Nameof"
                                                                                            value={bankDetail[i].Nameof}
                                                                                            onChange={(e) => handleInputChangeBANK(e, i)}
                                                                                            type="text"
                                                                                            disabled
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                bankDetail[i].NameBank &&
                                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                    <Form.Group controlId={`bankName-${i}`} className='form-group'>
                                                                                        <Form.Label>Name of the Bank</Form.Label>
                                                                                        <Form.Control
                                                                                            value={bankDetail[i].NameBank}
                                                                                            name="NameBank"
                                                                                            onChange={(e) => handleInputChangeBANK(e, i)}
                                                                                            type="text"
                                                                                            disabled
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                bankDetail[i].ClosingBalance &&
                                                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 vertically_center">
                                                                                    <Form.Group controlId={`closingBalance-${i}`} className='form-group'>
                                                                                        <Form.Label>Closing Balance in Bank Account</Form.Label>
                                                                                        <Form.Control
                                                                                            name="ClosingBalance"
                                                                                            value={formatter(bankDetail[i].ClosingBalance)}
                                                                                            onChange={(e) => handleInputChangeBANK(e, i)}
                                                                                            type="text"
                                                                                            disabled
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            }
                                                                            {/* {
                                                            i > 0 && (
                                                                <div className='d-flex jc-end'>
                                                                    <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                                </div>
                                                            )
                                                        } */}
                                                                        </div>
                                                                    </>
                                                                })
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        (bankDetail.relation || bankDetail.Nameof || bankDetail.NameBank || bankDetail.ClosingBalance) &&
                                                        <div className="row">
                                                            <div className="col-12 vertically_center">
                                                                <Form.Group controlId="total" className='form-group'>
                                                                    <Form.Label>Total</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="total"
                                                                        value={formatter(data.AssetTotal)}
                                                                        // onChange={handleChange}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>

                                                <div className="row">
                                                    {
                                                        form8.BankStatementFamily &&
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                                            <FileViewerAdmin
                                                                document={docs}
                                                                id={id}
                                                                text="Bank Statement of Last Three (03) Months (Latest) of All Family Members having an Account"
                                                                url={FamilyUrl}
                                                                keyName="BankStatementFamily"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                            :
                                            <h5 className='not_found_msg'>No Data Found</h5>
                                    }

                                </div>
                            </div>
                        </div>

                        {/* Income Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingIncome">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseIncome" aria-expanded="false" aria-controls="collapseIncome">
                                    <img src={INCOME_DETAIL} alt="icon" className='img-fluid form_icons' />
                                    Income Details
                                </button>
                            </h2>
                            <div id="collapseIncome" className="accordion-collapse collapse show" aria-labelledby="headingIncome" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <div className="table_form mb-4">
                                        {/* <h4>Details of Income</h4> */}
                                        {/* <h5>Main sources of Income:</h5> */}

                                        {/* FATHER DATA */}
                                        <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>Is your Father earning? <span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                value={data.fatherEarning}
                                                onChange={handleChange}
                                                name="fatherEarning"
                                                disabled
                                            />
                                        </Form.Group>

                                        {
                                            data.fatherEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Father Income Details</h5>
                                                    </div>

                                                    <div className="row">

                                                        {[
                                                            { name: 'fatherSalary', label: 'Salary' },
                                                            { name: 'fatherPension', label: 'Pension' },
                                                            { name: 'fatherAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'fatherBusinessIncome', label: 'Business Income' },
                                                            { name: 'fatherProfitBank', label: 'Profit in Bank' },
                                                            { name: 'fatherHouseRent', label: 'House Rent Income' },
                                                            { name: 'fatherBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'fatherShares', label: 'Shares and Securites' },
                                                            { name: 'totalfather', label: 'Total' }
                                                        ]
                                                            .map(fieldName =>
                                                                generateInputField(fieldName)
                                                            )}

                                                        {
                                                            form8.fatherPaySlip &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pay-slip"
                                                                    url={newURL}
                                                                    keyName="fatherPaySlip"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.fatherPension &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pension Book Slip"
                                                                    url={newURL}
                                                                    keyName="fatherPension"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.fatherAgriIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Income Certificate"
                                                                    url={newURL}
                                                                    keyName="fatherAgriIncome"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.fatherIncomeCertificate &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Rental Agreement"
                                                                    url={newURL}
                                                                    keyName="fatherIncomeCertificate"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* MOTHER DATA */}
                                        <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>Is your Mother earning? <span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                value={data.motherEarning}
                                                onChange={handleChange}
                                                name="motherEarning"
                                                disabled
                                            />
                                        </Form.Group>

                                        {
                                            data.motherEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Mother Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'motherSalary', label: 'Salary' },
                                                            { name: 'motherPension', label: 'Pension' },
                                                            { name: 'motherAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'motherBusinessIncome', label: 'Business Income' },
                                                            { name: 'motherProfitBank', label: 'Profit in Bank' },
                                                            { name: 'motherHouseRent', label: 'House Rent Income' },
                                                            { name: 'motherBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'motherShares', label: 'Shares and Securites' },
                                                            { name: 'totalMother', label: 'Total' }
                                                        ].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}

                                                        {
                                                            form8.motherPaySlip &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pay-slip"
                                                                    url={newURL}
                                                                    keyName="motherPaySlip"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.motherPension &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pension Book Slip"
                                                                    url={newURL}
                                                                    keyName="motherPension"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.motherAgriIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Income Certificate"
                                                                    url={newURL}
                                                                    keyName="motherAgriIncome"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.motherIncomeCertificate &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Rental Agreement"
                                                                    url={newURL}
                                                                    keyName="motherIncomeCertificate"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* SELF DATA */}
                                        <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>Are you earning? <span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                value={data.selfEarning}
                                                onChange={handleChange}
                                                name="selfEarning"
                                                disabled
                                            />
                                        </Form.Group>

                                        {
                                            data.selfEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Self Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'selfSalary', label: 'Salary' },
                                                            { name: 'selfPension', label: 'Pension' },
                                                            { name: 'selfAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'selfBusinessIncome', label: 'Business Income' },
                                                            { name: 'selfProfitBank', label: 'Profit in Bank' },
                                                            { name: 'selfHouseRent', label: 'House Rent Income' },
                                                            { name: 'selfBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'selfShares', label: 'Shares and Securites' },
                                                            { name: 'totalSelf', label: 'Total' }
                                                        ].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}

                                                        {
                                                            form8.selfPaySlip &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pay-slip"
                                                                    url={newURL}
                                                                    keyName="selfPaySlip"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.selfPension &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pension Book Slip"
                                                                    url={newURL}
                                                                    keyName="selfPension"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.selfAgriIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Income Certificate"
                                                                    url={newURL}
                                                                    keyName="selfAgriIncome"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.selfIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Rental Agreement"
                                                                    url={newURL}
                                                                    keyName="selfIncome"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* SIBLINGS DATA */}
                                        <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>Is your Siblings earning? <span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                value={data.siblingEarning}
                                                onChange={handleChange}
                                                name="siblingEarning"
                                                disabled
                                            />
                                        </Form.Group>

                                        {
                                            data.siblingEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Siblings Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'siblingSalary', label: 'Salary' },
                                                            { name: 'siblingPension', label: 'Pension' },
                                                            { name: 'siblingAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'siblingBusinessIncome', label: 'Business Income' },
                                                            { name: 'siblingProfitBank', label: 'Profit in Bank' },
                                                            { name: 'siblingHouseRent', label: 'House Rent Income' },
                                                            { name: 'siblingBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'siblingShares', label: 'Shares and Securites' },
                                                            { name: 'totalSiblings', label: 'Total' }
                                                        ]
                                                            .map(fieldName =>
                                                                generateInputField(fieldName)
                                                            )}

                                                        {
                                                            form8.siblingPaySlip &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pay-slip"
                                                                    url={newURL}
                                                                    keyName="siblingPaySlip"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.siblingPension &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pension Book Slip"
                                                                    url={newURL}
                                                                    keyName="siblingPension"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.siblingAgriIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Income Certificate"
                                                                    url={newURL}
                                                                    keyName="siblingAgriIncome"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.siblingsIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Rental Agreement"
                                                                    url={newURL}
                                                                    keyName="siblingsIncome"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div className="section_break"></div>

                                        {/* SPOUSE DATA */}
                                        <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>Is your Spouse earning? <span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                value={data.spouseEarning}
                                                onChange={handleChange}
                                                name="spouseEarning"
                                                disabled
                                            />
                                        </Form.Group>

                                        {
                                            data.spouseEarning === "yes" && (
                                                <>
                                                    <div className='form_sub_heading mb-2'>
                                                        <h5 className='my-2'>Spouse Income Details</h5>
                                                    </div>

                                                    <div className="row">
                                                        {[
                                                            { name: 'spouseSalary', label: 'Salary' },
                                                            { name: 'spousePension', label: 'Pension' },
                                                            { name: 'spouseAgriIncome', label: 'Agriculture Income' },
                                                            { name: 'spouseBusinessIncome', label: 'Business Income' },
                                                            { name: 'spouseProfitBank', label: 'Profit in Bank' },
                                                            { name: 'spouseHouseRent', label: 'House Rent Income' },
                                                            { name: 'spouseBusinessRent', label: 'Property Rent Income' },
                                                            { name: 'spouseShares', label: 'Shares and Securites' },
                                                            { name: 'totalSpouse', label: 'Total' }
                                                        ].map(fieldName =>
                                                            generateInputField(fieldName)
                                                        )}

                                                        {
                                                            form8.spousePaySlip &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pay-slip"
                                                                    url={newURL}
                                                                    keyName="spousePaySlip"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.spousePension &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Pension Book Slip"
                                                                    url={newURL}
                                                                    keyName="spousePension"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.spouseAgriIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Income Certificate"
                                                                    url={newURL}
                                                                    keyName="spouseAgriIncome"
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            form8.spouseIncome &&
                                                            <div className="col-md-4 col-sm-6 col-12">
                                                                <FileViewerAdmin
                                                                    document={docs}
                                                                    id={id}
                                                                    text="Rental Agreement"
                                                                    url={newURL}
                                                                    keyName="spouseIncome"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }


                                        <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                            <Form.Label>Total</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=''
                                                name="finalTotal"
                                                value={formatter(data.finalTotal)}
                                                disabled
                                            />
                                        </Form.Group>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Requirement of Loan */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingRequirement">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRequirement" aria-expanded="false" aria-controls="collapseRequirement">
                                    <img src={FINANCIAL_APPR} alt="icon" className='img-fluid form_icons' />
                                    Requirement of Loan
                                </button>
                            </h2>
                            <div id="collapseRequirement" className="accordion-collapse collapse show" aria-labelledby="headingRequirement" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className='financial_appraisal'>

                                        {/* Financial Breakdown */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='mb-0'>For how many Semesters/Years do you need Financial Assistance?</h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' disabled onClick={(e) => Add_New_Loan_Detail(e)}><FaPlus /></button>
                                                </div>
                                            </div>

                                            {loanDetail.map((data, i) => {
                                                return <>
                                                    {
                                                        i > 0 &&
                                                        <div className="section_break"></div>
                                                    }

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId={`Semester_Year_${i}`} className='form-group'>
                                                                <Form.Label>{user.type === "semester" ? "Semester" : "Year"} <span className='required'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={data.Semester_Year}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Semester_Year')}
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId={`Total_Fee_Cost_${i}`} className='form-group'>
                                                                <Form.Label>Total Fee Cost <span className='required'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formatter(data.Total_Fee_Cost)}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Total_Fee_Cost')}
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId={`Own_Contribution_${i}`} className='form-group'>
                                                                <Form.Label>Own Contribution <span className='required'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formatter(data.Own_Contribution)}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Own_Contribution')}
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId={`Loan_Required_${i}`} className='form-group'>
                                                                <Form.Label>Loan Required <span className='required'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formatter(data.Loan_Required)}
                                                                    onChange={(e) => handleLoanDetailChange(e, i, 'Loan_Required')}
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        {/* {
                                                            i > 0 && (
                                                                <div className='d-flex jc-end'>
                                                                    <button onClick={() => removeLoanDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                                </div>
                                                            )
                                                        } */}
                                                    </div>
                                                </>
                                            })
                                            }

                                        </div>

                                        {/* Financial Assistance */}
                                        {
                                            financeAssis.length > 0 &&
                                            <div className="table_form mb-2">
                                                <>
                                                    <div className='form_sub_heading mb-4'>
                                                        <h5 className='mb-0'>Financial Assistance Previously Received (if any)</h5>
                                                        <div className='d-flex jc-end'>
                                                            <button className='btn add_btn' disabled onClick={(e) => Add_New_Finance_Detail(e)}><FaPlus /></button>
                                                        </div>
                                                    </div>

                                                    {
                                                        financeAssis.map((data, i) => {
                                                            return <>
                                                                {
                                                                    i > 0 &&
                                                                    <div className="section_break"></div>
                                                                }

                                                                <div className="row">
                                                                    {
                                                                        financeAssis[i].Name_of_Organization &&
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                                            <Form.Group controlId={`Name_of_Organization_${i}`} className='form-group'>
                                                                                <Form.Label>Name of Organization</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="Name_of_Organization"
                                                                                    value={financeAssis[i].Name_of_Organization}
                                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                                    disabled
                                                                                    required
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        financeAssis[i].Type_of_Assistance &&
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                                            <Form.Group controlId={`Type_of_Assistance_${i}`} className='form-group'>
                                                                                <Form.Label>Type of Assistance</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="Type_of_Assistance"
                                                                                    value={financeAssis[i].Type_of_Assistance}
                                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                                    disabled
                                                                                    required
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        financeAssis[i].Amount_Applied_For &&
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                                            <Form.Group controlId={`Amount_Applied_For_${i}`} className='form-group'>
                                                                                <Form.Label>Amount Applied For</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="Amount_Applied_For"
                                                                                    value={formatter(financeAssis[i].Amount_Applied_For)}
                                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                                    disabled
                                                                                    required
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        financeAssis[i].Amount_Received &&
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                                            <Form.Group controlId={`Amount_Received_${i}`} className='form-group'>
                                                                                <Form.Label>Amount Received</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="Amount_Received"
                                                                                    value={formatter(financeAssis[i].Amount_Received)}
                                                                                    onChange={(e) => handleInputChangeFA(e, i)}
                                                                                    disabled
                                                                                    required
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    }
                                                                    {/* <div className='d-flex jc-end'>
                                                            <button onClick={() => removeFinanceDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                        </div> */}
                                                                </div>
                                                            </>
                                                        })
                                                    }
                                                </>
                                            </div>
                                        }

                                        <div className="row">
                                            <div className="col-12 vertically_center">
                                                <Form.Group controlId="question31" className='form-group'>
                                                    <Form.Label>Have your general financial conditions changed in the recent years? Or are there any reasons to believe that they may change in the days to come? <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data?.q21}
                                                        onChange={(e) => handlequestion31(e)}
                                                        rows={5}
                                                        // rows={parseInt(data?.q21?.length)/parseInt(120) }
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-12 vertically_center">
                                                <Form.Group controlId="validationCustom05" className='form-group'>
                                                    <Form.Label>Are any of the family members beside father/mother/guardian supporting your educational expense at this institute? <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data?.supportingEducation}
                                                        onChange={(e) => handleEducationSupport(e)}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>

                                            {
                                                data?.supportingEducation == "yes" &&
                                                <>
                                                    {/* <Form.Label>
                                                        Please state the amount contributed and your relationship with that person.
                                                    </Form.Label> */}
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="amountByGuardian" className='form-group'>
                                                            <Form.Label>Amount Contributed Rs. <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={formatter(data.feeContributor)}
                                                                onChange={(e) => handleamountByGuardian(e)}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="amountByGuardian" className='form-group'>
                                                            <Form.Label>Relation <span className='required'>*</span></Form.Label>
                                                            <div className="d-flex ai-center">
                                                                <Form.Control
                                                                    type="text"
                                                                    value={data.relation}
                                                                    onChange={(e) => handleRelation(e)}
                                                                    disabled
                                                                    required
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group className='form-group'>
                                                            <Form.Label>Per Semester Fee <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={formatter(data.perSemFee)}
                                                                onChange={(e) => handleperSemFee(e)}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 vertically_center">
                                                        <Form.Group className='form-group'>
                                                            <Form.Label>Outstanding Fee <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={formatter(data.OutStandingFee)}
                                                                onChange={(e) => handleOutStandingFee(e)}
                                                                disabled
                                                                required
                                                            />
                                                            {/* <Form.Label className="mb-0">(provide fee summary)</Form.Label> */}
                                                        </Form.Group>
                                                    </div>
                                                </>
                                            }

                                            <div className="col-12 vertically_center">
                                                <Form.Group controlId="question32" className='form-group'>
                                                    <Form.Label>How you would be able to return the amount to IT enabling it to provide similar facilities to other applicants? <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data?.q22}
                                                        onChange={(e) => handlequestion32(e)}
                                                        rows={5}
                                                        // rows={parseInt(data?.q22?.length)/parseInt(120) }
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-12 vertically_center">
                                                <Form.Group controlId="question33" className='form-group'>
                                                    <Form.Label>What alternate arrangements have you made or can you make in case an Interest-Free Loan by Ihsan Trust is not approved? <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data?.q23}
                                                        onChange={(e) => handlequestion33(e)}
                                                        rows={5}
                                                        // rows={parseInt(data?.q23?.length)/parseInt(120) }
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Explain in detail, why are you applying for the Interest-free Loan from Ihsan trust? <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        value={data?.Comments}
                                                        onChange={handleChange}
                                                        rows={5}
                                                        // rows={parseInt(data?.Comments?.length)/parseInt(120) }
                                                        name="Comments"
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
            </div>
        </>
    )
}

export default Financial_InformationViewOnly
