import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getForm1, getUNI, getUNIProfile, GetUnisProfileForStudent, SubmissionForm1 } from '../../ContextAPI/APIs/api';
import { useAuth } from '../../ContextAPI/Components/auth';
import { Show_Profile_Pic, User_Profile_Pic } from '../Profile/upload_profilePic';
import Select from '../Form/Select_New';
import { useToast } from '../../ContextAPI/Components/toast'
import { FaPlus, FaQuestionCircle } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import { useProSidebar } from 'react-pro-sidebar';
import ImageUploadInput from '../Modals/ImageUploadInput';
import TooltipComponent from '../../components/tooltip';
import ImageModal from "../../components/Modals/ImageModal"
import { CNICUrl, DeathCertificateUrl, DomicileUrl, passimageUrl, childrens } from '../../helpers/data';
import Form from 'react-bootstrap/Form';

import PERSONAL_INFO from "../../assets/form-icons/personal-information.png"
import FAMILY_INFO from "../../assets/form-icons/family-information.png"
// import Choices from 'choices.js';
import FormErrors from "./FormErrors"
import { ValidatePhoneNumber } from '../../helpers/validatePhoneNumber';
import { ValidateNIC } from '../../helpers/validateNIC';
import PhoneInput from 'react-phone-input-2';

import InputMask from 'react-input-mask';
import formatter from '../../helpers/number_formatter';


function Demographics_Information() {

    const { alert } = useToast();
    const { user, GettabsData } = useAuth();
    const { hash } = useLocation();
    const navigate = useNavigate();

    console.log("__99", user);
    const [showForm, setShowForm] = useState("step_1");
    const [form, setForm] = useState({})

    // Family Details
    const [familyDetail, setfamilyDetail] = useState([]);

    const [siblingDetail, setSiblingDetail] = useState([]);
    const [childrenDetail, setChildrenDetail] = useState([]);


    const f_detail = {
        S_No: "",
        Name_of_Family_Member: "",
        Relationship: "",
        Age: "",
        Marital_Status: "",
        Cell_No: "",
        Occupation: "",
        School_Institute_Office: "",
    }

    function Add_New_F_Detail(e) {
        e.preventDefault();
        setfamilyDetail([...familyDetail, f_detail])
    }

    function removeDetail(i) {

        const updated = [...familyDetail]

        updated.splice(i, 1)
        setfamilyDetail(updated)

    }

    function handleInputChange(e, index, field, type) {
        if (type == "sibling") {
            const updatedFamilyDetail = [...siblingDetail];
            updatedFamilyDetail[index][field] = e.target.value;
            setSiblingDetail(updatedFamilyDetail);
        }
        if (type == "children") {
            const updatedFamilyDetail = [...childrenDetail];
            updatedFamilyDetail[index][field] = e.target.value;
            setChildrenDetail(updatedFamilyDetail);
        }
    }

    const [data, setData] = useState({
        fullName: user?.fullName || "",
        fatherName: user?.fatherName || "",
        email: user?.email || "",
        cnic: user?.cnic || "",
        DOB: "",
        maritalStatus: "",
        cellNo: user?.phone || "",
        ptcl: user?.phone2 || "",
        whatsapp: user?.phone3 || "",
        presentAddress: "",
        permanentAddress: "",
        q1: "",
        q2: "",
        totalChildrens: "",
        totalSiblings: ""
    })


    const [questions, setQuestions] = useState({
        question23: "",
        question24: "",
    })

    const getFormData = async () => {
        try {
            const response = await getForm1()
            console.log("fomr1", response);
            if (response.success) {


                console.log("ChildrenDetails", response.message?.ChildrenDetails);
                console.log("SiblingDetails", response.message?.SiblingDetails);

                console.log("DDDDDD", response.message);
                setData(response.message)
                setChildrenDetail(response.message?.ChildrenDetails)
                setSiblingDetail(response.message?.SiblingDetails)


                setFatherData(response.message?.familyDetails[0])
                setMotherData(response.message?.familyDetails[1])
                setSpouseData(response.message?.familyDetails[2])
                setGuardianData(response.message?.familyDetails[3])
                // setfamilyDetail(response.message?.otherMembers)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [fatherData, setFatherData] = useState({
        name: user?.fatherName || '',
        email: '',
        cellNo: '',
        cnic: '',
        alive: '',
        occupation: '',
        designation: '',
        organization: '',
        deathDate: '',
        employemntStatus: "",
    });

    const [motherData, setMotherData] = useState({
        name: '',
        email: '',
        cellNo: '',
        cnic: '',
        alive: '',
        occupation: '',
        designation: '',
        organization: '',
        deathDate: '',
        employemntStatus: "",
    });

    const [guardianData, setGuardianData] = useState({
        haveGuardian: false,
        name: '',
        relation: '',
        email: '',
        cellNo: '',
        cnic: '',
        occupation: '',
        designation: '',
        organization: '',
        employemntStatus: "",
    });

    const [spouseData, setSpouseData] = useState({
        name: '',
        email: '',
        cellNo: '',
        cnic: '',
        alive: '',
        occupation: '',
        designation: '',
        organization: '',
        employemntStatus: "",
    });

    const handleFatherChange = (e, field) => {
        e.preventDefault();
        setFatherData({
            ...fatherData,
            [field]: e.target.value,
        });
    };

    const handleMotherChange = (e, field) => {
        setMotherData({
            ...motherData,
            [field]: e.target.value,
        });
    };

    const handleGuardianChange = (e, field) => {
        if (field == "haveGuardian" && e.target.value == "false") {
            setGuardianData({ haveGuardian: false, name: '', relation: '', email: '', cellNo: '', cnic: '', occupation: '', designation: '', organization: '', });
        } else {
            setGuardianData({ ...guardianData, [field]: e.target.value });
        }
    };

    const handleSpouseChange = (e, field) => {
        setSpouseData({
            ...spouseData,
            [field]: e.target.value,
        });
    };


    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);

    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {

        event.preventDefault();
        // console.log("DATA", data);

        const payload = {
            ...data,
            familyDetails: [fatherData, motherData, spouseData, guardianData],
            ChildrenDetails: childrenDetail,
            SiblingDetails: siblingDetail
        }
        console.log("PPAAYYLLOOAADDD", familyDetail);

        const checkV = validation()
        if (checkV !== "proceed") {
            alert("Form Validation Error", "error")
        }
        else {
            const form = event.currentTarget;
            console.log("form.checkValidity()", form.checkValidity());
            console.log("formErrors()", formError);
            if (form.checkValidity() === false) {
                console.log("--------------------------------------------", event.target);
            }
            console.log("================================", event.target);

            const response = await SubmissionForm1(payload)
            if (response?.success) {
                GettabsData()
                alert(response?.message, "success")
                navigate(`#${"academic_background"}`)
            }
            else {
                alert(response?.message, "error")

            }
        }
    };

    const [isFatherAliveDeath, setIsFatherAliveDeath] = useState("")
    const [isMotherAliveDeath, setIsMotherAliveDeath] = useState("")
    const [isSpouseAliveDeath, setIsSpouseAliveDeath] = useState("")

    const [maritalStatus, setMaritalStatus] = useState("")

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };

    const handleChangeSiblings = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        let siblings = []
        let childrens = []
        if (data.maritalStatus === "single") {
            for (let i = 0; i < value; i++) {
                let f_detail = {
                    heading: `Sibling ${i + 1}`,
                    key: "sibling",
                    Name_of_Family_Member: "",
                    Relationship: "",
                    Age: "",
                    Marital_Status: "",
                    Cell_No: "",
                    Occupation: "",
                    School_Institute_Office: "",
                }
                siblings.push(f_detail)
            }
        }
        else {
            for (let i = 0; i < parseInt(value); i++) {
                let f_detail = {
                    heading: `Sibling ${i + 1}`,
                    key: "sibling",
                    Name_of_Family_Member: "",
                    Relationship: "",
                    Age: "",
                    Marital_Status: "",
                    Cell_No: "",
                    Occupation: "",
                    School_Institute_Office: "",
                }
                siblings.push(f_detail)
            }
            for (let i = 0; i < parseInt(data.totalChildrens); i++) {
                let f_detail = {
                    heading: `Children ${i + 1}`,
                    key: "kid",
                    Name_of_Family_Member: "",
                    Relationship: "kid",
                    Age: "",
                    Marital_Status: "",
                    Cell_No: "",
                    Occupation: "",
                    School_Institute_Office: "",
                }
                childrens.push(f_detail)
            }
        }
        setSiblingDetail(siblings)
    };

    const handleChangeChildren = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));



        let siblings = []
        let childrens = []

        for (let i = 0; i < parseInt(data.totalSiblings); i++) {
            let f_detail = {
                heading: `Sibling ${i + 1}`,
                key: "sibling",
                Name_of_Family_Member: "",
                Relationship: "",
                Age: "",
                Marital_Status: "",
                Cell_No: "",
                Occupation: "",
                School_Institute_Office: "",
            }
            siblings.push(f_detail)

        }

        for (let i = 0; i < parseInt(value); i++) {
            let f_detail = {
                heading: `Child ${i + 1}`,
                key: "kid",
                Name_of_Family_Member: "",
                Relationship: "kid",
                Age: "",
                Marital_Status: "",
                Cell_No: "",
                Occupation: "",
                School_Institute_Office: "",
            }
            childrens.push(f_detail)
        }
        setChildrenDetail(childrens)
    };


    useEffect(() => {
        getFormData()

        window.scrollTo(0, 0)
    }, [])



    let errors = {}
    const [formError, setFormError] = useState([])

    const validation = () => {
        try {
            console.log("MM", motherData);

            if (data.fullName == "") {
                errors.fullName = "Applicant's Name is required";
            }

            if (data.email == "") {
                errors.email = "Applicant’s Email Address is required";
            }

            if (data.fatherName == "") {
                errors.fatherName = "Father Name is required";
            }

            if (data.DOB == "") {
                errors.DOB = "Date of Birth is required";
            }

            if (data.cnic == "") {
                errors.cnic = "Applicant’s CNIC No is required";
            }

            if (data.maritalStatus == "") {
                errors.maritalStatus = "Marital Status is required";
            }

            if (data.cellNo == "") {
                errors.cellNo = "Applicant’s Cell No No is required";
            }
            // if (!ValidatePhoneNumber(data.cellNo)) {
            //     errors.cellNo = "Phone numbers should start with +92 and be 13 characters long.";
            // }

            if (data.presentAddress == "") {
                errors.presentAddress = "Present Address is required";
            }

            if (data.permanentAddress == "") {
                errors.permanentAddress = "Permanent Address is required";
            }

            if (data.q1 == "") {
                errors.q1 = "Personal Aspirations is required";
            }

            if (data.q2 == "") {
                errors.q2 = "Aspirations for your career is required";
            }

            // -------------------------------------------------------------------------
            // -------------------------------------------------------------------------
            // -------------------------------------------------------------------------

            if (fatherData.alive == "") {
                // errors.motherData_cnic = ("Mother CNIC Image is required")
                errors.isFatherAliveDeath = ("Specify Father's status: alive or deceased")
                // errors.isMotherAliveDeath = ("Specify Mother status: alive or deceased")
                // isMotherAliveDeath
            }

            if (fatherData.alive === "alive") {

                if (fatherData.name == "") {
                    errors.fatherData_name = ("Father's Name is required")
                }

                if (fatherData.cellNo == "") {
                    console.log("fatherData.cellNo", fatherData.cellNo);
                    errors.fatherData_cellNo = ("Father's Phone no is required")
                }
                else if (ValidatePhoneNumber((fatherData.cellNo)) == false) {
                    errors.fatherData_cellNo = "Father's Phone numbers should start with +92 and be 13 characters long.";
                }

                if (fatherData.cnic == "" || fatherData.cnic == "_____-_______-_") {
                    errors.fatherData_cnic = ("Father's CNIC no is required")
                }
                else if (!ValidateNIC(fatherData.cnic)) {
                    errors.fatherData_cnic = "Father's CNIC should be 13 characters long.";
                }

                if (fatherData.employemntStatus == "") {
                    errors.fatherData_employemntStatus = ("Father's employement status is required")
                }


                if (fatherData.employemntStatus !== "" && fatherData.employemntStatus !== "retired" && fatherData.employemntStatus !== "Unemployed") {
                    if (fatherData.occupation == "") {
                        errors.fatherData_occupation = ("Father's Occupation is required")
                    }
                    if (fatherData.designation == "") {
                        errors.fatherData_designation = ("Father's Designation is required")
                    }
                    if (fatherData.organization == "") {
                        errors.fatherData_organization = ("Father's Organization is required")
                    }
                }

            }

            if (motherData.alive == "") {
                // errors.motherData_cnic = ("Mother CNIC Image is required")
                // errors.isFatherAliveDeath = ("Specify Father status: alive or deceased")
                errors.isMotherAliveDeath = ("Specify Mother's status: alive or deceased")
                // isMotherAliveDeath
            }

            if (motherData.alive === "alive") {
                if (motherData.name == "") {
                    errors.motherData_name = ("Mother's Name is required")
                }
                if (motherData.cnic == "" || motherData.cnic == "_____-_______-_") {
                    errors.motherData_cnic = ("Mother's CNIC no is required")
                }
                else if (!ValidateNIC(motherData.cnic)) {
                    errors.motherData_cnic = "Mother's CNIC should be 13 characters long.";
                }

                if (motherData.employemntStatus == "") {
                    errors.motherData_employemntStatus = ("Mother's employement status is required")
                }

                if (motherData.employemntStatus !== "" && motherData.employemntStatus !== "retired" && motherData.employemntStatus !== "Unemployed") {
                    if (motherData.occupation == "") {
                        errors.motherData_occupation = ("Mother's Occupation is required")
                    }
                    if (motherData.designation == "") {
                        errors.motherData_designation = ("Mother's Designation is required")
                    }
                    if (motherData.organization == "") {
                        errors.motherData_organization = ("Mother's Organization is required")
                    }
                }
            }

            if (guardianData.haveGuardian == "true") {
                if (guardianData.name == "") {
                    errors.guardianData_name = ("Guardian Name is required")
                }
                if (guardianData.relation == "") {
                    errors.guardianData_relation = ("Guardian Relation is required")
                }
                if (guardianData.cellNo == "") {
                    errors.guardian_cell = ("Guardian cell no is required")
                }
                if (guardianData.cnic == "" || guardianData.cnic == "_____-_______-_") {
                    errors.guardianData_cnic = ("Guardian CNIC no is required")
                }
                else if (!ValidateNIC(guardianData.cnic)) {
                    errors.guardianData_cnic = "Guardian CNIC should be 13 characters long.";
                }

                if (guardianData.employemntStatus == "") {
                    errors.guardianData_employemntStatus = ("Guardian employement status is required")
                }

                if (guardianData.employemntStatus !== "" && guardianData.employemntStatus !== "retired" && guardianData.employemntStatus !== "Unemployed") {
                    if (guardianData.occupation == "") {
                        errors.guardianData_occupation = ("Guardian's Occupation is required")
                    }
                    if (guardianData.designation == "") {
                        errors.guardianData_designation = ("Guardian's Designation is required")
                    }
                    if (guardianData.organization == "") {
                        errors.guardianData_organization = ("Guardian's Organization is required")
                    }
                }
            }

            // || data.maritalStatus !== "divorced"

            if (data.maritalStatus !== "" && data.maritalStatus === "married") {
                console.log("data.maritalStatus", data.maritalStatus);
                if (spouseData.name == "") {
                    console.log(spouseData.name);
                    errors.spouseData_name = ("Spouse's Name is required")
                }
                if (spouseData.cnic == "") {
                    errors.spouseData_cnic = ("Spouse's CNIC Image is required")
                }
                else if (!ValidateNIC(spouseData.cnic)) {
                    errors.spouseData_cnic = "Spouse's CNIC should be 13 characters long.";
                }
                // if (spouseData.alive == "") {
                //     errors.isSpouseAliveDeath = ("Specify Spouse's status: alive or deceased")
                // }  

                if (spouseData.employemntStatus == "") {
                    errors.spouseData_employemntStatus = ("Spouse employement status is required")
                }

                if (spouseData.employemntStatus !== "" && spouseData.employemntStatus !== "retired" && spouseData.employemntStatus !== "Unemployed") {
                    if (spouseData.occupation == "") {
                        errors.spouseData_occupation = ("Spouse's Occupation is required")
                    }
                    if (spouseData.designation == "") {
                        errors.spouseData_designation = ("Spouse's Designation is required")
                    }
                    if (spouseData.organization == "") {
                        errors.spouseData_organization = ("Spouse's Organization is required")
                    }
                }

            }

            if (data.totalSiblings == "") {
                errors.totalSiblings = ("Specify Number of Siblings")
            }

            console.log("data.totalSiblings", data.totalSiblings);
            console.log("siblingDetail.Name_of_Family_Member", siblingDetail.Name_of_Family_Member);
            console.log("siblingDetail.Relationship", siblingDetail.Relationship);
            console.log("siblingDetail.Age", siblingDetail.Age);
            console.log("siblingDetail.Marital_Status", siblingDetail.Marital_Status);

            if (parseFloat(data.totalSiblings) > 0) {
                if (siblingDetail.length > 0) {
                    for (let i = 0; i < siblingDetail.length; i++) {
                        console.log("---------------------------------");
                        console.log("---------------------------------");
                        console.log("---------------------------------");
                        console.log("---------------------------------");
                        if (siblingDetail[i].Name_of_Family_Member == "" || siblingDetail[i].Name_of_Family_Member == undefined) {
                            errors.Name_of_Family_Member = ("Name of sibling is required")
                        }
                        if (siblingDetail[i].Relationship == "" || siblingDetail[i].Relationship == undefined) {
                            errors.Relationship = ("Sibling relationship is required")
                        }
                        if (siblingDetail[i].Age == "" || siblingDetail[i].Age == undefined) {
                            errors.Age = ("Sibling age is required")
                        }
                        if (siblingDetail[i].Marital_Status == "" || siblingDetail[i].Marital_Status == undefined) {
                            errors.Marital_Status = ("Sibling marital status is required")
                        }
                    }
                }
            }

            if (data.maritalStatus !== "" && data.maritalStatus !== "single") {
                if (data.totalChildrens === "") {
                    errors.totalChildrens = "Specify Number of Childrens";
                }
            }

            if (parseFloat(data.totalChildrens) > 0) {
                if (childrenDetail.length > 0) {
                    for (let i = 0; i < childrenDetail.length; i++) {
                        console.log("---------------------------------");
                        console.log("---------------------------------");
                        console.log("---------------------------------");
                        console.log("---------------------------------");
                        if (childrenDetail[i].Name_of_Family_Member == "" || childrenDetail[i].Name_of_Family_Member == undefined) {
                            errors.Name_of_Child = ("Name of child is required")
                        }
                        if (childrenDetail[i].Age == "" || childrenDetail[i].Age == undefined) {
                            errors.Age_of_Child = ("Child age is required")
                        }
                        if (childrenDetail[i].Marital_Status == "" || childrenDetail[i].Marital_Status == undefined) {
                            errors.Marital_Status_of_Child = ("Child marital status is required")
                        }
                    }
                }
            }


            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }
            // else {
            //     return "proceed"
            // }

        }
        catch (e) {
            console.log(e);
        }
    }

    function IsError(id) {
        return !(Object.keys(formError)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formError)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }

    const handleFamilyCell = (value, i, type) => {

        if (type == "sibling") {
            const updatedFamilyDetail = [...siblingDetail];
            updatedFamilyDetail[i] = { ...updatedFamilyDetail[i], Cell_No: value };
            setSiblingDetail(updatedFamilyDetail);
        }
        if (type == "children") {
            const updatedFamilyDetail = [...childrenDetail];
            updatedFamilyDetail[i] = { ...updatedFamilyDetail[i], Cell_No: value };
            setChildrenDetail(updatedFamilyDetail);
        }
    };


    const handleFatherCell = (value) => {
        setFatherData({ ...fatherData, cellNo: value })
    }

    const handleMotherCell = (value) => {
        setMotherData({ ...motherData, cellNo: value })
    }

    const handleGuardianCell = (value) => {
        setGuardianData({ ...guardianData, cellNo: value })
    }


    const handleSpouseCell = (value) => {
        setSpouseData({ ...spouseData, cellNo: value })
    }



    const today = new Date().toISOString().split('T')[0];

    const today1 = new Date();
    const fifteenYearsAgo = new Date(today1.getFullYear() - 15, today1.getMonth(), today1.getDate());
    const fifteenYearsAgoISOString = fifteenYearsAgo.toISOString().split('T')[0];

    console.log(fifteenYearsAgoISOString);

    return (
        <>
            <div className="">
                {
                    (Object.values(formError)).length > 0 && (
                        <FormErrors errorList={formError} />
                    )
                }

                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        {/* Personal Information */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <img src={PERSONAL_INFO} alt="icon" className='img-fluid form_icons' />
                                    Personal Information
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className={`accordion-collapse collapse ${(Object.values(formError)).length > 0 ? "show" : "show"}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">

                                    <div className="row">

                                        {/* PROFILE IMAGE FOR MOBILE */}
                                        <div className="col-md-2 col-sm-12 mob_profile">
                                            <Form.Group controlId="validationCustomUsername" className='form-group'>
                                                <Form.Label>Profile Pic <span className='required'>*</span></Form.Label>
                                                <User_Profile_Pic />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-5 col-sm-6 col-12 vertically_center vertically_center_name_email">
                                            <Form.Group controlId="validationCustom01" id='fullName' className='form-group'>
                                                <Form.Label>Name <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First name"
                                                    name="fullName"
                                                    value={data.fullName}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                    className={`${(data.fullName == "" && "error") || (data.fullName != "" && "success")}`}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-5 col-sm-6 col-12 vertically_center vertically_center_name_email">
                                            <Form.Group controlId="validationCustomUsername" id='email' className='form-group'>
                                                <Form.Label>Email Address <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter here"
                                                    name="email"
                                                    value={data?.email}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                    className={`${(data.email == "" && "error") || (data.email != "" && "success")}`}
                                                />
                                            </Form.Group>
                                        </div>

                                        {/* PROFILE IMAGE FOR DESKTOP */}
                                        <div className="col-md-2 col-sm-12 desk_profile">
                                            <Form.Group controlId="validationCustomUsername" className='form-group'>
                                                <Form.Label>Profile Pic <span className='required'>*</span></Form.Label>
                                                <User_Profile_Pic />
                                            </Form.Group>
                                        </div>

                                        {/* <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                            <ImageUploadInput
                                                multiple={true}
                                                name="passportImage"
                                                text="Two Recent Passport Size Photographs"
                                                span="*"
                                                url={passimageUrl}
                                                itemkey="passimage"
                                                accept=".jpg, .jpeg, .png"
                                                required={true}
                                            />
                                        </div> */}

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom02" id='fatherName' className='form-group'>
                                                <Form.Label>Father’s / Guardian Name <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter here"
                                                    name="fatherName"
                                                    value={data.fatherName}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                    className={`${(data.fatherName == "" && "error") || (data.fatherName != "" && "success")}`}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom03" id='DOB' className='form-group'>
                                                <Form.Label>Date of Birth <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="DOB"
                                                    max={fifteenYearsAgoISOString}
                                                    value={data.DOB ? new Date(data.DOB).toISOString().split('T')[0] : ''}
                                                    onChange={handleChange}
                                                    required
                                                    className={`${(formError.length == 0 ? "" : IsError("DOB") ? "success" : "error")}`}
                                                />
                                                {
                                                    (ShowError("DOB"))[0] &&
                                                    <p className='error_label'>{(ShowError("DOB"))[1]}</p>
                                                }

                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom04" id='cnic' className='form-group'>
                                                <Form.Label>CNIC No. / B-Form No. <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter here'
                                                    name="cnic"
                                                    value={data.cnic}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                    className={`${(data.cnic == "" && "error") || (data.cnic !== "" && "success")}`}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <ImageUploadInput
                                                multiple={true}
                                                name="selfCnic"
                                                text="Self CNIC / B-Form"
                                                span="*"
                                                url={CNICUrl}
                                                itemkey="selfCnic"
                                                accept=".jpg, .jpeg, .png"
                                                required={true}
                                                tooltip={true}
                                                tooltipText="Please upload CNIC front and back side image"
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <ImageUploadInput
                                                multiple={true}
                                                name="domicile"
                                                text="Domicile Certificate"
                                                url={DomicileUrl}
                                                itemkey="Domicile"
                                                accept=".jpg, .jpeg, .png"
                                            />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom06" id='cellNo' className='form-group'>
                                                <Form.Label>Mobile No 1 <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    placeholder=''
                                                    name="cellNo"
                                                    value={data.cellNo}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                    className={`${(data.cellNo == "" && "error") || (data.cellNo !== "" && "success")}`}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom07" id='ptcl' className='form-group'>
                                                <Form.Label>Mobile No 2</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder=''
                                                    name="ptcl"
                                                    value={data.ptcl}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                                <div className='d-flex'>
                                                    <Form.Label>Whatsapp No.</Form.Label>
                                                    <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Example +921234567890">
                                                        <div><MdInfoOutline className='hint-icon mb-2 ml-1' /></div>
                                                    </TooltipComponent>
                                                </div>
                                                <Form.Control
                                                    type="number"
                                                    placeholder=''
                                                    name="whatsapp"
                                                    value={data.whatsapp}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom05" id='maritalStatus' className='form-group'>
                                                <Form.Label>Marital Status <span className='required'>*</span></Form.Label>
                                                <Form.Select
                                                    className={`${(formError.length == 0 ? "" : IsError("maritalStatus") ? "success" : "error")}`}
                                                    value={data.maritalStatus} onChange={handleChange} aria-label="Default select example" name="maritalStatus"
                                                >
                                                    <option selected disabled value="">Select</option>
                                                    <option value="single">Single</option>
                                                    <option value="married">Married</option>
                                                    <option value="widow">Widow</option>
                                                    <option value="widower">Widower</option>
                                                    <option value="divorced">Divorced</option>
                                                </Form.Select>
                                                {
                                                    (ShowError("maritalStatus"))[0] &&
                                                    <p className='error_label'>{(ShowError("maritalStatus"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>

                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom09" id='presentAddress' className='form-group'>
                                                <Form.Label>Present Address <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter here'
                                                    name="presentAddress"
                                                    value={data.presentAddress}
                                                    onChange={handleChange}
                                                    required
                                                    className={`${(formError.length == 0 ? "" : IsError("presentAddress") ? "success" : "error")}`}
                                                />
                                                {
                                                    (ShowError("presentAddress"))[0] &&
                                                    <p className='error_label'>{(ShowError("presentAddress"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>

                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom09" id='permanentAddress' className='form-group'>
                                                <Form.Label>Permanent Address <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter here'
                                                    name="permanentAddress"
                                                    value={data.permanentAddress}
                                                    onChange={handleChange}
                                                    required
                                                    className={`${(formError.length == 0 ? "" : IsError("permanentAddress") ? "success" : "error")}`}
                                                />
                                                {
                                                    (ShowError("permanentAddress"))[0] &&
                                                    <p className='error_label'>{(ShowError("permanentAddress"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>

                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom09" id='q1' className='form-group'>
                                                <Form.Label>What are your long term personal aspirations? <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder='Type here...'
                                                    name="q1"
                                                    value={data.q1}
                                                    onChange={handleChange}
                                                    required
                                                    className={`${(formError.length == 0 ? "" : IsError("q1") ? "success" : "error")}`}
                                                />
                                                {
                                                    (ShowError("q1"))[0] &&
                                                    <p className='error_label'>{(ShowError("q1"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>

                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom10" id='q2' className='form-group'>
                                                <Form.Label>What are your aspirations for your career? <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder='Type here...'
                                                    name="q2"
                                                    value={data.q2}
                                                    onChange={handleChange}
                                                    required
                                                    className={`${(formError.length == 0 ? "" : IsError("q2") ? "success" : "error")}`}
                                                />
                                                {
                                                    (ShowError("q2"))[0] &&
                                                    <p className='error_label'>{(ShowError("q2"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>
                                        
                                        {/* Repeat similar blocks for other input fields */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Family Information */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <img src={FAMILY_INFO} alt="icon" className='img-fluid form_icons' />
                                    Family Information
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className={`accordion-collapse collapse ${(Object.values(formError)).length > 0 && "show"}`} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    {/* FATHER'S DATA */}
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom05" id='isFatherAliveDeath' className='form-group'>
                                                <Form.Label>Is Father Alive / Deceased <span className='required'>*</span></Form.Label>
                                                <Form.Select
                                                    className={`${(formError.length == 0 ? "" : IsError("isFatherAliveDeath") ? "success" : "error")}`}
                                                    value={fatherData?.alive}
                                                    onChange={(e) => {
                                                        handleFatherChange(e, 'alive')
                                                        setIsFatherAliveDeath(e.target.value)
                                                    }}
                                                    required>
                                                    <option selected disabled value={""}>Select</option>
                                                    <option value="alive">Alive</option>
                                                    <option value="deceased">Deceased</option>
                                                </Form.Select>
                                                {
                                                    (ShowError("isFatherAliveDeath"))[0] &&
                                                    <p className='error_label'>{(ShowError("isFatherAliveDeath"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>

                                        {
                                            (fatherData?.alive !== "") &&
                                            <div className="col-12">
                                                <div className='mb-3 form_sub_heading'>
                                                    <h5 className='mb-0'>Father Details</h5>
                                                </div>
                                            </div>
                                        }

                                        {
                                            fatherData?.alive === "alive" &&
                                            <>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='fatherData_name' className='form-group'>
                                                        <Form.Label>Father’s Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter here"
                                                            value={fatherData?.name || ""}
                                                            disabled
                                                            onChange={(e) => handleFatherChange(e, 'name')}
                                                            className={`${formError.length == 0 ? "" : IsError("fatherData_name") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("fatherData_name"))[0] &&
                                                            <p className='error_label'>{(ShowError("fatherData_name"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='father_email' className='form-group'>
                                                        <Form.Label>Father’s Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter here"
                                                            value={fatherData?.email || ""}
                                                            onChange={(e) => handleFatherChange(e, 'email')}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='fatherData_cellNo' className='form-group'>
                                                        <Form.Label>Father’s Cell No. <span className='required'>*</span></Form.Label>
                                                        <PhoneInput
                                                            value={fatherData?.cellNo || "+92"}
                                                            onChange={handleFatherCell}
                                                            inputProps={{
                                                                required: true,
                                                            }}
                                                            className={`${formError.length == 0 ? "" : IsError("fatherData_cellNo") ? "success" : "error"}`}
                                                            placeholder='+92 ___ _______'
                                                            disableDropdown={true}
                                                        />
                                                        {
                                                            (ShowError("fatherData_cellNo"))[0] &&
                                                            <p className='error_label'>{(ShowError("fatherData_cellNo"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='fatherData_cnic' className='form-group'>
                                                        <Form.Label>Father’s CNIC No. <span className='required'>*</span></Form.Label>
                                                        {/* <Form.Control
                                                    type="number"
                                                    placeholder="Enter here"
                                                    value={fatherData?.cnic || ""}
                                                    onChange={(e) => handleFatherChange(e, 'cnic')}
                                                    required
                                                    className={`${formError.length == 0 ? "" : IsError("fatherData_cnic") ? "success" : "error"}`}
                                                /> */}
                                                        <InputMask
                                                            mask="99999-9999999-9"
                                                            value={fatherData?.cnic || ""}
                                                            onChange={(e) => handleFatherChange(e, 'cnic')}
                                                            name='cnic'
                                                            maskChar="_"
                                                            className={`form-control ${formError.length == 0 ? "" : IsError("fatherData_cnic") ? "success" : "error"}`}
                                                            placeholder="Enter here"
                                                        />
                                                        {
                                                            (ShowError("fatherData_cnic"))[0] &&
                                                            <p className='error_label'>{(ShowError("fatherData_cnic"))[1]}</p>
                                                        }

                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="cnicFather"
                                                        text="Father’s CNIC"
                                                        span="*"
                                                        url={CNICUrl}
                                                        itemkey="fatherCnic"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='fatherData_employemntStatus' className='form-group'>
                                                        <Form.Label>Father Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Select
                                                            className={`${(formError.length == 0 ? "" : IsError("fatherData_employemntStatus") ? "success" : "error")}`}
                                                            name='employemntStatus'
                                                            value={fatherData?.employemntStatus}
                                                            onChange={(e) => handleFatherChange(e, 'employemntStatus')}
                                                            required
                                                        >
                                                            <option selected disabled value={""}>Select</option>
                                                            <option value="employed">Employed</option>
                                                            <option value="self-employed">Self-employed</option>
                                                            <option value="Unemployed">Unemployed</option>
                                                            <option value="retired">Retired</option>
                                                        </Form.Select>
                                                        {
                                                            (ShowError("fatherData_employemntStatus"))[0] &&
                                                            <p className='error_label'>{(ShowError("fatherData_employemntStatus"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                {
                                                    (fatherData.employemntStatus !== "" && fatherData.employemntStatus !== "retired" && fatherData.employemntStatus !== "Unemployed") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='fatherData_occupation' className='form-group'>
                                                                    <Form.Label>Father’s Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={fatherData?.occupation || ""}
                                                                        onChange={(e) => handleFatherChange(e, 'occupation')}
                                                                        required
                                                                        className={`${formError.length == 0 ? "" : IsError("fatherData_occupation") ? "success" : "error"}`}
                                                                    />
                                                                    {
                                                                        (ShowError("fatherData_occupation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("fatherData_occupation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='fatherData_designation' className='form-group'>
                                                                    <Form.Label>Father’s Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={fatherData?.designation || ""}
                                                                        onChange={(e) => handleFatherChange(e, 'designation')}
                                                                        className={`${formError.length == 0 ? "" : IsError("fatherData_designation") ? "success" : "error"}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("fatherData_designation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("fatherData_designation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='fatherData_organization' className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={fatherData?.organization || ""}
                                                                        onChange={(e) => handleFatherChange(e, 'organization')}
                                                                        className={`${formError.length == 0 ? "" : IsError("fatherData_organization") ? "success" : "error"}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("fatherData_organization"))[0] &&
                                                                        <p className='error_label'>{(ShowError("fatherData_organization"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        }

                                        {
                                            (fatherData?.alive === "deceased") &&
                                            <>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="DeathCertificateFather"
                                                        text="Death certificate"
                                                        url={DeathCertificateUrl}
                                                        itemkey="DeathCertificateFather"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={false}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom03" id='DOB' className='form-group'>
                                                        <Form.Label>Date of Death </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            max={today}
                                                            name="deathDate"
                                                            value={fatherData?.deathDate}
                                                            // value={data.DOB ? new Date(data.DOB).toISOString().split('T')[0] : ''}
                                                            onChange={(e) => handleFatherChange(e, 'deathDate')}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className="section_break"></div>

                                    {/* MOTHER'S DATA */}
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom05" id='isMotherAliveDeath' className='form-group'>
                                                <Form.Label>Is Mother Alive / Deceased <span className='required'>*</span></Form.Label>
                                                <Form.Select
                                                    className={`${(formError.length == 0 ? "" : IsError("isMotherAliveDeath") ? "success" : "error")}`}
                                                    value={motherData.alive} onChange={(e) => {
                                                        handleMotherChange(e, 'alive')
                                                        setIsMotherAliveDeath(e.target.value)
                                                    }} required>
                                                    <option selected disabled value={""}>Select</option>
                                                    <option value="alive">Alive</option>
                                                    <option value="deceased">Deceased</option>
                                                </Form.Select>
                                                {
                                                    (ShowError("isMotherAliveDeath"))[0] &&
                                                    <p className='error_label'>{(ShowError("isMotherAliveDeath"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>

                                        {
                                            (motherData?.alive !== "") &&
                                            <div className="col-12">
                                                <div className='mb-3 form_sub_heading'>
                                                    <h5 className='mb-0'>Mother Details</h5>
                                                </div>
                                            </div>
                                        }

                                        {
                                            motherData.alive === "alive" &&
                                            <>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='motherData_name' className='form-group'>
                                                        <Form.Label>Mother's Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter here"
                                                            value={motherData.name}
                                                            onChange={(e) => handleMotherChange(e, 'name')}
                                                            required
                                                            className={formError.length == 0 ? "" : IsError("motherData_name") ? "success" : "error"}
                                                        />
                                                        {
                                                            (ShowError("motherData_name"))[0] &&
                                                            <p className='error_label'>{(ShowError("motherData_name"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='mother_email' className='form-group'>
                                                        <Form.Label>Mother's Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter here"
                                                            value={motherData.email}
                                                            onChange={(e) => handleMotherChange(e, 'email')}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='mother_cell' className='form-group'>
                                                        <Form.Label>Mother's Cell No.</Form.Label>
                                                        <PhoneInput
                                                            value={motherData.cellNo || "+92"}
                                                            // onChange={(e) => handleMotherChange(e, 'cellNo')}
                                                            onChange={handleMotherCell}
                                                            inputProps={{
                                                                required: false,
                                                            }}
                                                            placeholder='+92 ___ _______'
                                                            disableDropdown={true}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='motherData_cnic' className='form-group'>
                                                        <Form.Label>Mother's CNIC No. <span className='required'>*</span></Form.Label>
                                                        <InputMask
                                                            mask="99999-9999999-9"
                                                            value={motherData.cnic}
                                                            onChange={(e) => handleMotherChange(e, 'cnic')}
                                                            name='cnic'
                                                            maskChar="_"
                                                            type="text"
                                                            className={`form-control ${formError.length == 0 ? "" : IsError("motherData_cnic") ? "success" : "error"}`}
                                                            placeholder="Enter here"
                                                        />
                                                        {
                                                            (ShowError("motherData_cnic"))[0] &&
                                                            <p className='error_label'>{(ShowError("motherData_cnic"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="cnicMother"
                                                        text="Mother's CNIC"
                                                        span="*"
                                                        url={CNICUrl}
                                                        itemkey="motherCnic"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='motherData_employemntStatus' className='form-group'>
                                                        <Form.Label>Mother Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Select
                                                            className={`${(formError.length == 0 ? "" : IsError("motherData_employemntStatus") ? "success" : "error")}`}
                                                            name='employemntStatus'
                                                            value={motherData?.employemntStatus || ""}
                                                            onChange={(e) => handleMotherChange(e, 'employemntStatus')}
                                                            required>
                                                            <option selected disabled value={""}>Select</option>
                                                            <option value="employed">Employed</option>
                                                            <option value="self-employed">Self-employed</option>
                                                            <option value="Unemployed">Unemployed</option>
                                                            <option value="retired">Retired</option>
                                                            {/* <option value="house wife">house wife</option> */}
                                                        </Form.Select>
                                                        {
                                                            (ShowError("motherData_employemntStatus"))[0] &&
                                                            <p className='error_label'>{(ShowError("motherData_employemntStatus"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>

                                                {
                                                    (motherData.employemntStatus !== "" && motherData.employemntStatus !== "retired" && motherData.employemntStatus !== "Unemployed") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='motherData_occupation' className='form-group'>
                                                                    <Form.Label>Mother's Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='mother_occupation'
                                                                        placeholder="Enter here"
                                                                        value={motherData?.occupation || ""}
                                                                        onChange={(e) => handleMotherChange(e, 'occupation')}
                                                                        required
                                                                        className={`${formError.length == 0 ? "" : IsError("motherData_occupation") ? "success" : "error"}`}
                                                                    />
                                                                    {
                                                                        (ShowError("motherData_occupation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("motherData_occupation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='motherData_designation' className='form-group'>
                                                                    <Form.Label>Mother's Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={motherData.designation}
                                                                        onChange={(e) => handleMotherChange(e, 'designation')}
                                                                        className={`${formError.length == 0 ? "" : IsError("motherData_designation") ? "success" : "error"}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("motherData_designation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("motherData_designation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='motherData_organization' className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={motherData.organization}
                                                                        onChange={(e) => handleMotherChange(e, 'organization')}
                                                                        className={`${formError.length == 0 ? "" : IsError("motherData_organization") ? "success" : "error"}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("motherData_organization"))[0] &&
                                                                        <p className='error_label'>{(ShowError("motherData_organization"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )

                                                }
                                            </>
                                        }

                                        {
                                            (motherData.alive === "deceased") &&
                                            <>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="DeathCertificateMother"
                                                        text="Death certificate"
                                                        url={DeathCertificateUrl}
                                                        itemkey="DeathCertificateMother"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={false}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom03" id='DOB' className='form-group'>
                                                        <Form.Label>Date of Death </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="deathDate"
                                                            max={today}
                                                            value={motherData.deathDate}
                                                            onChange={(e) => handleMotherChange(e, 'deathDate')}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className="section_break"></div>

                                    {/* GUARDIAN DATA */}
                                    <div className="row">

                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom01" id='haveGuardian' className='form-group'>
                                                <Form.Label>Do you have any other Guardian besides your mother and father? <span className='required'>*</span></Form.Label>
                                                <Form.Select
                                                    className='form-select'
                                                    value={guardianData.haveGuardian}
                                                    onChange={(e) => handleGuardianChange(e, 'haveGuardian')}
                                                >
                                                    <option selected disabled value={""}>Select Yes or No</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Form.Select>
                                                {
                                                    (ShowError("haveGuardian"))[0] &&
                                                    <p className='error_label'>{(ShowError("haveGuardian"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>

                                        {guardianData.haveGuardian == "true" &&
                                            <>
                                                <div className="col-12">
                                                    <div className='mb-3 form_sub_heading'>
                                                        <h5 className='mb-0'>Guardian Details</h5>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardianData_name' className='form-group'>
                                                        <Form.Label>Guardian Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter here"
                                                            value={guardianData.name}
                                                            onChange={(e) => handleGuardianChange(e, 'name')}
                                                            required
                                                            className={formError.length == 0 ? "" : IsError("guardianData_name") ? "success" : "error"}
                                                        />
                                                        {
                                                            (ShowError("guardianData_name"))[0] &&
                                                            <p className='error_label'>{(ShowError("guardianData_name"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardianData_relation' className='form-group'>
                                                        <Form.Label>Relation <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter here"
                                                            value={guardianData.relation}
                                                            onChange={(e) => handleGuardianChange(e, 'relation')}
                                                            required
                                                            className={formError.length == 0 ? "" : IsError("guardianData_relation") ? "success" : "error"}
                                                        />
                                                        {
                                                            (ShowError("guardianData_relation"))[0] &&
                                                            <p className='error_label'>{(ShowError("guardianData_relation"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardian_email' className='form-group'>
                                                        <Form.Label>Guardian Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter here"
                                                            value={guardianData.email}
                                                            onChange={(e) => handleGuardianChange(e, 'email')}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardian_cell' className='form-group'>
                                                        <Form.Label>Guardian Cell No. <span className='required'>*</span></Form.Label>
                                                        <PhoneInput
                                                            value={guardianData.cellNo || "+92"}
                                                            // onChange={(e) => handleMotherChange(e, 'cellNo')}
                                                            onChange={handleGuardianCell}
                                                            inputProps={{
                                                                required: false,
                                                            }}
                                                            placeholder='+92 ___ _______'
                                                            disableDropdown={true}
                                                            className={`${formError.length == 0 ? "" : IsError("guardian_cell") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("guardian_cell"))[0] &&
                                                            <p className='error_label'>{(ShowError("guardian_cell"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardianData_cnic' className='form-group'>
                                                        <Form.Label>Guardian CNIC No. <span className='required'>*</span></Form.Label>
                                                        <InputMask
                                                            mask="99999-9999999-9"
                                                            value={guardianData.cnic}
                                                            onChange={(e) => handleGuardianChange(e, 'cnic')}
                                                            name='cnic'
                                                            maskChar="_"
                                                            type="text"
                                                            className={`form-control ${formError.length == 0 ? "" : IsError("guardianData_cnic") ? "success" : "error"}`}
                                                            placeholder="Enter here"
                                                        />
                                                        {
                                                            (ShowError("guardianData_cnic"))[0] &&
                                                            <p className='error_label'>{(ShowError("guardianData_cnic"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="guardianCnic"
                                                        text="Guardian CNIC"
                                                        span="*"
                                                        url={CNICUrl}
                                                        itemkey="cnicGuardian"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='guardianData_employemntStatus' className='form-group'>
                                                        <Form.Label>Guardian Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Select
                                                            className={`${(formError.length == 0 ? "" : IsError("guardianData_employemntStatus") ? "success" : "error")}`}
                                                            value={guardianData?.employemntStatus || ""}
                                                            onChange={(e) => handleGuardianChange(e, 'employemntStatus')}
                                                            required>
                                                            <option selected disabled value={""}>Select</option>
                                                            <option value="employed">Employed</option>
                                                            <option value="self-employed">Self-employed</option>
                                                            <option value="Unemployed">Unemployed</option>
                                                            <option value="retired">Retired</option>
                                                        </Form.Select>
                                                        {
                                                            (ShowError("guardianData_employemntStatus"))[0] &&
                                                            <p className='error_label'>{(ShowError("guardianData_employemntStatus"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                {
                                                    (guardianData?.employemntStatus !== "" && guardianData?.employemntStatus !== "retired" && guardianData?.employemntStatus !== "Unemployed") && (
                                                        <>

                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='guardianData_occupation' className='form-group'>
                                                                    <Form.Label>Guardian Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={guardianData.occupation}
                                                                        onChange={(e) => handleGuardianChange(e, 'occupation')}
                                                                        className={`${(formError.length == 0 ? "" : IsError("guardianData_occupation") ? "success" : "error")}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("guardianData_occupation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("guardianData_occupation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>

                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='guardianData_designation' className='form-group'>
                                                                    <Form.Label>Guardian Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={guardianData.designation}
                                                                        onChange={(e) => handleGuardianChange(e, 'designation')}
                                                                        className={`${(formError.length == 0 ? "" : IsError("guardianData_designation") ? "success" : "error")}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("guardianData_designation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("guardianData_designation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='guardianData_organization' className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={guardianData.organization}
                                                                        onChange={(e) => handleGuardianChange(e, 'organization')}
                                                                        className={`${(formError.length == 0 ? "" : IsError("guardianData_organization") ? "success" : "error")}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("guardianData_organization"))[0] &&
                                                                        <p className='error_label'>{(ShowError("guardianData_organization"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        }
                                    </div>


                                    {/* SPOUSE DATA */}
                                    {
                                        (data.maritalStatus !== "" && data.maritalStatus === "married") &&
                                        <>
                                            <div className="section_break"></div>

                                            <div className="col-12">
                                                <div className='mb-3 form_sub_heading'>
                                                    <h5 className='mb-0'>Spouse Details</h5>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='spouseData_name' className='form-group'>
                                                        <Form.Label>Spouse Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter here"
                                                            value={spouseData.name}
                                                            onChange={(e) => handleSpouseChange(e, 'name')}
                                                            required
                                                            className={formError.length == 0 ? "" : IsError("spouseData_name") ? "success" : "error"}
                                                        />
                                                        {
                                                            (ShowError("spouseData_name"))[0] &&
                                                            <p className="error_label">{(ShowError("spouseData_name"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='spouse_email' className='form-group'>
                                                        <Form.Label>Spouse Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter here"
                                                            value={spouseData.email}
                                                            onChange={(e) => handleSpouseChange(e, 'email')}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='spouse_cell' className='form-group'>
                                                        <Form.Label>Spouse Cell No.</Form.Label>
                                                        <PhoneInput
                                                            value={spouseData.cellNo || "+92"}
                                                            // onChange={(e) => handleSpouseChange(e, 'cellNo')}
                                                            onChange={handleSpouseCell}
                                                            inputProps={{
                                                                required: false,
                                                            }}
                                                            placeholder='+92 ___ _______'
                                                            disableDropdown={true}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='spouseData_cnic' className='form-group'>
                                                        <Form.Label>Spouse CNIC No. <span className='required'>*</span></Form.Label>
                                                        <InputMask
                                                            mask="99999-9999999-9"
                                                            value={spouseData.cnic}
                                                            onChange={(e) => handleSpouseChange(e, 'cnic')}
                                                            name='cnic'
                                                            maskChar="_"
                                                            type="text"
                                                            className={`form-control ${formError.length == 0 ? "" : IsError("spouseData_cnic") ? "success" : "error"}`}
                                                            placeholder="Enter here"
                                                        />
                                                        {
                                                            (ShowError("spouseData_cnic"))[0] &&
                                                            <p className="error_label">{(ShowError("spouseData_cnic"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="cnicSpouse"
                                                        text="Spouse CNIC Image"
                                                        span="*"
                                                        url={CNICUrl}
                                                        itemkey="spouseCnic"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={true}
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='spouseData_employemntStatus' className='form-group'>
                                                        <Form.Label>Spouse Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Select
                                                            className={`${(formError.length == 0 ? "" : IsError("spouseData_employemntStatus") ? "success" : "error")}`}
                                                            value={spouseData?.employemntStatus || ""}
                                                            onChange={(e) => handleSpouseChange(e, 'employemntStatus')}
                                                            required>
                                                            <option selected disabled value={""}>Select</option>
                                                            <option value="employed">Employed</option>
                                                            <option value="self-employed">Self-employed</option>
                                                            <option value="Unemployed">Unemployed</option>
                                                            <option value="retired">Retired</option>
                                                        </Form.Select>
                                                        {
                                                            (ShowError("spouseData_employemntStatus"))[0] &&
                                                            <p className='error_label'>{(ShowError("spouseData_employemntStatus"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                {
                                                    (spouseData?.employemntStatus !== "" && spouseData?.employemntStatus !== "retired" && spouseData?.employemntStatus !== "Unemployed") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='spouseData_occupation' className='form-group'>
                                                                    <Form.Label>Spouse Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={spouseData.occupation}
                                                                        onChange={(e) => handleSpouseChange(e, 'occupation')}
                                                                        className={`${(formError.length == 0 ? "" : IsError("spouseData_occupation") ? "success" : "error")}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("spouseData_occupation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("spouseData_occupation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>

                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='spouseData_designation' className='form-group'>
                                                                    <Form.Label>Spouse Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={spouseData.designation}
                                                                        onChange={(e) => handleSpouseChange(e, 'designation')}
                                                                        className={`form-control ${formError.length == 0 ? "" : IsError("spouseData_designation") ? "success" : "error"}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("spouseData_designation"))[0] &&
                                                                        <p className='error_label'>{(ShowError("spouseData_designation"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='spouseData_organization' className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter here"
                                                                        value={spouseData.organization}
                                                                        onChange={(e) => handleSpouseChange(e, 'organization')}
                                                                        className={`form-control ${formError.length == 0 ? "" : IsError("spouseData_organization") ? "success" : "error"}`}
                                                                        required
                                                                    />
                                                                    {
                                                                        (ShowError("spouseData_organization"))[0] &&
                                                                        <p className='error_label'>{(ShowError("spouseData_organization"))[1]}</p>
                                                                    }
                                                                </Form.Group>
                                                            </div>

                                                        </>
                                                    )
                                                }

                                            </div>
                                        </>
                                    }

                                    {/* {
                                        (isFatherAliveDeath == "deceased" || isMotherAliveDeath == "deceased" || isSpouseAliveDeath == "deceased") &&
                                        < div className="row">
                                            <div className="col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="DeathCertificate"
                                                    text="Death certificate (if / whenever applicable) of parents, siblings & spouse"
                                                    span="*"
                                                    url={DeathCertificateUrl}
                                                    itemkey="DeathCertificate"
                                                    accept=".jpg, .jpeg, .png"
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    } */}

                                    {/* Family Members Details */}
                                    <div className="table_form">
                                        <div className='mb-4 form_sub_heading'>
                                            <h5 className='mb-0'>Family Member's Details: <br /> Sisters, Brothers (Married, Unmarried, Widow, Widower and Divorced) and Applicant's Children</h5>
                                            {/* <button className='btn add_btn' onClick={(e) => Add_New_F_Detail(e)}>Add More Member</button> */}
                                            {/* <button className='btn add_btn' onClick={(e) => Add_New_F_Detail(e)}><FaPlus /></button> */}
                                        </div>
                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom01" id='totalSiblings' className='form-group'>
                                                <Form.Label>Number of Siblings <span className='required'>*</span></Form.Label>
                                                <Form.Select
                                                    className={`form-select ${formError.length == 0 ? "" : IsError("totalSiblings") ? "success" : "error"}`}
                                                    value={data.totalSiblings}
                                                    name="totalSiblings"
                                                    onChange={handleChangeSiblings}
                                                    required
                                                >
                                                    <option selected disabled value={""}>Select Number of Siblings</option>
                                                    {
                                                        childrens.map((item) => (
                                                            <option value={item}>{item}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {
                                                    (ShowError("totalSiblings"))[0] &&
                                                    <p className='error_label'>{(ShowError("totalSiblings"))[1]}</p>
                                                }
                                            </Form.Group>
                                        </div>


                                        {/* siblingDetail,childrenDetail */}
                                        {siblingDetail.map((data, i) => (
                                            <>
                                                {
                                                    i > 0 &&
                                                    <div className="section_break"></div>
                                                }

                                                <div className="row">
                                                    <p>{siblingDetail[i].heading}</p>
                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Name_of_Family_Member' className='form-group'>
                                                            <Form.Label>Name of Sibling <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter here"
                                                                value={siblingDetail[i].Name_of_Family_Member}
                                                                onChange={(e) => handleInputChange(e, i, 'Name_of_Family_Member', "sibling")}
                                                                className={`form-control ${formError.length == 0 ? "" : IsError("Name_of_Family_Member") ? "success" : "error"}`}
                                                                required
                                                            />
                                                            {
                                                                (ShowError("Name_of_Family_Member"))[0] &&
                                                                <p className='error_label'>{(ShowError("Name_of_Family_Member"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Relationship' className='form-group'>
                                                            <Form.Label>Relationship <span className='required'>*</span></Form.Label>
                                                            <Form.Select
                                                                value={siblingDetail[i].Relationship}
                                                                onChange={(e) => handleInputChange(e, i, 'Relationship', "sibling")}
                                                                className={`form-select ${formError.length == 0 ? "" : IsError("Relationship") ? "success" : "error"}`}
                                                                required
                                                            >
                                                                <option selected disabled value="">Select</option>
                                                                <option value="sister">Sister</option>
                                                                <option value="brother">Brother</option>
                                                                {/* <option value="kid">Kid</option> */}
                                                            </Form.Select>
                                                            {
                                                                (ShowError("Relationship"))[0] &&
                                                                <p className='error_label'>{(ShowError("Relationship"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Age' className='form-group'>
                                                            <Form.Label>Age <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Enter here"
                                                                value={siblingDetail[i].Age}
                                                                onChange={(e) => handleInputChange(e, i, 'Age', "sibling")}
                                                                className={`form-control ${formError.length == 0 ? "" : IsError("Age") ? "success" : "error"}`}
                                                                required
                                                            />
                                                            {
                                                                (ShowError("Age"))[0] &&
                                                                <p className='error_label'>{(ShowError("Age"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='cnic' className='form-group'>
                                                            <Form.Label>CNIC No. / B-Form No.</Form.Label>
                                                            {/* <Form.Control
                                                                type="number"
                                                                placeholder="Enter here"
                                                                value={siblingDetail[i].cnic}
                                                                onChange={(e) => handleInputChange(e, i, 'cnic', "sibling")}
                                                            /> */}
                                                            <InputMask
                                                                mask="99999-9999999-9"
                                                                value={siblingDetail[i].cnic}
                                                                onChange={(e) => handleInputChange(e, i, 'cnic', "sibling")}
                                                                name='cnic'
                                                                maskChar="_"
                                                                type="text"
                                                                className={`form-control`}
                                                                placeholder="Enter here"
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom05" id='Marital_Status' className='form-group'>
                                                            <Form.Label>Marital Status <span className='required'>*</span></Form.Label>
                                                            <Form.Select
                                                                value={siblingDetail[i].Marital_Status}
                                                                onChange={(e) => handleInputChange(e, i, 'Marital_Status', "sibling")}
                                                                className={`form-select ${formError.length == 0 ? "" : IsError("Marital_Status") ? "success" : "error"}`}
                                                                aria-label="Default select example"
                                                                required
                                                            >
                                                                <option selected disabled value="">Select</option>
                                                                <option value="single">Single</option>
                                                                <option value="married">Married</option>
                                                                <option value="widow">Widow</option>
                                                                <option value="widower">Widower</option>
                                                                <option value="divorced">Divorced</option>
                                                            </Form.Select>
                                                            {
                                                                (ShowError("Marital_Status"))[0] &&
                                                                <p className='error_label'>{(ShowError("Marital_Status"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Cell_No' className='form-group'>
                                                            <Form.Label>Cell No</Form.Label>
                                                            <PhoneInput
                                                                value={siblingDetail[i].Cell_No || "+92"}
                                                                onChange={(value) => handleFamilyCell(value, i, "sibling")}
                                                                inputProps={{
                                                                    required: false,
                                                                }}
                                                                placeholder='+92 ___ _______'
                                                                disableDropdown={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Occupation' className='form-group'>
                                                            <Form.Label>Occupation</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter here"
                                                                value={siblingDetail[i].Occupation}
                                                                onChange={(e) => handleInputChange(e, i, 'Occupation', "sibling")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='School_Institute_Office' className='form-group'>
                                                            <Form.Label>School/Institute/Office</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter here"
                                                                value={siblingDetail[i].School_Institute_Office}
                                                                onChange={(e) => handleInputChange(e, i, 'School_Institute_Office', "sibling")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {/* {
                                                        i > 0 &&
                                                        <div className='d-flex jc-end'>
                                                            <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                        </div>
                                                    } */}
                                                </div>
                                            </>
                                        ))}

                                        {/* <div className="section_break"></div> */}

                                        {
                                            (data.maritalStatus !== "single" && data.maritalStatus !== "") && (
                                                <div className="col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='totalChildrens' className='form-group'>
                                                        <Form.Label>Number of Children <span className='required'>*</span></Form.Label>
                                                        <Form.Select
                                                            className={`form-select ${formError.length == 0 ? "" : IsError("totalChildrens") ? "success" : "error"}`}
                                                            value={data.totalChildrens}
                                                            name="totalChildrens"
                                                            onChange={handleChangeChildren}
                                                        >
                                                            <option selected disabled value={""}>Select Number of Childrens</option>
                                                            {
                                                                childrens.map((item) => (
                                                                    <option value={item}>{item}</option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                        {
                                                            (ShowError("totalChildrens"))[0] &&
                                                            <p className='error_label'>{(ShowError("totalChildrens"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                            )
                                        }

                                        {childrenDetail.map((data, i) => (
                                            <>
                                                {
                                                    i > 0 &&
                                                    <div className="section_break"></div>
                                                }

                                                <div className="row">
                                                    <p>{childrenDetail[i].heading}</p>
                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Name_of_Child' className='form-group'>
                                                            <Form.Label>Name of Child <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter here"
                                                                value={childrenDetail[i].Name_of_Family_Member}
                                                                onChange={(e) => handleInputChange(e, i, 'Name_of_Family_Member', "children")}
                                                                className={`form-control ${formError.length == 0 ? "" : IsError("Name_of_Child") ? "success" : "error"}`}
                                                                required
                                                            />
                                                            {
                                                                (ShowError("Name_of_Child"))[0] &&
                                                                <p className='error_label'>{(ShowError("Name_of_Child"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Age_of_Child' className='form-group'>
                                                            <Form.Label>Age <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Enter here"
                                                                value={childrenDetail[i].Age}
                                                                onChange={(e) => handleInputChange(e, i, 'Age', "children")}
                                                                className={`form-control ${formError.length == 0 ? "" : IsError("Age_of_Child") ? "success" : "error"}`}
                                                                required
                                                            />
                                                            {
                                                                (ShowError("Age_of_Child"))[0] &&
                                                                <p className='error_label'>{(ShowError("Age_of_Child"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='cnic' className='form-group'>
                                                            <Form.Label>CNIC No. / B-Form No.</Form.Label>
                                                            {/* <Form.Control
                                                                type="number"
                                                                placeholder="Enter here"
                                                                value={childrenDetail[i].cnic}
                                                                onChange={(e) => handleInputChange(e, i, 'cnic', "children")}
                                                            /> */}

                                                            <InputMask
                                                                mask="99999-9999999-9"
                                                                value={childrenDetail[i].cnic}
                                                                onChange={(e) => handleInputChange(e, i, 'cnic', "children")}
                                                                name='cnic'
                                                                maskChar="_"
                                                                type="text"
                                                                className={`form-control`}
                                                                placeholder="Enter here"
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom05" id='Marital_Status_of_Child' className='form-group'>
                                                            <Form.Label>Marital Status <span className='required'>*</span></Form.Label>
                                                            <Form.Select
                                                                value={childrenDetail[i].Marital_Status}
                                                                onChange={(e) => handleInputChange(e, i, 'Marital_Status', "children")}
                                                                className={`form-select ${formError.length == 0 ? "" : IsError("Marital_Status_of_Child") ? "success" : "error"}`}
                                                                aria-label="Default select example"
                                                                required
                                                            >
                                                                <option selected disabled value="">Select</option>
                                                                <option value="single">Single</option>
                                                                <option value="married">Married</option>
                                                                <option value="widow">Widow</option>
                                                                <option value="widower">Widower</option>
                                                                <option value="divorced">Divorced</option>
                                                            </Form.Select>
                                                            {
                                                                (ShowError("Marital_Status_of_Child"))[0] &&
                                                                <p className='error_label'>{(ShowError("Marital_Status_of_Child"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Cell_No' className='form-group'>
                                                            <Form.Label>Cell No</Form.Label>
                                                            <PhoneInput
                                                                value={childrenDetail[i].Cell_No || "+92"}
                                                                onChange={(value) => handleFamilyCell(value, i, "children")}
                                                                inputProps={{
                                                                    required: false,
                                                                }}
                                                                placeholder='+92 ___ _______'
                                                                disableDropdown={true}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Occupation' className='form-group'>
                                                            <Form.Label>Occupation</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter here"
                                                                value={childrenDetail[i].Occupation}
                                                                onChange={(e) => handleInputChange(e, i, 'Occupation', "children")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='School_Institute_Office' className='form-group'>
                                                            <Form.Label>School/Institute/Office</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter here"
                                                                value={childrenDetail[i].School_Institute_Office}
                                                                onChange={(e) => handleInputChange(e, i, 'School_Institute_Office', "children")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {/* {
                                                        i > 0 &&
                                                        <div className='d-flex jc-end'>
                                                            <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                        </div>
                                                    } */}
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex jc-end mt-4'>
                            <button className='btn next_btn' type="submit">Save and Next</button>
                        </div>

                    </Form>

                </div >

            </div >
        </>
    )
}


export default Demographics_Information
