import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../Form/Input";
import { UpdatePassword } from "../../ContextAPI/APIs/api";
import { useAuth } from "../../ContextAPI/Components/auth";
import { useToast } from "../../ContextAPI/Components/toast";
import { useConfirm } from '../../ContextAPI/Components/confirm';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";



export function Update_Password() {
    const { alert } = useToast();
    const { Ask } = useConfirm()

    const navigate = useNavigate()
    const { user, GetLoginUser } = useAuth()

    // Update Password
    const [pass, setPass] = useState("");
    const [newpass, setNewPass] = useState("");
    const [confpass, setConfPass] = useState("");

    async function updatePassword(e) {
        e.preventDefault();
        if (await Ask() == false) return

        if (newpass !== confpass) return alert("Passwords not match", false)
        const res = await UpdatePassword({
            oldpass: pass,
            password: newpass
        })
        if (res.success) {
            alert(res.message, res.success)

            // user.role == "university" ? navigate("/university-profile") : navigate("/profile")

            if (user.role == "university") {
                navigate("/university-profile")
            }
            if (user.role == "student") {
                navigate("/std-profile")
            }
            else {
                navigate("/profile")
            }


            GetLoginUser()
        } else {
            alert(res.message, res.success)
        }
    }

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const togglePasswordVisibility3 = () => {
        setShowPassword3(!showPassword3);
    };


    return <section>
        <div className="password">
            <h4>Update Password</h4>
            <form onSubmit={(e) => updatePassword(e)}>
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-12">
                        <label className="form-label">Current Password</label>
                        <div class="input-group mb-3">
                            <input onChange={(e) => setPass(e.target.value)} value={pass} type={showPassword1 ? 'text' : 'password'} className="form-control" placeholder="Enter Current Password" required />
                            <span className="input-group-text password_visibility" onClick={togglePasswordVisibility1}>{showPassword1 ? <MdVisibilityOff /> : <MdVisibility />}</span>
                        </div>
                        {/* <div className='form-group'>
                            <Input label="Current Password" inputType="password" placeholder="Enter Current Password" value={pass} onChange={(e) => setPass(e.target.value)} required />
                        </div> */}
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                        <label className="form-label">New Password</label>
                        <div class="input-group mb-3">
                            <input onChange={(e) => setNewPass(e.target.value)} value={newpass} type={showPassword2 ? 'text' : 'password'} className="form-control" placeholder="Enter New Password" required />
                            <span className="input-group-text password_visibility" onClick={togglePasswordVisibility2}>{showPassword2 ? <MdVisibilityOff /> : <MdVisibility />}</span>
                        </div>
                        {/* <div className='form-group'>
                            <Input label="New Password" inputType="password" placeholder="Enter New Password" value={newpass} onChange={(e) => setNewPass(e.target.value)} required />
                        </div> */}
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                        <label className="form-label">Confirm Password</label>
                        <div class="input-group mb-3">
                            <input onChange={(e) => setConfPass(e.target.value)} value={confpass} type={showPassword3 ? 'text' : 'password'} className="form-control" placeholder="Enter Confirm Password" required />
                            <span className="input-group-text password_visibility" onClick={togglePasswordVisibility3}>{showPassword3 ? <MdVisibilityOff /> : <MdVisibility />}</span>
                        </div>
                        {/* <div className='form-group'>
                            <Input label="Confirm Password" inputType="password" placeholder="Enter Confirm Password" value={confpass} onChange={(e) => setConfPass(e.target.value)} required />
                        </div> */}
                    </div>
                    <div className="col-12">
                        <div className='d-flex jc-end mt-4'>
                            <button className='btn save_btn'>Update Password</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>

}