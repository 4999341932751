import { FaEdit, FaPlus } from 'react-icons/fa';

// ----------------------------------------------------------------

import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUNIProfile, createMOU, getMOU, updateMOU } from '../../ContextAPI/APIs/api'
import { Show_Profile_Pic } from './upload_profilePic';

import Input from "../Form/Input"
import Textarea from "../Form/Textarea"
import { useToast } from '../../ContextAPI/Components/toast';
import Tabs from '../tabs';

import { Button, Modal, Form } from 'react-bootstrap'
import { MdOutlineDisabledByDefault } from 'react-icons/md';
import { useAuth } from '../../ContextAPI/Components/auth';
import { IoArrowBack } from 'react-icons/io5';

export function UniversityProfileView() {
    const { user } = useAuth();
    const { alert } = useToast()
    const tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "MOU",
            pathName: "#university_mou"
        },
    ]

    const [show, setShow] = useState("");
    const { hash } = useLocation();

    useEffect(() => {
        console.log((hash.split("#"))[1] || "university_info");
        setShow((hash.split("#"))[1] || "university_info")
    }, [hash])


    const { id } = useParams();
    const navigate = useNavigate();

    const [sUNI, setsUNI] = useState({
        profilepic: null,
        fullName: null,
        email: null,
        shortName: null,
        types: null,
    })
    const [campuses, setCampuses] = useState([])
    const [programs, setPrograms] = useState([])


    const getAllData = async (id) => {
        try {
            const response = await getUNIProfile({ uniId: id })
            console.log("REPO", response);

            if (response.success == true && response.message != null) {
                setsUNI(response?.message)
                setCampuses(response?.message?.campuses || [])
                setPrograms(response?.message?.programs || [])
            } else {
                alert("invalid university", "error")
                navigate("/universities-list")
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [MOU, setMOU] = useState({
        MOU_Date: "",
        expiry: "",
        total: "",
        freshLimit: "",
        renewalLimit: "",
        freshConsumed: "",
        RenewalConsumed: "",
        isNotApproved: false,
        notApprovedReason: "",
    })
    const [expiry, setExpiry] = useState(0)
    const [MOUdis, setMOUdis] = useState(false)
    const [mouTXT, setMouTXT] = useState("")
    const GetMOU = async () => {
        try {

            console.log("CODE CHLGYAAA");

            const response = await getMOU(id)

            console.log("REPO", response);

            if (response.success == true && (response.message).length > 0) {

                const abc = new Date(response?.message[0]?.expiry)
                console.log("abc1111", response?.message[0]?.expiry);
                // console.log("abc", response.message.expiry);

                const time = abc.getTime();
                // console.log("abc", abc);
                console.log("TT11111", time);

                setExpiry(time)

                if (time < Date.now()) {
                    setMOUdis(true)
                    setMouTXT("MOU Expired")
                }
                else {
                    setMOUdis(false)
                    setMOU(response.message[0])
                }

            }
            else {
                setMOUdis(true)
                setMouTXT("MOU Not Found")
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getAllData(id)
        GetMOU()

    }, [])



    return <>
        <section className='uni_profile_section'>


            <div className='d-flex ai-center mb-4'>
                {/* <Link to="/all-users/universities-list">
                    <IoArrowBack className='back_arrow_icon me-3' />
                </Link> */}
                <h2 className='mb-0'>{sUNI.fullName}</h2>
            </div>


            {
                MOUdis && (
                    <div className='mou_Expired mb-3'>
                        <h5 className='my-2'>{mouTXT}</h5>
                    </div>
                )
            }

            <div className="card uni_profile">

                <Tabs tabLinks={tabs} show={show} setShow={setShow} />

                {
                    show == "university_info" &&
                    <>
                        <div className='d-flex jc-between ai-center mb-4'>
                            <div className='d-flex ai-center'>
                                <Show_Profile_Pic src={sUNI.profilepic} /> &nbsp;
                                {/* <h2 className='mb-0 ml-2'>{sUNI.fullName}</h2> */}
                            </div>
                        </div>
                        <div className="university_info">

                            <div className='d-flex ai-center jc-between mb-3 upd_uni_profile'>
                                <h4 className='mb-0'>University Information</h4>
                                {
                                    (user?.role == "admin" || user?.role == "operations") && (
                                        <div className='d-flex jc-end'>
                                            <button className='btn save_btn' onClick={() => navigate(`/update-university-profile/${id}`)}>Update Profile <FaEdit className='edit_icon ml-2' /></button>
                                        </div>
                                    )
                                }
                            </div>

                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Input label="Name" inputType="text" placeholder="Enter University Name" value={sUNI.fullName} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Input label="Email" inputType="email" placeholder="Enter University Email" class="text-transform-none" value={sUNI.email} disabled={true} />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <Input style={{ textTransform: "upperCase" }} label="Short Name" value={sUNI.shortName} inputType="text" disabled={true} />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <Input label="Institute Types" value={sUNI.types} disabled={true} />
                                        </div>
                                    </div> */}

                                    <div className='d-flex ai-center jc-between my-3'>
                                        <h4 className='mb-0'>Campuses</h4>
                                    </div>
                                    <div className="col-md-12">
                                        {
                                            campuses.map((c, i) => {
                                                return <div className='row'>
                                                    <div className="col-md-6 col-sm-6 col-12 mt-1">
                                                        <div className="form-group">
                                                            <Input label="Name" inputType="text" key={i} value={c.name} disabled style={{ textTransform: "capitalize" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-12 mt-1">
                                                        <div className="form-group">
                                                            <Input label="Address" inputType="text" key={i} value={c.address} disabled style={{ textTransform: "capitalize" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {
                                            campuses.length < 1 && (
                                                <h5 className='not_found_msg'>No Records Found</h5>
                                            )
                                        }
                                    </div>

                                    <div className='d-flex ai-center jc-between my-3'>
                                        <h4 className='mb-0'>Programs</h4>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className='row'>
                                                {
                                                    programs.map((p, i) => {
                                                        return <div className="col-md-6 col-sm-6 col-12 mt-1">
                                                            <div className='form-group'>
                                                                <Input key={i} value={p.name} label={false} disabled style={{ textTransform: "capitalize" }} />
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                                {
                                                    programs.length < 1 && (
                                                        <h5 className='not_found_msg'>No Records Found</h5>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                }

                {
                    show == "university_mou" &&
                    <div className="university_mou">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4 className='mb-3'>University MOU</h4>
                            {
                                (user?.role == "admin" || user?.role == "operations") && (
                                    expiry > Date.now() ?
                                        <Update GetMOU={GetMOU} />
                                        :
                                        <Create GetMOU={GetMOU} />
                                )
                            }

                        </div>

                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <Input label="Initial MOU Date" placeholder="---" value={MOU?.MOU_Date} inputType="date" disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <Input label="Expiry Date" placeholder="---" value={MOU?.expiry} inputType="date" disabled={true} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className='form-group'>
                                        <Input label="Total Limit" placeholder="---" value={MOU?.total} inputType="number" disabled={true} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <Input label="Fresh Cases Limit" placeholder="---" value={MOU?.freshLimit} inputType="number" disabled={true} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <Input label="Fresh Cases Consumed" placeholder="---" value={MOU?.freshConsumed} inputType="number" disabled={true} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <Input label="Renewal Cases Limit" placeholder="---" value={MOU?.renewalLimit} inputType="text" disabled={true} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='form-group'>
                                        <Input label="Renewal Cases Consumed" placeholder="---" value={MOU?.RenewalConsumed} inputType="text" disabled={true} />
                                    </div>
                                </div>
                                {
                                    MOU?.isNotApproved == true &&
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <Input label="Not Approved Reason" placeholder="---" value={MOU?.notApprovedReason} disabled={true} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                }
            </div>

        </section>
    </>
}

function Create(props) {
    const { id } = useParams();
    const { alert } = useToast();
    const [show, setShow] = useState(false);

    const [data, setData] = useState({
        MOU_Date: "",
        expiry: "",
        total: 0,
        freshLimit: 0,
        renewalLimit: 0,
        isNotApproved: false,
        notApprovedReason: "",
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submit = async (e) => {
        e.preventDefault();

        // props.GetMOU()
        // return



        console.log(data);

        const res = await createMOU({ ...data, uniID: id })

        alert(res.message, res.success);
        props.GetMOU()

        if (res.success) {
            handleClose()
        }

    }



    const handleChangeLimits = (e, key) => {
        try {
            if (key === "freshLimit") {
                const totalamount = parseInt(data.renewalLimit) + parseInt(e.target.value)
                setData({ ...data, freshLimit: e.target.value, total: totalamount })
            }
            if (key === "renewalLimit") {
                const totalamount = parseInt(data.freshLimit) + parseInt(e.target.value)
                setData({ ...data, renewalLimit: e.target.value, total: totalamount })
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Button variant="primary" className='save_btn' onClick={handleShow}> Create MOU </Button>

            <Modal size='lg' show={show} centered onHide={handleClose}>
                <form onSubmit={(e) => submit(e)}>

                    <Modal.Header closeButton>
                        <Modal.Title className='text-white'>Create MOU</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Input inputType="date" label="Initial MOU Date" value={data.MOU_Date} onChange={(e) => setData({ ...data, MOU_Date: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Input inputType="date" label="Expiry Date" value={data.expiry} onChange={(e) => setData({ ...data, expiry: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input inputType="number" label="Fresh Cases Limit" value={data.freshLimit}
                                        onChange={(e) => handleChangeLimits(e, "freshLimit")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input inputType="number" label="Renewal Cases Limit" value={data.renewalLimit}
                                        onChange={(e) => handleChangeLimits(e, "renewalLimit")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input inputType="number" disabled label="Total Limit" value={data.total} onChange={(e) => setData({ ...data, total: e.target.value })} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        class="form-check-input"
                                        checked={data.isNotApproved}
                                        onClick={() => setData({ ...data, isNotApproved: !data.isNotApproved, notApprovedReason: "" })}
                                    />
                                    <label class="form-label" for="flexCheckDefault">
                                        Is not Approved?
                                    </label>
                                </div>
                            </div>
                            {
                                data.isNotApproved &&
                                <div className="col-12">
                                    <div className="form-group">
                                        <Textarea label="Not Approved Reason?" value={data.notApprovedReason} onChange={(e) => setData({ ...data, notApprovedReason: e.target.value })} />
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='btn close_btn' onClick={handleClose}> Close </Button>
                        <button class="btn save_btn" type='submit'> Save </button>
                    </Modal.Footer>

                </form>
            </Modal>
        </>
    );
}




function Update(props) {
    const { id } = useParams();
    const { alert } = useToast();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({
        total: 0,
        freshLimit: 0,
        renewalLimit: 0,
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submit = async (e) => {
        e.preventDefault();

        console.log(data);

        const res = await updateMOU({ ...data, uniID: id })

        alert(res.message, res.success);
        props.GetMOU()
        if (res.success) {
            handleClose()
        }

    }


    const handleChangeLimits = (e, key) => {
        try {
            if (key === "freshLimit") {
                const totalamount = parseInt(data.renewalLimit) + parseInt(e.target.value)
                setData({ ...data, freshLimit: e.target.value, total: totalamount })
            }
            if (key === "renewalLimit") {
                const totalamount = parseInt(data.freshLimit) + parseInt(e.target.value)
                setData({ ...data, renewalLimit: e.target.value, total: totalamount })
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    return (
        <>
            <Button className='btn save_btn' onClick={handleShow}> Update MOU </Button>

            <Modal size='lg' show={show} onHide={handleClose} centered>
                <form onSubmit={(e) => submit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-white'>Update MOU</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input inputType="number" label="Fresh Cases Limit" value={data.freshLimit}
                                        onChange={(e) => handleChangeLimits(e, "freshLimit")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input inputType="number" label="Renewal Cases Limit" value={data.renewalLimit}
                                        onChange={(e) => handleChangeLimits(e, "renewalLimit")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input inputType="number" disabled label="Total Limit" value={data.total} onChange={(e) => setData({ ...data, total: e.target.value })} />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btn delete_btn mb-0" onClick={handleClose}> Close </button>
                        <button className="btn save_btn" type='submit'> Create </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}