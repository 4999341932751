import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useProSidebar } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useToast } from '../../ContextAPI/Components/toast';
import { useConfirm } from '../../ContextAPI/Components/confirm';
import { FaHistory } from 'react-icons/fa';
import { MdOutlineAccessTime, MdOutlineMessage } from 'react-icons/md';
import { getFreshCaseDetail, updateApplicationStatus, getLogs, isMOUExpired, getInterviewSheetForSTD, getApprovalSheetForSTD, updateLogs } from '../../ContextAPI/APIs/api';
import Navbar from '../../components/Navbar'
import NavSidebar from '../../components/Sidebar'
import Input from '../../components/Form/Input';
import UserModal from '../../components/Modals/Modal';
import ScoringChart from '../../components/Charts/ScoringChart';
import { Badge } from 'react-bootstrap';
import Badges from '../../components/Badges';
import Tabs from '../../components/tabs';
import { useMediaQuery } from 'react-responsive';
import { useFreshCase } from "../../ContextAPI/Components/freshCase"


function CasesHistory({ getData1 }) {

    const { id, doc } = useParams()
    const { user } = useAuth()
    const { alert } = useToast()
    const { Ask } = useConfirm()

    const [action, setAction] = useState("")
    const [reason, setReason] = useState("")
    const [date123, setDate123] = useState("")


    const today = new Date().toISOString().split('T')[0];
    const currentDateTime = new Date().toISOString().slice(0, 16);


    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setAction("")
        setReason("")
        setDate123("")
    };


    const [showDetail, setShowDetail] = useState("ShowFlow")

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    const handleChange = (e) => {
        setReason(e.target.value)
    }

    const actionAdmin = async (e) => {
        e.preventDefault();

        try {
            if (await Ask() == false) return

            let payload = {
                Id: id,
                action: action,
                date: Date.now(),
                InterviewDate: date123,
                nextReview: date123,
                reason: reason,
                document: data._id,
                programId: data.programId
            }

            console.log("PPPAAAAA", payload);
            // return

            const response = await updateApplicationStatus(payload)
            if (response.success) {
                alert(response.message, response.success)
                getData()
                getData1()
                getHistoryLogs()
                setShow(false)
                setAction("")
                setReason("")
                setDate123("")
            }
            else {
                alert(response.message, response.success)
            }
            setShow(false)

        }
        catch (e) {
            console.log(e);
        }
    }

    const [data, setData] = useState({
    })
    const [app, setApp] = useState({})
    const getData = async () => {
        try {
            const response = await getFreshCaseDetail({ _id: doc })
            console.log("REEESS", response.message);
            if (response.success) {
                setData({
                    ...data,
                    _id: response?.message?._id,
                    name: response?.message?.userId?.fullName,
                    email: response?.message?.userId?.email,
                    cnic: response?.message?.form1?.cnic,
                    cell: response?.message?.userId?.phone,
                    fCell: response?.message?.form1?.familyDetails[0]?.cellNo,
                    mCell: response?.message?.form1?.familyDetails[1]?.cellNo,
                    uni: response?.message?.university?.fullName,
                    program: response?.message?.program?.name,
                    programId: response?.message?.program?._id,
                    semester: response?.message?.form2?.totalSem,
                    appSem: response?.message?.form2?.currentSem,
                    type: response?.message?.form2?.ExamStructure
                })
                setApp(response.message)
                checkMouExpiry(response?.message?.university?._id)
            }

            console.log("DATA", response.message);
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getData()
    }, [])

    function timestampToDate(timestamp) {
        // Create a new Date object with the timestamp
        const date = new Date(timestamp);

        // Extract the components of the date
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Construct the formatted date string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

        return formattedDate;
    }

    const interviewReasons = [
        "Interview has been Scheduled",
        "Interview has been Rescheduled",
    ]

    const interviewConducted = [
        "Interview done and case is still in process",
    ]

    const approveReason = [
        "Case has been Approved",
        "Conditional approval if Student will get GPA 3.0 in current semester then fee will be disbursed"
    ]

    const cancelReason = [
        "Not shown up for the Interview",
        "Cancelled due to Low GPA/CGPA",
        "Cancelled as Wrongly applied",
        "Reject by University",
        "Refused by Student ",
        "Refused by Father of the Student",
        "Student Rusticated from University",
        "Cancelled as Student hasn't not got admission in University",
        "Cancelled By Ihsan Trust Management ",
        "Cancelled as Student not Interested",
        "Cancelled by Student after Interview",
        "Cancelled by Student at the time of the Interview as not willing to pay repayments",
        "Cancelled by Student after Approval",
        "Cancelled as Case already Approved; only disbursement required",
        "Cancelled as Case already Approved; but hold due to Student's missed Repayments",
        "Cancelled as Student is not interested in Interest Free Loan",
        "Cancelled as already fee paid",
        "Cancelled as Course clossed by University",
        "Cancelled as got Scholarship/ Financial Assistance",
    ];

    const disbursementReason = [
        "Fees has been Disbursed"
    ]

    const holdReason = [
        "Waiting for Fee Voucher/Fee Ledger",
        "Waiting for Results",
        "Not picking Calls & replying emails ",
        "Waiting for Agreement Waiting for Agreement, Guarantee of Loan & Collateral Cheque",
        "Waiting for Agreement Waiting for Agreement, Guarantee of Loan & Collateral Cheque",
        "Waiting for Agreement, Guarantee of Loan & Postdated Cheques",
        "Waiting for Guarantee of Loan",
        "Waiting for Collateral Cheque",
        "Waiting for Postdated Cheques ",
        "Waiting for 02nd Tranche Agreement",
        "Waiting for Renewal Agreement, Guarantee of Loan & Collateral Cheque",
        "Waiting for Renewal Agreement, Guarantee of Loan & Postdated Cheques",
        "Hold due to Student's missed Repayment",
        "Hold due to Tranche hasn't disbursed",
        "Hold due to missed Repayment of the Student's sibling ",
        "Hold Student willing to re-apply in next semester "
    ]
    const notShownUpReason = [
        "Not shown up for the Interview"
    ]



    const reasonClick = (val) => {
        setReason(val)
    }

    const [logs, setLogs] = useState([])
    const getHistoryLogs = async () => {
        try {
            const response = await getLogs({
                id: id,
                type: "fresh",
                document: doc
            })
            console.log("LOGS", response);
            if (response.success && response.message !== null) {
                setLogs(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [isMou, setMou] = useState(false)
    const [mouTXT, setMouTXT] = useState("")

    const checkMouExpiry = async (uni) => {
        try {
            const payload = { id: uni }
            const response = await isMOUExpired(payload)
            console.log("RESPONSEMOU", response.message);
            if (response.success) {
                setMou(true)
                setMouTXT(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const getInterviewSheet = async () => {
        try {
            const response = await getInterviewSheetForSTD({
                id: id,
                type: "fresh",
                documentIdFresh: doc
            })

            const response2 = await getApprovalSheetForSTD({
                id: id,
                type: "fresh",
                documentIdFresh: doc
            })

            console.log("response||||||||", response2);

            if (response.success && response.message !== null) {
                setOpen(true)
            }

            if (response2.success && response2.message !== null) {
                setOpen1(true)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getHistoryLogs()
        getInterviewSheet()
    }, [])


    const updateLogssss = async (logid, status) => {
        try {
            if (await Ask() == false) return

            let payload = {
                Id: id,
                logId: logid,
                action: status,
                date: Date.now(),
                InterviewDate: date123,
                nextReview: date123,
                reason: reason,
                document: data._id,
                type: "fresh"
            }

            console.log("PPPAAAAA", payload);
            // return

            const response = await updateLogs(payload)
            if (response.success) {
                alert(response.message, response.success)
                getHistoryLogs()
                setShow(false)
                setAction("")
                setReason("")
                setDate123("")
            }
            else {
                alert(response.message, response.success)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function timestampToDatetimeLocal(timestamp, type) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        if (type == "approve") {
            const dateInputString = `${year}-${month}-${day}`;
            return dateInputString;
        }
        if (type == "interview") {
            const datetimeLocalString = `${year}-${month}-${day}T${hours}:${minutes}`;
            return datetimeLocalString;
        }


    }

    const handleShow = (modalItemValue) => {
        // , reason, nextReview, interview, status
        setShow(true);
        setModalItem(modalItemValue)
        console.log("modalItemValue", modalItemValue);



        // setReason(reason)
        // if (status == "approved") {
        //     setDate123(convertTimestamp(nextReview))
        // }
        // if (status == "interview") {
        //     setDate123(convertTimestamp(interview))
        // }


    };


    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const tabs = [
        {
            tabName: "Interview Sheet",
            pathName: "#"
        },
        {
            tabName: "Recommendation Form",
            pathName: "#"
        },
        {
            tabName: "Approval Form",
            pathName: "#"
        },
    ]

    const Mob_tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "Education",
            pathName: "#education-details"
        },
        {
            tabName: "Password",
            pathName: "#password"
        }
    ]

    const navigate = useNavigate();
    const { hash } = useLocation();
    const [showTab, setShowTab] = useState("");


    useEffect(() => {
        setShowTab(hash)
    }, [hash])

    const setActionFunc = (e, val) => {
        e.preventDefault();

        setAction(val)
    }


    return (
        <>

            <section className='cases_history_section'>

                {
                    app?.RecommendedDate && (
                        <div className='case_recommend mb-3'>
                            {/* <h5 className='my-2'>This case is on Hold / Recommended by University</h5> */}
                            <h5 className='my-2'>This case is Recommended</h5>
                        </div>

                    )
                }
                {
                    app?.onholdDate && (
                        <div className='case_recommend mb-3'>
                            {/* <h5 className='my-2'>This case is on Hold / Recommended by University</h5> */}
                            <h5 className='my-2'>This case is On Hold</h5>
                        </div>

                    )
                }

                {
                    isMou && (
                        <div className='mou_Expired mb-3'>
                            {/* <h5 className='my-2'>This case is on Hold / Recommended by University</h5> */}
                            <h5 className='my-2'>{mouTXT}</h5>
                        </div>
                    )
                }
                <div className="mb-4">

                    {/* <Tabs tabLinks={isMobile ? Mob_tabs : tabs} show={showTab} setShow={setShowTab} /> */}

                    <div className='d-flex jc-between ai-center mb-4 case_detail_header'>
                        <h4 className='mb-0'>Case Detail</h4>
                        <div className='d-flex jc-end gap-2 case_detail_btns'>
                            {
                                ((app.status !== "disburse" && app.status !== "reject") && (user?.role == "operations" || user?.role == "admin" || user?.role == "university" || (user?.role == "finance" && app.status === "approved" && open1))) && (
                                    // (app.status == "disburse" && (user?.role == "operations" || user?.role == "admin" || user?.role == "university" || (user?.role == "finance" && app.status === "approved" && open1))) && (
                                    <>
                                        <div className=''>
                                            <UserModal btnText={"Action"} heading="Take Action" id={`ViewProgram`} customClasses="save_btn" actionModalClass="action_modal" modalSize="lg" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                <form action="" onSubmit={(e) => actionAdmin(e)} >
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label className="form-label">Actions:</label>
                                                            <div className='d-flex flex-wrap actions_btn'>
                                                                {
                                                                    (user?.role == "operations" || user?.role == "admin") &&
                                                                    <>
                                                                        {
                                                                            app.status === "pending" && (
                                                                                <>
                                                                                    <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "interview")} className={`btns btn-interview ${action === "interview" && "active_btn"} text-light me-2 mb-2`}>Interview Schedule</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "recommended")} className={`btns btn-primary ${action === "recommended" && "active_btn"} text-light me-2 mb-2`}>Recommended</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "onhold")} className={`btns btn-dark ${action === "onhold" && "active_btn"} text-light me-2 mb-2`}>On Hold</button>
                                                                                </>
                                                                            )
                                                                        }

                                                                        {
                                                                            app.status === "interview" && (
                                                                                <>
                                                                                    {/* <button onClick={() => {
                                                                                        setAction("interview")
                                                                                        setReason("Interview has been Rescheduled")
                                                                                    }} className={`btns btn-interview ${action === "interview" && "active_btn"} text-light me-2 mb-2`}>Interview Reschedule</button> */}

                                                                                    {/* <button onClick={(e) => setActionFunc(e, "recommended")} className={`btns btn-primary ${action === "recommended" && "active_btn"} text-light me-2 mb-2`}>Recommended</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "onhold")} className={`btns btn-dark ${action === "onhold" && "active_btn"} text-light me-2 mb-2`}>On Hold</button> */}
                                                                                    
                                                                                    
                                                                                    <button onClick={(e) => setActionFunc(e, "interview conducted")} className={`btns btn-intConducted ${action === "interview conducted" && "active_btn"} text-light me-2 mb-2`}>Interview Conducted</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "not shown up")} className={`btns btn-not_shown_up ${action === "not shown up" && "active_btn"} text-light me-2 mb-2`}>Not Shown Up</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>

                                                                                    {/* <button onClick={(e) => setActionFunc(e,"approve")} className={`btns btn-approve ${action === "approve" && "active_btn"} text-light me-2 mb-2`}>Approve</button> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            app.status === "interview conducted" && (
                                                                                <>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button> */}
                                                                                    <button onClick={(e) => setActionFunc(e, "approve")} className={`btns btn-approve ${action === "approve" && "active_btn"} text-light me-2 mb-2`}>Approve</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "recommended")} className={`btns btn-primary ${action === "recommended" && "active_btn"} text-light me-2 mb-2`}>Recommended</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "onhold")} className={`btns btn-dark ${action === "onhold" && "active_btn"} text-light me-2 mb-2`}>On Hold</button> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            app.status === "not shown up" && (
                                                                                <>
                                                                                    <button onClick={() => {
                                                                                        setAction("interview")
                                                                                        setReason("Interview has been Rescheduled")
                                                                                    }} className={`btns btn-interview ${action === "interview" && "active_btn"} text-light me-2 mb-2`}>Interview Reschedule</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "interview conducted")} className={`btns btn-intConducted ${action === "interview conducted" && "active_btn"} text-light me-2 mb-2`}>Interview Conducted</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button> */}
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "recommended")} className={`btns btn-primary ${action === "recommended" && "active_btn"} text-light me-2 mb-2`}>Recommended</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "onhold")} className={`btns btn-dark ${action === "onhold" && "active_btn"} text-light me-2 mb-2`}>On Hold</button> */}
                                                                                </>
                                                                            )
                                                                        }

                                                                        {
                                                                            app.status === "approved" && (
                                                                                <>
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "disburse")} className={`btns btn-disburse  ${action === "disburse" && "active_btn"} text-light me-2 mb-2`}>Disburse</button>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            app.status === "reject" && (
                                                                                <>
                                                                                    <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "interview")} className={`btns btn-interview ${action === "interview" && "active_btn"} text-light me-2 mb-2`}>Interview Schedule</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "approve")} className={`btns btn-approve ${action === "approve" && "active_btn"} text-light me-2 mb-2`}>Approve</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "disburse")} className={`btns btn-disburse  ${action === "disburse" && "active_btn"} text-light me-2 mb-2`}>Disburse</button>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (app.status === "onhold") && (
                                                                                <>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "onhold")} className={`btns btn-dark ${action === "onhold" && "active_btn"} text-light me-2 mb-2`}>On Hold</button> */}
                                                                                    <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "interview")} className={`btns btn-interview ${action === "interview" && "active_btn"} text-light me-2 mb-2`}>Interview Schedule</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "interview conducted")} className={`btns btn-intConducted ${action === "interview conducted" && "active_btn"} text-light me-2 mb-2`}>Interview Conducted</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "approve")} className={`btns btn-approve ${action === "approve" && "active_btn"} text-light me-2 mb-2`}>Approve</button> */}
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "disburse")} className={`btns btn-disburse  ${action === "disburse" && "active_btn"} text-light me-2 mb-2`}>Disburse</button> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (app.status === "recommended") && (
                                                                                <>
                                                                                    <button onClick={(e) => setActionFunc(e, "onhold")} className={`btns btn-dark ${action === "onhold" && "active_btn"} text-light me-2 mb-2`}>On Hold</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button>
                                                                                    <button onClick={(e) => setActionFunc(e, "interview")} className={`btns btn-interview ${action === "interview" && "active_btn"} text-light me-2 mb-2`}>Interview Schedule</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "interview conducted")} className={`btns btn-intConducted ${action === "interview conducted" && "active_btn"} text-light me-2 mb-2`}>Interview Conducted</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "approve")} className={`btns btn-approve ${action === "approve" && "active_btn"} text-light me-2 mb-2`}>Approve</button> */}
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "disburse")} className={`btns btn-disburse  ${action === "disburse" && "active_btn"} text-light me-2 mb-2`}>Disburse</button> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            app.status === "resubmit" && (
                                                                                <>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "resubmit")} className={`btns btn-resubmit ${action === "resubmit" && "active_btn"} text-light me-2 mb-2`}>Re Submit</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "interview")} className={`btns btn-interview ${action === "interview" && "active_btn"} text-light me-2 mb-2`}>Interview Schedule</button> */}
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "approve")} className={`btns btn-approve ${action === "approve" && "active_btn"} text-light me-2 mb-2`}>Approve</button> */}
                                                                                    <button onClick={(e) => setActionFunc(e, "reject")} className={`btns btn-reject ${action === "reject" && "active_btn"} text-light me-2 mb-2`}>Reject</button>
                                                                                    {/* <button onClick={(e) => setActionFunc(e, "disburse")} className={`btns btn-disburse  ${action === "disburse" && "active_btn"} text-light me-2 mb-2`}>Disburse</button> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                                {
                                                                    user.role == "finance" && (
                                                                        <>
                                                                            <button onClick={(e) => setActionFunc(e, "disburse")} className={`btns btn-disburse  ${action === "disburse" && "active_btn"} text-light me-2 mb-2`}>Disburse</button>
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    user?.role == "university" &&
                                                                    <>
                                                                        <button onClick={(e) => setActionFunc(e, "recommended")} className={`btns btn-primary ${action === "recommended" && "active_btn"} text-light me-2 mb-2`}>Recommended</button>
                                                                        <button onClick={(e) => setActionFunc(e, "onhold")} className={`btns btn-dark ${action === "onhold" && "active_btn"} text-light me-2 mb-2`}>On Hold</button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            {
                                                                action == "interview" &&
                                                                <div className='form-group'>
                                                                    <label className="form-label">Interview Date & Time <span className='required'>*</span></label>
                                                                    <input min={currentDateTime} type="datetime-local" className='form-control' name="DOB" value={date123} onChange={(e) =>
                                                                        setDate123(e.target.value)}
                                                                        required
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                (action == "approve" || action == "reject") &&
                                                                <div className='form-group'>
                                                                    <label className="form-label">Next Review <span className='required'>*</span></label>
                                                                    <input type="date"
                                                                        min={today}
                                                                        className='form-control'
                                                                        name="DOB"
                                                                        value={date123}
                                                                        onChange={(e) => setDate123(e.target.value)}
                                                                        required
                                                                    />

                                                                    {/* <input type="month"
                                                                            min={today}
                                                                            className='form-control'
                                                                            name="DOB"
                                                                            value={date123}
                                                                            onChange={(e) => {
                                                                                console.log(e.target.value)
                                                                                setDate123(e.target.value)
                                                                            }
                                                                            }
                                                                            required
                                                                        /> */}
                                                                </div>

                                                            }

                                                            <div className="form-group">
                                                                <label className="form-label">Remarks {(action == "resubmit" || action == "reject") && <span className='required'>*</span>}</label>
                                                                <Input disabled={(action == "resubmit" || action == "reject") ? false : true} value={reason} onChange={(e) => handleChange(e)} inputType="text" placeholder=" " required={(action == "resubmit" || action == "reject") ? true : false} />
                                                            </div>
                                                            <div className='remarks'>
                                                                <ul>
                                                                    {
                                                                        action == "interview" &&
                                                                        interviewReasons.length > 0 && interviewReasons.map((item, i) => (
                                                                            <li key={i} onClick={() => reasonClick(item)}>{item}</li>
                                                                        ))
                                                                    }
                                                                    {
                                                                        action == "interview conducted" &&
                                                                        interviewConducted.length > 0 && interviewConducted.map((item, i) => (
                                                                            <li key={i} onClick={() => reasonClick(item)}>{item}</li>
                                                                        ))
                                                                    }
                                                                    {
                                                                        action == "approve" &&
                                                                        approveReason.length > 0 && approveReason.map((item, i) => (
                                                                            <li key={i} onClick={() => reasonClick(item)}>{item}</li>
                                                                        ))
                                                                    }
                                                                    {
                                                                        action == "reject" &&
                                                                        cancelReason.length > 0 && cancelReason.map((item, i) => (
                                                                            <li key={i} onClick={() => reasonClick(item)}>{item}</li>
                                                                        ))

                                                                    }
                                                                    {
                                                                        action == "disburse" &&
                                                                        disbursementReason.length > 0 && disbursementReason.map((item, i) => (
                                                                            <li key={i} onClick={() => reasonClick(item)}>{item}</li>
                                                                        ))
                                                                    }
                                                                    {
                                                                        action == "onhold" &&
                                                                        holdReason.length > 0 && holdReason.map((item, i) => (
                                                                            <li key={i} onClick={() => reasonClick(item)}>{item}</li>
                                                                        ))
                                                                    }
                                                                    {
                                                                        action == "not shown up" &&
                                                                        notShownUpReason.length > 0 && notShownUpReason.map((item, i) => (
                                                                            <li key={i} onClick={() => reasonClick(item)}>{item}</li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex jc-end mt-3'>
                                                        <button type='submit' className='btn save_btn'>Done</button>
                                                    </div>
                                                </form >


                                            </UserModal>
                                        </div>
                                    </>
                                )
                            }

                            <div className=''>
                                {
                                    user.role === "university" ?
                                        <Link to={`/uni-application/${id}/${doc}`} className='link'>
                                            <button className="btn save_btn">Application Form</button>
                                        </Link>
                                        :
                                        <Link to={`/application/${id}/${doc}`} className='link'>
                                            <button className="btn save_btn">Application Form</button>
                                        </Link>
                                }
                            </div>


                            {/* {
                                (app?.status == "interview conducted" || app?.status == "interview" || app?.status == "approved" || app?.status == "disburse") && <>
                                    <div className='ml-2'>
                                        {
                                            user.role === "university" ?
                                                <Link to={`/uni-interview-sheet/${id}/${data._id}`} className='link'>
                                                    <button className="btn save_btn">Interview Sheet</button>
                                                </Link>
                                                :
                                                <Link to={`/interview-sheet/${id}/${data._id}`} className='link'>
                                                    <button className="btn save_btn">Interview Sheet</button>
                                                </Link>
                                        }

                                    </div>
                                    {
                                        open && (
                                            <div className='ml-2'>
                                                {
                                                    user.role === "university" ?
                                                        <Link to={`/approval-form/${id}/${data._id}`} className='link'>
                                                            <button className="btn save_btn">Approval Form</button>
                                                        </Link>
                                                        :
                                                        <Link to={`/approval-form/${id}/${data._id}`} className='link'>
                                                            <button className="btn save_btn">Approval Form</button>
                                                        </Link>
                                                }
                                            </div>
                                        )
                                    }
                                </>
                            }
                            {
                                open && user.role === "admin" && (
                                    (app.status !== "disburse" && app.status !== "reject") && (
                                        < div className='ml-2'>
                                            <Link to={`/recomendation-form/${id}/${data._id}`} className='link'>
                                                <button className="btn save_btn">Recomendation Form</button>
                                            </Link>
                                        </div>
                                    )
                                )
                            } */}


                        </div>
                    </div>

                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className='form-group'>
                                    <Input label="Applicant's Name" inputType="text" disabled value={data?.name || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label="Applicant's Email" inputType="text" class="text-transform-none" disabled value={data?.email || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label="Applicant's CNIC" inputType="text" disabled value={data?.cnic || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label="Applicant's Cell No." inputType="text" disabled value={data?.cell || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label="Father's Cell No." inputType="text" disabled value={data?.fCell || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label="Mother's Cell No." inputType="text" disabled value={data?.mCell || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label="Institute Name" inputType="text" disabled value={data?.uni || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label="Program" inputType="text" disabled value={data?.program || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label={`${data?.type}`} inputType="number" disabled value={data?.appSem || "NA"} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <Input label={`Approved ${data?.type}`} inputType="number" disabled value={data?.semester || "NA"} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-4 h-100">
                            <h4>History</h4>
                            <div className="history_data">

                                {
                                    logs.length > 0 && logs.map((item, i) => (

                                        <div key={i} className="history_card mb-3">
                                            <div className='d-flex'>
                                                <div className={`history_timeline status ${item?.status} md`}>
                                                    <FaHistory className='history_icon' />
                                                </div>
                                                <div className='w-100'>
                                                    <div className='d-flex jc-between'>
                                                        <h5>{item?.authority}</h5>
                                                        <h5><Badges text={item?.status === "pending" ? "Processing" : item?.status || "---"} classes={`${item?.status} md`} /></h5>
                                                    </div>

                                                    <p className='d-flex'>
                                                        {
                                                            item?.reason !== "" && (
                                                                <MdOutlineMessage className='msg_icon me-2' />
                                                            )
                                                        }

                                                        {
                                                            item?.status == "approved" ?
                                                                <div dangerouslySetInnerHTML={{ __html: item?.reason }} />
                                                                :
                                                                item?.reason

                                                        }
                                                    </p>


                                                    {
                                                        item?.status == "approved" && (
                                                            <p>
                                                                <MdOutlineAccessTime className='msg_icon me-2' />
                                                                Next Review: {timestampToDate(item?.nextReview)}
                                                            </p>
                                                        )
                                                    }
                                                    {
                                                        item?.status == "interview" && (
                                                            <p>
                                                                <MdOutlineAccessTime className='msg_icon me-2' />
                                                                Interview Date: {timestampToDate(item?.interview)}
                                                            </p>
                                                        )
                                                    }
                                                    <p>
                                                        <MdOutlineAccessTime className='msg_icon me-2' />
                                                        Case Time: {timestampToDate(item?.date)}
                                                    </p>

                                                    {
                                                        (user.role == "admin" || user.role == "operations") && (
                                                            <div className='mt-2'>
                                                                {
                                                                    item?.status !== "approved" &&
                                                                    <UserModal btnText={"Update"} heading="Take Action" id={`ViewProgram${i}`} customClasses="log_update_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose}
                                                                        // handleShow={() => handleShow(item?.reason, item?.nextReview, item?.interview, item?.status)}
                                                                        handleShow={handleShow}
                                                                    >
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                {
                                                                                    item?.status == "interview" &&
                                                                                    <div className='form-group'>
                                                                                        <label className="form-label">Interview Date & Time <span className='required'>*</span></label>
                                                                                        <input
                                                                                            type="datetime-local"
                                                                                            min={currentDateTime}
                                                                                            className='form-control'
                                                                                            name=""
                                                                                            defaultValue={timestampToDatetimeLocal(item?.interview, "interview")}
                                                                                            onChange={(e) =>
                                                                                                setDate123(e.target.value)
                                                                                            }
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item?.status == "approved" &&
                                                                                    <div className='form-group'>
                                                                                        <label className="form-label">Next Review</label>
                                                                                        <input type="date"
                                                                                            min={today}
                                                                                            className='form-control'
                                                                                            name="DOB"
                                                                                            defaultValue={timestampToDatetimeLocal(item?.nextReview, "approve")}

                                                                                            onChange={(e) =>
                                                                                                setDate123(e.target.value)}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                }

                                                                                {
                                                                                    item?.status == "approved" ?
                                                                                        <div className="form-group">
                                                                                            <label className="form-label">Remarks {(item?.status == "resubmit" || item?.status == "reject") && <span className='required'>*</span>}</label>
                                                                                            <textarea
                                                                                                className='form-control'
                                                                                                name=""
                                                                                                id=""
                                                                                                cols="30"
                                                                                                rows="10"
                                                                                                defaultValue={item?.reason}
                                                                                                onChange={(e) => handleChange(e)}
                                                                                                type="text"
                                                                                            >
                                                                                            </textarea>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="form-group">
                                                                                            <label className="form-label">Remarks {(item?.status == "resubmit" || item?.status == "reject") && <span className='required'>*</span>}</label>
                                                                                            <Input
                                                                                                defaultValue={item?.reason}
                                                                                                onChange={(e) => handleChange(e)}
                                                                                                inputType="text" />
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex jc-end mt-3'>
                                                                            <button onClick={() => updateLogssss(item?._id, item?.status)} className='btn save_btn'>Done</button>
                                                                        </div>

                                                                    </UserModal>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                }

                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-6">
                                <div className="card mb-4 h-100">
                                    <h2 className="mb-0">Scoring</h2>
                                    <div className="">
                                        <ScoringChart />
                                    </div>
                                </div>
                            </div> */}

                </div>


            </section>

        </>
    )
}

export default CasesHistory
