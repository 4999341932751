import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';


import { getForm3, getForm4, SubmissionForm3, SubmissionForm4 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"
import { useToast } from "../../ContextAPI/Components/toast"
import ImageUploadInput from '../Modals/ImageUploadInput';

import {
    academicCertficatesUrl, admissionLetterUrl, BillUrl, childrenBformUrl, CNICUrl, DeathCertificateUrl, DomicileUrl,
    FamilyUrl, IncomeCertificateUrl, newURL, passimageUrl, RenewalUrl, RentAgreementUrl, resumeUrl, vouchersUrl
} from '../../helpers/data';
import Step_7 from './Step_7';
import Step_8 from './Step_8';

import Form from 'react-bootstrap/Form';

import EMP_DETAIL from "../../assets/form-icons/employment-detail.png"
import EXPENDIRURE from "../../assets/form-icons/expenditure.png"
import PROPERTY from "../../assets/form-icons/property.png"
import FormErrors from './FormErrors';



function Additional_Information() {

    const { alert } = useToast()
    const { GetLoginUser, GettabsData, fileData, setfileData } = useAuth();

    // Financial Assistance Detail


    const [data, setData] = useState({
        EmployeeOrg: "",
        EmployeeDesig: "",
        EmployeeExp: "",
        EmployeeSalary: "",
        OtherBenefits: "",
        EmployeeStatus: "",
        orgSponsor: "",
        OrgOutcomeAmount: "",
        ResignDate: "",
        CommutationDate: "",
        houseDetial: "",
        houseSize: "",
        houseMonthlyRent: "",
        houseDeposit: "",
        houseYearlyExpenditure: "",
        telephoneMonth: "",
        electricityMonth: "",
        gasMonth: "",
        waterMonth: "",
        totalMonth: "",
        LegalExpenditure: "",
        LoanRepayment: "",
        donation: "",
        insurancePayment: "",

        TotalEducationExpenditure: "",
        TotalRentalExpenditure: "",
        TotalTransportationExpenditure: "",
        TotalRationExpenditure: "",
        TotalMedicalExpenditure: "",
        TotalUtilitiesExpenditure: "",
        OtherExpenditure: "",
        TotalOtherExpenditure: "",

        TotalFamilyExpenditureMonthly: "",
        totalIncomeMonth: "",

        totalIncomeYear: "",

        totalExpenditureMonth: "",
        totalExpenditureYear: "",
        netDispossibleIncomeMonth: "",
        netDispossibleIncomeYear: "",
        question41: "",


        total: "",


    })



    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setData(prevData => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };


    const calculateTotalBills = (data) => {
        const bills = [
            parseFloat(data.telephoneMonth) || 0,
            parseFloat(data.electricityMonth) || 0,
            parseFloat(data.gasMonth) || 0,
            parseFloat(data.waterMonth) || 0,
            // Add more bills as needed
        ];

        // Calculate the sum of all bills
        const totalBills = bills.reduce((acc, bill) => acc + bill, 0);

        return totalBills;
    };

    const calculateTotalOtherExpenditure = (data) => {
        const fieldsToSum = [
            'TotalEducationExpenditure',
            'TotalRentalExpenditure',
            'TotalTransportationExpenditure',
            'TotalRationExpenditure',
            'TotalMedicalExpenditure',
            'TotalUtilitiesExpenditure',
            'OtherExpenditure',
            // Add more fields as needed
        ];

        // Calculate the sum of specified fields
        const totalOtherExpenditure = fieldsToSum.reduce((acc, field) => {
            const fieldValue = parseFloat(data[field]) || 0;
            return acc + fieldValue;
        }, 0);

        return totalOtherExpenditure;
    };

    // Example usage in the handleChange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value
            };

            // Calculate the sum of specified fields for TotalOtherExpenditure
            const totalOtherExpenditure = calculateTotalOtherExpenditure(updatedData);

            // Calculate the sum of bills
            const totalBills = calculateTotalBills(updatedData);

            const totalIncomeYear = (parseFloat(updatedData.totalIncomeMonth) || 0) * 12;
            const totalExpenditureYear = (parseFloat(updatedData.totalExpenditureMonth) || 0) * 12;
            const netDispossibleIncomeMonth = (parseFloat(updatedData.totalIncomeMonth) || 0) - (parseFloat(updatedData.totalExpenditureMonth) || 0);
            const netDispossibleIncomeYear = (parseFloat(netDispossibleIncomeMonth) || 0) * 12;

            // Update both TotalOtherExpenditure and total fields in the state
            return {
                ...updatedData,
                TotalOtherExpenditure: totalOtherExpenditure.toString(), // Convert to string if necessary
                totalMonth: totalBills.toString(), // Update total field
                totalIncomeYear: totalIncomeYear.toString(),
                netDispossibleIncomeMonth: netDispossibleIncomeMonth.toString(),
                netDispossibleIncomeYear: netDispossibleIncomeYear.toString(),
                totalExpenditureYear: totalExpenditureYear.toString()
            };
        });
    };

    // Loan Detail



    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);



    const [dis, setDis] = useState(true)

    // const handleInputChange = (fieldName, value) => {
    //     setData((prevData) => ({
    //         ...prevData,
    //         [fieldName]: value,
    //     }));
    // };


    useEffect(() => {
        // getFormData()

    }, [])

    const [empDetail, iseEmpDetail] = useState("")
    const [empDis, setEmpDis] = useState(false)

    const handleCheckBoxEmpDetail = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setEmpDis(true)
        }
        else {
            setEmpDis(false)
        }
        iseEmpDetail(e.target.checked)

        setfileData({ ...fileData, empDis: e.target.checked })
    }


    const [validated, setValidated] = useState(false);

    const [jobStatus, setJobStatus] = useState();
    const [askForSponsor, setAskForSponsor] = useState();


    const [familyMembStd, setFamilyMembStd] = useState([
        {
            Name: "",
            Relationship: "",
            Name_of_Institution: "",
            Fee_per_Month: "",
            Private_Tuitions_Fee: "",
            Boarding_and_Lodging: "",
            Stationary_Expenditure: "",
        },
    ]);

    const family_detail = {
        Name: "",
        Relationship: "",
        Name_of_Institution: "",
        Fee_per_Month: "",
        Private_Tuitions_Fee: "",
        Boarding_and_Lodging: "",
        Stationary_Expenditure: "",
    }

    function Add_New_detail(e) {
        e.preventDefault();
        setFamilyMembStd([...familyMembStd, family_detail])
    }


    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);



    function removeDetail(i) {

        const updated = [...familyMembStd]

        updated.splice(i, 1)
        setFamilyMembStd(updated)

    }


    ///////////////////step 8 

    const [assetDetails, setAssetDetails] = useState({
        airCondition: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        car: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        motorCycle: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        television: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        computerLaptop: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
    });


    const handleInput2Change = (e, category, person) => {
        const { value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [`${person}${category}`]: value,
        }));
    };



    const getFormData = async () => {
        try {
            // const response = {}
            // if (response.success) {
            //     console.log("fomr1", response);
            //     setData(response.message)
            //     // setFamilyMembStd(response.message.familyMembersStudying)
            //     setAssetDetails({
            //         ...assetDetails,
            //         airCondition: response.message.HouseHoldAssets[0],
            //         car: response.message.HouseHoldAssets[1],
            //         motorCycle: response.message.HouseHoldAssets[2],
            //         television: response.message.HouseHoldAssets[3],
            //         computerLaptop: response.message.HouseHoldAssets[4]
            //     })
            // }


            const response = await getForm4()

            if (response.success) {
                console.log("@@@@", response.message);
                setData(response.message)

                setFamilyMembStd(response.message.FamilyStudying)
                setHouseDetail(response.message.houseHold)
                setTotalProperty(response.message.PropertyOwned)
                iseEmpDetail(response.message.EmploymentApplicable)
                setEmpDis(response.message.EmploymentApplicable)

            }

        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()
    }, [])


    const [houseDetail, setHouseDetail] = useState([
        {
            Item_Name: "",
            Quantity: "",
            Current_Value: "",
        },
    ]);

    const h_detail = {
        Item_Name: "",
        Quantity: "",
        Current_Value: "",
    }

    function House_Hold_Items(e) {
        e.preventDefault();
        setHouseDetail([...houseDetail, h_detail])
    }
    const [totalProperty, setTotalProperty] = useState([
        {
            valueOfAsset: "",
            father: "",
            mother: "",
            spouse: "",
            self: "",
            brotherSisterChildren: "",
            total: "",
        },
    ]);

    const t_Property = {
        valueOfAsset: "",
        father: "",
        mother: "",
        spouse: "",
        self: "",
        brotherSisterChildren: "",
        total: "",
    }

    function Total_Property(e) {
        e.preventDefault();
        setTotalProperty([...totalProperty, t_Property])
    }

    // function removeTPDetail(i) {
    //     const updated = [...totalProperty]
    //     updated.splice(i, 1)
    //     setTotalProperty(updated)
    // }

    function removeHouseDetail(i) {
        const updated = [...houseDetail]
        updated.splice(i, 1)
        setHouseDetail(updated)
    }


    const handleChangeRadio = (name, value) => {
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleInputChange = (index, fieldName, value) => {
        const updatedHouseDetail = [...houseDetail];
        updatedHouseDetail[index][fieldName] = value;
        setHouseDetail(updatedHouseDetail);
    };



    const renderFormControl = (label, type, value, onChange, disabled = false) => (
        <div className={`col-md-2 ${type === 'number' ? 'number-input' : ''}`}>
            <Form.Group className="form-group">
                <label className='form-label'>{label}</label>
                <Form.Control
                    type={type}
                    placeholder='Enter here'
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
                <Form.Control.Feedback className='error' type="invalid">Please Enter Valid Value.</Form.Control.Feedback>
                <Form.Control.Feedback className='success'>
                    Looks good!
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );


    const handleInput3Change = (e, index) => {
        const { name, value } = e.target;
        const updatedFamilyMembStd = [...familyMembStd];
        updatedFamilyMembStd[index][name] = value;
        setFamilyMembStd(updatedFamilyMembStd);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            ...data,
            houseHold: houseDetail,
            PropertyOwned: totalProperty,
            FamilyStudying: familyMembStd,
            EmploymentApplicable: empDetail
        }

        const check = validation()
        if (check === "proceed") {
            console.log("PPAAYYLLOOAADD", payload);
            const response = await SubmissionForm4(payload)
            if (response.success) {
                GettabsData()
                navigate(`#${"sop"}`)
            }
            alert(response.message, response.success)
        }
        else {
            alert("Form Validation Error", "error")
        }

        // setValidated(true);
    };

    const calculateGrandTotal = (updatedTotalProperty) => {
        const grandTotal = updatedTotalProperty.reduce((acc, item) => {
            const totalValue = parseFloat(item.total);
            return isNaN(totalValue) ? acc : acc + totalValue;
        }, 0);
        // return grandTotal;
        setData({ ...data, total: grandTotal })
    };


    const calculateTotal = () => {
        const updatedTotalProperty = totalProperty.map((item) => {

            const total = Object.values(item)
                .filter((value) => typeof value === 'string' && !isNaN(parseFloat(value)))
                .reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);

            return { ...item, total };
        });

        setTotalProperty(updatedTotalProperty);
        calculateGrandTotal(updatedTotalProperty)
    };


    const handleTPInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedTotalProperty = [...totalProperty];
        updatedTotalProperty[index][name] = value;
        setTotalProperty(updatedTotalProperty);
        // Call calculateTotal after updating the input value
        calculateTotal();
    };


    const removeTPDetail = (index) => {
        const updatedTotalProperty = [...totalProperty];
        updatedTotalProperty.splice(index, 1);
        setTotalProperty(updatedTotalProperty);
    };



    const handleJobStatus = (e) => {
        try {
            setJobStatus(e.target.value)
            setData({ ...data, EmployeeStatus: e.target.value })
        }
        catch (e) {
            console.log(e);
        }
    }

    const sethandleSponsor = (e) => {
        try {
            setAskForSponsor(e.target.value)
            setData({ ...data, orgSponsor: e.target.value })
        }
        catch (e) {
            console.log(e);
        }
    }

    let errors = {}
    const [formErrors, setFormErrors] = useState([])
    const validation = () => {
        try {
            if (empDetail == "") {
                errors.EmployeeStatus = ("Are you presently employed / working somewhere is required")
            }

            if (empDetail === "yes") {
                if (data.EmployeeOrg == "") {
                    errors.EmployeeOrg = ("Name of Organization is required")
                }
                if (data.EmployeeDesig == "") {
                    errors.EmployeeDesig = ("Designation is required")
                }
                if (data.EmployeeExp == "") {
                    errors.EmployeeExp = ("Experience in years is required")
                }
                if (data.EmployeeSalary == "") {
                    errors.EmployeeSalary = ("Salary per month is required")
                }
                if (data.OtherBenefits == "") {
                    errors.OtherBenefits = ("Other Benefits is required")
                }

                if (data.EmployeeStatus == "resigned") {
                    if (data.ResignDate === "" || data.ResignDate == undefined) {
                        errors.ResignDate = ("Resignation Date is required")
                    }
                    if (data.CommutationDate === "" || data.CommutationDate == undefined) {
                        errors.CommutationDate = ("Commutation Date is required")
                    }
                }

                if (data.orgSponsor == "yes") {
                    if (data.OrgOutcomeAmount === "" || data.OrgOutcomeAmount == undefined) {
                        errors.OrgOutcomeAmount = ("Outcome Amount is required")
                    }
                }
            }


            if (parseFloat(data.netDispossibleIncomeYear) < 0) {
                if (data.question41 === "" || data.question41 == undefined) {
                    errors.question41 = ("Reason is required for Net Dispossible Income Year")
                }
            }

            // if (data.OrgOutcomeAmount == "") {
            //     errors.push("Organization Outcome Amount is required")
            // }

            if (data.houseDetial == "") {
                errors.houseDetial = ("House Detail is required")
            }

            if (data.houseDetial == "Owned" || data.houseDetial == "Rental") {
                if (data.houseSize == "" || !data.houseSize) {
                    errors.houseSize = ("Size of the House is required")
                }
            }

            if (data.houseDetial == "Rental") {
                if (data.houseMonthlyRent == "" || !data.houseMonthlyRent) {
                    errors.houseMonthlyRent = ("House Rent is required")
                }
                if (data.houseDeposit == "" || !data.houseDeposit) {
                    errors.houseDeposit = ("House Advance is required")
                }
                if (data.houseYearlyExpenditure == "" || !data.houseYearlyExpenditure) {
                    errors.houseYearlyExpenditure = ("House Yearly Expense is required")
                }
            }


            // if (data.LegalExpenditure == "") {
            //     errors.LegalExpenditure = ("Legal Expenditure is required")
            // }
            // if (data.LoanRepayment == "") {
            //     errors.LoanRepayment = ("Loan Repayment is reuired")
            // }
            // if (data.donation == "") {
            //     errors.donation = ("Donation is required")
            // }
            // if (data.insurancePayment == "") {
            //     errors.insurancePayment = ("Insurance payment is required")
            // }

            if (data.electricityMonth == "") {
                errors.electricityMonth = ("Electricity Bill is required")
            }
            if (data.gasMonth == "") {
                errors.gasMonth = ("Gas Bill is required")
            }

            if (data.TotalEducationExpenditure == "" || !data.TotalEducationExpenditure) {
                errors.TotalEducationExpenditure = ("Total Education Expenditure is required")
            }
            if (data.TotalTransportationExpenditure == "" || !data.TotalTransportationExpenditure) {
                errors.TotalTransportationExpenditure = ("Total Transportation Expenditure is required")
            }
            if (data.TotalRationExpenditure == "" || !data.TotalRationExpenditure) {
                errors.TotalRationExpenditure = ("Total Ration Expenditure is required")
            }
            if (data.TotalUtilitiesExpenditure == "" || !data.TotalUtilitiesExpenditure) {
                errors.TotalUtilitiesExpenditure = ("Total Utilities Expenditure is required")
            }
            if (data.totalIncomeMonth == "" || !data.totalIncomeMonth) {
                errors.totalIncomeMonth = ("Total Income per Month is required")
            }
            console.log("data.totalExpenditureMonth");
            console.log(data.totalExpenditureMonth);
            if (data.totalExpenditureMonth == "" || !data.totalExpenditureMonth) {
                errors.totalExpenditureMonth = ("Total Expenditure per month is required")
            }

            if ((Object.entries(errors)).length == 0) {
                setFormErrors([])
                return "proceed"
            }
            else {
                console.log("errors");
                console.log(errors);
                setFormErrors(errors)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    function IsError(id) {
        return !(Object.keys(formErrors)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formErrors)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }


    const today = new Date().toISOString().split('T')[0];


    return (
        <>
            <div className="">

                {
                    (Object.values(formErrors)).length > 0 && (
                        <FormErrors errorList={formErrors} />
                    )
                }

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="accordion accordion-flush" id="accordionFlushExample">

                        {/* Employment Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <img src={EMP_DETAIL} alt="icon" className='img-fluid form_icons' />
                                    Employment Details
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    {/* <div className="form-group form-check text-center">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={empDetail} onChange={(e) => handleCheckBoxEmpDetail(e)} />
                                        <label className="form-check-label" for="flexCheckDefault" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                            Not Applicable For Me
                                        </label>
                                    </div> */}

                                    <Form.Group id='EmployeeStatus' className='form-group'>
                                        <Form.Label>Are you presently employed / working somewhere? <span className='required'>*</span></Form.Label>
                                        <Form.Select
                                            value={empDetail} onChange={(e) => iseEmpDetail(e.target.value)}
                                            aria-label="Default select example"
                                            className={`${(formErrors.length == 0 ? "" : IsError("EmployeeStatus") ? "success" : "error")}`}
                                        >
                                            <option selected disabled value="">Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </Form.Select>
                                        {
                                            (ShowError("EmployeeStatus"))[0] &&
                                            <p className='error_label'>{(ShowError("EmployeeStatus"))[1]}</p>
                                        }
                                    </Form.Group>

                                    {
                                        empDetail === "yes" &&
                                        <div className="row">
                                            <div className="col-12 vertically_center">
                                                <Form.Group id='EmployeeOrg' className='form-group'>
                                                    <Form.Label>Name of the Organization {empDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={"Enter here"}
                                                        name="EmployeeOrg"
                                                        value={data.EmployeeOrg}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("EmployeeOrg") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("EmployeeOrg"))[0] &&
                                                        <p className='error_label'>{(ShowError("EmployeeOrg"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='EmployeeDesig' className='form-group'>
                                                    <Form.Label>Designation {empDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={"Enter here"}
                                                        name="EmployeeDesig"
                                                        value={data.EmployeeDesig}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("EmployeeDesig") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("EmployeeDesig"))[0] &&
                                                        <p className='error_label'>{(ShowError("EmployeeDesig"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='EmployeeExp' className='form-group'>
                                                    <Form.Label>Experience in years {empDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={"Enter here"}
                                                        name="EmployeeExp"
                                                        value={data.EmployeeExp}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("EmployeeExp") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("EmployeeExp"))[0] &&
                                                        <p className='error_label'>{(ShowError("EmployeeExp"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='EmployeeSalary' className='form-group'>
                                                    <Form.Label>Salary per month during the program (Rs.) {empDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={"Enter here"}
                                                        name="EmployeeSalary"
                                                        value={data.EmployeeSalary}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("EmployeeSalary") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("EmployeeSalary"))[0] &&
                                                        <p className='error_label'>{(ShowError("EmployeeSalary"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='OtherBenefits' className='form-group'>
                                                    <Form.Label>Other benefits {empDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={"Enter here"}
                                                        name="OtherBenefits"
                                                        value={data.OtherBenefits}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("OtherBenefits") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("OtherBenefits"))[0] &&
                                                        <p className='error_label'>{(ShowError("OtherBenefits"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='EmployeeStatus' className='form-group'>
                                                    <Form.Label>Status of the job during the Program</Form.Label>
                                                    <Form.Select value={data.EmployeeStatus} onChange={(e) => handleJobStatus(e)} aria-label="Default select example">
                                                        <option selected disabled value="">Select</option>
                                                        <option value="working">Working</option>
                                                        <option value="on leave with pay">On leave with pay</option>
                                                        <option value="on leave without pay">On leave without pay</option>
                                                        <option value="resigned">Resigned</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id='ResignDate' className='form-group'>
                                                    <Form.Label>Date of resignation {data.EmployeeStatus === "resigned" ? <span className='required'>*</span> : ""}</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        max={today}
                                                        placeholder={"Enter here"}
                                                        name="ResignDate"
                                                        value={data.ResignDate ? new Date(data.ResignDate).toISOString().split('T')[0] : ''}
                                                        onChange={handleChange}
                                                        disabled={data.EmployeeStatus === "resigned" ? false : true}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("ResignDate") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("ResignDate"))[0] &&
                                                        <p className='error_label'>{(ShowError("ResignDate"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group id='CommutationDate' className='form-group'>
                                                    <Form.Label>Commutation received {data.EmployeeStatus === "resigned" ? <span className='required'>*</span> : ""}</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        max={today}
                                                        placeholder={"Enter here"}
                                                        name="CommutationDate"
                                                        value={data.CommutationDate ? new Date(data.CommutationDate).toISOString().split('T')[0] : ''}
                                                        onChange={handleChange}
                                                        disabled={data.EmployeeStatus === "resigned" ? false : true}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("CommutationDate") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("CommutationDate"))[0] &&
                                                        <p className='error_label'>{(ShowError("CommutationDate"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group controlId="validationCustom05" id='orgSponsor' className='form-group'>
                                                    <Form.Label>Did you ask the organization to sponsor your educational expense for this program?</Form.Label>
                                                    <Form.Select value={data.orgSponsor} onChange={(e) => sethandleSponsor(e)}>
                                                        <option selected disabled value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='OrgOutcomeAmount' className='form-group'>
                                                    <Form.Label>Outcome Amount (Rs). {data.orgSponsor == "yes" ? <span className='required'>*</span> : ""}</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={data.orgSponsor == "yes" ? "Enter here" : ""}
                                                        name="OrgOutcomeAmount"
                                                        value={data.OrgOutcomeAmount}
                                                        onChange={handleChange}
                                                        disabled={data.orgSponsor === "yes" ? false : true}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("OrgOutcomeAmount") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("OrgOutcomeAmount"))[0] &&
                                                        <p className='error_label'>{(ShowError("OrgOutcomeAmount"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>

                                            {/* ... Repeat the pattern for more input fields ... */}

                                            <div className="col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={false}
                                                    name="employemnetIncomeCertificate"
                                                    span={empDis ? "" : "*"}
                                                    text="Employment Certificate"
                                                    url={newURL}
                                                    itemkey="employemnetIncomeCertificate"
                                                    accept=".pdf"
                                                    dis={empDis}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Expenditure */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    <img src={EXPENDIRURE} alt="icon" className='img-fluid form_icons' />
                                    Expenditure
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <>
                                        {/* Family Members Studying */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='mb-0'>Details of Family Members who are studying </h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' onClick={(e) => Add_New_detail(e)}>Add More</button>
                                                </div>
                                            </div>

                                            {familyMembStd.map((data, i) => {
                                                return <>
                                                    {
                                                        i > 0 &&
                                                        <div className="section_break"></div>
                                                    }
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group id='Name' className="form-group">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Name"
                                                                    value={data.Name}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    placeholder="Enter here"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group id='Relationship' className="form-group">
                                                                <Form.Label>Relationship</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Relationship"
                                                                    value={data.Relationship}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    placeholder="Enter here"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group id='Name_of_Institution' className="form-group">
                                                                <Form.Label>Name of Institution (if Studying)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Name_of_Institution"
                                                                    value={data.Name_of_Institution}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    placeholder="Enter here"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group id='Fee_per_Month' className="form-group">
                                                                <Form.Label>Fee per Month (Rs.)</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="Fee_per_Month"
                                                                    value={data.Fee_per_Month}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    placeholder="Enter here"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group id='Private_Tuitions_Fee' className="form-group">
                                                                <Form.Label>Private Tuitions Fee</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="Private_Tuitions_Fee"
                                                                    value={data.Private_Tuitions_Fee}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    placeholder="Enter here"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group id='Boarding_and_Lodging' className="form-group">
                                                                <Form.Label>Boarding and Lodging</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="Boarding_and_Lodging"
                                                                    value={data.Boarding_and_Lodging}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    placeholder="Enter here"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-12 vertically_center">
                                                            <Form.Group id='Stationary_Expenditure' className="form-group">
                                                                <Form.Label>Stationary Expenditure</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="Stationary_Expenditure"
                                                                    value={data.Stationary_Expenditure}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    placeholder="Enter here"
                                                                />
                                                            </Form.Group>
                                                        </div>

                                                        {
                                                            i > 0 &&
                                                            <div className="d-flex jc-end">
                                                                <button onClick={() => removeDetail(i)} className="btn delete_btn">
                                                                    Remove Detail
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            })
                                            }

                                        </div>

                                        {/* House Details */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>House Details</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 vertically_center">
                                                    <Form.Group id='houseDetial' className='form-group'>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-5 pe-0">
                                                                <Form.Label>Do you own or rented house <span className='required'>*</span></Form.Label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-7 d-flex gap-3">
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="houseDetial"
                                                                    id="owned"
                                                                    value="Owned"
                                                                    checked={data.houseDetial === "Owned"}
                                                                    onChange={() => handleChangeRadio('houseDetial', 'Owned')}
                                                                    label="Owned"
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="houseDetial"
                                                                    id="rental"
                                                                    value="Rental"
                                                                    checked={data.houseDetial === "Rental"}
                                                                    onChange={() => handleChangeRadio('houseDetial', 'Rental')}
                                                                    label="Rental"
                                                                    className='ml-3'
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            (ShowError("houseDetial"))[0] &&
                                                            <p className='error_label'>{(ShowError("houseDetial"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {(data.houseDetial === "Owned" || data.houseDetial === "Rental") &&
                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group id='houseSize' className='form-group'>
                                                            <label className='form-label'>Size of the House(yards)<span className='required'>*</span></label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder='Enter here'
                                                                value={data.houseSize}
                                                                onChange={handleChange}
                                                                name="houseSize"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("houseSize") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("houseSize"))[0] &&
                                                                <p className='error_label'>{(ShowError("houseSize"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {data.houseDetial === "Rental" &&
                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group id='houseMonthlyRent' className='form-group'>
                                                            <label className='form-label'>Monthly Rent (Rs.) <span className='required'>*</span></label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder='Enter here'
                                                                value={data.houseMonthlyRent}
                                                                onChange={handleChange}
                                                                name="houseMonthlyRent"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("houseMonthlyRent") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("houseMonthlyRent"))[0] &&
                                                                <p className='error_label'>{(ShowError("houseMonthlyRent"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {data.houseDetial === "Rental" &&
                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group id='houseDeposit' className='form-group'>
                                                            <label className='form-label'>Deposit/Advance (Rs.) <span className='required'>*</span></label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder='Enter here'
                                                                value={data.houseDeposit}
                                                                onChange={handleChange}
                                                                name="houseDeposit"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("houseDeposit") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("houseDeposit"))[0] &&
                                                                <p className='error_label'>{(ShowError("houseDeposit"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {data.houseDetial === "Rental" &&
                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group id='houseYearlyExpenditure' className='form-group'>
                                                            <label className='form-label'>Yearly Expenditure <span className='required'>*</span></label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder='Enter here'
                                                                value={data.houseYearlyExpenditure}
                                                                onChange={handleChange}
                                                                name="houseYearlyExpenditure"
                                                                required
                                                                className={`${(formErrors.length == 0 ? "" : IsError("houseYearlyExpenditure") ? "success" : "error")}`}
                                                            />
                                                            {
                                                                (ShowError("houseYearlyExpenditure"))[0] &&
                                                                <p className='error_label'>{(ShowError("houseYearlyExpenditure"))[1]}</p>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {data.houseDetial === "Rental" &&
                                                    <div className="col-12 vertically_center">
                                                        <ImageUploadInput
                                                            multiple={false}
                                                            name="Rent"
                                                            text="Copy of Rent / Tenancy Agreement (if Applicable)"
                                                            url={RentAgreementUrl}
                                                            itemkey="RentAgreement"
                                                            accept=".pdf"
                                                            onChange={handleChange} // add this line
                                                            required={false}
                                                        />
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        {/* Utility Bills */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Last Month Utility Expenditure (Rs.)</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='electricityMonth' className="form-group">
                                                        <label className='form-label'>Electricity Bill <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data[`electricityMonth`]}
                                                            onChange={(e) => handleChange(e)}
                                                            name="electricityMonth"
                                                            required
                                                            className={`${(formErrors.length == 0 ? "" : IsError("electricityMonth") ? "success" : "error")}`}
                                                        />
                                                        {
                                                            (ShowError("electricityMonth"))[0] &&
                                                            <p className='error_label'>{(ShowError("electricityMonth"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='gasMonth' className="form-group">
                                                        <label className='form-label'>Gas Bill <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data[`gasMonth`]}
                                                            onChange={(e) => handleChange(e)}
                                                            name="gasMonth"
                                                            required
                                                            className={`${(formErrors.length == 0 ? "" : IsError("gasMonth") ? "success" : "error")}`}
                                                        />
                                                        {
                                                            (ShowError("gasMonth"))[0] &&
                                                            <p className='error_label'>{(ShowError("gasMonth"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='telephoneMonth' className="form-group">
                                                        <label className='form-label'>Telephone Bill</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data[`telephoneMonth`]}
                                                            onChange={(e) => handleChange(e)}
                                                            name="telephoneMonth"
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='waterMonth' className="form-group">
                                                        <label className='form-label'>Water Bill</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data[`waterMonth`]}
                                                            onChange={(e) => handleChange(e)}
                                                            name="waterMonth"
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-12 vertically_center">
                                                    <Form.Group id='totalMonth' className="form-group">
                                                        <label className='form-label'>Total</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=''
                                                            value={data[`totalMonth`]}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalMonth"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="ElectricBill"
                                                        text="Electricity Bill"
                                                        span="*"
                                                        url={BillUrl}
                                                        itemkey="ElectricBill"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="GasBill"
                                                        text="Gas Bill"
                                                        span="*"
                                                        url={BillUrl}
                                                        itemkey="GasBill"
                                                        accept=".jpg, .jpeg, .png"
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="TelephoneBill"
                                                        text="Telephone Bill"
                                                        url={BillUrl}
                                                        itemkey="TelephoneBill"
                                                        accept=".jpg, .jpeg, .png"
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <ImageUploadInput
                                                        multiple={true}
                                                        name="waterBill"
                                                        text="Water Bill"
                                                        url={BillUrl}
                                                        itemkey="waterBill"
                                                        accept=".jpg, .jpeg, .png"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        {/* Other Expenditure */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Other Expenditure (if any):</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='LegalExpenditure' className="form-group">
                                                        <label className='form-label'>Legal Expenditure</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.LegalExpenditure}
                                                            onChange={handleChange}
                                                            name="LegalExpenditure"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='LoanRepayment' className="form-group">
                                                        <label className='form-label'>Loan Repayment (please specify)</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.LoanRepayment}
                                                            onChange={handleChange}
                                                            name="LoanRepayment"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='donation' className="form-group">
                                                        <label className='form-label'>Donation</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.donation}
                                                            onChange={handleChange}
                                                            name="donation"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='insurancePayment' className="form-group">
                                                        <label className='form-label'>Others</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.insurancePayment}
                                                            onChange={handleChange}
                                                            name="insurancePayment"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Total Family Expenditure */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Total Family Expenditure – Monthly (Rs.)</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='TotalEducationExpenditure' className="form-group">
                                                        <label className='form-label'>Total Education Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.TotalEducationExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalEducationExpenditure" // Add the name attribute for identification
                                                            required
                                                            className={`${formErrors.length == 0 ? "" : IsError("TotalEducationExpenditure") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("TotalEducationExpenditure"))[0] &&
                                                            <p className="error_label">{(ShowError("TotalEducationExpenditure"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='TotalRentalExpenditure' className="form-group">
                                                        <label className='form-label'>Total Rental Expenditure</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.TotalRentalExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalRentalExpenditure"
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='TotalTransportationExpenditure' className="form-group">
                                                        <label className='form-label'>Total Transportation Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.TotalTransportationExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalTransportationExpenditure"
                                                            required
                                                            className={`${formErrors.length == 0 ? "" : IsError("TotalTransportationExpenditure") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("TotalTransportationExpenditure"))[0] &&
                                                            <p className="error_label">{(ShowError("TotalTransportationExpenditure"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='TotalRationExpenditure' className="form-group">
                                                        <label className='form-label'>Kitchen Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.TotalRationExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalRationExpenditure"
                                                            required
                                                            className={`${formErrors.length == 0 ? "" : IsError("TotalRationExpenditure") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("TotalRationExpenditure"))[0] &&
                                                            <p className="error_label">{(ShowError("TotalRationExpenditure"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='TotalMedicalExpenditure' className="form-group">
                                                        <label className='form-label'>Medical Expenditure (if any)</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.TotalMedicalExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalMedicalExpenditure"
                                                        />

                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='TotalUtilitiesExpenditure' className="form-group">
                                                        <label className='form-label'>Total Utilities Expenditure (3 months) <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.TotalUtilitiesExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalUtilitiesExpenditure"
                                                            required
                                                            className={`${formErrors.length == 0 ? "" : IsError("TotalUtilitiesExpenditure") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("TotalUtilitiesExpenditure"))[0] &&
                                                            <p className="error_label">{(ShowError("TotalUtilitiesExpenditure"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='OtherExpenditure' className="form-group">
                                                        <label className='form-label'>Other Expenditure</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.OtherExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="OtherExpenditure"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='TotalOtherExpenditure' className="form-group">
                                                        <label className='form-label'>Total Expenditure</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=''
                                                            value={data.TotalOtherExpenditure}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalOtherExpenditure"
                                                            disabled
                                                        />

                                                    </Form.Group>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Disposable Income */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Disposable Income</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='totalIncomeMonth' className="form-group">
                                                        <label className='form-label'>Total Monthly Income <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.totalIncomeMonth}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalIncomeMonth" // Add the name attribute for identification
                                                            required
                                                            className={`${formErrors.length == 0 ? "" : IsError("totalIncomeMonth") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("totalIncomeMonth"))[0] &&
                                                            <p className="error_label">{(ShowError("totalIncomeMonth"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='totalIncomeYear' className="form-group">
                                                        <label className='form-label'>Total Yearly Income</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=''
                                                            value={data.totalIncomeYear}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalIncomeYear"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='totalExpenditureMonth' className="form-group">
                                                        <label className='form-label'>Total Monthly Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder='Enter here'
                                                            value={data.totalExpenditureMonth}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalExpenditureMonth"
                                                            required
                                                            className={`${formErrors.length == 0 ? "" : IsError("totalExpenditureMonth") ? "success" : "error"}`}
                                                        />
                                                        {
                                                            (ShowError("totalExpenditureMonth"))[0] &&
                                                            <p className="error_label">{(ShowError("totalExpenditureMonth"))[1]}</p>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='totalExpenditureYear' className="form-group">
                                                        <label className='form-label'>Total Yearly Expenditure</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=''
                                                            value={data.totalExpenditureYear}
                                                            // onChange={(e) => handleChange(e)}
                                                            name="totalExpenditureYear"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='netDispossibleIncomeMonth' className="form-group">
                                                        <label className='form-label'>Net Monthly Disposable Income</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=''
                                                            value={data.netDispossibleIncomeMonth}
                                                            onChange={(e) => handleChange(e)}
                                                            name="netDispossibleIncomeMonth"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group id='netDispossibleIncomeYear' className="form-group">
                                                        <label className='form-label'>Net Annual Disposable Income</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=''
                                                            value={data.netDispossibleIncomeYear}
                                                            onChange={(e) => handleChange(e)}
                                                            name="netDispossibleIncomeYear"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                {
                                                    parseFloat(data.netDispossibleIncomeYear) < 0 && (
                                                        <div className="col-12 vertically_center">
                                                            <Form.Group id='question41' className="form-group">
                                                                <label className='form-label'>If the net annual disposable income is negative, please state the source you use to meet your needs <span className='required'>*</span></label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    placeholder='Type here...'
                                                                    value={data.question41}
                                                                    onChange={(e) => handleChange(e)}
                                                                    name="question41"
                                                                    rows={4}
                                                                    required
                                                                    className={`${(formErrors.length == 0 ? "" : IsError("question41") ? "success" : "error")}`}
                                                                />
                                                                {
                                                                    (ShowError("question41"))[0] &&
                                                                    <p className='error_label'>{(ShowError("question41"))[1]}</p>
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>

                                    </>
                                </div>
                            </div>

                        </div>

                        {/* Asset / Property Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    <img src={PROPERTY} alt="icon" className='img-fluid form_icons' />
                                    Asset / Property Details
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className={`accordion-collapse collapse ${formErrors.length > 0 && "show"}`} aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="row">

                                        {/* House_Hold_Items */}
                                        <div className="table_form mb-4">

                                            <div className='mb-4 form_sub_heading'>
                                                <h5 className='mb-0'>House Hold Items (please mention the current market value in Rs.)</h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' onClick={(e) => House_Hold_Items(e)}>Add More</button>
                                                </div>
                                            </div>

                                            {
                                                houseDetail.map((item, i) => (
                                                    <React.Fragment key={i}>
                                                        {i > 0 && <div className="section_break"></div>}

                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom08" id='whatsapp' className='form-group'>
                                                                    <Form.Label>Item Name</Form.Label>
                                                                    <Form.Select
                                                                        value={item.Item_Name}
                                                                        onChange={(e) => handleInputChange(i, 'Item_Name', e.target.value)}
                                                                        aria-label="Default select example"
                                                                    >
                                                                        <option selected disabled value="">Select</option>
                                                                        <option value="Air Condition">Air Condition</option>
                                                                        <option value="Car">Car</option>
                                                                        <option value="Motor Cycle">Motor Cycle</option>
                                                                        <option value="Television">Television</option>
                                                                        <option value="Computer/Laptop">Computer/Laptop</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group id='Quantity' className="form-group">
                                                                    <label className='form-label'>Quantity</label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder='Enter here'
                                                                        value={item.Quantity}
                                                                        onChange={(e) => handleInputChange(i, 'Quantity', e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group id='Current_Value' className="form-group">
                                                                    <label className='form-label'>Current Value (Rs.)</label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder='Enter here'
                                                                        value={item.Current_Value}
                                                                        onChange={(e) => handleInputChange(i, 'Current_Value', e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                            </div>

                                                            {i > 0 && (
                                                                <div className='d-flex jc-end'>
                                                                    <button onClick={() => removeHouseDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            }


                                        </div>

                                        {/* Total_Property */}
                                        <div className="table_form mb-4">
                                            <div className='mb-4 form_sub_heading'>
                                                <h5 className='mb-0'>Total Value of Property Owned (Rs.)</h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' onClick={(e) => Total_Property(e)}>Add More</button>
                                                </div>
                                            </div>

                                            {totalProperty.map((item, i) => (
                                                <div key={i}>
                                                    {i > 0 && <div className="section_break"></div>}

                                                    <div className="row">
                                                        <div className="col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom08" id='valueOfAsset' className='form-group'>
                                                                <Form.Label>Name of Asset</Form.Label>
                                                                <Form.Select
                                                                    value={item.valueOfAsset}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                    name="valueOfAsset"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option selected disabled value="">Select</option>
                                                                    <option value="Business">Business</option>
                                                                    <option value="Land and Building">Land and Building</option>
                                                                    <option value="Saving a/c and Deposits">Saving a/c and Deposits</option>
                                                                    <option value="Shares/Securities">Shares/Securities</option>
                                                                    <option value="Others">Others</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id='father' className="form-group">
                                                                <label className='form-label'>Father</label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder='Enter here'
                                                                    name="father"
                                                                    value={item.father}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                />

                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id='mother' className="form-group">
                                                                <label className='form-label'>Mother</label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder='Enter here'
                                                                    name="mother"
                                                                    value={item.mother}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id='spouse' className="form-group">
                                                                <label className='form-label'>Spouse</label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder='Enter here'
                                                                    name="spouse"
                                                                    value={item.spouse}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id='self' className="form-group">
                                                                <label className='form-label'>Self</label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder='Enter here'
                                                                    name="self"
                                                                    value={item.self}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id='brotherSisterChildren' className="form-group">
                                                                <label className='form-label'>Brother/Sister/Children</label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder='Enter here'
                                                                    name="brotherSisterChildren"
                                                                    value={item.brotherSisterChildren}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group id='total' className="form-group">
                                                                <label className='form-label'>Total</label>
                                                                <Form.Control
                                                                    type="number"
                                                                    value={item.total}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>

                                                        {i > 0 && (
                                                            <div className='d-flex jc-end'>
                                                                <button onClick={() => removeTPDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row">
                                                <div className="col-12 vertically_center">
                                                    <Form.Group id='assetstotal' className="form-group">
                                                        <label className='form-label'>Asset's Total</label>
                                                        <Form.Control type="number" value={data.total} disabled />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='d-flex jc-between gap-2 mt-4'>
                        <button className='btn prev_btn' onClick={() => navigate(`#${"financial_information"}`)}>Previous</button>
                        <button className='btn next_btn' type='submit'>Save and Next</button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Additional_Information
