import React, { useEffect, useState } from 'react'
import NavSidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Tabs from '../../../components/tabs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import TAT_Fresh from './TAT_Fresh';
import { Helmet } from 'react-helmet';
import { IoArrowBack } from 'react-icons/io5';
import TAT_Renewal from './TAT_Renewal';
import { TATAnalysisMonthlyFresh, TATAnalysisMonthlyRenewal, repaymentAnalysis } from '../../../ContextAPI/APIs/api';
import UserModal from '../../../components/Modals/Modal';
import { useToast } from '../../../ContextAPI/Components/toast';

function TATMonthlyAnalysis() {

    const { alert } = useToast();
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };


    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const [tabsD, setTabs] = useState([
        {
            tabName: "Fresh Case",
            pathName: ""
        },
        {
            tabName: "Renewal Case",
            pathName: "#renewal-cases"
        },
    ])

    const tabs = [
        {
            tabName: "Case Detail",
            pathName: "#cases-detail"
        },
        {
            tabName: "Interview Sheet",
            pathName: "#interview-sheet"
        },
        {
            tabName: "Recommendation Form",
            pathName: "#recomendation-form"
        },
        {
            tabName: "Approval Form",
            pathName: "#approval-form"
        },
    ]

    const Mob_tabs = [
        {
            tabName: "Fresh Case",
            pathName: "#fresh-cases"
        },
        {
            tabName: "Renewal Case",
            pathName: "#renewal-cases"
        },
    ]

    const navigate = useNavigate();
    const { hash } = useLocation();
    const [showTab, setShowTab] = useState("");


    useEffect(() => {
        setShowTab(hash)
    }, [hash])



    const [freshData, setFreshData] = useState([])
    const [renewalData, seRenewalData] = useState([])

    const [freshPercentages, setFreshPercentages] = useState({})
    const [renewalPercentages, setRenewalPercentages] = useState({})


    const [resMonth, setResMonth] = useState("")
    const [resYear, setResYear] = useState("")


    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(skip)
    }

    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = currentYear; year >= 2010; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }


    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    // Handler function to handle option change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        // Do something with the selected year
        setYear(event.target.value)

        console.log("Selected year:", selectedYear);
    };

    const handleMonthChange = (event) => {
        // Do something with the selected year
        setMonth(event.target.value)

    };




    const fetchFresh = async () => {
        try {
            const response = await TATAnalysisMonthlyFresh({
                month: month,
                year: year
            })

            console.log("responseFR", response);
            if (response.success && response.message.length > 0) {
                console.log("KHKH",response.message.length);
                setFreshData(response.message)

                setFreshPercentages(response.percentages)
                setResYear(response.year)
                setResMonth(response.month)
            }
            else {
                
                console.log("NONONO");
                setFreshData([])
                setFreshPercentages({})
                setResYear("")
                setResMonth("")
            }

            if (!response.success) {
                alert(response.message, response.success)
            }

            handleClose()
        }
        catch (e) {
            console.log(e);
        }
    }




    const fetchRenewal = async () => {
        try {
            const response = await TATAnalysisMonthlyRenewal({
                month: month,
                year: year
            })
            console.log("responseRR", response);
            if (response.success && response.message.length > 0) {
                // console.log("KHKH");
                console.log("KHKH",response.message.length);
                seRenewalData(response.message)
                setRenewalPercentages(response.percentages)
                setResYear(response.year)
                setResMonth(response.month)
            }
            else {
                console.log("NONONO");
                seRenewalData([])
                setRenewalPercentages({})
                setResYear("")
                setResMonth("")
            }
            handleClose()
        }
        catch (e) {
            console.log(e);
        }
    }



    const search = (e) => {
        e.preventDefault();

        fetchFresh()
        fetchRenewal()
    }

    console.log();

    return (
        <>

            <Helmet>
                <title>TAT Fresh</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section'>

                        <div className='d-flex jc-between ai-center mb-4 flex_heading_button'>

                            <div className='d-flex ai-center'>
                                <Link to="/reporting">
                                    <IoArrowBack className='back_arrow_icon me-3' />
                                </Link>
                                <h2 className='mb-0'>TAT Monthly Analysis</h2>
                            </div>

                            <div className='d-flex jc-end buttons'>
                                <UserModal btnText={"Filter"} heading="Filter" id={"filter"} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Month <span className='required'>*</span></label>
                                                <select className='form-select' value={month} id="yearSelect" onChange={handleMonthChange}>
                                                    <option selected disabled value="">Select Month</option>
                                                    <option value="January">January</option>
                                                    <option value="February">February</option>
                                                    <option value="March">March</option>
                                                    <option value="April">April</option>
                                                    <option value="May">May</option>
                                                    <option value="June">June</option>
                                                    <option value="July">July</option>
                                                    <option value="August">August</option>
                                                    <option value="September">September</option>
                                                    <option value="October">October</option>
                                                    <option value="November">November</option>
                                                    <option value="December">December</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Year <span className='required'>*</span></label>
                                                <select value={year} className='form-select' id="yearSelect" onChange={handleYearChange}>
                                                    <option selected disabled value="">Select Year</option>
                                                    {yearOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='d-flex jc-end'>
                                            <button onClick={(e) => search(e)} className='btn save_btn'>Save</button>
                                        </div>
                                    </div>
                                </UserModal>
                            </div>

                        </div>

                        <div className='card mb-4'>
                            {
                                freshData.length >= 2 && renewalData.length >= 2 ?

                                    <>
                                        <Tabs tabLinks={isMobile ? Mob_tabs : tabsD} show={showTab} setShow={setShowTab} />

                                        {
                                            (showTab == "#fresh-cases" || showTab == "") &&
                                            <TAT_Fresh data={freshData} percent={freshPercentages} month={resMonth} year={resYear} />
                                        }
                                        {
                                            showTab == "#renewal-cases" &&
                                            <TAT_Renewal data={renewalData} percent={renewalPercentages} month={resMonth} year={resYear} />
                                        }

                                    </>
                                    :

                                    <>
                                        <h5 className='not_found_msg'>No Records Found</h5>
                                    </>
                            }


                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}

export default TATMonthlyAnalysis
