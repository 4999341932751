import React, { useEffect, useState } from 'react'
import { useAuth } from "../../ContextAPI/Components/auth"
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, sidebarClasses } from 'react-pro-sidebar';

import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'

import { MdOutlinePieChartOutline } from 'react-icons/md';
import { TbClock24, TbReportAnalytics } from 'react-icons/tb';
import { IoCalendarOutline } from 'react-icons/io5';
import { TfiBarChart } from 'react-icons/tfi';

import CountUp from 'react-countup';

import { getAllApplications, getDashboardData, AdminDashboardGraph, getFreshCasesOnly, getRenewalCasesOnly } from '../../ContextAPI/APIs/api';

import Badges from '../../components/Badges';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';
import TooltipComponent from '../../components/tooltip';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import Pagination from '../../components/Pagination';
import IconText from '../../components/Table/IconText';
import { Show_Profile_Pic } from '../../components/Profile/upload_profilePic';
import { LiaUniversitySolid } from 'react-icons/lia';
import { PiStudentFill } from 'react-icons/pi';
import StackedAreaChart from '../../components/Charts/StackedAreaChart';


function AdminDashboard() {
    const { user } = useAuth()
    const navigate = useNavigate();

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    // COUNTER ANIMATION
    let valueDisplays = document.querySelectorAll(".num");
    let interval = 3000;

    console.log(valueDisplays);

    valueDisplays.forEach((valueDisplay) => {
        let startValue = 0;
        let endValue = parseInt(valueDisplay.getAttribute("data-val"));

        console.log(endValue);

        let duration = Math.floor(interval / endValue);

        let counter = setInterval(function () {
            startValue += 1;
            valueDisplay.textContent = startValue;
            if (startValue == endValue) {
                clearInterval(counter);
            }
        }, duration);
    })

    const [applications, setApp] = useState([])
    const [freshCases, setFreshCases] = useState([])
    const [renewalCases, setRenewalCases] = useState([])
    const [studentsCount, setStudentsCount] = useState(0)
    const [universitiesCount, setUniversitiesCount] = useState(0)
    const [freshCasesCount, setFreshCasesCount] = useState(0)
    const [renewalCasesCount, setRenewalCasesCount] = useState(0)

    const getAppData = async () => {
        try {
            const data = await getDashboardData()
            console.log("Response", data);
            console.log("students", data.students);
            console.log("universities", data.universities);
            console.log("freshCases", data.freshCases);
            console.log("renewalCases", data.renewalCases);

            if (data.success) {
                setStudentsCount(data.students)
                setUniversitiesCount(data.universities)
                setFreshCasesCount(data.freshCases)
                setRenewalCasesCount(data.renewalCases)
            }


            const response = await getAllApplications(5)
            console.log("Response", response);
            if (response.success) {
                setApp(response.message)
            }

            const response2 = await getFreshCasesOnly("interview", 2)
            console.log("Response2", response2);
            if (response2.success) {
                setFreshCases(response2.message)
            }

            const response3 = await getRenewalCasesOnly("interview", 2)
            console.log("Response3", response3);
            if (response3.success) {
                setRenewalCases(response3.message)
            }


        }
        catch (e) {
            console.log(e);
        }
    }

    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp?.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate
        }
        else {
            return "---"
        }
    }

    const [graphData, setGraphData] = useState({})

    const getGraphData = async () => {
        try {
            const response = await AdminDashboardGraph()
            if (response.success) {
                console.log("RESPONSE GRAPH", response);
                setGraphData(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getAppData()
        getGraphData()
    }, [])

    function convertTimestampToDateTime(timestamp) {
        // Create a new Date object
        const date = new Date(timestamp);

        // Extract the date, hours, and minutes
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12 || 12;

        // Construct the formatted date string
        const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }

    return (
        <>

            <Helmet>
                <title>Ihsan Trust | Admin Dashboard</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>

                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='admin_dashboard_section'>

                        <h2>Dashboard</h2>

                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="grid_row_4 mb-2">
                                            <div className='mb-2'>
                                                <div className="card grid_card h-100">
                                                    <div className="content">
                                                        <div>
                                                            <PiStudentFill className='grid_icon' style={{ color: "#924AEF" }} />
                                                            <p>No. of Students</p>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <CountUp start={0} end={studentsCount} decimal="." decimals={0} prefix="" duration={1.75} />
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-2'>
                                                <div className="card grid_card h-100">
                                                    <div className="content">
                                                        <div>
                                                            <LiaUniversitySolid className='grid_icon' style={{ color: "#17a2b8" }} />
                                                            <p>No. of Universities</p>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <span><CountUp start={0} end={universitiesCount} decimal="." decimals={0} prefix="" duration={1.75} /></span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-2'>
                                                <div className="card grid_card h-100 mb-0">
                                                    <div className="content">
                                                        <div>
                                                            <IoCalendarOutline className='grid_icon' style={{ color: "#28a745" }} />
                                                            <p>No. of Fresh Cases</p>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <span><CountUp start={0} end={freshCasesCount} decimal="." decimals={0} prefix="" duration={1.75} /></span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-2'>
                                                <div className="card grid_card h-100 mb-0">
                                                    <div className="content">
                                                        <div>
                                                            <IoCalendarOutline className='grid_icon' style={{ color: "#e742b9" }} />
                                                            <p>No. of Renewal Cases</p>
                                                        </div>
                                                        <div>
                                                            <h4>
                                                                <span><CountUp start={0} end={renewalCasesCount} decimal="." decimals={0} prefix="" duration={1.75} /></span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="row schedule_row">

                                            <div className={`col-lg-${(freshCases.length == 0 && renewalCases.length == 0) ? 12 : 8} mb-3`}>
                                                <div className="card mb-3">
                                                    <h4 className=''>Application Recieved</h4>
                                                    <StackedAreaChart graphData={graphData} />
                                                </div>

                                                <div className="card table_card">
                                                    <h4 className='h2_lg'>Fresh Cases</h4>
                                                    <h4 className='h2_md d-none'>Fresh Cases</h4>
                                                    {
                                                        applications?.length > 0 ?
                                                            <Table head={["View Form", "Student Name", "University Name", "Status", "Submission Date"]}>
                                                                {
                                                                    applications?.map((data, i) => {
                                                                        return <tr key={i}>
                                                                            <td>
                                                                                <div><TbReportAnalytics className='icon' onClick={() => navigate(`/application/${data?.userId._id}/${data?._id}`)} /></div>
                                                                            </td>
                                                                            <td className='text-capital'><Text text={data?.userId?.fullName || "---"} /></td>
                                                                            <td><Text text={data?.university || "---"} /></td>
                                                                            <td><Badges text={data?.status || "---"} classes={`${data?.status || "pending"} md`} /></td>
                                                                            <td><Text text={timestampToDate(data?.timestamp) || "---"} /></td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </Table>
                                                            :
                                                            <h5 className='not_found_msg'>No Records Found</h5>
                                                    }

                                                    {/* <Pagination itemsPerPage={4} /> */}
                                                </div>

                                            </div>

                                            <div className="col-lg-4 col-md-12">
                                                <div className="row">
                                                    {
                                                        freshCases.length > 0 &&
                                                        <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                                                            <div className="card schedule_card_main">
                                                                <div className='d-flex ai-center mb-2'>
                                                                    <h4 className='me-2 mb-0'>Fresh Cases</h4>
                                                                    <Badges text="Interview" classes="interview sm" />
                                                                </div>
                                                                {
                                                                    freshCases.length > 0 &&
                                                                    freshCases.map((item) => {
                                                                        return <>
                                                                            <div key={item._id} className="schedule_card">
                                                                                <Link to={`/fresh-cases-history/${item?.userId?._id}/${item._id}#cases-detail`} className='schedule_link'>
                                                                                    <div className='d-flex ai-center'>
                                                                                        <Show_Profile_Pic src={item?.userId?.profilepic} classes="md me-2" />
                                                                                        <div>
                                                                                            <h6>Meeting with {item?.userId?.fullName}</h6>
                                                                                            <div className='d-flex ai-center'>
                                                                                                <FaRegCalendarAlt className='gray_icon' />
                                                                                                <p className='mb-0 ml-2'>{convertTimestampToDateTime(item?.InterviewScheduled)}</p>
                                                                                                {/* <p className='mb-0 ml-2'>{moment(item?.InterviewScheduled).format("MMM Do YYYY")}</p> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>

                                                                        </>
                                                                    })
                                                                }
                                                                <Link to={`/cases/fresh-cases?filter=interview`} className='schedule_link'>
                                                                    <p className='show_more'>Show More</p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        renewalCases.length > 0 &&
                                                        <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                                                            <div className="card schedule_card_main">
                                                                <div className='d-flex ai-center mb-2'>
                                                                    <h4 className='me-2 mb-0'>Renewal Cases</h4>
                                                                    <Badges text="Interview" classes="interview sm" />
                                                                </div>
                                                                {/* <div className="schedule_card"> */}
                                                                <div className="">
                                                                    {
                                                                        renewalCases.length > 0 &&
                                                                        renewalCases.map((item) => {
                                                                            return <> <div key={item._id} className="schedule_card">
                                                                                <Link to={`/renewal-cases-history/${item?.userId?._id}/${item._id}#cases-detail`} className='schedule_link'>
                                                                                    <div className='d-flex ai-center'>
                                                                                        <Show_Profile_Pic src={item?.userId?.profilepic} classes="md me-2" />
                                                                                        <div>
                                                                                            <h6>Meeting with {item?.userId?.fullName}</h6>
                                                                                            <div className='d-flex ai-center'>
                                                                                                <FaRegCalendarAlt className='gray_icon' />
                                                                                                <p className='mb-0 ml-2'>{convertTimestampToDateTime(item?.InterviewScheduled)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>

                                                                            </>
                                                                        })
                                                                    }
                                                                    <Link to={`/cases/renewal-cases?filter=interview`} className='schedule_link'>
                                                                        <p className='show_more'>Show More</p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                </div >
            </div >
        </>
    )
}

export default AdminDashboard