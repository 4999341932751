import React from 'react'
import { useState, useEffect } from 'react';
import { Next } from 'react-bootstrap/esm/PageItem';
import { useLocation, useNavigate } from 'react-router-dom';

import { getForm7, getFormsByAdmin, SubmissionForm7 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"


function Step_8(props) {

    const { GetLoginUser } = useAuth();
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);



    const [assetDetails, setAssetDetails] = useState({
        airCondition: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        car: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        motorCycle: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        television: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        computerLaptop: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
    });



    // Function to handle input changes
    const handleInputChange = (item, field, value) => {
        setAssetDetails(prevState => ({
            ...prevState,
            [item]: {
                ...prevState[item],
                [field]: value,
            },
        }));
    };

    const [data, setData] = useState({
        fatherBusiness: "",
        motherBusiness: "",
        spouseBusiness: "",
        selfBusiness: "",
        relativeBusiness: "",
        TotalBusiness: "",
        fatherLand: "",
        motherLand: "",
        spouseLand: "",
        selfLand: "",
        relativeLand: "",
        TotalLand: "",
        fatherSaving: "",
        motherSaving: "",
        spouseSaving: "",
        selfSaving: "",
        relativeSaving: "",
        TotalSaving: "",
        fatherShare: "",
        motherShare: "",
        spouseShare: "",
        selfShare: "",
        relativeShare: "",
        TotalShare: "",
        fatherOtherAssets: "",
        motherOtherAssets: "",
        spouseOtherAssets: "",
        selfOtherAssets: "",
        relativeOtherAssets: "",
        TotalOtherAssets: "",
        fatherTotal: "",
        motherTotal: "",
        spouseTotal: "",
        selfTotal: "",
        relativeTotal: "",
        TotalTotalBusiness: "",
    })

    const handleInput2Change = (e, category, person) => {
        const { value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [`${person}${category}`]: value,
        }));
    };

    const Next = async () => {
        try {


            // console.log("assetDetails", assetDetails);

            // const payload = {
            //     ...data,
            //     HouseHoldAssets: [
            //         assetDetails.airCondition,
            //         assetDetails.car,
            //         assetDetails.motorCycle,
            //         assetDetails.television,
            //         assetDetails.computerLaptop
            //     ]
            // }

            // console.log("DATA", payload);
            // const response = await SubmissionForm7(payload)
            // if (response.success) {
            //     alert(response.message)
            navigate(`#${"step_9"}`)
            // GetLoginUser()
            // }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getFormData = async () => {
        try {
            // const response = await getForm7()
            const response = await getFormsByAdmin(7, props.id)
            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
                // setFamilyMembStd(response.message.familyMembersStudying)
                setAssetDetails({
                    ...assetDetails,
                    airCondition: response.message.HouseHoldAssets[0],
                    car: response.message.HouseHoldAssets[1],
                    motorCycle: response.message.HouseHoldAssets[2],
                    television: response.message.HouseHoldAssets[3],
                    computerLaptop: response.message.HouseHoldAssets[4]
                })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()
    }, [])

    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        {/* <form> */}
                        <div className="row">
                            <div className="table_form mb-4">
                                <h4>Details of Asset / Property (please mention the current market value in Rs.)</h4>
                                <h5>House Hold Items</h5>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Yes/No</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Current Value (Rs.)</th>
                                                <th scope="col">Registration No.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(assetDetails).map(item => (
                                                <tr key={item}>
                                                    <th scope='row'>{item}</th>
                                                    <td><input disabled value={assetDetails[item].yesNo} type="text" className='form-control' placeholder='Enter here' onChange={(e) => handleInputChange(item, 'yesNo', e.target.value)} /></td>
                                                    <td><input disabled value={assetDetails[item].quantity} type="number" className='form-control' placeholder='Enter here' onChange={(e) => handleInputChange(item, 'quantity', parseInt(e.target.value, 10))} /></td>
                                                    <td><input disabled value={assetDetails[item].currentValue} type="number" className='form-control' placeholder='Enter here' onChange={(e) => handleInputChange(item, 'currentValue', parseInt(e.target.value, 10))} /></td>
                                                    <td><input disabled value={assetDetails[item].registrationNo} type="number" className='form-control' placeholder='Enter here' onChange={(e) => handleInputChange(item, 'registrationNo', parseInt(e.target.value, 10))} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="table_form mb-4">
                                <h5>Total Value of Property Owned (Rs.):</h5>
                                <div className="table-responsive">
                                    <table className="table table-bordered" style={{ width: "150%" }}>
                                        <thead>
                                            <tr>
                                                <th scope="col">Value of Asset</th>
                                                <th scope="col">Father</th>
                                                <th scope="col">Mother</th>
                                                <th scope="col">Spouse</th>
                                                <th scope="col">Self</th>
                                                <th scope="col">Brother/Sister/Children</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope='row'>Business</th>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.fatherBusiness} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.motherBusiness} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.spouseBusiness} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.selfBusiness} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.relativeBusiness} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.TotalBusiness} /></td>
                                            </tr>

                                            <tr>
                                                <th scope='row'>Land and Building</th>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.fatherLand} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.motherLand} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.spouseLand} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.selfLand} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.relativeLand} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.TotalLand} /></td>
                                            </tr>

                                            <tr>
                                                <th scope='row'>Saving a/c and Deposits</th>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.fatherSaving} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.motherSaving} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.spouseSaving} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.selfSaving} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.relativeSaving} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.TotalSaving} /></td>
                                            </tr>

                                            <tr>
                                                <th scope='row'>Shares/Securities</th>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.fatherShare} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.motherShare} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.spouseShare} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.selfShare} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.relativeShare} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.TotalShare} /></td>
                                            </tr>

                                            <tr>
                                                <th scope='row'>Others</th>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.fatherOtherAssets} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.motherOtherAssets} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.spouseOtherAssets} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.selfOtherAssets} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.relativeOtherAssets} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.TotalOtherAssets} /></td>
                                            </tr>

                                            <tr>
                                                <th scope='row'>Total</th>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.fatherTotal} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.motherTotal} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.spouseTotal} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.selfTotal} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data.relativeTotal} /></td>
                                                <td className='bg-gray'><input disabled type="number" className='form-control' placeholder='Enter here' value={data.TotalTotalBusiness} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                        {/* </form> */}

                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"step_7"}`)}>Previous</button>
                            <button className='btn next_btn' onClick={() => Next()}>Next</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Step_8