import React from 'react'
import ReactApexChart from "react-apexcharts"

function StackedAreaChart(props) {

    const { freshCasesQTY, RenewalCasesQTY, RenewalCasesDates } = props.graphData;

    if (!freshCasesQTY || !RenewalCasesQTY || !RenewalCasesDates) {
        // Handle the case when data is not available
        return <div>Data is not available.</div>;
    }

    const data = {
        // applications: [
        //     {
        //         data: props.graphData.freshCasesQTY,
        //     },
        //     {
        //         data: props.graphData.RenewalCasesQTY,
        //     },
        // ],
        series: [{
            name: 'Fresh',
            data: props.graphData.freshCasesQTY,
            // data: [0, 0, 0, 0, 1, 1, 0],
        }, {
            name: 'Renewal',
            data: props.graphData.RenewalCasesQTY,
            // data: [0, 0, 0, 0, 1, 1, 0]
        }],

        options: {
            tooltip: {
                enabled: true,
                fillSeriesColor: false
            },
            colors: ["#924AEF", "#e328af"], //Add this line
            dataLabels: {
                enabled: false,
            },

            chart: {
                height: 350,
                type: 'line'
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                markers: {
                    fillColors: ["#924AEF", "#e328af"],
                },
            },

            stroke: {
                // curve: 'straight',
                curve: 'smooth',
                colors: ["#924AEF", "#e328af"],
            },
            xaxis: {
                // type: 'datetime',
                // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                categories: props.graphData.RenewalCasesDates,
            },
            // tooltip: {
            //     x: {
            //         format: 'dd/MM/yy HH:mm'
            //     },
            // },
        },

    }

    return (
        <>
            <ReactApexChart
                height={"350px"}
                options={data.options}
                series={data.series}
                type="line"
                className="LineChart"
            />
        </>
    )
}

export default StackedAreaChart

