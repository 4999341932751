import React from 'react'
import { FaFacebook, FaFacebookF, FaGlobe, FaHeadphones, FaInstagram, FaLinkedin, FaLinkedinIn, FaQuestion, FaWhatsapp } from 'react-icons/fa'

import TooltipComponent from "../components/tooltip"

function SocialWidget() {
    return (
        <>

            {/* Desktop View Widget */}

            <div className="side_widget">
                <a href="https://ihsantrust.org/" target='_blank' className='icons'>
                    <FaGlobe className='home_icon' />
                </a>
                <a href="http://www.facebook.com/ihsantrust" target='_blank' className='icons'>
                    <FaFacebook className='fb_icon' />
                </a>
                <a href="http://www.instagram.com/ihsantrust__" target='_blank' className='icons'>
                    <FaInstagram className='inst_icon' />
                </a>
                <a href="https://www.linkedin.com/company/ihsan-trustlinkenin/" target='_blank' className='icons'>
                    <FaLinkedin className='linked_icon' />
                </a>
                <a href="https://api.whatsapp.com/send?phone=+923310044726" target='_blank' className='icons'>
                    <FaWhatsapp className='wapp_icon' />
                </a>
                <a href="https://ihsantrust.org/contact-us/" target='_blank' className='icons'>
                    <FaHeadphones className='phone_icon' />
                </a>
                <a href="https://ihsantrust.org/how-we-work/#faq" target='_blank' className='icons'>
                    <FaQuestion className='faq_icon' />
                </a>
            </div>

            {/* Mobile View Widget */}

            <div className="sbuttons mob_side_widget">

                <a href="https://ihsantrust.org/" target="_blank" className="sbutton home"><i><FaGlobe /></i></a>

                <a href="http://www.facebook.com/ihsantrust" target="_blank" className="sbutton fb"><i><FaFacebookF /></i></a>

                <a href="http://www.instagram.com/ihsantrust__" target="_blank" className="sbutton instagram"><i><FaInstagram /></i></a>

                <a href="https://www.linkedin.com/company/ihsan-trustlinkenin/" target="_blank" className="sbutton linkedin"><i><FaLinkedinIn /></i></a>

                <a href="https://api.whatsapp.com/send?phone=+923310044726" target="_blank" className="sbutton whatsapp"><i><FaWhatsapp /></i></a>

                <a href="https://ihsantrust.org/contact-us/" target="_blank" className="sbutton contact"><i><FaHeadphones /></i></a>

                <a href="https://ihsantrust.org/how-we-work/#faq" target="_blank" className="sbutton faq"><i><FaQuestion /></i></a>

                <a target="_blank" className="sbutton mainsbutton"><i class="fa-solid fa-plus"></i></a>

            </div>


        </>
    )
}

export default SocialWidget
