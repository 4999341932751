import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAppDocuments, getFreshCaseApp, SubmissionForm1 } from '../../ContextAPI/APIs/api';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useToast } from '../../ContextAPI/Components/toast'
import { FaPlus } from 'react-icons/fa';
import ImageUploadInput from '../Modals/ImageUploadInput';

import { CNICUrl, DeathCertificateUrl, DomicileUrl, passimageUrl } from '../../helpers/data';

import Form from 'react-bootstrap/Form';

import PERSONAL_INFO from "../../assets/form-icons/personal-information.png"
import FAMILY_INFO from "../../assets/form-icons/family-information.png"
import { FileViewerAdmin } from '../Form/fileViewerAdmin';
import { User_Profile_Pic, Show_Profile_Pic } from '../Profile/upload_profilePic';


function Demographics_InformationViewOnly({ docs }) {

    const { id, doc } = useParams();

    const { alert } = useToast();
    const { user } = useAuth();
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");
    const [form, setForm] = useState({})

    // Family Details
    const [siblingDetail, setSiblingDetail] = useState([]);
    const [childrenDetail, setChildrenDetail] = useState([]);

    const [familyDetail, setfamilyDetail] = useState([
        {
            S_No: "",
            Name_of_Family_Member: "",
            Relationship: "",
            Age: "",
            Marital_Status: "",
            Cell_No: "",
            Occupation: "",
            School_Institute_Office: "",
        },
    ]);

    const f_detail = {
        S_No: "",
        Name_of_Family_Member: "",
        Relationship: "",
        Age: "",
        Marital_Status: "",
        Cell_No: "",
        Occupation: "",
        School_Institute_Office: "",
    }

    function Add_New_F_Detail(e) {
        e.preventDefault();
        setfamilyDetail([...familyDetail, f_detail])
    }

    function removeDetail(i) {

        const updated = [...familyDetail]

        updated.splice(i, 1)
        setfamilyDetail(updated)

    }

    function handleInputChange(e, index, field) {
        const updatedFamilyDetail = [...familyDetail];
        updatedFamilyDetail[index][field] = e.target.value;
        setfamilyDetail(updatedFamilyDetail);
    }

    const [data, setData] = useState({
        fullName: "",
        fatherName: "",
        cnic: "",
        DOB: "",
        maritalStatus: "",
        cellNo: user?.phone || "",
        ptcl: user?.phone2 || "",
        whatsapp: user?.phone3 || "",
        presentAddress: "",
        permanentAddress: "",
        q1: "",
        q2: "",
    })

    const [questions, setQuestions] = useState({
        question23: "",
        question24: "",
    })

    const getFormData = async () => {
        try {

            console.log("formdatachalgya");

            const response = await getFreshCaseApp(
                {
                    id: doc,
                    form: "form1"
                }
            )

            console.log("fomr111111", response);
            console.log("DDDDDD", response.message);
            if (response.success) {


                setData(response.message)
                setFatherData(response.message?.familyDetails[0])
                setMotherData(response.message?.familyDetails[1])
                setSpouseData(response.message?.familyDetails[2])
                setGuardianData(response.message?.familyDetails[3])

                setChildrenDetail(response.message?.ChildrenDetails)
                setSiblingDetail(response.message?.SiblingDetails)
                setfamilyDetail(response.message?.otherMembers)
                // setFatherData()
                // setForm(response.message)
                // setData({ ...response.message, maritalStatus: { label: response.message.maritalStatus, value: response.message.maritalStatus } })
                // setFatherData(response.message.familyDetails1[0])
                // setMotherData(response.message.familyDetails1[1])
                // setSpouseData(response.message.familyDetails1[2])
                // setQuestions({
                //     ...questions,
                //     question23: response.message.question23,
                //     question24: response.message.question24
                // })
                // setfamilyDetail(response.message.familyDetails2)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;

    //     setData((prevData) => ({
    //         ...prevData,
    //         [name]: type === "checkbox" ? checked : value,
    //     }));
    // };

    const [fatherData, setFatherData] = useState({
        name: '',
        email: '',
        cellNo: '',
        cnic: '',
        alive: '',
        occupation: '',
        designation: '',
        organization: '',
    });

    const [motherData, setMotherData] = useState({
        name: '',
        email: '',
        cellNo: '',
        cnic: '',
        alive: '',
        occupation: '',
        designation: '',
        organization: '',
    });

    const [guardianData, setGuardianData] = useState({
        haveGuardian: false,
        name: '',
        relation: '',
        email: '',
        cellNo: '',
        cnic: '',
        occupation: '',
        designation: '',
        organization: '',
        employemntStatus: "",
    });

    const [spouseData, setSpouseData] = useState({
        name: '',
        email: '',
        cellNo: '',
        cnic: '',
        alive: '',
        occupation: '',
        designation: '',
        organization: '',
    });

    const handleFatherChange = (e, field) => {
        setFatherData({
            ...fatherData,
            [field]: e.target.value,
        });
    };

    const handleMotherChange = (e, field) => {
        setMotherData({
            ...motherData,
            [field]: e.target.value,
        });
    };

    const handleGuardianChange = (e, field) => {
        setGuardianData({
            ...guardianData,
            [field]: e.target.value,
        });
    };

    const handleSpouseChange = (e, field) => {
        setSpouseData({
            ...spouseData,
            [field]: e.target.value,
        });
    };

    // const SELECTUNI = (e) => {
    //     try {
    //         setData({ ...data, institute: e.target.value })
    //         getAllData(e.target.value)

    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    // const [program, setProgram] = useState([])
    // const [uni, setUni] = useState([])
    // const [dis, setDis] = useState(true)


    // const getAllData = async (id) => {
    //     try {
    //         const response = await GetUnisProfileForStudent({ uniId: id })
    //         console.log("REPO", response);

    //         if (response.success) {
    //             setProgram(response?.message?.programs)
    //             setDis(false)
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    // const getAllUni = async () => {
    //     try {
    //         const response = await getUNI()

    //         if (response?.success) {
    //             console.log("UNISS", response.message);
    //             setUni(response?.message)
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    // useEffect(() => {
    //     getAllUni()
    //     getFormData()
    // }, [])

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);




    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // console.log("DATA", data);

        const payload = {
            ...data,
            familyDetails: [fatherData, motherData, guardianData, spouseData],
            otherMembers: familyDetail
        }

        console.log("PPAAYYLLOOAADDD", payload);

        const response = await SubmissionForm1(payload)
        alert(response.message)

        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.stopPropagation();
        // }

        // setValidated(true);
        // navigate(`#${"academic_background"}`)
    };

    const [isFatherAliveDeath, setIsFatherAliveDeath] = useState()
    const [isMotherAliveDeath, setIsMotherAliveDeath] = useState()
    const [isSpouseAliveDeath, setIsSpouseAliveDeath] = useState()

    const [maritalStatus, setMaritalStatus] = useState("")


    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const [form8, setForm8] = useState({})

    const getDocument = async () => {
        try {
            const response = await getAppDocuments({ id: id })
            console.log("RESPONSE23", response);
            if (response.success) {
                setForm8(response?.message)
                console.log("AAA", response?.message);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()
        getDocument()
    }, [])

    return (
        <>
            <div className="mb-4">

                <div className="preview_heading">
                    <h5>Demographics Information</h5>
                </div>

                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        {/* Personal Information */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <img src={PERSONAL_INFO} alt="icon" className='img-fluid form_icons' />
                                    Personal Information
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">

                                    <div className="row">

                                        {/* PROFILE IMAGE FOR MOBILE */}
                                        <div className="col-md-2 col-sm-12 mob_profile">
                                            <Form.Group controlId="validationCustomUsername" className='form-group'>
                                                <Form.Label>Profile Pic<span className='required'>*</span></Form.Label>
                                                <User_Profile_Pic />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-5 col-sm-6 col-12 vertically_center vertically_center_name_email">
                                            <Form.Group controlId="validationCustom01" className='form-group'>
                                                <Form.Label>Name <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fullName"
                                                    value={data.fullName}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-5 col-sm-6 col-12 vertically_center vertically_center_name_email">
                                            <Form.Group controlId="validationCustomUsername" className='form-group'>
                                                <Form.Label>Email Address <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={data.email}
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </div>

                                        {/* PROFILE IMAGE FOR DESKTOP */}
                                        <div className="col-md-2 col-sm-12 desk_profile">
                                            <Form.Group controlId="validationCustomUsername" className='form-group'>
                                                <Form.Label>Profile Pic<span className='required'>*</span></Form.Label>
                                                <Show_Profile_Pic src={data?.userId?.profilepic} />
                                            </Form.Group>
                                        </div>

                                        {/* <div className="col-md-4">
                                            <FileViewerAdmin
                                                id={id}
                                                span="*"
                                                text="Two Recent Passport Size Photographs"
                                                url={passimageUrl}
                                                keyName={"passimage"}
                                            />
                                        </div> */}

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom02" className='form-group'>
                                                <Form.Label>Father’s / Guardian Name <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fatherName"
                                                    value={data.fatherName}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom03" className='form-group'>
                                                <Form.Label>Date of Birth <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="DOB"
                                                    value={data.DOB ? new Date(data.DOB).toISOString().split('T')[0] : ''}
                                                    onChange={handleChange}
                                                    disabled
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom04" className='form-group'>
                                                <Form.Label>CNIC No. / B-Form No. <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="cnic"
                                                    value={data.cnic}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-4 col-md-3 col-sm-6 col-12 vertically_center">
                                            <FileViewerAdmin
                                                document={docs}
                                                id={id}
                                                text="Self CNIC / B-Form"
                                                span="*"
                                                url={CNICUrl}
                                                keyName={"selfCnic"}
                                            />
                                        </div>

                                        {
                                            form8.Domicile &&
                                            <div className="col-lg-4 col-md-3 col-sm-6 col-12 vertically_center">
                                                <FileViewerAdmin
                                                    document={docs}
                                                    id={id}
                                                    text="Domicile Certificate"
                                                    url={DomicileUrl}
                                                    keyName={"Domicile"}
                                                />
                                            </div>
                                        }

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom06" className='form-group'>
                                                <Form.Label>Mobile No 1 <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    name="cellNo"
                                                    value={data.cellNo}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </div>

                                        {
                                            data.ptcl != "+92" &&
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                                <Form.Group controlId="validationCustom07" className='form-group'>
                                                    <Form.Label>Mobile No 2</Form.Label>
                                                    <Form.Control
                                                        type="tel"
                                                        name="ptcl"
                                                        value={data.ptcl}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                        }

                                        {
                                            data.whatsapp != "+92" &&
                                            < div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                                <Form.Group controlId="validationCustom08" className='form-group'>
                                                    <Form.Label>Whatsapp No.</Form.Label>
                                                    <Form.Control
                                                        type="tel"
                                                        name="whatsapp"
                                                        value={data.whatsapp}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                        }

                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom05" className='form-group'>
                                                <Form.Label>Marital Status <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="maritalStatus"
                                                    value={data.maritalStatus}
                                                    required
                                                    disabled
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-12">
                                            <Form.Group controlId="validationCustom09" className='form-group'>
                                                <Form.Label>Present Address <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="presentAddress"
                                                    value={data.presentAddress}
                                                    onChange={handleChange}
                                                    required
                                                    disabled
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-12">
                                            <Form.Group controlId="validationCustom09" className='form-group'>
                                                <Form.Label>Permanent Address <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="permanentAddress"
                                                    value={data.permanentAddress}
                                                    onChange={handleChange}
                                                    disabled
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-12">
                                            <Form.Group controlId="validationCustom09" className='form-group'>
                                                <Form.Label>What are your long term personal aspirations? <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="q1"
                                                    value={data.q1}
                                                    onChange={handleChange}
                                                    rows={5}
                                                    // rows={parseInt(data?.q1?.length)/parseInt(120) }
                                                    disabled
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-12">
                                            <Form.Group controlId="validationCustom10" className='form-group'>
                                                <Form.Label>What are your aspirations for your career? <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="q2"
                                                    value={data.q2}
                                                    onChange={handleChange}
                                                    rows={5}
                                                    // rows={parseInt(data?.q2?.length)/parseInt(120) }
                                                    disabled
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* Family Information */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <img src={FAMILY_INFO} alt="icon" className='img-fluid form_icons' />
                                    Family Information
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    {/* FATHER'S DATA */}
                                    <div className="row">

                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom05" id='isFatherAliveDeath' className='form-group'>
                                                <Form.Label>Is Father Alive / Deceased <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={fatherData?.alive}
                                                    onChange={(e) => setIsFatherAliveDeath(e.target.value)}
                                                    disabled
                                                    required
                                                    className='text-capital'
                                                />
                                            </Form.Group>
                                        </div>

                                        {
                                            (fatherData?.alive == "alive") &&
                                            <div className="col-12">
                                                <div className='mb-3 form_sub_heading'>
                                                    <h5 className='mb-0'>Father Details</h5>
                                                </div>
                                            </div>
                                        }

                                        {
                                            fatherData?.alive == "alive" &&
                                            <>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" className='form-group'>
                                                        <Form.Label>Father's Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={fatherData?.name}
                                                            onChange={(e) => handleFatherChange(e, 'name')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                {
                                                    fatherData.email &&
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Father's Email</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={fatherData?.email}
                                                                onChange={(e) => handleFatherChange(e, 'email')}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" className='form-group'>
                                                        <Form.Label>Father's Cell No. <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            value={fatherData?.cellNo}
                                                            onChange={(e) => handleFatherChange(e, 'cellNo')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" className='form-group'>
                                                        <Form.Label>Father's CNIC No. <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={fatherData?.cnic}
                                                            onChange={(e) => handleFatherChange(e, 'cnic')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <FileViewerAdmin
                                                        document={docs}
                                                        id={id}
                                                        span="*"
                                                        text="Father's CNIC Image"
                                                        url={CNICUrl}
                                                        keyName="fatherCnic"
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='fatherData_employemntStatus' className='form-group'>
                                                        <Form.Label>Father Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={fatherData?.employemntStatus}
                                                            onChange={(e) => handleFatherChange(e, 'employemntStatus')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>

                                                {
                                                    (fatherData.employemntStatus !== "" && fatherData.employemntStatus !== "retired" && fatherData.employemntStatus !== "Unemployed") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Father's Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={fatherData?.occupation}
                                                                        onChange={(e) => handleFatherChange(e, 'occupation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Father's Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={fatherData?.designation}
                                                                        onChange={(e) => handleFatherChange(e, 'designation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={fatherData?.organization}
                                                                        onChange={(e) => handleFatherChange(e, 'organization')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        }

                                        {
                                            (fatherData?.alive == "deceased") &&
                                            <>
                                                {
                                                    form8.DeathCertificateFather &&
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                        <FileViewerAdmin
                                                            document={docs}
                                                            id={id}
                                                            text="Death Certificate"
                                                            url={DeathCertificateUrl}
                                                            keyName={"DeathCertificateFather"}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    fatherData.deathDate &&
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom03" id='DOB' className='form-group'>
                                                            <Form.Label>Date of Death </Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                // max={today}
                                                                disabled
                                                                name="deathDate"
                                                                value={fatherData?.deathDate}
                                                                // value={data.DOB ? new Date(data.DOB).toISOString().split('T')[0] : ''}
                                                                onChange={(e) => handleFatherChange(e, 'deathDate')}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>

                                    <div className="section_break"></div>

                                    {/* MOTHER'S DATA */}
                                    <div className="row">

                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                            <Form.Group controlId="validationCustom05" id='isMotherAliveDeath' className='form-group'>
                                                <Form.Label>Is Mother Alive / Deceased <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={motherData?.alive}
                                                    onChange={(e) => setIsMotherAliveDeath(e.target.value)}
                                                    disabled
                                                    required
                                                    className='text-capital'
                                                />
                                            </Form.Group>
                                        </div>

                                        {
                                            (motherData?.alive == "alive") &&
                                            <div className="col-12">
                                                <div className='mb-3 form_sub_heading'>
                                                    <h5 className='mb-0'>Mother Details</h5>
                                                </div>
                                            </div>
                                        }

                                        {
                                            motherData.alive == "alive" &&
                                            <>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" className='form-group'>
                                                        <Form.Label>Mother's Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={motherData?.name}
                                                            onChange={(e) => handleMotherChange(e, 'name')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                {
                                                    motherData?.email &&
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Mother's Email</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={motherData?.email}
                                                                onChange={(e) => handleMotherChange(e, 'email')}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {
                                                    motherData?.cellNo &&
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Mother's Cell No.</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={motherData?.cellNo}
                                                                onChange={(e) => handleMotherChange(e, 'cellNo')}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" className='form-group'>
                                                        <Form.Label>Mother's CNIC No. <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={motherData?.cnic}
                                                            onChange={(e) => handleMotherChange(e, 'cnic')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <FileViewerAdmin
                                                        document={docs}
                                                        id={id}
                                                        span="*"
                                                        text="Mother's CNIC"
                                                        url={CNICUrl}
                                                        keyName="motherCnic"
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='motherData_employemntStatus' className='form-group'>
                                                        <Form.Label>Mother Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={motherData?.employemntStatus || ""}
                                                            onChange={(e) => handleMotherChange(e, 'employemntStatus')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>

                                                {
                                                    (motherData.employemntStatus !== "" && motherData.employemntStatus !== "retired" && motherData.employemntStatus !== "Unemployed") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Mother's Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={motherData?.occupation}
                                                                        onChange={(e) => handleMotherChange(e, 'occupation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Mother's Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={motherData?.designation}
                                                                        onChange={(e) => handleMotherChange(e, 'designation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={motherData?.organization}
                                                                        onChange={(e) => handleMotherChange(e, 'organization')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        }

                                        {
                                            (motherData.alive == "deceased") &&
                                            <>
                                                {
                                                    form8.DeathCertificateMother &&
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                        <FileViewerAdmin
                                                            document={docs}
                                                            id={id}
                                                            text="Death Certificate"
                                                            url={DeathCertificateUrl}
                                                            keyName={"DeathCertificateMother"}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    motherData.deathDate &&
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom03" id='DOB' className='form-group'>
                                                            <Form.Label>Date of Death </Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="deathDate"
                                                                // max={today}
                                                                value={motherData.deathDate}
                                                                disabled
                                                                onChange={(e) => handleMotherChange(e, 'deathDate')}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>

                                    <div className="section_break"></div>

                                    {/* GUARDIAN DATA */}
                                    <div className="row">

                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom01" id='haveGuardian' className='form-group'>
                                                <Form.Label>Do you have any other Guardian besides your mother and father? <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    value={guardianData.haveGuardian ? "yes" : "no"}
                                                    onChange={(e) => handleGuardianChange(e, 'haveGuardian')}
                                                    disabled
                                                    required
                                                    className='text-capital'
                                                />
                                            </Form.Group>
                                        </div>

                                        {
                                            guardianData.haveGuardian &&
                                            <>
                                                <div className="col-12">
                                                    <div className='mb-3 form_sub_heading'>
                                                        <h5 className='mb-0'>Guardian Details</h5>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardianData_name' className='form-group'>
                                                        <Form.Label>Guardian Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={guardianData.name}
                                                            onChange={(e) => handleGuardianChange(e, 'name')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardianData_relation' className='form-group'>
                                                        <Form.Label>Relation <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={guardianData.relation}
                                                            onChange={(e) => handleGuardianChange(e, 'relation')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                {
                                                    guardianData.email &&
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='guardian_email' className='form-group'>
                                                            <Form.Label>Guardian Email</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder=""
                                                                value={guardianData.email}
                                                                onChange={(e) => handleGuardianChange(e, 'email')}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardian_cell' className='form-group'>
                                                        <Form.Label>Guardian Cell No. <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=""
                                                            value={guardianData.cellNo}
                                                            // onChange={handleGuardianCell}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='guardianData_cnic' className='form-group'>
                                                        <Form.Label>Guardian CNIC No. <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={guardianData.cnic}
                                                            onChange={(e) => handleGuardianChange(e, 'cnic')}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <FileViewerAdmin
                                                        document={docs}
                                                        id={id}
                                                        text="Guardian CNIC"
                                                        span="*"
                                                        url={CNICUrl}
                                                        keyName={"cnicGuardian"}
                                                    />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='guardianData_employemntStatus' className='form-group'>
                                                        <Form.Label>Guardian Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={guardianData?.employemntStatus || ""}
                                                            onChange={(e) => handleGuardianChange(e, 'employemntStatus')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                {
                                                    (guardianData?.employemntStatus !== "" && guardianData?.employemntStatus !== "retired" && guardianData?.employemntStatus !== "Unemployed") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='guardianData_occupation' className='form-group'>
                                                                    <Form.Label>Guardian Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={guardianData.occupation}
                                                                        onChange={(e) => handleGuardianChange(e, 'occupation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='guardianData_designation' className='form-group'>
                                                                    <Form.Label>Guardian Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={guardianData.designation}
                                                                        onChange={(e) => handleGuardianChange(e, 'designation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" id='guardianData_organization' className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={guardianData.organization}
                                                                        onChange={(e) => handleGuardianChange(e, 'organization')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        }
                                    </div>

                                    {/* SPOUSE DATA */}
                                    {
                                        data.maritalStatus == "married" &&
                                        <>
                                            <div className="section_break"></div>

                                            <div className="col-12">
                                                <div className='mb-3 form_sub_heading'>
                                                    <h5 className='mb-0'>Spouse Details</h5>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Form.Group controlId="validationCustom01" className='form-group'>
                                                        <Form.Label>Spouse Name <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={spouseData?.name}
                                                            onChange={(e) => handleSpouseChange(e, 'name')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                {
                                                    spouseData?.email &&
                                                    <div className="col-md-4">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Spouse Email</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={spouseData?.email}
                                                                onChange={(e) => handleSpouseChange(e, 'email')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {
                                                    spouseData?.cellNo &&
                                                    <div className="col-md-4">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Spouse Cell No.</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={spouseData?.cellNo}
                                                                onChange={(e) => handleSpouseChange(e, 'cellNo')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                <div className="col-md-4">
                                                    <Form.Group controlId="validationCustom01" className='form-group'>
                                                        <Form.Label>Spouse CNIC No. <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={spouseData?.cnic}
                                                            onChange={(e) => handleSpouseChange(e, 'cnic')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-4">
                                                    <FileViewerAdmin
                                                        document={docs}
                                                        id={id}
                                                        text="Spouse CNIC Image"
                                                        span="*"
                                                        url={CNICUrl}
                                                        keyName={"spouseCnic"}
                                                    />
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom05" id='spouseData_employemntStatus' className='form-group'>
                                                        <Form.Label>Spouse Employment Status <span className='required'>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={spouseData?.employemntStatus || ""}
                                                            onChange={(e) => handleSpouseChange(e, 'employemntStatus')}
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>

                                                {
                                                    (spouseData?.employemntStatus !== "" && spouseData?.employemntStatus !== "retired" && spouseData?.employemntStatus !== "Unemployed") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Spouse Occupation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={spouseData?.occupation}
                                                                        onChange={(e) => handleSpouseChange(e, 'occupation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Spouse Designation <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={spouseData?.designation}
                                                                        onChange={(e) => handleSpouseChange(e, 'designation')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group controlId="validationCustom01" className='form-group'>
                                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={spouseData?.organization}
                                                                        onChange={(e) => handleSpouseChange(e, 'organization')}
                                                                        disabled
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </>
                                    }

                                    {
                                        (isFatherAliveDeath == "death" || isMotherAliveDeath == "death" || isSpouseAliveDeath == "death") &&
                                        < div className="row">
                                            <div className="col-md-12">
                                                <FileViewerAdmin
                                                    document={docs}
                                                    id={id}
                                                    text="Death certificate (if / whenever applicable) of parents, siblings & spouse"
                                                    span="*"
                                                    url={DeathCertificateUrl}
                                                    keyName={"DeathCertificate"}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {/* Family Members Details */}
                                    <div className="table_form">
                                        <div className='mb-4 form_sub_heading'>
                                            <h5 className='mb-0'>Family Member's Details: <br /> Sisters, Brothers (Married, Unmarried, Widow, Widower and Divorced) and Applicant's Childrens</h5>
                                            {/* <button className='btn add_btn' disabled onClick={(e) => Add_New_F_Detail(e)}>Add More Member</button> */}
                                        </div>

                                        <div className="col-12 vertically_center">
                                            <Form.Group controlId="validationCustom01" className='form-group'>
                                                <Form.Label>Number of Siblings <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    disabled
                                                    placeholder=""
                                                    value={data.totalSiblings}
                                                // onChange={(e) => handleInputChange(e, i, 'Age', "sibling")}
                                                />
                                            </Form.Group>
                                        </div>

                                        {siblingDetail.map((data, i) => (
                                            <>
                                                {
                                                    i > 0 &&
                                                    <div className="section_break"></div>
                                                }

                                                <div className="row">
                                                    <p>{siblingDetail[i].heading}</p>
                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Name_of_Family_Member' className='form-group'>
                                                            <Form.Label>Name of Family Member <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={siblingDetail[i].Name_of_Family_Member}
                                                                disabled
                                                                onChange={(e) => handleInputChange(e, i, 'Name_of_Family_Member', "sibling")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Relationship' className='form-group'>
                                                            <Form.Label>Relationship <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled
                                                                placeholder=""
                                                                value={siblingDetail[i].Relationship}
                                                                onChange={(e) => handleInputChange(e, i, 'Relationship', "sibling")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Age' className='form-group'>
                                                            <Form.Label>Age <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                disabled
                                                                placeholder=""
                                                                value={siblingDetail[i].Age}
                                                                onChange={(e) => handleInputChange(e, i, 'Age', "sibling")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='cnic' className='form-group'>
                                                            <Form.Label>CNIC No. / B-Form No. <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled
                                                                placeholder=""
                                                                value={siblingDetail[i].cnic}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {
                                                        siblingDetail[i].Marital_Status &&
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom05" id='Marital_Status' className='form-group'>
                                                                <Form.Label>Marital Status</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    disabled
                                                                    placeholder=""
                                                                    value={siblingDetail[i].Marital_Status}
                                                                    onChange={(e) => handleInputChange(e, i, 'Marital_Status', "sibling")}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    {
                                                        siblingDetail[i].Cell_No &&
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom01" id='Cell_No' className='form-group'>
                                                                <Form.Label>Cell No</Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={siblingDetail[i].Cell_No}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    {
                                                        siblingDetail[i].Occupation &&
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom01" id='Occupation' className='form-group'>
                                                                <Form.Label>Occupation</Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={siblingDetail[i].Occupation}
                                                                    onChange={(e) => handleInputChange(e, i, 'Occupation', "sibling")}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    {
                                                        siblingDetail[i].School_Institute_Office &&
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom01" id='School_Institute_Office' className='form-group'>
                                                                <Form.Label>School/Institute/Office</Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={siblingDetail[i].School_Institute_Office}
                                                                    onChange={(e) => handleInputChange(e, i, 'School_Institute_Office', "sibling")}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    {/* {
                                                        i > 0 &&
                                                        <div className='d-flex jc-end'>
                                                            <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                        </div>
                                                    } */}
                                                </div>
                                            </>
                                        ))}

                                        {
                                            (data.maritalStatus !== "single" && data.maritalStatus) && (
                                                <div className="col-12 vertically_center">
                                                    <Form.Group controlId="validationCustom01" id='haveGuardian' className='form-group'>
                                                        <Form.Label>Number of Children <span className='required'>*</span> </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            disabled
                                                            placeholder=""
                                                            value={data.totalChildrens}
                                                        // onChange={(e) => handleInputChange(e, i, 'Age', "sibling")}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            )
                                        }

                                        {childrenDetail.map((data, i) => (
                                            <>
                                                {
                                                    i > 0 &&
                                                    <div className="section_break"></div>
                                                }

                                                <div className="row">
                                                    <p>{childrenDetail[i].heading}</p>
                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Name_of_Family_Member' className='form-group'>
                                                            <Form.Label>Name of Child <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                placeholder=""
                                                                value={childrenDetail[i].Name_of_Family_Member}
                                                                onChange={(e) => handleInputChange(e, i, 'Name_of_Family_Member', "children")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom01" id='Age' className='form-group'>
                                                            <Form.Label>Age <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="number"
                                                                placeholder=""
                                                                value={childrenDetail[i].Age}
                                                                onChange={(e) => handleInputChange(e, i, 'Age', "children")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {
                                                        childrenDetail[i].cnic &&
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom01" id='cnic' className='form-group'>
                                                                <Form.Label>CNIC No. / B-Form No.</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    disabled
                                                                    placeholder=""
                                                                    value={childrenDetail[i].cnic}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                        <Form.Group controlId="validationCustom05" id='Marital_Status' className='form-group'>
                                                            <Form.Label>Marital Status <span className='required'>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled
                                                                placeholder=""
                                                                value={childrenDetail[i].Marital_Status}
                                                                onChange={(e) => handleInputChange(e, i, 'Marital_Status', "children")}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {
                                                        childrenDetail[i].Cell_No &&
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom01" id='Cell_No' className='form-group'>
                                                                <Form.Label>Cell No</Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={childrenDetail[i].Cell_No}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    {
                                                        childrenDetail[i].Occupation &&
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom01" id='Occupation' className='form-group'>
                                                                <Form.Label>Occupation</Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={childrenDetail[i].Occupation}
                                                                    onChange={(e) => handleInputChange(e, i, 'Occupation', "children")}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    {
                                                        childrenDetail[i].School_Institute_Office &&
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group controlId="validationCustom01" id='School_Institute_Office' className='form-group'>
                                                                <Form.Label>School/Institute/Office</Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={childrenDetail[i].School_Institute_Office}
                                                                    onChange={(e) => handleInputChange(e, i, 'School_Institute_Office', "children")}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    }
                                                    {/* {
                                                        i > 0 &&
                                                        <div className='d-flex jc-end'>
                                                            <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                        </div>
                                                    } */}
                                                </div>
                                            </>
                                        ))}

                                        {/* {familyDetail.map((data, i) => (
                                            <>
                                                {
                                                    i > 0 &&
                                                    <div className="section_break"></div>
                                                }

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Name of Family Member</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={familyDetail[i].Name_of_Family_Member}
                                                                onChange={(e) => handleInputChange(e, i, 'Name_of_Family_Member')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Relationship</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={familyDetail[i].Relationship}
                                                                onChange={(e) => handleInputChange(e, i, 'Relationship')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Age</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={familyDetail[i].Age}
                                                                onChange={(e) => handleInputChange(e, i, 'Age')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Group controlId="validationCustom05" className='form-group'>
                                                            <Form.Label>Marital Status</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={familyDetail[i].Marital_Status}
                                                                onChange={(e) => handleInputChange(e, i, 'Marital_Status')}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Cell No</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={familyDetail[i].Cell_No}
                                                                onChange={(e) => handleInputChange(e, i, 'Cell_No')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>Occupation</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={familyDetail[i].Occupation}
                                                                onChange={(e) => handleInputChange(e, i, 'Occupation')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Group controlId="validationCustom01" className='form-group'>
                                                            <Form.Label>School/Institute/Office</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={familyDetail[i].School_Institute_Office}
                                                                onChange={(e) => handleInputChange(e, i, 'School_Institute_Office')}
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className='d-flex jc-end'>
                                                        <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                    </div>
                                                </div>
                                            </>
                                        ))} */}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>

                </div>

            </div>
        </>
    )
}


export default Demographics_InformationViewOnly
