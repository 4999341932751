import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from "../ContextAPI/Components/auth"
import { useToast } from '../ContextAPI/Components/toast'

import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import LOGO from "../assets/logo.png"

import { FaFacebook, FaInstagram, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import SocialWidget from '../components/SocialWidget'

function SignIn() {
    const { alert } = useToast()
    const { Login, user } = useAuth()
    const navigate = useNavigate()

    const [data, setData] = useState({
        email: "",
        password: "",
    })

    const handleEmail = (e) => {
        setData({ ...data, email: e.target.value })
    }

    const handlePassword = (e) => {
        setData({ ...data, password: e.target.value })
    }

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                email: data?.email,
                password: data?.password
            }

            console.log("PAYLOAD", payload);

            const checkV = validation()

            if (checkV !== "proceed") return

            else {
                const form = e.currentTarget;
                console.log("form.checkValidity()", form.checkValidity());
                console.log("formErrors()", formError);
                if (form.checkValidity() === false) {
                    console.log("--------------------------------------------", e.target);
                }
                console.log("================================", e.target);
                const response = await Login(payload)

                console.log("RESPONSE", response);
                if (response.success) {

                    if (response?.user?.role == "admin" || response?.user?.role == "operations" || response?.user?.role == "finance" || response?.user?.role == "repayment" || response?.user?.role == "viewer") {
                        navigate("/admin-dashboard")
                    } else if (response?.user?.role == "student") {

                        if (response?.user?.step == 0) return navigate("/dashboard")

                        navigate("/dashboard")

                    } else if (response?.user?.role == "university") {
                        navigate("/university-dashboard")

                    } else {
                        navigate("/")

                    }
                }
                alert(response.message, response.success)
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    let errors = {}
    const [formError, setFormError] = useState([])

    const validation = () => {
        try {
            console.log("MM", data);

            if (data.email == "") {
                errors.email = "Email Address is required";
            }

            if (data.password == "") {
                errors.password = "Password is required";
            }

            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }
            // else {
            //     return "proceed"
            // }

            console.log(errors);

        }
        catch (e) {
            console.log(e);
        }
    }

    function IsError(id) {
        return !(Object.keys(formError)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formError)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }



    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <>

            <Helmet>
                <title>Ihsan Trust | Sign In</title>
            </Helmet>

            <section className='authentication_section signin_pass_page d-flex ai-center'>
                <div className="container">

                    <SocialWidget />

                    <div className="card">
                        <img src={LOGO} alt="logo" className='img-fluid' />
                        <div className="sign_form">
                            <form onSubmit={(e) => loginUser(e)}>
                                <h3>Sign In to Ihsan Trust</h3>
                                <p className='desc'>Please sign in to your account.</p>
                                {/* <h3>Sign in to your account</h3> */}
                                <div className="form-group" id='email'>
                                    <input
                                        type="email"
                                        name='email'
                                        value={data.email}
                                        onChange={(e) => handleEmail(e)}
                                        placeholder="Enter Email Address"
                                        className={`${(formError.length == 0 ? "" : IsError("email") ? "success" : "error")} form-control`}
                                    />
                                    {
                                        (ShowError("email"))[0] &&
                                        <p className='error_label'>{(ShowError("email"))[1]}</p>
                                    }
                                </div>
                                <div className='mb-3'>
                                    <div className={`${(formError.length == 0 ? "" : IsError("password") ? "success" : "error")} input-group`} id='password'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            value={data.password}
                                            onChange={(e) => handlePassword(e)}
                                            className={"form-control"}
                                            placeholder="Enter Password"
                                        />
                                        <span
                                            className={"input-group-text password_visibility"}
                                            onClick={togglePasswordVisibility}>{showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                        </span>
                                    </div>
                                    {
                                        (ShowError("password"))[0] &&
                                        <p className='error_label'>{(ShowError("password"))[1]}</p>
                                    }
                                </div>

                                <div className="">
                                    <button type='submit' className="btn sign_btn">Sign In</button>
                                </div>
                            </form>
                            <div className='form-check-group my-4 d-flex jc-between mx-2'>
                                <div>
                                    <label className='mb-0'>
                                        <Link to="/forgot-password" className='link'>Forgot your password?</Link>
                                    </label>
                                </div>
                                {/* <div>
                                    <label className='mb-0'>
                                        <Link to="/reset-password/:token" className='link reset_pass'>Reset Here</Link>
                                    </label>
                                </div> */}
                                {/* <div>
                                    <label className='mb-0'>
                                        <Link to="/email-verification" className='link reset_pass'>Email Verification</Link>
                                    </label>
                                </div> */}
                            </div>
                            {/* <div>
                                <Link to="/sign-up" className='link'>
                                    <button className="btn signup_btn">Create An Account</button>
                                </Link>
                            </div> */}

                            <small>New Here? &nbsp; <Link to="/sign-up" className='Link'> Create your Account </Link></small>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SignIn