import { useEffect, useState } from "react";
import { useAuth } from "../../ContextAPI/Components/auth";
import { ValidatePhoneNumber } from "../../helpers/validatePhoneNumber";
import { ValidateNIC } from "../../helpers/validateNIC";
import { CitiesPk, countries, gender, states } from "../../helpers/data";
import { UpdateProfileInfo, getLogin_user } from "../../ContextAPI/APIs/api";
import Input from "../Form/Input";
import Select from "../Form/Select";
import Select_New from "../Form/Select_New";

import { useNavigate } from 'react-router-dom';

import { Upload_Profile_Pic } from "./upload_profilePic";
import { useToast } from "../../ContextAPI/Components/toast";
import { useConfirm } from '../../ContextAPI/Components/confirm';
import { MdInfoOutline } from "react-icons/md";

import TooltipComponent from "../../components/tooltip"
import PhoneInput from "react-phone-input-2";
import { capitalizeFirstLetter } from "../../helpers/data";


export function Student_Profile_Info() {

    const navigate = useNavigate()

    const { alert } = useToast();
    const { user, GetLoginUser } = useAuth()
    const { Ask } = useConfirm()

    // Update Profile Info
    const [info, setInfo] = useState({
        ...user,
        email2: user.email2 || "",
        email3: user.email3 || "",
        phone: user.phone || "+92",
        phone2: user.phone2 || "+92",
        phone3: user.phone3 || "+92",
        gender: { label: user.gender || "select...", value: user.gender || null },
        outside: user.outside || "",
        outsideAddress: user.outsideAddress || "",

        outsideCity: user.outsideCity || "",
        outsideContact: user.outsideContact || ""
    });

    async function handleSubmit(e) {
        e.preventDefault();

        console.log("INFO", info);
        // return

        if (await Ask() == false) return

        console.log(info.phone);

        // if (ValidateNIC(info.cnic) == false) return alert("CNIC should be 13 characters long.", false)
        // if (ValidatePhoneNumber(info.phone) == false) return alert("Phone numbers should start with +92 and be 13 characters long.", false)


        const payload = {
            FullName: info.fullName,
            cnic: info.cnic,
            email2: info.email2,
            email3: info.email3,
            FatherName: info.fatherName,
            phone: info.phone,
            phone2: info.phone2,
            phone3: info.phone3,
            state: info.state,
            city: info.city,
            gender: info.gender.value,
            outside: info.outside,
            outsideAddress: info.outsideAddress,
            outsideCity: info.outsideCity,
            outsideContact: info.outsideContact
        }
        // console.log("PP", payload);

        const checkV = validation()

        if (checkV !== "proceed") return

        else {
            const res = await UpdateProfileInfo(payload)
            if (res.success) {
                GetLoginUser()
                navigate("/std-profile#education-details")
            }

            alert(res.message, res.success)
        }
    }


    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);


    const handleChange = (value) => {
        console.log(value);
        setInfo({ ...info, phone: value });
        // setValid(ValidatePhoneNumber(value));
    };
    const handleChange2 = (value) => {
        console.log(value);
        setInfo({ ...info, phone2: value });
        // setValid(ValidatePhoneNumber(value));
    };
    const handleChange3 = (value) => {
        console.log(value);
        setInfo({ ...info, phone3: value });
        // setValid(ValidatePhoneNumber(value));
    };


    let errors = {}
    const [formError, setFormError] = useState([])

    const validation = () => {
        try {
            console.log("MM", info);

            if (typeof info.fatherName == "undefined" || info.fatherName == "") {
                errors.fatherName = "Father Name is required";
            }
            console.log("1");
            if (typeof info.phone == "undefined" || (info.phone).length != 12) {
                errors.phone = "Mobile 1 is required";
            }
            console.log("2");
            if (typeof info.state == "undefined" || info.state == "") {
                errors.state = "State / Provience is required";
            }
            console.log("3");
            if (typeof info.city == "undefined" || info.city == "") {
                errors.city = "City is required";
            }

            console.log("errors", errors);

            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }


        }
        catch (e) {
            console.log(e);
        }
    }

    function IsError(id) {
        return !(Object.keys(formError)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formError)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }





    return <section>
        <div className="personal_info">

            <div className="row">
                <div className="col-md-6 col-sm-5 col-12">
                    <Upload_Profile_Pic />
                </div>
                <div className="col-md-6 col-sm-7 col-12">
                    <div className="profile_specification">
                        <h6>Profile Picture Specification</h6>
                        <p>The added photo will appear in the application form. Follow instructions carefully; the photo is crucial for the application</p>
                        <ul>
                            <li>Submit a color photo.</li>
                            <li>Capture it against a plain white/blue background.</li>
                            <li>Ensure the photo is recent,<b> it should not be more than 1 month old </b></li>
                            <li>Display a clear, full-face front view; side or angled views are not allowed.</li>
                            <li>Avoid snapshots, magazine photos, low-quality vending machine or mobile phone photos, and full-length shots</li>
                        </ul>
                    </div>
                    {/* <p className="error_label">profile picture should be 3 months old maximum</p> */}
                </div>
            </div>

            <h4>Personal Information</h4>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                    <div className="col-md-6">
                        <div className='form-group'>
                            <Input disabled label="Full Name" inputType="text" placeholder="Enter Full Name" value={info.fullName} onChange={(e) => setInfo({ ...info, fullName: e.target.value })} required={true} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className='form-group' id='fatherName'>
                            <Input label="Father Name" inputType="text" span="*" placeholder="Enter Father Name" value={info.fatherName} onChange={(e) => setInfo({ ...info, fatherName: e.target.value })}
                                class={`${(formError.length == 0 ? "" : IsError("fatherName") ? "success" : "error")} form-control`}
                            />
                            {
                                (ShowError("fatherName"))[0] &&
                                <p className='error_label'>{(ShowError("fatherName"))[1]}</p>
                            }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className='form-group'>
                            <Input label="Personal Email Address" placeholder="Enter Email Address" inputType="email" value={info.email} class="text-transform-none" disabled={true} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className='form-group'>
                            <Input
                                disabled
                                label="CNIC / B-Form No."
                                inputType="text"
                                value={info.cnic}
                                onChange={(e) => setInfo({ ...info, cnic: e.target.value })}
                                required={true}
                                tooltip={false}
                                tooltipText="Please enter your National Identification Number i.e. 13 digit number, as stated on your Identity Card. or enter B-Form Number"
                            />
                            <p className='error_label'>{(ValidateNIC(info.cnic) == false) && "CNIC should be 13 characters long."}</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className='form-group'>
                            <Input label="University Email Address" inputType="email" placeholder="Enter University Email Address" class="text-transform-none" value={info.email2} onChange={(e) => setInfo({ ...info, email2: e.target.value })} />
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className='form-group'>
                            <Input label="Other Email Address" inputType="email" placeholder="Enter Other Email Address" class="text-transform-none" value={info.email3} onChange={(e) => setInfo({ ...info, email3: e.target.value })} />
                        </div>
                    </div>


                    {/* <div className="col-md-3">
                        <div className='form-group'>
                            <Input
                                label="Date of Birth"
                                inputType="date"
                                value={info.cnic}
                                onChange={(e) => setInfo({ ...info, cnic: e.target.value })}
                                required={true}
                            />
                            <small className="text-danger">{(ValidateNIC(info.cnic) == false) && "CNIC should be 13 characters long."}</small>
                        </div>
                    </div> */}


                    <div className="col-md-4">
                        <div className='form-group' id="phone">
                            <label className="form-label">Mobile No 1 <span className="required">*</span> </label>
                            <PhoneInput
                                country={"pk"}
                                value={info.phone || "+92"}
                                onChange={handleChange}
                                inputProps={{
                                    required: true,
                                }}
                                placeholder='+92 ___ _______'
                                disableDropdown={true}
                                className={`${(formError.length == 0 ? "" : IsError("phone") ? "success" : "error")}`}
                            />
                            {
                                (ShowError("phone"))[0] &&
                                <p className='error_label'>{(ValidatePhoneNumber(info.phone) == false) && "Phone numbers should start with +92 and be 13 characters long."}</p>
                                // <p className='error_label'>{(ShowError("phone"))[1]}</p>
                            }
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className='form-group'>
                            <div className="d-flex">
                                <label className="form-label">Mobile No 2</label>
                                <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Example +921234567890">
                                    <div><MdInfoOutline className='hint-icon mb-2 ml-1' /></div>
                                </TooltipComponent>
                            </div>
                            <PhoneInput
                                country={"pk"}
                                value={info.phone2 || "+92"}
                                onChange={handleChange2}
                                // inputProps={{
                                //     required: true,
                                // }}
                                placeholder='+92 ___ _______'
                                disableDropdown={true}
                            />
                            <p className="error_label">{(ValidatePhoneNumber(info.phone2) == false) && "Phone numbers should start with +92 and be 13 characters long."}</p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className='form-group'>
                            <div className="d-flex">
                                <label className="form-label">Whatsapp No.</label>
                                <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Example +921234567890">
                                    <div><MdInfoOutline className='hint-icon mb-2 ml-1' /></div>
                                </TooltipComponent>
                            </div>
                            <PhoneInput
                                country={"pk"}
                                value={info.phone3 || "+92"}
                                onChange={handleChange3}
                                // inputProps={{
                                //     required: true,
                                // }}
                                placeholder='+92 ___ _______'
                                disableDropdown={true}
                            />
                            <p className="error_label">{(ValidatePhoneNumber(info.phone3) == false) && "Phone numbers should start with +92 and be 13 characters long."}</p>
                        </div>
                    </div>

                    {/* <div className="d-flex">
                        <div className="form-check form-group me-5 pl-0">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked1" />
                            <label className="form-check-label form-label" for="flexCheckChecked1">
                                Address in Pakistan
                            </label>
                        </div>
                        <div className="form-check form-group pl-0">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                            <label className="form-check-label form-label" for="flexCheckChecked2">
                                Address outside Pakistan
                            </label>
                        </div>
                    </div> */}


                    <div className="col-md-4">
                        <div className='form-group' id="state">
                            {/* <Select label="State / Province" span="*" selectText="Select your State" options={states} value={info.state} onChange={(e) => setInfo({ ...info, state: e.target.value })} required={false}
                                className={`${(formError.length == 0 ? "" : IsError("fatherName") ? "success" : "error")} form-select`}
                            /> */}
                            <label className="form-label">State / Province <span className="required">*</span></label>
                            <select
                                className={`${(formError.length == 0 ? "" : IsError("state") ? "success" : "error")} form-select`}
                                aria-label="Default select example"
                                value={info.state}
                                onChange={(e) => setInfo({ ...info, state: e.target.value, city: "" })}
                                required
                            >
                                <option selected disabled >Select your State</option>
                                {
                                    states.map((item) => (
                                        <option value={item}>{item}</option>
                                    ))
                                }
                            </select>

                            {
                                (ShowError("state"))[0] &&
                                <p className='error_label'>{(ShowError("state"))[1]}</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='form-group' id="city">
                            <label className="form-label">City <span className="required">*</span></label>
                            <select
                                className={`${(formError.length == 0 ? "" : IsError("city") ? "success" : "error")} form-select`}
                                aria-label="Default select example"
                                value={info.city}
                                onChange={(e) => setInfo({ ...info, city: e.target.value })}
                                required
                            >
                                <option selected disabled >Select your City</option>
                                {
                                    CitiesPk.map((item) => (
                                        item.state === info.state && (
                                            < option value={item?.city} >{item?.city}</option>
                                        )
                                    ))
                                }
                            </select>

                            {
                                (ShowError("city"))[0] &&
                                <p className='error_label'>{(ShowError("city"))[1]}</p>
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='form-group'>
                            <Input
                                label="Gender"
                                inputType="text"
                                value={capitalizeFirstLetter(info.gender.value)}
                                // onChange={(e) => setInfo({ ...info, phone: e.target.value })}
                                required={true}
                                disabled
                                // tooltip={true}
                                tooltipText="Example +921234567890"
                            />
                        </div>
                    </div>
                    {
                        user.totalLoan > 0 && (
                            <>

                                <div className="col-md-12">
                                    <div className='form-group' id="city">
                                        <label className="form-label">Are you currently located outside of pakistan ?</label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={info.outside}
                                            onChange={(e) => setInfo({ ...info, outside: e.target.value })}
                                            required
                                        >
                                            <option selected disabled >Select</option>
                                            <option value="yes" >yes</option>
                                            <option value="no" >no</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    info.outside === "yes" && (
                                        <>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <Input label="Foreign Address" inputType="text" placeholder="Enter Address" value={info.outsideAddress} onChange={(e) => setInfo({ ...info, outsideAddress: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <Input label="Foreign City" inputType="text" placeholder="Enter City" value={info.outsideCity} onChange={(e) => setInfo({ ...info, outsideCity: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <Input label="Foreign Phone" inputType="number" placeholder="Enter Number" value={info.outsideContact} onChange={(e) => setInfo({ ...info, outsideContact: e.target.value })} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                    }

                </div>

                <div className='d-flex jc-end mt-3'>
                    <button type='submit' className='btn save_btn'>Update</button>
                </div>

            </form>

        </div >
    </section >
}