import React from 'react'

function Loader() {
    return (
        <>
            <div className="loader d-flex ai-center jc-center">
                <div className="spinner-border" role="status">
                    {/* <span className="sr-only">Loading...</span> */}
                </div>
            </div>
        </>
    )
}

export default Loader



// import React from "react";
// import './Loader.css'
// import Loader from "../assets/loader.svg"



// function Loader() {
//     return (
//         <div className="globalModal">
//             <div className="loader">
//                 <img src={Loader} alt="" />
//                 <h5>loading...</h5>
//             </div>
//         </div>
//     );
// }



// export default Loader;