import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { getForm2, SubmissionForm2 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"
import { useToast } from "../../ContextAPI/Components/toast"

import { academicCertficatesUrl, admissionLetterUrl, resumeUrl, newURL, vouchersUrl } from '../../helpers/data';

import formatter from '../../helpers/number_formatter';
import Form from 'react-bootstrap/Form';

import ACADEMIC_INFO from "../../assets/form-icons/academic.png"
import { FileViewer } from '../Form/fileViewer';

function Academic_BackgroundViewOnly() {

    const { alert } = useToast()

    const { GetLoginUser } = useAuth();


    const [examDetail, setExamDetail] = useState([
        {
            S_No: "",
            Examination_Level: "",
            Month_Year: "",
        },
    ]);

    const e_detail = {
        S_No: "",
        Examination_Level: "",
        Month_Year: "",
    }

    function Add_New_E_Detail(e) {
        e.preventDefault();
        setExamDetail([...examDetail, e_detail])
    }

    function handleInputChangeEXAM(e, index, field) {
        const updatedExamDetail = [...examDetail];
        updatedExamDetail[index][field] = e.target.value;
        setExamDetail(updatedExamDetail);
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };


    const [data, setData] = useState({
        institute: "",
        program: "",
        enrollNo: "",
        currentSem: "",
        totalSem: "",
        semtype: "",
        email: "",
        ExamStructure: "",
        newAdmission: "",
        currentGPA: "",
        CGPA: "",

        metricInstitute: "",
        metricGrade: "",
        metricPassing: "",
        intermediateInstitute: "",
        intermediateGrade: "",
        intermediatePassing: "",
        bachelorsInstitute: "",
        bachelorsGrade: "",
        bachelorsPassing: "",
        mastersInstitute: "",
        mastersGrade: "",
        mastersPassing: "",
        otherQualificationInstitute: "",
        otherQualificationGrade: "",
        otherQualificationPassing: "",
    })


    // examinationDetails: { type: Array, required: false },


    const handleInputChangeAQ = (e, degree) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [`${degree}${name}`]: value,
        }));
    };


    const [questions, setQuestions] = useState({
        question21: "",
        question22: "",
        question23: "",
        question24: "",
    })

    const handle21 = (e) => {
        setQuestions({ ...questions, question21: e.target.value })
    }

    const handle22 = (e) => {
        setQuestions({ ...questions, question22: e.target.value })
    }

    const getFormData = async () => {
        try {
            const response = await getForm2()
            if (response.success) {
                console.log("fomr1", response);

                setData(response.message)
                setExamDetail(response.message.examinationDetails)

                // underGradApplicable: ugrad,
                //     postGradApplicable: gradDis,
                //         otherApplicable: otherDis

                setIsNewAdmission(response?.message?.newAdmission)

                isUgrad(response.message.underGradApplicable)
                setDis(response.message.underGradApplicable)
                isGrad(response.message.postGradApplicable)
                setGradDis(response.message.postGradApplicable)
                isOther(response.message.otherApplicable)


                // setQuestions({
                //     ...questions,
                //     question21: response.message.question21,
                //     question22: response.message.question22,
                //     question23: response.message.question23,
                //     question24: response.message.question24
                // })
                // setfamilyDetail(response.message.familyDetails2)
                // setExamDetail(response.message.examinationDetails)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()
    }, [])

    function removeDetail(i) {

        const updated = [...examDetail]

        updated.splice(i, 1)
        setExamDetail(updated)

    }



    const [ugrad, isUgrad] = useState(false)
    const [dis, setDis] = useState(false)
    const [grad, isGrad] = useState(false)
    const [gradDis, setGradDis] = useState(false)
    const [other, isOther] = useState(false)
    const [otherDis, setOtherDis] = useState(false)

    const handleCheckBoxUgrad = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setDis(true)
        }
        else {
            setDis(false)
        }
        isUgrad(e.target.checked)
    }
    const handleCheckBoxGrad = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setGradDis(true)
        }
        else {
            setGradDis(false)
        }
        isGrad(e.target.checked)
    }
    const handleCheckBoxOther = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setOtherDis(true)
        }
        else {
            setOtherDis(false)
        }
        isOther(e.target.checked)
    }


    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // console.log("DATA", data);

        // console.log("EXAM DETAILS", examDetail);

        const payload = {
            ...data,
            examinationDetails: examDetail,
            underGradApplicable: ugrad,
            postGradApplicable: gradDis,
            otherApplicable: otherDis
        }


        console.log("PAYLOAD", payload);
        const response = await SubmissionForm2(payload)

        alert(response.message)

        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.stopPropagation();
        // }

        // setValidated(true);
        // navigate(`#${"academic_background"}`)
    };

    const [isNewAdmission, setIsNewAdmission] = useState();



    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     setData((prevData) => ({
    //       ...prevData,
    //       [name]: value,
    //     }));
    //   };


    return (
        <>
            <div className="">

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="preview_heading">
                        <h5>Academic Background</h5>
                    </div>

                    <div className="table_form mb-4">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>Institute Name <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data?.institute?.fullName}
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>Program <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data?.program?.name}
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>University Enrollment No. <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="enrollNo"
                                        value={data.enrollNo}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>Examination Structure <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="ExamStructure"
                                        value={data.ExamStructure}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>Current {data.ExamStructure === "semester" ? "Semester" : "Year"} <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="currentSem"
                                        value={data.currentSem}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            {data.ExamStructure == "semester" && (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                    <Form.Group className="form-group">
                                        <Form.Label>Academic Session <span className='required'>*</span></Form.Label>
                                        <Form.Control
                                            type="test"
                                            name="currentSem"
                                            value={data.semtype}
                                            onChange={handleChange}
                                            disabled
                                            required
                                        />
                                    </Form.Group>
                                </div>
                            )}

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>Total No. of {data.ExamStructure === "semester" ? "Semester" : "Year"} <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="totalSem"
                                        value={data.totalSem}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>Are you a new admission <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data.newAdmission}
                                        onChange={(e) => setIsNewAdmission(e.target.value)}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>Current Semester GPA / Percentage (%) {data.newAdmission == "yes" ? "" : <span className='required'>*</span>}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="currentGPA"
                                        value={data.currentGPA}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group className="form-group">
                                    <Form.Label>CGPA / Percentage (%) {data.newAdmission == "yes" ? "" : <span className='required'>*</span>}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="CGPA"
                                        value={data.CGPA}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            {/* DOCUMENTS */}
                            <div className="col-12 vertically_center">
                                <FileViewer
                                    url={admissionLetterUrl}
                                    keyName={"AdmissionLetter"}
                                    text="Admission Letter"
                                    span="*"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <FileViewer
                                    text="Self Resume / CV"
                                    span="*"
                                    url={resumeUrl}
                                    keyName={"Resume"}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <FileViewer
                                    text="Latest Unpaid Fee Vouchers"
                                    span="*"
                                    url={vouchersUrl}
                                    keyName={"UnpaidFeeVoucher"}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <FileViewer
                                    text="Last and Latest (paid) fee Vouchers / Receipts"
                                    url={vouchersUrl}
                                    keyName={"paidFeeVoucher"}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <FileViewer
                                    text="Up to Date Fee Ledger"
                                    url={vouchersUrl}
                                    keyName={"feeLedger"}
                                />
                            </div>

                        </div>

                        <div className="accordion accordion-flush" id="accordionFlushExample">

                            {/* Matriculation */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingMatric">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseMatric" aria-expanded="false" aria-controls="flush-collapseMatric">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Matriculation / O-Level / Equivalent
                                    </button>
                                </h2>

                                <div id="flush-collapseMatric" className="accordion-collapse collapse show" aria-labelledby="flush-headingMatric" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Name of School/College <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="metricInstitute"
                                                        value={data.metricInstitute}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Grade <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="metricGrade"
                                                        value={data.metricGrade}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Passing Year <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="metricPassing"
                                                        value={data.metricPassing}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <FileViewer
                                                    url={newURL}
                                                    text="Upload Certificate"
                                                    span="*"
                                                    keyName={"matricResult"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Intermediate */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingInter">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseInter" aria-expanded="false" aria-controls="flush-collapseInter">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Intermediate / A-Level / Equivalent
                                    </button>
                                </h2>
                                <div id="flush-collapseInter" className="accordion-collapse collapse show" aria-labelledby="flush-headingInter" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Name of School/College <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="intermediateInstitute"
                                                        value={data.intermediateInstitute}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Grade <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="intermediateGrade"
                                                        value={data.intermediateGrade}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Passing Year <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="intermediatePassing"
                                                        value={data.intermediatePassing}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <FileViewer
                                                    text="Upload Certificate"
                                                    span="*"
                                                    url={newURL}
                                                    keyName={"interResult"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Undergradudate */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingUndergradudate">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseUndergradudate" aria-expanded="false" aria-controls="flush-collapseUndergradudate">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Undergradudate / Bachelors / Equivalent
                                    </button>
                                </h2>
                                <div id="flush-collapseUndergradudate" className="accordion-collapse collapse show" aria-labelledby="flush-headingUndergradudate" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="form-check text-center mb-4">
                                            <input disabled className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" checked={ugrad} onChange={(e) => handleCheckBoxUgrad(e)} />
                                            <label className="form-check-label" for="flexCheckDefault1" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                                Not Applicable For Me
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Institute Name <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="bachelorsInstitute"
                                                        value={data.bachelorsInstitute}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>CGPA / Percentage (%) <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="bachelorsGrade"
                                                        value={data.bachelorsGrade}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Passing Year <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="bachelorsPassing"
                                                        value={data.bachelorsPassing}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <FileViewer
                                                    text="Upload Degree"
                                                    span="*"
                                                    url={newURL}
                                                    keyName={"bachelorsResult"}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* Gradudate */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingGradudate">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseGradudate" aria-expanded="false" aria-controls="flush-collapseGradudate">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Gradudate / Masters / Equivalent
                                    </button>
                                </h2>
                                <div id="flush-collapseGradudate" className="accordion-collapse collapse show" aria-labelledby="flush-headingGradudate" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div class="form-check text-center mb-4">
                                            <input disabled class="form-check-input" type="checkbox" value="" id="flexCheckDefault2" checked={grad} onChange={(e) => handleCheckBoxGrad(e)} />
                                            <label class="form-check-label" for="flexCheckDefault2" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                                Not Applicable For Me
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Institute Name <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="mastersInstitute"
                                                        value={data.mastersInstitute}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>CGPA / Percentage (%) <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="mastersGrade"
                                                        value={data.mastersGrade}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Passing Year <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="mastersPassing"
                                                        value={data.mastersPassing}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <FileViewer
                                                    text="Upload Degree"
                                                    span="*"
                                                    url={newURL}
                                                    keyName={"mastersResult"}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* Other */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOther">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOther" aria-expanded="false" aria-controls="flush-collapseOther">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        M. Phil. / Phd / Other
                                    </button>
                                </h2>
                                <div id="flush-collapseOther" className="accordion-collapse collapse show" aria-labelledby="flush-headingOther" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div class="form-check text-center mb-4">
                                            <input disabled class="form-check-input" type="checkbox" value="" id="flexCheckDefault3" checked={other} onChange={(e) => handleCheckBoxOther(e)} />
                                            <label class="form-check-label" for="flexCheckDefault3" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                                Not Applicable For Me
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Institute Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="otherQualificationInstitute"
                                                        value={data.otherQualificationInstitute}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>CGPA / Percentage (%)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="otherQualificationGrade"
                                                        value={data.otherQualificationGrade}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className="form-group">
                                                    <Form.Label>Passing Year</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="otherQualificationPassing"
                                                        value={data.otherQualificationPassing}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <FileViewer
                                                    text="Upload Degrees"
                                                    url={newURL}
                                                    keyName={"othersResult"}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Examinations to be taken in your Current Program */}
                    {/* <div className="table_form mb-4">
                        <div className='mb-4 form_sub_heading'>
                            <h5 className='mb-0'>Examinations to be taken in your Current Program</h5>
                            <button className='btn add_btn' disabled onClick={(e) => Add_New_E_Detail(e)}>Add More</button>
                        </div>

                        {
                            examDetail.map((data, i) => {
                                return <>
                                    {
                                        i > 0 &&
                                        <div className="section_break"></div>
                                    }

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId={`examinationLevel-${i}`} className='form-group'>
                                                <Form.Label>Examination / Level <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={examDetail[i].Examination_Level}
                                                    onChange={(e) => handleInputChangeEXAM(e, i, 'Examination_Level')}
                                                    disabled
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId={`monthYear-${i}`} className='form-group'>
                                                <Form.Label>Month / Year <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={examDetail[i].Month_Year}
                                                    onChange={(e) => handleInputChangeEXAM(e, i, 'Month_Year')}
                                                    disabled
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </>
                            })
                        }

                    </div> */}

                </Form>

            </div>
        </>
    )
}

export default Academic_BackgroundViewOnly
