import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';

import Input from "../Form/Input"
import Select from "../Form/Select"
import NavSidebar from '../Sidebar';
import Navbar from '../Navbar';

import { getUNIProfile, createUniProfile } from '../../ContextAPI/APIs/api'
import { UniFields } from '../../helpers/data';
import { University_Programs } from './university_programs';
import { Upload_User_Profile_Pic } from './upload_profilePic';
import { University_Campuses } from './university_campuses';
import { Helmet } from 'react-helmet';
import { useToast } from '../../ContextAPI/Components/toast';
import Tabs from '../tabs';
import { useMediaQuery } from 'react-responsive';
import { IoArrowBack } from 'react-icons/io5';


export function UniversityProfileUpdate() {

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const tabs = [
        {
            tabName: "University Information",
            pathName: ""
        },
        {
            tabName: "University Campuses",
            pathName: "#university_campuses"
        },
        {
            tabName: "Programs",
            pathName: "#programs"
        },
    ]

    const Mob_tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "Campuses",
            pathName: "#university_campuses"
        },
        {
            tabName: "Programs",
            pathName: "#programs"
        },
    ]

    const { hash } = useLocation();
    const [show, setShow] = useState("");
    const navigate = useNavigate();

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const { alert } = useToast();
    const params = useParams();
    console.log("IDDD", params.id);


    const [info, setInfo] = useState({
        fullName: "",
        email: "",
        shortName: "",
        types: "",
    })
    const GetUni = async () => {
        const res = await getUNIProfile({ uniId: params.id });
        console.log("   ", res);
        if (res.success == true && res.message != null) {
            console.log("KKK", res.message);
            setInfo({ ...res.message })

        } else {
            alert("invalid university", "error")
            navigate("/universities-list")
        }
    }
    const UpdateData = async (e) => {
        e.preventDefault();

        try {
            console.log("INFO", info);
            const payload = {
                uniId: params.id,
                ...info
            }

            console.log("payloadUNI", payload);

            const response = await createUniProfile(payload)

            alert(response.message, response.success)
            // console.log("info", payload);
        }
        catch (e) {
            console.log(e);
            alert("asdsad")
        }
    }


    useEffect(() => {
        console.log((hash.split("#"))[1] || "university_info");
        setShow((hash.split("#"))[1] || "university_info")
    }, [hash])

    useEffect(() => {
        GetUni()
    }, [])


    return <>

        <Helmet>
            <title>University Profile</title>
        </Helmet>

        <div className="d-flex">
            <div className="sidebar_div">
                <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
            </div>
            <div className="page_div">
                <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />


                {/* Profile Update Area */}
                <section className='uni_profile_section'>

                    <div className='d-flex ai-center mb-4'>
                        {/* <Link to="/all-users/universities-list">
                            <IoArrowBack className='back_arrow_icon me-3' />
                        </Link> */}
                        <h2 className='mb-0'>Update Profile</h2>
                    </div>

                    <div className="card">

                        <Tabs tabLinks={isMobile ? Mob_tabs : tabs} show={show} setShow={setShow} />

                        {
                            show == "university_info" &&
                            <div className='university_info'>
                                <Upload_User_Profile_Pic id={info?.uniId} profilepic={info?.profilepic} callDataFunction={GetUni} />

                                <h4 className='mb-3'>University Information</h4>

                                <form onSubmit={(e) => UpdateData(e)}>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="form-group">
                                                <Input style={{ textTransform: "capitalize" }} value={info?.fullName} onChange={(e) => setInfo({ ...info, fullName: e.target.value })} label="Name" inputType="text" required={true} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="form-group">
                                                <Input value={info?.email} label="Email" inputType="text" class="text-transform-none" disabled={true} required={true} />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <Input style={{ textTransform: "lowercase" }} value={info?.shortName} onChange={(e) => setInfo({ ...info, shortName: e.target.value })} label="Short Name" inputType="text" required={true} />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <Select style={{ textTransform: "capitalize" }} value={info?.types} onChange={(e) => setInfo({ ...info, types: e.target.value })} label="Institute Types" selectText="Select" options={UniFields} required={true} />
                                            </div>
                                        </div> */}
                                        <div className='d-flex jc-end mt-2'>
                                            <button className='btn save_btn'>Update Profile</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        }

                        {
                            show == "university_campuses" &&
                            <University_Campuses />
                        }

                        {
                            show == "programs" &&
                            <University_Programs feild={info?.types} />
                        }

                    </div>

                </section>

            </div>
        </div >



    </>
}