import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { useProSidebar } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Table from '../../components/Table/Table';
import IconText from '../../components/Table/IconText';
import Text from '../../components/Table/Text';
import TooltipComponent from '../../components/tooltip';
import Pagination from '../../components/Pagination';
import Badge from "../../components/Badges"
import { TbReportAnalytics } from 'react-icons/tb';
import { useToast } from '../../ContextAPI/Components/toast';
import { useConfirm } from '../../ContextAPI/Components/confirm';
import { freshCasesFilterAdmin, getAllApplications, getAllUniversities, getProgram, updateApplicationStatus } from '../../ContextAPI/APIs/api';
import { status } from '../../helpers/data';
import { useParams } from 'react-router-dom';

function FreshCases() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Get the value of the 'filter' parameter
    const filter = queryParams.get('filter');

    // const { filter } = useParams()s;
    console.log("fff222", filter);

    const { alert } = useToast()
    const { Ask } = useConfirm()

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const [reason, setReason] = useState("")
    const [date123, setDate123] = useState("")

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const navigate = useNavigate();
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [applications, setApp] = useState([])
    const getAppData = async () => {
        try {
            const response = await getAllApplications()
            console.log("Response", response);
            if (response.success) {
                setApp(response?.message)

                setTotalCount(response.totalCount)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const actionAdmin = async (e, id, act) => {
        e.preventDefault();

        try {
            if (act == "resubmit" || act == "reject") {
                if (typeof reason == "undefined" || reason == "" || reason == null) return alert("Reason is required", "error");
            }
            if (typeof date123 == "undefined" || date123 == "" || date123 == null) return alert("Date is required", "error");
            if (await Ask() == false) return

            const payload = {
                Id: id,
                action: act,
                reason: reason,
                date: date123
            }
            const response = await updateApplicationStatus(payload)
            if (response.success) {
                alert(response.message, 'success')
                getAppData()
            }
            else {
                alert(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleChange = (e) => {
        setReason(e.target.value)
    }

    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp?.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate
        }
        else {
            return "---"
        }
    }




    const [selectedUni, setSelectedUni] = useState([])

    const [selectedProgram, setSelectedProgram] = useState([])

    const [selectedStatus, setSelectedStatus] = useState([])


    const [totalCount, setTotalCount] = useState(4)

    const getAllFresh = async () => {
        try {
            const response = await freshCasesFilterAdmin({}, pageSkip)
            console.log("DDD", response.message);
            if (response.success) {
                setTotalCount(response.totalCount)
                setApp(response?.message)
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const [prog, setProg] = useState([])
    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [uni, setUni] = useState([])
    const getAllunis = async () => {
        try {
            const response = await getAllUniversities()
            if (response.success) {
                console.log("AAAAA", response);
                setUni(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const search = async (uni, prog, stat, skip) => {
        try {

            const payload = {
                uni: uni,
                program: prog,
                status: stat,
            }

            const response = await freshCasesFilterAdmin(payload, skip)
            if (response.success) {
                console.log("FILTERR", response.message);
                setApp(response?.message)

                setTotalCount(response.totalCount)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const clear = async () => {
        try {

            console.log("selectedUni", selectedUni);
            console.log("selectedProgram", selectedProgram);
            // console.log("selectedCity", selectedCity);
            setSelectedProgram([])
            setSelectedStatus([])
            setSelectedUni([])
            const response = await freshCasesFilterAdmin({}, pageSkip)
            // setApp(response.message)
            if (response.success) {
                console.log("DDD", response.message);
                setApp(response?.message)

                setTotalCount(response.totalCount)
            }

        }
        catch (e) {
            console.log(e);
        }

    }


    useEffect(() => {
        getAllProg()
        getAllunis()


        console.log("fff", filter);
        if (filter === "interview") {
            // const isSelected = selectedStatus.includes("interview");
            const upd = [...selectedStatus, "interview"]
            setSelectedStatus(upd);
            search(selectedUni, selectedProgram, upd, pageSkip)
        } else {
            getAllFresh()
        }
    }, [])



    const handleStatusChange = (id) => {

        const isSelected = selectedStatus.includes(id);
        if (isSelected) {
            const abc = selectedStatus.filter((uniId) => uniId !== id)

            setSelectedStatus(abc);

            search(selectedUni, selectedProgram, abc, 0)
        } else {

            const upd = [...selectedStatus, id]
            setSelectedStatus(upd);

            // search()

            search(selectedUni, selectedProgram, upd, 0)
        }
    };


    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {
            const abc = selectedProgram.filter((uniId) => uniId !== id)

            setSelectedProgram(abc);

            search(selectedUni, abc, selectedStatus, 0)
        } else {
            const upd = [...selectedProgram, id]
            setSelectedProgram(upd);

            // search()

            search(selectedUni, upd, selectedStatus, 0)
        }
    };


    const handleUniChange = (id) => {
        const isSelected = selectedUni.includes(id);
        if (isSelected) {
            const abc = selectedUni.filter((uniId) => uniId !== id)

            setSelectedUni(abc);

            search(abc, selectedProgram, selectedStatus, 0)

        } else {
            const upd = [...selectedUni, id]

            setSelectedUni(upd);
            search(upd, selectedProgram, selectedStatus, 0)
        }
    };



    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(selectedUni, selectedProgram, selectedStatus, skip)
    }


    return (
        <>
            <Helmet>
                <title>Fresh Cases</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='fresh_cases_section'>
                        <h2>Fresh Cases</h2>

                        <div className="card">

                            <div className="filter d-flex ai-center jc-between mb-3">
                                <div className='d-flex ai-center'>
                                    <p>Filter By</p>

                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Universities
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>
                                                {
                                                    uni.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor={`uniCheckbox_${item._id}`}>{item.fullName}</label>
                                                                {/* <input className="form-check-input" onChange={() => handleUniChange()} type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleUniChange(item._id)}
                                                                    checked={selectedUni.includes(item._id)}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Programs
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>

                                                {
                                                    prog.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.name}</label>
                                                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleProgramChange(item._id)}
                                                                    checked={selectedProgram.includes(item._id)}
                                                                />

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Status
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>

                                            <div className='uni_filter_body'>
                                                {
                                                    status.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item}`}>{item == "pending" ? "processing" : item}</label>
                                                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item}`}
                                                                    onChange={() => handleStatusChange(item)}
                                                                    checked={selectedStatus.includes(item)}
                                                                />

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    {
                                        (selectedProgram.length > 0 || selectedUni.length > 0 || selectedStatus.length > 0) && (
                                            <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                        )
                                    }
                                    {/* <button onClick={() => search()} className='btn clear_btn ml-2'>Search</button> */}
                                </div>
                            </div>

                            {
                                applications?.length > 0 ?
                                    <>
                                        <Table head={["Student Name", "University Name", "Program", "Status", "Submission Date", "Semester/Year", "Approved Semester/Year", ""]}>
                                            {
                                                applications?.map((data, i) => {
                                                    return <tr key={i}>
                                                        <td>
                                                            <div className='d-flex ai-center'>
                                                                <TooltipComponent className="" placement="top" tooltipText="View Form">
                                                                    <div><TbReportAnalytics className='icon' onClick={() => navigate(`/application/${data?.userId?._id}/${data._id}`)} style={{ cursor: "pointer", color: "var(--themePurpleColor)" }} /></div>
                                                                </TooltipComponent>
                                                                <IconText text={data?.userId?.fullName || "---"} />
                                                            </div>
                                                        </td>
                                                        <td><Text text={data?.university?.fullName || "---"} /></td>
                                                        <td><Text text={data?.program?.name || "---"} /></td>
                                                        <td><Badge text={data?.status === "pending" ? "Processing" : data?.status || "---"} classes={`${data?.status} md`} /></td>
                                                        <td><Text text={timestampToDate(data?.timestamp) || "---"} /></td>
                                                        <td className='text-center'><Text text={data?.form2?.totalSem || "---"} /></td>
                                                        <td className='text-center'><Text text={data?.form2?.currentSem || "---"} /></td>
                                                        <td>
                                                            <Link to={`/fresh-cases-history/${data?.userId?._id}/${data._id}#cases-detail`} className='link'>
                                                                <button className='btn save_btn'>Case Detail</button>
                                                            </Link>
                                                        </td>
                                                        {/* <td>
                                                            <Link to={`/application/${data?.userId?._id}/${data._id}`} className='link'>
                                                                <button className='btn save_btn'>View Application</button>
                                                            </Link>
                                                        </td> */}
                                                    </tr>
                                                })
                                            }
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }

                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default FreshCases