import React from 'react'

function EmailText(props) {
    return (
        <>
            <span className='green hash-tag text-truncate'>{props.text}</span>
        </>
    )
}

export default EmailText
