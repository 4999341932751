import React from 'react'
import { Helmet } from 'react-helmet';
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import Pagination from '../../components/Pagination';
import { useEffect } from 'react';
import { getStdApplication, getAllApplications, getProgram, freshCasesFilter } from '../../ContextAPI/APIs/api';
import { useState } from 'react';
import Badge from "../../components/Badges"

import IconText from '../../components/Table/IconText';
import TooltipComponent from '../../components/tooltip';
import { TbReportAnalytics } from 'react-icons/tb';
import UserModal from '../../components/Modals/Modal';
import { FaEllipsis } from 'react-icons/fa6';
import Input from '../../components/Form/Input';
import { FaEllipsisV } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { status } from "../../helpers/data"
function FreshCasesReport() {

    const Navigate = useNavigate()
    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };
    const [selectedProgram, setSelectedProgram] = useState([])

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const FC_REPORT = [
        {
            name: "Ahmed",
            roll_no: 1234,
            contact: "0333 1234567",
            email: "abc@gmail.com",
            program: "SE",
            case_detail: "Approved",
            amount: 2000,
            disbusment: "Yes",
            date: "28/10/23"
        },
        {
            name: "Ahmed",
            roll_no: 2244,
            contact: "0333 1234567",
            email: "abc@gmail.com",
            program: "SE",
            case_detail: "Not Approved",
            amount: "---",
            disbusment: "No",
            date: "---"
        },
    ]

    const [reason, setReason] = useState("")
    const [date123, setDate123] = useState("")

    const handleChange = (e) => {
        setReason(e.target.value)
    }

    // const [freshCases, setFreshCase] = useState([])

    // const getData = async () => {
    //     try {
    //         const response = await getStdApplication()
    //         if (response.success) {
    //             console.log("RESP", response);
    //             setFreshCase(response.message)
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    const [applications, setApp] = useState([])
    const getAppData = async () => {
        try {
            const response = await getStdApplication()
            console.log("Response", response);
            if (response.success) {
                setApp(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getAppData()
    }, [])

    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp?.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate
        }
        else {
            return "---"
        }
    }

    const [selectedStatus, setSelectedStatus] = useState([])

    const handleStatusChange = (id) => {

        const isSelected = selectedStatus.includes(id);
        if (isSelected) {
            const abc = selectedStatus.filter((uniId) => uniId !== id)

            setSelectedStatus(abc);

            search(selectedProgram, abc, pageSkip)
        } else {
            const upd = [...selectedStatus, id]
            setSelectedStatus(upd);

            // search()

            search(selectedProgram, upd, pageSkip)
        }
    };


    const [prog, setProg] = useState([])
    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {
            const abc = selectedProgram.filter((uniId) => uniId !== id)

            // setSelectedProgram((prevSelectedUni) => prevSelectedUni.filter((uniId) => uniId !== id));
            setSelectedProgram(abc,)
            search(abc, selectedStatus, pageSkip)
        } else {
            const upd = [...selectedProgram, id]

            // setSelectedProgram((prevSelectedUni) => [...prevSelectedUni, id]);
            setSelectedProgram(upd);
            search(upd, selectedStatus, pageSkip)

        }
    };

    const [fresh, setFresh] = useState([])
    const [totalCount, setTotalCount] = useState([])
    const getAllFreshCases = async (skip) => {
        try {
            const response = await freshCasesFilter({}, skip)
            if (response.success) {
                console.log("RRR--->>>", response.message);
                setFresh(response.message)
                setTotalCount(response.totalCount)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getAllFreshCases(pageSkip)
        getAllProg()
    }, [])


    const search = async (prog, stat, skip) => {
        try {

            const payload = {
                program: prog,
                status: stat
            }

            const response = await freshCasesFilter(payload, skip)

            if (response.success) {
                console.log("FILTERR", response.message);
                // setAllUsers(response.message)
                setFresh(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const clear = () => {
        try {
            setSelectedProgram([])
            search([], [], pageSkip)
        }
        catch (e) {
            console.log(e);
        }
    }


    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(selectedProgram, selectedStatus, skip)
    }


    return (
        <>

            <Helmet>
                <title>Fresh Cases Report</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='fresh_cases_section'>

                        <h2>Fresh Cases Report</h2>

                        <div className="card">
                            <div className='fc_report'>

                                <div className="filter d-flex ai-center jc-between mb-3">
                                    <div className='d-flex ai-center'>
                                        <p>Filter By</p>

                                        <div className="dropdown">
                                            <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Programs
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <div className='uni_filter_header d-flex jc-between'>
                                                    <p>Name</p>
                                                    <p className='me-0'>Filter</p>
                                                </div>
                                                <div className='uni_filter_body'>

                                                    {
                                                        prog.map((item, i) => (
                                                            <li key={i}>
                                                                <div className="form-check">
                                                                    <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.name}</label>
                                                                    {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id={`uniCheckbox_${item._id}`}
                                                                        onChange={() => handleProgramChange(item._id)}
                                                                        checked={selectedProgram.includes(item._id)}
                                                                    />

                                                                </div>
                                                            </li>
                                                        ))
                                                    }

                                                </div>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Status
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <div className='uni_filter_header d-flex jc-between'>
                                                    <p>Name</p>
                                                    <p className='me-0'>Filter</p>
                                                </div>
                                                <div className='uni_filter_body'>

                                                    {
                                                        status.map((item, i) => (
                                                            <li key={i}>
                                                                <div className="form-check">
                                                                    <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item}`}>{item}</label>
                                                                    {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id={`uniCheckbox_${item}`}
                                                                        onChange={() => handleStatusChange(item)}
                                                                        checked={selectedStatus.includes(item)}
                                                                    />

                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </div>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className='d-flex'>
                                        {
                                            selectedProgram?.length > 0 && (
                                                <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                            )
                                        }
                                        {/* <button onClick={() => search()} className='btn clear_btn ml-2'>Search</button> */}
                                    </div>
                                </div>

                                {
                                    fresh.length > 0 ?
                                        <>
                                            <Table head={["Student Name", "University Name", "Program", "Status", "Submission Date", "Semester/Year", "Approved Semester/Year", ""]}>
                                                {
                                                    fresh?.map((data, i) => {
                                                        return <tr key={i}>
                                                            <td>
                                                                <div className='d-flex ai-center'>
                                                                    <TooltipComponent className="" placement="top" tooltipText="View Form">
                                                                        <div><TbReportAnalytics className='icon' onClick={() => Navigate(`/uni-application/${data?.userId._id}/${data?._id}`)} style={{ cursor: "pointer", color: "var(--themePurpleColor)" }} /></div>
                                                                    </TooltipComponent>
                                                                    <IconText text={data?.userId?.fullName || "---"} />
                                                                </div>
                                                            </td>
                                                            <td><Text text={data?.university.fullName || "---"} /></td>
                                                            <td className='text-capital'><Text text={data?.program?.name || "---"} /></td>
                                                            <td><Badge text={data?.status || "---"} classes={`${data?.status} md`} /></td>
                                                            <td><Text text={timestampToDate(data?.timestamp) || "---"} /></td>
                                                            <td className='text-center'><Text text={data?.form2?.totalSem || "---"} /></td>
                                                            <td className='text-center'><Text text={data?.form2?.currentSem || "---"} /></td>
                                                            <td>
                                                                <Link to={`/uni-fresh-cases-history/${data?.userId?._id}/${data._id}`} className='link'>
                                                                    <button className='btn save_btn'>Case Detail</button>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={`/uni-application/${data?.userId?._id}/${data._id}`} className='link'>
                                                                    <button className='btn save_btn'>View Application</button>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })
                                                }


                                            </Table>

                                            <Pagination handlePagination={handlePagination} totalCount={totalCount} itemsPerPage={4} />
                                        </>
                                        :
                                        <h5 className='not_found_msg'>No Records Found</h5>
                                }

                            </div>
                        </div>

                    </section>
                </div >
            </div >
        </>
    )
}

export default FreshCasesReport
