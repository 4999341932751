import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { useProSidebar } from 'react-pro-sidebar';
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import IconText from '../../components/Table/IconText';

import CountUp from 'react-countup';

import { useToast } from '../../ContextAPI/Components/toast';
import { MdOutlinePieChartOutline } from 'react-icons/md';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoArrowBack, IoArrowForwardCircleOutline, IoCalendarOutline } from 'react-icons/io5';
import { TbClock24 } from 'react-icons/tb';
import { TfiBarChart } from 'react-icons/tfi';
import Pagination from '../../components/Pagination';
import UserModal from '../../components/Modals/Modal';
import PayRepayment from '../../components/PayRepayment';
import { useAuth } from '../../ContextAPI/Components/auth';
import { getRepayments, getStudentRepayment, repayementFilter, getStdTranch, validateApplication, uploadSlip, getAllSlipsByUSer } from '../../ContextAPI/APIs/api';
import Badges from '../../components/Badges';
import { TimestampToDate } from '../../helpers/timestampToDate';
import { Link } from 'react-router-dom';
import { PiArrowBendDownLeftBold } from 'react-icons/pi';
import { UserCard } from '../../components/UserCard';
import DashboardSlider from './DashboardSlider';
import line_bg from "../../assets/welcome-bg.png"
import Tabs from '../../components/tabs';
import ImageUploadInput from '../../components/Modals/ImageUploadInput';

import Badge from "../../components/Badges"
import { useMediaQuery } from 'react-responsive';
import { FaCheckCircle } from 'react-icons/fa';

import formatter from '../../helpers/number_formatter';


function StudentDashboard() {

    const [date, setDate] = useState();
    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    // const [loader, setLoader] = useState(false);

    const { alert } = useToast();

    const { user, GetLoginUser, getAllRepayments, repayments, setRepayments } = useAuth();
    console.log("OPOPOPOPOPOP", user._id);

    const repayment_history = [
        {
            month: "Jan",
            amount: 2000,
            status: "Timely",
            date: "10-10-2023"
        },
        {
            month: "Feb",
            amount: 5000,
            status: "Late",
            date: "12-09-2023"
        },
    ]

    const [stdRepayments, setStdRepayments] = useState([])

    const [totalCount, setTotalCount] = useState(0)
    const getStdRepayments = async (skip) => {
        try {
            const response = await getStudentRepayment(skip)
            console.log("ResponseRREEPPAAA", response);
            if (response.success) {
                setStdRepayments(response.message)
                setTotalCount(response.totalCount)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);

        // Get date components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based
        const year = date.getFullYear();

        // Get time components
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12; // Handle midnight

        // Formatted date and time string
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }

    const [format, setFormat] = useState("")
    const [date11, setDate11] = useState({
        start: "",
        end: "",
    })
    const [month, setMonth] = useState({
        start: "",
        end: "",
    })


    function getTimestamp(dateString) {
        const [year, month] = dateString.split('-');
        const dateObject = new Date(year, month - 1); // month - 1 because months are 0-indexed
        const timestamp = dateObject.getTime();
        return dateObject;
    }

    const getDD = async (e) => {
        try {
            e.preventDefault();

            if (format === "") {
                alert("Please select a format", "info")
            }

            let p = {
            }
            if (format !== "") {
                p.format = format
            }

            if (format == "date") {

                p.start = date11.start
                p.end = date11.end

            }

            if (format == "month") {
                p.start = month.start
                p.end = month.end
            }

            console.log("ppp", p);

            const response = await repayementFilter(p)
            console.log("RRR", response);

            if (response.success && response.message.length > 0) {
                setStdRepayments(response.message)
                setShow(false)
                setFormat("")
            }
            else {
                alert("No Data Found", "false")
                setShow(false)
                setFormat("")
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    // Array to hold the names of the days
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // Array to hold the names of the months
    const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // Get today's date
    const today = new Date();

    // Extract day, month, and year from the date object
    const dayOfWeek1 = daysOfWeek[today.getDay()];
    const dayOfMonth1 = today.getDate();
    const month1 = monthsOfYear[today.getMonth()];
    const year1 = today.getFullYear();

    // Format the date
    const formattedDate = `${dayOfWeek1}, ${dayOfMonth1} ${month1} ${year1}`;

    // console.log(formattedDate);


    const [app, setApp] = useState(false)

    const checkSubmitted = async () => {
        try {
            const res = await validateApplication()
            console.log("haveSubmitted", res?.application);

            if (res?.application) {
                if (res?.application.appStatus === "done") {
                    setApp(true)
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getAllRepayments()
        getStdRepayments(pageSkip)
        checkSubmitted()
    }, [])

    const [currentSlide, setCurrentSlide] = useState(0);

    // Function to handle button hover
    const handleButtonHover = (slideIndex) => {
        setCurrentSlide(slideIndex);
        console.log("slideIndex", slideIndex);
    };


    const tabs = [
        {
            tabName: "Due Payments",
            pathName: ""
        },
        {
            tabName: "Repayment History",
            pathName: "#repayment_history"
        },
        {
            tabName: "Disbursement History",
            pathName: "#disbursement_history"
        }
    ]

    const Mob_tabs = [
        {
            tabName: "Due Payments",
            pathName: ""
        },
        {
            tabName: "Repayment",
            pathName: "#repayment_history"
        },
        {
            tabName: "Disbursement",
            pathName: "#disbursement_history"
        }
    ]


    const { hash } = useLocation();
    const navigate = useNavigate();
    const [showTab, setShowTab] = useState("");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "");
        setShowTab((hash.split("#"))[1] || "")
    }, [hash])

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });


    const [formData, setFormData] = useState({
        date: '',
        amount: '',
        modeOfPayment: '',
        slip: null // Assuming you want to store the file object when uploaded
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'slip' ? files[0] : value
        }));
    };


    // uni:
    // prog:
    // campus:
    // slip:
    // examinationStructure:
    // modeOfPayment:
    // enrollmentNo:

    // timestamp:
    // datetime:

    const payNow = async (e) => {
        try {
            e.preventDefault();
            if (formData.date === null || formData.date === "" || typeof formData.date === undefined || !formData.date) {
                // return res.status(400).json({ success: false, message: "please select date" })
                alert(false, "please select date")
            }
            if (formData.modeOfPayment === null || formData.modeOfPayment === "" || typeof formData.modeOfPayment === undefined || !formData.modeOfPayment) {
                // return res.status(400).json({ success: false, message: "please select mode of payment" })
                alert(false, "please select mode of payment")
            }
            if (formData.slip === null || formData.slip === "" || typeof formData.slip === undefined || !formData.slip) {
                // return res.status(400).json({ success: false, message: "please upload slip" })
                alert(false, "please upload slip")
            }
            console.log("formData111", formData);
            const form = new FormData();

            console.log("III", user);
            form.append('userId', user._id);
            form.append('uni', user.university._id);
            form.append('prog', user.program._id);
            form.append('campus', user.campus._id);
            form.append('examinationStructure', user.type);
            form.append('enrollmentNo', user.EnrollNo);
            form.append('date', formData.date);
            form.append('amount', formData.amount);
            form.append('modeOfPayment', formData.modeOfPayment);
            form.append('slip', formData.slip);


            const res = await uploadSlip(form)

            alert(res.success, res.message)

        }
        catch (e) {
            console.log(e);
        }
    }



    const [slipsData, setSlipsData] = useState([])


    const getAllSlips = async () => {
        try {
            const response = await getAllSlipsByUSer()
            if (response.success) {
                setSlipsData(response.message)
            }

        }
        catch (e) {
            console.log(e);
        }
    }



    const [disbursements, setDicbursements] = useState([])
    const getDisbursements = async () => {
        try {
            const response = await getStdTranch()
            if (response.success) {
                setDicbursements(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        GetLoginUser()
        getDisbursements()
        getAllSlips()
    }, [])



    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp?.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate
        }
        else {
            return "---"
        }
    }


    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        getStdRepayments(skip)
    }


    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='student_dashboard_section'>

                        <div className='welcome_msg mb-4'>
                            <div className='d-flex flex-wrap jc-between ai-center abc'>
                                <div className='welcome-text'>
                                    <h5>Welcome back!</h5>
                                    {
                                        user.totalLoan == 0 &&
                                        <h2 className='name'>{`${user.fullName}`}</h2>
                                    }
                                    <p className='date'>{formattedDate}</p>
                                    {/* <button className='btn save_btn' disabled>{TimestampToDate(Date.now())}</button> */}
                                </div>
                                <div className='d-flex jc-end'>
                                    <button className='btn how_to_apply_btn'>How to Apply?</button>
                                </div>
                            </div>
                        </div>

                        {/* <div className="card mb-4">
                            <div className='form_steps_card'>
                                <div className='percent_box me-5'>
                                    <h1 className='profile_percent'>10%</h1>
                                    <h4>of your profile is complete</h4>
                                </div>

                                <div className='form_steps_msg w-100'>

                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                                    </div>

                                </div>
                            </div>
                        </div> */}

                        {
                            user.totalLoan > 0 ?

                                <>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 mb-4">
                                            <UserCard data={user} />
                                        </div>

                                        <div className="col-lg-8 col-md-12 mb-4">
                                            <div className="grid_row_4">
                                                <div className='mb-2'>
                                                    <div className="card grid_card h-100">
                                                        <div className="content">
                                                            <div className='d-flex ai-center'>
                                                                <MdOutlinePieChartOutline className='grid_icon' style={{ color: "#28a745" }} />
                                                                <p>Total Loan</p>
                                                            </div>
                                                            <div>
                                                                <h4>
                                                                    <CountUp prefix="Rs. " suffix='/-' start={0} end={user.totalLoan} decimal="." decimals={0} duration={1.75} />
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mb-2'>
                                                    <div className="card grid_card h-100">
                                                        <div className="content">
                                                            <div className='d-flex ai-center'>
                                                                <TbClock24 className='grid_icon' style={{ color: "#17a2b8" }} />
                                                                <p>Outstanding Amount</p>
                                                            </div>
                                                            <div>
                                                                <h4>
                                                                    <span><CountUp prefix="Rs. " suffix='/-' start={0} end={user.totalLoan - user.totalPaid} decimal="." decimals={0} duration={1.75} /></span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mb-2'>
                                                    <div className="card grid_card h-100">
                                                        <div className="content">
                                                            <div className='d-flex ai-center'>
                                                                <IoCalendarOutline className='grid_icon' style={{ color: "#ffc107" }} />
                                                                <p>Paid Amount</p>
                                                            </div>
                                                            <div>
                                                                <h4>
                                                                    <span><CountUp prefix="Rs. " suffix='/-' start={0} end={user.totalPaid} decimal="." decimals={0} duration={1.75} /></span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mb-2'>
                                                    <div className="card grid_card h-100">
                                                        <div className="content">
                                                            <div className='d-flex ai-center'>
                                                                <TfiBarChart className='grid_icon' style={{ color: "#6c757d" }} />
                                                                <p>Due Payment</p>
                                                            </div>
                                                            <div>
                                                                <h4>
                                                                    <span><CountUp prefix="Rs. " suffix='/-' start={0} end={repayments.reduce(function (acc, obj) { return acc + obj.amount; }, 0)} decimal="." decimals={0} duration={1.75} /></span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">

                                                <Tabs tabLinks={isMobile ? Mob_tabs : tabs} show={showTab} setShow={setShowTab} />

                                                {
                                                    showTab == "" &&
                                                    <div>
                                                        <div className='d-flex jc-between'>
                                                            <h4>Due Payments</h4>
                                                            {/* <UserModal btnText="Pay Now" heading="Pay Due Payments" id={`pay_now`} customClasses="save_btn pay_now_btn" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                                <form onSubmit={payNow}>
                                                                    <div className="form-group">
                                                                        <label className='form-label'>Date <span className='required'>*</span></label>
                                                                        <input type="date" placeholder='Enter here' className='form-control' name="date" value={formData.date} onChange={handleChange} />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className='form-label'>Amount <span className='required'>*</span></label>
                                                                        <input type="number" placeholder='Enter here' className='form-control' name="amount" value={formData.amount} onChange={handleChange} />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className='form-label'>Mode of Payment <span className='required'>*</span></label>
                                                                        <select className="form-select" name="modeOfPayment" value={formData.modeOfPayment} onChange={handleChange}>
                                                                            <option selected disabled value={""}>Select Mode of Payment</option>
                                                                            <option value="meeazan bank">Meezan Bank</option>
                                                                            <option value="cheque">Cheque</option>
                                                                            <option value="other">Other</option>
                                                                        </select>
                                                                    </div>

                                                                    <input type="file" onChange={handleChange} accept=".jpg, .jpeg, .png" name="slip" />

                                                                    <div className='d-flex jc-end'>
                                                                        <button type="submit" className='btn save_btn'>Pay Now</button>
                                                                    </div>
                                                                </form>
                                                            </UserModal> */}

                                                        </div>

                                                        {
                                                            repayments?.length > 0 ?
                                                                <>
                                                                    <Table head={["Month", "Amount", ""]}>
                                                                        {
                                                                            repayments?.map((item, i) => {
                                                                                return <tr key={i} >
                                                                                    <td><Text text={item?.date} /></td>
                                                                                    <td><Text text={parseFloat(item?.amount)} /></td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </Table>

                                                                    {/* <Pagination itemsPerPage={4} /> */}
                                                                </>
                                                                :
                                                                <h5 className='not_found_msg'>No Records Found</h5>
                                                        }
                                                    </div>
                                                }


                                                {
                                                    showTab == "repayment_history" &&
                                                    <>
                                                        <div className='d-flex ai-center jc-between mb-3'>
                                                            <div>
                                                                <h4 className='mb-0'>Repayment History</h4>
                                                            </div>
                                                            <div className='d-flex'>
                                                                {
                                                                    stdRepayments?.length > 0 && (
                                                                        <UserModal btnText="Filter" heading="Filter" id={`filter`} customClasses="save_btn" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                                            <form onSubmit={getDD}>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <div className="form-group">
                                                                                            <label className='form-label'>Format</label>
                                                                                            <select onChange={(e) => setFormat(e.target.value)} class="form-select" aria-label="Default select example">
                                                                                                <option disabled selected value="">Select Format</option>
                                                                                                <option value="date">Date</option>
                                                                                                <option value="month">Month</option>
                                                                                                {/* <option value="three">Three</option> */}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {
                                                                                        format == "date" && (
                                                                                            <>
                                                                                                <div className="col-md-6 col-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className='form-label'>Start Date</label>
                                                                                                        <input
                                                                                                            onChange={(e) => setDate11({ ...date11, start: e.target.value })}
                                                                                                            type="date"
                                                                                                            name=""
                                                                                                            className='form-control'
                                                                                                            required={format === "date" ? true : false}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className='form-label'>End Date</label>
                                                                                                        <input
                                                                                                            type="date"
                                                                                                            onChange={(e) => setDate11({ ...date11, end: e.target.value })}
                                                                                                            name=""
                                                                                                            required={format === "date" ? true : false}
                                                                                                            className='form-control'
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        format == "month" && (
                                                                                            <>
                                                                                                <div className="col-md-6 col-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className='form-label'>Start Month</label>
                                                                                                        <input
                                                                                                            onChange={(e) => setMonth({ ...month, start: e.target.value })}
                                                                                                            type="month"
                                                                                                            name=""
                                                                                                            className='form-control'
                                                                                                            required={format === "month" ? true : false}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-12">
                                                                                                    <div className="form-group">
                                                                                                        <label className='form-label'>End Month</label>
                                                                                                        <input
                                                                                                            type="month"
                                                                                                            onChange={(e) => setMonth({ ...month, end: e.target.value })}
                                                                                                            name=""
                                                                                                            className='form-control'
                                                                                                            required={format === "month" ? true : false}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }


                                                                                    <div className='d-flex jc-end'>
                                                                                        <button type="submit" className='btn save_btn'>Save</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </UserModal>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>

                                                        {
                                                            stdRepayments?.length > 0 ?
                                                                <>
                                                                    <Table head={["Month", "Amount", "Paid Amount", "Due Amount", "Status", "Repayment Date", "Mode of Payment", "Payment Details", "Date"]}>
                                                                        {
                                                                            stdRepayments?.map((history, i) => {
                                                                                return <tr key={i} className={`${history?.remainingAmount == 0 ? "complete" : "incomplete"}`}>
                                                                                    <td><Text text={history?.month} /></td>
                                                                                    <td><Text text={parseFloat(history?.amount) + parseFloat(history?.remainingAmount)} /></td>
                                                                                    <td><Text text={history?.amount} /></td>
                                                                                    <td><Text text={history?.remainingAmount} /></td>
                                                                                    <td><Badges text={history?.remainingAmount == 0 ? "complete" : "incomplete" || "---"} classes={`${history?.remainingAmount == 0 ? "complete" : "incomplete"} md`} /></td>
                                                                                    <td><Text text={history?.repaymentDate} /></td>
                                                                                    <td><Text text={history?.modeOfPayment} /></td>
                                                                                    <td><Text text={history?.chequeNo || history.slipNo || history?.stanNo || history?.accountNo ||history?.otherDetails} /></td>
                                                                                    <td><Text text={formatDateTime(history?.datetime)} /></td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </Table>

                                                                    <Pagination handlePagination={handlePagination} totalCount={totalCount} itemsPerPage={4} />
                                                                </>
                                                                :
                                                                <h5 className='not_found_msg'>No Records Found</h5>
                                                        }
                                                    </>
                                                }

                                                {
                                                    showTab == "disbursement_history" &&
                                                    <>
                                                        <div className="">
                                                            <h4>Disbursement History</h4>
                                                            {
                                                                disbursements?.length > 0 ?
                                                                    <Table head={["Student Name", "University Name", "Program", "Tranch", "Amount", "Status", "Case Type", "Date"]}>
                                                                        {
                                                                            disbursements?.map((data, i) => {
                                                                                return <tr key={i}>
                                                                                    <td><Text text={data?.userId?.fullName || "---"} /></td>
                                                                                    <td><Text text={data?.institute?.fullName} /></td>
                                                                                    <td><Text text={data?.program?.name} /></td>
                                                                                    <td><Text text={data?.tranchNo} /></td>
                                                                                    <td><Text text={formatter(data?.amount)} /></td>
                                                                                    <td><Badge text={data?.status || "---"} classes={`${data?.status} md`} /></td>
                                                                                    <td><Text text={data?.type} /></td>
                                                                                    <td><Text text={timestampToDate(data?.timestamp) || "---"} /></td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </Table>
                                                                    :
                                                                    <h5 className='not_found_msg'>No Records Found</h5>

                                                            }
                                                        </div>

                                                    </>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row row_reverse">
                                        <div className="col-lg-4 col-md-12">
                                            <div className="card form_steps_card mb-3">
                                                <h1>Application Form Procedure</h1>
                                                <p className='desc'>Follow these steps to successfully fill out the Application Form</p>

                                                <div className='percent_box d-flex ai-center mb-3'>
                                                    <p className='profile_percent'>
                                                        {/* 70% */}
                                                        {
                                                            // (user.pInfo && user.qInfo) ? "66%" : user.qInfo ? "33%" : user.pInfo ? "33%" : "0%"
                                                            app ? "100%" : (user.pInfo && user.qInfo) ? "66%" : user.qInfo ? "33%" : user.pInfo ? "33%" : "0%"

                                                        }
                                                    </p>

                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                                            role="progressbar"
                                                            aria-valuenow="75"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{
                                                                width: app ? "100%" : (user.pInfo && user.qInfo) ? "66%" : user.qInfo ? "33%" : user.pInfo ? "33%" : "0%"
                                                            }}>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='form_steps_msg w-100'>

                                                    <Link to="/std-profile" className='link'>
                                                        <div className='profile_steps mb-3'
                                                            onMouseEnter={() => handleButtonHover(1)}
                                                            onMouseLeave={() => handleButtonHover(0)}
                                                        >

                                                            <div className='d-flex ai-center'>
                                                                <FaCheckCircle className={`${user.pInfo == true ? "check_icon" : "uncheck_icon"}`} />
                                                                <div>
                                                                    <h5>Step: 1</h5>
                                                                    <h4>Personal Information</h4>
                                                                </div>
                                                            </div>
                                                            <button className='btn btn-complete'><IoArrowForwardCircleOutline /></button>
                                                            {/* <PiArrowBendDownLeftBold className='animated-arrow' /> */}
                                                        </div>
                                                    </Link>

                                                    <Link to="/std-profile#education-details" className='link'>
                                                        <div className='profile_steps'
                                                            onMouseEnter={() => handleButtonHover(2)}
                                                            onMouseLeave={() => handleButtonHover(0)}
                                                        >
                                                            <div className='d-flex ai-center'>
                                                                <FaCheckCircle className={`${user.qInfo == true ? "check_icon" : "uncheck_icon"}`} />
                                                                <div>
                                                                    <h5>Step: 2</h5>
                                                                    <h4>Education Details</h4>
                                                                </div>
                                                            </div>
                                                            <button className='btn btn-complete'><IoArrowForwardCircleOutline /></button>
                                                            {/* <PiArrowBendDownLeftBold className='animated-arrow' /> */}
                                                        </div>
                                                    </Link>

                                                    <Link to="/application-form" className='link'>
                                                        <div className='profile_steps mt-3'
                                                            onMouseEnter={() => handleButtonHover(3)}
                                                            onMouseLeave={() => handleButtonHover(0)}
                                                        >
                                                            <div className='d-flex ai-center'>
                                                                <FaCheckCircle className={`${app ? "check_icon" : "uncheck_icon"}`} />
                                                                <div>
                                                                    <h5>Step: 3</h5>
                                                                    <h4>Fill Application Form</h4>
                                                                </div>
                                                            </div>
                                                            <button className='btn btn-complete'><IoArrowForwardCircleOutline /></button>
                                                        </div>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-8 col-md-12">
                                            <DashboardSlider currentSlide={currentSlide} />
                                        </div>
                                    </div>
                                </>

                        }

                    </section >
                </div >
            </div >
        </>
    )
}

export default StudentDashboard
