import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useProSidebar } from 'react-pro-sidebar';
import { useAuth } from '../../../ContextAPI/Components/auth';
import { useToast } from "../../../ContextAPI/Components/toast"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../ContextAPI/Components/confirm';

import "../Form/Form.css"

import NavSidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import { getReasonRenewal, validateRenewal, getrenewal, GetUnisList, GetUnisProfileForStudent, submission4, submission3, submission2, submission1, submission5 } from '../../../ContextAPI/APIs/api';
import Select from '../../../components/Form/Select_New';
import SelectSimple from '../../../components/Form/Select';
import { ValidatePhoneNumber } from '../../../helpers/validatePhoneNumber';

import { RenewalUrl, semesters, years } from '../../../helpers/data';
import RenewalInstructions from './RenewalInstructions';
import RenewalTabs from './RenewalTabs';
import Preview from '../../../components/RenewalForm/Preview';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import ImageUploadInputrenewal from '../../../components/Modals/ImageUploadInputrenewal';

import PERSONAL_INFO from "../../../assets/form-icons/personal-information.png"
import ACADEMIC_INFO from "../../../assets/form-icons/academic.png"
import INCOME_DETAIL from "../../../assets/form-icons/income-detail.png"
import CASE_HISTORY from "../../../assets/form-icons/case-history.png"
import AccessDenied from './AccessDenied';
import FormErrors from '../../../components/ApplicationForm/FormErrors';
import ReopenFrom from '../../../components/RenewalForm/ReopenFrom';



const jobStatuses123 = [
    { value: "employed", label: "Employed" },
    { value: "unemployed", label: "Unemployed" },
    { value: "on_leave_with_pay", label: "On Leave with Pay" },
    { value: "on_leave_without_pay", label: "On Leave without Pay" }
]

const renewalStatus123 = [
    { value: "1st_review", label: "1st Review" },
    { value: "2nd_review", label: "2nd Review" },
    { value: "3rd_review", label: "3rd Review" }
]

const Promptrepayment123 = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" }
]

// const years = ["1st","2nd","3rd","4th","5th"]
// const semesters = ["1st","2nd","3rd","4th","5th","6th","7th","8th","9th","10th"]



function RenewalForm() {

    const { alert } = useToast();
    const { getRenewalTabs, user, renewalTabData } = useAuth();
    const { Ask } = useConfirm();
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setImageUrls([])
        setParam("")
    }
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };
    const [imageUrls, setImageUrls] = useState([]);
    const [param, setParam] = useState("")

    const [uni, setUni] = useState([])
    const [program, setProgram] = useState([])
    const getAllUni = async () => {
        try {
            const response = await GetUnisList()

            if (response?.success) {
                console.log("UNISS", response.message);
                setUni(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const getUnisProfileForStudent = async (id) => {
        try {
            const response = await GetUnisProfileForStudent({ uniId: id })
            console.log("REPO", response);

            if (response.success) {
                setProgram(response?.message?.programs)
            } else {
                setProgram([])
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [data, setData] = useState({
        date: "",
        Name: "",
        Institute: "",
        Program: "",
        contactNo: "",
        email: "",
        status: "",
        sem_year: "",
        cGPA: "",
        meezanBanckAccount: "",
        jobStatus: "",
        Amt_Disb_ToDate: "",
        Monthly_rePay: "",
        rePayDate: "",
        amountOutstanding: "",
        promptRepay: "",
        question1: "",
        question2: "",
    })


    const [data3, setData3] = useState({
        prevTotalIncome: "",
        currTotalIncome: "",
        prevTotalExpense: "",
        currTotalExpense: "",
        prevTotalAssets: "",
        currTotalAssets: "",
        amtRequested: "",
        revMonthlyRepay: "",
        srcMonthlyRepay: "",
        newMajorExpense: "",
        reasonForAssetsChange: "",
        srcMonthlyRepay: "",
    })

    const getData = async () => {
        try {
            const response = await getrenewal()
            console.log("RENEW1212", response);
            if (response.success) {
                console.log("RENEW", response);
                setData(response.message)
                setData3(response.message)

                setData3(
                    {
                        ...data3,
                        prevTotalIncome: response.message.prevTotalIncome,
                        currTotalIncome: response.message.currTotalIncome,
                        prevTotalExpense: response.message.prevTotalExpense,
                        currTotalExpense: response.message.currTotalExpense,
                        prevTotalAssets: response.message.prevTotalAssets,
                        currTotalAssets: response.message.currTotalAssets,
                        amtRequested: response.message.amtRequested,
                        revMonthlyRepay: response.message.revMonthlyRepay,
                        srcMonthlyRepay: response.message.srcMonthlyRepay,
                        newMajorExpense: response.message.newMajorExpense,
                        reasonForAssetsChange: response.message.reasonForAssetsChange,
                        srcMonthlyRepay: response.message.srcMonthlyRepay,

                    }
                )

            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const checkRenewal = async () => {
        const res = await validateRenewal()

        // {
        //     if (res.success) {
        //         if (res.message.application) {
        //             navigate("/application-tracking")
        //         }
        //         if (res.message.renewalStatus === "done") {
        //             if (res.message.RenewStatus !== "resubmit") {
        //                 navigate("/application-tracking")
        //             }
        //         }
        //     }
        // }

        console.log("FFFF", res);

        if (res?.renewal) {
            if (res?.renewal.RenewStatus === "disburse" || res?.renewal.RenewStatus === "reject") {
                if (res?.renewal.nextReview > Date.now()) {
                    navigate("/application-tracking")
                }
            }
            else {
                if (res.message.renewalStatus === "done" && res.message.RenewStatus !== "resubmit") {
                    navigate("/application-tracking")
                }
            }

        }

    }

    const getR = async () => {
        try {
            const response = await getReasonRenewal()
            if (response.success) {
                // alert(response.message, "warning", "")
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getR()
        getData()
        getAllUni()
        // haveSubmitted()
        checkRenewal()
    }, [])

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("instructions");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "instructions");
        setShowForm((hash.split("#"))[1] || "instructions")
        if ((hash.split("#"))[1] == "step_2") {
            setData({
                date: "",
                Name: user?.fullName,
                Institute: user?.university?.fullName,
                Program: user?.program?.name,
                contactNo: user?.phone,
                email: user?.email,
                status: "",
                sem_year: "",
                cGPA: "",
                meezanBanckAccount: "",
                jobStatus: "",
                Amt_Disb_ToDate: "",
                Monthly_rePay: "",
                rePayDate: "",
                amountOutstanding: "",
                promptRepay: "",
                question1: "",
                question2: "",
            })
        }

    }, [hash])


    // NEW
    const [form1, setForm1] = useState({
        Name: user?.fullName || "",
        email: user?.email || "",
        contactNo: user?.phone || "",
        Institute: user?.university?._id || "",
        InstituteName: user?.university?.fullName || "",
        Program: user?.program?._id || "",
        ProgramName: user?.program?.name || "",
        meezanBanckAccount: "",
        // status: "",
        status: {
            value: "",
            label: "Select your Job status"
        },
    })

    const [form2, setForm2] = useState({
        reviewNo: "",
        sem_year: "",
        semtype: "",
        cGPA: "",
    })

    const [form3, setForm3] = useState({
        Amt_Disb_ToDate: "",
        Monthly_rePay: "",
        rePayDate: "",
        amountOutstanding: "",
        promptRepay: "",
        question1: "",
        question2: "",
        amtRequested: "",
        revMonthlyRepay: "",
        srcMonthlyRepay: "",
    })

    const [form4, setForm4] = useState({
        prevTotalIncome: "",
        currTotalIncome: "",
        prevTotalExpense: "",
        currTotalExpense: "",
        prevTotalAssets: "",
        currTotalAssets: "",
        newMajorExpense: "",
        reasonForAssetsChange: "",
        reasonForIncomeChange: "",
        reasonForIncomeChange2: "",
        srcMonthlyRepay: "",
    })


    const handleChange = (e) => {
        console.log("UUU", user);
        const { name, value } = e.target;
        setForm1((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));

    };

    const handleSelectChange = (selectedOption) => {
        setForm1((prevForm) => ({
            ...prevForm,
            jobStatus: selectedOption,
        }));
    };

    const handleChangeForm2 = (e) => {
        const { name, value } = e.target;
        setForm2((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleChangeForm3 = (e) => {
        const { name, value } = e.target;
        setForm3((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleChangeForm4 = (e) => {
        const { name, value } = e.target;
        setForm4((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const Submission1 = async () => {
        try {
            const payload = {
                ...form1,
                // status: ""
                status: form1?.status?.value || ""
            }
            const check = validationForm1();
            if (check === "proceed") {
                const response = await submission1(payload)
                alert(response.message, response.success)
                console.log("PPAAYY Submission1", payload);
                getRenewalTabs()
                if (response.success) {
                    navigate(`#${"academic_background"}`)
                }
            }
            else {
                alert("Form Validation Error", "error")
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const Submission2 = async () => {
        try {

            console.log("invoked !!");

            const payload = {
                ...form2,
            }

            console.log("PPAAYY Submission2", payload);
            // return

            const check = validationForm2();
            if (check === "proceed") {
                const response = await submission2(payload)
                alert(response.message, response.success)
                getRenewalTabs()

                if (response.success) {
                    navigate(`#${"renewal_details"}`)
                }
            }
            else {
                alert("Form Validation Error", "error")
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const Submission3 = async () => {
        try {
            const payload = {
                ...form3,
                promptRepay: form3.promptRepay.value
            }

            console.log("PPAAYY333", payload);
            // return
            const check = validationForm3();

            if (check === "proceed") {
                console.log("PPAAYY333", payload);
                const response = await submission3(payload)
                alert(response.message, response.success)
                getRenewalTabs()
                if (response.success) {
                    navigate(`#${"additional_information"}`)
                    // navigate(`#${"renewal_details"}`)
                }
            }
            else {
                alert("Form Validation Error", "error")
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const Submission4 = async () => {
        try {
            const payload = {
                ...form4
            }
            console.log("PPAAYY", payload);
            const check = validationForm4();
            if (check === "proceed") {
                const response = await submission4(payload)
                alert(response.message, response.success)
                getRenewalTabs()
                if (response.success) {
                    if (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done") {
                        navigate(`#${"preview"}`)
                    }
                    // navigate(`#${"renewal_details"}`)
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const Submission5 = async () => {
        try {
            if (!dis) {
                alert("Please Agree terms and Conditions", "false")
            }
            else {
                const response = await submission5()
                alert(response.message, response.success)
                if (response.success) {
                    navigate("/application-tracking")
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const [abc, setABC] = useState({})

    const getDData = async () => {
        try {
            const response = await getrenewal()
            console.log("RRR", response);
            if (response.success) {

                console.log("(jobStatuses123.find(e => e.value == response.message.status)", (jobStatuses123.find(e => e.value == response.message.status)));

                setForm1({
                    ...form1,
                    Name: response.message.Name,
                    email: response.message.email,
                    contactNo: response.message.contactNo,
                    Institute: response.message.Institute,
                    Program: response.message.Program,
                    meezanBanckAccount: response.message.meezanBanckAccount,
                    status: (response.message.status != "" && typeof response.message.status != "undefined") ?
                        {
                            value: (jobStatuses123.find(e => e.value == response.message.status)?.value),
                            label: "Select your Job status"
                        }
                        :
                        {
                            value: "",
                            label: "Select your Job status"
                        }
                })

                setForm2({
                    ...form2,
                    // reviewNo: (response.message.reviewNo != "" && typeof response.message.reviewNo != "undefined") ? renewalStatus123.find(e => e.value == response.message.reviewNo) : "",
                    reviewNo: response.message.reviewNo,
                    sem_year: response.message.sem_year,
                    cGPA: response.message.cGPA,
                })

                setForm3({
                    ...form3,
                    Amt_Disb_ToDate: response.message.Amt_Disb_ToDate,
                    Monthly_rePay: response.message.Monthly_rePay,
                    rePayDate: response.message.rePayDate,
                    amountOutstanding: response.message.amountOutstanding,
                    promptRepay: (response.message.promptRepay != "" && typeof response.message.promptRepay != "undefined") ? Promptrepayment123.find(e => e.value == response.message.promptRepay) : "",
                    question1: response.message.question1,
                    question2: response.message.question2,
                    amtRequested: response.message.amtRequested,
                    revMonthlyRepay: response.message.revMonthlyRepay,
                    srcMonthlyRepay: response.message.srcMonthlyRepay,
                })

                setForm4({
                    ...form4,
                    prevTotalIncome: response.message.prevTotalIncome,
                    currTotalIncome: response.message.currTotalIncome,
                    prevTotalExpense: response.message.prevTotalExpense,
                    currTotalExpense: response.message.currTotalExpense,
                    prevTotalAssets: response.message.prevTotalAssets,
                    currTotalAssets: response.message.currTotalAssets,
                    newMajorExpense: response.message.newMajorExpense,
                    reasonForAssetsChange: response.message.reasonForAssetsChange,
                    srcMonthlyRepay: response.message.srcMonthlyRepay,
                    reasonForIncomeChange2: response.message.reasonForIncomeChange2
                })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDData()
        getRenewalTabs()
    }, [])

    useEffect(() => {
        console.log("form1");
        console.log(form1);

    }, [form1])

    useEffect(() => {
        console.log("----------------");
        console.log(user);
        console.log(user.fullName);
        console.log(user.email);
        setForm1({
            Name: user?.fullName || "",
            email: user?.email || "",
            contactNo: user?.phone || "",
            Institute: user?.university?._id || "",
            InstituteName: user?.university?.fullName || "",
            Program: user?.program?._id || "",
            ProgramName: user?.program?.name || "",
            status: {
                value: "",
                label: "Select option"
            }
        })

    }, [user])



    let errors = {}
    const [formError, setFormError] = useState([])


    const validationForm1 = () => {
        try {
            console.log("validation function invoked");
            errors = {}
            if (form1?.status?.value == undefined || form1?.status?.value == "") {
                errors.jobStatus = "Job Status is required";
            }
            console.log("FORM1", form1);
            if (form1.meezanBanckAccount == "" || form1.meezanBanckAccount === undefined) {
                console.log("NOO")
                errors.meezanBanckAccount = "Bank Account No is required";
            }

            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const validationForm2 = () => {
        try {
            errors = {}
            if (form2.reviewNo == "" || form2.reviewNo === undefined) {
                errors.status = "Status is required";
            }
            if (form2.sem_year == "" || form2.sem_year === undefined) {
                errors.sem_year = "Semester / Year is required";
            }
            if (user.type == "semester") {
                if (form2.semtype == "" || form2.semtype === undefined) {
                    errors.semtype = "Academic Session is required";
                }
            }
            if (form2.cGPA == "" || form2.cGPA === undefined) {
                errors.cGPA = "CGPA is required";
            }

            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validationForm3 = () => {
        try {
            errors = {}
            if (form3.Amt_Disb_ToDate == "" || form3.Amt_Disb_ToDate === undefined) {
                errors.Amt_Disb_ToDate = "Amount Disbursed Date is required";
            }
            if (form3.Monthly_rePay == "" || form3.Monthly_rePay === undefined) {
                errors.Monthly_rePay = "Monthly Repayment Amount is required";
            }
            if (form3.rePayDate == "" || form3.rePayDate === undefined) {
                errors.rePayDate = "Repayments Date is required";
            }
            if (form3.amountOutstanding == "" || form3.amountOutstanding === undefined) {
                errors.amountOutstanding = "Outstanding Amount is required";
            }
            if (form3.promptRepay == "" || form3.promptRepay === undefined) {
                errors.promptRepay = "Prompt Repayment is required";
            }

            //question ki validation lagani hai if k andr 
            if (form3.promptRepay.value === "no") {
                if (form3.question1 == "" || form3.question1 === undefined) {
                    errors.question1 = "Explain the reason of Prompt Repayment is required";
                }
            }


            if (form3.amtRequested == "" || form3.amtRequested === undefined) {
                errors.amtRequested = "Amount Requested is required";
            }
            if (form3.revMonthlyRepay == "" || form3.revMonthlyRepay === undefined) {
                errors.revMonthlyRepay = "Reviewed Monthly Repayment Amount is required";
            }
            if (form3.srcMonthlyRepay == "" || form3.srcMonthlyRepay === undefined) {
                errors.srcMonthlyRepay = "Source of Monthly Repayment is required";
            }

            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validationForm4 = () => {
        try {
            if (form4.prevTotalIncome == "" || form4.prevTotalIncome === undefined) {
                errors.prevTotalIncome = "Previous Total Income is required";
            }
            if (form4.currTotalIncome == "" || form4.currTotalIncome === undefined) {
                errors.currTotalIncome = "Current Total Income is required";
            }
            if (form4.prevTotalExpense == "" || form4.prevTotalExpense === undefined) {
                errors.prevTotalExpense = "Previous Total Expense is required";
            }
            if (form4.currTotalExpense == "" || form4.currTotalExpense === undefined) {
                errors.currTotalExpense = "Current Total Expense is required";
            }

            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    function IsError(id) {
        return !(Object.keys(formError)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formError)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }

    const [ugrad, isUgrad] = useState(false)
    const [dis, setDis] = useState(false)

    const handleCheckBoxUgrad = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setDis(true)
        }
        else {
            setDis(false)
        }
        isUgrad(e.target.checked)
    }



    return (
        <>

            <Helmet>
                <title>Renewal Form</title>
            </Helmet>

            {
                (user.role == "student" && user.pInfo && user.qInfo)
                    ?
                    <>
                        {
                            user.nextReview < Date.now() ?
                                <>
                                    {
                                        showForm == "instructions" &&
                                        <RenewalInstructions />
                                    }

                                    {
                                        (showForm == "demographics_information" || showForm == "academic_background" || showForm == "renewal_details" || showForm == "additional_information" || showForm == "preview" || showForm == "final") &&

                                        < div className="page_div">
                                            <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                                            <section className='renewal_form_section section_main'>

                                                <div className="card">
                                                    <div className='header mb-4'>
                                                        <div className='heading'>
                                                            {/* <h2>Ihsan Trust</h2> */}
                                                            <h2>Renewal Form</h2>
                                                            <p>
                                                                It is responsibility of the candidate to provide all the documents and if at any stage the documents are found to be fake/forged the Ihsan Trust will have the right to take necessary actions which may lead to cancellation of application without intimation.
                                                            </p>
                                                            <ul>
                                                                <li>
                                                                    All fields marked with (<span className='required'>*</span>) are mandatory and ensure all compulsory documents are uploaded in required format.
                                                                </li>
                                                                <li>
                                                                    Please ensure that your mobile numbers, CNIC, Email and Permenat address are correct and updated.
                                                                </li>
                                                                <li>
                                                                    Check all the provided details before moving to the Next section.
                                                                </li>
                                                            </ul>
                                                            <button className='btn save_btn'>Online Application Video Guide</button>
                                                        </div>
                                                    </div>

                                                    <RenewalTabs showForm={showForm} />

                                                    <div className="row">
                                                        <div className="col-xl-3 col-lg-12 col-md-12 mb-4">
                                                            <div className="card required_doc_card">
                                                                {/* REQUIRED DOCUMENTS */}
                                                                <div className="mb-4">
                                                                    <h4>Required Documents</h4>
                                                                    <div className="req_content">
                                                                        <Uploaded_Doc_Checked
                                                                            keyName="paidFeeVoucher"
                                                                            lable="Latest (paid (if any) and unpaid) fee Voucher / fee ledger"
                                                                        />
                                                                        <Uploaded_Doc_Checked
                                                                            keyName="latestTranscript"
                                                                            lable="Latest Transcript / Result Sheet"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="">
                                                                    <h4>FAQ</h4>
                                                                    <div className="accordion" id="accordionExample">
                                                                        <div className="accordion-item renewal_faq_item">
                                                                            <h2 className="accordion-header renewal_faq_header" id="headingFAQ">
                                                                                <button className="accordion-button renewal_faq_btn" type="button" disabled data-bs-toggle="collapse" data-bs-target="#collapseFAQ" aria-expanded="true" aria-controls="collapseFAQ">
                                                                                    What is the process of making repayments?
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseFAQ" className="accordion-collapse collapse show" aria-labelledby="headingFAQ" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body renewal_faq_body">
                                                                                    The repayment of Interest-Free Loan is mandatory (compulsory) and is on monthly basis. The student has to pay his/her repayments as per the mutual agreement from the next month of loan disbursement.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-xl-9 col-lg-12 col-md-12">
                                                            <div className="card">

                                                                {/* <RenewalTabs showForm={showForm} /> */}

                                                                {
                                                                    (Object.values(formError)).length > 0 && (
                                                                        <FormErrors errorList={formError} />
                                                                    )
                                                                }

                                                                {/* 1 */}
                                                                {
                                                                    showForm == "demographics_information" &&
                                                                    <>
                                                                        {
                                                                            (user.role == "student" && user.pInfo && user.qInfo) ?
                                                                                <>
                                                                                    <div className="accordion" id="accordionExample">
                                                                                        <div className="accordion-item">
                                                                                            <h2 className="accordion-header" id="headingOne">
                                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                                    <img src={PERSONAL_INFO} alt="icon" className='img-fluid form_icons' />
                                                                                                    Personal Info
                                                                                                </button>
                                                                                            </h2>
                                                                                            <div id="collapseOne" className={`accordion-collapse collapse ${(Object.values(formError)).length > 0 ? "show" : "show"}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                                <div className="accordion-body">
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Name</label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className='form-control'
                                                                                                                    name="Name"
                                                                                                                    value={form1.Name}
                                                                                                                    placeholder='Enter here'
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Email Address</label>
                                                                                                                <input
                                                                                                                    type="email"
                                                                                                                    className='form-control'
                                                                                                                    name="email"
                                                                                                                    value={form1.email}
                                                                                                                    placeholder='Enter here'
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Contact Number</label>
                                                                                                                <input
                                                                                                                    inputType="tel"
                                                                                                                    className='form-control'
                                                                                                                    name="contactNo"
                                                                                                                    value={form1.contactNo}
                                                                                                                    placeholder='Enter here'
                                                                                                                    disabled
                                                                                                                />
                                                                                                                <small className="text-danger">{(data.contactNo != null && data.contactNo != "" && ValidatePhoneNumber(data.contactNo) == false) && "Phone numbers should start with +92 and be 13 characters long."}</small>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Name of the University/Institution</label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className='form-control'
                                                                                                                    name="Name"
                                                                                                                    value={form1?.InstituteName}
                                                                                                                    placeholder='Enter here'
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Program</label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className='form-control'
                                                                                                                    name="Name"
                                                                                                                    value={form1?.ProgramName}
                                                                                                                    placeholder='Enter here'
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='jobStatus'>
                                                                                                                <label className='form-label'>Job Status <span className='required'>*</span></label>
                                                                                                                {console.log("000000000 => ", form1)}
                                                                                                                {console.log(form1?.status)}
                                                                                                                <Select
                                                                                                                    options={jobStatuses123}
                                                                                                                    isOptionDisabled={(option) => (option.value == '' || option.value == null)}
                                                                                                                    value={form1?.status || { value: '', label: 'Select your Job status' }}
                                                                                                                    getOptionLabel={option =>
                                                                                                                        `${option.label}`
                                                                                                                    }
                                                                                                                    getOptionValue={option => `${option.value}`}

                                                                                                                    onChange={(event_value) =>
                                                                                                                        setForm1({
                                                                                                                            ...form1,
                                                                                                                            status: event_value
                                                                                                                        })
                                                                                                                    }

                                                                                                                    loadingMessage={"Loading..."}
                                                                                                                    style={{ textTransform: "capitalize" }}
                                                                                                                    required
                                                                                                                    className={`${(formError.length == 0 ? "" : IsError("jobStatus") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("jobStatus"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("jobStatus"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <div className='form-group' id='meezanBanckAccount'>
                                                                                                                <label className="form-label">Meezan Bank Current Account No <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    name="meezanBanckAccount"
                                                                                                                    value={form1.meezanBanckAccount}
                                                                                                                    onChange={handleChange}
                                                                                                                    placeholder='Enter here'
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("meezanBanckAccount") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("meezanBanckAccount"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("meezanBanckAccount"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='d-flex jc-end mt-4'>
                                                                                        <button className='btn next_btn' onClick={() => Submission1()}>Save and Next</button>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <AccessDenied />
                                                                        }
                                                                    </>
                                                                }

                                                                {/* 2 */}
                                                                {
                                                                    showForm == "academic_background" &&
                                                                    <>
                                                                        {
                                                                            (user.role == "student" && user.pInfo && user.qInfo) ?
                                                                                <>
                                                                                    <div className="accordion" id="accordionExample">
                                                                                        <div className="accordion-item">
                                                                                            <h2 className="accordion-header" id="headingTwo">
                                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                                                    <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                                                                                    Semester / Year
                                                                                                </button>
                                                                                            </h2>
                                                                                            <div id="collapseTwo" className={`accordion-collapse collapse ${(Object.values(formError)).length > 0 ? "show" : "show"}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                                                <div className="accordion-body">
                                                                                                    <div className="row">
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='status'>
                                                                                                                <SelectSimple
                                                                                                                    name="status"
                                                                                                                    selectText="Select Status"
                                                                                                                    value={form2.reviewNo} onChange={(e) => setForm2({ ...form2, reviewNo: e.target.value })}
                                                                                                                    label={"Status"} span={"*"} options={["1st Reveiw", "2nd Review", "3rd Review"]}
                                                                                                                    class={`form-select ${(formError.length == 0 ? "" : IsError("status") ? "success" : "error")}`}
                                                                                                                />

                                                                                                                {
                                                                                                                    (ShowError("status"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("status"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='sem_year'>
                                                                                                                <SelectSimple
                                                                                                                    name="sem_year"
                                                                                                                    selectText={user.type == "semester" ? "Select your Semester" : "Select your Year"}
                                                                                                                    value={form2.sem_year} onChange={handleChangeForm2}
                                                                                                                    label={"Semester/Year"} span={"*"} options={user.type == "semester" ? semesters : years}
                                                                                                                    class={`form-select ${(formError.length == 0 ? "" : IsError("sem_year") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("sem_year"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("sem_year"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            user.type == "semester" &&
                                                                                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                                <div className='form-group' id='semtype'>
                                                                                                                    <SelectSimple
                                                                                                                        name="semtype"
                                                                                                                        selectText={"Select Academic Session"}
                                                                                                                        value={form2.semtype} onChange={handleChangeForm2}
                                                                                                                        label={"Academic Session"} span={"*"} options={["Spring", "Fall"]}
                                                                                                                        class={`form-select ${(formError.length == 0 ? "" : IsError("semtype") ? "success" : "error")}`}
                                                                                                                    />
                                                                                                                    {
                                                                                                                        (ShowError("semtype"))[0] &&
                                                                                                                        <p className='error_label'>{(ShowError("semtype"))[1]}</p>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }

                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <ImageUploadInputrenewal
                                                                                                                name="feeLedger"
                                                                                                                text="Latest (paid (if any) and unpaid) Fee Voucher / Fee Ledger"
                                                                                                                span="*"
                                                                                                                url={RenewalUrl}
                                                                                                                itemkey="paidFeeVoucher"
                                                                                                                accept=".pdf"
                                                                                                                multiple={false}
                                                                                                            />
                                                                                                        </div>

                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='cGPA'>
                                                                                                                <label className="form-label">Current (latest) CGPA / Percentage(%) <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    name="cGPA"
                                                                                                                    value={form2.cGPA}
                                                                                                                    onChange={handleChangeForm2}
                                                                                                                    placeholder='Enter here'
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("cGPA") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("cGPA"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("cGPA"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <ImageUploadInputrenewal
                                                                                                                name="transcript"
                                                                                                                text="Latest Transcript / Result Sheet"
                                                                                                                span="*"
                                                                                                                url={RenewalUrl}
                                                                                                                itemkey="latestTranscript"
                                                                                                                accept=".pdf"
                                                                                                                multiple={false}
                                                                                                            />
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='d-flex jc-between mt-4'>
                                                                                        <button className='btn prev_btn' onClick={() => navigate(`#${"demographics_information"}`)}>Previous</button>
                                                                                        <button className='btn next_btn' onClick={() => Submission2()}>Save and Next</button>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <AccessDenied />
                                                                        }
                                                                    </>
                                                                }

                                                                {/* 3 */}
                                                                {
                                                                    showForm == "renewal_details" &&
                                                                    <>
                                                                        {
                                                                            (user.role == "student" && user.pInfo && user.qInfo) ?
                                                                                <div className="">
                                                                                    <div className="accordion" id="accordionExample">

                                                                                        {/* Case History */}
                                                                                        <div className="accordion-item">
                                                                                            <h2 className="accordion-header" id="headingThree">
                                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                                                    <img src={CASE_HISTORY} alt="icon" className='img-fluid form_icons' />
                                                                                                    Case History
                                                                                                </button>
                                                                                            </h2>
                                                                                            <div id="collapseThree" className={`accordion-collapse collapse ${(Object.values(formError)).length > 0 ? "show" : "show"}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                                                <div className="accordion-body">
                                                                                                    <div className="row case_history">
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='Amt_Disb_ToDate'>
                                                                                                                <label className="form-label">Amount Disbursed to Date <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    name="Amt_Disb_ToDate"
                                                                                                                    // value={form3.Amt_Disb_ToDate ? new Date(form3.Amt_Disb_ToDate).toISOString().split('T')[0] : ''}
                                                                                                                    value={form3.Amt_Disb_ToDate}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    placeholder='Enter here'
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("Amt_Disb_ToDate") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("Amt_Disb_ToDate"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("Amt_Disb_ToDate"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='Monthly_rePay'>
                                                                                                                <label className="form-label">What token monthly repayment amount you can easily pay? <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    name="Monthly_rePay"
                                                                                                                    value={form3.Monthly_rePay}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    placeholder='Enter here'
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("Monthly_rePay") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("Monthly_rePay"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("Monthly_rePay"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='rePayDate'>
                                                                                                                <label className="form-label">Repayments Made to Date <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="date"
                                                                                                                    name="rePayDate"
                                                                                                                    value={form3.rePayDate ? new Date(form3.rePayDate).toISOString().split('T')[0] : ''}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    placeholder='Enter here'
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("rePayDate") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("rePayDate"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("rePayDate"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='amountOutstanding'>
                                                                                                                <label className="form-label">Amount Outstanding <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    name="amountOutstanding"
                                                                                                                    value={form3.amountOutstanding}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    placeholder='Enter here'
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("amountOutstanding") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("amountOutstanding"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("amountOutstanding"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <div className='form-group' id='promptRepay'>
                                                                                                                <label className='form-label'>Prompt repayment <span className='required'>*</span></label>
                                                                                                                <Select
                                                                                                                    options={Promptrepayment123}
                                                                                                                    isOptionDisabled={(option) => (option.value == '' || option.value == null)}
                                                                                                                    value={form3.promptRepay || { value: '', label: 'Select option' }}
                                                                                                                    getOptionLabel={option =>
                                                                                                                        `${option.label}`
                                                                                                                    }
                                                                                                                    getOptionValue={option => `${option.value}`}
                                                                                                                    onChange={(event_value) => setForm3({
                                                                                                                        ...form3,
                                                                                                                        promptRepay: event_value,
                                                                                                                    })}
                                                                                                                    loadingMessage={"Loading..."}
                                                                                                                    style={{ textTransform: "capitalize" }}
                                                                                                                    required
                                                                                                                    className={`${(formError.length == 0 ? "" : IsError("promptRepay") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("promptRepay"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("promptRepay"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            form3.promptRepay.value == "no" && (
                                                                                                                <div className="col-12 vertically_center">
                                                                                                                    <div className='form-group' id='question1'>
                                                                                                                        <label className="form-label">If No, explain why: <span className='required'>*</span></label>
                                                                                                                        <textarea
                                                                                                                            cols="30"
                                                                                                                            rows="5"
                                                                                                                            name="question1"
                                                                                                                            required
                                                                                                                            value={form3.question1}
                                                                                                                            onChange={handleChangeForm3}
                                                                                                                            placeholder='Type here...'
                                                                                                                            className={`form-control ${(formError.length == 0 ? "" : IsError("question1") ? "success" : "error")}`}
                                                                                                                        ></textarea>
                                                                                                                        {
                                                                                                                            (ShowError("question1"))[0] &&
                                                                                                                            <p className='error_label'>{(ShowError("question1"))[1]}</p>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Any other financial assistance received during the review period? If yes, then what are the amount and the type of the financial assistance?</label>
                                                                                                                <textarea
                                                                                                                    cols="30"
                                                                                                                    rows="5"
                                                                                                                    className='form-control'
                                                                                                                    name="question2"
                                                                                                                    value={form3.question2}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    placeholder='Type here...'
                                                                                                                ></textarea>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* Case Extension */}
                                                                                        <div className="accordion-item">
                                                                                            <h2 className="accordion-header" id="headingFive">
                                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                                                    <img src={CASE_HISTORY} alt="icon" className='img-fluid form_icons' />
                                                                                                    Case Extension
                                                                                                </button>
                                                                                            </h2>
                                                                                            <div id="collapseFive" className={`accordion-collapse collapse ${(Object.values(formError)).length > 0 && "show"}`} aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                                                                <div className="accordion-body">

                                                                                                    <div className="row case_history">
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='amtRequested'>
                                                                                                                <label className="form-label">Amount Requested <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="amtRequested"
                                                                                                                    value={form3.amtRequested}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("amtRequested") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("amtRequested"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("amtRequested"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='revMonthlyRepay'>
                                                                                                                <label className="form-label">Reviewed Monthly Repayment Amount <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="revMonthlyRepay"
                                                                                                                    value={form3.revMonthlyRepay}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("revMonthlyRepay") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("revMonthlyRepay"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("revMonthlyRepay"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <div className='form-group' id='srcMonthlyRepay'>
                                                                                                                <label className="form-label">Source of Monthly Repayment <span className='required'>*</span></label>
                                                                                                                <textarea
                                                                                                                    cols="30"
                                                                                                                    rows="5"
                                                                                                                    placeholder='Type here...'
                                                                                                                    name="srcMonthlyRepay"
                                                                                                                    value={form3.srcMonthlyRepay}
                                                                                                                    onChange={handleChangeForm3}
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("srcMonthlyRepay") ? "success" : "error")}`}
                                                                                                                ></textarea>
                                                                                                                {
                                                                                                                    (ShowError("srcMonthlyRepay"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("srcMonthlyRepay"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='d-flex jc-between mt-4'>
                                                                                        <button className='btn prev_btn' onClick={() => navigate(`#${"academic_background"}`)}>Previous</button>
                                                                                        <button className='btn next_btn' onClick={() => Submission3()}>Save and Next</button>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <AccessDenied />
                                                                        }
                                                                    </>
                                                                }

                                                                {/* 4 */}
                                                                {
                                                                    showForm == "additional_information" &&
                                                                    <>
                                                                        {
                                                                            (user.role == "student" && user.pInfo && user.qInfo) ?
                                                                                <>
                                                                                    <div className="accordion" id="accordionExample">
                                                                                        <div className="accordion-item">
                                                                                            <h2 className="accordion-header" id="headingFour">
                                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                                                    <img src={INCOME_DETAIL} alt="icon" className='img-fluid form_icons' />
                                                                                                    Income and Expense Details
                                                                                                </button>
                                                                                            </h2>
                                                                                            <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                                                <div className="accordion-body">
                                                                                                    <div className='row'>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='prevTotalIncome'>
                                                                                                                <label className="form-label">Previous Total Income (Rs.) <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="prevTotalIncome"
                                                                                                                    value={form4.prevTotalIncome}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("prevTotalIncome") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("prevTotalIncome"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("prevTotalIncome"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='currTotalIncome'>
                                                                                                                <label className="form-label">Current Total Income (Rs.) <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="currTotalIncome"
                                                                                                                    value={form4.currTotalIncome}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("currTotalIncome") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("currTotalIncome"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("currTotalIncome"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">If the current total income is different from previous total income, then what’s the reason for that change?</label>
                                                                                                                <textarea
                                                                                                                    cols="30"
                                                                                                                    rows="5"
                                                                                                                    className='form-control textarea'
                                                                                                                    placeholder='Type here...'
                                                                                                                    name="reasonForIncomeChange2"
                                                                                                                    value={form4.reasonForIncomeChange2}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                ></textarea>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <ImageUploadInputrenewal
                                                                                                                name="salarySlip"
                                                                                                                text="Latest Salary Slip (all family members who are earning)"
                                                                                                                url={RenewalUrl}
                                                                                                                itemkey="salarySlip"
                                                                                                                accept=".pdf"
                                                                                                                multiple={false}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <ImageUploadInputrenewal
                                                                                                                name="bankSTMT"
                                                                                                                text="Last 3 Months Bank Statements"
                                                                                                                url={RenewalUrl}
                                                                                                                itemkey="bankStmt"
                                                                                                                accept=".pdf"
                                                                                                                multiple={false}
                                                                                                            />
                                                                                                        </div>

                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='prevTotalExpense'>
                                                                                                                <label className="form-label">Previous Total Expenses(Rs.) <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="prevTotalExpense"
                                                                                                                    value={form4.prevTotalExpense}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("prevTotalExpense") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("prevTotalExpense"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("prevTotalExpense"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group' id='currTotalExpense'>
                                                                                                                <label className="form-label">Current Total Expenses (Rs.) <span className='required'>*</span></label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="currTotalExpense"
                                                                                                                    value={form4.currTotalExpense}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                    required
                                                                                                                    className={`form-control ${(formError.length == 0 ? "" : IsError("currTotalExpense") ? "success" : "error")}`}
                                                                                                                />
                                                                                                                {
                                                                                                                    (ShowError("currTotalExpense"))[0] &&
                                                                                                                    <p className='error_label'>{(ShowError("currTotalExpense"))[1]}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Any new major expense added? If yes, then what are the source and the amount being incurred?</label>
                                                                                                                <textarea
                                                                                                                    cols="30"
                                                                                                                    rows="5"
                                                                                                                    className='form-control textarea'
                                                                                                                    placeholder='Type here...'
                                                                                                                    name="newMajorExpense"
                                                                                                                    value={form4.newMajorExpense}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                ></textarea>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Previous value of total assets of the family (Rs.)</label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    className='form-control'
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="prevTotalAssets"
                                                                                                                    value={form4.prevTotalAssets}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                    required
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">Current value of total assets of the family (Rs.)</label>
                                                                                                                <input
                                                                                                                    type="number"
                                                                                                                    className='form-control'
                                                                                                                    placeholder='Enter here'
                                                                                                                    name="currTotalAssets"
                                                                                                                    value={form4.currTotalAssets}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                    required
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-12 vertically_center">
                                                                                                            <div className='form-group'>
                                                                                                                <label className="form-label">If the current value of total assets is different from the previous value, then please state the reason for that change.</label>
                                                                                                                <textarea
                                                                                                                    cols="30"
                                                                                                                    rows="5"
                                                                                                                    className='form-control textarea'
                                                                                                                    placeholder='Type here...'
                                                                                                                    name="reasonForAssetsChange"
                                                                                                                    value={form4.reasonForAssetsChange}
                                                                                                                    onChange={handleChangeForm4}
                                                                                                                ></textarea>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='d-flex jc-between mt-4'>
                                                                                        <button className='btn prev_btn' onClick={() => navigate(`#${"renewal_details"}`)}>Previous</button>
                                                                                        <button className='btn next_btn' onClick={() => Submission4()}>Save and Next</button>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <AccessDenied />
                                                                        }
                                                                    </>
                                                                }

                                                                {
                                                                    showForm == "preview" && (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done" && renewalTabData.form4 == "done") ?
                                                                        <>
                                                                            <Preview />
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }

                                                                {/* 4 */}
                                                                {
                                                                    showForm == "final" && (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done" && renewalTabData.form4 == "done") ?
                                                                        <>
                                                                            {
                                                                                (user.role == "student" && user.pInfo && user.qInfo) ?
                                                                                    <div className="">
                                                                                        <div className='mb-4'>

                                                                                            <div className="form-check">
                                                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                                                                                <label className="form-check-label" for="flexCheckDefault">
                                                                                                    I hereby declare that all the information provided in this form is true, accurate, and complete to the best of my knowledge and belief.
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="form-check mb-3">
                                                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                                                    I undertake that I have read complete translation of the Holy Quran.
                                                                                                    {/* I understand that any false or misleading information provided may result in the rejection of my application. */}
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="form-check mb-3">
                                                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                                                    I undertake that I have paid all the monthly repayments regularly till current month.
                                                                                                    {/* I understand that any false or misleading information provided may result in the rejection of my application. */}
                                                                                                </label>
                                                                                            </div>

                                                                                            <div className="form-check">
                                                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={ugrad} onChange={(e) => handleCheckBoxUgrad(e)} />

                                                                                                <label className="form-check-label" for="flexCheckDefault">
                                                                                                    I Agree
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='d-flex jc-between'>
                                                                                            <button className='btn prev_btn' onClick={() => navigate(`#${"preview"}`)}>Previous</button>
                                                                                            <button onClick={() => Submission5()} className='btn submit_btn'>Submit</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <AccessDenied />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <></>

                                                                }
                                                                {/* 3 */}
                                                                {
                                                                    showForm == "step_3" &&
                                                                    <>
                                                                        {/* {
                                                (user.role == "student" && user.pInfo && user.qInfo) ?
                                                    <section className='section_main'>
                                                        <div className="card">
                                                            <div className='row'>
                                                                <div className="col-md-6 col-sm-6 col-12">
                                                                    <div className='form-group border_input'>
                                                                        <label className="form-label">Previous Total Income (Rs.)</label>
                                                                        <input
                                                                            type="number"
                                                                            className='form-control'
                                                                            placeholder='Enter here'
                                                                            name="prevTotalIncome"
                                                                            value={data3.prevTotalIncome}
                                                                            onChange={handleChange2}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6 col-12">
                                                                    <div className='form-group border_input'>
                                                                        <label className="form-label">Current Total Income (Rs.)</label>
                                                                        <input
                                                                            type="number"
                                                                            className='form-control'
                                                                            placeholder='Enter here'
                                                                            name="currTotalIncome"
                                                                            value={data3.currTotalIncome}
                                                                            onChange={handleChange2}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className='form-group mb-4'>
                                                                        <label className="form-label">If the current total income is different from previous total income, then what’s the reason for that change?</label>
                                                                        <textarea
                                                                            cols="30"
                                                                            rows="1"
                                                                            className='form-control textarea'
                                                                            placeholder='Type here...'
                                                                            name="reasonForIncomeChange"
                                                                            value={data3.reasonForIncomeChange}
                                                                            onChange={handleChange2}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 col-sm-6 col-12">
                                                                    <div className='form-group border_input'>
                                                                        <label className="form-label">Previous Total Expenses(Rs.)</label>
                                                                        <input
                                                                            type="number"
                                                                            className='form-control'
                                                                            placeholder='Enter here'
                                                                            name="prevTotalExpense"
                                                                            value={data3.prevTotalExpense}
                                                                            onChange={handleChange2}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 col-sm-6 col-12">
                                                                    <div className='form-group border_input'>
                                                                        <label className="form-label">Current Total Expenses (Rs.)</label>
                                                                        <input
                                                                            type="number"
                                                                            className='form-control'
                                                                            placeholder='Enter here'
                                                                            name="currTotalExpense"
                                                                            value={data3.currTotalExpense}
                                                                            onChange={handleChange2}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className='form-group mb-4'>
                                                                        <label className="form-label">Any new major expense added? If yes, then what are the source and the amount being incurred?</label>
                                                                        <textarea
                                                                            cols="30"
                                                                            rows="1"
                                                                            className='form-control textarea'
                                                                            placeholder='Type here...'
                                                                            name="newMajorExpense"
                                                                            value={data3.newMajorExpense}
                                                                            onChange={handleChange2}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 col-sm-6 col-12">
                                                                    <div className='form-group border_input'>
                                                                        <label className="form-label">Previous value of total assets of the family (Rs.)</label>
                                                                        <input
                                                                            type="number"
                                                                            className='form-control'
                                                                            placeholder='Enter here'
                                                                            name="prevTotalAssets"
                                                                            value={data3.prevTotalAssets}
                                                                            onChange={handleChange2}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 col-sm-6 col-12">
                                                                    <div className='form-group border_input'>
                                                                        <label className="form-label">Current value of total assets of the family (Rs.)</label>
                                                                        <input
                                                                            type="number"
                                                                            className='form-control'
                                                                            placeholder='Enter here'
                                                                            name="currTotalAssets"
                                                                            value={data3.currTotalAssets}
                                                                            onChange={handleChange2}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className='form-group'>
                                                                        <label className="form-label">If the current value of total assets is different from the previous value, then please state the reason for that change.</label>
                                                                        <textarea
                                                                            cols="30"
                                                                            rows="1"
                                                                            className='form-control textarea'
                                                                            placeholder='Type here...'
                                                                            name="reasonForAssetsChange"
                                                                            value={data3.reasonForAssetsChange}
                                                                            onChange={handleChange2}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>

                                                                <h3>Case extension</h3>

                                                                <div className="row case_history">
                                                                    <div className="col-md-12">
                                                                        <div className='form-group'>
                                                                            <label className="form-label">Amount Requested:</label>
                                                                            <input
                                                                                type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter here'
                                                                                name="amtRequested"
                                                                                value={data3.amtRequested}
                                                                                onChange={handleChange2}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className='form-group'>
                                                                            <label className="form-label">Reviewed Monthly Repayment Amount:</label>
                                                                            <input
                                                                                type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter here'
                                                                                name="revMonthlyRepay"
                                                                                value={data3.revMonthlyRepay}
                                                                                onChange={handleChange2}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <div className='form-group'>
                                                                            <label className="form-label">Source of Monthly Repayment:</label>
                                                                            <textarea
                                                                                cols="30"
                                                                                rows="3"
                                                                                className='form-control'
                                                                                placeholder='Type here...'
                                                                                name="srcMonthlyRepay"
                                                                                value={data3.srcMonthlyRepay}
                                                                                onChange={handleChange2}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                            <div className='d-flex jc-between'>
                                                                <button className='btn prev_btn' onClick={() => navigate(`#${"step_2"}`)}>Previous</button>
                                                                <button className='btn submit_btn' onClick={() => Next3()}>Submit</button>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    :
                                                    <Page403 />
                                            } */}
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>

                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <ReopenFrom />
                                </>
                        }
                    </>
                    :
                    <div className="d-flex">
                        <div className="sidebar_div">
                            <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                        </div>

                        <div className="page_div">
                            <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                            <section className='section_main'>
                                <AccessDenied />
                            </section>
                        </div>
                    </div>
            }

        </>
    )
}



function Page403() {
    const { user } = useAuth();

    return <div className='update_msg'>
        <div>
            <h1>403</h1>
            <h2>Access Forbidden!</h2>
            <p>You need to complete your Profile and Qualification</p>
            {
                user.pInfo == false ?
                    <Link to="/profile">
                        <button className='btn btn-complete'>Complete Profile</button>
                    </Link>
                    :
                    <Link to="/profile#qualification">
                        <button className='btn btn-complete'>Complete Qualification</button>
                    </Link>
            }
        </div>
    </div>
}

export function Uploaded_Doc_Checked(props) {
    const { renewalTabData } = useAuth();

    const [isDocUploaded, setIsDocUploaded] = useState([]);

    return (
        <>
            <div className='d-flex jc-between ai-center mb-1 Uploaded_Doc_Checked'>
                <label className='mb-0'>{props.lable}</label>
                <div>
                    {
                        renewalTabData[props.keyName] ?
                            <MdCheckCircle className='tick_icon' style={{ color: "var(--successColor)" }} />
                            :
                            <MdCancel className='tick_icon' style={{ color: "var(--dltBtnColor)" }} />
                    }
                </div>
            </div>
        </>
    )
}


export default RenewalForm