import { Student_Profile_Pic_Dashboard } from "./Profile/upload_profilePic";
import TooltipComponent from '../components/tooltip';
import Badges from "./Badges";
import { Link } from "react-router-dom";
import { LiaUniversitySolid } from "react-icons/lia";
import { PiGraduationCap, PiStudentFill } from "react-icons/pi";



export function UserCard(props) {

    console.log("User Data", props.data);

    return (
        <>
            <div className="card user_card h-100">
                <div className="profile_info">

                    <div className="text-center">
                        <div className="d-flex jc-center ai-center mb-2" style={{ minHeight: "70px" }}>
                            <Student_Profile_Pic_Dashboard src={props?.data?.profilepic} role={props?.data?.role} classes="student_profile_image lg" />
                        </div>
                        <h5 className="mb-2">{(props?.data?.fullName) || "NA"}</h5>
                    </div>

                    {/* <div className="d-flex ai-center mb-2">
                        <h6>Name :</h6>
                    </div> */}

                    <div className="d-flex mb-2 jc-center-md">
                        <LiaUniversitySolid className="std_info_icon" style={{fontSize: "1.5rem"}} />
                        <p className="text-capital">{props?.data?.university?.fullName || "NA"}</p>
                        {/* <p className="limit-text sm text-capital">{props?.data?.university?.fullName || "Sir Syed University of Engineering and Technology"}</p> */}
                        {/* <p className="text-capital">{"Institute Of Business Administration"}</p> */}
                    </div>

                    <div className="d-flex jc-center-md">
                        <PiGraduationCap className="std_info_icon" style={{fontSize: "1.5rem"}} />
                        <p className="text-capital">{props?.data?.program.name || "NA"}</p>
                    </div>

                </div>
            </div>
        </>
    )
}