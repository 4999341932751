import React from 'react'
import NavSidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useProSidebar } from 'react-pro-sidebar';

import { Link } from 'react-router-dom';
import { useAuth } from '../ContextAPI/Components/auth';

import { getAppData, GetLatestSubmittedApplication, getLogs, getrenewalForLogs } from '../ContextAPI/APIs/api';





function ApplicationTracking() {
    const { user } = useAuth();
    const [data, setData] = useState(null)
    const [app, setApp] = useState(null)
    const [ren, setRen] = useState(null)

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const statuses = [
        { id: 1, name: 'Pending', color: '#ffc107' },
        { id: 2, name: 'In Process', color: '#17a2b8' },
        { id: 3, name: 'Approved', color: '#28a745' },
        { id: 4, name: 'Interview', color: '#007bff' },
        { id: 5, name: 'Disbursed', color: '#dc3545' },
    ];


    const getApplicationData = async () => {
        try {
            const response = await GetLatestSubmittedApplication(user._id)
            if (response.success) {
                console.log("HELLO", response);
                setData(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate

        } else {
            return "---"
        }
    }

    const getApp = async () => {
        try {
            const response = await getAppData()
            console.log("APPLICATION", response);
            if (response.success && response.message !== null) {

                if ((response.message.status === "disburse" || response.message.status === "reject") && user.nextReview > Date.now()) {
                    setApp("hello")
                    getHistoryLogs("fresh", response.message._id)
                }
                if (response.message.status !== "disburse" && response.message.status !== "reject") {
                    setRen("hello")
                    getHistoryLogs("fresh", response.message._id)
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getRen = async () => {
        try {
            const response = await getrenewalForLogs()
            console.log("RENEWAL", response);
            if (response.success && response.message !== null) {
                console.log("GET", response);
                if ((response.message.RenewStatus === "disburse" || response.message.RenewStatus === "reject") && user.nextReview > Date.now()) {
                    setRen("hello")
                    getHistoryLogs("renewal", response.message._id)
                }

                if (response.message.RenewStatus !== "disburse" || response.message.RenewStatus !== "reject") {
                    setRen("hello")
                    getHistoryLogs("renewal", response.message._id)
                }

            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const [logs, setLogs] = useState([])
    const getHistoryLogs = async (t, id) => {
        try {
            const response = await getLogs({
                id: user._id,
                type: t,
                document: id
            })

            console.log("LOGS", response);
            if (response.success && response.message !== null) {
                setLogs(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        // if (user.nextReview > Date.now()) {
        getRen()
        getApp()
        // }
    }, [])


    function convertTimestampToDateTime(timestamp) {
        // Create a new Date object
        const date = new Date(timestamp);

        // Extract the date, hours, and minutes
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12 || 12;

        // Construct the formatted date string
        const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }


    let userInfo = 'blah1 blah2 blah3'
    var charArry = userInfo.split(' ');
    let ul = document.querySelector('#ul')



    return (
        <>
            <Helmet>
                <title>Application Tracking</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='application_tracking_section'>


                        {
                            app == null && ren == null ?
                                <>
                                    <section className="page-contain">

                                        <div>
                                            <h2 className='text-center'>Please first submit your application</h2>
                                        </div>

                                        <div className="row">
                                            {
                                                user.totalLoan > 0 ?
                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <Link to="/renewal-form" className="card data-card" style={{ textDecoration: "none" }}>
                                                            <h3>Renewal Form</h3>
                                                            <h4>For Loan Beneficiary Applicant</h4>
                                                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                                                            <span className="link-text">
                                                                Click Here
                                                                <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD" />
                                                                </svg>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className="col-md-12 col-sm-12 col-12">
                                                        <Link to="/application-form" className="card data-card" style={{ textDecoration: "none" }}>
                                                            <h3>Application Form</h3>
                                                            <h4>For New Applicant</h4>
                                                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                                                            <span className="link-text">
                                                                Click Here
                                                                <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD" />
                                                                </svg>
                                                            </span>
                                                        </Link>
                                                    </div>
                                            }
                                        </div>

                                    </section>
                                </>
                                :
                                <>
                                    {/* <a href="https://codepen.io/hunzaboy/pen/qBWRBXw" target="_blank">Checkout Version 2</a> */}
                                    <h2>Application Tracking </h2>

                                    {
                                        logs.length > 0 && logs.map((item, i) => (
                                            <div className="timeline">
                                                <div className={`timeline__event animated fadeInUp delay-3s timeline__event--type1 status__hover ${item?.status}`}>
                                                    <div className={`timeline__event__icon status ${item?.status}`}>
                                                        {/* <FaMoneyBill /> */}
                                                        <div className={"timeline__event__date status"}>
                                                            {timestampToDate(item?.date)}
                                                        </div>
                                                    </div>
                                                    <div className={`timeline__event__content ${item?.status}`}>
                                                        <div className={`timeline__event__title title ${item?.status}`}>
                                                            {/* {item?.status} */}
                                                            {item?.status === "pending" ? "Processing" : item?.status === "interview" ? "interview scheduled" : item?.status}
                                                        </div>
                                                        <div className="timeline__event__description reason">
                                                            {/* <p>Your study loan application is in the pending zone right now. We're on it and will keep you posted on the progress</p> */}
                                                            {/* <p className='mb-0'>{item?.reason}</p> */}
                                                            <p>
                                                                {
                                                                    item?.status == "approved" ?
                                                                        <div dangerouslySetInnerHTML={{ __html: item?.reason }} />
                                                                        :
                                                                        item?.reason
                                                                }
                                                            </p>
                                                            {
                                                                item?.status == "interview" && (
                                                                    <p className='mb-0'>Your interview is scheduled on : <span style={{ fontWeight: "600" }}>{`${convertTimestampToDateTime(item?.interview)}`}</span></p>
                                                                )
                                                            }
                                                            {
                                                                item?.status == "approved" && (
                                                                    <p className='mb-0'>{`Your renewal form will be open on : ${convertTimestampToDateTime(item?.nextReview)}`}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </>
                        }
                    </section>

                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', margin: 0, backgroundColor: '#f0f0f0' }}>
                        {
                            ren?.InprocessDate && (

                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                        borderRadius: '8px',
                                        margin: '10px',
                                        width: '250px',
                                        backgroundColor: '#17a2b8',
                                        border: data.status == "inprocess" ? '10px solid #000000' : "",
                                    }}
                                >
                                    <h2>In Process</h2>
                                    <p>{timestampToDate(app?.InprocessDate)}</p>
                                </div>
                            )
                        }
                        {
                            data?.approvalDate && (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                        borderRadius: '8px',
                                        margin: '10px',
                                        width: '250px',
                                        backgroundColor: '#28a745',
                                        border: data.status == "approved" ? '10px solid #000000' : "",
                                    }}
                                >
                                    <h2>Approved </h2>
                                    <p>{timestampToDate(data?.approvalDate)}</p>
                                </div>

                            )
                        }

                        {
                            data?.interviewDate && (

                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                        borderRadius: '8px',
                                        margin: '10px',
                                        width: '250px',
                                        backgroundColor: '#007bff',
                                        border: data.status == "interview" ? '10px solid #000000' : "",
                                    }}
                                >
                                    <h2>Interview </h2>
                                    <p>{timestampToDate(data?.interviewDate)}</p>
                                </div>
                            )
                        }

                        {
                            data?.DisburseDate && (

                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                        borderRadius: '8px',
                                        margin: '10px',
                                        width: '250px',
                                        backgroundColor: '#dc3545',
                                        border: data.status == "disburse" ? '10px solid #000000' : "",
                                    }}
                                >
                                    <h2>Disbursed</h2>
                                    <p>{timestampToDate(data?.DisburseDate)}</p>
                                </div>
                            )
                        }

                        {
                            data?.RejectionDate && (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                        borderRadius: '8px',
                                        margin: '10px',
                                        width: '250px',
                                        backgroundColor: '#dd3775',
                                        border: data.status == "reject" ? '10px solid #000000' : "",
                                    }}
                                >
                                    <h2>Rejected</h2>
                                    <p>{timestampToDate(data?.RejectionDate)}</p>
                                </div>
                            )
                        }

                    </div> */}


                </div>
            </div>
        </>
    )
}

export default ApplicationTracking