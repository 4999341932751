import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import { useAuth } from '../ContextAPI/Components/auth';
import { usePathname } from '../ContextAPI/Components/PathnameContext';

import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, sidebarClasses } from 'react-pro-sidebar';

import { BsBook } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { HiBars3, HiOutlineNewspaper } from "react-icons/hi2";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { FaWpforms } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { LuLayoutDashboard } from "react-icons/lu";
import { LiaUniversitySolid } from "react-icons/lia";
import { MdAutorenew } from 'react-icons/md';
import { PiSignInBold, PiStudent, PiUserCirclePlus } from "react-icons/pi";

import logo from "../assets/logo.png"
import logo2 from "../assets/logo-icon.png"
import DarkMode from './Darkmode';


function NavSidebar({ collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl }) {

    const { user } = useAuth();

    const { pathname: currentPath } = useLocation();

    const { style } = usePathname();
    const navigate = useNavigate()

    // const [open, setOpen] = useState (undefined);

    // const [open, setOpen] = React.useState<'charts' | 'maps' | 'theme' | undefined>();

    // const handleOpenSubMenu = ({key: 'charts' || 'maps' || 'theme'}) => {
    //     if (open === key) {
    //         setOpen(undefined);
    //     } else {
    //         setOpen(key);
    //     }
    // };

    const isSmallMobile = useMediaQuery({ query: '(max-width: 501px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    function newfunction() {
        const sidebar = window.document.getElementById("sideBar")
        console.log("siderbar", sidebar);
        sidebar.style.height = "100%"
        sidebar.style.zIndex = "1"
        if (collapsed) {
            sidebar.style.position = "fixed"
            sidebar.style.width = "50%"
        }
        else {
            sidebar.style.position = "unset"
        }
    }


    return (
        <>
            <div className='sidebar_main'>
                <Sidebar
                    // defaultCollapsed={isTablet ? true : false}
                    defaultCollapsed={false}
                    id="sideBar"
                    className='navsidebar'
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            padding: "10px",
                            width: "100%"
                        },
                    }}
                >
                    <Menu
                        menuItemStyles={{
                            button: ({ level, active, disabled }) => {
                                // only apply styles on first level elements of the tree
                                if (level == 0)
                                    return {
                                        color: disabled ? '#f5d9ff' : '#d359ff',
                                        backgroundColor: active ? '#eecef9' : undefined,
                                        paddingRight: "5px",
                                        paddingLeft: "5px"
                                    };
                            },
                        }}
                    >

                        <MenuItem className='py-3 sidebar_toggle'>
                            {/* <div className='d-flex jc-between ai-center'> */}
                            {
                                collapsed ?
                                    <Link to="/" className="activeLink1 hash-tag text-truncate">
                                        <img src={logo2} alt="logo" className='img-fluid' style={{ width: "40px" }} />
                                    </Link>
                                    :
                                    <>
                                        <Link to={`${((user?.role == "admin" || user?.role == "operations") && "/admin-dashboard") || (user.role == "student" && "/dashboard") || (user.role == "university" && "/university-dashboard")}`} className="" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                            <img src={logo} alt="logo" className='img-fluid' style={{ width: "210px" }} />
                                        </Link>
                                    </>
                            }
                            {/* <main className="oo78uijk">
                                    {
                                        isSmallMobile ?
                                            <button className="btn btnFocus" onClick={() => { collapseSidebar(); console.log("collapsed", collapsed); newfunction() }}><HiBars3 className="mr-1" style={{ color: "#b1bad3", fontSize: "25px" }} /></button>
                                            :
                                            <button className="btn btnFocus" onClick={() => { collapseSidebar(); console.log("collapsed", collapsed) }}><HiBars3 className="mr-1" style={{ color: "#b1bad3", fontSize: "25px" }} /></button>
                                    }
                                </main> */}
                            {/* </div> */}
                        </MenuItem>

                        {
                            user.role == "anonymous" &&
                            <>
                                <h6>GLOBAL</h6>
                                <Link to="/profile" className='link'>
                                    <MenuItem className={`${style == "profile" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<CgProfile className={`${style == "profile" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/profile" className={`mx-1 my-1 ${style == "profile" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Profile </Link>
                                    </MenuItem>
                                </Link>
                                <Link to="/sign-in" className='link'>
                                    <MenuItem className={`${style == "sign_in" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<PiSignInBold className={`${style == "sign_in" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/sign-in" className={`mx-1 my-1 ${style == "sign_in" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Sign In </Link>
                                    </MenuItem>
                                </Link>
                            </>
                        }
                        {
                            (user.role == "admin" || user?.role == "viewer" || user.role == "operations" || user.role == "finance" || user.role == "repayment") &&
                            <>
                                <h6>ADMIN</h6>
                                <Link to="/admin-dashboard" className='link'>
                                    <MenuItem className={`${style == "admin_dashboard" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''} mt-2 link_one `} icon={<LuLayoutDashboard className={`${style == "admin_dashboard" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/admin-dashboard" className={`mx-1 my-1 ${style == "admin_dashboard" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Dashboard </Link>
                                    </MenuItem>
                                </Link>
                                {
                                    (user.role == "admin" || user.role == "operations") && (
                                        <Link to="/create-user" className='link'>
                                            <MenuItem className={`${style == "create_user" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<PiUserCirclePlus className={`${style == "create_user" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                                <Link to="/create-user" className={`mx-1 my-1 ${style == "create_user" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Create User </Link>
                                            </MenuItem>
                                        </Link>
                                    )
                                }

                                {/* A L L  U S E R S */}
                                <SubMenu label="All Users" defaultOpen={currentPath.startsWith("/all-users")} icon={<FiUsers className={`${style == "all_users" || style == "universities" || style == "admin" ? "active_sidebar_icon" : "sidebar_icon"}`} />} className={`mb-2 ${style == "all_users" || style == "universities" || style == "admin" ? "active" : "unactive"}`}>
                                    <Link to="/all-users/students-list" className='link'>
                                        <MenuItem className={`${style == "all_users" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<PiStudent className={`${style == "all_users" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/all-users/students-list" className={`mx-1 my-1 ${style == "all_users" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Students </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/all-users/universities-list" className='link'>
                                        <MenuItem className={`${style == "universities" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<LiaUniversitySolid className={`${style == "universities" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/all-users/universities-list" className={`mx-1 my-1 ${style == "universities" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Universities </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/all-users/others-list" className='link'>
                                        <MenuItem className={`${style == "admin" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<FiUsers className={`${style == "admin" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/all-users/others-list" className={`mx-1 my-1 ${style == "admin" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Others </Link>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>

                                <Link to="/programs" className='link'>
                                    <MenuItem className={`${style == "programs" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<BsBook className={`${style == "programs" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/programs" className={`mx-1 my-1 ${style == "programs" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Programs </Link>
                                    </MenuItem>
                                </Link>

                                {/* C A S E S */}
                                <SubMenu label="Cases" defaultOpen={currentPath.startsWith("/cases")} icon={<FiUsers className={`${style == "freshCases" || style == "renewalCases" ? "active_sidebar_icon" : "sidebar_icon"}`} />} className={`mb-2 ${style == "freshCases" || style == "renewalCases" ? "active" : "unactive"}`}>
                                    <Link to="/cases/fresh-cases" className='link'>
                                        <MenuItem className={`${style == "freshCases" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<FiUsers className={`${style == "freshCases" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/cases/fresh-cases" className={`mx-1 my-1 ${style == "freshCases" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Fresh Cases </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/cases/renewal-cases" className='link'>
                                        <MenuItem className={`${style == "renewalCases" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<FiUsers className={`${style == "renewalCases" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/cases/renewal-cases" className={`mx-1 my-1 ${style == "renewalCases" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Renewal Cases </Link>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>

                                {/* A P P R O V A L  S H E E T S */}
                                <SubMenu label="Approval Sheets" defaultOpen={currentPath.startsWith("/approval-sheets")} icon={<HiOutlineNewspaper className={`${style == "approvalSheet" || style == "approvalSheetRenewal" ? "active_sidebar_icon" : "sidebar_icon"}`} />} className={`mb-2 ${style == "approval_sheets" || style == "approvalSheet" || style == "approvalSheetRenewal" ? "active" : "unactive"}`}>
                                    <Link to="/approval-sheets/fresh" className='link'>
                                        <MenuItem className={`${style == "approvalSheet" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineNewspaper className={`${style == "approvalSheet" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/approval-sheets/fresh" className={`mx-1 my-1 ${style == "approvalSheet" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Fresh Sheet </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/approval-sheets/renewal" className='link'>
                                        <MenuItem className={`${style == "approvalSheetRenewal" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineNewspaper className={`${style == "approvalSheetRenewal" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/approval-sheets/renewal" className={`mx-1 my-1 ${style == "approvalSheetRenewal" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Renewal Sheet </Link>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>


                                <Link to="/create-repayments" className='link'>
                                    <MenuItem className={`${style == "create-repayments" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<FiUsers className={`${style == "create-repayments" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/create-repayments" className={`mx-1 my-1 ${style == "create-repayments" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Create Repayment</Link>
                                    </MenuItem>
                                </Link>
                                <Link to="/tranch" className='link'>
                                    <MenuItem className={`${style == "tranch" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<FiUsers className={`${style == "tranch" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/tranch" className={`mx-1 my-1 ${style == "tranch" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Disbursements </Link>
                                    </MenuItem>
                                </Link>
                                <Link to="/reporting" className='link'>
                                    <MenuItem className={`${style == "reporting2" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "reporting2" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/reporting" className={`mx-1 my-1 ${style == "reporting2" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Reporting </Link>
                                    </MenuItem>
                                </Link>


                                {/* R E P O R T I N G */}
                                {/* <SubMenu
                                    label="Reporting"
                                    defaultOpen={currentPath.startsWith("/reporting")}
                                    icon={<HiOutlineDocumentReport className={`${(style == "disb_analysis_std" || style == "disb_analysis_uni" || style == "DBvsREPAY" || style == "repay_analysis" || style == "monthly_analysis" || style == "interview_analysis" || style == "aging_analysis") ? "active_sidebar_icon" : "sidebar_icon"}`} />}
                                    className={`mb-2 ${(style == "disb_analysis_std" || style == "disb_analysis_uni" || style == "DBvsREPAY" || style == "repay_analysis" || style == "monthly_analysis" || style == "interview_analysis" || style == "aging_analysis") ? "active" : "unactive"}`}
                                >
                                    <Link to="/reporting/disbursementAnalysis-Student" className='link'>
                                        <MenuItem className={`${style == "disb_analysis_std" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "disb_analysis_std" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/reporting/disbursementAnalysis-Student" className={`mx-1 my-1 ${style == "disb_analysis_std" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Disbursement Analysis Student </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/reporting/disbursementAnalysis-University" className='link'>
                                        <MenuItem className={`${style == "disb_analysis_uni" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "disb_analysis_uni" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/reporting/disbursementAnalysis-University" className={`mx-1 my-1 ${style == "disb_analysis_uni" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Disbursement Analysis University </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/reporting/disbursement-repayments-Analysis" className='link'>
                                        <MenuItem className={`${style == "DBvsREPAY" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "DBvsREPAY" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/reporting/disbursement-repayments-Analysis" className={`mx-1 my-1 ${style == "DBvsREPAY" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Disbursement and Repayment Analysis </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/reporting/repayments-Analysis" className='link'>
                                        <MenuItem className={`${style == "repay_analysis" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "repay_analysis" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/reporting/repayments-Analysis" className={`mx-1 my-1 ${style == "repay_analysis" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Repayment Analysis </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/reporting/monthly-analysis" className='link'>
                                        <MenuItem className={`${style == "monthly_analysis" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "monthly_analysis" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/reporting/monthly-analysis" className={`mx-1 my-1 ${style == "monthly_analysis" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> TAT Monthly Analysis </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/reporting/interview-analysis" className='link'>
                                        <MenuItem className={`${style == "interview_analysis" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "interview_analysis" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/reporting/interview-analysis" className={`mx-1 my-1 ${style == "interview_analysis" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> TAT Interview Analysis </Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/reporting/aging-analysis" className='link'>
                                        <MenuItem className={`${style == "aging_analysis" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineDocumentReport className={`${style == "aging_analysis" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                            <Link to="/reporting/aging-analysis" className={`mx-1 my-1 ${style == "aging_analysis" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Aging Analysis </Link>
                                        </MenuItem>
                                    </Link>
                                </SubMenu> */}

                            </>
                        }

                        {
                            (user.role == "student" || user.role == "anonymous") &&
                            <>
                                <h6>STUDENT</h6>

                                <Link to="/dashboard" className='link'>
                                    <MenuItem className={`${style == "repayment_history" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<LuLayoutDashboard className={`${style == "repayment_history" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/dashboard" className={`mx-1 my-1 ${style == "repayment_history" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Dashboard </Link>
                                    </MenuItem>
                                </Link>
                                {
                                    user.totalLoan <= 0 && (
                                        <Link to="/application-form" className='link'>
                                            <MenuItem className={`${style == "application_form" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<FaWpforms className={`${style == "application_form" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                                <Link to="/application-form" className={`mx-1 my-1 ${style == "application_form" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Application Form </Link>
                                            </MenuItem>
                                        </Link>
                                    )
                                }
                                {
                                    user.totalLoan > 0 && (
                                        <Link to="/renewal-form" className='link'>
                                            <MenuItem className={`${style == "renewal_form" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<MdAutorenew className={`${style == "renewal_form" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                                <Link to="/renewal-form" className={`mx-1 my-1 ${style == "renewal_form" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Renewal Form </Link>
                                            </MenuItem>
                                        </Link>
                                    )
                                }

                                <Link to="/application-tracking" className='link'>
                                    <MenuItem className={`${style == "application_tracking" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<BiMoneyWithdraw className={`${style == "application_tracking" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/application-tracking" className={`mx-1 my-1 ${style == "application_tracking" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Application Tracking </Link>
                                    </MenuItem>
                                </Link>
                                <Link to="/std-profile" className='link'>
                                    <MenuItem className={`${style == "std-profile" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<CgProfile className={`${style == "std-profile" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/std-profile" className={`mx-1 my-1 ${style == "std-profile" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Profile </Link>
                                    </MenuItem>
                                </Link>

                            </>
                        }

                        {
                            (user.role == "university" || user.role == "anonymous") &&
                            <>
                                <h6>UNIVERSITY</h6>
                                <Link to="/university-dashboard" className='link'>
                                    <MenuItem className={`${style == "university_dashboard" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<LuLayoutDashboard className={`${style == "university_dashboard" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/university-dashboard" className={`mx-1 my-1 ${style == "university_dashboard" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Dashboard </Link>
                                    </MenuItem>
                                </Link>
                                <Link to="/fresh-cases-report" className='link'>
                                    <MenuItem className={`${style == "fresh_cases_report" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<PiSignInBold className={`${style == "fresh_cases_report" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/fresh-cases-report" className={`mx-1 my-1 ${style == "fresh_cases_report" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Fresh Cases Report </Link>
                                    </MenuItem>
                                </Link>
                                <Link to="/renewal-cases-report" className='link'>
                                    <MenuItem className={`${style == "renewal_cases_report" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<PiSignInBold className={`${style == "renewal_cases_report" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                        <Link to="/renewal-cases-report" className={`mx-1 my-1 ${style == "renewal_cases_report" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Renewal Cases Report </Link>
                                    </MenuItem>
                                </Link>
                            </>
                        }

                    </Menu>

                    {/* <div className={`mt-5 d-flex jc-center ${collapsed == true ? 'd-none' : 'd-block'} ${collapsed == true ? 'd-flex jc-center' : ''} theme_switcher`}>
                        <DarkMode />
                    </div> */}

                </Sidebar>
            </div>
        </>
    )
}

export default NavSidebar