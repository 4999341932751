// Create our number formatter.
// const formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'PKR',

//     // These options are needed to round to whole numbers if that's what you want.
//     //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//     //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
// });

// console.log(formatter.format(2500)); /* $2,500.00 */


function formatter(number) {
    try {

        const numberString = number?.toString();
        if (numberString == "" || numberString == '' || number == null) {
            return "Rs. 0"
        }
        else {
            // Split the number into integer and decimal parts
            const parts = numberString?.split('.');
            let integerPart = parts[0];
            const decimalPart = parts?.length > 1 ? '.' + parts[1] : '';

            // Add commas to the integer part
            integerPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Concatenate integer and decimal parts
            const formattedNumber = `Rs. ${integerPart} ${decimalPart}`;
            return formattedNumber;
        }

    }
    catch (e) {
        console.log(e);
    }
    // Convert the number to a string
}



export default formatter;