import React, { useState, useEffect } from 'react'
import Select from '../Form/Select_New';
import TooltipComponent from '../../components/tooltip';
import ImageUploadInput from '../Modals/ImageUploadInput';
import { FaPlus, FaQuestionCircle } from 'react-icons/fa';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../ContextAPI/Components/toast'
import { User_Profile_Pic } from '../Profile/upload_profilePic';
import Step_7 from '../ApplicationForm/Step_7';
import Step_8 from '../ApplicationForm/Step_8';

import {
    academicCertficatesUrl, admissionLetterUrl, BillUrl, childrenBformUrl, CNICUrl, DeathCertificateUrl, DomicileUrl,
    FamilyUrl, IncomeCertificateUrl, passimageUrl, RenewalUrl, RentAgreementUrl, resumeUrl, vouchersUrl
} from '../../helpers/data';

import { getForm1, getForm2, getForm3, getUNI, getUNIProfile, GetUnisProfileForStudent, SubmissionForm1, SubmissionForm2, SubmissionForm3,getAppDocuments } from '../../ContextAPI/APIs/api';

import Demo from "./Demographics_InformationViewOnly"
import Acade from "./Academic_BackgroundViewOnly"
import Finance from "./Financial_InformationViewOnly"
import Additional from "./Additional_InformationViewOnly"
import Sop from "./SOPViewOnly"
import { useParams } from 'react-router-dom';
function Preview() {

    const {id} = useParams();

    const [docData, setDocData] = useState({})

    const getDocument = async () => {
        try {
            const response = await getAppDocuments({ id: id })
            console.log("RESPONSE23", response);
            if (response.success) {
                setDocData(response?.message)
                console.log("AAA", response?.message);
            }
            else
            {
                setDocData({})
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getDocument()
    }, [])



    return (
        <>
            <div className="">
                <Demo docs={docData}/>
                <Acade docs={docData}/>
                <Finance docs={docData}/>
                <Additional docs={docData}/>
                <Sop docs={docData}/>
            </div>
        </>
    )
}




export default Preview
