import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { useProSidebar } from 'react-pro-sidebar';
import NavSidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import IconText from '../../components/Table/IconText';
import Table from '../../components/Table/Table';
import { CgProfile } from 'react-icons/cg';
import Pagination from '../../components/Pagination';

function Disbustment() {

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const DISBUSTMET_REPORT = [
        {
            name: "Ahmed",
        },
        {
            name: "Hammad",
        },
    ]

    const DISBUSTMET_DETAIL = [
        {
            year: 2022,
            semester: 5,
            amount: 20000
        },
        {
            year: 2023,
            semester: 8,
            amount: 30000
        }
    ]


    return (
        <>

            <Helmet>
                <title>Disbustment</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='disbustment_section'>
                        <div className='d-flex jc-between sort mb-4'>
                            <h2 className='mb-0'>Disbustment</h2>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="inputGroupSelect01">Sort By:</label>
                                </div>
                                <select class="custom-select form-select" id="inputGroupSelect01">
                                    <option selected value={""}>Choose...</option>
                                    <option value="student wise">Student Wise</option>
                                    <option value="year wise">Year Wise</option>
                                </select>
                            </div>

                        </div>

                        <div className="card">
                            <div className="disbusment_report">

                                <Table head={["Student Name"]}>

                                    {
                                        DISBUSTMET_REPORT.map((data, i) => {
                                            return <>
                                                <tr key={i}>
                                                    <td>
                                                        <IconText icon={<CgProfile className='icon' />} text={data.name} />
                                                    </td>
                                                </tr>
                                            </>
                                        })
                                    }

                                </Table>

                                <Pagination itemsPerPage={4} />

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Disbustment
