import { useEffect, useState } from "react";
import { GetUnisList, GetUnisProfileForStudent, UpdateQualification } from "../../ContextAPI/APIs/api";
import { useAuth } from "../../ContextAPI/Components/auth";
import Select from "../Form/Select";
import SelectNew from "../Form/Select_New";
import Input from "../Form/Input";
import { useToast } from "../../ContextAPI/Components/toast";
import { useConfirm } from '../../ContextAPI/Components/confirm';

import { useNavigate } from 'react-router-dom';




export function Student_Qualification() {
    const navigate = useNavigate()

    const { alert } = useToast()
    const { Ask } = useConfirm()

    const { user, GetLoginUser } = useAuth()
    console.log("USERR", user);
    const [uni, setUni] = useState([])
    const [campus, setCampus] = useState([])
    const [program, setProgram] = useState([])
    const [Qualification, setQualification] = useState({
        university: user?.university || "",
        campus: user?.campus || "",
        program: user?.program || "",
        rollNo: user?.rollNo || "",
        EnrollNo: user?.EnrollNo || "",
        type: user?.type || "",
        semester: user?.semester || "",
        year: user?.year || "",
    })

    const SELECTUNI = (e) => {
        console.log(e)
        try {
            setQualification({ ...Qualification, university: e, campus: "", program: "" })
            getUnisProfileForStudent(e._id)
        }
        catch (e) {
            console.log(e);
        }
    }

    const getUnisProfileForStudent = async (id) => {
        try {
            const response = await GetUnisProfileForStudent({ uniId: id })
            console.log("REPO", response);

            if (response.success) {
                setCampus(response?.message?.campuses)
                setProgram(response?.message?.programs)
            } else {
                setCampus([])
                setProgram([])
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const getAllUni = async () => {
        try {
            const response = await GetUnisList()

            if (response?.success) {
                console.log("UNISS", response.message);
                setUni(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const UpdateQual = async (e) => {
        e.preventDefault();

        const payload = {
            ...Qualification,
            university: Qualification.university?._id,
            type: Qualification.type,
            program: Qualification.program._id,
            campus: Qualification.campus._id,
            semester: Qualification.semester,
        }
        console.log("PAYLOAD", payload);
        if (await Ask("You can set qualification only once") == false) return

        try {
            const response = await UpdateQualification(payload)
            if (response.success) {
                alert(response.message, response.success)
                GetLoginUser()
                navigate("/dashboard")
            }
            else {

                alert(response.message, response.success)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getAllUni()
        if (typeof user?.university != "undefined" && user?.university != null && user?.university != "") {
            getUnisProfileForStudent(user?.university)
        }
    }, [])


    return <section>
        <div className="qualification">
            <h4>Education Details</h4>
            <form onSubmit={(e) => UpdateQual(e)}>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                        <div className='form-group'>
                            <SelectNew
                                label="Institute Name"
                                options={[{ _id: '', fullName: 'Select your Institute' }, ...uni]}
                                isOptionDisabled={(option) => (option._id == '' || option._id == null)}
                                value={Qualification?.university}
                                getOptionLabel={option =>
                                    `${option.fullName}`
                                }
                                getOptionValue={option => `${option._id}`}
                                onChange={(value) => SELECTUNI(value)}
                                loadingMessage={"Loading..."}
                                style={{ textTransform: "capitalize" }}
                                required
                                isDisabled={user.qInfo}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <SelectNew
                                label="Campus"
                                options={campus.length == 0 ? [{ _id: '', name: 'Campuses not available' }] : campus}
                                isOptionDisabled={(option) => (option._id == '' || option._id == null)}
                                value={Qualification?.campus}
                                getOptionLabel={option =>
                                    `${option.name}`
                                }
                                getOptionValue={option => `${option._id}`}
                                onChange={(value) => setQualification({ ...Qualification, campus: value })}
                                loadingMessage={"Loading..."}
                                style={{ textTransform: "capitalize" }}
                                required
                                isDisabled={user.qInfo}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <SelectNew
                                label="Program"
                                options={campus.length == 0 ? [{ _id: '', name: 'Programs not available' }] : program}
                                isOptionDisabled={(option) => (option._id == '' || option._id == null)}
                                value={Qualification?.program}
                                getOptionLabel={option =>
                                    `${option.name}`
                                }
                                getOptionValue={option => `${option._id}`}
                                onChange={(value) => setQualification({ ...Qualification, program: value })}
                                loadingMessage={"Loading..."}
                                style={{ textTransform: "capitalize" }}
                                required
                                isDisabled={user.qInfo}
                            />
                        </div>
                    </div>

                    {/* <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <Input label="Reg. No. / Roll. No." value={Qualification.rollNo} onChange={(e) => setQualification({ ...Qualification, rollNo: e.target.value })} inputType="text" required />
                        </div>
                    </div> */}

                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <Input label="University Enrollment No." placeholder="Enter University Enrollment No." value={Qualification.EnrollNo} onChange={(e) => setQualification({ ...Qualification, EnrollNo: e.target.value })} inputType="text" required />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <Select
                                label="Examination Structure"
                                value={Qualification.type}
                                options={["semester", "year"]}
                                onChange={(e) => setQualification({ ...Qualification, type: e.target.value })}
                                selectText="Select Examination Structure"
                                required style={{ textTransform: "capitalize" }}
                            />
                        </div>
                    </div>

                    {/* {
                        Qualification.type === "semester" && (
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className='form-group'>
                                    <Input label="Semester" value={Qualification.semester} onChange={(e) => setQualification({ ...Qualification, semester: e.target.value })} inputType="text" required style={{ textTransform: "capitalize" }} />
                                </div>
                            </div>
                        )
                    }
                    {
                        Qualification.type === "year" && (
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className='form-group'>
                                    <Input label="Year" value={Qualification.year} onChange={(e) => setQualification({ ...Qualification, year: e.target.value })} inputType="text" required style={{ textTransform: "capitalize" }} />
                                </div>
                            </div>
                        )
                    } */}

                </div>

                <div className='d-flex jc-end mt-3'>
                    <button className='btn save_btn'>Update</button>
                </div>

            </form>
        </div>
    </section>
}