import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useProSidebar } from 'react-pro-sidebar';
import CopyToClipboard from 'react-copy-to-clipboard';

import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { BiCopy, BiKey } from 'react-icons/bi';

import Input from '../../components/Form/Input';
import SelectNew from '../../components/Form/Select_New';

import Table from "../../components/Table/Table"
import Text from "../../components/Table/Text"
import EmailText from '../../components/Table/EmailText';
import { create_user, getPendingUser } from '../../ContextAPI/APIs/api';
import Tabs from '../../components/tabs';
import { UserTypes } from '../../helpers/data';
import { useToast } from "../../ContextAPI/Components/toast"
import { useConfirm } from '../../ContextAPI/Components/confirm';
import Pagination from '../../components/Pagination';
import { useAuth } from '../../ContextAPI/Components/auth';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

function CreateUser() {
    const { user } = useAuth();
    const { alert } = useToast();
    const { Ask } = useConfirm()

    const tabs = [
        {
            tabName: "Create",
            pathName: ""
        },
        {
            tabName: "Users",
            pathName: "#all_users"
        },
    ]

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const { hash } = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState("");
    const [role, setRole] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("");


    const [users, setUsers] = useState([])

    function generatePass() {
        let __password = '';
        let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';

        for (let i = 1; i <= 15; i++) {
            let char = Math.floor(Math.random() * str.length + 1);

            __password += str.charAt(char)
        }

        console.log(__password);
        setPassword(__password);
    }

    async function CreateUser(e) {
        e.preventDefault();

        if (await Ask() == false) return

        console.log(role);
        if (name == "" || email == "" || password == "" || role == "") return alert("Please Fill All Fields", false)
        const res = await create_user({
            name: name,
            email: email,
            password: password,
            role: role.value
        });

        if (res.success) {
            alert(res.message, res.success);
            GetPendingUsers(pageSkip)
            setRole(null)
            setName("")
            setEmail("")
            setPassword("");
            return
        }
        alert(res.message, res.success);
    }

    const [totalCount, setTotalCount] = useState(4)

    async function GetPendingUsers(skip) {
        const res = await getPendingUser(skip)
        if (res.success) {
            setUsers(res.message)
            setTotalCount(res.totalCount)
        }
    }

    useEffect(() => {
        GetPendingUsers(pageSkip)

    }, [])

    useEffect(() => {
        console.log((hash.split("#"))[1] || "create_user");
        setShow((hash.split("#"))[1] || "create_user")
    }, [hash])


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        GetPendingUsers(skip)
        // search(selectedUni, selectedProgram, selectedStatus, skip)
    }

    return (
        <>

            <Helmet>
                <title>Create User</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='create_user_section'>

                        <h2>Create User</h2>

                        <div className="card">

                            <Tabs tabLinks={tabs} show={show} setShow={setShow} />

                            {
                                show == "create_user" &&
                                <div className="create_user_card">
                                    <form onSubmit={(e) => CreateUser(e)}>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <div className='form-group'>
                                                    <SelectNew
                                                        label="User Type"
                                                        options={UserTypes}
                                                        // isOptionDisabled={(option) => (option.value == '' || option.value == 'recovery manager' || option.value == 'disbustment manager' || option.value == 'viewer')}
                                                        onChange={(e) => setRole(e)}
                                                        loadingMessage={"Loading..."}
                                                        required
                                                        value={role}
                                                        getOptionLabel={option =>
                                                            `${option.label}`
                                                        }
                                                        getOptionValue={option => `${option.value}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <div className='form-group'>
                                                    <Input label="Name" inputType="text" value={name} onChange={(e) => setName(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <div className='form-group position-relative'>
                                                    <Input label="Email" inputType="email" class="text-transform-none" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                    <div className='copy_email'>
                                                        <CopyToClipboard text={email} onCopy={() => alert("email copied", true)}>
                                                            <button className='btn btn-primary' type='button'><BiCopy /></button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <div className='form-group position-relative'>
                                                    <Input label="Password" inputType={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
                                                    <div className='auto_gen_pass'>
                                                        <button className='btn password_visibility' type='button' onClick={togglePasswordVisibility}>{showPassword ? <MdVisibilityOff /> : <MdVisibility />}</button>
                                                        <button className='btn btn-primary' type='button' onClick={(e) => generatePass(e)}><BiKey style={{ fontSize: "22px" }} /></button>
                                                        <CopyToClipboard text={password} onCopy={() => alert("password copied", true)}>
                                                            <button className='btn btn-primary' type='button'><BiCopy /></button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            (user.role == "admin" || user.role == "operations") && (
                                                <div className='d-flex jc-end mt-5'>
                                                    <button className='btn save_btn'>Create</button>
                                                </div>
                                            )
                                        }
                                    </form>
                                </div>
                            }

                            {
                                show == "all_users" &&
                                <div className="all_users_card">

                                    {
                                        users.length > 0 ?
                                            <>
                                                <Table head={["Name", "Role", "Email", "Passwords"]}>
                                                    {
                                                        users.map((data, i) => {
                                                            return <tr key={i}>
                                                                <td><Text text={data.fullName} /></td>
                                                                <td><Text text={data.role} /></td>
                                                                <td>
                                                                    <EmailText text={data.email} />
                                                                    <CopyToClipboard text={data.email} onCopy={() => alert("Email copied", true)}>
                                                                        <button className='btn copy_btn ml-0'><BiCopy className='purple_icons' /></button>
                                                                    </CopyToClipboard>
                                                                </td>
                                                                <td>
                                                                    <Text text={"x x x x x"} />
                                                                    <CopyToClipboard text={data.password} onCopy={() => alert("Password copied", true)}>
                                                                        <button className='btn copy_btn ml-0'><BiCopy className='purple_icons' /></button>
                                                                    </CopyToClipboard>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </Table>

                                                <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                            </>
                                            :
                                            <h5 className='not_found_msg'>No Records Found</h5>
                                    }
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </div>

        </>
    )
}


export default CreateUser