import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { Admin_Profile_Info } from "./admin_profile_info";
import { Update_Password } from "./update_password";
import { PageNotFound } from "../PageNotFound";
import Tabs from "../tabs";
import { useMediaQuery } from "react-responsive";


export function Admin_Index() {

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const tabs = [
        {
            tabName: "Personal Information",
            pathName: ""
        },
        {
            tabName: "Update Password",
            pathName: "#password"
        }
    ]

    const Mob_tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "Password",
            pathName: "#password"
        }
    ]

    const navigate = useNavigate();
    const { hash } = useLocation();
    const [show, setShow] = useState("");


    useEffect(() => {
        setShow(hash)

    }, [hash])


    if (show !== "" && show !== "#info" && show !== "#password") return <PageNotFound />

    return <>

        <h2>Profile</h2>

        <div className="card">

            <Tabs tabLinks={isMobile ? tabs : tabs} show={show} setShow={setShow} />

            {
                show == "" || show == "#info" ?
                    <Admin_Profile_Info />
                    :
                    <Update_Password />
            }
        </div>

    </>

} 