import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import SignatureCanvas from 'react-signature-canvas'
import UserModal from '../../components/Modals/Modal';
import Input from '../../components/Form/Input';
import SelectNew from '../../components/Form/Select_New';

// import SelectNew from "../Form/Select_New";
import { useEffect } from 'react';
import { ApprovalSheetFilter, createRepayManual, getAllUniversities, getProgram, getRepayApps, getRepayRenewal, GetUnisList } from '../../ContextAPI/APIs/api';
import { useToast } from '../../ContextAPI/Components/toast';
import { CSVLink } from "react-csv";
import Pagination from '../../components/Pagination';
import { useAuth } from '../../ContextAPI/Components/auth';
import { useNavigate } from 'react-router-dom';
import { cityList } from "../../helpers/data";
import formatter from '../../helpers/number_formatter';

const headers = [
    { label: "S.No", key: "SNO", },
    { label: "Name", key: "name", },
    { label: "University", key: "institute", },
    { label: "Program", key: "program", },
    { label: "Family Income Per month (Rs.)", key: "familyIncome", },
    { label: "Per Semester/Year Fee (Rs.)", key: "Semfee / Yearfee", },
    { label: "Outstanding fee (Rs.)", key: "feeOutstanding", },
    { label: "Amount of Fee Recommended", key: "recomendationRupees", },
    { label: "% of Fee Recommended", key: "recomendationPercent", },
    { label: "Repayment Amount", key: "recomendationRepayment", },
    // { label: "Available_Limit",  key: "Available Limit", },
];


const universities = [
    { value: "ku", label: "KU" },
    { value: "ssuet", label: "SSUET" },
    { value: "iba", label: "IBA" }
]


function ManualRepay() {

    const { user } = useAuth();
    const { alert } = useToast();
    const navigate = useNavigate();

    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setPayload({ ...payload, amount: "" })
    }

    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    const [Qualification, setQualification] = useState({
        university: "",
    })


    const [selectedUni, setSelectedUni] = useState([])
    const [selectedProgram, setSelectedProgram] = useState([])
    const [selectedCities, setSelectedCities] = useState([])


    const [payload, setPayload] = useState({
        amount: "",
        repaymentDate: "",
        modeOfPayment: "",
        chequeNo: "",
        slipNo: "",
        stanNo: "",
        accountNo: "",
        otherDetails:""
    });


    const [endDate, setEndDate] = useState('');

    const handleChange = (event, dateType) => {
        const value = event.target.value;

        // Convert the date to timestamp
        const timestamp = Date.parse(value);

        // Update the state based on the date type
        if (dateType === 'monthDate') {
            setEndDate(value);
        }
        else {
            setPayload({ ...payload, [dateType]: value })
        }
    };


    const [data, setData] = useState([])

    const [totalCount, setTotalCount] = useState(4)

    const fetchData = async (uni, prog, city, skip) => {
        try {

            const payload = {
                uni: uni,
                program: prog,
                city: city,
            }

            const response = await getRepayApps(payload, skip)
            console.log("FILTER---->", response);

            if (response.success && response.message.length > 0) {
                setData(response.message)
                setTotalCount(response.totalCount)
                // setShow(false)
            }
            else {
                // alert("No Data Found", false)
                setData([])
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const save = async (e,id) => {
        try {
            e.preventDefault();
            
            console.log("lloooggPPAAA", payload);


            if (payload.repaymentDate === null || payload.repaymentDate === "" || typeof payload.repaymentDate === undefined || !payload.repaymentDate) {
                // return res.status(400).json({ success: false, message: "please select mode of payment" })
                return alert("please select date", false)
            }

            if (payload.amount === null || payload.amount === "" || typeof payload.amount === undefined || !payload.amount) {
                // return res.status(400).json({ success: false, message: "please select date" })
                return alert("please enter amount", false)
            }

            if (payload.modeOfPayment === null || payload.modeOfPayment === "" || typeof payload.modeOfPayment === undefined || !payload.modeOfPayment) {
                // return res.status(400).json({ success: false, message: "please upload slip" })
                return alert(false, "please select mode of payment", false)
            }


            const p = {
                ...payload,
                id: id
            }
            console.log("llooogg", p);
            // return
            const response = await createRepayManual(p)
            console.log("jhjhjshdjsdsd", response);
            alert(response.message, response.success)
            if (response.success) {
                handleClose()
                fetchData(selectedUni, selectedProgram, selectedCities, pageSkip)
                // fetchData({}, pageSkip)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [prog, setProg] = useState([])
    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [uni, setUni] = useState([])
    const getAllunis = async () => {
        try {
            const response = await getAllUniversities()
            if (response.success) {
                console.log("AAAAA", response);
                setUni(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        fetchData([], [], [], pageSkip)
        getAllProg()
        getAllunis()

    }, [])


    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {
            const abc = selectedProgram.filter((uniId) => uniId !== id)

            setSelectedProgram(abc);

            // search(selectedUni, abc, selectedStatus, pageSkip)
            fetchData(selectedUni, abc, selectedCities, 0)
        } else {
            const upd = [...selectedProgram, id]
            setSelectedProgram(upd);

            fetchData(selectedUni, upd, selectedCities, 0)
            // search()
            // search(selectedUni, upd, selectedStatus, pageSkip)
        }
    };


    const handleUniChange = (id) => {
        const isSelected = selectedUni.includes(id);
        if (isSelected) {
            const abc = selectedUni.filter((uniId) => uniId !== id)

            setSelectedUni(abc);

            // search(abc, selectedProgram, selectedStatus, pageSkip)
            fetchData(abc, selectedProgram, selectedCities, 0)

        } else {
            const upd = [...selectedUni, id]

            setSelectedUni(upd);
            fetchData(upd, selectedProgram, selectedCities, 0)
            // search(upd, selectedProgram, selectedStatus, pageSkip)
        }
    };

    const handleCityChange = (id) => {
        const isSelected = selectedCities.includes(id);
        if (isSelected) {
            const abc = selectedCities.filter((uniId) => uniId !== id)

            setSelectedCities(abc)

            fetchData(selectedUni, selectedProgram, abc, 0)

            // search(abc, selectedProgram, selectedStatus, pageSkip)

        } else {
            const upd = [...selectedUni, id]

            setSelectedCities(upd)
            fetchData(selectedUni, selectedProgram, upd, 0)
            // search(upd, selectedProgram, selectedStatus, pageSkip)
        }
    };

    const clear = async () => {
        try {

            console.log("selectedUni", selectedUni);
            console.log("selectedProgram", selectedProgram);
            // console.log("selectedCity", selectedCity);
            setSelectedProgram([])
            setSelectedUni([])
            setSelectedCities([])
            fetchData([], [], [], 0)
            // const response = await freshCasesFilterAdmin({}, pageSkip)
            // // setApp(response.message)
            // if (response.success) {
            //     console.log("DDD", response.message);
            //     setApp(response?.message?.reverse())
            // }

        }
        catch (e) {
            console.log(e);
        }

    }

    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        fetchData(selectedUni, selectedProgram, selectedCities, skip)
    }

    return (
        <>
            <Helmet>
                <title>Create Repayments</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='create_repayment_section'>

                        <h2>Create Repayments</h2>

                        <div className="card">
                            <div className="filter d-flex ai-center jc-between mb-3">
                                <div className='d-flex ai-center'>
                                    <p>Filter By</p>

                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Universities
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>
                                                {
                                                    uni.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.fullName}</label>
                                                                {/* <input
                                                                span="*" className="form-check-input" onChange={() => handleUniChange()} type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    span="*"
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleUniChange(item._id)}
                                                                    checked={selectedUni.includes(item._id)}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Programs
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>

                                                {
                                                    prog.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.name}</label>
                                                                {/* <input
                                                                span="*" className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    span="*"
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleProgramChange(item._id)}
                                                                    checked={selectedProgram.includes(item._id)}
                                                                />

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Cities
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>

                                                {
                                                    cityList.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item}`}>{item}</label>
                                                                {/* <input
                                                                span="*" className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    span="*"
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item}`}
                                                                    onChange={() => handleCityChange(item)}
                                                                    checked={selectedCities.includes(item)}
                                                                />

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>

                                        </ul>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    {
                                        (selectedProgram.length > 0 || selectedUni.length > 0 || selectedCities.length > 0) && (
                                            <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                        )
                                    }
                                    {/* <button onClick={() => search()} className='btn clear_btn ml-2'>Search</button> */}
                                </div>
                            </div>
                            {
                                data.length > 0 ?
                                    <>
                                        <Table head={["S.NO", "Name", "University", "Program", "State", "City", "Outstanding Amount"]} >
                                            <>
                                                {data?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            {/* <td><Text text={i} /></td> */}
                                                            <td><Text text={i + 1} /></td>
                                                            <td><Text text={item?.studentId?.fullName} /></td>
                                                            <td><Text text={item?.university?.fullName} /></td>
                                                            <td><Text text={item?.program?.name} /></td>
                                                            <td><Text text={item?.state} /></td>
                                                            <td><Text text={item?.city} /></td>
                                                            <td className='text-center'><Text text={formatter(item?.totalLoan - item?.totalPaid)} /></td>
                                                            <td><button className='btn save_btn' onClick={() => { navigate(`/student-report/${item?.studentId?._id}#repayment_history`) }} >Repayment History</button></td>
                                                            {
                                                                (user?.role == "admin" || user?.role == "operations" || user?.role == "repayment") && (
                                                                    <td>
                                                                        <UserModal btnText={"Create"} heading="Create Repayment" id={`filter${i}`} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                                                            <form onSubmit={(e) => save(e,item?.studentId?._id)}>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <div className="form-group">
                                                                                            <Input
                                                                                                span="*"
                                                                                                inputType="date"
                                                                                                label="Date"
                                                                                                placeholder="Enter here"
                                                                                                // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                onChange={(event) => handleChange(event, 'repaymentDate')}
                                                                                                required={true}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <div className="form-group">
                                                                                            <Input
                                                                                                span="*"
                                                                                                inputType="number"
                                                                                                label="Amount"
                                                                                                placeholder="Enter here"
                                                                                                // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                onChange={(event) => handleChange(event, 'amount')}
                                                                                                required={true}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <div className="form-group">
                                                                                            <label className='form-label'>Mode of Payment <span className='required'>*</span></label>
                                                                                            <select
                                                                                                onChange={(event) => handleChange(event, 'modeOfPayment')}
                                                                                                className="form-select" required={true}>
                                                                                                <option selected disabled value={""}>Select Mode of Payment</option>
                                                                                                <option value="Cheque">Cheque</option>
                                                                                                <option value="Deposit">Deposit</option>
                                                                                                <option value="IBFT">IBFT</option>
                                                                                                <option value="Internal transfer from Meezan Bank">Internal transfer from Meezan Bank</option>
                                                                                                <option value="Easypaisa/Jazzcash">Easypaisa/Jazzcash</option>
                                                                                                <option value="other">other</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        (payload.modeOfPayment !== "") && (
                                                                                            <div className="col-12">
                                                                                                <div className="form-group">
                                                                                                    {
                                                                                                        payload.modeOfPayment == "Cheque" && (
                                                                                                            <Input
                                                                                                                span="*"
                                                                                                                inputType="text"
                                                                                                                label="Cheque No"
                                                                                                                placeholder="Enter here"
                                                                                                                // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                                onChange={(event) => handleChange(event, 'chequeNo')}
                                                                                                                required={true}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        payload.modeOfPayment == "Deposit" && (
                                                                                                            <Input
                                                                                                                span="*"
                                                                                                                inputType="text"
                                                                                                                label="slipNo"
                                                                                                                placeholder="Enter here"
                                                                                                                // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                                onChange={(event) => handleChange(event, 'slipNo')}
                                                                                                                required={true}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        payload.modeOfPayment == "Internal transfer from Meezan Bank" && (
                                                                                                            <Input
                                                                                                                span="*"
                                                                                                                inputType="text"
                                                                                                                label="Account No"
                                                                                                                placeholder="Enter here"
                                                                                                                // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                                onChange={(event) => handleChange(event, 'accountNo')}
                                                                                                                required={true}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        (payload.modeOfPayment == "Easypaisa/Jazzcash" || payload.modeOfPayment == "IBFT") && (
                                                                                                            <Input
                                                                                                                span="*"
                                                                                                                inputType="text"
                                                                                                                label="Stan No"
                                                                                                                placeholder="Enter here"
                                                                                                                // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                                onChange={(event) => handleChange(event, 'stanNo')}
                                                                                                                required={true}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        payload.modeOfPayment == "other" && (
                                                                                                            <Input
                                                                                                                // span="*"
                                                                                                                inputType="text"
                                                                                                                label="Details"
                                                                                                                placeholder="Enter here"
                                                                                                                // value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                                                                                                onChange={(event) => handleChange(event, 'otherDetails')}
                                                                                                                // required={true}
                                                                                                            />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                    <div className='d-flex jc-end'>
                                                                                        <button className='btn save_btn'>Save</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </UserModal>
                                                                    </td>
                                                                )
                                                            }
                                                        </tr>
                                                    );
                                                })}
                                            </>
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }


                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}

export default ManualRepay