import React, { useEffect, useState } from 'react'
import { IoDocumentTextOutline } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';

import { IoIosArrowForward } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { PiStudent } from "react-icons/pi";
import { AiOutlineFileAdd } from "react-icons/ai";
import { MdPreview } from "react-icons/md";
import { MdDomainVerification } from "react-icons/md";
import { FaCheck, FaChevronRight } from 'react-icons/fa';
import FormProgress from './FormProgress';
import { useAuth } from '../../../ContextAPI/Components/auth';


function RenewalTabs(props) {
    const { renewalTabData } = useAuth();
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [isDemoInoComplete, setIsDemoInoComplete] = useState("incomplete")
    const [isAcdBgComplete, setIsAcdBgComplete] = useState("incomplete")
    const [isRenDetComplete, setIsRenDetComplete] = useState("incomplete")
    const [isAddInfoComplete, setIsAddInfoComplete] = useState("incomplete")


    const [showForm, setShowForm] = useState("step_1");
    const [form, setForm] = useState({})

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);

    const preview = () => {
        if (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done" && renewalTabData.form4 == "done") {
            navigate(`#${"preview"}`)
        }
    }
    const final = () => {
        if (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done" && renewalTabData.form4 == "done") {
            navigate(`#${"final"}`)
        }
    }

    console.log("TTTAAAAABBB", renewalTabData);
    return (
        <>
            <div className='progress_tabs mb-4'>
                <div className="renewal_tabs timeline-steps aos-init aos-animate" data-aos="fade-up">

                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => navigate(`#${"demographics_information"}`)}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            renewalTabData.form1 == "done" ?
                                                <ImProfile className={`timeline_icon ${showForm == "demographics_information" ? "formActivrTabColor" : "formCompleteTabColor"}`} />
                                                :
                                                <ImProfile className={`timeline_icon ${(showForm == "demographics_information" && "formActivrTabColor")}`} />
                                        }
                                    </div>
                                    {
                                        renewalTabData.form1 == "done" &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        renewalTabData.form1 == "done" ?
                                            <p className={`mb-0 ${showForm == "demographics_information" ? "formActivrTabColor" : "formCompleteTabColor"}`}>1. Demographics Information</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "demographics_information" && "formActivrTabColor"}`}>1. Demographics Information</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${renewalTabData.form1 == "done" ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className='d-flex ai-center'>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => navigate(`#${"academic_background"}`)}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            renewalTabData.form2 == "done" ?
                                                <PiStudent className={`timeline_icon ${showForm == "academic_background" ? "formActivrTabColor" : "formCompleteTabColor"}`} />
                                                :
                                                <PiStudent className={`timeline_icon ${showForm == "academic_background" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        renewalTabData.form2 == "done" &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        renewalTabData.form2 == "done" ?
                                            <p className={`mb-0 ${showForm == "academic_background" ? "formActivrTabColor" : "formCompleteTabColor"}`}>2. Academic Background</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "academic_background" && "formActivrTabColor"}`}>2. Academic Background</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${renewalTabData.form2 == "done" ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className='d-flex ai-center'>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => navigate(`#${"renewal_details"}`)}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            renewalTabData.form3 == "done" ?
                                                <ImProfile className={`timeline_icon ${showForm == "renewal_details" ? "formActivrTabColor" : "formCompleteTabColor"}`} />
                                                :
                                                <ImProfile className={`timeline_icon ${showForm == "renewal_details" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        renewalTabData.form3 == "done" &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        renewalTabData.form3 == "done" ?
                                            <p className={`mb-0 ${showForm == "renewal_details" ? "formActivrTabColor" : "formCompleteTabColor"}`}>3. Renewal Details</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "renewal_details" && "formActivrTabColor"}`}>3. Renewal Details</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${renewalTabData.form3 == "done" ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className='d-flex ai-center'>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => navigate(`#${"additional_information"}`)}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            renewalTabData.form4 == "done" ?
                                                <AiOutlineFileAdd className={`timeline_icon ${showForm == "additional_information" ? "formActivrTabColor" : "formCompleteTabColor"}`} />
                                                :
                                                <AiOutlineFileAdd className={`timeline_icon ${showForm == "additional_information" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        renewalTabData.form4 == "done" &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        renewalTabData.form4 == "done" ?
                                            <p className={`mb-0 ${showForm == "additional_information" ? "formActivrTabColor" : "formCompleteTabColor"}`}>4. Additional Information</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "additional_information" && "formActivrTabColor"}`}>4. Additional Information</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${renewalTabData.form4 == "done" ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className='d-flex ai-center'>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => preview()}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done" && renewalTabData.form4 == "done" && props.showForm == "final") ?
                                                <MdPreview className={`timeline_icon ${showForm == "preview" ? "formActivrTabColor" : "formCompleteTabColor"}`} />
                                                :
                                                <MdPreview className={`timeline_icon ${showForm == "preview" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done" && renewalTabData.form4 == "done" && props.showForm == "final") &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        (renewalTabData.form1 == "done" && renewalTabData.form2 == "done" && renewalTabData.form3 == "done" && renewalTabData.form4 == "done" && props.showForm == "final") ?
                                            <p className={`mb-0 ${showForm == "preview" ? "formActivrTabColor" : "formCompleteTabColor"}`}>5. Preview</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "preview" && "formActivrTabColor"}`}>5. Preview</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='d-flex ai-center'>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    <div className='timeline-tab'>
                        <div className="timeline-step mb-0" onClick={() => final()}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        <MdDomainVerification className={`timeline_icon ${showForm == "final" && "formActivrTabColor"}`} />
                                    </div>
                                    <p className={`text-muted mb-0 ${showForm == "final" && "formActivrTabColor"}`}>6. Final</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <FormProgress /> */}

            </div>
        </>
    )
}

export default RenewalTabs
