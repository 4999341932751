import { useMemo, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { AddProgramsInUNI, RemoveProgramID, getProgram, getProgramOfUni } from '../../ContextAPI/APIs/api';
import { useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import UserModal from '../Modals/Modal';
import { useToast } from '../../ContextAPI/Components/toast';
import { useConfirm } from '../../ContextAPI/Components/confirm';



export function University_Programs(props) {
    const { Ask } = useConfirm();
    const { alert } = useToast();
    const params = useParams();
    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const [UniPrograms, setUniProg] = useState([])
    const [prog, setProg] = useState([])

    const getAllPrograms = async () => {
        try {
            // const response = await getProgram(props.feild || "")
            const response = await getProgram()
            if (response?.success) {
                setProg(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getAllUniPrograms = async () => {
        try {
            const response = await getProgramOfUni(params.id)
            if (response?.success) {
                setUniProg(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const PRogAdd = async (pid) => {
        try {
            // if (await Ask() == false) return
            const response = await AddProgramsInUNI({ uniId: params.id, programId: pid })
            if (response.success) {
                getAllUniPrograms()
            }
            alert(response.message, response.success)
        }
        catch (e) {
            console.log(e);
        }
    }

    const PRogDelete = async (pid) => {
        if (await Ask() == false) return
        try {
            const response = await RemoveProgramID({ uniId: params.id, programId: pid })
            if (response.success) {
                getAllUniPrograms()
            }
            alert(response.message, response.success)
        }
        catch (e) {
            console.log(e);
        }
    }


    useMemo(() => {
        console.log("props.feild", props.feild == "");
        if (props.feild != "") {
            getAllPrograms()
            getAllUniPrograms()
        }

    }, [props.feild])





    return <>
        <div className='d-flex jc-between ai-center mb-3'>
            <h4 className='mb-0'>Programs</h4>

            <div className='d-flex jc-end'>
                <UserModal btnText={<FaPlus />} heading="Add Program" id={`ViewProgram`} customClasses="round_btn" modalSize="lg" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                    <div className="programs_main">
                        {
                            prog.filter(item => !UniPrograms.some(userItem => userItem.name === item.name)).map((item, i) => (
                                <div key={i} className='program_name'>
                                    <p>{item?.name}</p>
                                    <button className='btn round_btn' onClick={() => PRogAdd(item._id)}><FaPlus /></button>
                                </div>
                            ))
                        }
                        {
                            prog.filter(item => !UniPrograms.some(userItem => userItem.name === item.name)).length < 1 && (
                                <p>No Program Available</p>
                            )
                        }
                    </div>
                </UserModal>
            </div>
        </div>

        <div className="program_row">
            {
                UniPrograms.map((item, i) => {
                    return <div key={i} className="program_card my-2">
                        <div className='d-flex ai-center jc-between'>
                            <p className='prog_name'>{item?.name}</p>
                            <button onClick={() => PRogDelete(item?._id)} className='btn dlt_program_btn'><RxCross2 /></button>
                        </div>
                    </div>
                })
            }
        </div >

    </>
}