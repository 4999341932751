import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Form from "react-bootstrap/Form"
import { getForm5, getFreshCaseApp, SubmissionForm5 } from '../../ContextAPI/APIs/api';

import { FileViewerAdmin } from '../Form/fileViewerAdmin';
import { newURL } from '../../helpers/data';


function SOPViewOnly({ docs }) {

    const { id, doc } = useParams();
    const navigate = useNavigate();

    const [sop, setSop] = useState({ SOPViewOnly: "" })

    const handleSop = (e) => {
        setSop({ ...sop, SOP: e.target.value })
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {

        const payload = {
            SOPs: sop.SOP
        }

        const response = await SubmissionForm5(payload)
        alert(response.message)

        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }

        // setValidated(true);
        // navigate(`#${"academic_background"}`)
    };
    const getFormData = async () => {
        try {
            // const response = await getForm5()
            const response = await getFreshCaseApp(
                {
                    id: doc,
                    form: "form5"
                }
            )
            console.log("REP", response);
            if (response.success) {
                setSop({ ...sop, SOP: response.message?.SOPs })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()
    }, [])


    return (
        <>

            <div className='preview_heading'>
                <h5>Statement of Purpose</h5>
            </div>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <div className="col-12">
                    <div className='sop_section'>
                        <h5>Statement of Purpose means:</h5>
                        <ul>
                            <li>What the applicant wants to do in his/her life</li>
                            <li>What ambitions you have, what special you have done in the past</li>
                            <li>What peculiar and special qualities and personal attributes you posses which makes you
                                eligible for Ihsan Trust Interest Free Loan scheme
                            </li>
                            <li>What plans you have for your country Pakistan to make it a better place to live</li>
                        </ul>
                        <div className='mb-4'>
                            <Form.Group controlId="sopTextArea">
                                <Form.Label className='form-label'>Statement of Purpose (SOP)* in Life <span className='required'>*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={sop.SOP}
                                    onChange={(e) => handleSop(e)}
                                    className='form-control'
                                    cols={30}
                                    rows={7}
                                    // rows={parseInt(sop?.SOP?.length)/parseInt(120) }
                                    disabled
                                    required
                                />
                            </Form.Group>
                        </div>

                    </div>
                </div>

            </Form>

            <div className='preview_heading'>
                <h5>Undertaking</h5>
            </div>

            <FileViewerAdmin
                document={docs}
                id={id}
                text="Undertaking"
                span="*"
                url={newURL}
                keyName={"undertaking"}
            />

        </>
    )
}

export default SOPViewOnly
