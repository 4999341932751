import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useProSidebar } from 'react-pro-sidebar';

import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'

import { getAllOthers } from '../../ContextAPI/APIs/api';
import { UserCard } from '../../components/UserCard';
import TooltipComponent from '../../components/tooltip';
import { Link } from 'react-router-dom';
import { Show_Profile_Pic } from '../../components/Profile/upload_profilePic';
import Table from '../../components/Table/Table';
import IconText from '../../components/Table/IconText';
import Text from '../../components/Table/Text';
import Badges from '../../components/Badges';
import Pagination from '../../components/Pagination';



function Others() {
    const [allOthers, setAllOthers] = useState([])
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    async function GetAllOthers() {
        const res = await getAllOthers()
        if (res.success) setAllOthers(res.message)
    }

    useEffect(() => {
        GetAllOthers()

    }, [])


    return (
        <>

            <Helmet>
                <title>Ihsan Trust | Other Users</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='uni_profile_manag_section'>
                        <div className='unversity_card'>
                            <h2 className='mb-3'>Other Users List</h2>

                            <div className="card">
                                {
                                    allOthers.length > 0 ?
                                        <Table head={["Name"]}>
                                            {
                                                allOthers?.map((data, i) => {
                                                    return <tr key={i}>
                                                        <td className="text-left">
                                                            <div className='d-flex ai-center'>
                                                                <div>
                                                                    <Show_Profile_Pic src={data?.profilepic} role={data?.role} classes="profile-image sm mb-0" />
                                                                </div>
                                                                <div>
                                                                    <IconText text={data?.fullName} smallText={data?.email || "NA"} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/* <td><Text text={data?.phone || "NA"} /></td> */}
                                                        {/* <td className="text-capital"><Text text={data?.gender || "NA"} /></td> */}
                                                        {/* <td><Badges text={data?.status || "NA"} classes={`${data?.status || "pending"} md`} /></td> */}
                                                    </tr>
                                                })
                                            }

                                        </Table>
                                        :
                                        <h5 className='not_found_msg'>No Records Found</h5>
                                }

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}


export default Others