import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { getForm6, getFormsByAdmin, SubmissionForm6 } from "../../ContextAPI/APIs/api"

import { useAuth } from "../../ContextAPI/Components/auth"


function Step_7(props) {

    const { GetLoginUser } = useAuth();
    // Family Members Studying
    const [familyMembStd, setFamilyMembStd] = useState([
        {
            Name: "",
            Relationship: "",
            Name_of_Institution: "",
            Fee_per_Month: "",
            Private_Tuitions_Fee: "",
            Boarding_and_Lodging: "",
            Stationary_Expenditure: "",
        },
    ]);

    const family_detail = {
        Name: "",
        Relationship: "",
        Name_of_Institution: "",
        Fee_per_Month: "",
        Private_Tuitions_Fee: "",
        Boarding_and_Lodging: "",
        Stationary_Expenditure: "",
    }

    function Add_New_detail(e) {
        e.preventDefault();
        setFamilyMembStd([...familyMembStd, family_detail])
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);



    const [data, setData] = useState({
        houseDetail: "",
        houseSize: "",
        houseRent: "",
        houseAdvance: "",
        houseYearlyExpense: "",
        UtilityMonth1: "",
        telephoneMonth1: "",
        electricityMonth1: "",
        gasMonth1: "",
        waterMonth1: "",
        totalMonth1: "",
        UtilityMonth2: "",
        telephoneMonth2: "",
        electricityMonth2: "",
        gasMonth2: "",
        waterMonth2: "",
        totalMonth2: "",
        UtilityMonth3: "",
        telephoneMonth3: "",
        electricityMonth3: "",
        gasMonth3: "",
        waterMonth3: "",
        totalMonth3: "",
        LegalExpenditure: "",
        LoanRepayment: "",
        donation: "",
        insurancePayment: "",
        TotalEducationExpenditure: "",
        TotalRentalExpenditure: "",
        TotalTransportationExpenditure: "",
        TotalRationExpenditure: "",
        TotalMedicalExpenditure: "",
        TotalUtilitiesExpenditure: "",
        TotalOtherExpenditure: "",
        TotalFamilyExpenditureMonthly: "",
        totalIncomeMonth: "",
        totalIncomeMonth: "",
        totalExpenditureMonth: "",
        totalExpenditureYear: "",
        netDispossibleIncomeMonth: "",
        netDispossibleIncomeYear: "",
        question61: "",
    })

    const handleChange = (name, value) => {
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };




    function handleInputChange(e, index) {
        const { name, value } = e.target;
        const updatedFamilyMembStd = [...familyMembStd];
        updatedFamilyMembStd[index][name] = value;
        setFamilyMembStd(updatedFamilyMembStd);
    }


    const Next = async () => {
        try {
            // console.log("familyMembStd", familyMembStd);
            // console.log("DATA", data);

            // const payload = {
            //     ...data,
            //     familyMembersStudying: familyMembStd
            // }

            // const response = await SubmissionForm6(payload)
            // if (response.success) {
            //     alert(response.message)
            navigate(`#${"step_8"}`)
            //     GetLoginUser()
            // }
            // console.log("Payload", payload);
        }
        catch (e) {
            console.log(e);
        }
    }



    const getFormData = async () => {
        try {
            // const response = await getForm6()
            const response = await getFormsByAdmin(6, props.id)

            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
                setFamilyMembStd(response.message.familyMembersStudying)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getFormData()
    }, [])

    return (

        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        {/* <form> */}
                        <div className="table_form mb-4">
                            <h4>Details of Expenditure</h4>
                            <div className='d-flex ai-center jc-between mb-4'>
                                <h5 className='mb-0'>Family Members Studying:</h5>
                                {/* <button className='btn add_btn' onClick={(e) => Add_New_detail(e)}><FaPlus /></button> */}
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered" style={{ width: "170%" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Relationship</th>
                                            <th scope="col">Name of Institution (if Studying)</th>
                                            <th scope="col">Fee per Month (Rs.)</th>
                                            <th scope="col">Private Tuitions Fee</th>
                                            <th scope="col">Boarding and Lodging</th>
                                            <th scope="col">Stationary Expenditure</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {familyMembStd.map((data, i) => (
                                            <tr key={i}>
                                                <td><input disabled type="text" name="Name" value={data.Name} onChange={(e) => handleInputChange(e, i)} className='form-control' placeholder="Enter here" /></td>
                                                <td><input disabled type="text" name="Relationship" value={data.Relationship} onChange={(e) => handleInputChange(e, i)} className='form-control' placeholder="Enter here" /></td>
                                                <td><input disabled type="text" name="Name_of_Institution" value={data.Name_of_Institution} onChange={(e) => handleInputChange(e, i)} className='form-control' placeholder="Enter here" /></td>
                                                <td><input disabled type="number" name="Fee_per_Month" value={data.Fee_per_Month} onChange={(e) => handleInputChange(e, i)} className='form-control' placeholder="Enter here" /></td>
                                                <td><input disabled type="number" name="Private_Tuitions_Fee" value={data.Private_Tuitions_Fee} onChange={(e) => handleInputChange(e, i)} className='form-control' placeholder="Enter here" /></td>
                                                <td><input disabled type="number" name="Boarding_and_Lodging" value={data.Boarding_and_Lodging} onChange={(e) => handleInputChange(e, i)} className='form-control' placeholder="Enter here" /></td>
                                                <td><input disabled type="number" name="Stationary_Expenditure" value={data.Stationary_Expenditure} onChange={(e) => handleInputChange(e, i)} className='form-control' placeholder="Enter here" /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="table_form mb-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <h5 className='mt-0 mb-3 me-5'>House Detail:</h5>
                                        <div className="form-check me-5">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="houseDetail"
                                                id="owned"
                                                value="Owned"
                                                checked={data.houseDetail === "Owned"}
                                            />
                                            <label className="form-check-label" htmlFor="owned">Owned</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="houseDetail"
                                                id="rental"
                                                value="Rental"
                                                checked={data.houseDetail === "Rental"}
                                            />
                                            <label className="form-check-label" htmlFor="rental">Rental</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <p>Please Mention Details of your Rental Expenditure (if any)</p>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Size of the House (yards)</th>
                                            <th scope="col">Monthly Rent (Rs.)</th>
                                            <th scope="col">Deposit/Advance (Rs.)</th>
                                            <th scope="col">Yearly Expenditure</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.houseSize}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.houseRent}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.houseAdvance}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.houseYearlyExpense}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className="table_form mb-4">
                            <h5>Utility Expenditure (Rs.)</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={6}>Last 3 Months</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Month</th>
                                            <th>Telephone</th>
                                            <th>Electricity</th>
                                            <th>Gas</th>
                                            <th>Water</th>
                                            <th>Total</th>
                                        </tr>

                                        {[1, 2, 3].map((month) => (
                                            <tr key={month}>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data[`UtilityMonth${month}`]} onChange={(e) => handleChange(`UtilityMonth${month}`, e.target.value)} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data[`telephoneMonth${month}`]} onChange={(e) => handleChange(`telephoneMonth${month}`, e.target.value)} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data[`electricityMonth${month}`]} onChange={(e) => handleChange(`electricityMonth${month}`, e.target.value)} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data[`gasMonth${month}`]} onChange={(e) => handleChange(`gasMonth${month}`, e.target.value)} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data[`waterMonth${month}`]} onChange={(e) => handleChange(`waterMonth${month}`, e.target.value)} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder='Enter here' value={data[`totalMonth${month}`]} onChange={(e) => handleChange(`totalMonth${month}`, e.target.value)} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>








                        <div className="table_form mb-4">
                            <h5>Other Expenditure (if any):</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered" style={{ width: "150%" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Legal Expenditure</th>
                                            <th scope="col">Loan Repayment (please specify)</th>
                                            <th scope="col">Donation</th>
                                            <th scope="col">Payment of Insurance Premium (please mention the thing insured)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.LegalExpenditure}
                                                    onChange={(e) => handleChange('LegalExpenditure', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.LoanRepayment}
                                                    onChange={(e) => handleChange('LoanRepayment', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.donation}
                                                    onChange={(e) => handleChange('donation', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.insurancePayment}
                                                    onChange={(e) => handleChange('insurancePayment', e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="table_form mb-4">
                            <h5>Total Family Expenditure â€“ Monthly (Rs.)</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">(a) Total Education Expenditure</th>
                                            <th scope="col">(b) Total Rental Expenditure</th>
                                            <th scope="col">(c) Total Transportation Expenditure</th>
                                            <th scope="col">(d) Ration Expenditure</th>
                                            <th scope="col">(e) Medical Expenditure (if any)</th>
                                            <th scope="col">(f) Total Utilities Expenditure (average of 3 months)</th>
                                            <th scope="col">(g) Total Other Expenditure</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.TotalEducationExpenditure}
                                                    onChange={(e) => handleChange('TotalEducationExpenditure', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.TotalRentalExpenditure}
                                                    onChange={(e) => handleChange('TotalRentalExpenditure', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.TotalTransportationExpenditure}
                                                    onChange={(e) => handleChange('TotalTransportationExpenditure', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.TotalRationExpenditure}
                                                    onChange={(e) => handleChange('TotalRationExpenditure', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.TotalMedicalExpenditure}
                                                    onChange={(e) => handleChange('TotalMedicalExpenditure', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.TotalUtilitiesExpenditure}
                                                    onChange={(e) => handleChange('TotalUtilitiesExpenditure', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <input disabled
                                                    type="number"
                                                    className='form-control'
                                                    placeholder='Enter here'
                                                    value={data.TotalOtherExpenditure}
                                                    onChange={(e) => handleChange('TotalOtherExpenditure', e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className='d-flex ai-center mb-4'>
                                    <label className="form-label me-3">Total Family Expenditure (monthly):</label>
                                    <div>
                                        <input disabled
                                            type="number"
                                            className='form-control'
                                            placeholder='Enter here'
                                            value={data.TotalFamilyExpenditureMonthly}
                                            onChange={(e) => handleChange('TotalFamilyExpenditureMonthly', e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="table_form mb-4">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Amount (Rs.)</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Amount (Rs.)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope='row'>Total Monthly Income</th>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className='form-control'
                                                        placeholder='Enter here'
                                                        value={data.totalIncomeMonth}
                                                        onChange={(e) => handleChange('totalIncomeMonth', e.target.value)}
                                                    />
                                                </td>
                                                <th scope='row'>Total Yearly Income</th>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className='form-control'
                                                        placeholder='Enter here'
                                                        value={data.totalIncomeYear}
                                                        onChange={(e) => handleChange('totalIncomeYear', e.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope='row'>Total Monthly Expenditure</th>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className='form-control'
                                                        placeholder='Enter here'
                                                        value={data.totalExpenditureMonth}
                                                        onChange={(e) => handleChange('totalExpenditureMonth', e.target.value)}
                                                    />
                                                </td>
                                                <th scope='row'>Total Yearly Expenditure</th>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className='form-control'
                                                        placeholder='Enter here'
                                                        value={data.totalExpenditureYear}
                                                        onChange={(e) => handleChange('totalExpenditureYear', e.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope='row'>Net Monthly Disposable Income</th>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className='form-control'
                                                        placeholder='Enter here'
                                                        value={data.netDispossibleIncomeMonth}
                                                        onChange={(e) => handleChange('netDispossibleIncomeMonth', e.target.value)}
                                                    />
                                                </td>
                                                <th scope='row'>Net Annual Disposable Income</th>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className='form-control'
                                                        placeholder='Enter here'
                                                        value={data.netDispossibleIncomeYear}
                                                        onChange={(e) => handleChange('netDispossibleIncomeYear', e.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className='mb-4'>
                                    <label className="form-label">If the net annual disposable income is negative, please state the source you use to meet your needs</label>
                                    <textarea
                                        disabled
                                        className='form-control'
                                        placeholder='Type here...'
                                        value={data.question61}
                                        onChange={(e) => handleChange('question61', e.target.value)}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </div>


                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"step_6"}`)}>Previous</button>
                            <button className='btn next_btn' onClick={() => Next()}>Next</button>
                        </div>
                        {/* </form> */}
                    </div>
                </div >
            </section >
        </>
    )
}

export default Step_7