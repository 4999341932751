import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import profile from "../../assets/profile.svg"

import { getForm1, getFormsByAdmin, getUNI, getUNIProfile, SubmissionForm1, UpdateProfilePic } from '../../ContextAPI/APIs/api';
import { profilePicUrl } from '../../helpers/data';
import { useAuth } from '../../ContextAPI/Components/auth';
import { Show_Profile_Pic } from '../Profile/upload_profilePic';


function Step_2(props) {

    const { GetLoginUser, user } = useAuth();

    const [logo, setLogo] = useState(null);
    const [showLogo, setShowLogo] = useState([]);

    const onSelectLogo = (event) => {
        const selectedFiles = event.target.files;
        setLogo(selectedFiles[0])
        setShowLogo(URL.createObjectURL(selectedFiles[0]))

        // FOR BUG IN CHROME
        event.target.value = "";
    };

    function deleteLogo(image, e) {
        e.preventDefault();
        setLogo(null);
        URL.revokeObjectURL(image);
        setShowLogo([])
    }

    async function handleImage(e) {
        e.preventDefault();

        const myForm = new FormData();
        myForm.append("picture", logo)
        const res = await UpdateProfilePic(myForm)
        if (res.success) {
            GetLoginUser()
            setLogo(null)
            return alert(res.message)
        }
        else return alert(res.message)
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    const [form, setForm] = useState({})

    const [data, setData] = useState({
        date: form.date || "",
        fullName: form.fullName || "",
        fatherName: form.fatherName || "",
        cnic: form.cnic || "",
        DOB: form.DOB || "",
        institute: form.institute || "",
        program: form.program || "",
        rollNo: form.rollNo || "",
        email: form.email || "",
        maritalStatus: form.maritalStatus || "",
        ptcl: form.ptcl || "",
        cellNo: form.cellNo || "",
        presentAddress: form.presentAddress || "",
        permanentAddress: form.permanentAddress || "",
        ExamStructure: form.ExamStructure || "",
        currentSem: form.currentSem || "",
        totalSem: form.totalSem || "",
        currentGPA: form.currentGPA || "",
        CGPA: form.CGPA || "",
        Comments: form.Comments || "",
    })

    const getFormData = async () => {
        try {
            // const response = await getForm1()
            const response = await getFormsByAdmin(1, props.id)
            console.log("fomr1", response);
            setForm(response?.message)
            setData(response?.message)
            setFatherData(response?.message?.familyDetails1[0])
            setMotherData(response?.message?.familyDetails1[1])
            setSpouseData(response?.message?.familyDetails1[2])
        }
        catch (e) {
            console.log(e);
        }
    }

    // familyDetails1: [],

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);

    const Next = async () => {
        try {
            navigate(`#${"step_3"}`)
        }
        catch (e) {
            console.log(e);
        }
    }

    const [fatherData, setFatherData] = useState({
        name: '',
        cnic: '',
        residential: '',
        email: '',
        occupation: '',
        designation: '',
        organization: '',
        monthlyIncome: '',
        pension: '',
        cellNo: '',
    });

    const [motherData, setMotherData] = useState({
        name: '',
        cnic: '',
        residential: '',
        email: '',
        occupation: '',
        designation: '',
        organization: '',
        monthlyIncome: '',
        pension: '',
        cellNo: '',
    });

    const [spouseData, setSpouseData] = useState({
        name: '',
        cnic: '',
        residential: '',
        email: '',
        occupation: '',
        designation: '',
        organization: '',
        monthlyIncome: '',
        pension: '',
        cellNo: '',
    });

    const handleFatherChange = (e, field) => {
        setFatherData({
            ...fatherData,
            [field]: e.target.value,
        });
    };

    const handleMotherChange = (e, field) => {
        setMotherData({
            ...motherData,
            [field]: e.target.value,
        });
    };

    const handleSpouseChange = (e, field) => {
        setSpouseData({
            ...spouseData,
            [field]: e.target.value,
        });
    };

    const SELECTUNI = (e) => {
        try {
            setData({ ...data, institute: e.target.value })
            getAllData(e.target.value)

        }
        catch (e) {
            console.log(e);
        }
    }

    const [program, setProgram] = useState([])

    const [uni, setUni] = useState([])

    const [dis, setDis] = useState(true)
    const getAllData = async (id) => {
        try {
            const response = await getUNIProfile({ uniId: id })
            console.log("REPO", response);

            if (response.success) {
                setProgram(response?.message?.programs)
                setDis(false)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getAllUni = async () => {
        try {
            const response = await getUNI()

            if (response?.success) {
                console.log("UNISS", response.message);
                setUni(response?.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        // getAllUni()
        getFormData()
    }, [])

        
    function timestampToDate(timestamp) {
        if (timestamp) {
            const milliseconds = timestamp.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate
        }
        else {
            return "---"
        }
    }


    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        {/* <form> */}
                        <div className='header'>
                            <div className='heading'>
                                <h4>Ihsan Trust (IT)</h4>
                                <p>Interest Free Loan Application Form
                                    Applicant’s Personal and Family Information</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className='mb-4 mt-2 date_image'>
                                <Show_Profile_Pic src={data?.userId?.profilepic} />
                                {/* <div className='profile-image image'>
                                        <label className="mb-0">
                                            <div className="overlay">
                                                <span className="icon d-flex justify-content-around align-items-center w-40 text-center">
                                                    <div>
                                                        <i className="fas fa-pen"></i>
                                                    </div>
                                                    {
                                                        typeof logo !== "undefined" && logo !== null &&
                                                        <>
                                                            <div onClick={(e) => deleteLogo(logo.image, e)}>
                                                                <i className="fas fa-trash"></i>
                                                            </div>
                                                            <div
                                                                onClick={(e) => handleImage(e)}
                                                            >
                                                                <i className="fas fa-check"></i>
                                                            </div>
                                                        </>
                                                    }
                                                </span>
                                            </div>
                                            {
                                                (showLogo && showLogo != "") ?
                                                    <img src={showLogo} alt="logo 1" className="img-fluid" />
                                                    :
                                                    (user.profilepic && user.profilepic != "") ?
                                                        <img src={`${profilePicUrl}/${user.profilepic}`} alt="logo 3" className="img-fluid" />
                                                        :
                                                        <img src={profile} alt="logo 4" className="img-fluid" />
                                            }
                                            <input type="file" name="" className='upload_image_input' id="" style={{ display: "none" }} onChange={onSelectLogo} />
                                        </label>
                                    </div> */}

                                <div className='d-flex ai-center date'>
                                    <label className="form-label mb-0 me-2">Date:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name="date"
                                        value={timestampToDate(data.timestamp)}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label">Full Name of the Applicant:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="fullName"
                                        value={data.fullName}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label"> Father’s / Guardian Name:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="fatherName"
                                        value={data.fatherName}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label">Applicant’s CNIC No.</label>
                                    <input
                                        type="number"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="cnic"
                                        value={data.cnic}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label">Date of Birth:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name="DOB"
                                        value={timestampToDate(data.DOB)}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-4">
                                <div className='form-group'>
                                    <label className="form-label">University/Institute Name:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name="DOB"
                                        value={data?.institute?.fullName}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            {/* Continue the pattern for the remaining input fields */}
                            <div className="col-lg-3 col-md-4">
                                <div className='form-group'>
                                    <label className="form-label">Program:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name="DOB"
                                        value={data?.program?.name}
                                        onChange={handleChange}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className='form-group'>
                                    <label className="form-label">Reg No. / Enrollment No.:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="rollNo"
                                        value={data.rollNo}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label">Applicant’s Email Address:</label>
                                    <input
                                        type="email"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="email"
                                        value={data.email}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label me-4">Marital Status:</label>
                                    <div className='d-flex'>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="maritalStatus"
                                                id="flexRadioDefault1"
                                                value="Single"
                                                checked={(data.maritalStatus).toLowerCase() === "single"}
                                            />
                                            <label className="form-check-label me-5" htmlFor="flexRadioDefault1">Single</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="maritalStatus"
                                                id="flexRadioDefault2"
                                                value="Married"
                                                checked={(data.maritalStatus).toLowerCase() === "married"}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">Married</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Continue for the remaining input fields */}
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label">Res PTCL:</label>
                                    <input
                                        type="tel"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="ptcl"
                                        value={data.ptcl}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className="form-label">Applicant’s Cell No:</label>
                                    <input
                                        type="tel"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="cellNo"
                                        value={data.cellNo}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className='form-group'>
                                    <label className="form-label">Present Address:</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="presentAddress"
                                        value={data.presentAddress}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className='form-group'>
                                    <label className="form-label">Permanent Address: </label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="permanentAddress"
                                        value={data.permanentAddress}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className='form-group'>
                                    <label className="form-label me-4">Examination Structure:</label>
                                    <div className='d-flex'>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ExamStructure"
                                                id="flexRadioDefault55"
                                                value="Semester"
                                                checked={data.ExamStructure === "Semester"}
                                            />
                                            <label className="form-check-label me-5" htmlFor="flexRadioDefault55">Semester</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ExamStructure"
                                                id="flexRadioDefault66"
                                                value="Yearly"
                                                checked={data.ExamStructure === "Yearly"}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault66">Yearly</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className='form-group'>
                                    <label className="form-label">Current Semester / Year:</label>
                                    <input
                                        type="number"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="currentSem"
                                        value={data.currentSem}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className='form-group'>
                                    <label className="form-label">Total No. of Semester / Year:</label>
                                    <input
                                        type="number"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="totalSem"
                                        value={data.totalSem}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className='form-group'>
                                    <label className="form-label">Current GPA:</label>
                                    <input
                                        type="number"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="currentGPA"
                                        value={data.currentGPA}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className='form-group'>
                                    <label className="form-label">CGPA:</label>
                                    <input
                                        type="number"
                                        className='form-control'
                                        placeholder='Enter here'
                                        name="CGPA"
                                        value={data.CGPA}
                                        disabled
                                        required
                                    />
                                </div>
                            </div>

                            <div className="table_form mb-4">
                                <h5>Parent’s Information</h5>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Father / Guardian</th>
                                                <th scope="col">Mother</th>
                                                <th scope="col">Spouse (if any)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Name</th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.name}
                                                        disabled
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.name}
                                                        disabled
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.name}
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">CNIC No.</th>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.cnic}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.cnic}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.cnic}
                                                    />
                                                </td>
                                            </tr>
                                            {/* ... (repeat the structure for other fields) ... */}
                                            <tr>
                                                <th scope="row">Pension (if retired)</th>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.pension}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.pension}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.pension}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cell No.</th>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.cellNo}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.cellNo}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.cellNo}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">CNIC No.</th>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.cnic}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.cnic}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.cnic}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Residential (if different)</th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.residential}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.residential}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.residential}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email</th>
                                                <td>
                                                    <input
                                                        type="email"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.email}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="email"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.email}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="email"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.email}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Occupation</th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.occupation}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.occupation}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.occupation}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Designation</th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.designation}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.designation}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.designation}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Name of the Organization</th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.organization}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.organization}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.organization}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Monthly Income</th>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.monthlyIncome}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.monthlyIncome}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={spouseData.monthlyIncome}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cell No.</th>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={fatherData.cellNo}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        disabled
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        value={motherData.cellNo}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        // disabled
                                                        disabled
                                                        placeholder="Enter here"
                                                        value={spouseData.cellNo}
                                                    />
                                                </td>
                                            </tr>
                                            {/* ... (repeat the above structure for other fields) ... */}
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label className="form-label">Explain in detail, why are you applying for the Interest free Loan from Ihsan trust?</label>
                                <textarea
                                    className='form-control'
                                    cols="30" rows="3"
                                    placeholder='Type here...'
                                    type="text"
                                    name="Comments"
                                    value={data.Comments}
                                    disabled
                                    required
                                ></textarea>
                            </div>

                            <div className='d-flex jc-between'>
                                <button className='btn prev_btn' onClick={() => navigate(`#${"step_1"}`)}>Previous</button>
                                <button className='btn next_btn' onClick={() => Next()}>Next</button>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step_2
