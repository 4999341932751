import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';


import { getForm3, getForm4, SubmissionForm3, SubmissionForm4 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"
import { useToast } from "../../ContextAPI/Components/toast"
import ImageUploadInput from '../Modals/ImageUploadInput';

import {
    academicCertficatesUrl, admissionLetterUrl, BillUrl, childrenBformUrl, CNICUrl, DeathCertificateUrl, DomicileUrl,
    FamilyUrl, IncomeCertificateUrl, newURL, passimageUrl, RenewalUrl, RentAgreementUrl, resumeUrl, vouchersUrl
} from '../../helpers/data';

import Form from 'react-bootstrap/Form';

import EMP_DETAIL from "../../assets/form-icons/employment-detail.png"
import EXPENDIRURE from "../../assets/form-icons/expenditure.png"
import PROPERTY from "../../assets/form-icons/property.png"
import { FileViewer } from '../Form/fileViewer';
import formatter from '../../helpers/number_formatter';


function Additional_InformationViewOnly() {

    const { alert } = useToast()
    const { GetLoginUser } = useAuth();

    // Financial Assistance Detail


    const [data, setData] = useState({
        EmployeeOrg: "",
        EmployeeDesig: "",
        EmployeeExp: "",
        EmployeeSalary: "",
        OtherBenefits: "",
        EmployeeStatus: "",
        orgSponsor: "",
        OrgOutcomeAmount: "",
        ResignDate: "",
        CommutationDate: "",
        houseDetial: "",
        houseSize: "",
        houseMonthlyRent: "",
        houseDeposit: "",
        houseYearlyExpenditure: "",
        telephoneMonth: "",
        electricityMonth: "",
        gasMonth: "",
        waterMonth: "",
        totalMonth: "",
        LegalExpenditure: "",
        LoanRepayment: "",
        donation: "",
        insurancePayment: "",

        TotalEducationExpenditure: "",
        TotalRentalExpenditure: "",
        TotalTransportationExpenditure: "",
        TotalRationExpenditure: "",
        TotalMedicalExpenditure: "",
        TotalUtilitiesExpenditure: "",

        TotalOtherExpenditure: "",

        TotalFamilyExpenditureMonthly: "",
        totalIncomeMonth: "",

        totalIncomeYear: "",

        totalExpenditureMonth: "",
        totalExpenditureYear: "",
        netDispossibleIncomeMonth: "",
        netDispossibleIncomeYear: "",
        question41: "",


        total: "",


    })

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setData(prevData => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };

    const calculateTotalBills = (data) => {
        const bills = [
            parseFloat(data.telephoneMonth) || 0,
            parseFloat(data.electricityMonth) || 0,
            parseFloat(data.gasMonth) || 0,
            parseFloat(data.waterMonth) || 0,
            // Add more bills as needed
        ];

        // Calculate the sum of all bills
        const totalBills = bills.reduce((acc, bill) => acc + bill, 0);

        return totalBills;
    };

    const calculateTotalOtherExpenditure = (data) => {
        const fieldsToSum = [
            'TotalEducationExpenditure',
            'TotalRentalExpenditure',
            'TotalTransportationExpenditure',
            'TotalRationExpenditure',
            'TotalMedicalExpenditure',
            'TotalUtilitiesExpenditure',
            // Add more fields as needed
        ];

        // Calculate the sum of specified fields
        const totalOtherExpenditure = fieldsToSum.reduce((acc, field) => {
            const fieldValue = parseFloat(data[field]) || 0;
            return acc + fieldValue;
        }, 0);

        return totalOtherExpenditure;
    };

    // Example usage in the handleChange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value
            };

            // Calculate the sum of specified fields for TotalOtherExpenditure
            const totalOtherExpenditure = calculateTotalOtherExpenditure(updatedData);

            // Calculate the sum of bills
            const totalBills = calculateTotalBills(updatedData);

            const totalIncomeYear = (parseFloat(updatedData.totalIncomeMonth) || 0) * 12;
            const totalExpenditureYear = (parseFloat(updatedData.totalExpenditureMonth) || 0) * 12;
            const netDispossibleIncomeMonth = (parseFloat(updatedData.totalIncomeMonth) || 0) - (parseFloat(updatedData.totalExpenditureMonth) || 0);
            const netDispossibleIncomeYear = (parseFloat(netDispossibleIncomeMonth) || 0) * 12;

            // Update both TotalOtherExpenditure and total fields in the state
            return {
                ...updatedData,
                TotalOtherExpenditure: totalOtherExpenditure.toString(), // Convert to string if necessary
                totalMonth: totalBills.toString(), // Update total field
                totalIncomeYear: totalIncomeYear.toString(),
                netDispossibleIncomeMonth: netDispossibleIncomeMonth.toString(),
                netDispossibleIncomeYear: netDispossibleIncomeYear.toString(),
                totalExpenditureYear: totalExpenditureYear.toString()
            };
        });
    };

    // Loan Detail

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);



    const [dis, setDis] = useState(true)


    // const handleInputChange = (fieldName, value) => {
    //     setData((prevData) => ({
    //         ...prevData,
    //         [fieldName]: value,
    //     }));
    // };


    useEffect(() => {
        // getFormData()

    }, [])

    const [empDetail, iseEmpDetail] = useState("")
    const [empDis, setEmpDis] = useState(false)

    const handleCheckBoxEmpDetail = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setEmpDis(true)
        }
        else {
            setEmpDis(false)
        }
        iseEmpDetail(e.target.checked)
    }

    const [validated, setValidated] = useState(false);

    const [jobStatus, setJobStatus] = useState();
    const [askForSponsor, setAskForSponsor] = useState();


    const [familyMembStd, setFamilyMembStd] = useState([
        // {
        //     Name: "",
        //     Relationship: "",
        //     Name_of_Institution: "",
        //     Fee_per_Month: "",
        //     Private_Tuitions_Fee: "",
        //     Boarding_and_Lodging: "",
        //     Stationary_Expenditure: "",
        // },
    ]);

    const family_detail = {
        Name: "",
        Relationship: "",
        Name_of_Institution: "",
        Fee_per_Month: "",
        Private_Tuitions_Fee: "",
        Boarding_and_Lodging: "",
        Stationary_Expenditure: "",
    }

    function Add_New_detail(e) {
        e.preventDefault();
        setFamilyMembStd([...familyMembStd, family_detail])
    }


    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);



    function removeDetail(i) {

        const updated = [...familyMembStd]

        updated.splice(i, 1)
        setFamilyMembStd(updated)

    }


    ///////////////////step 8 

    const [assetDetails, setAssetDetails] = useState({
        airCondition: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        car: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        motorCycle: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        television: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        computerLaptop: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
    });


    const handleInput2Change = (e, category, person) => {
        const { value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [`${person}${category}`]: value,
        }));
    };



    const getFormData = async () => {
        try {
            // const response = {}
            // if (response.success) {
            //     console.log("fomr1", response);
            //     setData(response.message)
            //     // setFamilyMembStd(response.message.familyMembersStudying)
            //     setAssetDetails({
            //         ...assetDetails,
            //         airCondition: response.message.HouseHoldAssets[0],
            //         car: response.message.HouseHoldAssets[1],
            //         motorCycle: response.message.HouseHoldAssets[2],
            //         television: response.message.HouseHoldAssets[3],
            //         computerLaptop: response.message.HouseHoldAssets[4]
            //     })
            // }


            const response = await getForm4()

            if (response.success) {
                console.log("@@@@", response.message);
                setData(response.message)

                setFamilyMembStd(response.message.FamilyStudying)
                setHouseDetail(response.message.houseHold)
                setTotalProperty(response.message.PropertyOwned)
                iseEmpDetail(response.message.EmploymentApplicable)
                setEmpDis(response.message.EmploymentApplicable)

            }

        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()
    }, [])


    const [houseDetail, setHouseDetail] = useState([
        {
            Item_Name: "",
            Quantity: "",
            Current_Value: "",
        },
    ]);

    const h_detail = {
        Item_Name: "",
        Quantity: "",
        Current_Value: "",
    }

    function House_Hold_Items(e) {
        e.preventDefault();
        setHouseDetail([...houseDetail, h_detail])
    }
    const [totalProperty, setTotalProperty] = useState([
        {
            valueOfAsset: "",
            father: "",
            mother: "",
            spouse: "",
            self: "",
            brotherSisterChildren: "",
            total: "",
        },
    ]);

    const t_Property = {
        valueOfAsset: "",
        father: "",
        mother: "",
        spouse: "",
        self: "",
        brotherSisterChildren: "",
        total: "",
    }

    function Total_Property(e) {
        e.preventDefault();
        setTotalProperty([...totalProperty, t_Property])
    }

    // function removeTPDetail(i) {
    //     const updated = [...totalProperty]
    //     updated.splice(i, 1)
    //     setTotalProperty(updated)
    // }

    function removeHouseDetail(i) {
        const updated = [...houseDetail]
        updated.splice(i, 1)
        setHouseDetail(updated)
    }

    const handleChangeRadio = (name, value) => {
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleInputChange = (index, fieldName, value) => {
        const updatedHouseDetail = [...houseDetail];
        updatedHouseDetail[index][fieldName] = value;
        setHouseDetail(updatedHouseDetail);
    };



    const renderFormControl = (label, type, value, onChange, disabled = false) => (
        <div className={`col-md-2 ${type === 'number' ? 'number-input' : ''}`}>
            <Form.Group className="form-group">
                <label className='form-label'>{label}</label>
                <Form.Control
                    type={type}
                    placeholder='Enter here'
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
                <Form.Control.Feedback type="invalid">Please Enter Valid Value.</Form.Control.Feedback>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
        </div>
    );


    const handleInput3Change = (e, index) => {
        const { name, value } = e.target;
        const updatedFamilyMembStd = [...familyMembStd];
        updatedFamilyMembStd[index][name] = value;
        setFamilyMembStd(updatedFamilyMembStd);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();


        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.stopPropagation();
        // }


        const payload = {
            ...data,
            houseHold: houseDetail,
            PropertyOwned: totalProperty,
            FamilyStudying: familyMembStd,
            EmploymentApplicable: empDis
        }


        console.log("PPAAYYLLOOAADD", payload);
        const response = await SubmissionForm4(payload)
        alert(response.message)


        // setValidated(true);
        // navigate(`#${"academic_background"}`)
    };

    const calculateGrandTotal = () => {
        const grandTotal = totalProperty.reduce((acc, item) => {
            const totalValue = parseFloat(item.total);
            return isNaN(totalValue) ? acc : acc + totalValue;
        }, 0);
        // return grandTotal;
        setData({ ...data, total: grandTotal })
    };


    const calculateTotal = () => {
        const updatedTotalProperty = totalProperty.map((item) => {
            const total = Object.values(item)
                .filter((value) => typeof value === 'string' && !isNaN(parseFloat(value)))
                .reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);

            return { ...item, total };
        });

        setTotalProperty(updatedTotalProperty);
        calculateGrandTotal()
    };


    const handleTPInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedTotalProperty = [...totalProperty];
        updatedTotalProperty[index][name] = value;
        setTotalProperty(updatedTotalProperty);
        // Call calculateTotal after updating the input value
        calculateTotal();
    };


    const removeTPDetail = (index) => {
        const updatedTotalProperty = [...totalProperty];
        updatedTotalProperty.splice(index, 1);
        setTotalProperty(updatedTotalProperty);
    };



    const handleJobStatus = (e) => {
        try {
            setJobStatus(e.target.value)
            setData({ ...data, EmployeeStatus: e.target.value })
        }
        catch (e) {
            console.log(e);
        }
    }

    const sethandleSponsor = (e) => {
        try {
            setAskForSponsor(e.target.value)
            setData({ ...data, orgSponsor: e.target.value })
        }
        catch (e) {
            console.log(e);
        }
    }


    return (
        <>
            <div className="">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="preview_heading">
                        <h5>Additional Information</h5>
                    </div>

                    <div className="accordion accordion-flush mb-4" id="accordionFlushExample">

                        {/* Employment Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading333">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse333" aria-expanded="false" aria-controls="flush-collapse333">
                                    <img src={EMP_DETAIL} alt="icon" className='img-fluid form_icons' />
                                    Employment Details
                                </button>
                            </h2>
                            <div id="flush-collapse333" className="accordion-collapse collapse show" aria-labelledby="flush-heading333" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    {/* <div className="form-check text-center">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={empDetail} onChange={(e) => handleCheckBoxEmpDetail(e)} />
                                        <label className="form-check-label" for="flexCheckDefault" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                            Not Applicable For Me
                                        </label>
                                    </div> */}

                                    <Form.Group id='EmployeeStatus' className='form-group'>
                                        <Form.Label>Are you presently employed / working somewhere?</Form.Label>
                                        <Form.Control
                                            type='text'
                                            disabled
                                            placeholder=''
                                            value={empDetail}
                                            onChange={(e) => iseEmpDetail(e.target.value)}
                                        />
                                    </Form.Group>

                                    {
                                        empDetail === "yes" &&
                                        <div className="row">
                                            <div className="col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Name of the Organization <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="EmployeeOrg"
                                                        value={data.EmployeeOrg}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Designation <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="EmployeeDesig"
                                                        value={data.EmployeeDesig}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Experience in years <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="EmployeeExp"
                                                        value={data.EmployeeExp}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Salary per month during the program (Rs.) <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="EmployeeSalary"
                                                        value={formatter(data.EmployeeSalary)}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Other benefits <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="OtherBenefits"
                                                        value={data.OtherBenefits}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-4 col-sm-12 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Status of the job during the Program</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data.EmployeeStatus}
                                                        onChange={(e) => handleJobStatus(e)}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Date of resignation <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="ResignDate"
                                                        value={data.ResignDate ? new Date(data.ResignDate).toISOString().split('T')[0] : ''}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Commutation received <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="CommutationDate"
                                                        value={data.CommutationDate ? new Date(data.CommutationDate).toISOString().split('T')[0] : ''}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group controlId="validationCustom05" className='form-group'>
                                                    <Form.Label>Did you ask the organization to sponsor your educational expense for this program?</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={data.orgSponsor}
                                                        onChange={(e) => sethandleSponsor(e)}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group className='form-group'>
                                                    <Form.Label>Outcome Amount (Rs). <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="OrgOutcomeAmount"
                                                        value={formatter(data.OrgOutcomeAmount)}
                                                        onChange={handleChange}
                                                        disabled
                                                        required
                                                    />
                                                </Form.Group>
                                            </div>

                                            {/* ... Repeat the pattern for more input fields ... */}

                                            <div className="col-12 vertically_center">
                                                <FileViewer
                                                    text="Employment Certificate"
                                                    span="*"
                                                    url={newURL}
                                                    keyName={"employemnetIncomeCertificate"}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Expenditure */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <img src={EXPENDIRURE} alt="icon" className='img-fluid form_icons' />
                                    Expenditure
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <>
                                        {/* Family Members Studying */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='mb-0'>Details of Family Members who are studying </h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' disabled onClick={(e) => Add_New_detail(e)}>Add More</button>
                                                </div>
                                            </div>

                                            {familyMembStd.map((data, i) => {
                                                return <>
                                                    {
                                                        i > 0 &&
                                                        <div className="section_break"></div>
                                                    }
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Name"
                                                                    value={data.Name}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Relationship</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Relationship"
                                                                    value={data.Relationship}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Name of Institution (if Studying)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Name_of_Institution"
                                                                    value={data.Name_of_Institution}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Fee per Month (Rs.)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Fee_per_Month"
                                                                    value={formatter(data.Fee_per_Month)}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Private Tuitions Fee</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Private_Tuitions_Fee"
                                                                    value={formatter(data.Private_Tuitions_Fee)}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Boarding and Lodging</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Boarding_and_Lodging"
                                                                    value={formatter(data.Boarding_and_Lodging)}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Stationary Expenditure</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="Stationary_Expenditure"
                                                                    value={formatter(data.Stationary_Expenditure)}
                                                                    onChange={(e) => handleInput3Change(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>

                                                        {/* <div className="d-flex jc-end">
                                                            <button onClick={() => removeDetail(i)} className="btn delete_btn">
                                                                Remove Detail
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </>
                                            })
                                            }
                                        </div>

                                        {/* House Details */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>House Details</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 vertically_center">
                                                    <Form.Group className='form-group'>
                                                        <div className="d-flex">
                                                            <Form.Label className='mb-0'>Do you own or rented house <span className='required'>*</span></Form.Label>
                                                            <Form.Check
                                                                type="radio"
                                                                name="houseDetial"
                                                                id="owned"
                                                                value="Owned"
                                                                checked={data.houseDetial === "Owned"}
                                                                onChange={() => handleChangeRadio('houseDetial', 'Owned')}
                                                                label="Owned"
                                                                className='ml-3'
                                                                disabled
                                                            />
                                                            <Form.Check
                                                                type="radio"
                                                                name="houseDetial"
                                                                id="rental"
                                                                value="Rental"
                                                                checked={data.houseDetial === "Rental"}
                                                                onChange={() => handleChangeRadio('houseDetial', 'Rental')}
                                                                label="Rental"
                                                                className='ml-3'
                                                                disabled
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {(data.houseDetial === "Owned" || data.houseDetial === "Rental") &&
                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                        <Form.Group className='form-group'>
                                                            <label className='form-label'>Size of the House (yards) <span className='required'>*</span></label>
                                                            <Form.Control
                                                                type="text"
                                                                value={data.houseSize}
                                                                onChange={handleChange}
                                                                name="houseSize"
                                                                disabled
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                }
                                                {data.houseDetial === "Rental" &&
                                                    <>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className='form-group'>
                                                                <label className='form-label'>Monthly Rent (Rs.) <span className='required'>*</span></label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formatter(data.houseMonthlyRent)}
                                                                    onChange={handleChange}
                                                                    name="houseRent"
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className='form-group'>
                                                                <label className='form-label'>Deposit/Advance (Rs.) <span className='required'>*</span></label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formatter(data.houseDeposit)}
                                                                    onChange={handleChange}
                                                                    name="houseAdvance"
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                            <Form.Group className='form-group'>
                                                                <label className='form-label'>Yearly Expenditure <span className='required'>*</span></label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formatter(data.houseYearlyExpenditure)}
                                                                    onChange={handleChange}
                                                                    name="houseYearlyExpense"
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-12 vertically_center">
                                                            <FileViewer
                                                                text="Copy of Rent / Tenancy Agreement (if Applicable)"
                                                                url={RentAgreementUrl}
                                                                keyName={"RentAgreement"}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>

                                        </div>

                                        {/* Utility Bills */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Last Month Utility Expenditure (Rs.)</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Electricity Bill <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data[`electricityMonth`])}
                                                            onChange={(e) => handleChange(e)}
                                                            name="electricityMonth"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Gas Bill <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data[`gasMonth`])}
                                                            onChange={(e) => handleChange(e)}
                                                            name="gasMonth"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Telephone Bill</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data[`telephoneMonth`])}
                                                            onChange={(e) => handleChange(e)}
                                                            name="telephoneMonth"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Water Bill</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data[`waterMonth`])}
                                                            onChange={(e) => handleChange(e)}
                                                            name="waterMonth"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data[`totalMonth`])}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalMonth"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <FileViewer
                                                        text="Electricity Bill"
                                                        span="*"
                                                        url={BillUrl}
                                                        keyName={"ElectricBill"}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <FileViewer
                                                        text="Gas Bill"
                                                        span="*"
                                                        url={BillUrl}
                                                        keyName={"GasBill"}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <FileViewer
                                                        text="Telephone Bill"
                                                        url={BillUrl}
                                                        keyName={"TelephoneBill"}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <FileViewer
                                                        text="Water Bill"
                                                        url={BillUrl}
                                                        keyName={"waterBill"}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        {/* Other Expenditure */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Other Expenditure (if any):</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Legal Expenditure</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.LegalExpenditure)}
                                                            onChange={handleChange}
                                                            name="LegalExpenditure"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Loan Repayment (please specify)</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.LoanRepayment)}
                                                            onChange={handleChange}
                                                            name="LoanRepayment"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Donation</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.donation)}
                                                            onChange={handleChange}
                                                            name="donation"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Other</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.insurancePayment)}
                                                            onChange={handleChange}
                                                            name="insurancePayment"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Total Family Expenditure */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Total Family Expenditure – Monthly (Rs.)</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Education Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.TotalEducationExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalEducationExpenditure" // Add the name attribute for identification
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Rental Expenditure</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.TotalRentalExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalRentalExpenditure"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Transportation Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.TotalTransportationExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalTransportationExpenditure"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Kitchen Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.TotalRationExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalRationExpenditure"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Medical Expenditure (if any)</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.TotalMedicalExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalMedicalExpenditure"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Utilities Expenditure (3 months) <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.TotalUtilitiesExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalUtilitiesExpenditure"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group id='OtherExpenditure' className="form-group">
                                                        <label className='form-label'>Other Expenditure</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.OtherExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="OtherExpenditure"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Expenditure</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.TotalOtherExpenditure)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="TotalOtherExpenditure"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Disposable Income */}
                                        <div className="table_form mb-4">
                                            <div className='form_sub_heading mb-4'>
                                                <h5 className='my-2'>Disposable Income</h5>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Monthly Income <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.totalIncomeMonth)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalIncomeMonth" // Add the name attribute for identification
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Yearly Income</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.totalIncomeYear)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalIncomeYear"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Monthly Expenditure <span className='required'>*</span></label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.totalExpenditureMonth)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="totalExpenditureMonth"
                                                            disabled
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Total Yearly Expenditure</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.totalExpenditureYear)}
                                                            // onChange={(e) => handleChange(e)}
                                                            name="totalExpenditureYear"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Net Monthly Disposable Income</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.netDispossibleIncomeMonth)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="netDispossibleIncomeMonth"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Net Annual Disposable Income</label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formatter(data.netDispossibleIncomeYear)}
                                                            onChange={(e) => handleChange(e)}
                                                            name="netDispossibleIncomeYear"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </div>
                                                {
                                                    parseFloat(data.netDispossibleIncomeYear) < 0 && (
                                                        <div className="col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>If the net annual disposable income is negative, please state the source you use to meet your needs <span className='required'>*</span></label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    value={data.question41}
                                                                    onChange={(e) => handleChange(e)}
                                                                    name="question41"
                                                                    rows={4}
                                                                    disabled
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>

                        {/* Asset / Property Details */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <img src={PROPERTY} alt="icon" className='img-fluid form_icons' />
                                    Asset / Property Details
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="row">

                                        {/* House_Hold_Items */}
                                        <div className="table_form mb-4">
                                            <div className='mb-4 form_sub_heading'>
                                                <h5 className='mb-0'>House Hold Items (please mention the current market value in Rs.)</h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' disabled onClick={(e) => House_Hold_Items(e)}>Add More</button>
                                                </div>
                                            </div>

                                            {
                                                houseDetail.map((item, i) => (
                                                    <React.Fragment key={i}>
                                                        {i > 0 && <div className="section_break"></div>}

                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group className="form-group">
                                                                    <label className='form-label'>Item Name</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={item.Item_Name}
                                                                        onChange={(e) => handleInputChange(i, 'Item_Name', e.target.value)}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </div>

                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                                <Form.Group className="form-group">
                                                                    <label className='form-label'>Quantity</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={item.Quantity}
                                                                        onChange={(e) => handleInputChange(i, 'Quantity', e.target.value)}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </div>

                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                                                <Form.Group className="form-group">
                                                                    <label className='form-label'>Current Value (Rs.)</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={formatter(item.Current_Value)}
                                                                        onChange={(e) => handleInputChange(i, 'Current_Value', e.target.value)}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </div>

                                                            {/* {i > 0 && (
                                                                <div className='d-flex jc-end'>
                                                                    <button onClick={() => removeHouseDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                                </div>
                                                            )} */}
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            }


                                        </div>

                                        {/* Total_Property */}
                                        <div className="table_form mb-4">
                                            <div className='mb-4 form_sub_heading'>
                                                <h5 className='mb-0'>Total Value of Property Owned (Rs.)</h5>
                                                <div className='d-flex jc-end'>
                                                    <button className='btn add_btn' disabled onClick={(e) => Total_Property(e)}>Add More</button>
                                                </div>
                                            </div>

                                            {totalProperty.map((item, i) => (
                                                <div key={i}>
                                                    {i > 0 && <div className="section_break"></div>}

                                                    <div className="row">
                                                        <div className="col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>Name of Asset</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="valueOfAsset"
                                                                    value={item.valueOfAsset}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>Father</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="father"
                                                                    value={formatter(item.father)}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>Mother</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="mother"
                                                                    value={formatter(item.mother)}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>Spouse</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="spouse"
                                                                    value={formatter(item.spouse)}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>Self</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="self"
                                                                    value={formatter(item.self)}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>Brother/Sister/Children</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="brotherSisterChildren"
                                                                    value={formatter(item.brotherSisterChildren)}
                                                                    onChange={(e) => handleTPInputChange(e, i)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                                            <Form.Group className="form-group">
                                                                <label className='form-label'>Total</label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formatter(item.total)}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </div>

                                                        {/* {i > 0 && (
                                                            <div className='d-flex jc-end'>
                                                                <button onClick={() => removeTPDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                            </div>
                                                        )} */}
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row">
                                                <div className="col-12 vertically_center">
                                                    <Form.Group className="form-group">
                                                        <label className='form-label'>Asset's Total</label>
                                                        <Form.Control type="text" value={formatter(data.total)} disabled />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
            </div>
        </>
    )
}

export default Additional_InformationViewOnly
