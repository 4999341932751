import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import Tabs from '../../components/tabs';
import { useMediaQuery } from 'react-responsive';
import CasesHistory from '../AdminPortal/CasesHistory';

function UniFreshCaseDetails() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const [tabsD, setTabs] = useState([
        {
            tabName: "Case Detail",
            pathName: ""
        },
    ])

    const tabs = [
        {
            tabName: "Case Detail",
            pathName: "#cases-detail"
        },
        {
            tabName: "Interview Sheet",
            pathName: "#interview-sheet"
        },
        {
            tabName: "Recommendation Form",
            pathName: "#recomendation-form"
        },
        {
            tabName: "Approval Form",
            pathName: "#approval-form"
        },
    ]

    const Mob_tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "Education",
            pathName: "#education-details"
        },
        {
            tabName: "Password",
            pathName: "#password"
        }
    ]

    const navigate = useNavigate();
    const { hash } = useLocation();
    const [show, setShow] = useState("");


    useEffect(() => {
        setShow(hash)
    }, [hash])


    return (
        <>
            <Helmet>
                <title>Cases History</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />


                    <section className='cases_details_section'>

                        <div className='d-flex ai-center mb-4'>
                            <Link to="/fresh-cases-report">
                                <IoArrowBack className='back_arrow_icon me-3' />
                            </Link>
                            <h2 className='mb-0'>Fresh Case Detail</h2>
                        </div>

                        <div className='card mb-4'>

                            <Tabs tabLinks={isMobile ? Mob_tabs : tabsD} show={show} setShow={setShow} />

                            {
                                (show == "#cases-detail" || show == "") &&
                                <CasesHistory />
                            }

                        </div>
                    </section>


                </div>
            </div>
        </>
    )
}

export default UniFreshCaseDetails
