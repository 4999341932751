import React, { useEffect, useState } from 'react'
import { IoDocumentTextOutline } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';

import { IoIosArrowForward } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { PiStudent } from "react-icons/pi";
import { AiOutlineFileAdd } from "react-icons/ai";
import { MdPreview } from "react-icons/md";
import { MdDomainVerification } from "react-icons/md";
import { FaCheck, FaChevronRight } from 'react-icons/fa';
import FormProgress from './FormProgress';
// import getTab from "../../../ContextAPI/APIs/api"

import { getTab } from '../../../ContextAPI/APIs/api';
import { useAuth } from '../../../ContextAPI/Components/auth';
import Preview from '../../../components/ApplicationForm/Preview';

function FormTabs(props) {

    const [isDemoInoComplete, setIsDemoInoComplete] = useState("incomplete")
    const [isAcdBgComplete, setIsAcdBgComplete] = useState("incomplete")
    const [isFinInfoComplete, setIsFinInfoComplete] = useState("incomplete")
    const [isAddInfoComplete, setIsAddInfoComplete] = useState("incomplete")
    const [isSopComplete, setIsSopComplete] = useState("incomplete")
    const [isPreviewComplete, setIsPreviewComplete] = useState("incomplete")
    const { user, tabData } = useAuth()
    const navigate = useNavigate()

    const getTABDATA = async () => {

        try {
            const response = await getTab()
            console.log("RESPONSE TAB", response);

            if (response.message.form1 && response.message.form1 !== "") {
                setIsDemoInoComplete("complete")
            }
            else {
                setIsDemoInoComplete("incomplete")
            }
            if (response.message.form2 && response.message.form2 !== "") {
                setIsAcdBgComplete("complete")
            }
            else {
                setIsAcdBgComplete("incomplete")
            }
            if (response.message.form3 && response.message.form3 !== "") {
                // setIsDemoInoComplete("complete")
                setIsFinInfoComplete("complete")
            } else {
                // setIsDemoInoComplete("incomplete")
            }

            if (response.message.form4 && response.message.form4 !== "") {
                // setIsDemoInoComplete("complete")
                setIsAddInfoComplete("complete")
            } else {
                // setIsDemoInoComplete("incomplete")
            }

            if (response.message.form5 && response.message.form5 !== "") {
                setIsSopComplete("complete")
                // setIsDemoInoComplete("complete")
            } else {
                setIsSopComplete("incomplete")
            }

            // if (response.message.form1 !== null && response.message.form1 !== "") {
            //     setIsDemoInoComplete("complete")
            // }


        }
        catch (e) {
            console.log(e);
        }
    }



    const { hash } = useLocation();
    // const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");
    const [form, setForm] = useState({})

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);

    const Preview = () => {
        try {
            if (tabData.form1 && tabData.form2 && tabData.form3 && tabData.form4 && tabData.form5) {
                navigate(`#${"preview"}`)
                // alert("hello")
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const Final = () => {
        try {
            if (tabData.form1 && tabData.form2 && tabData.form3 && tabData.form4 && tabData.form5) {
                // navigate(`#${"preview"}`)
                navigate(`#${"final"}`)
                setIsPreviewComplete("complete")

                // alert("hello")
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        // getTABDATA()
        // tabData()
        console.log("DDD", tabData);

    }, [])



    return (
        <>
            <div className='progress_tabs mb-4'>
                <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">

                    {/* Demographics Information */}
                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => navigate(`#${"demographics_information"}`)}>
                            <div className="timeline-content">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            tabData?.form1 ?
                                                <ImProfile className={`timeline_icon ${(showForm == "demographics_information" ? "formActivrTabColor" : "formCompleteTabColor")}`} />
                                                :
                                                <ImProfile className={`timeline_icon ${(showForm == "demographics_information" && "formActivrTabColor")}`} />
                                        }
                                    </div>
                                    {
                                        tabData?.form1 &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        tabData?.form1 ?
                                            <p className={`mb-0 ${(showForm == "demographics_information" ? "formActivrTabColor" : "formCompleteTabColor")}`}>1. Demographics Information</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "demographics_information" && "formActivrTabColor"}`}>1. Demographics Information</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${tabData?.form1 ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className=''>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    {/* Academic Background */}
                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => { navigate(`#${"academic_background"}`) }}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            tabData?.form2 ?
                                                <PiStudent className={`timeline_icon ${(showForm == "academic_background" ? "formActivrTabColor" : "formCompleteTabColor")}`} />
                                                :
                                                <PiStudent className={`timeline_icon ${showForm == "academic_background" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        tabData?.form2 &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        tabData?.form2 ?
                                            <p className={`mb-0 ${(showForm == "academic_background" ? "formActivrTabColor" : "formCompleteTabColor")}`}>2. Academic Background</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "academic_background" && "formActivrTabColor"}`}>2. Academic Background</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${tabData?.form2 ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className=''>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    {/* Financial Information */}
                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => { navigate(`#${"financial_information"}`) }}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            tabData?.form3 ?
                                                <AiOutlineFileAdd className={`timeline_icon ${(showForm == "financial_information" ? "formActivrTabColor" : "formCompleteTabColor")}`} />
                                                :
                                                <AiOutlineFileAdd className={`timeline_icon ${showForm == "financial_information" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        tabData?.form3 &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        tabData?.form3 ?
                                            <p className={`mb-0 ${(showForm == "financial_information" ? "formActivrTabColor" : "formCompleteTabColor")}`}>3. Financial Information</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "financial_information" && "formActivrTabColor"}`}>3. Financial Information</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${tabData?.form3 ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className=''>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    {/* Additional Information */}
                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => { navigate(`#${"additional_information"}`) }}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            tabData?.form4 ?
                                                <AiOutlineFileAdd className={`timeline_icon ${(showForm == "additional_information" ? "formActivrTabColor" : "formCompleteTabColor")}`} />
                                                :
                                                <AiOutlineFileAdd className={`timeline_icon ${showForm == "additional_information" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        tabData?.form4 &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        tabData?.form4 ?
                                            <p className={`mb-0 ${(showForm == "additional_information" ? "formActivrTabColor" : "formCompleteTabColor")}`}>4. Additional Information</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "additional_information" && "formActivrTabColor"}`}>4. Additional Information</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${tabData?.form4 ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className=''>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    {/* SOP's */}
                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => { navigate(`#${"sop"}`) }}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            tabData?.form5 ?
                                                <IoDocumentTextOutline className={`timeline_icon ${(showForm == "sop" ? "formActivrTabColor" : "formCompleteTabColor")}`} />
                                                :
                                                <IoDocumentTextOutline className={`timeline_icon ${showForm == "sop" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        tabData?.form5 &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        tabData?.form5 ?
                                            <p className={`mb-0 ${(showForm == "sop" ? "formActivrTabColor" : "formCompleteTabColor")}`}>5. SOP's</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "sop" && "formActivrTabColor"}`}>5. SOP's</p>
                                    }
                                </div>
                            </div>
                            <div className="progress">
                                <div className={`${tabData?.form5 ? "progress-bar-complete" : "progress-bar"}`} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className=''>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    {/* Preview */}
                    <div className='timeline-tab'>
                        <div className="timeline-step" onClick={() => Preview()}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                <div className='position-relative'>
                                    <div className="inner-circle">
                                        {
                                            (tabData?.form1 && tabData?.form2 && tabData?.form3 && tabData?.form4 && tabData?.form5 && props.showForm == "final") ?
                                                <MdPreview className={`timeline_icon ${(showForm == "preview" ? "formActivrTabColor" : "formCompleteTabColor")}`} />
                                                :
                                                <MdPreview className={`timeline_icon ${showForm == "preview" && "formActivrTabColor"}`} />
                                        }
                                    </div>
                                    {
                                        (tabData?.form1 && tabData?.form2 && tabData?.form3 && tabData?.form4 && tabData?.form5 && props.showForm == "final") &&
                                        <FaCheck className='completion_tick' />
                                    }
                                    {
                                        (tabData?.form1 && tabData?.form2 && tabData?.form3 && tabData?.form4 && tabData?.form5 && props.showForm == "final") ?
                                            <p className={`mb-0 ${(showForm == "preview" ? "formActivrTabColor" : "formCompleteTabColor")}`}>6. preview</p>
                                            :
                                            <p className={`text-muted mb-0 ${showForm == "preview" && "formActivrTabColor"}`}>6. preview</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <FaChevronRight className='tab_arrow_icon' />
                        </div>
                    </div>

                    <div className='timeline-tab'>
                        <div className="timeline-step mb-0" onClick={() => Final()}>
                            <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                <div className="position-relative">
                                    <div className="inner-circle">
                                        <MdDomainVerification className={`timeline_icon ${showForm == "final" && "formActivrTabColor"}`} />
                                    </div>
                                    <p className={`text-muted mb-0 ${showForm == "final" && "formActivrTabColor"}`}>7. Final</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                            <div>
                                <div className="inner-circle">
                                    <MdPreview className={`timeline_icon ${showForm == "step_66" && "formActivrTabColor"}`} />
                                </div>
                                <p className="h6 text-muted mb-0 mb-lg-0">5. Preview</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex ai-center'>
                        <FaChevronRight className='tab_arrow_icon' />
                    </div> */}
                </div>

                {/* <FormProgress /> */}

            </div>
        </>
    )
}

export default FormTabs
