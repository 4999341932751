import { API_URL } from "../config"

export const profilePicUrl = `${API_URL}/static/files/ProfilePics`;



export const academicCertficatesUrl = `${API_URL}/static/files/academicCertficates`;
export const admissionLetterUrl = `${API_URL}/static/files/admissionLetter`;
export const BillUrl = `${API_URL}/static/files/Bill`;
export const childrenBformUrl = `${API_URL}/static/files/childrenBform`;
export const CNICUrl = `${API_URL}/static/files/CNIC`;
export const DeathCertificateUrl = `${API_URL}/static/files/DeathCertificate`;
export const DomicileUrl = `${API_URL}/static/files/Domicile`;
export const FamilyUrl = `${API_URL}/static/files/Family`;
export const IncomeCertificateUrl = `${API_URL}/static/files/IncomeCertificate`;
export const passimageUrl = `${API_URL}/static/files/passimage`;
export const RenewalUrl = `${API_URL}/static/files/Renewal`;
export const RentAgreementUrl = `${API_URL}/static/files/RentAgreement`;
export const resumeUrl = `${API_URL}/static/files/resume`;
export const vouchersUrl = `${API_URL}/static/files/vouchers`;
export const newURL = `${API_URL}/static/files/NEW`;
export const slipURL = `${API_URL}/static/files/slips`;



export const UserTypes = [
    // { value: "admin", label: "Admin" },
    { value: "university", label: "University" },
    { value: "viewer", label: "Viewer" },
    { value: "repayment", label: "Repayment Manager" },
    { value: "finance", label: "Finance Manager" },
    { value: "operations", label: "Operational Manager" },
    // { value: "recovery manager", label: "Recovery Manager" },
    // { value: "disbustment manager", label: "Disbustment Manager" },
]

export const gender = [{ label: "Select your Gender", value: null }, { label: "Male", value: "male" }, { label: "Female", value: "female" }]

export const states = ["Sindh", "Punjab", "Khyber Pakhtunkhwa", "Balochistan", "Gilgit Baltistan"]

export const cities = ["islamabad", "karachi", "lahore", "peshawar", "quetta"]

export const countries = ["Afghanistan", "Australia", "Azerbaijan", "Bahrain", "Bangladesh"]

export const UniFields = ["engineering", "medical", "commerce"]

export const actions = ["inprocess", "approve", "interview", "disburse", "reject"]

export const defaultFields = ["one", "two", "three"]

export const status = ["pending", "resubmit", "interview", "approved", "reject", "disburse"]

export const years = [1, 2, 3, 4, 5]

// export const semesters = ["1st","2nd","3rd","4th","5th","6th","7th","8th","9th","10th"]
export const semesters = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const childrens = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]


export function capitalizeFirstLetter(inputString) {
    // Check if the inputString is not empty
    if (inputString && typeof inputString === 'string') {
        // Capitalize the first letter and concatenate the rest of the string
        return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    } else {
        // Handle the case where the input is not a valid string
        return "Invalid input";
    }
}






export const CitiesPk = [
    { city: 'Ahmadpur East', state: 'Punjab' },
    { city: 'Ahmed Nager Chatha', state: 'Punjab' },
    { city: 'Ali Khan Abad', state: 'Punjab' },
    { city: 'Alipur', state: 'Punjab' },
    { city: 'Arifwala', state: 'Punjab' },
    { city: 'Attock', state: 'Punjab' },
    { city: 'Bhera', state: 'Punjab' },
    { city: 'Bhalwal', state: 'Punjab' },
    { city: 'Bahawalnagar', state: 'Punjab' },
    { city: 'Bahawalpur', state: 'Punjab' },
    { city: 'Bhakkar', state: 'Punjab' },
    { city: 'Burewala', state: 'Punjab' },
    { city: 'Chillianwala', state: 'Punjab' },
    { city: 'Chakwal', state: 'Punjab' },
    { city: 'Chichawatni', state: 'Punjab' },
    { city: 'Chiniot', state: 'Punjab' },
    { city: 'Chishtian', state: 'Punjab' },
    { city: 'Daska', state: 'Punjab' },
    { city: 'Darya Khan', state: 'Punjab' },
    { city: 'Dera Ghazi Khan', state: 'Punjab' },
    { city: 'Dhaular', state: 'Punjab' },
    { city: 'Dina', state: 'Punjab' },
    { city: 'Dinga', state: 'Punjab' },
    { city: 'Dipalpur', state: 'Punjab' },
    { city: 'Faisalabad', state: 'Punjab' },
    { city: 'Fateh Jang', state: 'Punjab' },
    { city: 'Ghakhar Mandi', state: 'Punjab' },
    { city: 'Gojra', state: 'Punjab' },
    { city: 'Gujranwala', state: 'Punjab' },
    { city: 'Gujrat', state: 'Punjab' },
    { city: 'Gujar Khan', state: 'Punjab' },
    { city: 'Hafizabad', state: 'Punjab' },
    { city: 'Haroonabad', state: 'Punjab' },
    { city: 'Hasilpur', state: 'Punjab' },
    { city: 'Haveli Lakha', state: 'Punjab' },
    { city: 'Jalalpur Jattan', state: 'Punjab' },
    { city: 'Jampur', state: 'Punjab' },
    { city: 'Jaranwala', state: 'Punjab' },
    { city: 'Jhang', state: 'Punjab' },
    { city: 'Jhelum', state: 'Punjab' },
    { city: 'Kalabagh', state: 'Punjab' },
    { city: 'Karor Lal Esan', state: 'Punjab' },
    { city: 'Kasur', state: 'Punjab' },
    { city: 'Kamalia', state: 'Punjab' },
    { city: 'Kāmoke', state: 'Punjab' },
    { city: 'Khanewal', state: 'Punjab' },
    { city: 'Khanpur', state: 'Punjab' },
    { city: 'Kharian', state: 'Punjab' },
    { city: 'Khushab', state: 'Punjab' },
    { city: 'Kot Adu', state: 'Punjab' },
    { city: 'Jauharabad', state: 'Punjab' },
    { city: 'Lahore', state: 'Punjab' },
    { city: 'Islamabad', state: 'Punjab' },
    { city: 'Lalamusa', state: 'Punjab' },
    { city: 'Layyah', state: 'Punjab' },
    { city: 'Liaquat Pur', state: 'Punjab' },
    { city: 'Lodhran', state: 'Punjab' },
    { city: 'Malakwal', state: 'Punjab' },
    { city: 'Mamoori', state: 'Punjab' },
    { city: 'Mailsi', state: 'Punjab' },
    { city: 'Mandi Bahauddin', state: 'Punjab' },
    { city: 'Mian Channu', state: 'Punjab' },
    { city: 'Mianwali', state: 'Punjab' },
    { city: 'Multan', state: 'Punjab' },
    { city: 'Murree', state: 'Punjab' },
    { city: 'Muridke', state: 'Punjab' },
    { city: 'Mianwali Bangla', state: 'Punjab' },
    { city: 'Muzaffargarh', state: 'Punjab' },
    { city: 'Narowal', state: 'Punjab' },
    { city: 'Okara', state: 'Punjab' },
    { city: 'Renala Khurd', state: 'Punjab' },
    { city: 'Pakpattan', state: 'Punjab' },
    { city: 'Pattoki', state: 'Punjab' },
    { city: 'Pir Mahal', state: 'Punjab' },
    { city: 'Qaimpur', state: 'Punjab' },
    { city: 'Qila Didar Singh', state: 'Punjab' },
    { city: 'Rabwah', state: 'Punjab' },
    { city: 'Raiwind', state: 'Punjab' },
    { city: 'Rajanpur', state: 'Punjab' },
    { city: 'Rahim Yar Khan', state: 'Punjab' },
    { city: 'Rawalpindi', state: 'Punjab' },
    { city: 'Sadiqabad', state: 'Punjab' },
    { city: 'Safdarabad', state: 'Punjab' },
    { city: 'Sahiwal', state: 'Punjab' },
    { city: 'Sangla Hill', state: 'Punjab' },
    { city: 'Sarai Alamgir', state: 'Punjab' },
    { city: 'Sargodha', state: 'Punjab' },
    { city: 'Shakargarh', state: 'Punjab' },
    { city: 'Sheikhupura', state: 'Punjab' },
    { city: 'Sialkot', state: 'Punjab' },
    { city: 'Sohawa', state: 'Punjab' },
    { city: 'Soianwala', state: 'Punjab' },
    { city: 'Siranwali', state: 'Punjab' },
    { city: 'Talagang', state: 'Punjab' },
    { city: 'Taxila', state: 'Punjab' },
    { city: 'Toba Tek Singh', state: 'Punjab' },
    { city: 'Vehari', state: 'Punjab' },
    { city: 'Wah Cantonment', state: 'Punjab' },
    { city: 'Wazirabad', state: 'Punjab' },
    { city: 'Badin', state: 'Sindh' },
    { city: 'Bhirkan', state: 'Sindh' },
    { city: 'Rajo Khanani', state: 'Sindh' },
    { city: 'Chak', state: 'Sindh' },
    { city: 'Dadu', state: 'Sindh' },
    { city: 'Digri', state: 'Sindh' },
    { city: 'Diplo', state: 'Sindh' },
    { city: 'Dokri', state: 'Sindh' },
    { city: 'Ghotki', state: 'Sindh' },
    { city: 'Haala', state: 'Sindh' },
    { city: 'Hyderabad', state: 'Sindh' },
    { city: 'Islamkot', state: 'Sindh' },
    { city: 'Jacobabad', state: 'Sindh' },
    { city: 'Jamshoro', state: 'Sindh' },
    { city: 'Jungshahi', state: 'Sindh' },
    { city: 'Kandhkot', state: 'Sindh' },
    { city: 'Kandiaro', state: 'Sindh' },
    { city: 'Karachi', state: 'Sindh' },
    { city: 'Kashmore', state: 'Sindh' },
    { city: 'Keti Bandar', state: 'Sindh' },
    { city: 'Khairpur', state: 'Sindh' },
    { city: 'Kotri', state: 'Sindh' },
    { city: 'Larkana', state: 'Sindh' },
    { city: 'Matiari', state: 'Sindh' },
    { city: 'Mehar', state: 'Sindh' },
    { city: 'Mirpur Khas', state: 'Sindh' },
    { city: 'Mithani', state: 'Sindh' },
    { city: 'Mithi', state: 'Sindh' },
    { city: 'Mehrabpur', state: 'Sindh' },
    { city: 'Moro', state: 'Sindh' },
    { city: 'Nagarparkar', state: 'Sindh' },
    { city: 'Naudero', state: 'Sindh' },
    { city: 'Naushahro Feroze', state: 'Sindh' },
    { city: 'Naushara', state: 'Sindh' },
    { city: 'Nawabshah', state: 'Sindh' },
    { city: 'Nazimabad', state: 'Sindh' },
    { city: 'Qambar', state: 'Sindh' },
    { city: 'Qasimabad', state: 'Sindh' },
    { city: 'Ranipur', state: 'Sindh' },
    { city: 'Ratodero', state: 'Sindh' },
    { city: 'Rohri', state: 'Sindh' },
    { city: 'Sakrand', state: 'Sindh' },
    { city: 'Sanghar', state: 'Sindh' },
    { city: 'Shahbandar', state: 'Sindh' },
    { city: 'Shahdadkot', state: 'Sindh' },
    { city: 'Shahdadpur', state: 'Sindh' },
    { city: 'Shahpur Chakar', state: 'Sindh' },
    { city: 'Shikarpaur', state: 'Sindh' },
    { city: 'Sukkur', state: 'Sindh' },
    { city: 'Tangwani', state: 'Sindh' },
    { city: 'Tando Adam Khan', state: 'Sindh' },
    { city: 'Tando Allahyar', state: 'Sindh' },
    { city: 'Tando Muhammad Khan', state: 'Sindh' },
    { city: 'Thatta', state: 'Sindh' },
    { city: 'Umerkot', state: 'Sindh' },
    { city: 'Warah', state: 'Sindh' },
    { city: 'Quetta', state: 'Balochistan' },
    { city: 'Khuzdar', state: 'Balochistan' },
    { city: 'Turbat', state: 'Balochistan' },
    { city: 'Chaman', state: 'Balochistan' },
    { city: 'HUb', state: 'Balochistan' },
    { city: 'Sibi', state: 'Balochistan' },
    { city: 'Zhob', state: 'Balochistan' },
    { city: 'Gawadar', state: 'Balochistan' },
    { city: 'Dera Murad Jamali', state: 'Balochistan' },
    { city: 'Dera Allah Yar', state: 'Balochistan' },
    { city: 'Usta Mohammad', state: 'Balochistan' },
    { city: 'Loralai', state: 'Balochistan' },
    { city: 'Pasni', state: 'Balochistan' },
    { city: 'Kharan', state: 'Balochistan' },
    { city: 'Mastung', state: 'Balochistan' },
    { city: 'Nushki', state: 'Balochistan' },
    { city: 'Kalat', state: 'Balochistan' },
    { city: 'Abbottabad', state: 'Khyber Pakhtunkhwa' },
    { city: 'Adezai', state: 'Khyber Pakhtunkhwa' },
    { city: 'Alpuri', state: 'Khyber Pakhtunkhwa' },
    { city: 'Akora Khattak', state: 'Khyber Pakhtunkhwa' },
    { city: 'Ayubia', state: 'Khyber Pakhtunkhwa' },
    { city: 'Banda Daud Shah', state: 'Khyber Pakhtunkhwa' },
    { city: 'Bannu', state: 'Khyber Pakhtunkhwa' },
    { city: 'Batkhela', state: 'Khyber Pakhtunkhwa' },
    { city: 'Battagram', state: 'Khyber Pakhtunkhwa' },
    { city: 'Birote', state: 'Khyber Pakhtunkhwa' },
    { city: 'Chakdara', state: 'Khyber Pakhtunkhwa' },
    { city: 'Charsadda', state: 'Khyber Pakhtunkhwa' },
    { city: 'Chitral', state: 'Khyber Pakhtunkhwa' },
    { city: 'Daggar', state: 'Khyber Pakhtunkhwa' },
    { city: 'Dargai', state: 'Khyber Pakhtunkhwa' },
    { city: 'Darya Khan', state: 'Khyber Pakhtunkhwa' },
    { city: 'Dera Ismail Khan', state: 'Khyber Pakhtunkhwa' },
    { city: 'Doaba', state: 'Khyber Pakhtunkhwa' },
    { city: 'Dir', state: 'Khyber Pakhtunkhwa' },
    { city: 'Drosh', state: 'Khyber Pakhtunkhwa' },
    { city: 'Hangu', state: 'Khyber Pakhtunkhwa' },
    { city: 'Haripur', state: 'Khyber Pakhtunkhwa' },
    { city: 'Karak', state: 'Khyber Pakhtunkhwa' },
    { city: 'Kohat', state: 'Khyber Pakhtunkhwa' },
    { city: 'Kulachi', state: 'Khyber Pakhtunkhwa' },
    { city: 'Lakki Marwat', state: 'Khyber Pakhtunkhwa' },
    { city: 'Latamber', state: 'Khyber Pakhtunkhwa' },
    { city: 'Madyan', state: 'Khyber Pakhtunkhwa' },
    { city: 'Mansehra', state: 'Khyber Pakhtunkhwa' },
    { city: 'Mardan', state: 'Khyber Pakhtunkhwa' },
    { city: 'Mastuj', state: 'Khyber Pakhtunkhwa' },
    { city: 'Mingora', state: 'Khyber Pakhtunkhwa' },
    { city: 'Nowshera', state: 'Khyber Pakhtunkhwa' },
    { city: 'Paharpur', state: 'Khyber Pakhtunkhwa' },
    { city: 'Pabbi', state: 'Khyber Pakhtunkhwa' },
    { city: 'Peshawar', state: 'Khyber Pakhtunkhwa' },
    { city: 'Saidu Sharif', state: 'Khyber Pakhtunkhwa' },
    { city: 'Shorkot', state: 'Khyber Pakhtunkhwa' },
    { city: 'Shewa Adda', state: 'Khyber Pakhtunkhwa' },
    { city: 'Swabi', state: 'Khyber Pakhtunkhwa' },
    { city: 'Swat', state: 'Khyber Pakhtunkhwa' },
    { city: 'Tangi', state: 'Khyber Pakhtunkhwa' },
    { city: 'Tank', state: 'Khyber Pakhtunkhwa' },
    { city: 'Thall', state: 'Khyber Pakhtunkhwa' },
    { city: 'Timergara', state: 'Khyber Pakhtunkhwa' },
    { city: 'Tordher', state: 'Khyber Pakhtunkhwa' },
    { city: 'Gilgit', state: 'Gilgit Baltistan' },
    { city: 'Skardu', state: 'Gilgit Baltistan' },
    { city: 'Khaplu', state: 'Gilgit Baltistan' },
    { city: 'Dambudas', state: 'Gilgit Baltistan' },
    { city: 'Tolti', state: 'Gilgit Baltistan' },
    { city: 'Eidgah', state: 'Gilgit Baltistan' },
    { city: 'Shigar', state: 'Gilgit Baltistan' },
    { city: 'Nagarkhas', state: 'Gilgit Baltistan' },
    { city: 'Ishkoman', state: 'Gilgit Baltistan' },
    { city: 'Juglot', state: 'Gilgit Baltistan' },
    { city: 'Danyor', state: 'Gilgit Baltistan' },
    { city: 'Karimabad', state: 'Gilgit Baltistan' },
    { city: 'Aliabad', state: 'Gilgit Baltistan' },
    { city: 'Chillas', state: 'Gilgit Baltistan' },
    { city: 'Gahkuch', state: 'Gilgit Baltistan' },
    { city: 'Tangir', state: 'Gilgit Baltistan' }
]



export const cityList = [
    'Ahmadpur East',
    'Ahmed Nager Chatha',
    'Ali Khan Abad',
    'Alipur',
    'Arifwala',
    'Attock',
    'Bhera',
    'Bhalwal',
    'Bahawalnagar',
    'Bahawalpur',
    'Bhakkar',
    'Burewala',
    'Chillianwala',
    'Chakwal',
    'Chichawatni',
    'Chiniot',
    'Chishtian',
    'Daska',
    'Darya Khan',
    'Dera Ghazi Khan',
    'Dhaular',
    'Dina',
    'Dinga',
    'Dipalpur',
    'Faisalabad',
    'Fateh Jang',
    'Ghakhar Mandi',
    'Gojra',
    'Gujranwala',
    'Gujrat',
    'Gujar Khan',
    'Hafizabad',
    'Haroonabad',
    'Hasilpur',
    'Haveli Lakha',
    'Jalalpur Jattan',
    'Jampur',
    'Jaranwala',
    'Jhang',
    'Jhelum',
    'Kalabagh',
    'Karor Lal Esan',
    'Kasur',
    'Kamalia',
    'Kāmoke',
    'Khanewal',
    'Khanpur',
    'Kharian',
    'Khushab',
    'Kot Adu',
    'Jauharabad',
    'Lahore',
    'Lalamusa',
    'Layyah',
    'Liaquat Pur',
    'Lodhran',
    'Malakwal',
    'Mamoori',
    'Mailsi',
    'Mandi Bahauddin',
    'Mian Channu',
    'Mianwali',
    'Multan',
    'Murree',
    'Muridke',
    'Mianwali Bangla',
    'Muzaffargarh',
    'Narowal',
    'Okara',
    'Renala Khurd',
    'Pakpattan',
    'Pattoki',
    'Pir Mahal',
    'Qaimpur',
    'Qila Didar Singh',
    'Rabwah',
    'Raiwind',
    'Rajanpur',
    'Rahim Yar Khan',
    'Rawalpindi',
    'Sadiqabad',
    'Safdarabad',
    'Sahiwal',
    'Sangla Hill',
    'Sarai Alamgir',
    'Sargodha',
    'Shakargarh',
    'Sheikhupura',
    'Sialkot',
    'Sohawa',
    'Soianwala',
    'Siranwali',
    'Talagang',
    'Taxila',
    'Toba Tek Singh',
    'Vehari',
    'Wah Cantonment',
    'Wazirabad',
    'Badin',
    'Bhirkan',
    'Rajo Khanani',
    'Chak',
    'Dadu',
    'Digri',
    'Diplo',
    'Dokri',
    'Ghotki',
    'Haala',
    'Hyderabad',
    'Islamkot',
    'Jacobabad',
    'Jamshoro',
    'Jungshahi',
    'Kandhkot',
    'Kandiaro',
    'Karachi',
    'Kashmore',
    'Keti Bandar',
    'Khairpur',
    'Kotri',
    'Larkana',
    'Matiari',
    'Mehar',
    'Mirpur Khas',
    'Mithani',
    'Mithi',
    'Mehrabpur',
    'Moro',
    'Nagarparkar',
    'Naudero',
    'Naushahro Feroze',
    'Naushara',
    'Nawabshah',
    'Nazimabad',
    'Qambar',
    'Qasimabad',
    'Ranipur',
    'Ratodero',
    'Rohri',
    'Sakrand',
    'Sanghar',
    'Shahbandar',
    'Shahdadkot',
    'Shahdadpur',
    'Shahpur Chakar',
    'Shikarpaur',
    'Sukkur',
    'Tangwani',
    'Tando Adam Khan',
    'Tando Allahyar',
    'Tando Muhammad Khan',
    'Thatta',
    'Umerkot',
    'Warah',
    'Quetta',
    'Khuzdar',
    'Turbat',
    'Chaman',
    'HUb',
    'Sibi',
    'Zhob',
    'Gawadar',
    'Dera Murad Jamali',
    'Dera Allah Yar',
    'Usta Mohammad',
    'Loralai',
    'Pasni',
    'Kharan',
    'Mastung',
    'Nushki',
    'Kalat',
    'Abbottabad',
    'Adezai',
    'Alpuri',
    'Akora Khattak',
    'Ayubia',
    'Banda Daud Shah',
    'Bannu',
    'Batkhela',
    'Battagram',
    'Birote',
    'Chakdara',
    'Charsadda',
    'Chitral',
    'Daggar',
    'Dargai',
    'Darya Khan',
    'Dera Ismail Khan',
    'Doaba',
    'Dir',
    'Drosh',
    'Hangu',
    'Haripur',
    'Karak',
    'Kohat',
    'Kulachi',
    'Lakki Marwat',
    'Latamber',
    'Madyan',
    'Mansehra',
    'Mardan',
    'Mastuj',
    'Mingora',
    'Nowshera',
    'Paharpur',
    'Pabbi',
    'Peshawar',
    'Saidu Sharif',
    'Shorkot',
    'Shewa Adda',
    'Swabi',
    'Swat',
    'Tangi',
    'Tank',
    'Thall',
    'Timergara',
    'Tordher',
    'Gilgit',
    'Skardu',
    'Khaplu',
    'Dambudas',
    'Tolti',
    'Eidgah',
    'Shigar',
    'Nagarkhas',
    'Ishkoman',
    'Juglot',
    'Danyor',
    'Karimabad',
    'Aliabad',
    'Chillas',
    'Gahkuch',
    'Tangir'
]











































