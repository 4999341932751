import { useState, useEffect } from "react";
import jsPDF from "jspdf";
import cloud_upload from "../../assets/cloud-upload.svg"
import { getrenewal, uploadBankStmt, uploadSalarySlip, uploadfeeLedgerRenewal, uploadLatestTranscript } from "../../ContextAPI/APIs/api";

import { FaRegFileLines } from "react-icons/fa6";
import { useToast } from "../../ContextAPI/Components/toast"
import { useAuth } from "../../ContextAPI/Components/auth";

function ImageUploadInputrenewal(props) {
    const { getRenewalTabs } = useAuth();
    const { alert } = useToast();
    const [Accetp, setAccept] = useState([])
    const [imageUrls, setImageUrls] = useState([]);

    const [param, setParam] = useState("")

    const [fileData, setFileData] = useState({})
    const [pdfFile, setPdfFile] = useState([])

    const addImage = (event) => {
        console.log("KEY", props);
        // console.log("KEY22", fileData[props.key]);


        console.log("nn", event.target.name);
        setParam(event.target.name)
        const files = event.target.files;
        setPdfFile(files)

        const urls = [];
        for (let i = 0; i < files.length; i++) {
            const url = URL.createObjectURL(files[i]);
            urls.push(url);
        }
        console.log("URL", urls);
        setImageUrls((prevImageUrls) => [...prevImageUrls, ...urls]);
        // generatePdf()

        // setShow(true)
    };


    const generatePdf = async () => {

        if (props.accept !== ".pdf") {
            const doc = new jsPDF();
            for (const url of imageUrls) {
                const img = new Image();
                img.src = url;
                await new Promise((resolve) => (img.onload = resolve));

                const imgWidth = 210;
                const imgHeight = (img.height * imgWidth) / img.width;
                doc.addImage(img, "JPEG", 0, 0, imgWidth, imgHeight);
                if (url !== imageUrls[imageUrls.length - 1]) {
                    doc.addPage();
                }
            }
            // setPdfDoc(doc);
            savePdf(doc)
        }
        else {
            console.log("--------------------");
            console.log(imageUrls[0]);
            console.log("--------------------");
            savePdf(imageUrls[0])
        }
    };


    const savePdf = async (pdfDoc) => {
        // console.log(pdfDoc);
        if (pdfDoc) {
            console.log("PATH", param);
            // pdfDoc.save("images.pdf");
            // const pdfBlob = pdfDoc.output("blob");

            let pdfBlob
            if (props.accept === ".pdf") {
                console.log("------", pdfFile[0]);
            }
            else {
                pdfBlob = pdfDoc.output("blob");
            }


            if (param == "bankSTMT") {
                const formData = new FormData();
                formData.append("bankStmt", pdfFile[0], "bankStmt.pdf");
                console.log("FD", formData);
                const response = await uploadBankStmt(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "salarySlip") {
                const formData = new FormData();
                formData.append("salarySlip", pdfFile[0], "salarySlip.pdf");
                console.log("FD", formData);
                const response = await uploadSalarySlip(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "feeLedger") {
                const formData = new FormData();
                formData.append("paidFeeVoucher", pdfFile[0], "paidFeeVoucher.pdf");
                console.log("FD", formData);
                const response = await uploadfeeLedgerRenewal(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            if (param == "transcript") {
                const formData = new FormData();
                formData.append("latestTranscript", pdfFile[0], "latestTranscript.pdf");
                console.log("FD", formData);
                const response = await uploadLatestTranscript(formData)
                if (response.success) {
                    alert(response.message, response.success)
                    setParam("")
                }
                else {
                    alert(response.message, response.success)
                }
            }
            getFormData()
            setImageUrls([])
            setParam("")
            getRenewalTabs()
        }
    };


    const getFormData = async () => {
        try {
            const response = await getrenewal()
            console.log("RESPONSE", response);
            if (response.success) {
                setFileData(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    function validateForm(files) {
        // // Get the input element
        // var fileInput = document.getElementById('fileUpload');

        // // Get the selected files
        // var files = fileInput.files;


        // Check if any file is selected
        if (files.length === 0) {
            alert("Please select at least one file.");
            return false;
        }

        // Accepted file types
        var acceptedTypes = props.accept || ['.jpg', '.jpeg', '.png', '.pdf', '.doc', '.docx'];

        // Check each selected file
        for (var i = 0; i < files.length; i++) {
            var file = files[i];

            // Get the file extension
            var fileExtension = '.' + file.name.split('.').pop().toLowerCase();

            // Check if the file type is accepted
            if (acceptedTypes.indexOf(fileExtension) === -1) {
                alert(`Invalid file type. Please upload files of type: ${formatAcceptData(props.accept)}`);
                return false;
            }
        }

        // If everything is valid, allow the form submission
        return true;
    }

    function formatAcceptData(acceptData) {
        const extensionMapping = {
            '.jpg': 'JPG',
            '.jpeg': 'JPEG',
            '.png': 'PNG',
            '.pdf': 'PDF',
            '.doc': 'DOC',
            '.docx': 'DOC',
            '.ppt': 'PPT',
            '.pptx': 'PPT'
        };

        // Split the accept data into individual extensions
        const extensions = acceptData.split(',').map(type => type.trim());

        // Map each extension to its corresponding format
        const formattedTypes = extensions.map(extension => extensionMapping[extension]);

        // Filter out undefined values (extensions not in the mapping)
        const validTypes = formattedTypes.filter(type => type);

        // Join the types into a comma-separated string
        const formattedString = validTypes.join(', ');

        return formattedString;
    }



    useEffect(() => {
        getFormData()
        setAccept(props.accept)

    }, [])


    return (
        <>
            <div className='form-group'>
                <div className=''>
                    <div className='d-flex ai-center jc-between mb-2'>
                        <label className='form-label mb-0'>{props.text} <span className="required">{props.span}</span></label>
                    </div>
                    <div className='upload_file dropzone image mb-0'>

                        <label className="mb-0">
                            <div className="overlay"></div>
                            <input type="file" name={props.name} multiple={props?.multiple} className='upload_image_input' accept={props.accept} style={{ display: "none" }} onChange={addImage} />
                        </label>

                        <div class="d-flex align-items-center gap-3 w-auto">
                            <div class="upload_icon rounded-2 px-2 py-1 w-auto">
                                <img src={cloud_upload} alt="img" />
                            </div>

                            <div class="dz-message text-start">
                                {
                                    imageUrls?.length > 0 ?
                                        <div className="d-flex ai-center">
                                            {
                                                ['.pdf', '.doc', '.docx']?.some(item => item.includes(Accetp)) ?
                                                    imageUrls?.map((item, i) => {
                                                        console.log("imageUrls => ", item);
                                                        return <div className="">
                                                            <FaRegFileLines className="purple_icons" style={{ fontSize: "34px" }} />
                                                        </div>
                                                    })
                                                    :
                                                    imageUrls?.map((item, i) => {
                                                        console.log("imageUrls => ", item);
                                                        return <>
                                                            <div className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                                                                <div className="dz-image">
                                                                    <img key={i} src={item} alt="logo" className="img-fluid border image_upload" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    })
                                            }
                                        </div>
                                        :
                                        fileData[props.itemkey] ?
                                            <div className="uploaded_doc_icon">
                                                <a href={`${props.url}/${fileData[props.itemkey]}`} className="" target="_blank">
                                                    <FaRegFileLines className="purple_icons file_icon" />
                                                </a>
                                            </div>
                                            :
                                            <div>
                                                <p class="mb-0 fw-semibold text-dark">Upload File</p>
                                                <small class="mb-0 fw-semibold text-gray">{formatAcceptData(props.accept)}</small>
                                            </div>
                                }
                            </div>
                        </div>

                    </div>

                    {imageUrls?.length > 0 &&
                        <div style={{ display: "flex", flexDirection: "row-reverse" }} className="mt-2">
                            <button className='btn upload_btn me-2' onClick={() => setImageUrls([])} >Cancel</button>
                            <button className='btn upload_btn me-2' onClick={(e) => generatePdf(e)}>Save</button>
                        </div>
                    }

                </div>
            </div>
        </>
    );
}


export default ImageUploadInputrenewal;