import React from 'react'
import jsPDF from "jspdf";
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import profile from "../../assets/profile.svg"
import ImageModal from '../Modals/ImageModal';
import {
    academicCertficatesUrl, admissionLetterUrl, BillUrl, childrenBformUrl, CNICUrl, DeathCertificateUrl, DomicileUrl,
    FamilyUrl, IncomeCertificateUrl, passimageUrl, RenewalUrl, RentAgreementUrl, resumeUrl, vouchersUrl
} from '../../helpers/data';
import { FaFileCircleXmark } from "react-icons/fa6";
import { form8, getFormsByAdmin, uploadAcademicCertficates, uploadAdmissionLetter, uploadBankStatementFamily, uploadChildrenBform, uploadCnicParent, uploadCnicSelf, uploadCnicSibling, uploadCnicSpouse, uploadDeathCertificate, uploadDomicile, uploadElectricBill, uploadFeeLedger, uploadGasBill, uploadGuardianIncomeCertificate, uploadIncomeTaxFamily, uploadPaidFeeVoucher, uploadPassportImage, uploadRentAgreement, uploadResume, uploadSelfIncomeCertificate, uploadSiblingIncomeCertificate, uploadSpouseIncomeCertificate, uploadTelephoneBill, uploadUnpaidFeeVoucher, uploadwaterBill } from "../../ContextAPI/APIs/api"
import { FileViewer } from '../Form/fileViewer';
function Step_9(props) {

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setImageUrls([])
        setParam("")
    }
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const [logo, setLogo] = useState([]);
    const [showLogo, setShowLogo] = useState([]);

    const store = [{
        logo: ""
    }]

    const onSelectLogo = (event) => {
        const selectedFiles = event.target.files;
        setLogo((selectedFiles[0]))
        setShowLogo(URL.createObjectURL(selectedFiles[0]))

        // FOR BUG IN CHROME
        event.target.value = "";

        console.log(selectedFiles);
    };

    function deleteLogo(image, e) {
        e.preventDefault();
        setLogo([]);
        URL.revokeObjectURL(image);
    }


    const [imageUrls, setImageUrls] = useState([]);
    const [showImage, setShowImage] = useState([]);

    const [pdfDoc, setPdfDoc] = useState(null);

    const [n, setN] = useState({ name: "" });

    const [param, setParam] = useState("")


    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);

    const [sop, setSop] = useState({ SOP: "" })

    const handleSop = (e) => {
        setSop({ ...sop, SOP: e.target.value })

    }
    const Next = async () => {
        try {
            const response = await form8(sop)
            if (response.success) {
                // navigate(`#${"step_3"}`)
                alert(response.message)
            }
            else {
                alert(response.message)
            }

        }
        catch (e) {
            console.log(e);
        }
    }



    const [fileData, setfileData] = useState({})
    const getFormData = async () => {
        try {
            // const response = await getForm7()
            const response = await getFormsByAdmin(8, props.id)
            if (response.success) {
                console.log("fomr1", response);
                setfileData(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getFormData()
    }, [])

    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        <p className='heading_para'>NO APPLICATION WILL BE ENTERTAINED WITHOUT THE FOLLOWING DOCUMENTS, <i>“APPLICATION WILL BE CANCELLED WITHOUT INTIMATION”</i></p>
                        {/* <form> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='form-label'>Two (02) Recent Passport Size Photographs</label>
                                    {fileData?.passimage ?
                                        <FileViewer file="pdf" urls={[`${passimageUrl}/${fileData?.passimage}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Updated Resume (C.V) Self</label>
                                    {fileData?.Resume ?

                                        <FileViewer file="pdf" urls={[`${resumeUrl}/${fileData?.Resume}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* Copy of CNIC */}
                            <h4 className='label_heading'>CNIC and Domicile</h4>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Self / B-Form (if CNIC not applicable) </label>
                                    {fileData?.selfCnic ?
                                        <FileViewer file="pdf" urls={[`${CNICUrl}/${fileData?.selfCnic}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Parents (Father, Mother) / Guardian </label>
                                    {fileData?.guardianCnic ?
                                        <FileViewer file="pdf" urls={[`${CNICUrl}/${fileData?.guardianCnic}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group mb-4'>
                                    <label className='form-label'>Spouse</label>
                                    {fileData?.spouseCnic ?
                                        <FileViewer file="pdf" urls={[`${CNICUrl}/${fileData?.spouseCnic}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group mb-4'>
                                    <label className='form-label'>Siblings / B-Form (if CNIC not applicable)</label>
                                    {fileData?.siblingCnic ?
                                        <FileViewer file="pdf" urls={[`${CNICUrl}/${fileData?.siblingCnic}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Domicile Certificate (Self)</label>
                                    {fileData?.Domicile ?
                                        <FileViewer file="pdf" urls={[`${DomicileUrl}/${fileData?.Domicile}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>B-Form / CNIC of Children (if applicable)</label>
                                    {fileData?.childrenBform ?
                                        <FileViewer file="pdf" urls={[`${childrenBformUrl}/${fileData?.childrenBform}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className='form-group'>
                                    <label className='form-label'>Death Certificate (if / Whenever Applicable) of Parents, Siblings & Spouse (if any)</label>
                                    {fileData?.DeathCertificate ?
                                        <FileViewer file="pdf" urls={[`${DeathCertificateUrl}/${fileData?.DeathCertificate}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* Admission and Fee Details */}
                            <h4 className='label_heading'> Admission and Fee Details </h4>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Admission Letter (for New Admission)</label>
                                    {fileData?.AdmissionLetter ?
                                        <FileViewer file="pdf" urls={[`${admissionLetterUrl}/${fileData?.AdmissionLetter}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Photocopies of all Academic Certificates / Degrees / Marks Sheets / Transcripts</label>
                                    {fileData?.AcademicCertificates ?
                                        <FileViewer file="pdf" urls={[`${academicCertficatesUrl}/${fileData?.AcademicCertificates}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Copy of Latest Unpaid Fee Vouchers</label>
                                    {fileData?.UnpaidFeeVoucher ?
                                        <FileViewer file="pdf" urls={[`${vouchersUrl}/${fileData?.UnpaidFeeVoucher}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Copies of Last and Latest (paid) fee Vouchers / Receipts of Self and Siblings</label>
                                    {fileData?.paidFeeVoucher ?
                                        <FileViewer file="pdf" urls={[`${vouchersUrl}/${fileData?.paidFeeVoucher}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'> Up to Date Fee Ledger</label>
                                    {fileData?.feeLedger ?
                                        <FileViewer file="pdf" urls={[`${vouchersUrl}/${fileData?.feeLedger}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* Income Certificate */}
                            <h4 className='label_heading'>Income Certificate / Pay-slip / Pension Book – Slip (if Applicable) </h4>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Self (pdf Only)</label>
                                    {fileData?.selfIncomeCertificate ?
                                        <FileViewer file="pdf" urls={[`${IncomeCertificateUrl}/${fileData?.selfIncomeCertificate}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Parents / Guardian (pdf Only) </label>
                                    {fileData?.guardianIncomeCertificate ?
                                        <FileViewer file="pdf" urls={[`${IncomeCertificateUrl}/${fileData?.guardianIncomeCertificate}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group mb-4'>
                                    <label className='form-label'>Spouse (pdf Only)</label>
                                    {fileData?.spouseIncomeCertificate ?
                                        <FileViewer file="pdf" urls={[`${IncomeCertificateUrl}/${fileData?.spouseIncomeCertificate}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group mb-4'>
                                    <label className='form-label'>Siblings (pdf Only)</label>
                                    {fileData?.siblingIncomeCertificate ?
                                        <FileViewer file="pdf" urls={[`${IncomeCertificateUrl}/${fileData?.siblingIncomeCertificate}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Income Tax Return of All Family Members (pdf Only)</label>
                                    {fileData?.IncomeTaxFamily ?
                                        <FileViewer file="pdf" urls={[`${FamilyUrl}/${fileData?.IncomeTaxFamily}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Bank Statement of Last Three (03) Months (Latest) of All Family Members having an Account (pdf Only)</label>
                                    {fileData?.BankStatementFamily ?
                                        <FileViewer file="pdf" urls={[`${FamilyUrl}/${fileData?.BankStatementFamily}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>


                            {/* Utilities Bills */}
                            <h4 className='label_heading'>Photocopies of Latest One (01) Month Paid Utilities Bills</h4>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Electricity</label>
                                    {fileData?.ElectricBill ?
                                        <FileViewer file="pdf" urls={[`${BillUrl}/${fileData?.ElectricBill}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Gas</label>
                                    {fileData?.GasBill ?
                                        <FileViewer file="pdf" urls={[`${BillUrl}/${fileData?.GasBill}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Telephone</label>
                                    {fileData?.TelephoneBill ?
                                        <FileViewer file="pdf" urls={[`${BillUrl}/${fileData?.TelephoneBill}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Water</label>
                                    {fileData?.waterBill ?
                                        <FileViewer file="pdf" urls={[`${BillUrl}/${fileData?.waterBill}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "black" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='form-group'>
                                    <label className='form-label'>Copy of Rent / Tenancy Agreement (if Applicable) (pdf Only)</label>
                                    {fileData?.RentAgreement ?
                                        <FileViewer file="pdf" urls={[`${RentAgreementUrl}/${fileData?.RentAgreement}`]} />
                                        :
                                        <div className='upload_file image mb-4' style={{ width: "fit-content" }}>
                                            <FaFileCircleXmark style={{ fontSize: "50px", color: "balck" }} />
                                            <p className='mb-0 ml-2'>File Not Found</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* SOP */}
                            <h4 className='label_heading'>SOP</h4>

                            <div className="col-md-12">
                                <div className='sop_section'>
                                    <div className='mb-4'>
                                        <label className='form-label'>Statement of Purpose (SOP)* in Life</label>
                                        <textarea disabled value={fileData.SOP} className='form-control' cols="30" rows="4" placeholder='Type here...' required ></textarea>
                                    </div>
                                    <h5>*SOP means:</h5>
                                    <ul>
                                        <li>What the applicant wants to do in his/her life</li>
                                        <li>What ambitions you have, what special you have done in the past</li>
                                        <li>What peculiar and special qualities and personal attributes you posses which makes you
                                            eligible for Ihsan Trust’s Interest Free Loan scheme
                                        </li>
                                        <li>What plans you have for your country Pakistan to make it a better place to live</li>
                                    </ul>
                                    <h6>Note:-</h6>
                                    <p>Kindly do not mention the financial position and / or requirements at all in the statement of purpose.</p>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}

                        <div className='d-flex jc-between mt-5'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"step_8"}`)}>Previous</button>
                            {/* <button className='btn next_btn' onClick={() => Next()}>Next</button> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step_9
