import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useAuth } from '../ContextAPI/Components/auth';
import { usePathname } from '../ContextAPI/Components/PathnameContext';

import { BsBook } from 'react-icons/bs';
import { HiBars3, HiOutlineNewspaper } from 'react-icons/hi2';
import { FaWpforms } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineClose } from 'react-icons/ai';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { LuLayoutDashboard } from 'react-icons/lu';
import { LiaUniversitySolid } from 'react-icons/lia';
import { FiChevronDown, FiUsers } from 'react-icons/fi';
import { MdAutorenew, MdOutlineLanguage } from 'react-icons/md';
import { PiSignInBold, PiSignOutBold, PiUserCirclePlus, PiStudent } from 'react-icons/pi';

import logo from "../assets/logo.png";
import logo2 from "../assets/logo-icon.png";
import germany_flag from "../assets/language icons/germany.png";
import england_flag from "../assets/language icons/united-kingdom.png";

import Collapse from 'react-bootstrap/Collapse';
import { HiOutlineDocumentReport } from 'react-icons/hi';


function MobSidebar() {
    const navigate = useNavigate();
    const { user, setUser } = useAuth();
    const { handlePathname, style } = usePathname();
    const [cookies, setCookie, removeCookie] = useCookies(["pk2"]);

    const [open, setOpen] = useState(false);
    const [openCases, setOpenCases] = useState(false);
    const [openSheets, setOpenSheets] = useState(false);
    const [openReports, setOpenReports] = useState(false);

    function signout() {
        removeCookie("pk2")
        setUser(null)
    }


    return (
        <>
            <img src={logo2} alt="logo" className='img-fluid me-1' style={{ width: "50px" }} />
            <button className="btn nav_sidebar_toggle_btn px-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                <HiBars3 className='nav_sidebar_toggle_icon' />
            </button>

            <div className="offcanvas offcanvas-start mob_sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div className="offcanvas-header d-flex ai-center">
                    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                        <img src={logo} alt="logo" className='img-fluid' />
                    </h5>
                    <button type="button" className="btn btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                        <AiOutlineClose />
                    </button>
                </div>
                <div className="offcanvas-body mob_sidebar_body">
                    <ul>
                        {
                            user.role == "anonymous" &&
                            <>
                                <h6>GLOBAL</h6>
                                <li className={`${style == "profile" ? "active" : "unactive"}`}>
                                    <Link to="/profile" className={`${style == "profile" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <CgProfile className='me-2 link_icon' />
                                            <p>Profile</p>
                                        </div>
                                    </Link>
                                </li>
                                <li className={`${style == "sign_in" ? "active" : "unactive"}`}>
                                    <Link to="/sign-in" className={`${style == "sign_in" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <PiSignInBold className='me-2 link_icon' />
                                            <p>Sign In</p>
                                        </div>
                                    </Link>
                                </li>
                                <li className={"unactive"} onClick={() => signout()}>
                                    <Link to="#" className={"link"}>
                                        <div className='d-flex ai-center'>
                                            <PiSignOutBold className='me-2 link_icon' />
                                            <p>Log Out</p>
                                        </div>
                                    </Link>
                                </li>
                            </>
                        }

                        {(user.role == "admin" || user?.role == "viewer" || user.role == "operations" || user.role == "finance" || user.role == "repayment") &&

                            <>
                                <h6>ADMIN</h6>
                                <li className={`${style == "admin_dashboard" ? "active" : "unactive"}`}>
                                    <Link to="/admin-dashboard" className={`${style == "admin_dashboard" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <LuLayoutDashboard className='me-2 link_icon' />
                                            <p>Dashboard</p>
                                        </div>
                                    </Link>
                                </li>

                                {
                                    (user.role == "admin" || user.role == "operations") && (
                                        <li className={`${style == "create_user" ? "active" : "unactive"}`}>
                                            <Link to="/create-user" className={`${style == "create_user" ? "activeLink" : "link"}`}>
                                                <div className='d-flex ai-center'>
                                                    <PiUserCirclePlus className='me-2 link_icon' />
                                                    <p>Create User</p>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                }

                                {/* A L L  U S E R S */}
                                <div className='side-menu__item' onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open} >
                                    <FiUsers className='link_icon' />
                                    <p className='side-menu__label mb-0'>All Users</p>
                                    <FiChevronDown className="angel dropdown_icon" />
                                </div>
                                <Collapse in={open}>
                                    <div id="example-collapse-text">
                                        <ul className="slide-menu  open " style={{ opacity: "1", transition: "opacity 500ms ease-in 0s", display: "block" }}>
                                            <li className={`sub-slide ${style == "all_users" ? "active" : "unactive"}`}>
                                                <Link to="/all-users/students-list" className={`slide-item ${style == "all_users" ? "activeLink" : "link"}`}>
                                                    <div className='d-flex ai-center'>
                                                        <PiStudent className='me-2 link_icon' />
                                                        <p>Students</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className={`sub-slide ${style == "universities" ? "active" : "unactive"}`}>
                                                <Link to="/all-users/universities-list" className={`slide-item ${style == "universities" ? "activeLink" : "link"}`}>
                                                    <div className='d-flex ai-center'>
                                                        <LiaUniversitySolid className='me-2 link_icon' />
                                                        <p>Universities</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className={`sub-slide ${style == "admin" ? "active" : "unactive"}`}>
                                                <Link to="/all-users/others-list" className={`slide-item ${style == "admin" ? "activeLink" : "link"}`}>
                                                    <div className='d-flex ai-center'>
                                                        <FiUsers className='me-2 link_icon' />
                                                        <p>Others</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Collapse>

                                <li className={`${style == "programs" ? "active" : "unactive"}`}>
                                    <Link to="/programs" className={`${style == "programs" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <BsBook className='me-2 link_icon' />
                                            <p>Programs</p>
                                        </div>
                                    </Link>
                                </li>

                                {/* C A S E S */}
                                <div className='side-menu__item' onClick={() => setOpenCases(!openCases)} aria-controls="example-collapse-text" aria-expanded={openCases} >
                                    <FiUsers className='link_icon' />
                                    <p className='side-menu__label mb-0'>Cases</p>
                                    <FiChevronDown className="angel dropdown_icon" />
                                </div>
                                <Collapse in={openCases}>
                                    <div id="example-collapse-text">
                                        <ul className="slide-menu  open " style={{ opacity: "1", transition: "opacity 500ms ease-in 0s", display: "block" }}>
                                            <li className={`sub-slide ${style == "freshCases" ? "active" : "unactive"}`}>
                                                <Link to="/cases/fresh-cases" className={`slide-item ${style == "freshCases" ? "activeLink" : "link"}`}>
                                                    <div className='d-flex ai-center'>
                                                        <FiUsers className='me-2 link_icon' />
                                                        <p>Fresh Cases</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className={`sub-slide ${style == "renewalCases" ? "active" : "unactive"}`}>
                                                <Link to="/cases/renewal-cases" className={`slide-item ${style == "renewalCases" ? "activeLink" : "link"}`}>
                                                    <div className='d-flex ai-center'>
                                                        <FiUsers className='me-2 link_icon' />
                                                        <p>Renewal Cases</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Collapse>

                                {/* A P P R O V A L  S H E E T S */}
                                <div className='side-menu__item' onClick={() => setOpenSheets(!openSheets)} aria-controls="example-collapse-text" aria-expanded={openSheets} >
                                    <HiOutlineNewspaper className='link_icon' />
                                    <p className='side-menu__label mb-0'>Approval Sheets</p>
                                    <FiChevronDown className="angel dropdown_icon" />
                                </div>
                                <Collapse in={openSheets}>
                                    <div id="example-collapse-text">
                                        <ul className="slide-menu  open " style={{ opacity: "1", transition: "opacity 500ms ease-in 0s", display: "block" }}>
                                            <li className={`sub-slide ${style == "approvalSheet" ? "active" : "unactive"}`}>
                                                <Link to="/approval-sheets/fresh" className={`slide-item ${style == "approvalSheet" ? "activeLink" : "link"}`}>
                                                    <div className='d-flex ai-center' >
                                                        <HiOutlineNewspaper className='me-2 link_icon' />
                                                        <p>Fresh Sheet</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className={`sub-slide ${style == "approvalSheetRenewal" ? "active" : "unactive"}`}>
                                                <Link to="/approval-sheets/renewal" className={`slide-item ${style == "approvalSheetRenewal" ? "activeLink" : "link"}`}>
                                                    <div className='d-flex ai-center' >
                                                        <HiOutlineNewspaper className='me-2 link_icon' />
                                                        <p>Renewal Sheet</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Collapse>

                                <li className={`${style == "create-repayments" ? "active" : "unactive"}`}>
                                    <Link to="/create-repayments" className={`${style == "create-repayments" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center' >
                                            <FiUsers className='me-2 link_icon' />
                                            <p>Create Repayment</p>
                                        </div>
                                    </Link>
                                </li>
                                <li className={`${style == "tranch" ? "active" : "unactive"}`}>
                                    <Link to="/tranch" className={`${style == "tranch" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center' >
                                            <FiUsers className='me-2 link_icon' />
                                            <p>Disbursements</p>
                                        </div>
                                    </Link>
                                </li>

                                <li className={`${style == "reporting2" ? "active" : "unactive"}`}>
                                    <Link to="/reporting" className={`${style == "reporting2" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center' >
                                            <HiOutlineDocumentReport className='me-2 link_icon' />
                                            <p>Reporting</p>
                                        </div>
                                    </Link>
                                </li>

                            </>
                        }

                        {
                            (user.role == "student" || user.role == "anonymous") &&
                            <>
                                <h6>STUDENT</h6>
                                <li className={`${style == "repayment_history" ? "active" : "unactive"}`}>
                                    <Link to="/dashboard" className={`${style == "repayment_history" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <LuLayoutDashboard className='me-2 link_icon' />
                                            <p>Dashboard</p>
                                        </div>
                                    </Link>
                                </li>

                                {
                                    user.totalLoan <= 0 && (
                                        <li className={`${style == "application_form" ? "active" : "unactive"}`}>
                                            <Link to="/application-form" className={`${style == "application_form" ? "activeLink" : "link"}`}>
                                                <div className='d-flex ai-center'>
                                                    <FaWpforms className='me-2 link_icon' />
                                                    <p>Application Form</p>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                }
                                {
                                    user.totalLoan > 0 && (
                                        <li className={`${style == "renewal_form" ? "active" : "unactive"}`}>
                                            <Link to="/renewal-form" className={`${style == "renewal_form" ? "activeLink" : "link"}`}>
                                                <div className='d-flex ai-center'>
                                                    <MdAutorenew className='me-2 link_icon' />
                                                    <p>Renewal Form</p>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                }
                                <li className={`${style == "application_tracking" ? "active" : "unactive"}`}>
                                    <Link to="/application-tracking" className={`${style == "application_tracking" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <BiMoneyWithdraw className='me-2 link_icon' />
                                            <p>Application Tracking</p>
                                        </div>
                                    </Link>
                                </li>
                                <li className={`${style == "std-profile" ? "active" : "unactive"}`}>
                                    <Link to="/std-profile" className={`${style == "std-profile" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <CgProfile className='me-2 link_icon' />
                                            <p>Profile</p>
                                        </div>
                                    </Link>
                                </li>
                            </>
                        }

                        {
                            (user.role == "university" || user.role == "anonymous") &&
                            <>
                                <h6>UNIVERSITY</h6>
                                <li className={`${style == "university_dashboard" ? "active" : "unactive"}`}>
                                    <Link to="/university-dashboard" className={`${style == "university_dashboard" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <LuLayoutDashboard className='me-2 link_icon' />
                                            <p>Dashboard</p>
                                        </div>
                                    </Link>
                                </li>
                                <li className={`${style == "fresh_cases_report" ? "active" : "unactive"}`}>
                                    <Link to="/fresh-cases-report" className={`${style == "fresh_cases_report" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <PiSignInBold className='me-2 link_icon' />
                                            <p>Fresh Cases Report</p>
                                        </div>
                                    </Link>
                                </li>
                                <li className={`${style == "renewal_cases_report" ? "active" : "unactive"}`}>
                                    <Link to="/renewal-cases-report" className={`${style == "renewal_cases_report" ? "activeLink" : "link"}`}>
                                        <div className='d-flex ai-center'>
                                            <PiSignInBold className='me-2 link_icon' />
                                            <p>Renewal Cases Report</p>
                                        </div>
                                    </Link>
                                </li>
                            </>
                        }

                    </ul>

                    {/* <div className="mob_language_show">
                        <div className="btn-group dropup">
                            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <MdOutlineLanguage className='language_icon' />
                            </button>
                            <ul className="dropdown-menu mob_language_box" style={{ background: "transparent" }}>
                                <li><div className='d-flex ai-center mb-2'>
                                    <img src={england_flag} alt="icon" className='img-fluid me-2' style={{ width: "16px" }} />
                                    <p>Eng</p>
                                </div>
                                </li>
                                <li>
                                    <div className='d-flex ai-center'>
                                        <img src={germany_flag} alt="icon" className='img-fluid me-2' style={{ width: "16px" }} />
                                        <p>Ger</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div >
        </>
    )
}

export default MobSidebar
