import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getForm2, SubmissionForm2 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"
import { useToast } from "../../ContextAPI/Components/toast"

import { admissionLetterUrl, resumeUrl, vouchersUrl, newURL, semesters, years } from '../../helpers/data';
import ImageUploadInput from '../Modals/ImageUploadInput';

import Form from 'react-bootstrap/Form';

import ACADEMIC_INFO from "../../assets/form-icons/academic.png"
import FormErrors from './FormErrors';


function Academic_Background() {

    const { fileData, setfileData } = useAuth();

    const { alert } = useToast()

    const { GetLoginUser, user, GettabsData } = useAuth();

    // const { user } = useAuth()
    console.log("++++++>>>", user);

    const [examDetail, setExamDetail] = useState([
        {
            S_No: "",
            Examination_Level: "",
            Month_Year: "",
        },
    ]);

    const e_detail = {
        S_No: "",
        Examination_Level: "",
        Month_Year: "",
    }

    function Add_New_E_Detail(e) {
        e.preventDefault();
        setExamDetail([...examDetail, e_detail])
    }

    function handleInputChangeEXAM(e, index, field) {
        const updatedExamDetail = [...examDetail];
        updatedExamDetail[index][field] = e.target.value;
        setExamDetail(updatedExamDetail);
    }

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const [data, setData] = useState({
        institute: user.university._id || "",
        program: user.program._id || "",
        institutenName: user.university.fullName || "",
        programName: user.program.name || "",


        ExamStructure: user.type || "",
        enrollNo: user.EnrollNo || "",
        currentSem: "",
        totalSem: "",
        semtype: "",
        email: "",
        newAdmission: "",
        currentGPA: "",
        CGPA: "",



        metricInstitute: "",
        metricGrade: "",
        metricPassing: "",
        intermediateInstitute: "",
        intermediateGrade: "",
        intermediatePassing: "",
        bachelorsInstitute: "",
        bachelorsGrade: "",
        bachelorsPassing: "",
        mastersInstitute: "",
        mastersGrade: "",
        mastersPassing: "",
        otherQualificationInstitute: "",
        otherQualificationGrade: "",
        otherQualificationPassing: "",
    })

    // examinationDetails: { type: Array, required: false },

    const handleInputChangeAQ = (e, degree) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [`${degree}${name}`]: value,
        }));
    };


    const [questions, setQuestions] = useState({
        question21: "",
        question22: "",
        question23: "",
        question24: "",
    })

    const handle21 = (e) => {
        setQuestions({ ...questions, question21: e.target.value })
    }

    const handle22 = (e) => {
        setQuestions({ ...questions, question22: e.target.value })
    }

    const getFormData = async () => {
        try {
            const response = await getForm2()
            console.log("fomr1", response);
            if (response.success) {


                setData(response.message)
                setExamDetail(response.message.examinationDetails)

                // underGradApplicable: ugrad,
                //     postGradApplicable: gradDis,
                //         otherApplicable: otherDis

                setIsNewAdmission(response?.message?.newAdmission)

                isUgrad(response.message.underGradApplicable)
                setDis(response.message.underGradApplicable)
                isGrad(response.message.postGradApplicable)
                setGradDis(response.message.postGradApplicable)
                isOther(response.message.otherApplicable)

                setfileData({
                    ...fileData,
                    undergrad: response.message.underGradApplicable,
                    grad: response.message.postGradApplicable,
                })

                // setOtherDis(response.message.otherApplicable)

                // setQuestions({
                //     ...questions,
                //     question21: response.message.question21,
                //     question22: response.message.question22,
                //     question23: response.message.question23,
                //     question24: response.message.question24
                // })
                // setfamilyDetail(response.message.familyDetails2)
                // setExamDetail(response.message.examinationDetails)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()

        window.scrollTo(0, 0)

    }, [])

    function removeDetail(i) {

        const updated = [...examDetail]

        updated.splice(i, 1)
        setExamDetail(updated)

    }


    const [ugrad, isUgrad] = useState(false)
    const [dis, setDis] = useState(false)
    const [grad, isGrad] = useState(false)
    const [gradDis, setGradDis] = useState(false)
    const [other, isOther] = useState(false)

    const [otherDis, setOtherDis] = useState(false)

    const handleCheckBoxUgrad = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setDis(true)
        }
        else {
            setDis(false)
        }
        isUgrad(e.target.checked)

        setfileData({ ...fileData, undergrad: e.target.checked })
    }
    const handleCheckBoxGrad = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setGradDis(true)
        }
        else {
            setGradDis(false)
        }
        isGrad(e.target.checked)
        setfileData({ ...fileData, grad: e.target.checked })

    }
    const handleCheckBoxOther = (e) => {
        console.log("YYY", e.target.checked);
        if (e.target.checked == true) {
            setOtherDis(true)
        }
        else {
            setOtherDis(false)
        }
        isOther(e.target.checked)
        setfileData({ ...fileData, other: e.target.checked })
    }


    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // console.log("DATA", data);

        // console.log("EXAM DETAILS", examDetail);

        const payload = {
            ...data,
            newAdmission: isNewAdmission,
            examinationDetails: examDetail,
            underGradApplicable: ugrad,
            postGradApplicable: gradDis,
            otherApplicable: otherDis
        }


        console.log("PAYLOAD", payload);

        const form = event.currentTarget;
        console.log("form.checkValidity()", form.checkValidity());
        if (form.checkValidity() === false) {
            console.log("--------------------------------------------", event.target);
            // event.preventDefault();
            event.stopPropagation();
        }

        // setValidated(true);
        console.log("================================", event.target);
        const check = validation()
        if (check === "proceed") {

            const response = await SubmissionForm2(payload)
            if (response.success) {
                GettabsData()

                navigate(`#${"financial_information"}`)
            }
            alert(response.message, response.success)

        }
        else {
            alert("Form Validation Error", "error")
        }
        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.stopPropagation();
        // }

        // setValidated(true);

    };

    const [isNewAdmission, setIsNewAdmission] = useState("");


    const [formErrors, setFormErrors] = useState([])
    let errors = {}

    const validation = () => {
        try {
            console.log("0000");
            console.log("ISUGRAD", ugrad);

            if (data.institutenName == "") {
                errors.institutenName = ("Institute Name is required")
            }
            if (data.programName == "") {
                errors.programName = ("Program Name is required")
            }
            if (data.enrollNo == "") {
                errors.enrollNo = ("Roll Number is required")
            }

            if (data.ExamStructure == "") {
                console.log("data.ExamStructure", data.ExamStructure);
                errors.ExamStructure = ("Examination Structure is required")
            }
            if (data.ExamStructure == "semester") {
                if (data.semtype == "") {
                    errors.semtype = ("Academic Session is required")
                }
            }

            if (data.currentSem == "") {
                errors.currentSem = ("Current Semester required")
            }
            if (data.totalSem == "") {
                errors.totalSem = ("Total No. of Semester is required")
            }

            if (isNewAdmission == "" || !isNewAdmission) {
                errors.isNewAdmission = ("Admission type is required")
            }
            if (isNewAdmission == "no") {
                if (data.currentGPA == "") {
                    errors.currentGPA = ("GPA is required")
                }
                if (data.CGPA == "") {
                    errors.CGPA = ("CGPA is required")
                }
            }


            // Matric 
            if (data.metricInstitute == "") {
                errors.metricInstitute = ("Matriculation / O-Level / Name of School/College is required")
            }
            if (data.metricGrade == "") {
                errors.metricGrade = ("Matriculation / O-Level / Grade is required")
            }
            if (data.metricPassing == "") {
                errors.metricPassing = ("Matriculation / O-Level / Passing Year is required")
            }

            // Inter
            if (data.intermediateInstitute == "") {
                errors.intermediateInstitute = ("Intermediate / A-Level Name of School/College is required")
            }
            if (data.intermediateGrade == "") {
                errors.intermediateGrade = ("Intermediate / A-Level Grade is required")
            }
            if (data.intermediatePassing == "") {
                errors.intermediatePassing = ("Intermediate / A-Level Passing Year is required")
            }

            // Bachelors
            if (!ugrad) {
                if (data.bachelorsInstitute == "") {
                    errors.bachelorsInstitute = ("Undergradudate / Bachelors Institute Name is required")
                }
                if (data.bachelorsGrade == "") {
                    errors.bachelorsGrade = ("Undergradudate / Bachelors CGPA / Percentage is required")
                }
                if (data.bachelorsPassing == "") {
                    errors.bachelorsPassing = ("Undergradudate / Bachelors Passing Year is required")
                }
            }


            // Masters
            if (!grad) {
                if (data.mastersInstitute == "") {
                    errors.mastersInstitute = ("Gradudate / Masters Institute Name is required")
                }
                if (data.mastersGrade == "") {
                    errors.mastersGrade = ("Gradudate / Masters CGPA / Percentage is required")
                }
                if (data.mastersPassing == "") {
                    errors.mastersPassing = ("Gradudate / Masters Passing Year is required")
                }
            }


            // Others
            if (!otherDis) {
                if (data.otherQualificationInstitute == "") {
                    errors.otherQualificationInstitute = ("Other Qualification Institute Name is required")
                }
                if (data.otherQualificationGrade == "") {
                    errors.otherQualificationGrade = ("Other Qualification CGPA / Percentage is required")
                }
                if (data.otherQualificationPassing == "") {
                    errors.otherQualificationPassing = ("Other Qualification Passing Year is required")
                }
            }

            // if (examDetail.length > 0) {

            //     for (let i = 0; i < examDetail.length; i++) {
            //         if (examDetail[i].Examination_Level == "") {
            //             errors["Examination_Level" + i] = "Examination level required in Exam detail"
            //         }
            //         if (examDetail[i].Month_Year == "") {
            //             errors["Month_Year" + i] = "Month is required in Exam detail"
            //         }
            //     }
            // }

            if ((Object.keys(errors)).length == 0) {
                setFormErrors([])
                return "proceed"
            }
            else {
                setFormErrors(errors)
            }


        }
        catch (e) {
            console.log(e);
        }
    }


    function IsError(id) {
        return !(Object.keys(formErrors)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formErrors)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }



    return (
        <>
            <div className="">

                {
                    (Object.values(formErrors)).length > 0 && (
                        <FormErrors errorList={formErrors} />
                    )
                }

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className='mb-4 form_sub_heading'>
                        <h5 className='mb-0'>Academic Background</h5>
                    </div>

                    <div className="table_form">

                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group id='institutenName' className="form-group">
                                    <Form.Label>Institute Name <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data?.institutenName}
                                        required
                                        disabled
                                        className={`${(formErrors.length == 0 ? "" : IsError("institutenName") ? "success" : "error")}`}
                                    />
                                    {
                                        (ShowError("institutenName"))[0] &&
                                        <p className='error_label'>{(ShowError("institutenName"))[1]}</p>
                                    }
                                </Form.Group>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group id='program' className="form-group">
                                    <Form.Label>Program <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data?.programName}
                                        disabled
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group id='enrollNo' className="form-group">
                                    <Form.Label>University Enrollment No. <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter here"
                                        name="enrollNo"
                                        value={data.enrollNo}
                                        onChange={handleChange}
                                        className={`${(formErrors.length == 0 ? "" : IsError("enrollNo") ? "success" : "error")}`}
                                        required
                                        disabled

                                    />
                                    {
                                        (ShowError("enrollNo"))[0] &&
                                        <p className='error_label'>{(ShowError("enrollNo"))[1]}</p>
                                    }
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group id='ExamStructure' className="form-group">
                                    <Form.Label>Examination Structure <span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="ExamStructure"
                                        value={data.ExamStructure}
                                        onChange={handleChange}
                                        required
                                        disabled
                                        className={`${(formErrors.length == 0 ? "" : IsError("ExamStructure") ? "success" : "error")}`}
                                    />
                                    {
                                        (ShowError("ExamStructure"))[0] &&
                                        <p className='error_label'>{(ShowError("ExamStructure"))[1]}</p>
                                    }
                                </Form.Group>
                            </div>

                            {
                                data.ExamStructure === "semester" && (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                        <Form.Group id='semtype' className="form-group">
                                            <Form.Label>Academic Session <span className='required'>*</span></Form.Label>
                                            <Form.Select
                                                value={data.semtype}
                                                onChange={handleChange}
                                                name="semtype"
                                                required
                                                className={`${(formErrors.length == 0 ? "" : IsError("semtype") ? "success" : "error")}`}>
                                                <option selected disabled value="">Select</option>
                                                <option value={"Spring"}>Spring</option>
                                                <option value={"Fall"}>Fall</option>
                                            </Form.Select>
                                            {
                                                (ShowError("semtype"))[0] &&
                                                <p className='error_label'>{(ShowError("semtype"))[1]}</p>
                                            }
                                        </Form.Group>
                                    </div>
                                )
                            }

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group id='currentSem' className="form-group">
                                    <Form.Label>Current {data.ExamStructure === "semester" ? "Semester" : "Year"} <span className='required'>*</span></Form.Label>
                                    <Form.Select
                                        value={data.currentSem}
                                        onChange={handleChange}
                                        name="currentSem"
                                        required
                                        className={`${(formErrors.length == 0 ? "" : IsError("currentSem") ? "success" : "error")}`}>
                                        <option selected disabled value="">Select</option>
                                        {
                                            user.type == "semester" &&
                                            semesters.map((semester, i) => {
                                                return <>
                                                    <option value={semester}>{semester}</option>
                                                </>
                                            })
                                        }
                                        {
                                            user.type == "year" &&
                                            years.map((year, i) => {
                                                return <>
                                                    <option value={year}>{year}</option>
                                                </>
                                            })
                                        }
                                    </Form.Select>
                                    {
                                        (ShowError("currentSem"))[0] &&
                                        <p className='error_label'>{(ShowError("currentSem"))[1]}</p>
                                    }
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 vertically_center">
                                <Form.Group id='totalSem' className="form-group">
                                    <Form.Label>Total No. of {data.ExamStructure === "semester" ? "Semester" : "Year"} <span className='required'>*</span></Form.Label>
                                    <Form.Select
                                        value={data.totalSem}
                                        onChange={handleChange}
                                        name="totalSem"
                                        required
                                        className={`${(formErrors.length == 0 ? "" : IsError("totalSem") ? "success" : "error")}`}>
                                        <option selected disabled value="">Select</option>
                                        {
                                            user.type == "semester" &&
                                            semesters.map((semester, i) => {
                                                return <>
                                                    <option value={semester}>{semester}</option>
                                                </>
                                            })
                                        }
                                        {
                                            user.type == "year" &&
                                            years.map((year, i) => {
                                                return <>
                                                    <option value={year}>{year}</option>
                                                </>
                                            })
                                        }
                                    </Form.Select>
                                    {
                                        (ShowError("totalSem"))[0] &&
                                        <p className='error_label'>{(ShowError("totalSem"))[1]}</p>
                                    }
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 vertically_center">
                                <Form.Group id='isNewAdmission' className="form-group">
                                    <Form.Label>Are you a new admission <span className='required'>*</span></Form.Label>
                                    <Form.Select value={isNewAdmission}
                                        onChange={(e) => { setIsNewAdmission(e.target.value); setData({ ...data, currentGPA: "", CGPA: "" }) }}
                                        className={`${(formErrors.length == 0 ? "" : IsError("isNewAdmission") ? "success" : "error")}`}>
                                        <option selected disabled value="">Select</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Form.Select>
                                    {
                                        (ShowError("isNewAdmission"))[0] &&
                                        <p className='error_label'>{(ShowError("isNewAdmission"))[1]}</p>
                                    }
                                </Form.Group>
                            </div>

                            {
                                // isNewAdmission == "no" &&
                                <>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <Form.Group id='currentGPA' className="form-group">
                                            <Form.Label>Current Semester GPA / Percentage (%) {isNewAdmission == "yes" ? "" : <span className='required'>*</span>}</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder={`${isNewAdmission == "yes" ? "" : "Enter here"}`}
                                                name="currentGPA"
                                                value={data.currentGPA}
                                                onChange={handleChange}
                                                disabled={isNewAdmission === "yes" ? true : false}
                                                required={isNewAdmission === "yes" ? true : false}
                                                className={`${(formErrors.length == 0 ? "" : IsError("currentGPA") ? "success" : "error")}`}
                                            />
                                            {
                                                (ShowError("currentGPA"))[0] &&
                                                <p className='error_label'>{(ShowError("currentGPA"))[1]}</p>
                                            }
                                        </Form.Group>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 vertically_center">
                                        <Form.Group id='CGPA' className="form-group">
                                            <Form.Label>CGPA / Percentage (%) {isNewAdmission == "yes" ? "" : <span className='required'>*</span>}</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder={`${isNewAdmission == "yes" ? "" : "Enter here"}`}
                                                name="CGPA"
                                                value={data.CGPA}
                                                onChange={handleChange}
                                                disabled={isNewAdmission === "yes" ? true : false}
                                                required={isNewAdmission === "yes" ? true : false}
                                                className={`${(formErrors.length == 0 ? "" : IsError("CGPA") ? "success" : "error")}`}
                                            />
                                            {
                                                (ShowError("CGPA"))[0] &&
                                                <p className='error_label'>{(ShowError("CGPA"))[1]}</p>
                                            }
                                        </Form.Group>
                                    </div>
                                </>
                            }

                            {/* DOCUMENTS */}
                            <div className="col-12 vertically_center">
                                <ImageUploadInput
                                    multiple={true}
                                    name="AdmissionLetter"
                                    text="Admission Letter"
                                    span={isNewAdmission == "yes" ? "*" : ""}
                                    url={admissionLetterUrl}
                                    itemkey="AdmissionLetter"
                                    accept=".jpg, .jpeg, .png"
                                    classes="label-margin"
                                    required={true}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <ImageUploadInput
                                    multiple={false}
                                    name="resume"
                                    span="*"
                                    text="Self Resume / CV"
                                    url={resumeUrl}
                                    itemkey="Resume"
                                    accept=".pdf"
                                    required={true}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <ImageUploadInput
                                    multiple={true}
                                    name="UnpaidFee"
                                    text="Latest Unpaid Fee Vouchers"
                                    span="*"
                                    url={vouchersUrl}
                                    itemkey="UnpaidFeeVoucher"
                                    accept=".jpg, .jpeg, .png"
                                    classes="label-margin"
                                    required={true}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <ImageUploadInput
                                    multiple={true}
                                    name="paidFee"
                                    text="Last and Latest (paid) fee Vouchers / Receipts"
                                    url={vouchersUrl}
                                    itemkey="paidFeeVoucher"
                                    accept=".jpg, .jpeg, .png"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                <ImageUploadInput
                                    multiple={true}
                                    name="feeLedger"
                                    text="Up to Date Fee Ledger"
                                    url={vouchersUrl}
                                    itemkey="feeLedger"
                                    accept=".jpg, .jpeg, .png"
                                />
                            </div>

                        </div>

                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            {/* Matriculation */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Matriculation / O-Level / Equivalent
                                    </button>
                                </h2>

                                <div id="flush-collapseOne" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='metricInstitute' className="form-group">
                                                    <Form.Label>Name of School/College <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter here"
                                                        name="metricInstitute"
                                                        value={data.metricInstitute}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("metricInstitute") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("metricInstitute"))[0] &&
                                                        <p className='error_label'>{(ShowError("metricInstitute"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='metricGrade' className="form-group">
                                                    <Form.Label>Grade <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter here"
                                                        name="metricGrade"
                                                        value={data.metricGrade}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("metricGrade") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("metricGrade"))[0] &&
                                                        <p className='error_label'>{(ShowError("metricGrade"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='metricPassing' className="form-group">
                                                    <Form.Label>Passing Year <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter here"
                                                        name="metricPassing"
                                                        value={data.metricPassing}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("metricPassing") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("metricPassing"))[0] &&
                                                        <p className='error_label'>{(ShowError("metricPassing"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                {/* <ImageUploadInput
                                                    multiple={true}
                                                    name="feeLedger"
                                                    text="Up to Date Fee Ledger"
                                                    url={vouchersUrl}
                                                    itemkey="feeLedger"
                                                    accept=".jpg, .jpeg, .png"
                                                /> */}
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="matricResult"
                                                    text="Upload Certificate"
                                                    span="*"
                                                    url={newURL}
                                                    itemkey="matricResult"
                                                    accept=".jpg, .jpeg, .png"
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Intermediate */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Intermediate / A-Level / Equivalent
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='intermediateInstitute' className="form-group">
                                                    <Form.Label>Name of School/College <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter here"
                                                        name="intermediateInstitute"
                                                        value={data.intermediateInstitute}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("intermediateInstitute") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("intermediateInstitute"))[0] &&
                                                        <p className='error_label'>{(ShowError("intermediateInstitute"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='intermediateGrade' className="form-group">
                                                    <Form.Label>Grade <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter here"
                                                        name="intermediateGrade"
                                                        value={data.intermediateGrade}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("intermediateGrade") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("intermediateGrade"))[0] &&
                                                        <p className='error_label'>{(ShowError("intermediateGrade"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='intermediatePassing' className="form-group">
                                                    <Form.Label>Passing Year <span className='required'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter here"
                                                        name="intermediatePassing"
                                                        value={data.intermediatePassing}
                                                        onChange={handleChange}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("intermediatePassing") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("intermediatePassing"))[0] &&
                                                        <p className='error_label'>{(ShowError("intermediatePassing"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    name="interResult"
                                                    text="Upload Certificate"
                                                    span="*"
                                                    url={newURL}
                                                    itemkey="interResult"
                                                    accept=".jpg, .jpeg, .png"
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Undergradudate */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Undergradudate / Bachelors / Equivalent
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="form-check text-center mb-4">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" checked={ugrad} onChange={(e) => handleCheckBoxUgrad(e)} />
                                            <label className="form-check-label" for="flexCheckDefault1" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                                Not Applicable For Me
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='bachelorsInstitute' className="form-group">
                                                    <Form.Label>Institute Name {dis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`${dis == true ? "" : "Enter here"}`}
                                                        name="bachelorsInstitute"
                                                        value={data.bachelorsInstitute}
                                                        onChange={handleChange}
                                                        disabled={dis}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("bachelorsInstitute") ? "success" : "error")}`}
                                                    />
                                                    {

                                                        (ShowError("bachelorsInstitute"))[0] &&
                                                        <p className='error_label'>{(ShowError("bachelorsInstitute"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='bachelorsGrade' className="form-group">
                                                    <Form.Label>CGPA / Percentage (%) {dis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`${dis == true ? "" : "Enter here"}`}
                                                        name="bachelorsGrade"
                                                        value={data.bachelorsGrade}
                                                        onChange={handleChange}
                                                        disabled={dis}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("bachelorsGrade") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("bachelorsGrade"))[0] &&
                                                        <p className='error_label'>{(ShowError("bachelorsGrade"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='bachelorsPassing' className="form-group">
                                                    <Form.Label>Passing Year {dis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={`${dis == true ? "" : "Enter here"}`}
                                                        name="bachelorsPassing"
                                                        value={data.bachelorsPassing}
                                                        onChange={handleChange}
                                                        disabled={dis}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("bachelorsPassing") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("bachelorsPassing"))[0] &&
                                                        <p className='error_label'>{(ShowError("bachelorsPassing"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    dis={dis}
                                                    name="bachelorsResult"
                                                    text="Upload Degree"
                                                    span={dis == true ? "" : "*"}
                                                    url={newURL}
                                                    itemkey="bachelorsResult"
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* Gradudate */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        Gradudate / Masters / Equivalent
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="form-check text-center mb-4">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2" checked={grad} onChange={(e) => handleCheckBoxGrad(e)} />
                                            <label className="form-check-label" for="flexCheckDefault2" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                                Not Applicable For Me
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='mastersInstitute' className="form-group">
                                                    <Form.Label>Institute Name {gradDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`${gradDis == true ? "" : "Enter here"}`}
                                                        name="mastersInstitute"
                                                        value={data.mastersInstitute}
                                                        onChange={handleChange}
                                                        disabled={gradDis}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("mastersInstitute") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("mastersInstitute"))[0] &&
                                                        <p className='error_label'>{(ShowError("mastersInstitute"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='mastersGrade' className="form-group">
                                                    <Form.Label>CGPA / Percentage (%) {gradDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`${gradDis == true ? "" : "Enter here"}`}
                                                        name="mastersGrade"
                                                        value={data.mastersGrade}
                                                        onChange={handleChange}
                                                        disabled={gradDis}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("mastersGrade") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("mastersGrade"))[0] &&
                                                        <p className='error_label'>{(ShowError("mastersGrade"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='mastersPassing' className="form-group">
                                                    <Form.Label>Passing Year {gradDis == true ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={`${gradDis == true ? "" : "Enter here"}`}
                                                        name="mastersPassing"
                                                        value={data.mastersPassing}
                                                        onChange={handleChange}
                                                        disabled={gradDis}
                                                        required
                                                        className={`${(formErrors.length == 0 ? "" : IsError("mastersPassing") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("mastersPassing"))[0] &&
                                                        <p className='error_label'>{(ShowError("mastersPassing"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    dis={gradDis}
                                                    name="mastersResult"
                                                    text="Upload Degree"
                                                    span={gradDis == true ? "" : "*"}
                                                    url={newURL}
                                                    itemkey="mastersResult"
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* Other */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        <img src={ACADEMIC_INFO} alt="icon" className='img-fluid form_icons' />
                                        M. Phil. / Phd / Other
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" className={`accordion-collapse collapse ${(Object.values(formErrors)).length > 0 && "show"}`} aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="form-check text-center mb-4">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault3" checked={other} onChange={(e) => handleCheckBoxOther(e)} />
                                            <label className="form-check-label" for="flexCheckDefault3" style={{ fontWeight: "bold", fontSize: "20px" }}>
                                                Not Applicable For Me
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='otherQualificationInstitute' className="form-group">
                                                    <Form.Label>Institute Name {otherDis ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`${otherDis ? "" : "Enter here"}`}
                                                        name="otherQualificationInstitute"
                                                        value={data.otherQualificationInstitute}
                                                        onChange={handleChange}
                                                        disabled={otherDis}
                                                        className={`${(formErrors.length == 0 ? "" : IsError("otherQualificationInstitute") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("otherQualificationInstitute"))[0] &&
                                                        <p className='error_label'>{(ShowError("otherQualificationInstitute"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='otherQualificationGrade' className="form-group">
                                                    <Form.Label>CGPA / Percentage (%) {otherDis ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`${otherDis ? "" : "Enter here"}`}
                                                        name="otherQualificationGrade"
                                                        value={data.otherQualificationGrade}
                                                        onChange={handleChange}
                                                        disabled={otherDis}
                                                        className={`${(formErrors.length == 0 ? "" : IsError("otherQualificationGrade") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("otherQualificationGrade"))[0] &&
                                                        <p className='error_label'>{(ShowError("otherQualificationGrade"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <Form.Group id='otherQualificationPassing' className="form-group">
                                                    <Form.Label>Passing Year {otherDis ? "" : <span className='required'>*</span>}</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={`${otherDis ? "" : "Enter here"}`}
                                                        name="otherQualificationPassing"
                                                        value={data.otherQualificationPassing}
                                                        onChange={handleChange}
                                                        disabled={otherDis}
                                                        className={`${(formErrors.length == 0 ? "" : IsError("otherQualificationPassing") ? "success" : "error")}`}
                                                    />
                                                    {
                                                        (ShowError("otherQualificationPassing"))[0] &&
                                                        <p className='error_label'>{(ShowError("otherQualificationPassing"))[1]}</p>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 vertically_center">
                                                <ImageUploadInput
                                                    multiple={true}
                                                    dis={otherDis}
                                                    name="othersResult"
                                                    text="Upload Degrees"
                                                    span={otherDis == true ? "" : "*"}
                                                    url={newURL}
                                                    itemkey="othersResult"
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Examinations to be taken in your Current Program */}
                    {/* <div className="table_form mb-4">
                        <div className='mb-4 form_sub_heading'>
                            <h5 className='mb-0'>Examinations to be taken in your Current Program</h5>
                            <button className='btn add_btn' onClick={(e) => Add_New_E_Detail(e)}>Add More</button>
                        </div>

                        {
                            examDetail.map((data, i) => {
                                return <>
                                    {
                                        i > 0 &&
                                        <div className="section_break"></div>
                                    }

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId={`examinationLevel-${i}`} id={`Examination_Level${i}`} className='form-group'>
                                                <Form.Label>Examination / Level <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter here"
                                                    value={examDetail[i].Examination_Level}
                                                    onChange={(e) => handleInputChangeEXAM(e, i, 'Examination_Level')}
                                                    required
                                                    className={`${(formErrors.length == 0 ? "" : (IsError(`Examination_Level${i}`)) ? "success" : "error")}`}
                                                />
                                                {
                                                    (ShowError(`Examination_Level${i}`)) &&
                                                    <p className='error_label'>{(ShowError(`Examination_Level${i}`))}</p>
                                                }
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 vertically_center">
                                            <Form.Group controlId={`monthYear${i}`} id='Month_Year' className='form-group'>
                                                <Form.Label>Month / Year <span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter here"
                                                    value={examDetail[i].Month_Year}
                                                    onChange={(e) => handleInputChangeEXAM(e, i, 'Month_Year')}
                                                    required
                                                    className={`${(formErrors.length == 0 ? "" : (IsError(`Month_Year${i}`)) ? "success" : "error")}`}
                                                />
                                                {
                                                    (ShowError(`Month_Year${i}`)) &&
                                                    <p className='error_label'>{(ShowError(`Month_Year${i}`))}</p>
                                                }
                                            </Form.Group>
                                        </div>
                                        {
                                            i > 0 && (
                                                <div className='d-flex jc-end'>
                                                    <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                </div>
                                            )
                                        }

                                    </div>
                                </>
                            })
                        }

                    </div> */}


                    <div className='d-flex jc-between gap-2 mt-4'>
                        <button className='btn prev_btn' onClick={() => navigate(`#${"demographics_information"}`)}>Previous</button>
                        <button className='btn next_btn' type="submit">Save and Next</button>
                    </div>

                </Form>

            </div>
        </>
    )
}

export default Academic_Background
