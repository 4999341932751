import React from 'react'
import { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { getForm2, getFormsByAdmin, SubmissionForm2 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"


function Step_3(props) {
    const { GetLoginUser } = useAuth();

    // Family Details
    const [familyDetail, setfamilyDetail] = useState([
        {
            S_No: "",
            Name_of_Family_Member: "",
            Relationship: "",
            Age: "",
            Marital_Status: "",
            Cell_No: "",
            Occupation: "",
            School_Institute_Office: "",
        },
    ]);

    const f_detail = {
        S_No: "",
        Name_of_Family_Member: "",
        Relationship: "",
        Age: "",
        Marital_Status: "",
        Cell_No: "",
        Occupation: "",
        School_Institute_Office: "",
    }

    function Add_New_F_Detail(e) {
        e.preventDefault();
        setfamilyDetail([...familyDetail, f_detail])
    }

    function handleInputChange(e, index, field) {
        const updatedFamilyDetail = [...familyDetail];
        updatedFamilyDetail[index][field] = e.target.value;
        setfamilyDetail(updatedFamilyDetail);
    }



    // Exam Detail
    const [examDetail, setExamDetail] = useState([
        {
            S_No: "",
            Examination_Level: "",
            Month_Year: "",
        },
    ]);

    const e_detail = {
        S_No: "",
        Examination_Level: "",
        Month_Year: "",
    }
    function Add_New_E_Detail(e) {
        e.preventDefault();
        setExamDetail([...examDetail, e_detail])
    }

    function handleInputChangeEXAM(e, index, field) {
        const updatedExamDetail = [...examDetail];
        updatedExamDetail[index][field] = e.target.value;
        setExamDetail(updatedExamDetail);
    }


    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);



    const Next = async () => {
        try {

            // const payload = {
            //     ...data,
            //     ...questions,
            //     familyDetails2: familyDetail,
            //     examinationDetails: examDetail,
            // }

            // console.log("PAYLOAD", payload);

            // const response = await SubmissionForm2(payload)

            // if (response.success) {
            //     alert(response.message)
            //
            navigate(`#${"step_4"}`)
            //     GetLoginUser()
            // }
            // else {
            //     alert(response.message)

            // }

        }
        catch (e) {
            console.log(e);
        }
    }


    const [data, setData] = useState({
        metricInstitute: "",
        metricGrade: "",
        metricPassing: "",
        intermediateInstitute: "",
        intermediateGrade: "",
        intermediatePassing: "",
        bachelorsInstitute: "",
        bachelorsGrade: "",
        bachelorsPassing: "",
        mastersInstitute: "",
        mastersGrade: "",
        mastersPassing: "",
        otherQualificationInstitute: "",
        otherQualificationGrade: "",
        otherQualificationPassing: "",


    })

    const handleInputChangeAQ = (e, degree) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [`${degree}${name}`]: value,
        }));
    };


    const [questions, setQuestions] = useState({
        question21: "",
        question22: "",
        question23: "",
        question24: "",
    })

    const handle21 = (e) => {
        setQuestions({ ...questions, question21: e.target.value })
    }

    const handle22 = (e) => {
        setQuestions({ ...questions, question22: e.target.value })
    }

    const handle23 = (e) => {
        setQuestions({ ...questions, question23: e.target.value })
    }

    const handle24 = (e) => {
        setQuestions({ ...questions, question24: e.target.value })
    }

    const getFormData = async () => {
        try {
            // const response = await getForm2()

            const response = await getFormsByAdmin(2, props.id)

            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
                setQuestions({
                    ...questions,
                    question21: response.message.question21,
                    question22: response.message.question22,
                    question23: response.message.question23,
                    question24: response.message.question24
                })
                setfamilyDetail(response.message.familyDetails2)
                setExamDetail(response.message.examinationDetails)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getFormData()
    }, [])


    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        {/* <form> */}
                        <div className="table_form mb-4">

                            <div className='d-flex ai-center jc-between mb-4'>
                                <h5 className='mb-0'>Family Memberâ€™s Details: Parents, (Sisters and Brothers (Married and Unmarried)):</h5>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-bordered" style={{ width: "150%" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No</th>
                                            <th scope="col">Name of Family Member</th>
                                            <th scope="col">Relationship</th>
                                            <th scope="col">Age</th>
                                            <th scope="col">Marital Status</th>
                                            <th scope="col">Cell No</th>
                                            <th scope="col">Occupation</th>
                                            <th scope="col">School/Institute/Office</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {familyDetail.map((data, i) => (
                                            <tr key={i}>
                                                <th scope="row" className='s_no'>{(i + 1)}</th>
                                                <td><input disabled type="text" className='form-control' placeholder="Enter here" value={familyDetail[i].Name_of_Family_Member} onChange={(e) => handleInputChange(e, i, 'Name_of_Family_Member')} /></td>
                                                <td><input disabled type="text" className='form-control' placeholder="Enter here" value={familyDetail[i].Relationship} onChange={(e) => handleInputChange(e, i, 'Relationship')} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder="Enter here" value={familyDetail[i].Age} onChange={(e) => handleInputChange(e, i, 'Age')} /></td>
                                                <td><input disabled type="text" className='form-control' placeholder="Enter here" value={familyDetail[i].Marital_Status} onChange={(e) => handleInputChange(e, i, 'Marital_Status')} /></td>
                                                <td><input disabled type="number" className='form-control' placeholder="Enter here" value={familyDetail[i].Cell_No} onChange={(e) => handleInputChange(e, i, 'Cell_No')} /></td>
                                                <td><input disabled type="text" className='form-control' placeholder="Enter here" value={familyDetail[i].Occupation} onChange={(e) => handleInputChange(e, i, 'Occupation')} /></td>
                                                <td><input disabled type="text" className='form-control' placeholder="Enter here" value={familyDetail[i].School_Institute_Office} onChange={(e) => handleInputChange(e, i, 'School_Institute_Office')} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="table_form mb-4">
                            <h5>Academic Qualifications:</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Degree</th>
                                            <th scope="col">School / Institute</th>
                                            <th scope="col">Grade</th>
                                            <th scope="col">Passing Year</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[
                                            { degree: 'Metric', key: 'metric' },
                                            { degree: 'Intermediate', key: 'intermediate' },
                                            { degree: 'Bachelors', key: 'bachelors' },
                                            { degree: 'Masters', key: 'masters' },
                                            { degree: 'Other Qualification', key: 'otherQualification' },
                                        ].map((item) => (
                                            <tr key={item.key}>
                                                <th scope="row">{item.degree}</th>
                                                <td>
                                                    <input disabled
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        name="Institute"
                                                        value={data[item.key + 'Institute']}
                                                    />
                                                </td>
                                                <td>
                                                    <input disabled
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        name="Grade"
                                                        value={data[item.key + 'Grade']}
                                                    />
                                                </td>
                                                <td>
                                                    <input disabled
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter here"
                                                        name="Passing"
                                                        value={data[item.key + 'Passing']}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="table_form mb-4">
                            <div className='d-flex ai-center jc-between mb-4'>
                                <h5 className='mb-0'>Examinations to be taken in your Current Program</h5>
                                <button className='btn add_btn' onClick={(e) => Add_New_E_Detail(e)}><FaPlus /></button>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No</th>
                                            <th scope="col">Examination/Level</th>
                                            <th scope="col">Month/Year</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            examDetail.map((data, i) => {
                                                return <tr key={i}>
                                                    <th scope="row" className='s_no'>{(i + 1)}</th>
                                                    <td><input disabled type="text" className='form-control' placeholder='Enter here' value={examDetail[i].Examination_Level} onChange={(e) => handleInputChangeEXAM(e, i, 'Examination_Level')} /></td>
                                                    <td><input disabled type="number" className='form-control' placeholder='Enter here' value={examDetail[i].Month_Year} onChange={(e) => handleInputChangeEXAM(e, i, 'Month_Year')} /></td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">Which other college/university did you apply to? Where did you get accepted? Why did you choose to attend this one?</label>
                            <textarea disabled value={questions.question21} onChange={(e) => handle21(e)} className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">What are your academic plans for the future?</label>
                            <textarea disabled value={questions.question22} onChange={(e) => handle22(e)} className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">What are your long term personal aspirations?</label>
                            <textarea disabled value={questions.question23} onChange={(e) => handle23(e)} className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>

                        <div className='form-group'>
                            <label className="form-label">What are your aspirations for your career?</label>
                            <textarea disabled value={questions.question24} onChange={(e) => handle24(e)} className='form-control' cols="30" rows="3" placeholder='Type here...'></textarea>
                        </div>
                        {/* </form> */}

                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => Next()}>Previous</button>
                            <button className='btn next_btn' onClick={() => Next()}>Next</button>
                            {/* navigate(`#${"step_4"}`) */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step_3