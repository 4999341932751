import React from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { HiOutlineDocumentReport } from 'react-icons/hi';


import report from "../../assets/report icons/report-icon.png"


function Reporting_2() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    return (
        <>
            <Helmet>
                <title>Reporting</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section'>

                        <h2>Reportings</h2>

                        <div className="row">
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/disbursementAnalysis-Student"}>
                                    <div className="card reporting_card">
                                        {/* <HiOutlineDocumentReport className='reporting_icon' /> */}
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>Disbursement Analysis Student</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/disbursementAnalysis-University"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>Disbursement Analysis University</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/interview-analysis"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>TAT Interview Analysis Fresh</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/interview-analysis-renewal"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>TAT Interview Analysis Renewal</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/monthly-analysis"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>TAT Monthly Analysis</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/repayments-Analysis"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>Repayment Analysis</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/aging-analysis"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>Aging Analysis</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/disbursement-repayments-Analysis"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>Disbursement and Repayment Analysis</h4>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-sm-6 mb-4">
                                <Link className='link' to={"/reporting/mastersheet"}>
                                    <div className="card reporting_card">
                                        <img src={report} alt="icon" className='img-fluid reporting_icon' />
                                        <h4>MasterSheet</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}

export default Reporting_2
