import React, { useEffect, useState } from 'react'
import NavSidebar from '../Sidebar'
import Navbar from '../Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import moment from 'moment';
import { useAuth } from '../../ContextAPI/Components/auth';

function ReopenFrom() {

    const { user } = useAuth();

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [countDown, setCountDown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const countDownFunc = () => {
        // Current time
        const now = moment();

        // Next review time
        const nextReviewTime = moment(parseInt(user.nextReview));

        // Calculate the duration
        const duration = moment.duration(nextReviewTime.diff(now));

        // Get days, hours, minutes, seconds from duration
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        // Update countDown state
        setCountDown({ days, hours, minutes, seconds });
    }

    useEffect(() => {
        // Update countdown every second
        const interval = setInterval(() => {
            countDownFunc();
        }, 1000);

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, []);



    return (
        <>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='form_reopen_section d-flex ai-center text-center'>
                        <div className="container">

                            <h2>Your Renewal Form will be reopen after</h2>

                            <div className="countdown">
                                <ul>
                                    <li><span>{countDown.days}</span>days</li>
                                    <li><span>{countDown.hours}</span>Hours</li>
                                    <li><span>{countDown.minutes}</span>Minutes</li>
                                    <li><span>{countDown.seconds}</span>Seconds</li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>
            </div>

        </>
    )
}

export default ReopenFrom
