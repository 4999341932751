import React from 'react'

function FormErrors(props) {

    function scrollToErrorElement(params) {
        const aaa = document.getElementById(params)
        aaa.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    }
    return (
        <>
            <div className="formErrors">
                <h5>Kindly Fixed Below Errors :</h5>
                <ul>
                    {
                        (Object.entries(props.errorList)).map((item, i) => (
                            <li style={{ cursor: "pointer" }} onClick={() => scrollToErrorElement(item[0])}>{item[1]}</li>
                        ))
                    }
                </ul>
            </div>
        </>
    )
}

export default FormErrors
