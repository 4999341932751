import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';

// import Navbar from '../../../components/Navbar';
import Navbar from '../../components/Navbar'
import NavSidebar from '../../components/Sidebar';
import { useAuth } from '../../ContextAPI/Components/auth';

// import '../Form/Form.css'
import '../../pages/Students/Form/Form.css'

import Step_1 from '../../components/ApplicationFormAdmin/Step_1';
import Step_2 from '../../components/ApplicationFormAdmin/Step_2';
import Step_3 from '../../components/ApplicationFormAdmin/Step_3';
import Step_4 from '../../components/ApplicationFormAdmin/Step_4';
import Step_5 from '../../components/ApplicationFormAdmin/Step_5';
import Step_6 from '../../components/ApplicationFormAdmin/Step_6';
import Step_7 from '../../components/ApplicationFormAdmin/Step_7';
import Step_8 from '../../components/ApplicationFormAdmin/Step_8';
import Step_9 from '../../components/ApplicationFormAdmin/Step_9';
import Step_10 from '../../components/ApplicationFormAdmin/Step_10';
import Preview from '../../components/ApplicationFormAdmin/Preview';
import formatter from '../../helpers/number_formatter';
import { IoArrowBack } from 'react-icons/io5';


function ApplicationFormAdmin() {

    const { id, doc } = useParams()
    console.log("ID", id);


    const { user } = useAuth();

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("");

    const checkUser = () => {
        try {
            console.log("HELLO", user);
            if (user.ApplicationStep == 0) {
                setShowForm("step_1")
            }
            if (user.ApplicationStep == 1) {
                // setShowForm("step_3")
                setShowForm((hash.split("#"))[1] || "step_3");
            }
            if (user.ApplicationStep == 2) {
                setShowForm("step_4")
            }
            if (user.ApplicationStep == 3) {
                setShowForm("step_5")
            }
            if (user.ApplicationStep == 4) {
                setShowForm("step_6")
            }
            if (user.ApplicationStep == 5) {
                setShowForm("step_7")
            }
            if (user.ApplicationStep == 6) {
                setShowForm("step_8")
            }
            if (user.ApplicationStep == 7) {
                setShowForm("step_9")
            }
            if (user.ApplicationStep == 8) {
                setShowForm("step_10")
            }
        }
        catch (e) {
            console.log();
        }
    }


    useEffect(() => {
        console.log((hash.split("#"))[1] || "preview");
        setShowForm((hash.split("#"))[1] || "preview");
    }, [hash]);


    // useEffect(() => {
    //     checkUser()
    // }, [])


    return (
        <>

            <Helmet>
                <title>Application Form</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>

                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                    <section className='application_form_section section_main'>

                        <div className='header mb-4'>
                            <div className='heading'>
                                <div className='d-flex ai-center jc-center'>
                                    {/* <Link to={`/fresh-cases-history/${id}/${doc}#cases-detail`}>
                                        <IoArrowBack className='back_arrow_icon me-3' />
                                    </Link> */}
                                    <h2 className='mb-0'>Interest Free Loan Application Form For Higher Education</h2>
                                </div>
                            </div>
                        </div>

                        <div className="card">


                            {
                                // user?.role == "admin" || user.role == "operations" || user.role == "university" ?
                                // user?.role == "admin" || (user.role == "student" && user.step == 3) ?
                                <>
                                    {/* Preview */}
                                    
                                    {
                                        showForm == "preview" &&
                                        <Preview id={id} />
                                    }

                                    {/* 01 */}
                                    {
                                        showForm == "step_1" &&

                                        <Step_1 id={id} />

                                    }

                                    {/* 02 */}
                                    {
                                        showForm == "step_2" &&
                                        <Step_2 id={id} />
                                    }

                                    {/* 03 */}
                                    {
                                        showForm == "step_3" &&
                                        <Step_3 id={id} />
                                    }

                                    {/* 04 */}
                                    {
                                        showForm == "step_4" &&
                                        <Step_4 id={id} />
                                    }

                                    {/* 05 */}
                                    {
                                        showForm == "step_5" &&
                                        <Step_5 id={id} />
                                    }

                                    {/* 06 */}
                                    {
                                        showForm == "step_6" &&
                                        <Step_6 id={id} />
                                    }

                                    {/* 07 */}
                                    {
                                        showForm == "step_7" &&
                                        <Step_7 id={id} />
                                    }

                                    {/* 08 */}
                                    {
                                        showForm == "step_8" &&
                                        <Step_8 id={id} />
                                    }

                                    {/* 09 */}
                                    {
                                        showForm == "step_9" &&
                                        <Step_9 id={id} />
                                    }

                                    {/* 10 */}
                                    {
                                        showForm == "step_10" &&
                                        <Step_10 id={id} />
                                    }

                                </>

                                // :
                                // <>
                                //     <div>
                                //         <h1>Please Update Your Profile</h1>
                                //     </div>
                                // </>


                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default ApplicationFormAdmin
