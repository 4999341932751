import axios from "axios";
import { API_URL } from "../../config";




/** ------------------------------ **/
/**            USER APIS           **/
/** ------------------------------ **/
export const create_user = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/create-user`, body)
            .then(res => {
                // console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const login_user = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/sign-in`, body)
            .then(res => {
                // console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getLogin_user = async () => {
    try {
        const data = await axios.get(`${API_URL}/user/get-login-user`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const UpdatePassword = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/update_password`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const UpdateProfilePic = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/profilPic`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const UpdateProfileInfo = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/update-profile-info`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const GetUnisList = async () => {
    try {
        const data = await axios.get(`${API_URL}/user/get-all-unis-for-user-profile`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const GetUnisProfileForStudent = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/get-uni-profile-for-user`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const GetLatestSubmittedApplication = async (studentID) => {
    try {
        const data = await axios.get(`${API_URL}/app/get-latest-submitted-application/${studentID}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const validateApplication = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/validateApplication`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const validateRenewal = async () => {
    try {
        const data = await axios.get(`${API_URL}/renewal/validateRenewal`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getReason = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getReason`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getReasonRenewal = async () => {
    try {
        const data = await axios.get(`${API_URL}/renewal/getReason`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getPic = async (id) => {
    try {
        const data = await axios.get(`${API_URL}/user/get-pic/${id}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


/** ------------------------------ **/
/**           ADMIN APIS           **/
/** ------------------------------ **/
export const getDashboardData = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/dashboard`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getPendingUser = async (skip) => {
    try {
        const data = await axios.get(`${API_URL}/user/get-pending-users?skip=${skip * 10}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const setProgram = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/createPrograms`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getProgram = async (feild) => {
    try {
        const data = await axios.get(`${API_URL}/admin/getAllPrograms`, { params: { feild: feild || null } })
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getProgramOfUni = async (id) => {
    try {
        const data = await axios.get(`${API_URL}/admin/getAllProgramsOfUni/${id}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const UpdProgram = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/UpdateProgram`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getUNI = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-all-universities`)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const createUniProfile = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/updateUniInfo`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const createCampus = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/createCampus`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getCampus = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getUNICampus`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const UpdateCampus = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/UpdateCampus`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const DeleteCampus = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/delete-campus/${body}`,)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const AddProgramsInUNI = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/addUniProgram`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const RemoveProgramID = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/removeUniProgram`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }

}

export const DeleteProgrambyId = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/delete-program/${body}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }

}


export const getUNIProfile = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getUniProfile`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const UpdateQualification = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/update-qualification`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const SubmissionForm1 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form1`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const SubmissionForm2 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form2`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const SubmissionForm3 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form3`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const SubmissionForm4 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form4`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const SubmissionForm5 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form5`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const SubmissionForm6 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form6`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const SubmissionForm7 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form8`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadPassportImage = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/passimage`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadGuardianCnic = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/cnicGuardian`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadResume = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/resume`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadCnicSelf = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/cnicSelf`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadCnicParent = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/cnicParent`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadCnicSpouse = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/cnicSpouse`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadCnicSibling = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/cnicSibling`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadDomicile = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/domicile`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadAdmissionLetter = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/admissionLetter`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadPaidFeeVoucher = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/paidFeeVoucher`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadUnpaidFeeVoucher = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/unpaidFeeVoucher`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadFeeLedger = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/feeLedger`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadChildrenBform = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/childrenBform`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadDeathCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/DeathCertificate`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadAcademicCertficates = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/academicCertficates`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadGuardianIncomeCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/guardianIncomeCertificate`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadSpouseIncomeCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/spouseIncomeCertificate`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadSelfIncomeCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/selfIncomeCertificate`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadSiblingIncomeCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/siblingIncomeCertificate`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadIncomeTaxFamily = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/IncomeTaxFamily`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadDeathCertificateFather = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/DeathCertificateFather`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadDeathCertificateMother = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/DeathCertificateMother`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}




export const uploadBankStatementFamily = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/BankStatementFamily`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadElectricBill = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/ElectricBill`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadGasBill = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/GasBill`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadTelephoneBill = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/TelephoneBill`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadwaterBill = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/waterBill`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadRentAgreement = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/RentAgreement`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAllStudents = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-students-list`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAllUniversities = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-all-universities`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAllOthers = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-all-others`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadBankStmt = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/bankStmt`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadSalarySlip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/salarySlip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadLatestTranscript = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/latestTranscript`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadfeeLedgerRenewal = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/paidFeeVoucher`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const createRenewalStep1 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/createRenewalStep1`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const createRenewalStep2 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/createRenewalStep2`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const createRenewalStep3 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/createRenewalStep3`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const form8 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/form8`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAllApplications = async (limit) => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-all-applications`, { params: { limit } })
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAllRenewal = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/getAllRenewal`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const updateApplicationStatus = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/UpdateApplicationStatus`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const updateRenewalStatus = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/UpdateRenewalStatus`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm1 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm1`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm2 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm2`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm3 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm3`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm4 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm4`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm5 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm5`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm6 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm6`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm7 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm7`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm8 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm8`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getFormsByAdmin = async (from, id) => {
    try {
        const data = await axios.get(`${API_URL}/admin/getAplication?form=${from}&id=${id}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getrenewal = async (body) => {
    try {
        const data = await axios.get(`${API_URL}/renewal/getrenewal`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getrenewalByAdmin = async (id) => {
    try {
        const data = await axios.get(`${API_URL}/renewal/getrenewalByAdmin?id=${id}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getStdCount = async (id) => {
    try {
        const data = await axios.get(`${API_URL}/uni/getStdCount`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getStdApplication = async (id) => {
    try {
        const data = await axios.get(`${API_URL}/uni/getStdApplication`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getForm9 = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getForm9`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAppData = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getApp`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getRenewalData = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getRenewal`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const getrenewalForLogs = async () => {
    try {
        const data = await axios.get(`${API_URL}/renewal/getrenewalForLogs`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const getRenewalReportForUni = async () => {
    try {
        const data = await axios.get(`${API_URL}/uni/getStdRenewal`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getFreshCasesOnly = async (status, limit) => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-fresh-cases`, { params: { status, limit } })
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getRenewalCasesOnly = async (status, limit) => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-renewal-cases`, { params: { status, limit } })
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getStudentProfile = async (id) => {
    try {
        const data = await axios.get(`${API_URL}/admin/getUserProfile`, { params: { id } })
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const cnicFather = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/cnicFather`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const cnicMother = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/cnicMother`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const matricResult = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/matricResult`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const interResult = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/interResult`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const bachelorsResult = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/bachelorsResult`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const mastersResult = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/mastersResult`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const othersResult = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/othersResult`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const fatherPaySlip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/fatherPaySlip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const motherPaySlip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/motherPaySlip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const selfPaySlip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/selfPaySlip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const siblingPaySlip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/siblingPaySlip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const spousePaySlip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/spousePaySlip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const fatherPension = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/fatherPension`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const motherPension = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/motherPension`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const selfPension = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/selfPension`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const siblingPension = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/siblingPension`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const spousePension = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/spousePension`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const fatherIncomeCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/fatherIncomeCertificate`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const motherIncomeCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/motherIncomeCertificate`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const fatherAgriIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/fatherAgriIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const motherAgriIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/motherAgriIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const spouseAgriIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/spouseAgriIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const siblingAgriIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/siblingAgriIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const selfAgriIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/selfAgriIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const fatherOtherIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/fatherOtherIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const motherOtherIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/motherOtherIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uploadSlip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/slip/uploadSlip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const siblingOtherIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/siblingOtherIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const selfOtherIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/selfOtherIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const employemnetIncomeCertificate = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/employemnetIncomeCertificate  `, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const undertaking = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/undertaking  `, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getStudentsData = async (body) => {
    try {
        const data = await axios.get(`${API_URL}/admin/getStudentsData?city=${body}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getTop5UNIS = async (body) => {
    try {
        const data = await axios.get(`${API_URL}/admin/getTop5UNIS`, { body })
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getTop5PROG = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/getTop5PROG`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const FiltersSTD = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/filtersStd?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getTab = async () => {
    try {
        const data = await axios.get(`${API_URL}/app/getTab`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const submission1 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/submission1`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const submission2 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/submission2`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const submission3 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/submission3`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const submission4 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/submission4`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const submission5 = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/renewal/submission5`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const AdminDashboardGraph = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/AdminDashboardGraph`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const universityFilter = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/uni/universityFilter`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const freshCasesFilter = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/uni/freshCasesFilter?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const freshCasesFilterAdmin = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/freshCasesFilter?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const renewalCasesFilter = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/renewalCasesFilter?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const uniFilter = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/uniFilter?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getFreshCaseDetail = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getFreshCaseDetail`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getRenewalCaseDetail = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getRenewalCaseDetail`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getFreshCaseApp = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getAllApp`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAllRenewalAdmin = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getAllRenewalAdmin`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const UpdateRenStatus = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/UpdateRenStatus`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const signUp = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/sign-up`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const forget_password = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/forget_password`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const reset_password = async (body, token) => {
    try {
        const data = await axios.post(`${API_URL}/user/reset_password/${token}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getUNIsData = async () => {
    try {
        const data = await axios.get(`${API_URL}/admin/getUNIsData`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getStudentData = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getStudentData`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getStudentDataRenewal = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getStudentData-renewal`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getAppDocuments = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getAppDocuments`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const RenewalCasesFilterUni = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/uni/RenewalCasesFilter?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getLogs = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getLogs`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const createInterviewSheet = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/createInterviewSheet`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getSheetData = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getSheetData`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const createMOU = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/create-mou`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const updateMOU = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/updateMOU`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const getMOU = async (uniID) => {
    try {
        const data = await axios.get(`${API_URL}/admin/get-mou/${uniID}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}






export const createApprovalSheet = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/createApprovalSheet`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const getApprovalSheetData = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getApprovalSheetData`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const ApprovalSheetFilter = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/ApprovalSheetFilter?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}





export const isMOUExpired = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/isMOUExpired`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}




export const getUniMOU = async () => {
    try {
        const data = await axios.get(`${API_URL}/uni/getUni-MOU`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const getRepayments = async () => {
    try {
        const data = await axios.get(`${API_URL}/user/getRepayments`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const payRepayment = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/createRepayment`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const getStudentRepayment = async (skip) => {
    try {
        const data = await axios.get(`${API_URL}/user/get-student-repayments?skip=${skip * 10}`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getRepaymentYear = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/getRepaymentYear`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const getRepayApps = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getRepayApps?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}




export const getRepayRenewal = async (body) => {
    try {
        const data = await axios.get(`${API_URL}/admin/getRepayRenewal`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const createRepayManual = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/createRepaymentManual`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const getInterviewSheetForSTD = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getInterviewSheetForSTD`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const getApprovalSheetForSTD = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getApprovalSheetForSTD`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const updateLogs = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/updateLogs`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const repayementFilter = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/user/repayementFilter`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const getAllTranch = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getAllTranch?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const payTranch = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/payTranch`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })
        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const spouseOtherIncome = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/app/spouseOtherIncome`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const getAllSlips = async () => {
    try {
        const data = await axios.get(`${API_URL}/slip/getAllSlips`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const getAllSlipsByUSer = async () => {
    try {
        const data = await axios.get(`${API_URL}/slip/getAllSlipsByUSer`)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const verifySLip = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/slip/verifySLip`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const updateRepaymentInvoice = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/updateRepaymentInvoice`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const undoTranch = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/undoTranch`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const getStudentsApplications = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getStudentsApplications`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const uniStatusToggle = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/uniStatusToggle`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const getStdTranch = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/getStdTranch`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const DisbursementAnalysis = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/DisbursementAnalysis?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const repaymentDisbursementAnalysis = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/repayment-DisbursementAnalysis?skip=${skip*10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}

export const repaymentAnalysis = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/repaymentAnalysis`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const TATAnalysisMonthlyFresh = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/TAT-Analysis-Monthly-fresh-updated`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}




export const TATAnalysisMonthlyRenewal = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/TAT-Analysis-Monthly-renewal-updated`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const TATInterview = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/TAT-Interview?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}


export const AGING = async (body) => {
    try {
        const data = await axios.post(`${API_URL}/admin/AGING`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}



export const Mastersheet = async (body, skip) => {
    try {
        const data = await axios.post(`${API_URL}/admin/mastersheet?skip=${skip * 10}`, body)
            .then(res => {
                console.log(res);
                return (res.data);
            })

        return data;
    }
    catch (err) {
        console.log(err.response.data);
        return err.response.data;
    }
}
