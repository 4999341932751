import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import { createInterviewSheet, getStudentDataRenewal, getSheetData } from '../../../ContextAPI/APIs/api';
import { FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import SignatureCanvas from 'react-signature-canvas'
import { useToast } from '../../../ContextAPI/Components/toast';
import { useAuth } from '../../../ContextAPI/Components/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import formatter from "../../../helpers/number_formatter"


function InterviewSheetRenewalPreview() {

    const { user } = useAuth();
    const { alert } = useToast();
    const { id, doc } = useParams();
    const navigate = useNavigate()


    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    // siblingDetails: { type: Array, required: false },

    const [data, setData] = useState({
        userId: id,
        documentIdRenewal: doc,
        name: "",
        institute: "",
        program: "",
        instituteName: "",
        programName: "",
        fatherIncome: "",
        motherIncome: "",
        haveSiblings: "",
        totalSiblings: 0,
        belongsTo: "",
        houseDetail: "",
        houseSize: "",
        locality: "",
        repayThisYear: "",
        repayLastYear: "",
        careerGoals: "",
        otherDetails: "",


        like: false,
        mblAcc: false,
        tarjumah: false,
        Dua_e_Qunoot: false,

        type: "renewal",
        otherRequirements: "",
        Recomendations: "",

        selfIncomeSource: "",
        currentSem: "",
        currentYear: "",
        examStructure: "",
        Semfee: "",
        Yearfee: "",
        feeStudent: "",
        feeOutstanding: "",
        brothers: 0,
        sisters: 0,
        rent: "",
        recomendationRupees: "",
        recomendationPercent: "",
        recomendationFee: "",
        recomendationYear: "",
        recomendationRepayment: "",
        nextApproval: "",
        lastApprovedAmount: "",
        semtype: "",

        semDetail: "",
        recomendationFeeNEXT: "",
        semDetailNEXT: "",
        recomendationYearNEXT: ""
    })


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log("V", name, value);


        if (name == "brothers") {
            const tot = parseInt(data.sisters) + parseInt(value)
            setData({ ...data, brothers: value, totalSiblings: tot })
        }
        if (name == "sisters") {
            const tot = parseInt(data.brothers) + parseInt(value)
            setData({ ...data, sisters: value, totalSiblings: tot })
        }

        if (name != "brothers" && name != "sisters") {
            setData((prevData) => ({
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    };



    const save = async (e) => {
        e.preventDefault()
        try {

            const payload = {
                ...data,
                interviewerDetails: interviewers,
                siblingsData: siblingsDetail
            }

            console.log("Dlll", payload);
            const response = await createInterviewSheet(payload)
            alert(response?.message, response?.success)
            if (response?.success) {
                // alert(response?.message, response?.success)
                // getSheet()
                getstdData()
                // navigate(`/renewal-cases-history/${id}/${doc}`)
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const [interviewers, setInterviewers] = useState([
        {
            Name: "",
            Signature: "",
        },
    ]);

    const interviewer_detail = {
        Name: "",
        Signature: "",
    }

    function Add_New_Interviewer(e) {
        e.preventDefault();
        setInterviewers([...interviewers, interviewer_detail])
    }

    function removeInterviewDetail(i) {

        const updated = [...interviewers]

        updated.splice(i, 1)
        setInterviewers(updated)

    }
    function handleInterviewChange(e, index, field) {
        const updated = [...interviewers];
        updated[index][field] = e.target.value;
        setInterviewers(updated);
    }



    const [siblingsDetail, setSiblingsDetail] = useState([
        {
            relation: "",
            maritalStatus: "",
            info: "",
            comments: ""
        },
    ]);

    const siblings_detail = {
        relation: "",
        maritalStatus: "",
        info: "",
        comments: ""
    }

    function Add_New_SiblingsDetail(e) {
        e.preventDefault();
        setSiblingsDetail([...siblingsDetail, siblings_detail])
    }

    function removeDetail(i) {

        const updated = [...siblingsDetail]

        updated.splice(i, 1)
        setSiblingsDetail(updated)

    }
    function handleSiblingChange(e, index, field) {
        const updated = [...siblingsDetail];
        updated[index][field] = e.target.value;
        setSiblingsDetail(updated);
    }



    const getstdData = async () => {
        try {
            const response = await getStudentDataRenewal({ id: id })
            const response2 = await getSheetData({ id: doc, type: "renewal" })
            console.log("response22222", response2.message);

            let upd = {}

            // if (response?.success && response2?.success && response2?.message !== null) {
            //     console.log("HHH@@@", response.message);
            //     setData({
            //         ...data,
            //         name: response?.message?.studentId?.fullName,
            //         institute: response?.message?.university?._id,
            //         program: response?.message?.program?._id,
            //         instituteName: response?.message?.university?.fullName,
            //         programName: response?.message?.program?.name,
            //         ...response2.message
            //     })
            //     setInterviewers(response2?.message?.interviewerDetails)
            //     setSiblingsDetail(response2?.message?.siblingsData)
            // }


            if ((response?.success && response?.message !== null) && (response2.message === null)) {
                upd = {
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    examStructure: response?.message?.type,
                    semtype: response?.message?.semtype,
                    currentSem: response?.message?.sem_year,
                    currentYear: response?.message?.sem_year,
                }
            }
            else {
                upd = {
                    ...data,
                    name: response?.message?.studentId?.fullName,
                    institute: response?.message?.university?._id,
                    program: response?.message?.program?._id,
                    instituteName: response?.message?.university?.fullName,
                    programName: response?.message?.program?.name,
                    ...response2.message
                }

                setInterviewers(response2?.message?.interviewerDetails)
                setSiblingsDetail(response2?.message?.siblingsData)

            }
            setData(upd)

        }
        catch (e) {
            console.log(e);
        }
    }
    const getSheet = async () => {
        try {
            const response = await getSheetData({ id: id, type: "renewal" })
            console.log("RSS", response);
            if (response?.success && response?.message !== null) {
                setData(response?.message)
                setInterviewers(response?.message?.interviewerDetails)
                setSiblingsDetail(response?.message?.siblingsData)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getstdData()
        // getSheet()
    }, [])


    return (
        <>
            <Helmet>
                <title>Interview Sheet Preview</title>
            </Helmet>


            <section className='interview_sheet_section'>


                <div className="interview_sheet_preview">
                    <form onSubmit={(e) => save(e)}>
                        <div className="row">

                            {/* <div className="d-flex jc-end">
                                <div className="form-group">
                                    <Input inputType="date" label="Date" />
                                </div>
                            </div> */}

                            <h4>Ihsan Trust Renewal Case Interview sheet Preview</h4>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input inputType="text" name="name" label="Applicant's Name" value={data.name} placeholder="" onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <Input inputType="text" name="institute" label="Name of the University/Institution" value={data.instituteName} placeholder="" onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <Input inputType="text" name="program" label="Program" value={data.programName} placeholder="" onChange={handleChange} disabled={true} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Input inputType="text" name="examStructure" label="Examination Structure" span="*" value={data.examStructure} placeholder="" onChange={handleChange} disabled={true} />
                                        </div>
                                    </div>
                                    {
                                        (data.examStructure !== "" && data.examStructure === "year") && (
                                            <>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="currentYear" label="Current Year" span="*" value={data.currentYear} placeholder="" onChange={handleChange} required disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="Yearfee" label="Per Year Fee" span="*" value={data.Yearfee} placeholder="" onChange={handleChange} required disabled={true} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        (data.examStructure !== "" && data.examStructure === "semester") && (
                                            <>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="currentSem" label="Current Semester" span="*" value={data.currentSem} placeholder="" onChange={handleChange} required disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <Input inputType="text" name="semtype" label="Academic Session" span="*" value={data.semtype} placeholder="" onChange={handleChange} required disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <Input inputType="number" name="Semfee" label="Per Semester Fee" span="*" value={data.Semfee} placeholder="" onChange={handleChange} required disabled={true} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <Input
                                        inputType="number"
                                        name="feeStudent"
                                        label="Fee Paid by Student"
                                        span="*"
                                        value={data.feeStudent}
                                        placeholder=""
                                        onChange={handleChange}
                                        required
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <Input inputType="number" name="feeOutstanding" label="Outstanding Fee" value={data.feeOutstanding} placeholder="Enter here" onChange={handleChange} />
                                    </div>
                                </div> */}

                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <Input inputType="date" name="nextApproval" label="Next Approval" span="*" value={data.nextApproval} placeholder="Enter here" onChange={handleChange} required />
                                </div>
                            </div> */}
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <Input inputType="number" name="lastApprovedAmount" label="Last Approved Amount" value={data.lastApprovedAmount} placeholder="" onChange={handleChange} disabled={true} />
                                </div>
                            </div>


                            <div className="accordion" id="accordionExample">
                                {/* Siblings Information */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button" type="button" disabled data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Siblings Information
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="form-group">
                                                        <Input inputType="text" name="haveSiblings" label="Do you have siblings?" span="*" value={data.haveSiblings} placeholder="" onChange={handleChange} disabled={true} />
                                                        {/* <label className='form-label'>Do you have siblings? <span className='required'>*</span></label>
                                                        <select name="haveSiblings" value={data.haveSiblings} onChange={handleChange} className="form-select" required>
                                                            <option disabled value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select> */}
                                                    </div>
                                                </div>
                                                {
                                                    (data.haveSiblings !== "" && data.haveSiblings === "yes") && (
                                                        <>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="form-group">
                                                                    <Input inputType="number" name="brothers" value={data.brothers} label="Number of Brothers" placeholder="" onChange={handleChange} disabled={true} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="form-group">
                                                                    <Input inputType="number" name="sisters" label="Number of Sisters" value={data.sisters} placeholder="" onChange={handleChange} disabled={true} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6">
                                                                <div className="form-group">
                                                                    <Input inputType="number" label="Total" value={data.totalSiblings} disabled={true} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="form_sub_heading">
                                                                    <h5 className='mb-0'> Siblings Detail</h5>
                                                                    <div className='d-flex jc-end'>
                                                                        <button className='btn add_btn' disabled onClick={(e) => Add_New_SiblingsDetail(e)}><FaPlus /></button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                siblingsDetail.map((data, i) => {
                                                                    return <>

                                                                        {
                                                                            i > 0 &&
                                                                            <div className="section_break"></div>
                                                                        }

                                                                        <div className="col-md-12">
                                                                            <div className="form_sub_heading mb-3">
                                                                                <h5 className='mb-0'>Sibling {i + 1}</h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <Input inputType="text" label="Relationship" value={siblingsDetail[i].relation} onChange={(e) => handleSiblingChange(e, i, 'relation')} placeholder={" "} disabled={true} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <Input inputType="text" label="Marital Status" value={siblingsDetail[i].maritalStatus} onChange={(e) => handleSiblingChange(e, i, 'maritalStatus')} placeholder={" "} disabled={true} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label className='form-label'>Sibling {i + 1} Information</label>
                                                                                <textarea className='form-control' value={siblingsDetail[i].info} onChange={(e) => handleSiblingChange(e, i, 'info')} cols="30" rows="3" placeholder='' disabled></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label className='form-label'>Sibling {i + 1} Comments</label>
                                                                                <textarea className='form-control' value={siblingsDetail[i].comments} onChange={(e) => handleSiblingChange(e, i, 'comments')} cols="30" rows="3" placeholder='' disabled></textarea>
                                                                            </div>
                                                                        </div>

                                                                        {/* {
                                                                            i > 0 &&
                                                                            <div className='d-flex jc-end'>
                                                                                <button onClick={() => removeDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                                            </div>
                                                                        } */}
                                                                    </>
                                                                })
                                                            }

                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Income Details */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" disabled data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Income Details
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form_sub_heading mb-3">
                                                        <h5 className='my-2'>Income Details</h5>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Source of Income (Self) <span className='required'>*</span></label>
                                                        <textarea name="selfIncomeSource" className='form-control' value={data.selfIncomeSource} cols="30" rows="3" placeholder='' onChange={handleChange} required disabled></textarea>
                                                        {/* <Editor
                                                            initialValue=""
                                                            init={{
                                                                branding: false,
                                                                height: 250,
                                                                menubar: true,
                                                                plugins: "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                                                toolbar: "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                                                image_advtab: true
                                                            }}
                                                            name="selfIncomeSource"
                                                            value={data.selfIncomeSource}
                                                            onChange={handleChange}
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Source of Income (Father) <span className='required'>*</span></label>
                                                        <textarea name="fatherIncomeSource" className='form-control' value={data.fatherIncomeSource} cols="30" rows="3" placeholder='' onChange={handleChange} required disabled></textarea>
                                                        {/* <Editor
                                                            initialValue=""
                                                            init={{
                                                                branding: false,
                                                                height: 250,
                                                                menubar: true,
                                                                plugins: "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                                                toolbar: "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                                                image_advtab: true
                                                            }}
                                                            name="fatherIncome"
                                                            value={data.fatherIncome}
                                                            onChange={handleChange}
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Source of Income (Mother) <span className='required'>*</span></label>
                                                        <textarea name="motherIncomeSource" className='form-control' value={data.motherIncomeSource} cols="30" rows="3" placeholder='Type here...' onChange={handleChange} required disabled></textarea>
                                                        {/* <Editor
                                                            initialValue=""
                                                            init={{
                                                                branding: false,
                                                                height: 250,
                                                                menubar: true,
                                                                plugins: "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                                                toolbar: "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                                                image_advtab: true
                                                            }}
                                                            name="motherIncome"
                                                            value={data.motherIncome}
                                                            onChange={handleChange}
                                                        /> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input inputType="text" name="belongsTo" label="Belongs to" span="*" value={data.belongsTo} placeholder="" onChange={handleChange} required disabled={true} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className='form-group'>
                                    <div className="house_size_input">
                                        <label className='form-label me-4'>Do you own or rent a house? <span className='required'>*</span></label>
                                        <div className="form-check me-5">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="houseDetail"
                                                id="ownedRadio"
                                                checked={data.houseDetail === 'owned'}
                                                onChange={() => handleChange({ target: { name: 'houseDetail', value: 'owned', type: 'radio' } })}
                                                required
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="ownedRadio">
                                                Owned
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="houseDetail"
                                                id="rentalRadio"
                                                checked={data.houseDetail === 'rental'}
                                                onChange={() => handleChange({ target: { name: 'houseDetail', value: 'rental', type: 'radio' } })}
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="rentalRadio">
                                                Rental
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className='form-group'>
                                    <Input inputType="text" name="houseSize" value={data.houseSize} label="Size of the House(yards)" span="*" placeholder="" onChange={handleChange} required disabled={true} />
                                </div>
                            </div>
                            {
                                data.houseDetail === "rental" && (
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className='form-group'>
                                            <Input inputType="text" name="rent" value={data.rent} label="Monthly Rent (Rs.)" span="*" placeholder="" onChange={handleChange}
                                                required={data.houseDetail === 'rental' ? true : false}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className='form-group'>
                                    <Input inputType="text" name="locality" value={data.locality} label="Locality" span="*" placeholder="" onChange={handleChange} required disabled={true} />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <Input inputType="number" name="repayThisYear" label="Monthly repayment amount for this year (Rs.)" span="*" value={data.repayThisYear} placeholder="" onChange={handleChange} required disabled={true} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <Input inputType="number" name="repayLastYear" label="Last year results" span="*" value={data.repayLastYear} placeholder="" onChange={handleChange} required disabled={true} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="like"
                                                    checked={data.like}
                                                    onChange={handleChange}
                                                    id="likeCheckbox"
                                                    required
                                                    disabled
                                                />
                                                <label className="form-check-label form-label" htmlFor="likeCheckbox">
                                                    FB & Insta Like
                                                    <span className='required'>*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="mblAcc"
                                                    checked={data.mblAcc}
                                                    onChange={handleChange}
                                                    id="mblAccCheckbox"
                                                    required
                                                    disabled
                                                />
                                                <label className="form-check-label form-label" htmlFor="mblAccCheckbox">
                                                    MBL Account
                                                    <span className='required'>*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="tarjumah"
                                                    checked={data.tarjumah}
                                                    onChange={handleChange}
                                                    id="tarjumahCheckbox"
                                                    disabled
                                                />
                                                <label className="form-check-label form-label" htmlFor="tarjumahCheckbox">
                                                    Tarjuma & Tashreeh
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="Dua_e_Qunoot"
                                                    checked={data.Dua_e_Qunoot}
                                                    onChange={handleChange}
                                                    id="duaQunootCheckbox"
                                                    disabled
                                                />
                                                <label className="form-check-label form-label" htmlFor="duaQunootCheckbox">
                                                    Dua e Qanoot
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='form-label'>Career goals of the candidate</label>
                                    <textarea className='form-control' name="careerGoals" value={data.careerGoals} cols="30" rows="3" placeholder='' onChange={handleChange} disabled></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='form-label'>Other Detail</label>
                                    <textarea className='form-control' name="otherDetails" value={data.otherDetails} cols="30" rows="3" placeholder='' onChange={handleChange} disabled></textarea>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group">
                                    <label className='form-label'>Other Requirements</label>
                                    <textarea className='form-control' name="otherRequirements" value={data.otherRequirements} cols="30" rows="3" placeholder='' onChange={handleChange} disabled></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <h5>RECOMMENDATIONS:</h5>
                                <div className='recommendation'>
                                    <p>
                                        As per our recommendation, the student seems to be needy as the applicant’s family income
                                        is insufficient to ear educational expenses in full, and the applicant has requested for
                                        financial assistance
                                    </p>

                                    <div className='d-flex flex-wrap ai-center'>
                                        <p>@ Rs.</p>
                                        <input type="number" value={data.recomendationRupees} name="recomendationRupees" onChange={handleChange} className='form-control recomend_input' disabled />
                                        <p>/- i.e.</p>
                                        <input type="number" value={data.recomendationPercent} name="recomendationPercent" onChange={handleChange} className='form-control recomend_input' disabled />
                                        <p>% of fee for</p>
                                        <input type="number" value={data.recomendationFee} name="recomendationFee" onChange={handleChange} className='form-control recomend_input' disabled />

                                        {/* year */}
                                        {
                                            data.examStructure === "semester" ?
                                                <>
                                                    <p>semester</p> &nbsp;
                                                </>
                                                :
                                                data.examStructure === "year" ?
                                                    <>
                                                        <p>year</p> &nbsp;
                                                    </>
                                                    :
                                                    "---"
                                        }

                                        {
                                            data.examStructure === "semester" && (
                                                <>
                                                    {/* <label className='form-label'>Fall/Spring</label> */}
                                                    <input type="text" value={data.semDetail} name="semDetail" onChange={handleChange} className='form-control recomend_input' disabled />
                                                    {/* <select name="semDetail" value={data.semDetail} onChange={handleChange} className='form-select' disabled >
                                                        <option selected disabled value="">Select</option>
                                                        <option value="spring">Spring</option>
                                                        <option value="fall">Fall</option>
                                                    </select> */}
                                                </>
                                            )
                                        }

                                        {
                                            data.examStructure === "semester" && (
                                                <>
                                                    {/* <div className='d-flex ai-center' style={{ textWrap: "nowrap" }}> */}
                                                    <input type="number" value={data.recomendationYear} name="recomendationYear" onChange={handleChange} className='form-control recomend_input' disabled />
                                                    <p>and furthermore % of fee for</p>
                                                    <input type="number" value={data.recomendationFeeNEXT} name="recomendationFeeNEXT" onChange={handleChange} className='form-control recomend_input' disabled />
                                                    <p>semester</p> &nbsp;
                                                    {/* <div className="w-25"> */}
                                                    {/* <label className='form-label'>Fall/Spring</label> */}
                                                    <input type="text" value={data.semDetailNEXT} name="semDetailNEXT" onChange={handleChange} className='form-control recomend_input' disabled />
                                                    {/* <select name="semDetailNEXT" value={data.semDetailNEXT} onChange={handleChange} className='form-select' disabled >
                                                        <option selected disabled value="">Select</option>
                                                        <option value="spring">Spring</option>
                                                        <option value="fall">Fall</option>
                                                    </select> */}
                                                    {/* </div> */}
                                                    <input type="number" value={data.recomendationYearNEXT} name="recomendationYearNEXT" onChange={handleChange} className='form-control recomend_input' disabled />
                                                    {/* </div> */}
                                                </>
                                            )
                                        }


                                        {/* <div className='d-flex ai-center' style={{ textWrap: "nowrap" }}> */}
                                        <div>
                                            <p>could be given as Interest Free Loan with monthly repayments</p>
                                        </div>

                                        &nbsp;

                                        <p>@ Rs.</p>
                                        <input type="number" value={data.recomendationRepayment} name="recomendationRepayment" onChange={handleChange} className='form-control recomend_input' disabled />
                                        <p>/- per month.</p>
                                        {/* </div> */}

                                    </div>

                                </div>
                            </div>

                            <div className="">

                                <div className="form_sub_heading">
                                    <h5 className='mb-0'>Interviewers</h5>
                                    <div className='d-flex jc-end'>
                                        <button className='btn add_btn' disabled onClick={(e) => Add_New_Interviewer(e)}><FaPlus /></button>
                                    </div>
                                </div>

                                {
                                    interviewers.length > 0 && interviewers.map((data, i) => (
                                        <>

                                            {
                                                i > 0 &&
                                                <div className="section_break"></div>
                                            }

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className='form-label'>Name</label>
                                                        <input value={interviewers[i].Name} onChange={(e) => handleInterviewChange(e, i, "Name")} type="text" className='form-control' placeholder='' required disabled />
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className='form-label'>Signature</label>
                                                                <input value={interviewers[i].Signature} onChange={(e) => handleInterviewChange(e, i, "Signature")} type="text" className='form-control' />
                                                            </div>
                                                        </div> */}

                                            </div>

                                            {/* {
                                                i > 0 &&
                                                <div className='d-flex jc-end'>
                                                    <button onClick={() => removeInterviewDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                                </div>
                                            } */}

                                        </>
                                    ))
                                }

                            </div>
                        </div>
                        {
                            (user?.role == "admin" || user?.role == "operations") && (
                                data.documentIdRenewal.RenewStatus !== "disburse" && (
                                    < div className='d-flex jc-end'>
                                        <button className='btn save_btn' type='submit'>Save</button>
                                    </div>
                                )
                            )
                        }
                    </form>

                </div>
            </section>

        </>
    )
}

export default InterviewSheetRenewalPreview
