import React from 'react'
import { useState, useEffect } from 'react';
import { Next } from 'react-bootstrap/esm/PageItem';
import { useLocation, useNavigate } from 'react-router-dom';

import { getForm7, SubmissionForm7 } from "../../ContextAPI/APIs/api"
import { useAuth } from "../../ContextAPI/Components/auth"
import { useToast } from "../../ContextAPI/Components/toast"
import { FaPlus } from 'react-icons/fa';

import Form from "react-bootstrap/Form"

function Step_8() {
    const { alert } = useToast();
    const { GetLoginUser } = useAuth();
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);

    const [assetDetails, setAssetDetails] = useState({
        airCondition: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        car: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        motorCycle: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        television: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
        computerLaptop: { yesNo: '', quantity: 0, currentValue: 0, registrationNo: '' },
    });

    // Function to handle input changes
    const handleInputChange = (item, field, value) => {
        setAssetDetails(prevState => ({
            ...prevState,
            [item]: {
                ...prevState[item],
                [field]: value,
            },
        }));
    };

    const [data, setData] = useState({
        fatherBusiness: "",
        motherBusiness: "",
        spouseBusiness: "",
        selfBusiness: "",
        relativeBusiness: "",
        TotalBusiness: "",
        fatherLand: "",
        motherLand: "",
        spouseLand: "",
        selfLand: "",
        relativeLand: "",
        TotalLand: "",
        fatherSaving: "",
        motherSaving: "",
        spouseSaving: "",
        selfSaving: "",
        relativeSaving: "",
        TotalSaving: "",
        fatherShare: "",
        motherShare: "",
        spouseShare: "",
        selfShare: "",
        relativeShare: "",
        TotalShare: "",
        fatherOtherAssets: "",
        motherOtherAssets: "",
        spouseOtherAssets: "",
        selfOtherAssets: "",
        relativeOtherAssets: "",
        TotalOtherAssets: "",
        fatherTotal: "",
        motherTotal: "",
        spouseTotal: "",
        selfTotal: "",
        relativeTotal: "",
        TotalTotalBusiness: "",
    })

    const handleInput2Change = (e, category, person) => {
        const { value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [`${person}${category}`]: value,
        }));
    };

    const Next = async () => {
        try {
            console.log("assetDetails", assetDetails);

            const payload = {
                ...data,
                HouseHoldAssets: [
                    assetDetails.airCondition,
                    assetDetails.car,
                    assetDetails.motorCycle,
                    assetDetails.television,
                    assetDetails.computerLaptop
                ]
            }

            console.log("DATA", payload);
            const response = await SubmissionForm7(payload)
            if (response.success) {
                navigate(`#${"step_9"}`)
                // GetLoginUser()
            }
            else {
                alert(response.message, response.success)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getFormData = async () => {
        try {
            const response = await getForm7()
            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
                // setFamilyMembStd(response.message.familyMembersStudying)
                setAssetDetails({
                    ...assetDetails,
                    airCondition: response.message.HouseHoldAssets[0],
                    car: response.message.HouseHoldAssets[1],
                    motorCycle: response.message.HouseHoldAssets[2],
                    television: response.message.HouseHoldAssets[3],
                    computerLaptop: response.message.HouseHoldAssets[4]
                })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getFormData()
    }, [])


    const [houseDetail, setHouseDetail] = useState([
        {
            Item_Name: "",
            Quantity: "",
            Current_Value: "",
        },
    ]);

    const h_detail = {
        Item_Name: "",
        Quantity: "",
        Current_Value: "",
    }

    function House_Hold_Items(e) {
        e.preventDefault();
        setHouseDetail([...houseDetail, h_detail])
    }

    const [totalProperty, setTotalProperty] = useState([
        {
            Value_Asset: "",
            Father: "",
            Mother: "",
            Spouse: "",
            Self: "",
            Brother_Sister_Children: "",
            Total: "",
        },
    ]);

    const t_Property = {
        Value_Asset: "",
        Father: "",
        Mother: "",
        Spouse: "",
        Self: "",
        Brother_Sister_Children: "",
        Total: "",
    }

    function Total_Property(e) {
        e.preventDefault();
        setTotalProperty([...totalProperty, t_Property])
    }

    function removeTPDetail(i) {
        const updated = [...totalProperty]
        updated.splice(i, 1)
        setTotalProperty(updated)
    }

    function removeHouseDetail(i) {
        const updated = [...houseDetail]
        updated.splice(i, 1)
        setHouseDetail(updated)
    }

    return (
        <>
            <div className="row">
                {/* House_Hold_Items */}
                <div className="table_form mb-4">
                    {/* <h4>Details of Asset / Property (please mention the current market value in Rs.)</h4> */}

                    <div className='mb-4 form_sub_heading'>
                        <h5 className='mb-0'>House Hold Items (please mention the current market value in Rs.)</h5>
                        <button className='btn add_btn' onClick={(e) => House_Hold_Items(e)}><FaPlus /></button>
                    </div>

                    {
                        houseDetail.map((item, i) => {
                            return <>
                                {
                                    i > 0 &&
                                    <div className="section_break"></div>
                                }

                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Group className="form-group">
                                            <label className='form-label'>Item Name</label>
                                            <Form.Control type="text" placeholder='Enter here' />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Value.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Group className="form-group">
                                            <label className='form-label'>Quantity</label>
                                            <Form.Control type="number" placeholder='Enter here' />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Value.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Group className="form-group">
                                            <label className='form-label'>Current Value (Rs.)</label>
                                            <Form.Control type="number" placeholder='Enter here' />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Value.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                    {i > 0 && (
                                        <div className='d-flex jc-end'>
                                            <button onClick={() => removeHouseDetail(i)} className='btn delete_btn'>Remove Detail</button>
                                        </div>
                                    )}
                                </div>
                            </>
                        })
                    }

                </div>

                {/* Total_Property */}
                <div className="table_form mb-4">
                    <div className='mb-4 form_sub_heading'>
                        <h5 className='mb-0'>Total Value of Property Owned (Rs.)</h5>
                        <button className='btn add_btn' onClick={(e) => Total_Property(e)}><FaPlus /></button>
                    </div>



                    <div className="row">
                        <div className="col-md-12">
                            <Form.Group className="form-group">
                                <label className='form-label'>Asset's Total</label>
                                <Form.Control type="number" placeholder='Enter here' disabled />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Valid Value.
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>

                </div>

            </div>


        </>
    )
}

export default Step_8
