import React from 'react'
import { Helmet } from 'react-helmet'
import { useProSidebar } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Table from '../../components/Table/Table';
import IconText from '../../components/Table/IconText';
import Text from '../../components/Table/Text';
import TooltipComponent from '../../components/tooltip';
import Pagination from '../../components/Pagination';
import { useState } from 'react';
import { getAllRenewal, getProgram, getRenewalReportForUni, RenewalCasesFilter, RenewalCasesFilterUni, updateRenewalStatus } from '../../ContextAPI/APIs/api';
import { useEffect } from 'react';
import { TbReportAnalytics } from "react-icons/tb";
import { useToast } from "../../ContextAPI/Components/toast"
import { useConfirm } from '../../ContextAPI/Components/confirm';
import Badges from '../../components/Badges';
import { status } from "../../helpers/data"


function RenewalCasesReport() {

    const { Ask } = useConfirm()
    const { alert } = useToast()
    const [selectedProgram, setSelectedProgram] = useState([])

    const [modalItem, setModalItem] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };


    const navigate = useNavigate();
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    const [renewal, setRenewal] = useState([])
    const [reason, setReason] = useState("")

    const [totalCount, setTotalCount] = useState(4)
    const getAllRenewalApp = async () => {
        try {
            const response = await RenewalCasesFilterUni({}, pageSkip)
            console.log("RESPONSE", response);
            if (response.success) {
                setRenewal(response.message)
                setTotalCount(response.totalCount)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const actionAdmin = async (id, act) => {
        try {
            if (await Ask() == false) return

            const payload = {
                userId: id,
                action: act,
                reason: reason
            }
            // const response = await updateRenewalStatus(payload)
            const response = await RenewalCasesFilterUni(payload)
            if (response.success) {
                alert(response.message, response.success)
                getAllRenewalApp()
            }
            else {
                alert(response.message, response.success)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    function timestampToDate(timestamp) {

        if (timestamp) {
            const milliseconds = timestamp.length === 10 ? timestamp * 1000 : timestamp;
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
            const day = date.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}-${month}-${year}`
            return formattedDate

        }
        else {
            return "---"
        }
    }


    const handleChange = (e) => {
        setReason(e.target.value)
    }

    const [prog, setProg] = useState([])
    const getAllProg = async () => {
        try {
            const response = await getProgram()
            if (response.success) {
                console.log("RRRR", response);
                setProg(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [selectedStatus, setSelectedStatus] = useState([])

    const handleStatusChange = (id) => {

        const isSelected = selectedStatus.includes(id);
        if (isSelected) {
            const abc = selectedStatus.filter((uniId) => uniId !== id)

            setSelectedStatus(abc);

            search(selectedProgram, abc, pageSkip)
        } else {
            const upd = [...selectedStatus, id]
            setSelectedStatus(upd);

            // search()

            search(selectedProgram, upd, pageSkip)
        }
    };

    const handleProgramChange = (id) => {
        const isSelected = selectedProgram.includes(id);
        if (isSelected) {
            const abc = selectedProgram.filter((uniId) => uniId !== id)

            // setSelectedProgram((prevSelectedUni) => prevSelectedUni.filter((uniId) => uniId !== id));
            setSelectedProgram(abc)
            search(abc, selectedStatus, pageSkip)
        } else {
            const upd = [...selectedProgram, id]

            // setSelectedProgram((prevSelectedUni) => [...prevSelectedUni, id]);
            setSelectedProgram(upd);
            search(upd, selectedStatus, pageSkip)
        }
    };


    const search = async (prog, stat, skip) => {
        try {

            const payload = {
                program: prog,
                status: stat
            }
            const response = await RenewalCasesFilterUni(payload, skip)
            if (response.success) {
                console.log("FILTERR", response.message);
                // setAllUsers(response.message)
                // setFresh(response.message)
                setRenewal(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const clear = () => {
        try {
            setSelectedProgram([])
            search([], [], pageSkip)
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getAllRenewalApp()
        getAllProg()
    }, [])


    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        search(selectedProgram, selectedStatus, skip)
    }


    return (
        <>
            <Helmet>
                <title>Renewal Cases Report</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='renewal_cases_section'>
                        <h2>Renewal Cases Report</h2>

                        <div className="card">

                            <div className="filter d-flex ai-center jc-between mb-3">
                                <div className='d-flex ai-center'>
                                    <p>Filter By</p>

                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Programs
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>

                                                {
                                                    prog.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item._id}`}>{item.name}</label>
                                                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item._id}`}
                                                                    onChange={() => handleProgramChange(item._id)}
                                                                    checked={selectedProgram.includes(item._id)}
                                                                />

                                                            </div>
                                                        </li>
                                                    ))
                                                }

                                            </div>
                                        </ul>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn filter_btn dropdown-toggle me-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Status
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <div className='uni_filter_header d-flex jc-between'>
                                                <p>Name</p>
                                                <p className='me-0'>Filter</p>
                                            </div>
                                            <div className='uni_filter_body'>
                                                {
                                                    status.map((item, i) => (
                                                        <li key={i}>
                                                            <div className="form-check">
                                                                <label className="form-check-label text-capital" htmlFor={`uniCheckbox_${item}`}>{item}</label>
                                                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`uniCheckbox_${item}`}
                                                                    onChange={() => handleStatusChange(item)}
                                                                    checked={selectedStatus.includes(item)}
                                                                />

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </ul>
                                    </div>

                                </div>
                                <div className='d-flex'>
                                    {
                                        selectedProgram?.length > 0 && (
                                            <button onClick={() => clear()} className='btn clear_btn'>Clear</button>
                                        )
                                    }
                                    {/* <button onClick={() => search()} className='btn clear_btn ml-2'>Search</button> */}
                                </div>
                            </div>

                            {
                                (renewal.length) > 0 ?
                                    <>
                                        <Table head={["Student Name", "University Name", "Program", "Status", "Submission Date", "Approved Semester/Year", ""]}>
                                            {
                                                (renewal.length) > 0 && renewal?.map((data, i) => {
                                                    return <tr key={i}>
                                                        <td>
                                                            <div className='d-flex ai-center'>
                                                                <TooltipComponent className="" placement="top" tooltipText="View Form">
                                                                    <div><TbReportAnalytics className='icon' onClick={() => navigate(`/uni-renewal/${data?.userId._id}/${data?._id}`)} /></div>
                                                                </TooltipComponent>
                                                                <IconText text={data?.userId?.fullName || ""} />
                                                            </div>
                                                        </td>
                                                        <td><Text text={data?.Institute?.fullName || ""} /></td>
                                                        <td className='text-capital'><Text text={data?.Program?.name} /></td>
                                                        <td><Badges text={data?.RenewStatus || "---"} classes={`${data?.RenewStatus} md`} /></td>
                                                        <td><Text text={timestampToDate(data?.timestamp)} /></td>
                                                        <td className='text-center'><Text text={data?.sem_year || "---"} /></td>
                                                        <td>
                                                            <Link to={`/uni-renewal-cases-history/${data?.userId?._id}/${data?._id}`} className='link'>
                                                                <button className='btn save_btn'>Case Detail</button>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/uni-renewal/${data?.userId._id}/${data?._id}`} className='link'>
                                                                <button className='btn save_btn'>View Application</button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                })
                                            }

                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={4} />
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }

                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default RenewalCasesReport
