import axios from 'axios';
import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom"
import { useCookies } from 'react-cookie';
import { useAuth } from "../ContextAPI/Components/auth"
import PrivateRoute from "./privateRoutes";
import PrivateRoute1 from "./privateRoutes";
import PrivateRoute2 from "./privateRoutes";
import PrivateRoute3 from "./privateRoutes";


import Profile from '../pages/Profile';
import SignUp from '../pages/SignUp';
import SignIn from '../pages/Signin';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import CryptoJS from 'crypto-js';
import AdminDashboard from '../pages/AdminPortal/AdminDashboard';
import CreateUser from '../pages/AdminPortal/CreateUser';
import AllUsers_Students from '../pages/AdminPortal/AllUsers.Students';
import AllUsers_Universities from '../pages/AdminPortal/AllUsers.Universities';
import AllUsers_Others from '../pages/AdminPortal/AllUsers.Others';
import ApprovalFlow from '../pages/AdminPortal/ApprovalFlow';
import DisbustmentProcess from '../pages/AdminPortal/DisbustmentProcess';
import Programs from '../pages/AdminPortal/Programs';
import UniversityProfile from '../components/Profile/university_index';
import { UniversityProfileUpdate } from '../components/Profile/university_profile_info';

import CasesHistory from '../pages/AdminPortal/CasesHistory';

import FreshCases from '../pages/AdminPortal/FreshCases';
import FreshCaseDetails from '../pages/AdminPortal/FreshCaseDetails';
import InterviewSheet from '../pages/AdminPortal/InterviewSheet';
import RecomendationForm from '../pages/AdminPortal/RecommendationForm';
import ApprovalForm from '../pages/AdminPortal/ApprovalForm';

import RenewalCases from '../pages/AdminPortal/RenewalCases';
import RenewalCasesDetail from '../pages/AdminPortal/RenewalCasesDetail/RenewalCasesDetail';
import RenewalCasesHistory from '../pages/AdminPortal/RenewalCasesDetail/RenewalCasesHistory';
import InterviewSheetRenewal from '../pages/AdminPortal/RenewalCasesDetail/InterviewSheetRenewal';
import ApprovalFormRenewal from '../pages/AdminPortal/RenewalCasesDetail/ApprovalFormRenewal';

import ApplicationFormAdmin from '../pages/AdminPortal/ApplicationFormAdmin';
import RenewalFormForAdmin from '../pages/AdminPortal/RenewalFormForAdmin';
import StudentReport from '../pages/AdminPortal/StudentReport';
import Tranch from '../pages/AdminPortal/tranch';

import StudentDashboard from '../pages/Students/StudentDashboard';
// import StudentReport from '../pages/Students/StudentReport';
import ApplicationTracking from '../pages/ApplicationTracking';
import ApplicationForm from '../pages/Students/Form/ApplicationForm';
import RenewalForm from '../pages/Students/Form/RenewalForm';

import UniversityDashboard from '../pages/University/UniversityDashboard';
import FreshCasesReport from '../pages/University/FreshCasesReport';
import RenewalCasesReport from '../pages/University/RenewalCasesReport';
import Disbustment from '../pages/University/Disbustment';

import { PageNotFound } from '../pages/PageNotFound';
import { Profile_UniversityProfileView } from '../components/Profile/profile_university_profile_view';
import ApprovalSheet from '../pages/AdminPortal/ApprovalSheet';
import ApprovalSheetRenewal from '../pages/AdminPortal/ApprovalSheetRenewal';
import ManualRepay from "../pages/AdminPortal/ManualRepay"
import EmailVerification from '../pages/EmailVerification';
import InterviewSheetPreview from '../pages/AdminPortal/InterviewSheetPreview';
import InterviewSheetRenewalPreview from '../pages/AdminPortal/RenewalCasesDetail/InterviewSheetRenewalPreview';
import DisbursementAnalaysisSTD from "../pages/AdminPortal/disbursementAnalysisStudent"
import DisbursementAnalaysisUNI from "../pages/AdminPortal/disbursementAnalysisUniversity"
import DisbursementRepayAnalysis from "../pages/AdminPortal/disburse&RepayAnalysis"
import RepayAnalysis from "../pages/AdminPortal/RepayAnalysis"
import TATMonthlyAnalysis from '../pages/AdminPortal/TAT_Fresh_Renewal/TATMonthlyAnalysis';
import UniFreshCaseDetails from '../pages/University/UniFreshCaseDetails';
import UniRenewalCaseDetails from '../pages/University/UniRenewalCaseDetails';
import TAT_Interview_Analysis from '../pages/AdminPortal/TAT_Interview_Analysis';
import AgingAnalysis from '../pages/AdminPortal/AgingAnalysis';
import MasterSheet from '../pages/AdminPortal/MasterSheet';
import Reporting_2 from '../pages/AdminPortal/Reporting_2';
import TAT_Interview_Analysis_Renewal from '../pages/AdminPortal/TAT_Interview_Analysis_Renewal';

function MyRoutes() {
    const { setUser, GetLoginUser, setLoader } = useAuth()
    const [cookies, setCookie, removeCookie] = useCookies(["pk2"]);
    const [count, setcount] = useState(0)
    console.log(count);
    console.log("cookies", cookies);
    const navigate = useNavigate();


    axios.defaults.withCredentials = true
    axios.defaults.headers.common["pk2"] = cookies.pk2

    let a = 0
    axios.interceptors.response.use(
        function (response) {
            //1111
            a--
            if (a == 0) {
                setLoader(false)
            }
            return response
        },
        function (error) {
            // 1111
            a--
            if (a == 0) {
                setLoader(false)
            }
            // Do something with response error
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 200 to 299
                if (error.response.data.error === "auth token required") {
                    removeCookie("pk2");
                    setUser(null)
                    console.clear()
                    navigate("/sign-in");
                }
            } else if (error.request) {
                // The request was made but no response was received
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }
            console.log(error.config);
            // Return a new promise that rejects with the error
            return Promise.reject(error);
        }
    );



    axios.interceptors.request.use(function (config) {
        //111
        a++
        setLoader(true)
        return config;
    }
        , function (error) {
            return Promise.reject(error);
        }
    );



    
    useEffect(() => {
        if ((window.location.host).search("localhost") > -1) {
            return () => {
                GetLoginUser()
            }

        } else {
            GetLoginUser()
        }

        setcount(count + 1)

        return () => { }

    }, [])


    return (
        <>
            <Routes>
                <Route exact path='/' element={<SignIn />} />
                <Route exact path='/sign-in' element={<SignIn />} />
                <Route exact path='/sign-up' element={<SignUp />} />
                <Route exact path='/email-verification' element={<EmailVerification />} />
                <Route exact path="/forgot-password/" element={<ForgotPassword />} />
                <Route exact path="/reset-password/:token" element={<ResetPassword />} />


                <Route element={<PrivateRoute role={"student"} />}>
                    <Route exact path='/std-profile' element={<Profile />} />
                    <Route exact path='/application-tracking' element={<ApplicationTracking />} />
                    <Route exact path='/dashboard' element={<StudentDashboard />} />
                    <Route exact path='/application-form' element={<ApplicationForm />} />
                    <Route exact path='/renewal-form' element={<RenewalForm />} />
                </Route>

                <Route element={<PrivateRoute role={"university"} />}>
                    <Route exact path='/university-dashboard' element={<UniversityDashboard />} />
                    <Route exact path='/fresh-cases-report' element={<FreshCasesReport />} />
                    <Route exact path='/renewal-cases-report' element={<RenewalCasesReport />} />
                    <Route exact path='/disbustment' element={<Disbustment />} />
                    <Route exact path='/university-profile' element={<Profile_UniversityProfileView />} />
                    <Route exact path='/uni-fresh-cases-history/:id/:doc' element={<UniFreshCaseDetails />} />
                    <Route exact path='/uni-renewal-cases-history/:id/:doc' element={<UniRenewalCaseDetails />} />
                    {/* <Route exact path='/uni-fresh-cases-history/:id/:doc' element={<CasesHistory />} /> */}
                    {/* <Route exact path='/uni-renewal-cases-history/:id/:doc' element={<RenewalCasesHistory />} /> */}
                    <Route exact path='/uni-interview-sheet/:id/:doc' element={<InterviewSheet />} />
                    <Route exact path='/uni-interview-sheet-renewal/:id/:doc' element={<InterviewSheetRenewal />} />
                    <Route exact path='/uni-approval-form/:id/:doc' element={<ApprovalForm />} />
                    <Route exact path='/uni-approval-form-renewal/:id/:doc' element={<ApprovalFormRenewal />} />
                    <Route exact path='/uni-application/:id/:doc' element={<ApplicationFormAdmin />} />
                    <Route exact path='/uni-renewal/:id/:doc' element={<RenewalFormForAdmin />} />
                </Route>

                <Route element={<PrivateRoute role={"management"} />}>
                    <Route exact path='/profile' element={<Profile />} />
                    <Route exact path='/student-report/:id' element={<StudentReport />} />
                    <Route exact path='/admin-dashboard' element={<AdminDashboard />} />
                    <Route exact path='/create-user' element={<CreateUser />} />
                    <Route exact path='/approval-flow' element={<ApprovalFlow />} />
                    <Route exact path='/disbustment-process' element={<DisbustmentProcess />} />
                    <Route exact path='/all-users/students-list' element={<AllUsers_Students />} />
                    <Route exact path='/all-users/universities-list' element={<AllUsers_Universities />} />
                    <Route exact path='/all-users/others-list' element={<AllUsers_Others />} />

                    <Route exact path='/university-profile' element={<Profile_UniversityProfileView />} />
                    <Route exact path='/university-profile/:id' element={<UniversityProfile />} />
                    <Route exact path='/update-university-profile/:id' element={<UniversityProfileUpdate />} />

                    <Route exact path='/programs' element={<Programs />} />
                    <Route exact path='/tranch' element={<Tranch />} />
                    <Route exact path='/cases/fresh-cases' element={<FreshCases />} />
                    <Route exact path='/cases/renewal-cases' element={<RenewalCases />} />

                    <Route exact path='/fresh-cases-history/:id/:doc' element={<FreshCaseDetails />} />
                    {/* <Route exact path='/fresh-cases-history/:id/:doc' element={<CasesHistory />} /> */}

                    <Route exact path='/renewal-cases-history/:id/:doc' element={<RenewalCasesDetail />} />
                    {/* <Route exact path='/renewal-cases-history/:id/:doc' element={<RenewalCasesHistory />} /> */}

                    <Route exact path='/interview-sheet-preview/:id/:doc' element={<InterviewSheetPreview />} />
                    <Route exact path='/interview-sheet/:id/:doc' element={<InterviewSheet />} />
                    <Route exact path='/interview-sheet-renewal-preview/:id/:doc' element={<InterviewSheetRenewalPreview />} />
                    <Route exact path='/interview-sheet-renewal/:id/:doc' element={<InterviewSheetRenewal />} />
                    <Route exact path='/recomendation-form/:id/:doc' element={<RecomendationForm />} />
                    <Route exact path='/approval-form/:id/:doc' element={<ApprovalForm />} />
                    <Route exact path='/approval-form-renewal/:id/:doc' element={<ApprovalFormRenewal />} />

                    <Route exact path='/approval-sheets/fresh' element={<ApprovalSheet />} />
                    <Route exact path='/approval-sheets/renewal' element={<ApprovalSheetRenewal />} />
                    <Route exact path='/create-repayments' element={<ManualRepay />} />
                    <Route exact path='/application/:id/:doc' element={<ApplicationFormAdmin />} />
                    <Route exact path='/renewal/:id/:doc' element={<RenewalFormForAdmin />} />

                    <Route exact path='/reporting/disbursementAnalysis-Student' element={<DisbursementAnalaysisSTD />} />
                    <Route exact path='/reporting/disbursementAnalysis-University' element={<DisbursementAnalaysisUNI />} />
                    <Route exact path='/reporting/disbursement-repayments-Analysis' element={<DisbursementRepayAnalysis />} />
                    <Route exact path='/reporting/repayments-Analysis' element={<RepayAnalysis />} />
                    <Route exact path='/reporting/monthly-analysis' element={<TATMonthlyAnalysis />} />
                    <Route exact path='/reporting/interview-analysis' element={<TAT_Interview_Analysis />} />
                    <Route exact path='/reporting/interview-analysis-renewal' element={<TAT_Interview_Analysis_Renewal />} />
                    <Route exact path='/reporting/aging-analysis' element={<AgingAnalysis />} />
                    <Route exact path='/reporting/mastersheet' element={<MasterSheet />} />

                    <Route exact path='/reporting' element={<Reporting_2 />} />
                </Route>



                <Route exact path='*' element={<PageNotFound />} />
            </Routes>
        </>
    );
}





export default MyRoutes;