import React from 'react'
import { useState, useEffect } from 'react';
import { Next } from 'react-bootstrap/esm/PageItem';
import { useLocation, useNavigate } from 'react-router-dom';

import { getForm5, getFormsByAdmin, SubmissionForm5 } from '../../ContextAPI/APIs/api'
import { useAuth } from "../../ContextAPI/Components/auth"


function Step_6(props) {

    const { GetLoginUser } = useAuth();
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState("step_1");

    useEffect(() => {
        console.log((hash.split("#"))[1] || "step_1");
        setShowForm((hash.split("#"))[1] || "step_1");
    }, [hash]);




    const [data, setData] = useState({

        fatherSalary: "",
        motherSalary: "",
        selfSalary: "",
        siblingSalary: "",
        spouseSalary: "",
        totalSalary: "",
        fatherAllowances: "",
        motherAllowances: "",
        selfAllowances: "",
        siblingAllowances: "",
        spouseAllowances: "",
        totalAllowances: "",
        fatherAnnual: "",
        motherAnnual: "",
        selfAnnual: "",
        siblingAnnual: "",
        spouseAnnual: "",
        totalAnnual: "",
        fatherLeaveEnchas: "",
        motherLeaveEnchas: "",
        selfLeaveEnchas: "",
        siblingLeaveEnchas: "",
        spouseLeaveEnchas: "",
        totalLeaveEnchas: "",
        fatherAFT: "",
        motherAFT: "",
        selfAFT: "",
        siblingAFT: "",
        spouseAFT: "",
        totalAFT: "",
        fatherOther: "",
        motherOther: "",
        selfOther: "",
        siblingOther: "",
        spouseOther: "",
        totalOther: "",
        fatherTGS: "",
        motherTGS: "",
        selfTGS: "",
        siblingTGS: "",
        spouseTGS: "",
        totalTGS: "",
        fatherIncomeTax: "",
        motherIncomeTax: "",
        selfIncomeTax: "",
        siblingIncomeTax: "",
        spouseIncomeTax: "",
        totalIncomeTax: "",
        fatherProvidentFund: "",
        motherProvidentFund: "",
        selfProvidentFund: "",
        siblingProvidentFund: "",
        spouseProvidentFund: "",
        totalProvidentFund: "",
        fatherLoans: "",
        motherLoans: "",
        selfLoans: "",
        siblingLoans: "",
        spouseLoans: "",
        totalLoans: "",
        fatherLeaveEnchas: "",
        motherLeaveEnchas: "",
        selfLeaveEnchas: "",
        siblingLeaveEnchas: "",
        spouseLeaveEnchas: "",
        totalLeaveEnchas: "",
        fatherOtherDeduction: "",
        motherOtherDeduction: "",
        selfOtherDeduction: "",
        siblingOtherDeduction: "",
        spouseOtherDeduction: "",
        totalOtherDeduction: "",
        fatherTotalDeduction: "",
        motherTotalDeduction: "",
        selfTotalDeduction: "",
        siblingTotalDeduction: "",
        spouseTotalDeduction: "",
        totalTotalDeduction: "",
        fatherNetSalary: "",
        motherNetSalary: "",
        selfNetSalary: "",
        siblingNetSalary: "",
        spouseNetSalary: "",
        totalNetSalary: "",
        fatherPension: "",
        motherPension: "",
        selfPension: "",
        siblingPension: "",
        spousePension: "",
        totalPension: "",
        fatherAnualIncome: "",
        motherAnualIncome: "",
        selfAnualIncome: "",
        siblingAnualIncome: "",
        spouseAnualIncome: "",
        totalAnualIncome: "",
        fatherLessAnualExpense: "",
        motherLessAnualExpense: "",
        selfLessAnualExpense: "",
        siblingLessAnualExpense: "",
        spouseLessAnualExpense: "",
        totalLessAnualExpense: "",
        fatherAgricultureIncome: "",
        motherAgricultureIncome: "",
        selfAgricultureIncome: "",
        siblingAgricultureIncome: "",
        spouseAgricultureIncome: "",
        totalAgricultureIncome: "",
        fatherTotalBusinessIncome: "",
        motherTotalBusinessIncome: "",
        selfTotalBusinessIncome: "",
        siblingTotalBusinessIncome: "",
        spouseTotalBusinessIncome: "",
        totalTotalBusinessIncome: "",
        fatherBankDeposit: "",
        motherBankDeposit: "",
        selfBankDeposit: "",
        siblingBankDeposit: "",
        spouseBankDeposit: "",
        totalBankDeposit: "",
        fatherFromHouseProperty: "",
        motherFromHouseProperty: "",
        selfFromHouseProperty: "",
        siblingFromHouseProperty: "",
        spouseFromHouseProperty: "",
        totalFromHouseProperty: "",
        fatherFromBusinessProperty: "",
        motherFromBusinessProperty: "",
        selfFromBusinessProperty: "",
        siblingFromBusinessProperty: "",
        spouseFromBusinessProperty: "",
        totalFromBusinessProperty: "",
        fatherShares: "",
        motherShares: "",
        selfShares: "",
        siblingShares: "",
        spouseShares: "",
        totalShares: "",
        fatherOtherIncome: "",
        motherOtherIncome: "",
        selfOtherIncome: "",
        siblingOtherIncome: "",
        spouseOtherIncome: "",
        totalOtherIncome: "",
        fatherTotal: "",
        motherTotal: "",
        selfTotal: "",
        siblingTotal: "",
        spouseTotal: "",
        totalTotal: "",

    })




    const handleInputChange = (fieldName, value) => {
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const generateInputField = (fieldName, label) => (
        <td key={fieldName}>
            <input
                type="number"
                disabled
                className="form-control"
                placeholder="Enter here"
                value={data[fieldName]}
                onChange={(e) => handleInputChange(fieldName, e.target.value)}
            />
        </td>
    );


    const Next = async () => {
        try {
            // console.log("DATA", data);
            // const response = await SubmissionForm5(data)
            // if (response.success) {
            //     alert(response.message)
            navigate(`#${"step_7"}`)
            // GetLoginUser()
            // }
        }
        catch (e) {
            console.log(e);
        }
    }



    const getFormData = async () => {
        try {
            // const response = await getForm5()

            const response = await getFormsByAdmin(5, props.id)

            if (response.success) {
                console.log("fomr1", response);
                setData(response.message)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getFormData()
    }, [])


    return (
        <>
            <section className='section_main'>
                <div className="container">
                    <div className="card">
                        {/* <form> */}
                        <div className="table_form mb-4">
                            <h4>Details of Income</h4>
                            <h5>Main sources of Income:</h5>
                            <div className="table-responsive mb-4">
                                <table className="table table-bordered mb-0" style={{ width: "170%" }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Income sources (Rs.)</th>
                                            <th scope="col">Father</th>
                                            <th scope="col">Mother</th>
                                            <th scope="col">Self</th>
                                            <th scope="col">Siblings</th>
                                            <th scope="col">Spouse</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row" className="s_no">1</th>
                                            <th rowSpan={7}>Salary Income</th>
                                            <th>Salary</th>
                                            {['fatherSalary', 'motherSalary', 'selfSalary', 'siblingSalary', 'spouseSalary', 'totalSalary'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope="row" className="s_no">2</th>
                                            <th>Allowances</th>
                                            {['fatherAllowances', 'motherAllowances', 'selfAllowances', 'siblingAllowances', 'spouseAllowances', 'totalAllowances'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope="row" className="s_no">3</th>
                                            <th>Annual Bonus</th>
                                            {['fatherAnnual', 'motherAnnual', 'selfAnnual', 'siblingAnnual', 'spouseAnnual', 'totalAnnual'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>


                                        <tr>
                                            <th scope='row' className='s_no'>4</th>
                                            <th>Leave Encashment</th>
                                            {['fatherLeaveEnchas', 'motherLeaveEnchas', 'selfLeaveEnchas', 'siblingLeaveEnchas', 'spouseLeaveEnchas', 'totalLeaveEnchas'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>5</th>
                                            <th>Assistance for Travel</th>
                                            {['fatherAFT', 'motherAFT', 'selfAFT', 'siblingAFT', 'spouseAFT', 'totalAFT'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>6</th>
                                            <th>Other (Please specify)</th>
                                            {['fatherOther', 'motherOther', 'selfOther', 'siblingOther', 'spouseOther', 'totalOther'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>7</th>
                                            <th>Total Gross Salary</th>
                                            {['fatherTGS', 'motherTGS', 'selfTGS', 'siblingTGS', 'spouseTGS', 'totalTGS'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th colSpan={9}>Less Deductions</th>
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>8</th>
                                            <th rowSpan={5}>Salary Deductions</th>
                                            <th>Income Tax</th>
                                            {['fatherIncomeTax', 'motherIncomeTax', 'selfIncomeTax', 'siblingIncomeTax', 'spouseIncomeTax', 'totalIncomeTax'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>9</th>
                                            <th>Provident Fund</th>
                                            {['fatherProvidentFund', 'motherProvidentFund', 'selfProvidentFund', 'siblingProvidentFund', 'spouseProvidentFund', 'totalProvidentFund'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>10</th>
                                            <th>Loan(s)</th>
                                            {['fatherLoans', 'motherLoans', 'selfLoans', 'siblingLoans', 'spouseLoans', 'totalLoans'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>



                                        <tr>
                                            <th scope='row' className='s_no'>11</th>
                                            <th>Other (Please Specify)</th>
                                            {['fatherOtherDeduction', 'motherOtherDeduction', 'selfOtherDeduction', 'siblingOtherDeduction', 'spouseOtherDeduction', 'totalOtherDeduction'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>12</th>
                                            <th>Total Deductions</th>
                                            {['fatherTotalDeduction', 'motherTotalDeduction', 'selfTotalDeduction', 'siblingTotalDeduction', 'spouseTotalDeduction', 'totalTotalDeduction'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no bg-gray'></th>
                                            <td className='bg-gray'></td>
                                            <th className='bg-gray'>Net Salary</th>
                                            {['fatherNetSalary', 'motherNetSalary', 'selfNetSalary', 'siblingNetSalary', 'spouseNetSalary', 'totalNetSalary'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <td colSpan={9} className='row_break'></td>
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>13</th>
                                            <td></td>
                                            <th>Pension</th>
                                            {['fatherPension', 'motherPension', 'selfPension', 'siblingPension', 'spousePension', 'totalPension'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <td colSpan={9} className='row_break'></td>
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>14</th>
                                            <th rowSpan={4}>Business Income/Agriculture Income</th>
                                            <th>Annual Income</th>
                                            {['fatherAnualIncome', 'motherAnualIncome', 'selfAnualIncome', 'siblingAnualIncome', 'spouseAnualIncome', 'totalAnualIncome'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>15</th>
                                            <th>Less Annual Expense</th>
                                            {['fatherLessAnualExpense', 'motherLessAnualExpense', 'selfLessAnualExpense', 'siblingLessAnualExpense', 'spouseLessAnualExpense', 'totalLessAnualExpense'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>16</th>
                                            <th>Agriculture Income</th>
                                            {['fatherAgricultureIncome', 'motherAgricultureIncome', 'selfAgricultureIncome', 'siblingAgricultureIncome', 'spouseAgricultureIncome', 'totalAgricultureIncome'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>



                                        <tr>
                                            <th scope='row' className='s_no'>17</th>
                                            <th>Total Business Income</th>
                                            {['fatherTotalBusinessIncome', 'motherTotalBusinessIncome', 'selfTotalBusinessIncome', 'siblingTotalBusinessIncome', 'spouseTotalBusinessIncome', 'totalTotalBusinessIncome'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <td colSpan={9} className='row_break'></td>
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>18</th>
                                            <th rowSpan={6}>Other Income</th>
                                            <th>Bank Deposit</th>
                                            {['fatherBankDeposit', 'motherBankDeposit', 'selfBankDeposit', 'siblingBankDeposit', 'spouseBankDeposit', 'totalBankDeposit'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>19</th>
                                            <th>From House Property</th>
                                            {['fatherFromHouseProperty', 'motherFromHouseProperty', 'selfFromHouseProperty', 'siblingFromHouseProperty', 'spouseFromHouseProperty', 'totalFromHouseProperty'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>20</th>
                                            <th>From Business Property</th>
                                            {['fatherFromBusinessProperty', 'motherFromBusinessProperty', 'selfFromBusinessProperty', 'siblingFromBusinessProperty', 'spouseFromBusinessProperty', 'totalFromBusinessProperty'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>21</th>
                                            <th>Shares/Securities</th>
                                            {['fatherShares', 'motherShares', 'selfShares', 'siblingShares', 'spouseShares', 'totalShares'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no'>22</th>
                                            <th>Others (specify)</th>
                                            {['fatherOtherIncome', 'motherOtherIncome', 'selfOtherIncome', 'siblingOtherIncome', 'spouseOtherIncome', 'totalOtherIncome'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>

                                        <tr>
                                            <th scope='row' className='s_no bg-gray'></th>
                                            <th className='bg-gray'>Total (Rs.)</th>
                                            {['fatherTotal', 'motherTotal', 'selfTotal', 'siblingTotal', 'spouseTotal', 'totalTotal'].map(fieldName =>
                                                generateInputField(fieldName)
                                            )}
                                        </tr>



                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* </form> */}

                        <div className='d-flex jc-between'>
                            <button className='btn prev_btn' onClick={() => navigate(`#${"step_5"}`)}>Previous</button>
                            <button className='btn next_btn' onClick={() => Next()}>Next</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Step_6