import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import Table from '../../components/Table/Table';
import Text from '../../components/Table/Text';
import EmailText from '../../components/Table/EmailText';
import UserModal from '../../components/Modals/Modal';
import { repaymentAnalysis, TATInterview } from '../../ContextAPI/APIs/api';
import { useToast } from '../../ContextAPI/Components/toast';
import Pagination from '../../components/Pagination';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { useAuth } from '../../ContextAPI/Components/auth';

function TAT_Interview_Analysis_Renewal() {
const {user} = useAuth();
    const { alert } = useToast();

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();


    const [modalItem, setModalItem] = useState("filter");
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = (modalItemValue) => { setShow(true); setModalItem(modalItemValue) };

    const [totalCount, setTotalCount] = useState(0)
    const [pageSkip, setPageSkip] = useState(0)
    const handlePagination = (skip) => {
        console.log("SKIPPPPPPP", skip);
        setPageSkip(skip)
        fetch1(skip)
        // sear ch(skip)
    }

    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = currentYear; year >= 2010; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }


    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    // Handler function to handle option change
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        // Do something with the selected year
        setYear(event.target.value)

        console.log("Selected year:", selectedYear);
    };

    const handleMonthChange = (event) => {
        // Do something with the selected year
        setMonth(event.target.value)

    };


    function timestampToDate(timestamp) {
        if (!timestamp) {
            return "---"
        }
        else {

            const date = new Date(timestamp);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
    }



    function timestampToTime(timestamp) {
        if (!timestamp) {
            return "---";
        } else {
            const date = new Date(timestamp);
            let hours = date.getHours();
            const minutes = ('0' + date.getMinutes()).slice(-2); // Ensure minutes are always two digits
            const amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12; // Convert hours to 12-hour format
            return `${hours}:${minutes} ${amOrPm}`;
        }
    }



    const [xlsxData, setXlsxData] = useState([]);

    const [data, setData] = useState([])



    const fetch1 = async (skip) => {
        try {

            const response = await TATInterview({
                // month: month,
                year: year,
                type: "renewal"
            }, skip)

            console.log("jsjsjsjs", response);

            if (response.success && response.message.length > 0) {
                setData(response.message)
                setTotalCount(response.totalCount.length)
                let temp = [];

                for (let i = 0; i < response.totalCount.length; i++) {
                    
                    let a = {};
                    a['Student Name'] = response?.totalCount[i]?.studentId?.fullName;
                    a.University = response?.totalCount[i]?.uniId?.fullName;
                    a.CNIC = response?.totalCount[i]?.studentProfile?.cnic;
                    a.Contact = response?.totalCount[i]?.studentId?.phone;
                    a.Email = response?.totalCount[i]?.studentId?.email;
                    a['Case Receipt Date'] = timestampToDate(response?.totalCount[i]?.documentRenewal.timestamp);
                    // a['Interview Time'] = timestampToTime(response?.totalCount[i]?.interviewTime);
                    a['Interview Date'] = timestampToDate(response?.totalCount[i]?.interviewDate);
                    a['Approval Date'] = timestampToDate(response?.totalCount[i]?.approvalDate);
                    a['Disbursement Date'] = timestampToDate(response?.totalCount[i]?.disbursementDate);
                    a['Interview Remarks'] = response?.totalCount[i]?.remarks;
                    temp.push(a);

                }
                console.log("HELLO EMP",temp);

                setXlsxData(temp);
                
            // handleClose();
            }

            if (response.success && response.message.length <= 0) {
                alert("No Data Found", false);
                setData([]);
                setXlsxData([]);
            }
            if (!response.success) {
                alert(response.message, response.success);
                setData([]);
                setXlsxData([]);
            }

            handleClose();
        }
        catch (e) {
            console.log(e);
        }
    }




    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const fetchTemplateAndExport = async (apiData, fileName) => {
        try {
            const response = await fetch('/TATInterviewAnalysisFresh.xlsx');
    
            // Added debugging logs
            console.log('Fetch Response:', response);
    
            if (!response.ok) {
                throw new Error('Failed to fetch template file');
            }
    
            const arrayBuffer = await response.arrayBuffer();
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(arrayBuffer);
    
            const worksheet = workbook.getWorksheet('Sheet1');
            const startRow = 5;
    
            apiData.forEach((row, rowIndex) => {
                const sheetRow = worksheet.getRow(startRow + rowIndex);
                Object.keys(row).forEach((key, colIndex) => {
                    const cell = sheetRow.getCell(colIndex + 1);
                    cell.value = row[key];
    
                    // Apply header styles only if they are necessary and don't affect the font weight
                    const headerCell = worksheet.getRow(2).getCell(colIndex + 1);
                    if (headerCell) {
                        // cell.font = { ...headerCell.font, bold: false };  // Ensure font is not bold
                        cell.font = {  bold: false };  // Ensure font is not bold
                        cell.alignment = headerCell.alignment;
                        cell.border = headerCell.border;
                        cell.fill = headerCell.fill;
                    }
                });
                sheetRow.commit();
            });
    
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: fileType });
    
            FileSaver.saveAs(blob, fileName + fileExtension);
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            alert('Error exporting to Excel. Please try again later.');
        }
    };


    return (
        <>
            <Helmet>
                <title>TAT Interview Analysis Renewal</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='reporting_section'>

                        <div className='d-flex jc-between ai-center mb-4 flex_heading_button'>

                            <div className='d-flex ai-center'>
                                <Link to="/reporting">
                                    <IoArrowBack className='back_arrow_icon me-3' />
                                </Link>
                                <h2 className='mb-0'>TAT Interview Analysis Renewal</h2>
                            </div>

                            <div className='d-flex jc-end gap-1 buttons'>
                            {(data.length > 0 && xlsxData.length > 0) && (user?.role === 'admin' || user?.role === 'operations') && (
                                    <button
                                        onClick={() => fetchTemplateAndExport(xlsxData, 'TAT Interview Analysis Renewal')}
                                        className="btn save_btn"
                                    >
                                        Download
                                    </button>
                                )}
                                <UserModal btnText={"Filter"} heading="Filter" id={"filter"} customClasses="save_btn" actionModalClass="action_modal" modalSize="md" modalItem={modalItem} show={show} handleClose={handleClose} handleShow={handleShow}>
                                    <div className="row">

                                        <div className="col-12">
                                            {/* <div className="form-group">
                                                <label htmlFor="yearSelect">Select Month <span className='required'>*</span></label>
                                                <select className='form-select' value={month} id="yearSelect" onChange={handleMonthChange}>
                                                    <option selected disabled value="">Select Month </option>
                                                    <option value="January">January</option>
                                                    <option value="February">February</option>
                                                    <option value="March">March</option>
                                                    <option value="April">April</option>
                                                    <option value="May">May</option>
                                                    <option value="June">June</option>
                                                    <option value="July">July</option>
                                                    <option value="August">August</option>
                                                    <option value="September">September</option>
                                                    <option value="October">October</option>
                                                    <option value="November">November</option>
                                                    <option value="December">December</option>
                                                </select>
                                            </div> */}
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="yearSelect">Select Year <span className='required'>*</span></label>
                                                <select value={year} className='form-select' id="yearSelect" onChange={handleYearChange}>
                                                    <option selected disabled value="">Select Year</option>
                                                    {yearOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='d-flex jc-end'>
                                            <button onClick={() => fetch1(pageSkip)} className='btn save_btn'>Save</button>
                                        </div>
                                    </div>
                                </UserModal>
                            </div>
                        </div>


                        <div className="card">


                            {
                                data.length > 0 ?
                                    <>
                                        <Table head={["Student Name", "University", "CNIC", "Contact", "Email", "Case Receipt Date",  "Interview Date", "Approval Date", "Disbursement Date", "Interview Remarks"]}>
                                            {
                                                data.map((item) => (
                                                    <tr className={item?.status}>
                                                        <td><Text text={item?.studentId?.fullName} /></td>
                                                        <td><Text text={item?.uniId?.fullName} /></td>
                                                        <td><Text text={item?.studentProfile?.cnic} /></td>
                                                        <td><Text text={item?.studentId?.phone} /></td>
                                                        <td><EmailText text={item?.studentId?.email} /></td>
                                                        {
                                                            item?.type == "fresh" ?
                                                                <td><Text text={timestampToDate(item?.documentFresh.timestamp)} /></td>
                                                                :
                                                                <td><Text text={timestampToDate(item?.documentRenewal.timestamp)} /></td>
                                                        }
                                                        {/* <td><Text text={timestampToTime(item?.interviewTime)} /></td> */}
                                                        <td><Text text={timestampToDate(item?.interviewDate)} /></td>
                                                        <td><Text text={timestampToDate(item?.approvalDate)} /></td>
                                                        <td><Text text={timestampToDate(item?.disbursementDate)} /></td>
                                                        <td><Text text={item?.remarks} /></td>
                                                    </tr>
                                                ))
                                            }
                                        </Table>

                                        <Pagination totalCount={totalCount} handlePagination={handlePagination} itemsPerPage={10} />
                                    </>
                                    :
                                    <h5 className='not_found_msg'>No Records Found</h5>
                            }


                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default TAT_Interview_Analysis_Renewal
