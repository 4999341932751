import React, { useEffect, useState } from 'react'
import Helmet from "react-helmet"
import NavSidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import RenewalCasesHistory from './RenewalCasesHistory';
import InterviewSheetRenewal from './InterviewSheetRenewal';
import ApprovalFormRenewal from './ApprovalFormRenewal';
import Tabs from '../../../components/tabs';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import RecommendationFormRenewal from './RecommendationFormRenewal';
import { useAuth } from '../../../ContextAPI/Components/auth';
import { getApprovalSheetForSTD, getInterviewSheetForSTD, getRenewalCaseDetail, isMOUExpired } from '../../../ContextAPI/APIs/api';
import InterviewSheetRenewalPreview from './InterviewSheetRenewalPreview';
import { IoArrowBack } from 'react-icons/io5';



function RenewalCasesDetail() {
    const { id, doc } = useParams()
    const { user } = useAuth()

    const [app, setApp] = useState({})


    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const [tabsD, setTabs] = useState([
        {
            tabName: "Case Detail",
            pathName: "#cases-detail"
        },
    ])


    const Mob_tabs = [
        {
            tabName: "Info",
            pathName: ""
        },
        {
            tabName: "Education",
            pathName: "#education-details"
        },
        {
            tabName: "Password",
            pathName: "#password"
        }
    ]

    const navigate = useNavigate();
    const { hash } = useLocation();
    const [show, setShow] = useState("cases-detail");


    useEffect(() => {
        setShow(hash)
    }, [hash])


    const [data, setData] = useState({})
    const getData1 = async () => {
        try {
            const response = await getRenewalCaseDetail({ _id: doc })
            if (response.success) {
                setData({
                    ...data,
                    _id: response?.message?._id,
                    name: response?.message?.userId?.fullName,
                    email: response?.message?.userId?.email,
                    // cnic: response?.message?.form1?.cnic,
                    cell: response?.message?.userId?.phone,

                    uni: response?.message?.Institute?.fullName,
                    program: response?.message?.Program?.name,
                    semester: response?.message?.form2?.totalSem,
                    appSem: response?.message?.form2?.currentSem,
                })
                setApp(response.message)
                checkMouExpiry(response?.message?.Institute?._id)
                if (response?.message?.RenewStatus == "interview conducted" || response?.message?.RenewStatus == "interview" || response?.message?.RenewStatus == "approved" || response?.message?.RenewStatus == "disburse") {
                    const check = tabsD.some(obj => obj.tabName === "Interview Sheet");
                    console.log("TABBBBcheck", check);
                    if (!check) {
                        let p = {
                            tabName: "Interview Sheet",
                            pathName: "#interview-sheet"
                        }
                        getInterviewSheet(p)
                    }
                    else {
                        getInterviewSheet({})
                    }
                }
            }
            console.log("DATA123", response.message);
        }

        catch (e) {
            console.log(e);
        }
    }


    const [isMou, setMou] = useState(false)
    const [mouTXT, setMouTXT] = useState("")

    const checkMouExpiry = async (uni) => {
        try {
            const payload = { id: uni }
            const response = await isMOUExpired(payload)
            if (response.success) {
                setMou(true)
                setMouTXT(response.message)

            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)

    const getInterviewSheet = async (p) => {
        try {
            console.log("KKKK", p);
            console.log("KKKK", p);
            let a = []
            if (p && p.tabName) {
                a.push(p)
                a.push(
                    {
                        tabName: "Interview Sheet Preview",
                        pathName: "#interview-sheet-preview"
                    }
                )
                a.push({
                    tabName: "Recommendation Form",
                    pathName: "#recomendation-form"
                })

            }

            const response = await getInterviewSheetForSTD({
                id: id,
                type: "renewal"
            })

            const response2 = await getApprovalSheetForSTD({
                id: id,
                type: "renewal"
            })

            const getRenewal = await getRenewalCaseDetail({ _id: doc })


            console.log("response||||||||", response);

            // if (response.success && response.message !== null && getApp?.message?.status ==="interview conducted" && response?.message?.recomendationRupees !== "" && response?.message?.recomendationRepayment !== "" && response?.message?.recomendationPercent !=="") {


            if (response.success && response.message !== null && (getRenewal?.message?.RenewStatus === "interview conducted" || getRenewal?.message?.RenewStatus === "approved" || getRenewal?.message?.RenewStatus === "disburse" ) && response?.message?.recomendationRupees !== "" && response?.message?.recomendationRepayment !== "" && response?.message?.recomendationPercent !== "") {
                setOpen(true)
                // const check2 = tabsD.some(obj => obj.tabName === "Recommendation Form");
                // if (!check2) {
                //     a.push({
                //         tabName: "Recommendation Form",
                //         pathName: "#recomendation-form"
                //     })
                // }
                const check3 = tabsD.some(obj => obj.tabName === "Approval Form");
                if (!check3) {
                    a.push({
                        tabName: "Approval Form",
                        pathName: "#approval-form"
                    })
                }
            }

            if (response2.success && response2.message !== null) {
                setOpen1(true)
            }
            setTabs([...tabsD, ...a])
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        getData1()
    }, [])




    return (
        <>
            <Helmet>
                <title>Cases History</title>
            </Helmet>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <Navbar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />

                    <section className='cases_details_section'>

                        <div className='d-flex ai-center mb-4'>
                            {/* <Link to="/cases/renewal-cases">
                                <IoArrowBack className='back_arrow_icon me-3' />
                            </Link> */}
                            <h2 className='mb-0'>Renewal Case Detail</h2>
                        </div>

                        <div className='card mb-4'>

                            <Tabs tabLinks={tabsD} show={show} setShow={setShow} />

                            {
                                show == "#cases-detail" &&
                                <RenewalCasesHistory getData1={getData1} />
                            }
                            {
                                show == "#interview-sheet" &&
                                <InterviewSheetRenewal />
                            }
                            {
                                show == "#interview-sheet-preview" &&
                                <InterviewSheetRenewalPreview />
                            }
                            {
                                show == "#recomendation-form" &&
                                <RecommendationFormRenewal />
                            }
                            {
                                show == "#approval-form" &&
                                <ApprovalFormRenewal />
                            }

                        </div>

                    </section>
                </div>
            </div>

        </>
    )
}

export default RenewalCasesDetail
