import { login_user, getLogin_user, getTab, getForm8, getrenewal, getForm2, getForm4, getRepayments } from "../APIs/api";

import { createContext, useContext, useState, useEffect } from "react"
import { useCookies } from 'react-cookie';


// Step 1
const FreshCaseContext = createContext()

// Step 2
export const useFreshCase = () => {
    return useContext(FreshCaseContext);
}

// Step 3
export const FreshCaseProvider = ({ children }) => {



    const [test, setTest] = useState("saim")



    return (
        <FreshCaseContext.Provider value={{
            test
        }}>
            {children}
        </FreshCaseContext.Provider>
    )
}


