import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useAuth } from "../ContextAPI/Components/auth"

import LOGO from "../assets/logo.png"
import { signUp } from '../ContextAPI/APIs/api'
import { useToast } from '../ContextAPI/Components/toast'
import { ValidatePhoneNumber } from "../helpers/validatePhoneNumber";
import { ValidateNIC } from "../helpers/validateNIC"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import InputMask from 'react-input-mask';
import SocialWidget from '../components/SocialWidget'

import TooltipComponent from '../components/tooltip';

import { MdInfoOutline } from "react-icons/md"
import { MdVisibility, MdVisibilityOff } from "react-icons/md";


function SignUp() {
    const { alert } = useToast();
    const { SignUp } = useAuth()

    const navigate = useNavigate()
    const [data, setData] = useState({
        firstname: "",
        email: "",
        cnic: "",
        // phone: "",
        password: "",
        cpassword: "",
        gender: ""
    })

    const handleForm = (e) => {

        if (e.target.name == "cnic") {
            const rawCnic = e.target.value.replace(/-/g, '');
            // setCnic(formatCnic(rawCnic));
            setData({ ...data, cnic: formatCnic(rawCnic) })
        }
        else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }


    const formatCnic = (rawCnic) => {
        // Format the CNIC with dashes
        return rawCnic.replace(/^(\d{5})(\d{7})(\d{1})$/, '$1-$2-$3');
    };


    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);

    const register = async (e) => {
        try {
            e.preventDefault();

            console.log("data?.password", data?.password);
            console.log("data?.cpassword", data?.cpassword);

            const payload = {
                ...data,
                phone: phoneNumber
            }
            console.log("PAYLOAD", payload);

            // return

            const checkV = validation()

            if (checkV !== "proceed") return

            else {
                const form = e.currentTarget;
                console.log("form.checkValidity()", form.checkValidity());
                console.log("formErrors()", formError);
                if (form.checkValidity() === false) {
                    console.log("--------------------------------------------", e.target);
                }
                console.log("================================", e.target);



                // if (ValidateNIC(data.cnic) == false) return alert("CNIC should be 13 characters long.", false)
                // if (ValidatePhoneNumber(phoneNumber) == false) return alert("Phone numbers should start with +92 and be 13 characters long.", false)

                // return
                const response = await signUp(payload)

                console.log("RESPONSE", response);
                alert(response?.message, response?.success)
                if (response.success) {
                    navigate("/sign-in")
                }
            }

        }

        catch (e) {
            console.log(e);
        }
    }


    const handleChange = (value) => {
        setPhoneNumber(value);
        setValid(validatePhoneNumber(value));
    };


    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
        return phoneNumberPattern.test(phoneNumber);
    };


    const inputRef = useRef(null);

    const handleInput = () => {
        const inputValue = inputRef.current.value.replace(/\D/g, ''); // Remove non-digits
        const formattedValue = inputValue.replace(/^(\d{5})(\d{7})(\d{1})$/, '$1-$2-$3');

        inputRef.current.value = formattedValue;
    };


    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };



    let errors = {}
    const [formError, setFormError] = useState([])

    const validation = () => {
        try {
            console.log("MM", data);

            if (data.firstname == "") {
                errors.firstname = "Full Name is required";
            }

            if (data.email == "") {
                errors.email = "Email Address is required";
            }

            if ((phoneNumber).length != 12) {
                errors.phone = "Invalid phone number";
            }

            console.log("data.cnic", data.cnic, (data.cnic).replace(/[_-]/g, ''));
            if (((data.cnic).replace(/[_-]/g, '')).length != 13) {
                errors.cnic = "CNIC should be 13 characters long.";
            }

            if (data.gender == "") {
                errors.gender = "Gender is required";
            }

            if (data.password == "") {
                errors.password = "Password is required";
            }

            if (data.cpassword == "") {
                errors.cpassword = "Confirm Password is required";
            }

            if (data.password !== "" && data.password.length < 8) {
                errors.password = "Password length should be greater than 8 characters";
            }

            if (data.cpassword !== "" && data.cpassword.length < 8) {
                errors.cpassword = "Password length should be greater than 8 characters";
            }


            if ((data?.password != data?.cpassword) && data?.cpassword != "") {
                errors.passwordsNotMatch = "passwords do not match";
                errors.password = "";
                errors.cpassword = "";
            }

            if ((Object.keys(errors)).length == 0) {
                setFormError([])
                return "proceed"
            }
            else {
                setFormError(errors)
            }
            // else {
            //     return "proceed"
            // }

            console.log(errors);

        }
        catch (e) {
            console.log(e);
        }
    }

    function IsError(id) {
        return !(Object.keys(formError)).includes(id)
    }

    function ShowError(id) {
        let asd = undefined
        asd = (Object.entries(formError)).find((e) => {
            return e[0] == id
        })

        if (asd) {
            return [true, asd[1]]
        }
        return [false, ""]
    }


    return (
        <>

            <Helmet>
                <title>Ihsan Trust | Sign Up</title>
            </Helmet>

            <section className='authentication_section signup_pass_page d-flex ai-center'>
                <div className="container">

                    <SocialWidget />

                    <div className="card">
                        <img src={LOGO} alt="logo" className='img-fluid' />
                        <div className="sign_form">
                            <h3>Create Your Account</h3>
                            <p className='desc'>Fill out the form to create your account</p>

                            <form onSubmit={(e) => register(e)}>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="form-group" id='firstname'>
                                            <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Please enter your Full Name as stated on your National Identity Card or B-Form.">
                                                <input
                                                    type="text"
                                                    name='firstname'
                                                    value={data.firstname}
                                                    onChange={(e) => handleForm(e)}
                                                    placeholder="Enter Full Name *"
                                                    // required
                                                    // className="form-control"
                                                    className={`${(formError.length == 0 ? "" : IsError("firstname") ? "success" : "error")} form-control`}
                                                />
                                            </TooltipComponent>
                                            {
                                                (ShowError("firstname"))[0] &&
                                                <p className='error_label'>{(ShowError("firstname"))[1]}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="form-group" id='email'>
                                            <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Please make sure you have a functional email address before you create an account. If you do not have an email account, you can create one for free at gmail.com or yahoo.com">
                                                <input
                                                    type="email"
                                                    name='email'
                                                    value={data.email}
                                                    onChange={(e) => handleForm(e)}
                                                    placeholder="Enter your Email *"
                                                    // required
                                                    className={`${(formError.length == 0 ? "" : IsError("email") ? "success" : "error")} form-control`}
                                                />
                                            </TooltipComponent>
                                            {
                                                (ShowError("email"))[0] &&
                                                <p className='error_label'>{(ShowError("email"))[1]}</p>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group" id='cnic'>
                                            <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Please enter your National Identification Number i.e. 13 digit number, as stated on your National Identity Card or B-Form.">
                                                <InputMask
                                                    name='cnic'
                                                    maskChar="_"
                                                    mask="99999-9999999-9"
                                                    placeholder="Enter your CNIC *"
                                                    value={data.cnic}
                                                    onChange={(e) => handleForm(e)}
                                                    className={`${(formError.length == 0 ? "" : IsError("cnic") ? "success" : "error")} form-control`}
                                                />
                                            </TooltipComponent>
                                            {/* <p className='error_label'>{(data.cnic != "" && (ValidateNIC(data.cnic) == false)) && (data.cnic).replace(/[_-]/g, '') != "" ? "CNIC should be 13 characters long." : ""}</p> */}

                                            {
                                                (ShowError("cnic"))[0] &&
                                                <p className='error_label'>{(ShowError("cnic"))[1]}</p>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="form-group" id='phone'>
                                            {/* <input onChange={(e) => handleForm(e)} name='phone' type="tel" className='form-control' placeholder='Phone' /> */}
                                            {/* <p className='error_label'>{data.phone != "" && (ValidatePhoneNumber(data.phone) == false) && "Phone numbers should start with +92 and be 13 characters long."}</p> */}
                                            <TooltipComponent className="" placement="top" tooltipHeading="Hint" tooltipText="Please enter your Phone Number">
                                                <PhoneInput
                                                    name="phone"
                                                    value={phoneNumber || "+92"}
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        required: true,
                                                    }}
                                                    placeholder='+92 ___ _______'
                                                    disableDropdown={true}
                                                    className={`${(formError.length == 0 ? "" : IsError("phone") ? "success" : "error")}`}
                                                />
                                            </TooltipComponent>
                                            {
                                                (ShowError("phone"))[0] &&
                                                <p className='error_label'>{(ShowError("phone"))[1]}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="form-group" id='gender'>
                                            <select value={data.gender} onChange={(e) => handleForm(e)} name='gender' placeholder='Gender'
                                                className={`${(formError.length == 0 ? "" : IsError("gender") ? "success" : "error")} form-select`}
                                            >
                                                <option selected disabled value={""}>Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                            {
                                                (ShowError("gender"))[0] &&
                                                <p className='error_label'>{(ShowError("gender"))[1]}</p>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className={`${(formError.length == 0 ? "" : IsError("password") ? "success" : "error")} input-group`} id='password'>
                                                    <input
                                                        type={showPassword1 ? 'text' : 'password'}
                                                        name='password'
                                                        value={data.password}
                                                        onChange={(e) => handleForm(e)}
                                                        className={"form-control"}
                                                        placeholder="Enter Password"
                                                    />
                                                    <span
                                                        className={"input-group-text password_visibility"}
                                                        onClick={togglePasswordVisibility1}>{showPassword1 ? <MdVisibilityOff /> : <MdVisibility />}
                                                    </span>
                                                </div>
                                                {
                                                    (ShowError("password"))[0] &&
                                                    <p className='error_label'>{(ShowError("password"))[1]}</p>
                                                }
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className={`${(formError.length == 0 ? "" : IsError("cpassword") ? "success" : "error")} input-group`} id='cpassword'>
                                                    <input
                                                        type={showPassword2 ? 'text' : 'password'}
                                                        name='cpassword'
                                                        value={data.cpassword}
                                                        onChange={(e) => handleForm(e)}
                                                        className={"form-control"}
                                                        placeholder="Confirm Password"
                                                    />
                                                    <span
                                                        className={"input-group-text password_visibility"}
                                                        onClick={togglePasswordVisibility2}>{showPassword2 ? <MdVisibilityOff /> : <MdVisibility />}
                                                    </span>
                                                </div>
                                                {
                                                    (ShowError("cpassword"))[0] &&
                                                    <p className='error_label'>{(ShowError("cpassword"))[1]}</p>
                                                }
                                            </div>

                                            {
                                                (ShowError("passwordsNotMatch"))[0] &&
                                                <p className='error_label'>{(ShowError("passwordsNotMatch"))[1]}</p>
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="mb-4">
                                    <button type="submit" className="btn sign_btn">Create My Account</button>
                                </div>
                            </form>

                            <small>Already have an account? &nbsp; <Link to="/sign-in" className='Link'> Sign In </Link></small>

                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default SignUp