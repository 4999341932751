import { useState } from "react";
import { useAuth } from "../../ContextAPI/Components/auth";
import { gender } from "../../helpers/data";
import { Upload_Profile_Pic } from "./upload_profilePic";
import { UpdateProfileInfo } from "../../ContextAPI/APIs/api";
import Input from "../Form/Input";
import Select from "../Form/Select_New";
import { ValidatePhoneNumber } from "../../helpers/validatePhoneNumber";
import { useConfirm } from "../../ContextAPI/Components/confirm";
import { useToast } from "../../ContextAPI/Components/toast";
import PhoneInput from "react-phone-input-2";



export function Admin_Profile_Info() {
    const { alert } = useToast()
    const { Ask } = useConfirm()
    const { user, GetLoginUser } = useAuth()


    const [info, setInfo] = useState({
        name: user.fullName || "",
        email: user.email || "",
        phone: user.phone || "+92",
        gender: { label: user.gender || "select...", value: user.gender || null }
    });

    const [phoneNumber, setPhoneNumber] = useState('');

    async function handleSubmit(e) {
        e.preventDefault();

        console.log("info", info);
        console.log("user", user);
        // return


        // if (info.phone != "" && info.phone != null) {
        //     if (ValidatePhoneNumber(info.phone) == false) return alert("Phone numbers should start with +92 and be 13 characters long.", "error")
        // }

        const payload = {
            FullName: info.name,
            phone: phoneNumber,
            gender: info.gender.value,
        }

        console.log("payload", payload);

        if (await Ask() == false) return;

        const res = await UpdateProfileInfo(payload)
        if (res.success) GetLoginUser()
        alert(res.message, res.success)
    }


    const [valid, setValid] = useState(true);


    const handleChange = (value) => {
        setPhoneNumber(value);
        setValid(validatePhoneNumber(value));
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

        return phoneNumberPattern.test(phoneNumber);
    };


    return <section>
        <div className="personal_info">
            <Upload_Profile_Pic />

            <h4>Personal Information</h4>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <Input label="Full Name" inputType="text" value={info.name} onChange={(e) => setInfo({ ...info, name: e.target.value })} required={true} />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <Input label="Email Address" inputType="email" value={info.email} class="text-transform-none" required={true} disabled={true} />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            {/* <Input label="Phone" inputType="tel" value={info.phone} onChange={(e) => setInfo({ ...info, phone: e.target.value })} placeholder="Enter Phone Number" /> */}
                            <label className="form-label">Phone</label>
                            <PhoneInput
                                value={info.phone || "+92"}
                                onChange={handleChange}
                                // onChange={(e) => setInfo({ ...info, phone: e.target.value })}
                                inputProps={{
                                    required: false,
                                }}
                                placeholder='+92 ___ _______'
                                disableDropdown={true}
                            />
                            <small className="error_label">{(ValidatePhoneNumber(info.phone) == false) && "Phone numbers should start with +92 and be 13 characters long."}</small>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                        <div className='form-group'>
                            <Select
                                label="Gender"
                                options={gender}
                                isOptionDisabled={(option) => (option.value == '' || option.value == null)}
                                value={info?.gender}
                                getOptionLabel={option =>
                                    `${option.label}`
                                }
                                getOptionValue={option => `${option.value}`}
                                onChange={(value) => setInfo({ ...info, gender: value })}
                                loadingMessage={"Loading..."}
                                style={{ textTransform: "capitalize" }}
                                className="my-select"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className='d-flex jc-end mt-3'>
                    <button type='submit' className='btn save_btn'>Update</button>
                </div>

            </form>
        </div>
    </section>
}